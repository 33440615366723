import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import React, {useEffect, useState} from 'react'
import useAsyncEffect from 'hooks/use_async_effect'
import {backend} from '@eitje/easy_api'
import {useSelector} from 'react-redux'
import {all} from '@eitje/easy_api'
import useArbitrary from 'hooks/use_arbitrary'
import {API} from '@eitje/easy_api'
import {Link, Redirect} from 'components/routing'

const Consent = () => {
	const mollieId = useArbitrary('mollieId')
	const [status, setStatus] = useState(undefined)
	useAsyncEffect(async () => {
		const res = await backend.post(`billing/mollie_payment_status`, {payment_id: mollieId, doLoad: true})
		const {success} = res.data
		setStatus(success ? 'ok' : 'failed')
	}, [])

	const billingInfos = useSelector(state => all(state, 'billingInfos'))
	const billingInfo = billingInfos[0]

	if (status == 'ok' || billingInfo?.has_mandate) return <Success />
	if (status == 'failed') return <Failure />
	return (
		<div>
			<p>loading ... </p>
		</div>
	)
}

const Success = () => {
	const billingInfo = useSelector(state => all(state, 'billingInfos'))[0]
	useEffect(() => {
		API.updatePartial('billingInfos', billingInfo.id, {mollie_mandate: true})
	}, [])

	return <Redirect to="/billing/sign" />
	return (
		<div className="w100 fCol aCen jCen veedree" style={{height: 720}}>
			<img style={{width: 400}} src="/images/mollieSetupCompleted.png" />
			<div className="mollieMessage shadow">
				<p className="bold">{t('mollieSuccess')}</p>
			</div>
			<Link to="/">
				<p className="eitje-text-secondary pointer">{t('goBackMollie')}</p>
			</Link>
		</div>
	)
}

const Failure = () => {
	useEffect(() => {
		utils.errorNotif({content: t('billing.notifs.mollie_failed'), duration: 8})
	}, [])
	return <Redirect to="/billing/setup_mollie" />

	return (
		<div className="w100 fCol aCen jCen veedree" style={{height: 720}}>
			<img style={{width: 400}} src="/images/mollieSetupCompleted.png" />
			<div className="mollieMessage shadow" style={{borderColor: 'red'}}>
				<p className="bold" style={{color: 'red'}}>
					{t('mollieFailure')}
				</p>
			</div>
			<Link to="/">
				<p className="eitje-text-secondary pointer">{t('goBackMollie')}</p>
			</Link>
		</div>
	)
}

export default Consent
