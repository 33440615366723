import {all, find} from '@eitje/easy_api'
import createCachedSelector from 'initializers/selectors'
import {createSelector} from 'reselect'
import {currentEnvSelector, orgEnvsSelector} from 'selectors/records'
import {contractJoins as opts} from 'constants/users'

export const schedulableUsersSelector = createCachedSelector(
	state => all(state, 'users', opts),
	(state, envId) => (envId ? find(state, 'environments', envId) : currentEnvSelector(state)),
	(users, {id, onroosterbaar_user_ids = []}) => users.filter(u => u.environment_ids.includes(id) && !onroosterbaar_user_ids.includes(u.id)),
)

export const unschedulableOrgUserIdsSelector = createSelector(orgEnvsSelector, orgEnvs => orgEnvs.map(e => e.onroosterbaar_user_ids).flat())
