import {RawCheckbox as Checkbox} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useEnvTableContext} from 'cores/planning/pages/index/env_table/env_table_provider'
import {useHasRole} from 'hooks'
import useSelector from 'hooks/use_selector'
import {roleOrgEnvsSelector} from 'selectors/new_settings'

export const MultiActionCheckbox = ({shifts, isOrganisationLevel, className, style}) => {
	const {env} = useEnvTableContext()
	const hasManagerRole = useHasRole('manager', env?.id)
	const managerEnvs = useSelector(roleOrgEnvsSelector, 'manager')
	const hasOrgManagerRole = managerEnvs.length
	const {multiEdit, setSelectedShifts, selectedShifts} = useEnvsContext()

	if (!multiEdit || (isOrganisationLevel ? !hasOrgManagerRole : !hasManagerRole)) return null

	const shiftIds = shifts.map(s => s.id)

	const checked = shiftIds.length > 0 && shiftIds.every(id => selectedShifts.includes(id))
	const classNames = utils.makeCns('multi-edit-shifts-checkbox', className)
	return (
		<Checkbox
			onClick={e => e.stopPropagation()}
			value={checked}
			disabled={shifts.length == 0}
			onChange={() => setSelectedShifts(shiftIds)}
			className={classNames}
			style={style}
		/>
	)
}
