import {Input} from 'antd'
import {t} from 'initializers/i18n'
import React from 'react'
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete'

export default class LocationSearchInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {address: props.address || ''}
	}

	handleChange = address => {
		this.setState({address})
	}

	handleSelect = address => {
		this.setState({address})
		geocodeByAddress(address)
			.then(results => this.props.onSucc(results))
			.catch(error => console.error('Error', error))
	}

	render() {
		const {onSucc} = this.props
		return (
			<div style={{position: 'relative'}}>
				<PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
					{({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
						<div>
							<Input
								style={{width: 220}}
								{...getInputProps({
									placeholder: t('searchPlaces'),
									className: 'location-search-input',
								})}
								value={this.state.address}
							/>
							<div style={{position: 'absolute'}} className="autocomplete-dropdown-container">
								{loading && <div>Loading...</div>}
								{suggestions.map(suggestion => {
									const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
									// inline style for demonstration purpose
									const style = suggestion.active
										? {backgroundColor: '#fafafa', cursor: 'pointer'}
										: {backgroundColor: '#ffffff', cursor: 'pointer'}
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			</div>
		)
	}
}
