import useShared from 'hooks/use_shared'
import Add from './index'

export const New = () => {
	const {envId} = useShared()
	const initialValues = {environment_id: envId, kind: 'few_hours'}
	return <Add initialValues={initialValues} />
}

export default New
