import {NAMESPACE} from 'cores/onboarding'

export const featureExplanationsTaxonomy = () => {
	const baseTaxonomy = [
		{
			id: 'firms',
			youTubeId: 'XWqfrX7qR50',
		},
		{
			id: 'planning',
			youTubeId: 'Xu1F5ONEYpA',
		},
		{
			id: 'time_registration',
			youTubeId: 'FdHElyw1un8',
		},
		{
			id: 'time_tracking',
			youTubeId: 'm_D_SQzcGRY',
		},
		{
			id: 'mobile_app',
			youTubeId: 'qUVg5qBsVRo',
		},
	]

	return baseTaxonomy.map(taxItem => {
		const {id} = taxItem
		const namespace = `${NAMESPACE}.features.${id}`
		const modalLink = `/onboarding/${id}`

		return {...taxItem, namespace, modalLink}
	})
}
