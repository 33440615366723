import {UsersOutOfServiceAdvancedTable} from 'common/components/advanced_table/instances/users_out_of_service'
import SearchProvider from 'contexts/search'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import {useMultiEdit, useOutOfServiceUsers} from 'hooks'
import {PageHeader} from './page_header'
import {contractJoins} from 'constants/users'
import {useWhere} from '@eitje/easy_api'

export const UsersOutOfService = ({}) => {
	const users = useOutOfServiceUsers()
	const items = useWhere('users', users.ids(), contractJoins)
	const filters = ['environment']
	const multiEditProps = useMultiEdit()

	return (
		<SearchProvider>
			<FilterFooterProvider
				id="users_out_of_service"
				items={items}
				filters={filters}
				searchField="full_name"
				filterEnviromentsKey="del_env_ids"
			>
				<PageLayout state={multiEditProps} name="active-users" headers={<PageHeader />} footers={<FilterFooter />}>
					<UsersOutOfServiceAdvancedTable />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}
