import {writeStore} from 'common/components/advanced_table'

export const onUnpinnedColumnOrderChange = ({order: unpinned, setState, tableName}) => {
	// update state to trigger render
	setState(unpinned)

	// cache user preference to rebuild on page revisit
	writeStore(tableName, {unpinned})
}

export const onPinnedColumnOrderChange = ({pinnedColumns, columnPinning, tableName}) => {
	// Ordering pinned columns works completely different than ordering unpinned columns: 1) there is no callback hook like
	// onPinnedColumnOrderChange() or something similar, 2) the object that should be written to ReactTable's inner state
	// isn't a flat, ordered array, but an object with an array under key 'left', so `{left: [...orderedColumns]}`.
	// Point 1) makes it more difficult to persist the preferred order of the pinned columns in Redux. So on each render,
	// check if there is a difference between ReactTable's internal state of ordered, pinned columns (which is updated
	// immediately but does not persist) and our persisted value. If there is a difference between the two, it means that
	// the pinned columns have reordered.

	const shouldCachePinnedOrder = !_.isEqual(pinnedColumns, columnPinning.left)

	if (shouldCachePinnedOrder) {
		const pinned = columnPinning.left
		writeStore(tableName, {pinned})
	}
}

export const moveColumn = ({context, header, direction, isPinned}) => {
	const {
		getLeftLeafColumns,
		getCenterLeafColumns,
		getVisibleLeafColumns,
		setColumnOrder,
		options: {
			state: {setColumnPinning},
		},
	} = context.table

	const pinnedColumns = getLeftLeafColumns?.()._map('id')
	const unpinnedColumns = getCenterLeafColumns()._map('id')
	const visibleColumns = getVisibleLeafColumns()._map('id')
	let columns = isPinned ? pinnedColumns : unpinnedColumns

	// We have to update the column's index in order to change its position, BUT we have to update the array which contains
	// both visible and not visible columns. Therefore updating the index with 1 or -1 won't work: if there are three hidden
	// columns between the moving column and the next visible column, the index will be updated but the user won't see any change.
	// Instead, we have to lookup the index of the nearest visible column and use that as anchor to update the index of the
	// current column.
	const thisColumn = header.id
	const allColumnsIndex = columns.findIndex(column => column === thisColumn)
	let newIndex
	if (direction === 'left') {
		newIndex = _.findLastIndex(columns, column => visibleColumns.includes(column), allColumnsIndex - 1)
	} else if (direction === 'right') {
		newIndex = _.findIndex(columns, column => visibleColumns.includes(column), allColumnsIndex + 1)
	}
	columns._moveIndex(allColumnsIndex, newIndex)

	if (isPinned) {
		setColumnPinning({left: columns})
	} else {
		setColumnOrder(columns)
	}
}
