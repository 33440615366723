import {memo} from 'react'
import {Layout} from 'common/components'
import utils from '@eitje/web_utils'
import './styles/index.less'

const _WeekTableRow = ({dateArray = [], className, Panel, Day, preventDoubleBorder, marginTop, ...rest}) => {
	const classNames = utils.makeCns('week-table-row', className)
	const style = {borderTop: preventDoubleBorder && 'unset'}

	// Allow prevention of double borders, can eventually do in Layout with marginTop prop as in marketing site
	const margin = marginTop && `${marginTop}px - - -`

	return (
		<Layout className={classNames} gap={0} childrenEqual style={style} margin={margin}>
			{[<Panel dateArray={dateArray} {...rest} />, ...dateArray.map(d => <Day key={d} date={d} {...rest} />)]}
		</Layout>
	)
}

export const WeekTableRow = memo(_WeekTableRow)
