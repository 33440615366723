import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {getPlannedHours} from 'cores/planning'
import {pathActive} from 'helpers'

export const CurrentWeek = ({dateArray, children, weekShifts}) => {
	const startDate = dateArray[0]
	const {week, year} = utils.getYearWeekFromDate(startDate)
	const {timeString} = getPlannedHours(weekShifts)
	const isHoursPerWeekPage = pathActive('/hours/per_week')
	const isHoursPerMemberPage = pathActive('/hours/per_member')
	const isHourPage = isHoursPerWeekPage || isHoursPerMemberPage

	const plannedHours = utils.exists(weekShifts) && `${timeString} ${isHourPage ? t('common.difference') : ''}`

	return (
		<div className="current-week">
			<h5 className="current-week-title">
				{t('common.week')} {week} - {year}
			</h5>
			{plannedHours && <h6>{plannedHours}ㅤ</h6>}
			{children}
		</div>
	)
}
export default CurrentWeek
