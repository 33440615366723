import React, {useEffect, useState, Fragment, createContext, useContext, useMemo, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import {date} from 'initializers/date'
import utils from '@eitje/web_utils'
import {rangeForWeek, rangeToDateArray} from 'helpers/date'
import {store} from 'index'

const useFeatureDates = feature => {
	let startDate = useSelector(state => state[utils.snakeToCamel(feature)].startDate)
	if (!startDate) startDate = date().startOf('week').format()
	startDate = date(startDate)
	const dateRange = useMemo(() => rangeForWeek({date: startDate}), [startDate])
	const dateArray = rangeToDateArray(dateRange)

	const setDates = date => {
		const newRange = rangeForWeek({date})
		store.dispatch({
			type: `SET_${feature.toUpperCase()}_DATES`,
			startDate: newRange.start.format(),
			endDate: newRange.end.format(),
		})
	}

	const {year, week} = utils.getYearWeekFromDate(startDate)

	const value = useMemo(
		() => ({setDates, year, week, dateArray, dateRange, startDate: dateRange.start, endDate: dateRange.end}),
		[feature, startDate.format()],
	)
	return value
}

export default useFeatureDates
