import React, {useEffect, useState} from 'react'
import Modal from 'components/general/modal'
import utils from '@eitje/web_utils'
import {history} from 'index'
import {TimeRegistrationShift} from 'models/index'
import {useForm, ContextForm} from '@eitje/form'
import {finishOnboardingStep} from 'cores/onboarding'
import useFormData from 'hooks/use_form_data'
import {t as timeT} from 'cores/time_registration/index'
import Header from './header'
import Footer from './footer/index'
import SimpleProvider from 'contexts/simple'
import Body from './body'
import {useDetermineNewShiftEnv} from 'shared'
import {useBodyHotkey, useShared, useHasRole} from 'hooks'
import './styles/index.less'

const t = (text, opts = {}) => timeT(`add_modal.${text}`, opts)
export {t}
const Add = ({isEdit}) => {
	const {setValues} = useForm()
	const {date, dates} = useFormData()
	useEffect(() => {
		if (!utils.exists(dates) && utils.exists(date)) {
			setValues({dates: [date]})
		}
	}, [date])

	const classNames = utils.makeCns('add-shift', isEdit && 'edit-shift')

	return (
		<div className={classNames}>
			<Header />
			<Body />
			<Footer />
		</div>
	)
}

const save = (data, shift, {fields}) => {
	const {dates} = data
	data['user_id'] = fields['user_id']
	const portableData = _.omit(data, 'dates')
	const shifts = dates.map(date => {
		const data = {...portableData, date, start: portableData.from, end: portableData.till}
		data['allowance_ids'] = [data['allowance_id']].flat()
		if (shift?.isPlan()) {
			data['plan_shift_id'] = shift.id
			delete data.id
		}
		return data
	})
	return TimeRegistrationShift.multiUpdate(shifts)
}

const types = ['gewerkte_uren', 'verlof', 'bijzonder_verlof', 'ziek']

const Wrapper = ({initialValues = {}, relatedShifts, shift, hasRole, ...props}) => {
	const {org} = useShared()
	const [isPickingUser, setIsPickingUser] = useState(!shift)
	const envId = useDetermineNewShiftEnv({role: 'uren_schrijven'})
	const context = {isEdit: !!shift, isPickingUser, relatedShifts, shift, setIsPickingUser}
	let initial = {...defaultVals, environment_id: envId, ...initialValues}

	if (!types.includes(initial.typ)) initial.typ = 'gewerkte_uren'

	const afterSubmit = () => {
		finishOnboardingStep({step: 'create_time_registration_shift', org})
		history.goBack()
	}

	return (
		<Modal redirectIf={!hasRole} simpleBack width={840} wrapClassName="eitje-modal-2 time-shift-modal" width="840px">
			<SimpleProvider {...context}>
				<ContextForm
					transNamespace="shift"
					fieldProps={{i18nOpts: {type: 'time_registration_shift'}}}
					afterSubmit={afterSubmit}
					onSubmit={(data, obj) => save(data, shift, obj)}
					className="eitje-form-2-use-borders"
					initialValues={initial}
				>
					<Add {...props} isEdit={!!shift} />

					<HotkeyManager />
				</ContextForm>
			</SimpleProvider>
		</Modal>
	)
}

const defaultVals = {amt_shifts: 1, pauze_duur: '00:00', from: '09:00', till: '17:00', typ: 'gewerkte_uren'}

const HotkeyManager = () => {
	const {submit} = useForm()
	useBodyHotkey({ctrl_enter: submit})
	return null
}

export default Wrapper
