import {Route} from 'components/routing'
import {ProtectedRoute} from 'components/private_route'
import {Invoices} from './pages/invoices'

import Setup from 'cores/billing/pages/setup'
import Sign from 'cores/billing/pages/sign'
import {TrialEnded} from 'cores/billing/pages/expired'
import SetupMollie from 'cores/billing/pages/setup_mollie/index'

const backgroundRoutes = [
	<Route role="admin" path="/billing/setup" exact component={Setup} />,
	<Route role="admin" path="/billing/sign" exact component={Sign} />,
	<Route role="admin" path="/billing/setup_mollie" exact component={SetupMollie} />,
]

const foregroundRoutes = [
	<Route path="/billing/expired" exact component={TrialEnded} />,
	<ProtectedRoute path="/billing/invoices" roleInAnyEnv="admin" exact component={Invoices} />,
]

export {backgroundRoutes, foregroundRoutes}
