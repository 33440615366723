import React, {useContext, createContext, useMemo} from 'react'
import {usePlanningTeams} from 'cores/planning/index'
import useAvailability from 'hooks/use_availability'

const EnvTableContext = createContext({})
const {Provider} = EnvTableContext

const EnvTableProvider = ({env, children}) => {
	const teams = usePlanningTeams(env)
	const {alwaysShowPrefShifts} = useAvailability(env.id)
	const val = {env, alwaysShowPrefShifts, teams}
	const value = useMemo(() => val, [env, alwaysShowPrefShifts, teams])

	return <Provider value={value}> {children} </Provider>
}

export const useEnvTableContext = () => useContext(EnvTableContext)

export default EnvTableProvider
