import {useTotals, items} from './day_content'
import {useCheckboxLeft} from '.'
import {usePerWeekContext} from 'cores/time_registration'
import {useFilterFooterContext} from 'common/components'

export function usePanelItems(date) {
	const {dateRange} = usePerWeekContext()
	const weekData = useTotals(dateRange)
	return items(weekData)
}

export function usePanelCheckbox(date) {
	let {filteredItems: shifts} = useFilterFooterContext()
	return useCheckboxLeft(shifts)
}
