import {PopoutCard} from '@eitje/web_components'
import {Text} from 'common/components'
import {ftime} from 'helpers'

// TO DO: Keep this in common/components for now... but we can move this to text once Chris has the components lib set up
export const RelativeTime = ({date, ...rest}) => {
	const relativeTime = ftime(date, 'relative')
	const dateTime = ftime(date, 'dateTime')

	return (
		<PopoutCard title={dateTime}>
			<Text {...rest}> {relativeTime} </Text>
		</PopoutCard>
	)
}
