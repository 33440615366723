import {t} from 'initializers/i18n'
import {ExportHoursLink, TimeSettingsLink, useFilteredShifts} from 'cores/time_registration/index'
import {LegacyIntegrationLink} from 'components/shared/index'
import {RouteToggle, usePerWeekContext} from 'cores/time_registration/index'
import FixedRangePicker from 'components/date/fixed_range_picker'
import {LocalCheckboxButton} from 'components/ui'
import {ViewButton, PageHeader as _PageHeader, HelpButton} from 'common/components'
import {statusLegendItems} from 'cores/time_registration/components/status_legend'
import {usePageContext} from 'common/components'
import {ModalLink} from 'components/routing'
import {Tools} from 'cores/time_registration/components/tools'
import {ViewOptions} from './view_options'

const docsLinks = [
	{id: 8228442, t: 'time_registration_basics'},
	{id: 6707882, t: 'approve_time_registration_shifts'},
	{id: 8519388, t: 'edit_time_registration_shifts'},
	{id: 6707882, t: 'where_to_find_revenues'},
]

export const PageHeader = () => {
	const {setDates, startDate} = usePerWeekContext()

	return (
		<_PageHeader
			height="small"
			label="common.time_registration_shifts"
			left={[
				<FixedRangePicker onChange={dates => setDates(dates[0])} initialValue={startDate} length={7} allowTodayJump />,
				<RouteToggle />,
			]}
			right={[
				<ViewOptions />,
				<Tools />,
				<HelpButton
					collection={3355114}
					tourId={521520}
					docsLinks={docsLinks}
					legendProps={{items: statusLegendItems(), docsId: 8228442, title: t('hours')}}
				/>,
			]}
		/>
	)
}
