import {t, useShiftsByIds} from 'cores/time_registration/index'
import useRecordErrors from 'hooks/use_record_errors'

export const useErroredShifts = () => {
	const recordNames = ['timeRegistrationShifts', 'planningShifts']
	const errors = useRecordErrors(recordNames)

	const shifts = useShiftsByIds(errors.map(e => e.id))
	const items = shifts.map(s => ({...s, error: errors.find(e => e.id == s.id)?.error}))
	return items
}

export default useErroredShifts
