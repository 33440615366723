import {getTableHeaderFieldName, getTableMeta} from 'common/components/advanced_table'
import {Text} from 'common/components'

export const HeaderTitle = ({value, context, tooltipProps}) => {
	const {tableName, recordType} = getTableMeta(context)
	const name = getTableHeaderFieldName({field: value, name: tableName, recordType})
	return (
		<Text small {...tooltipProps}>
			{name}
		</Text>
	)
}
