import {AmtWorked, getShiftMins} from 'cores/planning/index'
import {useForm} from '@eitje/form'
import {useFormData} from 'hooks/index'
import {Text, ClearButton, Layout} from 'common/components'
import {EitjeAvatar} from 'common/components'

const padding = [14, 14, 14, 24]

const UserShiftCard = ({user, date, env, shift, planningTablePage}) => {
	const {setValues} = useForm()
	const {from, till, pauze_duur, dates = []} = useFormData()
	const isUpdate = shift && shift.user_id == user.id

	const {totalMins: dayMins} = getShiftMins({from, till, pauze_duur})

	const extraDays = isUpdate ? dates.length - 1 : dates.length
	let totalMins = dayMins * dates.length
	if (isUpdate) {
		const {totalMins: savedShiftMins} = getShiftMins(shiftMapper(shift))
		totalMins -= savedShiftMins
	}
	return (
		<Layout id="user-shift-card" padding={padding} gap={8}>
			<EitjeAvatar user={user} />
			<Layout direction="vertical" grow>
				<Text bold truncate>
					{user.full_name}
				</Text>
				{!planningTablePage && <AmtWorked extraMins={totalMins} extraDays={extraDays} user={user} date={date} envId={env.id} showPopout />}
			</Layout>
			{!shift?.reg_shift_id && <ClearButton onClick={() => setValues({user_id: null})} t="common.clear" height="small" />}
		</Layout>
	)
}

const shiftMapper = shift => {
	return {...shift, from: shift.startTime(), till: shift.rawEndTime(), dates: [shift.date], pauze_duur: shift.breaktimeString()}
}

export default UserShiftCard
