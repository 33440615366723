import {NAMESPACE} from 'cores/auth'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export function notify(notification, rest) {
	const namespace = `${NAMESPACE}.notifications.${notification}`

	utils.notify({
		baseColor: 'blue',
		title: t(`${namespace}.title`),
		content: t(`${namespace}.content`),
		duration: 5,
		...rest,
	})
}
