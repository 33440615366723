import {useEffect, useState} from 'react'
import useEventListener from './use_event_listener'

export const useFitInViewportCheck = ({element, offset = 0}) => {
	const [doesItFit, setDoesItFit] = useState({})
	const currentElement = element?.current

	const checkFit = () => {
		const {height, width} = currentElement?.getBoundingClientRect() || {}

		const vertical = window.innerHeight > height + offset
		const horizontal = window.innerWidth > width + offset

		setDoesItFit({vertical, horizontal})
	}

	useEffect(() => checkFit(), [offset, currentElement?.offsetHeight, currentElement?.offsetWidth])
	useEventListener('resize', () => checkFit())

	return doesItFit
}
