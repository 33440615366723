import React, {useEffect, useState, Fragment, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import i18n, {t} from 'initializers/i18n'
import {translationNames} from 'cores/exports2/constants'

export const useExportTranslations = () => {
	const allFiles = useSelector(state => state.fileCacher)
	let translationFiles = _.pick(allFiles, translationNames)
	const languages = _.merge(...Object.values(translationFiles).map(f => f.file))

	useEffect(() => {
		_.each(languages, addTranslation)
	}, [translationFiles])
}

const addTranslation = (v, k) => {
	i18n.addResourceBundle(k, 'translation', {exports2_backend: v})
}

export default useExportTranslations
