import {Input} from '@eitje/form-fields-web'
import {useFormData} from 'hooks'
import {FormRow} from 'components/ui'
import {FormSubmitButton} from 'components/form'
import {AddButton} from 'common/components'
import {ContextForm} from '@eitje/form'
import {t} from 'initializers/i18n'
import './styles/add_item_form.less'
import utils from '@eitje/web_utils'

export const Inner = props => {
	const {new_item} = useFormData()

	const SubmitButton = <FormSubmitButton height="small" disabled={!utils.exists(new_item)} ButtonComponent={AddButton} t="add" />

	return (
		<FormRow>
			<Input className="add-item-input" field="new_item" suffix={SubmitButton} hideClearIcon labelVisible={false} {...props} />
		</FormRow>
	)
}

export const AddItemForm = ({onAdd, transNamespace = 'add_item', ...props}) => {
	return (
		<ContextForm transNamespace={transNamespace} onSubmit={data => onAdd(data.new_item)} resetAfterSubmit hidePrompt>
			<Inner {...props} />
		</ContextForm>
	)
}
