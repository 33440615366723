import {date} from 'initializers/date'
import utils from '@eitje/web_utils'
import {t, ShiftLink, usePerWeekContext} from 'cores/time_registration/index'
import {useUserContext} from 'contexts/user'
import {TimeRegistrationShiftCell} from './time_registration_shift_cell'
import {useDrop} from 'hooks'
import {AddButton, WeekTableColumn} from 'common/components'
import {usePageContext} from 'common/components/page_layout'

export const Column = ({date, ...rest}) => {
	const {multiEdit} = usePageContext()
	const {shifts, user} = useUserContext()
	const dayShifts = shifts.where({date})

	// Drag and drop
	const dropFn = shift => {
		const differentDay = shift.date != date.format()
		const differentUser = shift.user.id != user.id
		if (differentDay || differentUser) return shift.update({date, user_id: user.id})
	}
	const {isOver, canDrop, dropRef} = useDrop({
		accept: `time-registration-shift`,
		canDrop: item => !!utils.exists(item.shift),
		onDrop: item => dropFn(item.shift),
	})

	return (
		<WeekTableColumn
			ref={dropRef}
			dragProps={{isOver, canDrop}}
			hoverChildren={!canDrop && !multiEdit && <CreateShiftButton date={date} />}
			{...rest}
		>
			{dayShifts.map(s => (
				<TimeRegistrationShiftCell key={s.id} shift={s} />
			))}
		</WeekTableColumn>
	)
}

const CreateShiftButton = ({date: _date}) => {
	const isDisabled = () => {
		const {firstRegDate} = usePerWeekContext()
		const timeRegStartInFuture = firstRegDate > _date.format()

		if (timeRegStartInFuture) return t('per_week.before_reg_date', {date: date(firstRegDate).format('DD MMMM')})
	}

	const disabled = isDisabled()
	const {user} = useUserContext()

	return (
		<ShiftLink date={_date} user={user} disabled={disabled}>
			<AddButton width="full" iconLeft="plus" colorSet="color-bordered-color-content" t="hours" minHeight="100%" disabled={disabled} />
		</ShiftLink>
	)
}
