import {t} from 'initializers/i18n'
import {Text} from 'common/components'
import {useLocalValue, setLocal} from 'hooks/use_local'
import {LocalCheckboxButton} from 'components/ui'
import {NAMESPACE as BASE_NAMESPACE} from 'cores/planning/pages/index/week_table_days_header'
import utils from '@eitje/utils'

export function sharedViewOptions(_viewOptions, pageNamespace, title = t(`${BASE_NAMESPACE}.view_options`)) {
	const viewOptions = utils.alwaysArray(_viewOptions)
	const allHidden = viewOptions.map(option => !useLocalValue(option)).every(Boolean)
	const pageSpecificOptions = viewOptions.map(opt => <LocalCheckboxButton kind={opt} t={`${pageNamespace}.${_.snakeCase(opt)}`} />)
	const hideAll = () => {
		viewOptions.each(option => setLocal({name: option, value: false}))
	}

	return {
		title,
		icon: 'eye-open',
		children: [
			{
				icon: 'eye-closed',
				disabled: allHidden && `${BASE_NAMESPACE}.disabled.all_hidden`,
				element: <Text t={`${BASE_NAMESPACE}.hide_all`} />,
				onClick: hideAll,
			},

			...pageSpecificOptions,
		],
	}
}
