import useSelector from 'hooks/use_selector'
import ActiveRecord, {DateRangeActiveRecord} from 'models/active_record/base'
import 'models/active_record/perf_test'

const Page = () => {
	const user = useSelector(state => state.records.users[0])

	const arUser = new ActiveRecord(user)
	return (
		<div>
			<p onClick={() => window.test(10000, user)}> test perf </p>
		</div>
	)
}

export default Page
