import {Layout, Text} from 'common/components'
import {OnboardingStepsPopout, useOnboardingStatus} from 'cores/onboarding'
import {t} from 'initializers/i18n'

const sharedProps = {borderRadius: true, height: 6}

export const OnboardingProgress = ({width = 200, showOnboardingStepsPopout = true}) => {
	const {percentageCompleted} = useOnboardingStatus()
	const completionWidth = (percentageCompleted / 100) * width

	return (
		<Layout className="onboarding-progress" direction="vertical" gap={8}>
			<Text semiBold PopoutComponent={showOnboardingStepsPopout && OnboardingStepsPopout}>
				{percentageCompleted}% {t('common.completed')}
			</Text>
			<Layout width={width} initialBackground={Colors.midtoneGrey} relative {...sharedProps}>
				<Layout width={completionWidth} initialBackground={Colors.mediumBlue} {...sharedProps} />
			</Layout>
		</Layout>
	)
}
