import {useState, forwardRef} from 'react'
import {Dropdown as AntDropdown, Menu} from 'antd'
import utils from '@eitje/web_utils'
const {Item, SubMenu} = Menu

const Dropdown = forwardRef((props, ref) => {
	let {
		elements,
		children,
		visibleAfterClick,
		onVisibleChange = _.noop,
		visibleKeysAfterClick = [],
		hiddenKeysAfterClick = [],
		...rest
	} = props
	visibleKeysAfterClick = utils.alwaysDefinedArray(visibleKeysAfterClick)
	const [visible, setVisible] = useState(false)

	const _onVisibleChange = vis => {
		onVisibleChange(vis)
		setVisible(vis)
	}
	const handleMenuClick = ({key}) => {
		key = Number(key)
		if ((visibleAfterClick || visibleKeysAfterClick.includes(key)) && !hiddenKeysAfterClick.includes(key)) return
		setVisible(false)
	}

	if (ref) {
		ref.current = {
			hide: () => setVisible(false),
			show: () => setVisible(true),
			toggle: () => setVisible(!visible),
		}
	}

	return (
		<AntDropdown {...rest} visible={visible} onVisibleChange={_onVisibleChange} overlay={() => makeOverlay(elements, handleMenuClick)}>
			{children}
		</AntDropdown>
	)
})

const makeOverlay = (elements = [], onClick) => {
	return (
		<Menu onClick={onClick}>
			{elements.filter(Boolean).map((e, idx) =>
				e.children ? (
					<SubMenu title={e.title} key={idx}>
						{e.children.map((child, index) => (
							<Item key={idx + index}>{child}</Item>
						))}
					</SubMenu>
				) : (
					<Item key={idx}>{e}</Item>
				),
			)}
		</Menu>
	)
}

export default Dropdown
