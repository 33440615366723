import {useFind} from '@eitje/easy_api'
import useParams from 'hooks/use_params'
import {Redirect} from 'components/routing'
import {PlanningShift, TimeRegistrationShift} from 'models/index'
import Add from './index'
import useGet from 'hooks/use_get'
import {useHasRole} from 'hooks'

export const Edit = () => {
	const {id} = useParams()
	const planShift = PlanningShift.find(id)
	const regShift = TimeRegistrationShift.find(id)
	const shift = planShift.id ? planShift : regShift
	const team = useFind('teams', shift.team_id)
	const hasRole = useHasRole('uren_schrijven', team.environment_id)

	if (planShift.id) return <PlanShiftEdit shift={planShift} hasRole={hasRole} />
	if (regShift.id) return <RegShiftEdit shift={regShift} hasRole={hasRole} />
	return <Redirect to="/hours/per_week" />
}

const PlanShiftEdit = ({shift, ...rest}) => {
	const team = useFind('teams', shift.team_id)
	return (
		<>
			<Add shift={shift} initialValues={shiftMapper(shift, team)} {...rest} />
		</>
	)
}

const RegShiftEdit = ({shift, ...rest}) => {
	const team = useFind('teams', shift.team_id)
	const {data} = useGet(`time_registration_shifts/${shift.id}/audits`)
	const {data: relatedData} = useGet(`time_registration_shifts/${shift.id}/related_shifts`)
	const {audits = []} = data

	return (
		<>
			<Add shift={{...shift, audits}} relatedShifts={relatedData?.items} initialValues={shiftMapper(shift, team)} {...rest} />
		</>
	)
}

const shiftMapper = (shift, team) => {
	return {
		...shift,
		environment_id: team.environment_id,
		from: shift.startTime(),
		till: shift.rawEndTime(),
		dates: [shift.date],
		pauze_duur: shift.breaktimeString(),
	}
}
