import React, {useEffect, useState, useContext} from 'react'
import {API} from '@eitje/easy_api'
import {useBoolState} from '@eitje/react-hooks'
import useAvailability from 'hooks/use_availability'
import useEnvFilter from 'hooks/use_env_filter'
import {useSearchInput} from 'hooks/use_search'
import {rangeToDateArray} from 'helpers/date'
import utils from '@eitje/web_utils'
import {usePlanningDates} from 'cores/planning/index'
import {usePusherUser} from 'hooks/use_pusher'
import {LeaveRequest} from 'models/index'
const AvContext = React.createContext({})
const {Provider} = AvContext

const AvContextWrapper = ({children}) => {
	const [autoLockEnabled, toggleAutoLockEnabled] = useBoolState(true)
	const {firstOpenDate, alwaysShowPrefShifts} = useAvailability()
	const {dateRange, startDate, endDate, setDates} = usePlanningDates()
	const {envs, filter} = useEnvFilter({role: 'manager', defaultToCurrentEnv: true})
	const {search, searchInput, setSearch, searchInputRef} = useSearchInput({enableHotkey: true})

	const dates = rangeToDateArray(dateRange)

	const [statusFilter, setStatusFilter] = useState([])
	const [contractTypeFilter, setContractTypeFilter] = useState([])
	const [mode, setMode] = useState('weekly') // useEnumState(["weekly", 'monthly'], 'weekly')

	const toggleMode = () => setMode(mode == 'weekly' ? 'monthly' : 'weekly')

	const cellVisible = ({status, conflict, secondaryStatus}) => {
		if (!utils.exists(statusFilter)) return true

		if (statusFilter.includes('conflict')) {
			return !!conflict
		}

		return statusFilter.some(s => [status, secondaryStatus].includes(s))
	}

	const resetSearch = () => {
		setSearch('')
	}

	const resetFilters = () => {
		setStatusFilter([])
		setContractTypeFilter([])
	}

	const resetSearchAndFilters = () => {
		resetSearch()
		resetFilters()
	}

	const toggleConflictStatus = () => {
		setStatusFilter(state => utils.toggle(state, 'conflict'))
	}

	const filters = {start_date: startDate.format('YYYY-MM-DD'), end_date: endDate.format('YYYY-MM-DD')}

	usePusherUser(
		'availability_templates_loaded',
		() => API.index('availability_shifts', {params: {doLoad: true, doNotLoad: false}, filters}),
		startDate?.format('YYYY-MM-DD'),
	)

	useEffect(() => {
		const {week, year} = utils.getYearWeekFromDate(startDate)
		if (dates.length > 0) {
			API.index('availability_shifts', {filters})
			API.index('days', {filters})
			API.index('planning_shifts', {filters})
			LeaveRequest.index({filters})
			API.index('team_days', {filters})
			API.index('user_weeks', {filters: {weeknumber: [week], year: [year]}})
		}
	}, [startDate && startDate.format('YYYY-MM-DD')])

	const context = {
		statusFilter,
		firstOpenDate,
		startDate,
		alwaysShowPrefShifts,
		setStatusFilter,
		contractTypeFilter,
		setContractTypeFilter,
		toggleConflictStatus,
		cellVisible,
		mode,
		autoLockEnabled,
		toggleAutoLockEnabled,
		resetSearchAndFilters,
		endDate,
		toggleMode,
		dates,
		dateRange,
		setDates,
		envs,
		resetFilters,
		resetSearch,
		userSearch: search,
		userSearchInput: searchInput,
		envFilter: filter,
		searchInputRef,
	}

	return <Provider value={context}>{children}</Provider>
}

export default AvContextWrapper

export const useAvContext = () => {
	const context = useContext(AvContext)
	return context
}
