import useSelector from 'hooks/use_selector'
import Modal from 'components/general/modal'
import {useToggleState, useBoolState, useAsyncEffect} from '@eitje/react-hooks'
import {t} from 'cores/integrations/index'
import {lsUrl} from 'initializers/api'
import {taxonomySelector} from 'selectors/new_settings'
import {store} from 'index'
import useQueryParams from 'hooks/use_query_params'
import './styles/index.less'

export const ActivateLightspeed = () => {
	const lightspeedSettings = useSelector(state => taxonomySelector(state, 'lightspeed')) || {}
	const {client_id, code_challenge} = lightspeedSettings
	const {connection_id} = useQueryParams()
	useAsyncEffect(async () => {
		const res = await fetch(
			`${lsUrl}?response_type=code&client_id=${client_id}&code_challenge=${code_challenge}&code_challenge_method=S256`,
		)
		if (res.ok && res.url) {
			const split = res.url.split('&')
			const lsState = split[split.length - 1].split('state=')[1]
			store.dispatch({type: 'SET_LIGHTSPEED_STATE', payload: decodeURI(lsState), connection_id})
			window.location.replace(res.url)
		}
	}, [])

	return (
		<Modal className="integration-redirect-modal">
			<img className="integration-redirect-image" src="/images/integrations/lightspeed_redirect.png" />
			<h3 className="integration-redirect-title"> {t('oauth_redirect.title')} </h3>
			<h5 className="integration-redirect-text"> {t('oauth_redirect.subtitle')} </h5>
		</Modal>
	)
}
