import utils from '@eitje/web_utils'
import {history} from 'index'
import {useLocation} from 'react-router-dom'

export const navigateModal = (path, {search = '', state = {}, bgPath, keepBackground, replace = !!bgPath} = {}) => {
	let loc = history.location
	if (_.isObject(search)) {
		search = new URLSearchParams(search).toString()
	}
	if (!utils.exists(search) && path.split) {
		;[path, search] = path.split('?')
	}

	if (replace) replace = !!loc?.state?.background // idea behind this is that replace is only sensible if you're in a modal

	if (replace || keepBackground) loc = loc?.state?.background

	const url = search ? `${path}?${search}` : path

	if (bgPath) {
		loc['pathname'] = bgPath
	}

	const args = [url, {...state, background: loc}]
	replace ? history.replace(...args) : history.push(...args)
}

export const closeModal = location => {
	const pathname = location.state?.background.pathname
	if (!pathname) return
	history.replace(pathname)
}

export const getQuery = () => {
	const search = useLocation().search.substring(1)
	if (!utils.exists(search)) return {}
	const sanitized = decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/, ' ')
	const final = JSON.parse('{"' + sanitized + '"}')
	return _.mapValues(final, v => (v == 'true' ? true : v == 'false' ? false : v))
}

export function scrollToEnd() {
	const el = document.querySelector('.page-body')
	const scrollLeft = el.scrollHeight - el.scrollTop
	return scrollLeft - el.clientHeight
}

export function scrollFromTop() {
	const el = document.querySelector('.page-body')
	return el.scrollTop
}

export function getCurrentRoute() {
	return history?.location?.pathname
}

export function usePath() {
	const loc = useLocation()
	return loc.pathname
}

export function useLastPathParam() {
	const path = usePath()
	return path
		.split('/')
		.filter(p => utils.exists(p))
		.last()
}

export function goBackToForeground() {
	try {
		const foregroundPath = history.location.state.background.pathname
		history.replace(foregroundPath)
	} catch (e) {
		history.goBack()
	}
}
