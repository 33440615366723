import usePlanningDates from 'cores/planning/hooks/use_planning_dates'
import {usePrevious} from 'hooks'
import {useState} from 'react'

export const useTeamPanelFilter = () => {
	const {dateRange} = usePlanningDates()
	const date = dateRange.start
	const [filteredItems, setFilteredItems] = useState([])
	const prevFilteredItems = usePrevious(filteredItems)

	return {date, filteredItems, setFilteredItems, prevFilteredItems}
}
