import {AdvancedTableHeader, AdvancedTableBody} from '..'
import {SearchEmpty} from 'hooks/use_search'
import {Layout, Placeholder} from 'common/components'
import {AdvancedTableShowMore} from '..'
import {useSearchContext} from 'contexts/search'

const placeholderLayoutProps = {
	width: '100%',
}

export const AdvancedTable = ({data, placeholderProps, ...rest}) => {
	const {search, setSearch} = useSearchContext()
	const {
		getHeaderGroups,
		getRowModel,
		options: {
			state: {columnVisibility},
			clearFilters,
			filteredOutAmount,
		},
	} = data
	const headers = getHeaderGroups()[0]?.headers // we dont use header groups
	const meta = headers[0]?.getContext()?.table?.options?.meta
	const rows = getRowModel().rows
	const empty = rows.length == 0
	const clearSearchAndFilters = () => {
		setSearch?.('') // safe operator: not present in all advanced tables, e.g. invoices
		clearFilters?.() // safe operator: filters are not always set, sometimes only the search is active
	}
	const placeholder =
		empty && (search || filteredOutAmount ? <SearchEmpty action={clearSearchAndFilters} /> : <Placeholder {...placeholderProps} />)
	const pageBody = document.querySelector('.page-body')
	const pageBodyHeight = pageBody?.clientHeight

	const allColumnsVisible = _.every(columnVisibility, Boolean)

	return (
		<Layout
			className="advanced-table"
			gap={0}
			style={{whiteSpace: 'nowrap'}}
			height="unset"
			block={!placeholder}
			minHeight="100%"
			vertical="center"
			horizontal="center"
			{...(placeholder && placeholderLayoutProps)}
		>
			{placeholder || (
				<>
					<Layout direction="vertical" borderRight gap={0} style={{verticalAlign: 'top', display: 'inline-flex'}} minHeight="100%">
						<AdvancedTableHeader rows={rows} headers={headers} {...rest} />
						<AdvancedTableBody meta={meta} rows={rows} {...rest} />
					</Layout>
					{!allColumnsVisible && <AdvancedTableShowMore height={pageBodyHeight} {...meta} columnIds={data.options.columnIds} />}
				</>
			)}
		</Layout>
	)
}
