import React from 'react'
import EnvTableProvider from './env_table_provider'
import TeamRow from './team_row/index'
import {useEnvTableContext, usePlanningContext, useEnvTeams, EventRow, ImproductiveRow} from 'cores/planning'
import EmptyTeamRow from './empty_team_row'
import RevenueFooter from './revenue_footer/index'
import './styles/index.less'
import EnvInfo from './env_info'
import {Event} from 'models'
import {EitjeLazyLoad} from 'common/components/eitje_lazyload'
import {useRevenueFooterHeight} from 'hooks'
import {useFilterFooterContext} from 'common/components/filter_footer/context'

const EnvHeader = () => (
	<div className="env-header">
		<EnvInfo />
	</div>
)

const eventBaseHeight = 13
const eventItemHeight = 42.8

const EnvTable = () => {
	const {env} = useEnvTableContext()
	const {dateRange} = usePlanningContext()

	// event row height calc
	const events = Event.where({date: dateRange, environment_id: env.id}).groupBy('date')
	const maxEventsInADay = Object.values(events).max('length')
	const eventHeight = eventBaseHeight + eventItemHeight * (maxEventsInADay || 1)

	const revFooterHeight = useRevenueFooterHeight(env.id)

	const {envs} = useFilterFooterContext()
	const multiEnvs = envs.length > 1

	return (
		<div className="env-table popout-boundary">
			{multiEnvs && <EnvHeader />}
			<EitjeLazyLoad height={eventHeight}>
				<EventRow envIds={[env.id]} />
			</EitjeLazyLoad>
			<TeamRows env={env} />
			<ImproductiveRow envId={env.id} />
			<EitjeLazyLoad height={revFooterHeight}>
				<RevenueFooter />
			</EitjeLazyLoad>
		</div>
	)
}

let TeamRows = ({env}) => {
	const {teams} = useEnvTableContext()
	const {startDate} = usePlanningContext()
	const date = startDate.format()
	let activeTeams = useEnvTeams()
	activeTeams = _.orderBy(activeTeams, 'rooster_order', 'desc')
	if (teams.length == 0) return <EmptyTeamRow env={env} />
	return activeTeams.map((t, idx) => <TeamRow idx={idx} key={`${t.id}-${date}-${idx}`} item={t} />)
}

TeamRows = React.memo(TeamRows)

const Wrapper = ({item}) => {
	return (
		<EnvTableProvider env={item}>
			<EnvTable />
		</EnvTableProvider>
	)
}

export default React.memo(Wrapper)
