import {
	getEnvSetting,
	getEnvSettingGroup,
	orgEnvSettingKeyValueSelector,
	orgEnvSettingSelector,
	orgEnvsSettingsSelector,
	orgEnvsSettingGroupSelector,
} from 'selectors/new_settings'
import useSelector from 'hooks/use_selector'

export const useEnvSetting = (kind, key, envId) => {
	return useSelector(getEnvSetting, kind, key, envId)
}

export const useEnvSettingGroup = (kind, envId) => {
	return useSelector(getEnvSettingGroup, kind, envId)
}

export const useOrgEnvSetting = (kind, key) => {
	return useSelector(orgEnvSettingSelector, kind, key)
}

export const useEnvsSetting = (kind, key, envIds) => {
	const all = useSelector(orgEnvsSettingsSelector)
	const relevant = _.pick(all, envIds)
	const transformed = transformValues(relevant, [kind, key])
	return transformed
}

function transformValues(obj, pathArray) {
	for (let key in obj) {
		obj[key] = pathArray.reduce((o, k) => o && o[k], obj[key])
	}
	return obj
}

export const useOrgEnvSettingKeyValue = (kind, key) => {
	return useSelector(orgEnvSettingKeyValueSelector, kind, key)
}

export const useOrgEnvsSettingGroup = kind => {
	return useSelector(orgEnvsSettingGroupSelector, kind)
}

export default useEnvSetting
