import {useRoleEnvs} from 'hooks'
import {useFilterFooterContext} from 'common/components'
import {Team} from 'models'

export function useEditableShifts(shifts) {
	const approveEnvs = useRoleEnvs('uren_accorderen')
	const writeEnvs = useRoleEnvs('uren_schrijven')

	return shifts.filter(shift => {
		if (shift.isCheckIn()) return
		const team = shift.team || Team.find(shift.team_id)
		const envId = team.environment_id
		if (approveEnvs._map('id').includes(envId)) return true

		if (!shift.isApproved() && writeEnvs._map('id').includes(envId)) return true
	})
}

export function useEditableFilteredShifts() {
	const {filteredItems: shifts} = useFilterFooterContext()
	return useEditableShifts(shifts)
}
