import utils from '@eitje/web_utils'
import {BaseConnection, ExtEnvironment} from 'models/index'

const useIntegration = id => {
	const item = BaseConnection.find(id)
	const extEnvs = ExtEnvironment.where(item.ext_environment_ids)
	if (!item.id) return {}

	const maxStep = item.isPos() ? (item.hasSalesAreas ? 3 : 2) : 1

	const status = getStatus(item, extEnvs)
	let currentStep = 0
	if (status == 'partly_operational') currentStep = maxStep - 1
	if (status == 'operational') currentStep = maxStep
	return {status, currentStep, maxStep}
}

export const getStatus = (item, extEnvs = []) => {
	if (item.hasIssue()) return 'issue'

	if (!utils.exists(extEnvs)) return 'not_operational'
	const envIds = extEnvs.map(e => e.environment_ids).flat()
	if (!utils.exists(envIds) && !extEnvs.every(e => e.inactive)) return 'not_operational'

	if (item.isHr()) {
		const someNotIntegrated = extEnvs.some(e => !utils.exists(e.environment_ids) && !e.inactive)
		if (someNotIntegrated) return 'partly_operational'
	} else {
		if (!extEnvs.length) return 'partly_operational'
		const {integrationExtRevenueGroups = []} = extEnvs[0]
		if (integrationExtRevenueGroups.some(e => !utils.exists(e.revenue_group_ids) && !e.inactive)) return 'partly_operational'
	}
	return 'operational'
}

export default useIntegration
