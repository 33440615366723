import {BasicCell, stringToCell} from 'common/components/advanced_table'

const SUM_BOOLEAN_CELLS = ['BooleanCell']
const SUM_VALUES_CELLS = ['TimeCell', 'TimeCellWithZero']

const cellValue = (columnName, row) => {
	const cell = row.getAllCells().find(cell => cell.column.id === columnName)
	return cell.getValue()
}

export const sumBooleans = (columnName, rows) => {
	let truthy = 0

	const values = rows.map(row => {
		const value = cellValue(columnName, row)
		if (value && value !== 'no') truthy += 1
	})

	return `${truthy}/ ${values.length}`
}

export const sumValues = (columnName, rows) => {
	const values = rows.map(row => cellValue(columnName, row))
	return values.sum()
}

export const countUniqueValues = (columnName, rows) => {
	const values = rows.map(row => cellValue(columnName, row))
	return values.uniq().length
}

export const getAggregateFn = ({cell, aggregatesFn, ...rest}) => {
	const _sumBooleans = aggregatesFn === 'sumBooleans' || SUM_BOOLEAN_CELLS.includes(cell)
	if (_sumBooleans) return sumBooleans

	const sumValueCells = ['TimeCell', 'TimeCellWithZero']
	const _sumValues = aggregatesFn === 'sumValues' || SUM_VALUES_CELLS.includes(cell)
	if (_sumValues) return sumValues

	if (aggregatesFn === 'countUniqueValues') return countUniqueValues

	return false
}

export const getAggregateCell = ({id, cell, aggregatesCell, ...rest}) => {
	if (aggregatesCell) return stringToCell(aggregatesCell)
	if (typeof cell !== 'string') return cell
	if (SUM_VALUES_CELLS.includes(cell)) return stringToCell(cell)
	return BasicCell
}
