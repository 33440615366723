import {useSelector} from 'react-redux'
import useAvailability from 'hooks/use_availability'
import {useFind} from '@eitje/easy_api'
import {envIdSelector} from 'selectors/records'

const useDay = (date, envId) => {
	const defaultEnvId = useSelector(envIdSelector)
	envId = envId || defaultEnvId
	const {isLocked} = useAvailability(envId)
	const day = useFind('day', {date, environment_id: envId})
	return {...day, closed: isLocked(date) || day.closed}
}

export default useDay
