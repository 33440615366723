import {useDrag as _useDrag} from 'react-dnd'

export const useDrag = props => {
	const [properties, dragRef] = _useDrag({
		...props,
		item: {
			type: props.type,
			...(props?.item || {}),
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
			dragType: props.type,
		}),
	})
	return {...properties, dragRef}
}
