import {useModalContext} from 'components/common'
import {Text} from 'common/components'
import utils from '@eitje/web_utils'

export const Progress = () => {
	const {extEnvironments} = useModalContext()
	const groups = extEnvironments._map('integrationExtRevenueGroups').flat().uniq('id').filter(Boolean) || []

	const mapped = groups.filter(r => utils.exists(r.revenue_group_ids) || r.inactive)
	return (
		<Text>
			({mapped.length} / {groups.length})
		</Text>
	)
}

export default Progress
