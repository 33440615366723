import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import AvailabilityList from './availability_list'
import {useAmountWorkedContext} from 'cores/planning/pages/index/amount_worked_provider'

const GroupedAvailability = ({users, date, maxAmount}) => {
	const [fixedUsers, flexUsers] = _.partition(users, u => u.userEmploymentType(date)?.name == 'Uren contract')
	const {userMap} = useAmountWorkedContext()
	const sortedFixedUsers = _.orderBy(
		fixedUsers,
		u => {
			let val = userMap[u.id]?.minsRemaining
			if (u.available) val += 10000
			return val
		},
		'desc',
	)

	return (
		<Fragment>
			{fixedUsers.length > 0 && (
				<AvailabilityList label={t('planning.environment_table.fixed_hours')} users={sortedFixedUsers} noSort maxAmount={maxAmount} />
			)}
			{flexUsers.length > 0 && (!maxAmount || fixedUsers.length < maxAmount) && (
				<AvailabilityList
					label={t('planning.environment_table.flex')}
					users={flexUsers}
					maxAmount={maxAmount && maxAmount - fixedUsers.length}
				/>
			)}
		</Fragment>
	)
}

export default GroupedAvailability
