import {Placeholder} from 'common/components'
import {NewChatButton} from 'cores/chat'

export const ChatPlaceholder = () => (
	<Placeholder name="chat" animation="chat" docsId="6010068">
		<NewChatButton />
	</Placeholder>
)

export const EmptyChannelPlaceholder = () => <Placeholder name="chat_empty_channel" animation="chat" />
