import {useDebug} from 'helpers'
import {useEffect, useState} from 'react'
import {Layout, Title, Text, ListPicker, ConfirmButton} from 'common/components'
import {ContextForm} from '@eitje/form'
import {setArbitrary} from 'actions'
import {useArbitrary} from 'hooks'
import {Input} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {FormRow} from 'components/ui'
import './styles.less'

export const DevFooter = () => {
	const show = useDebug()
	if (!show) return null
	return <Inner show={show} />
}

const name = 'devHideClassNames'

const Inner = ({show}) => {
	const debugClassNames = utils.alwaysDefinedArray(useArbitrary(name))
	useAddCss(debugClassNames)

	if (!show) return null

	return (
		<Layout
			className="internal-hide-class-names-footer"
			width="full"
			style={{position: 'fixed', bottom: 0, zIndex: 100000}}
			horizontal="last-item-end"
			borderTop
			colorSet="black-bordered"
			padding="- 16 - 24"
			gap="24"
		>
			<Layout direction="vertical" gap={0}>
				<Title> Debug footer </Title>
				<Text fontSize={10}>Voeg classes toe die je wil hiden.</Text>
				<Text fontSize={10}>Je hoeft geen '.' te typen.</Text>
			</Layout>
			<Layout gap={0}>
				<ClassNameForm classNames={debugClassNames} />
				<ClassNames classNames={debugClassNames} />
			</Layout>
			<ConfirmButton baseColor="red" colorSet="color-bordered-fill" onClick={() => setArbitrary(name, [])}>
				verwijder alles
			</ConfirmButton>
		</Layout>
	)
}

const useAddCss = classNames => {
	useEffect(() => {
		const sheet = document.createElement('style')
		sheet.type = 'text/css'
		document.head.appendChild(sheet)

		return () => {
			if (document.head.contains(sheet)) {
				document.head.removeChild(sheet)
			}
		}
	}, [])

	useEffect(() => {
		const sheet = document.head.querySelector('style:last-child')

		if (sheet) {
			const css = classNames
				.map(className => {
					className = className
						.split(' ')
						.map(word => (word[0] == '.' ? word : `.${word}`))
						.join(' ')

					return `${className} { display: none !important; }`
				})
				.join(' ')
			sheet.innerHTML = css
		}
	}, [classNames])
}

const ClassNames = ({classNames}) => {
	const removeItem = data => {
		setArbitrary(
			name,
			classNames.filter(c => c != data.className),
		)
	}

	return (
		<div className="eitje-form-2 eitje-form-2-use-borders ">
			<ContextForm resetAfterSubmit onSubmit={removeItem}>
				<FormRow>
					<ListPicker
						hideClearIcon
						single
						submitStrategy="change"
						items={classNames}
						dropdown
						label={`delete class (${classNames.length})`}
						field="className"
					/>
				</FormRow>
			</ContextForm>
		</div>
	)
}

const forbiddenNames = ['eitje-layout', 'ant-spin-container', 'ant-spin-nested-loading']

const ClassNameForm = ({classNames}) => {
	const addToStore = data => {
		if (data.className[0] == '.') data.className = data.className.slice(1, data.className.length)
		if (forbiddenNames.includes(data.className))
			return utils.errorNotif({content: 'Je mag deze class niet hiden omdat de pagina dan verdwijnt'})
		setArbitrary(name, [...classNames, data.className].uniq())
		return {ok: true}
	}
	return (
		<div className="eitje-form-2 eitje-form-2-use-borders add-classnames-form">
			<ContextForm resetAfterSubmit transNamespace="dev" onSubmit={addToStore}>
				<FormRow>
					<Input label="class toevoegen" placeholder="typ hier" field="className" value="gabber" />
				</FormRow>
			</ContextForm>
		</div>
	)
}
