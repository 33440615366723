import {Fragment, forwardRef} from 'react'
import {Layout} from 'common/components'
import ReactHoverObserver from 'react-hover-observer'
import {makeCnVariants} from 'helpers'
import './styles/index.less'

export const WeekTableColumn = forwardRef(({children, dropRef, dragProps = {}, hoverChildren, hideChildrenOnHover, ...rest}, ref) => {
	// TMP solution: the columns don't get the correct width, since the parent Layout's childrenEqual seems to not use it's own gap of 0px for the
	// calculation, but the childs gap property which somehow seems to be inherited. This Layout does need a gap to space the cells vertically
	// though, thus workaround by passing a gap of 4px but also changing the variable.
	const inlineStyle = {'--gap': '0px'}
	const {isOver, canDrop} = dragProps

	return (
		<ReactHoverObserver className="week-table-column-hover-area" hoverDelayInMs={150}>
			{({isHovering}) => {
				const hoverChildrenVisible = isHovering && hoverChildren

				const classNames = makeCnVariants(
					'week-table-column',
					isOver && canDrop && 'can-drop',
					isOver && !canDrop && 'cannot-drop',
					children.length === 0 && !hoverChildrenVisible && 'has-zero-children',
					children.length === 1 && !hoverChildrenVisible && 'has-one-child',
				)

				// allow to hide tiles in columns on hover, for when tile is simply a placeholder for cells / hover button, such as the availability
				// on the planning per member page
				const showChildren = !(hideChildrenOnHover && isHovering)

				return (
					<Layout
						ref={ref}
						className={classNames}
						direction="vertical"
						vertical="start"
						width="full"
						padding={4}
						style={inlineStyle}
						childrenEqual={false}
						children={[showChildren && children, isHovering && <HoverChildren children={hoverChildren} />]}
						{...rest}
					/>
				)
			}}
		</ReactHoverObserver>
	)
})

const HoverChildren = ({children}) => {
	if (!children) return null // don't render anything to prevent an extra empty child from spawning an extra gap
	return <Layout grow width="full" horizontal="center" className="week-table-column-hover-children" children={children} />
}
