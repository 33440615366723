import {Text, useFilterFooterContext} from 'common/components'
import {t} from 'initializers/i18n'
import {usePlanningContext} from 'cores/planning/index'
import {navigateModal} from 'actions/routing'
import {PlanningShift} from 'models'
import {useRoleMessage} from 'hooks'

const baseStr = 'planning.environment_table.tools_buttons'

export const useLoadTemplate = date => {
	const {
		filterValues: {teamIds},
	} = useFilterFooterContext()

	let search = `team_ids=${teamIds.join(',')}`
	const suffix = date ? `date=${date.format()}` : `isWeek=true`
	search = `${search}&${suffix}`
	const onClick = () => navigateModal('/planning_templates/load', {search})
	const element = <Text t={`${baseStr}.load_template`} />
	const disabled = useRoleMessage({roles: 'manager'})
	return {icon: 'copy-block', element, disabled, onClick}
}

export const useSaveTemplate = date => {
	const {dateRange} = usePlanningContext()
	const {
		filterValues: {teamIds},
	} = useFilterFooterContext()

	const planShifts = PlanningShift.where({date: date || dateRange, team_id: teamIds})
	const msg = date ? `day_disabled` : `disabled`
	let search = `team_ids=${teamIds.join(',')}`
	const suffix = date ? `date=${date.format()}` : `isWeek=true`
	search = `${search}&${suffix}`

	const onClick = () => navigateModal('/planning_templates/save', {search})
	const element = <Text t={`${baseStr}.save_template`} />
	const disabled = useRoleMessage({roles: 'manager'}) || (_.isEmpty(planShifts) && t(`planning.save_template.${msg}`))

	return {icon: 'floppy', element, onClick, disabled}
}
