import {Progress} from 'antd'
import {t} from 'initializers/i18n'

export const ProgressionBlock = ({total, active, color = '#0496ff', title = t('total'), subTitle = t('progression')}) => {
	const pct = Math.round((active / total) * 100) || 0
	return (
		<div className="progression-block">
			<div className="progression-block-header">
				<p className="eitje-heading-xl">{title}</p>
				<p className="eitje-text-secondary">{subTitle}</p>
			</div>
			<div className="progression-block-percent-container">
				<p className="progression-block-number">{pct}</p>
				<p className="progression-block-percent">%</p>
			</div>
			<Progress strokeColor={color} percent={pct} format={() => `${active}/${total}`} />
		</div>
	)
}

export default ProgressionBlock
