import {useEffect} from 'react'
import useHotkey from 'hooks/use_hotkey'

// We want to rewrite useBodyHotkey and use react-hotkeys-hook instead
// This because we have had some problems with using multiple instances of useBodyHotkey on the same page

export const useBodyHotkey = (keyMap = {}, watch = '') => {
	const prevKeyMap = window.keyMap || {}
	const currentKeyMap = {...prevKeyMap, ...keyMap}
	const onKeyDown = useHotkey(currentKeyMap)

	useEffect(() => {
		if (!window.keyMap) window.keyMap = {}
		window.keyMap = {...window.keyMap, ...keyMap}

		return () => {
			window.keyMap = _.omit(window.keyMap, Object.keys(keyMap))
			const newKeyMap = _.omit(window.keyMap, Object.keys(keyMap))
			document.body.onkeydown = useHotkey(newKeyMap)
		}
	}, [])

	useEffect(() => {
		document.body.onkeydown = onKeyDown
	}, [watch])
}
