import {Invoice} from 'models'
import {useBillingInfo} from 'cores/billing/hooks'
import {date} from 'initializers/date'

export const UNPAID_STATUSSES = [
	'unpaid',
	'chargeback', // Manually updated to 'paid' when the invoice is paid for the second time by hand.
	'failed', // Idem ditto.
	'in_transaction', // This means that the invoice is paid manually and has not been updated to 'paid' by our support agents.
]

const THIRD_PARTY_PAYERS = ['invoices@staffable.com', 'administratie@fooks.nl']

// We check the invoices after 14 days, which would make the message disappear, so wait a bit longer with showing the
// red, aggressive reminder.
const DAYS_SINCE_INVOICE = 28

export const useUnpaidInvoices = () => {
	const invoices = Invoice.all()
	const billingInfo = useBillingInfo()
	const notPaidByCustomer = billingInfo?.emails?.some(email => THIRD_PARTY_PAYERS.includes(email))

	if (notPaidByCustomer) return false

	// 1. Filter on status.
	let unpaid = invoices.filter(invoice => {
		return UNPAID_STATUSSES.includes(invoice.status)
	})

	// 2. Filter on invoices that have overreached their payment term.
	unpaid = unpaid.filter(invoice => {
		const {billing_date} = invoice
		const billingDate = date(billing_date)
		const thresholdDate = date().subtract(DAYS_SINCE_INVOICE, 'days')
		return billingDate.isBefore(thresholdDate)
	})

	if (_.isEmpty(unpaid)) return false
	return unpaid.length
}
