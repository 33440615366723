import AvailabilityFormWrapper from 'cores/availability/components/form/availability_form_provider'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import {AvailabilityWeek} from './components'

const Index = () => {
	const dates = ['2007-01-01', '2007-01-02', '2007-01-03', '2007-01-04', '2007-01-05', '2007-01-06', '2007-01-07'].map(d => date(d))
	return (
		<div className="user-availability-table-container">
			<div className="user-availability-header">
				<div className="availability-header-text-container">
					<p className="availability-header-title"> {t('templateAvailability')} </p>
					<p className="availability-header-sub-title"> {t('templateAvailabilitySub')} </p>
				</div>
			</div>
			<div className="user-availability-table">
				<AvailabilityFormWrapper kind="availabilityTemplateShifts" hiddenModes={'leave'}>
					<AvailabilityWeek dates={dates} />
				</AvailabilityFormWrapper>
			</div>
		</div>
	)
}

export default Index
