import {EitjeModal} from 'components/common'
import {ContextForm, t} from '@eitje/form'
import {updateOrgSettings} from 'actions/settings'
import {Switch} from '@eitje/form-fields-web'

import FormRow from 'components/form_row'
import {useOrgSettingGroup} from 'hooks'

export const ContractSettings = () => {
	const generalSettings = useOrgSettingGroup('general')
	return (
		<EitjeModal title={t('common.contract_settings')} name="contract_settings" className="time-settings-modal">
			<ContextForm onSubmit={data => updateOrgSettings({general: data})} transNamespace="contract_settings" initialValues={generalSettings}>
				<FormRow>
					<Switch submitStrategy="change" field="hide_own_salary" />
				</FormRow>
			</ContextForm>
		</EitjeModal>
	)
}
