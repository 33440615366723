import {Icon} from '@eitje/web_components'
import {InnerAvatar} from '..'

export const IconAvatar = props => {
	const {radius, icon} = props
	return (
		<InnerAvatar {...props}>
			<Icon name={icon} size={radius * 0.4} />
		</InnerAvatar>
	)
}
