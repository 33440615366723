import {FilterFooterProvider as _FilterFooterProvider} from 'common/components'
import {Team, PlanningShift} from 'models'
import {getShiftStatusFilter} from 'cores/planning/hooks'
import {falsy} from 'constants/general'
import {t} from 'initializers/i18n'
import {ConflictsButton} from 'cores/planning/components'
import {RecordErrorsButton} from 'shared'
import {usePlanningContext} from 'cores/planning/pages/index/planning_provider'
import {usePathActive} from 'hooks'

const teamQuery = {locked: falsy, onroosterbaar: falsy}

export const FilterFooterProvider = ({...rest}) => {
	const {dateRange} = usePlanningContext()
	const perTeam = usePathActive('/planning/per_team')
	const perMember = !perTeam
	const items = PlanningShift.where({date: dateRange})

	// Filters
	const schedulableTeamIds = Team.where(teamQuery).ids()
	const {filterFunction, filterProps} = getShiftStatusFilter({withOpenShifts: perTeam})
	const filters = [
		'environment',
		{
			name: 'team',
			filterFunction: team => schedulableTeamIds.includes(team.id),
		},
		{
			name: 'status',
			...filterProps,
			filterFunction,
		},
		perMember && {
			name: 'user_shifts',
			items: ['with_shifts', 'without_shifts'],
			filterFunction: (item, filterValue) => {
				// Although we filter rows, return false for the item if it is not visible, to maintain integrity for filteredItemsCount
				// We should fix this within the FilterFooter
				const filter = filterValue[0]
				return !filter || filter == 'with_shifts'
			},
			labelT: label => t(`planning.${label}`),
			title: t('common.team_members'),
		},
	]

	const rightElements = [<ConflictsButton />, <RecordErrorsButton recordTypes={['planningShifts']} />]

	return (
		<_FilterFooterProvider id="planning" items={items} filters={filters} rightElements={rightElements} watchedValue={perTeam} {...rest} />
	)
}
