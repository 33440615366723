import {AuthPageLayout, notify} from 'cores/auth'
import {EmailInput} from 'lib/form/fields'
import {backend} from '@eitje/easy_api'
import {useState} from 'react'
import {navigate} from 'components/routing'

const onSubmit = data => {
	return backend.post(`/users/identify_email`, {...data, skipSuccessNotif: true})
}

export const Welcome = () => {
	const [failedAttempt, setFailedAttempt] = useState(false)

	const afterSubmit = ({resData, params}) => {
		const {user, confirmed} = resData
		const {email} = params
		const encodedEmail = encodeURIComponent(email)
		if (!user) {
			failedAttempt && notify('invalid_email', {baseColor: 'red', duration: 3})
			return setFailedAttempt(true)
		}

		const url = confirmed ? `/login?email=${encodedEmail}` : `/activate_account?user_id=${user}&email=${encodedEmail}`
		navigate(url)
	}

	return (
		<AuthPageLayout
			name="welcome"
			callToActions={['forgot_password', 'create_environment']}
			subtitle={failedAttempt}
			onSubmit={onSubmit}
			afterSubmit={afterSubmit}
			demoButton
			trialButton
		>
			<EmailInput />
		</AuthPageLayout>
	)
}
