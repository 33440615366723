import {EitjeButton as _EitjeButton} from '@eitje/web_components'
import {LinkButton, ModalLinkBtn} from 'components/common'
import {ExternalLinkButton} from './eitje_buttons'
import {useRoleMessage} from 'hooks'

export const EitjeButton = props => {
	let {modalLink, link, externalLink, style, enableRole, roleEnv, disabled} = props
	const linkStyles = {width: props.width?.includes?.('full') && '100%', ...style}

	// If no roleEnv prop is passed, simply 'any' env is used.
	const notPermitted = useRoleMessage({roles: enableRole, env: roleEnv})

	// always prefer role message above custom, content-related message, as to be utmost clear
	// e.g. if something is disabled because there is no contract, adding a contract wouldn't suffice since the user doesn't have a contract role
	disabled = notPermitted || disabled

	const newProps = {...props, disabled}

	if (modalLink) return <ModalLinkBtn {...newProps} style={linkStyles} />
	if (link) return <LinkButton {...newProps} style={linkStyles} />
	if (externalLink) return <ExternalLinkButton {...newProps} style={linkStyles} />

	return <_EitjeButton {...newProps} />
}

export default EitjeButton
