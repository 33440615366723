import {makeLegacyField, usePicker} from '@eitje/form'
import {PopoutCard} from '@eitje/web_components'
import {Layout} from 'common/components'

const Colors = ({items, onChange}) => (
	<Layout>
		{items.map(i => (
			<div
				onClick={() => onChange(i.value)}
				style={{
					cursor: 'pointer',
					display: 'inline-block',
					margin: 4,
					backgroundColor: i.value,
					borderRadius: '50%',
					width: 32,
					height: 32,
				}}
			/>
		))}
	</Layout>
)
// TODO Avatar className styles
const ColorPicker = props => {
	const {value} = props
	const {pickerItems} = usePicker(props)
	const body = <Colors {...props} items={pickerItems} />
	return (
		<PopoutCard body={body}>
			<div
				className="whiteBorder fRow aCen jCen"
				style={{cursor: 'pointer', backgroundColor: 'white', borderRadius: '50%', width: 32, height: 32}}
			>
				<div style={{cursor: 'pointer', backgroundColor: value, borderRadius: '50%', width: 26, height: 26}} />
			</div>
		</PopoutCard>
	)
}

export default makeLegacyField(ColorPicker, {className: 'eitje-color-picker-container'})
