import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'
import {baseColorMapping} from 'cores/time_registration/index'

const StatusCard = ({shift, status, expanded = false, small}) => {
	let _status = status || shift.regStatus()
	const isExported = shift.exported()
	const statusText = `time_registration.statusses.${_status}`
	const color = baseColorMapping[_status]
	const element = <InnerStatus text={statusText} isExported={isExported} small={small} />

	return (
		<Layout>
			{<Icon name="dot-small" fill={Colors[color]} />}
			{expanded && element}
		</Layout>
	)
}

const InnerStatus = ({text, isExported, small}) => {
	return (
		<Layout gap={6}>
			<Text t={text} small={small} />
			{isExported && <Icon name="upload" size={12} />}
		</Layout>
	)
}

export default StatusCard
