import {find} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {makeAbbr} from 'helpers/users'
import _ from 'lodash'
import {useSelector} from 'react-redux'

const avaPattern = /chicken.*avatar.png/

export const UserImage = ({
	test,
	user,
	userId,
	pStyle = {},
	divProps = {},
	divClassName = '',
	extraStyle,
	textStyle,
	className = 'userAvatar',
	imageStyle,
	avatarImage,
	...rest
}) => {
	let abbr
	let newUser

	const dbUser = useSelector(state => find(state, 'users', userId || user.id))
	if (!user) user = dbUser

	if (!user) return null
	let {avatar, image, pf} = user

	if (!avatar) avatar = image
	if (!avatar) avatar = pf
	if (!avatar) avatar = avatarImage
	if (_.isObject(avatar)) {
		avatar = avatar.url
	}

	const noAvatar = !_.isString(avatar) || avatar.match(avaPattern)
	if (noAvatar) {
		abbr = makeAbbr(user)
	}

	const cn = `user-image fRow aCen jCen avaBG bglb ${divClassName} `

	return (
		<div className={cn} {...divProps} style={{...extraStyle}}>
			{noAvatar ? <p style={pStyle}>{abbr}</p> : <img style={{flexShrink: 0, ...imageStyle}} src={avatar} className={className} />}
		</div>
	)
}

export const UserAvatar = ({user, size = 'md', userId, divProps = {}, className, avatarImage}) => {
	let abbr
	if (size == 'small') size = 'sm'
	if (size == 'large') size = 'lg'

	const dbUser = useSelector(state => find(state, 'users', userId || user?.id))
	if (!user) user = dbUser

	if (!user) return null
	let {avatar, image, pf} = user

	if (!avatar) avatar = image
	if (!avatar) avatar = pf
	if (!avatar) avatar = avatarImage
	if (_.isObject(avatar)) {
		avatar = avatar.url
	}

	const noAvatar = !_.isString(avatar) || avatar.match(avaPattern)
	if (noAvatar) {
		abbr = makeAbbr(user)
	}

	const cn = utils.makeCns(`eitje-avatar-overlay-${size}`, className)

	return (
		<div {...divProps} className={cn}>
			{noAvatar ? <p className="eitje-avatar-inner">{abbr}</p> : <img className="eitje-avatar-inner" src={avatar} />}
		</div>
	)
}
