const initialState = {
	expires_at: null,
	refresh_token: null,
	token: null,
	user: {},
	oneSignalId: null,
	oneSignalDone: false,
	oneSignalPreference: false,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'SET_LIGHTSPEED_STATE':
			return {
				...state,
				lightspeedState: action.payload,
				connection_id: action.connection_id,
			}

		case 'USERS':
			const user = action.users.find(u => u.id == state.user.id)
			return {
				...state,
				user: {...state.user, ...user},
			}
		case 'SET_TOKENS':
			return {
				...state,
				token: action.token,
				refresh_token: action.refresh_token,
				expires_at: action.expires_at,
			}
		case 'AUTH_SUCCESS':
			window.user = action.user
			window.pf = 'https://d2oal46jnr9m8n.cloudfront.net//uploads/user/avatar/1307/Merel_Verkaart.png' // we should remove this if non mission critical
			const newUser = {...action.user, avatar: action.user.image}
			return {
				...state,
				refresh_token: action.refresh_token,
				token: action.token,
				expires_at: action.expires_at,
				user: newUser,
			}

		case 'ONE_SIGNAL_ID':
			return {
				...state,
				oneSignalId: action.id,
			}
		case 'ONE_SIGNAL_DONE':
			return {
				...state,
				oneSignalDone: true,
			}

		default:
			return state
	}
}
