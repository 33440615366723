import {API, find, where} from '@eitje/easy_api'
import utils from '@eitje/utils'
import {BasicPopout} from '@eitje/web_components'
import {DeleteButton, EitjeButton} from 'common/components'
import Modal from 'components/general/modal'
import {ModalLink} from 'components/routing'
import {UserImage} from 'components/ui'
import {ConfirmButton} from 'common/components'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {futurePostSelector} from 'selectors/entities'

const getTeamName = teams => {
	return teams.length > 1 ? '$t(multipleTeams)' : teams[0].naam
}

const getScheduledDate = sync_moment => {
	return !sync_moment ? '' : utils.shortDateTime(sync_moment)
}

const DraftOptions = props => {
	const {postId} = props
	return [
		<ConfirmButton
			width="full"
			onClick={async e => {
				e.stopPropagation()
				await API.update('posts', {id: postId, published: true})
			}}
			t="sendNow"
		/>,
		<DeleteButton
			title={t('areYouSure')}
			onClick={async e => {
				e.stopPropagation()
				await API.destroy('posts', postId)
			}}
			width="full"
			t="deletePost"
		/>,
	]
}

// only recieved unpublishd, non event posts
const ScheduledPostLinkRow = ({postId}) => {
	const {user_id, title, team_ids, sync_moment} = useSelector(state => find(state, 'posts', postId))
	const user = useSelector(state => find(state, 'users', user_id))
	const teams = useSelector(state => where(state, 'teams', team_ids))

	if (teams.length == 0) return null

	const teamName = getTeamName(teams)
	const schedDate = getScheduledDate(sync_moment) //should be sync_moment
	const isDraft = !sync_moment

	return (
		<ModalLink to={`/forms/posts/${postId}`}>
			<div className="defaultRow fRow aCen" style={{height: '80px'}}>
				<div className="fRow aCen jSta jBetween" style={{padding: '0px 12px', flex: '1'}}>
					<div className="fRow aCen" style={{flex: 1}}>
						<UserImage divClassName="ava40" extraStyle={{marginRight: 16}} userId={user_id} />
						<div>
							<p className="bold">{title} </p>
							<p className="eitje-text-secondary">{t('inTeamByUser', {teamName, userName: user.full_name})}</p>
							{sync_moment && <p className="eitje-text-secondary">{t('messageInFuture', {date: schedDate})}</p>}
						</div>
					</div>
					{isDraft && (
						<BasicPopout buttons={<DraftOptions postId={postId} />}>
							<EitjeButton t="options" height="mini" colorSet="solid" dropdown />
						</BasicPopout>
					)}
					<img src="/images/dropDown1.png" style={{width: 24, transform: 'rotate(-90deg)'}} />{' '}
				</div>
			</div>
		</ModalLink>
	)
}

const ScheduledPostsModal = () => {
	const unpublished = useSelector(futurePostSelector) || []

	if (unpublished.length === 0) {
		return <Redirect to={'/news'} />
	}

	return (
		<Modal title={t('scheduledDraftPosts')}>
			<div className="modalOverflow">
				{unpublished.map(post => (
					<ScheduledPostLinkRow postId={post.id} />
				))}
			</div>
		</Modal>
	)
}

export default ScheduledPostsModal
// e8f5ff
