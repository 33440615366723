import {FormCheckboxButton} from 'components/ui'
import {makeCnVariants} from 'helpers'
import {Text, Title, Layout} from 'common/components'
import './styles/header.less'

const Header = ({title, shift, hideUserPanel}) => {
	const classNames = makeCnVariants('planning-shift-form-header', hideUserPanel && 'full-width')
	return (
		<div className={classNames}>
			<Layout>
				<Title bold>{title}</Title>
				{shift && <Text small>{shift.formatDate('DD MMM YYYY')}</Text>}
			</Layout>

			{!shift?.reg_shift_id && <FormCheckboxButton field="published" />}
		</div>
	)
}

export default Header
