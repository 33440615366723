import utils from '@eitje/web_utils'
import {Icon} from '@eitje/web_components'
import {Text, Layout} from 'common/components'
import List from 'components/list/list'
import usePrevious from 'hooks/use_previous'
import _ from 'lodash'
import React, {Fragment, useEffect, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {makeCnVariants} from 'helpers'
import './styles/index.less'

const DraggableList = props => {
	const {items, sortField = 'idx', direction = 'horizontal'} = props
	const [localItems, setLocalItems] = useState(_.sortBy(items, sortField))
	const prevItems = usePrevious(items)
	const containerClassName = `list-container-draggable drag-direction-${direction}`

	useEffect(() => {
		if (!_.isEqual(prevItems, items)) {
			setLocalItems(_.sortBy(items, sortField))
		}
	}, [items])

	const updateSortOrder = (startIdx, endIdx) => {
		const _items = reorder(localItems, startIdx, endIdx)
		setLocalItems(_items)
		if (!props.onDragEnd) return
		props.onDragEnd(_items.map((i, idx) => ({...i, [sortField]: idx + 1})))
	}

	const onPosChange = (amt, idx) => updateSortOrder(idx, amt + idx)

	const onDragEnd = ({source, destination}) => source && destination && updateSortOrder(source.index, destination.index)

	const itemProps = {
		...props,
		...props?.itemProps,
		onPosChange: onPosChange,
		totalLength: localItems.length,
	}

	//1.  // If we get this out, every time this component re-renders a new reference is created for NewItem
	// This causes ant's popover to close and might producre other weird bugs. General wisdom seems to tell us
	// to use useCalback sparingly, because 'optimization increases complexity' but for components defined on-the-fly
	// it seems the other way around.

	//2. ignoreCallbackDeps is used in /exports/layouts/form/table to ensure you don't have to click the dd picker twice.
	//   I do remember that without any deps, it produced weird errors, but it doesn't inside exports, and I can't find the place where it does
	// Thus I leave it this way until we figure out what the original reason was we put in onPosChange into the deps

	// 3. ignoreCallbackDeps causes localItems to not be properly referenced in onPosChange, thus leading to bugs..
	// if we put localItems into the dependency, it works beautifully again.. it even looks like this may be the end, as skills also works nicely with this enabled
	// we'll keep it in for a lil while longer just to be sure

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable direction={direction} className="helloworld" droppableId="droppableAlign">
				{(provided, snapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef} className={containerClassName}>
						<List {...props} items={localItems} ListItem={DraggableEl} itemProps={itemProps} />
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

const DraggableEl = ({item, idx, ListItem, onPosChange, totalLength, direction, ...rest}) => {
	const itemProps = utils.funcOrVal(rest.itemProps, item)
	return (
		<Draggable key={String(item.id)} draggableId={String(item.id)} index={idx}>
			{(provided, snapshot) => (
				<Fragment>
					<div className="drag-element" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						<ListItem item={item} idx={idx} {...itemProps} {...rest} />
					</div>
					<Arrows onPosChange={onPosChange} totalLength={totalLength} idx={idx} direction={direction} />
				</Fragment>
			)}
		</Draggable>
	)
}

export const Arrows = ({totalLength, hideCount, onPosChange, direction = 'vertical', idx}) => {
	const isFirst = idx === 0
	const num = idx + 1
	const isLast = num === totalLength
	const classNames = utils.makeCns(makeCnVariants('order-arrows', direction))
	const iconSortUp = direction == 'horizontal' ? 'caret-left' : 'caret-up'
	const iconSortDown = direction == 'horizontal' ? 'caret-right' : 'caret-down'

	return (
		<Layout direction={direction} className={classNames} gap={0} horizontal="center" vertical="center">
			{!isFirst && <Icon size="12px" fill={Colors.black} name={iconSortUp} onClick={() => onPosChange(-1, idx)} />}
			{!hideCount && (
				<Text small bold>
					{num}
				</Text>
			)}
			{!isLast && <Icon size="12px" fill={Colors.black} name={iconSortDown} onClick={() => onPosChange(1, idx)} />}
		</Layout>
	)
}

export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

export default DraggableList
