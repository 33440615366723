import {backend} from '@eitje/easy_api'
import {store} from 'index'

export const getTraining = async () => {
	const res = await backend.get(`training`)
	handleRes(res)
}

export const finishArticle = async topicRec => {
	const res = await backend.post(`topic_resources/${topicRec.topic_resource_id}/finish`)
	handleRes(res)
	return res
}

export const finishQuizItem = async (topicRec, val, userId) => {
	const res = await backend.post(`user_answers`, {quiz_item_id: topicRec.id, answer: val, user_id: userId})
	handleRes(res)
	return res
}

const handleRes = res => {
	if (res.ok) {
		const {items} = res.data
		store.dispatch({type: 'SET_VALUE', name: 'training', payload: res.data})
	}
}
