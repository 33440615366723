const {useImperativeHandle} = require('react')

export const useHideShow = ({ref, setIsOpen, isOpen, setHovering}) => {
	useImperativeHandle(ref, () => ({
		hide() {
			setIsOpen(false)
			setHovering(false)
		},
		show() {
			setIsOpen(true)
			setHovering(true)
		},
		toggleHideShow(bool) {
			setIsOpen(bool)
		},
		isPopoutOpen: isOpen,
	}))
}
