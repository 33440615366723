import {useEffect} from 'react'
import {PageLayout, Layout, Placeholder} from 'common/components'
import {Channel, ChannelList, JumpToMessageProvider, useChatWindowContext} from 'cores/chat'
import {store} from 'index'
import {PageHeader} from './page_header'
import {useChatContext} from 'stream-chat-react'
import {useSelector} from 'react-redux'

const bodyLayoutProps = {
	gap: 0,
	direction: 'vertical',
	height: 'full',
	padding: '- 32',
	margin: 'auto',
	maxWidth: 1200,
}

const placeholderLayoutProps = {
	padding: '180 0',
	height: 'unset',
}

export const Chat = () => {
	const initialChannel = useSelector(state => state.chat.initialChannel)

	const {client, setActiveChannel} = useChatContext()
	const {chatWindowOpen, closeChatWindow} = useChatWindowContext()

	useEffect(() => {
		// reset active channel on dismount
		return () => {
			store.dispatch({type: 'SET_INITIAL_CHANNEL', initialChannel: ''})
			setActiveChannel?.(null)
		}
	}, [])

	useEffect(() => {
		// close chat window in case chat page is rendered
		chatWindowOpen && closeChatWindow()
	}, [chatWindowOpen])

	return (
		<PageLayout name="chat" bodyLayoutProps={bodyLayoutProps} unsetPagePadding>
			{client && (
				<>
					<PageHeader />
					<Layout grow width="full" gap={0} minHeight={0} vertical="unset">
						<JumpToMessageProvider>
							<ChannelList customActiveChannel={initialChannel} borderLeft borderRight minWidth={250} width="30%" />
							<Channel minWidth={550} width="70%" />
						</JumpToMessageProvider>
					</Layout>
				</>
			)}
			{!client && <Placeholder name="chat_down" animation="broken-egg" supportButton layoutProps={placeholderLayoutProps} />}
		</PageLayout>
	)
}
