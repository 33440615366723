import {API, find} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker} from '@eitje/form-fields-web'
import {Button} from 'antd'
import {DeleteButton, Layout} from 'common/components'
import InputNumber from 'components/form/input_number'
import useList from 'components/general/list'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {forwardRef} from 'react'
import {useSelector} from 'react-redux'

const overtimeItems = ['no_overtime', 'over_and_under', 'undertime']
const leaveItems = ['contract_hours', 'worked_hours', 'no_leave']

// Unused Component
export const SettingsForm = forwardRef(({id, hideSubmit, ...rest}, ref) => {
	const empType = useSelector(state => find(state, 'employmentTypes', id))
	const _leaveItems = empType.fixed ? leaveItems : leaveItems.slice(1, 3) // temp disabled cuz we dont use this yet in the back-end

	return (
		<Form ref={ref} initialValues={empType} {...rest}>
			<DropdownPicker bordered={false} items={_leaveItems} field="leave_registration" />
			{false && empType.fixed && <DropdownPicker valueField="value" items={overtimeItems} field="overtime_registration" />}{' '}
			{
				// temp disabled cuz we dont use this yet in the back-end
			}
			<div fieldWrapper className="form-row">
				<InputNumber max={10} extraLabel={t('value')} placeholder="1,35" required field="conversion_factor" />
				<InputNumber
					disabled={formData => formData.leave_registration == 'no_leave'}
					extraLabel={t('value')}
					placeholder="..."
					max={0.99}
					field="leave_factor"
				/>
			</div>
			{!hideSubmit && (
				<Button style={{marginTop: 8, marginLeft: 12}} type="primary" submitButton showAfterTouch>
					{t('submit')}
				</Button>
			)}
		</Form>
	)
})

const SettingsItem = ({item, isLast, isFirst, isActive}) => {
	const {start_date, end_date} = item
	const empType = useSelector(state => find(state, 'employmentTypes', item.employment_type_id))
	return (
		<div className="settings-item">
			<Layout className="work-week-row" horizontal="spaceBetween">
				<div>
					<p className="eitje-text-secondary"> {t('fromTill', {from: start_date, till: end_date, kind: 'userEmploymentType'})} </p>
					<p>{empType.name}</p>
				</div>
				{isActive && isFirst && !isLast && (
					<DeleteButton onDel={() => API.destroyMutation('userEmploymentTypes', item.id)}>{t('deleteMutation')}</DeleteButton>
				)}
			</Layout>

			{isActive && false && (
				<SettingsForm
					id={item.employment_type_id}
					onSubmit={data => API.resourceReq('employment_types', 'crupdate', {...data, user_employment_type_id: item.id})}
				/>
			)}
		</div>
	)
}

const Settings = ({fullContract}) => {
	let {userEmpTypes} = fullContract
	const {list} = useList({items: userEmpTypes, def: userEmpTypes[0]?.id, ListItem: SettingsItem})

	return <div className={'list-item-active-bg'}>{list}</div>
}

export default Settings
