import {HelpButton as _HelpButton} from 'common/components'

const docsLinks = [
	{id: 5967145, t: 'how_to_invite_users'},
	{id: 5967127, t: 'how_to_create_teams'},
	{id: 5967127, t: 'how_to_delete_teams'},
	{id: 6024737, t: 'duplicate_users_through_hr_integration'},
]

export const HelpButton = props => {
	return <_HelpButton tourId={562546} collection={3355132} docsLinks={docsLinks} {...props} />
}
