import {useMemo} from 'react'
import utils from '@eitje/web_utils'
import {LeaveRequest, Association} from 'models'
import {useLeaveDates} from 'cores/leave'
import {useEnvsContext} from '../providers/envs_provider'
import {useSearchContext} from 'contexts/search'
import {useFilterFooterContext} from 'common/components'
import {useRoleEnvs, filterItems as searchItems, useShared} from 'hooks'

const opts = {joins: ['users', 'environments']}

const filterShifts = (items, search) => {
	return searchItems(items, search, {searchField: 'user.full_name'})
}

export const useFilteredLeave = () => {
	const base = LeaveRequest.all()

	// Items visible for users with 'leave' role
	const environment_ids = useRoleEnvs('verlof').ids()
	const {dateRange, startDate, endDate} = useLeaveDates()
	const envItems = base.where({dateRange, environment_id: environment_ids})

	// Items visible for users
	const {me} = useShared()
	const myItems = base.where({user_id: me.id}, opts)

	// All items
	let allItems = useMemo(() => new Association(_.uniq([...envItems, ...myItems], 'id')), [envItems, myItems])
	allItems = useMemo(() => allItems.where({dateRange}), [allItems, startDate?.format(), endDate?.format()]) // dateRange isn't cached properly - we should write a serializer

	// Filtered by search
	const {search} = useSearchContext()
	const searchItems = useMemo(() => filterShifts(allItems, search), [allItems, search])

	return {items: searchItems}
}
