import {TableText} from 'common/components/advanced_table'
import {Layout} from 'common/components'

export const ExtLinkCell = ({getValue, column}) => {
	// truncate={false} is required to prevent the text from becoming an inline block, influencing the vertical position within the cell
	let {t} = column.columnDef
	const href = getValue()

	// Prevent the row count being pushed to the next line in collapsible, grouped cells.
	const style = {display: 'inline-block'}

	// clicks directly on the <a> are handled automatically, clicks within the cell BUT outside the <a> are handeld handled by a
	// querySelector in the cell, see helper function below.
	return (
		<a target="_blank" href={href} style={style}>
			<TableText t={t || 'common.link'} darkBlue underline truncate={false} />
		</a>
	)
}

export const handleExtLinkCellClick = event => {
	const {target, currentTarget} = event
	const link = currentTarget.querySelector('a')

	// Check for link, to prevent breaking clicks on other table cells.
	if (target.tagName === 'DIV' && link) {
		event.preventDefault()
		link.click()
	}
}
