export const articlesTaxonomy = () => {
	return [
		{
			name: 'trial_period',
			article: 9984310,
			icon: 'question-mark-circled',
		},
		{
			name: 'create_team',
			article: 7234409,
			icon: 'chart-circles',
		},
		{
			name: 'invite_users',
			article: 7234477,
			icon: 'person-plus',
		},
		{
			name: 'create_planning_shift',
			article: 7234481,
			icon: 'calendar',
		},
		{
			name: 'time_registration_basics',
			article: 8228442,
			icon: 'clock',
		},
		{
			name: 'time_tracking',
			article: 6037228,
			icon: 'stopwatch',
		},
		{
			name: 'integrations',
			article: 8233205,
			icon: 'link',
		},
		{
			name: 'roles',
			article: 6022058,
			icon: 'confetti',
		},
		{
			name: 'guide_for_team_members',
			collection: 5106320,
			icon: 'book',
		},
	]
}
