import {useFormField, makeLegacyField} from '@eitje/form'
import {upload} from 'actions/files'
import {Button} from 'antd'
import {t} from 'initializers/i18n'
import React, {Fragment, useRef, useState} from 'react'
import {buildField} from '@eitje/form-fields-web'
import './styles/image_picker.less'
import {DeleteButton} from 'common/components'

const acceptImg = 'image/*'

const acceptFile = '*'

export const ImagePicker = props => {
	const actualInput = useRef(null)
	const {
		image = true,
		pickImg = {current: null},
		inputHidden,
		blockSubmit = () => {},
		ContainerComponent = 'div',
		DeleteComponent = DeleteButton,
		value,
		textClassName,
		onChange = () => {},
		onFileChange,
		imageClassName,
		hideFileList,
	} = props

	const {disabled} = useFormField(props)
	const [progress, setProgress] = useState(0)
	const [fileName, setFileName] = useState(null)

	const afterSelect = async data => {
		if (onFileChange) {
			onFileChange(data)
			return
		}
		blockSubmit(true)
		const finalUrl = await upload(data, setProgress)
		await blockSubmit(false)
		if (finalUrl) {
			onChange(finalUrl)
		}
		setProgress(null)

		if (data[0] && data[0].name) setFileName(data[0].name) // TODO file name once we port over new filenaming on S3
	}
	// const src = image ? t('image') : t('attachment')
	const isFile = !image
	const pickImage = () => !disabled && actualInput.current.click()
	pickImg.current = pickImage //

	return (
		<Fragment>
			{value && !hideFileList && (
				<ContainerComponent className={`eitje-picker-details-container fRow jBetween aCen attachRow w100`}>
					{isFile ? (
						<div className="fRow jSta aCen" style={{width: '100%', whiteSpace: 'pre', overflow: 'auto'}}>
							<img style={{width: 40, padding: 8, opacity: 0.5}} src="/images/web/icons/news/fileIcon.png" />
							<p className={textClassName} style={{marginLeft: '8px'}} onClick={pickImage}>
								{fileName || t('file')}
							</p>
						</div>
					) : (
						<div style={{width: '100%'}}>
							<img onClick={pickImage} src={value} className={imageClassName || 'photoViewer'} style={{objectFit: 'cover'}} />
						</div>
					)}
					<DeleteComponent
						iconOnly
						onDel={() => onChange(null)}
						extraStyle={{minHeight: 40, height: 40, borderRadius: 4, marginRight: 4}}
					/>
				</ContainerComponent>
			)}

			{image && !value && !inputHidden && (
				<ContainerComponent className="fRow jSta aCen">
					<div className="photoViewer fRow aCen jCen">
						{progress > 0 && <p style={{fontSize: 8, color: '#2b2b2b', fontWeight: 'bold'}}>{progress}%</p>}
					</div>
					<p className="eitje-text-secondary" style={{paddingLeft: 12}}>
						{t('noAttachUpload')}
					</p>
				</ContainerComponent>
			)}

			{!inputHidden && <Button onClick={pickImage}>{t(isFile ? 'selectFile' : 'selectAttach')}</Button>}
			<input
				style={{display: 'none'}}
				ref={actualInput}
				accept={image ? acceptImg : acceptFile}
				type="file"
				id="single"
				onChange={e => afterSelect(e.target.files)}
			/>
		</Fragment>
	)
}
const props = {withLabel: false, className: 'eitje-image-picker-container'}

export const newImagePicker = buildField(ImagePicker, props)
export default makeLegacyField(ImagePicker, props)
