import {useState, useEffect} from 'react'
import {useEventListener, useMutationObserver} from 'hooks'
import {useApplicationHeaders} from 'common/components/headers/application_headers/state'

export const useTopOffset = ref => {
	// This hook should return the state's stored headers, without writing it to a new object this that would unnecessarily
	// trigger the dependency array below.
	const headers = useApplicationHeaders()

	const [offset, setOffset] = useState(0)
	const applicationHeaders = document.querySelector('.application-headers')
	const computeOffset = () => {
		const rect = ref.current.getBoundingClientRect()
		setOffset(rect.top + window.scrollY)
	}

	useEventListener('resize', computeOffset)

	// Also recheck the top offset when the height of the headers changes
	useMutationObserver({
		mutationFunction: computeOffset,
		observeOptions: {childList: true, subtree: true},
		element: applicationHeaders,
	})

	useEffect(() => {
		if (ref.current) {
			computeOffset()
		}
	}, [ref, headers])

	return Math.round(offset)
}
