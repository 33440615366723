import {t} from 'initializers/i18n'
import {useLastPathParam} from 'actions/routing'
import {Environment, Allowance} from 'models'
import {ModalForm, CompositeField} from 'components/form/index'
import {useModalContext} from 'components/common/'
import {useGet, useParams, useOrgSettings} from 'hooks'
import {Message, Layout, ListPicker, goToNext} from 'common/components'
import {Spin} from 'antd'
import {FormRow} from 'components/ui'
import {updateOrgSettings} from 'actions/settings'

const MapPayrollComponents = props => {
	const {id: connectionId} = useParams()

	const {nmbrs} = useOrgSettings()

	const id = useLastPathParam()

	const environment = Environment.find(id)
	const allowances = Allowance.where({environment_id: environment.id})

	const initialValues = {
		meal_code: environment.meal_code,
		allowances,
		...nmbrs,
	}

	const {item} = useModalContext()
	const submit = data => {
		const obj = _.pick(data, Object.keys(nmbrs))
		updateOrgSettings({nmbrs: obj})
		Allowance.multiUpdate(data['allowances'])
		return environment.update({meal_code: data['meal_code'], skipSuccessNotif: true, doLoad: false})
	}

	const {data, loading} = useGet(`environments/${id}/payroll_components`, {params: {connection_id: connectionId}})

	const {components = []} = data
	const mappedComponents = components.map(c => ({
		...c,
		category: t(`integrations.hr.payroll_components.${c.category}`, c.category),
		name: `${c.name} - code: ${c.code}`,
	}))
	const sharedProps = {
		single: true,
		showSearch: true,
		collapseGroups: true,
		groupField: item.payrollComponents?.group == false ? null : 'category',
		items: mappedComponents,
	}

	if (loading) return <Spin spinning />
	return (
		<ModalForm
			afterSubmit={() => goToNext(props)}
			transNamespace="integ_hr_map_components"
			submitInitialValues
			initialValues={initialValues}
			onSubmit={submit}
		>
			<Layout direction="vertical" name="modals.setup_hr.payroll_components" gap={0}>
				<Message t={{key: 'message', integ_name: item.name}} baseColor="blue" colorSet="color-bordered-fill" />

				<CompositeField compositeName="allowances" fields={['integration_payroll_component_id', 'id']}>
					<AllowanceDropdown allowances={allowances} {...sharedProps} />
				</CompositeField>
				<FormRow>
					<ListPicker dropdown label={t('common.meals')} {...sharedProps} valueField="code" field="meal_code" />
				</FormRow>

				{item.export?.showImproductive && (
					<>
						<FormRow>
							<ListPicker label={t('common.leave')} dropdown {...sharedProps} valueField="code" field="leave_code" />
						</FormRow>

						<FormRow>
							<ListPicker dropdown {...sharedProps} field="sick_code_flex" valueField="code" />
						</FormRow>

						<FormRow>
							<ListPicker dropdown {...sharedProps} field="sick_code_fixed" valueField="code" />
						</FormRow>
					</>
				)}
			</Layout>
		</ModalForm>
	)
}

const AllowanceDropdown = ({number, allowances, ...props}) => {
	const allowance = allowances[number - 1]
	return <ListPicker dropdown label={allowance.label()} field={`integration_payroll_component_id-${number}`} {...props} />
}

export default MapPayrollComponents
