import {backend} from '@eitje/easy_api'
import {
	LegacyDropdownPicker as DropdownPicker,
	LegacyInput as Input,
	LegacyDatePicker as DatePicker,
	LegacySwitch as Switch,
} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import Modal from 'components/general/modal'
import {submitNmbrs} from 'exports/actions'
import useExport from 'exports/use_export'
import useAsyncEffect from 'hooks/use_async_effect'
import useMergeState from 'hooks/use_merge_state'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../styles/export_hours.less'

const contractTypes = [
	{id: 1, name: 'Nul uren'},
	{
		id: 2,
		name: 'Uren',
	},
]

const makePeriodName = ({start, eind, period}) => {
	return `${utils.formatDate(start)} - ${utils.formatDate(eind)} || ${t('period')} ${period} `
}

const makePeriods = ({period = [], current_m, current_year}) => {
	return period.map(p => {
		return {
			id: `${p.year}-${p.period}`,
			name: makePeriodName(p),
		}
	})
}

const optionalKeys = ['worked_hours', 'amount_days', 'meals', 'allowances']
const fixedContractKeys = ['amount_days', 'meals']

const _submit = data => {
	if (!optionalKeys.some(s => data[s])) {
		utils.errNotif(t('oops'), t('fillInSomething'))
		return
	}
	return submitNmbrs(data)
}

const Nmbrs = props => {
	const [nmbrs, setNmbrs] = useState({})
	const [state, setState] = useMergeState()
	const {contract_type} = state
	const periods = makePeriods(nmbrs)
	const {id} = useParams()
	const {title, subtitle, downloadLink, imgSrc, opts} = useExport('NmbrsUpload')
	const form = useRef(null)

	let _optionalKeys = optionalKeys

	if (contract_type == 2) {
		_optionalKeys = fixedContractKeys
	}

	useAsyncEffect(async () => {
		const res = await backend.get(`environment/nmbrs`)
		if (res.ok) {
			const {nmbrs} = res.data
			setNmbrs(nmbrs)
			const {current_m, current_year, period = []} = nmbrs
			if (periods.find(p => p.year == current_year && p.period == Number(current_m))) {
				form.current.setValues({period: `${current_year}-${current_m}`})
			}
		}
	}, [])

	const afterSubmit = () => {
		utils.openNotif(t('prepFile'), 'info', t('nmbrsFinished'))
		history.goBack()
	}

	return (
		<Modal size="lg" onOk={() => form.current.submit()} title={t('exportToNmbrs')}>
			<div className="export-preview">
				<img src={imgSrc} className="export-preview-image" />

				<div className="export-header-container">
					<p className="export-header-title">{title}</p>
					<p className="export-header-subtitle">{subtitle}</p>
				</div>
			</div>

			<Form
				ref={form}
				setState={setState}
				mirrorFields={['contract_type']}
				fieldProps={{bordered: false}}
				initialValues={{worked_hours: true, contract_type: 1, amount_days: true}}
				onSubmit={_submit}
				afterSubmit={afterSubmit}
			>
				<DropdownPicker field="contract_type" label={'contract3'} required items={contractTypes} />
				<DropdownPicker noSort field="period" label={'period2'} required items={periods} />
				<div fieldWrapper className="form-row">
					{_optionalKeys.map(k => (
						<Switch style={{marginTop: 4}} field={k} />
					))}
				</div>
			</Form>
		</Modal>
	)
}

export default Nmbrs
