import {useEffect} from 'react'
import {setFilter} from 'hooks/use_filter'

const useSaveFiltersOnLeave = ({activeTeams, teams, filterName}) => {
	useEffect(() => {
		if (!_.isEqual(activeTeams, teams)) {
			setFilter(
				filterName,
				activeTeams.map(t => t.id),
			)
		} else {
			setFilter(filterName, [])
		}
	}, [activeTeams, teams])
}

export default useSaveFiltersOnLeave
