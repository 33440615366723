import utils from '@eitje/utils'
import {UserAvatar, UserImage} from 'components/ui'
import {ContractEnv, DeletedEnvs, TableTeams} from 'components/users'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import './styles/users.less'

export const renderCell = (item, field, idx) => {
	let toReturn
	const val = _.get(item, field)
	const props = {user: item}
	switch (field) {
		case 'avatar':
			return <UserAvatar size="sm" className="user-avatar" {...props} />
		case 'verjaardag':
			const age = date().diff(date(val), 'years')
			return (
				<span className="eitje-text-primary" style={{fontSize: 13}} {...props}>
					{date(val).format('DD-MM-YYYY')} <span className="eitje-text-secondary">({age})</span>{' '}
				</span>
			)
		case 'team_ids':
			return {label: <TableTeams className="teamsTable" {...props} />, value: val.length}
		case 'created_at':
			return date(val).format()
		case 'salary.amount':
			return val ? utils.money(val) : null
		case 'contract.environment_id':
			return val ? <ContractEnv id={val} /> : null
		case 'contract.end_date':
			if (!_.get(item, 'contract.start_date')) return null
			return val ? date(val).format('DD-MM-YYYY') : t('indefinite')
		case 'del_env_ids':
			return utils.exists(val) ? <DeletedEnvs ids={val} /> : null
		case 'workSchedule.totalMins':
			return utils.minToTimeString(val)
		case 'employmentType.name':
			return val ? t(val) : null
		case 'confirmed':
			return {
				value: val ? 2 : 1,
				label: val ? <p className="eitje-text-secondary">{t('yes')}</p> : <p className="eitje-text-secondary">{t('notActive')}</p>,
			}

		// // case 'roles':
		// //   return <Roles {...props} />
	}

	return toReturn
}

export const makeAbbr = ({full_name, name, naam}) => {
	const _naam = full_name || name || naam
	if (!_naam || typeof _naam !== 'string') return
	const nom = _naam.split(' ').slice(0, 2)
	if (nom.length > 1) {
		return nom
			.filter(i => i)
			.map(v => v[0].toUpperCase())
			.join('')
	} else {
		return _naam.slice(0, 2).toUpperCase()
	}
}
