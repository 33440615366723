import ActiveRecord from './active_record/base'

export class ShiftType extends ActiveRecord {
	static tableName = 'shiftTypes'

	nameWithEmoji() {
		if (this.emoji) {
			return `${this.emoji} ${this.name}`
		}
		return this.name
	}
}

export default ShiftType
