import {TABLE_INFO} from 'common/components/advanced_table/instances/users_in_service'
import {PageHeader as _PageHeader, useFilterFooterContext, EitjeButton} from 'common/components'
import {ColumnGroupingPickers} from 'common/components/advanced_table/consumers'
import {HelpButton} from './help_button'
import {Tools} from './tools'
import {RouteSelect} from './route_select'

export * from './help_button'
export * from './route_select'
export * from './tools'

const buttonProps = {iconLeft: 'plus', enableRole: 'manager'}

export const PageHeader = () => {
	return (
		<_PageHeader
			height="small"
			label="common.users_in_service"
			left={[
				<RouteSelect />,
				<EitjeButton t="common.team_members" modalLink="/forms/invite" {...buttonProps} />,
				<EitjeButton t="common.team" modalLink="/team/new" {...buttonProps} />,
			]}
			right={[<ColumnGroupingPickers {...TABLE_INFO} />, <Tools />, <HelpButton />]}
		/>
	)
}
