import {API, filterRecords, inverseFilterRecords} from '@eitje/easy_api'
import utils from '@eitje/web_utils'

const validateItems = items => {
	if (process.env.NODE_ENV == 'production') return
	// if (!items.every(i => i.activeRecordDescendant)) throw new Error('Association must be initialized with model instances')
	// if (items.map(i => i.constructor.name).uniq().length > 1) throw new Error('All assoc children must be of same type')
}

class Association extends Array {
	constructor(props) {
		const items = utils.alwaysArray(props)
		validateItems(items)
		super(...items)

		if (items.length > 0) {
			const modelClass = items[0].constructor
			Object.getOwnPropertyNames(modelClass).forEach(methodName => {
				if (
					typeof modelClass[methodName] === 'function' &&
					methodName !== 'length' &&
					methodName !== 'prototype' &&
					methodName !== 'name'
				) {
					this[methodName] = (...args) => modelClass[methodName](this, ...args)
				}
			})
		}
	}

	static get [Symbol.species]() {
		return this
	}

	ids() {
		return this.map(i => i.id)
	}

	multiUpdate(payload) {
		const tableName = this.first().snakeTableName()
		const params = this.map(i => ({id: i.id, ...payload}))
		return API.updateMulti(tableName, params)
	}

	where(query) {
		return new Association(filterRecords(this, query))
	}

	whereNot(query) {
		return inverseFilterRecords(this, query)
	}

	first = () => this[0]
	last = () => this[this.length - 1]

	tableName = () => this.first().tableName
}

export default Association
