import {useContract} from 'hooks/use_contract'
import {useSelector} from 'react-redux'
import {onToday} from 'selectors/records'

export function hasMissing(contract) {
	return Object.values(missingParts(contract)).some(val => !!val)
}

export function missingParts(contract) {
	const workWeekMissing = contract.employmentTypes.some(e => e.fixed) && contract.workSchedules.length == 0
	return {
		salary: contract.salaries.length === 0,
		workWeek: workWeekMissing,
	}
}

export function useMissingContract(user_id) {
	const holder = useSelector(state => onToday(state, 'contractHolders', {user_id}))[0]
	const contract = useContract(holder)
	if (!holder) return {}
	const _missingParts = missingParts(contract)
	return {..._missingParts, contract, holder, hasMissing: hasMissing(contract)}
}

export default useMissingContract
