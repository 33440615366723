import {t} from 'initializers/i18n'
import {Redirect} from 'components/routing'
import Drawer from 'components/general/drawer'
import {Route, Switch as RouteSwitch, useRouteMatch} from 'react-router-dom'
import Balance from './balance/index'
import Sick from './sick/index'
import './styles/index.less'
import {RouteToggle} from 'components/ui'

const Page = () => {
	const {url} = useRouteMatch()
	const balanceUrl = `${url}/balance`
	const sickUrl = `${url}/sick`
	return (
		<Drawer className="team-panel" width="504px">
			<Header balanceUrl={balanceUrl} sickUrl={sickUrl} />
			<RouteSwitch>
				<Route path={balanceUrl} render={props => <Balance {...props} />} />
				<Route path={sickUrl} component={props => <Sick {...props} />} />
				<Redirect to={balanceUrl} />
			</RouteSwitch>
		</Drawer>
	)
}

const Header = ({balanceUrl, sickUrl}) => {
	const items = [
		{path: balanceUrl, name: t('planning.team_panel.planned_hours')},
		{path: sickUrl, name: t('planning.team_panel.sick.title')},
	]

	return (
		<div className="team-panel-header">
			<h3>{t('planning.team_panel.title')} </h3>
			<RouteToggle raw items={items} modal />
		</div>
	)
}

export default Page
