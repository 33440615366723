import {CollapseChevron} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {formatCssVars, makeCnVariants} from 'helpers'
import {Layout} from 'common/components'
import {useAdvancedTableRoleMessage} from './role_check'
import './styles/index.less'
import {
	CellCheckbox,
	CollapsibleCell,
	GROUPED_CELL_WIDTH,
	DEFAULT_CELL_WIDTH,
	getLeftOffset,
	isLastPinnedColumn,
	renderCell,
	getPinnedColumns,
	TableText,
	handleExtLinkCellClick,
} from 'common/components/advanced_table'

export * from './constants'
export * from './components'
export * from './cells'
export * from './collapsible'
export * from './helpers'
export * from './role_check'

export const AdvancedTableCell = ({row, cell, index, multiEdit, ...rest}) => {
	const {column, getContext} = cell
	const {id: columnName, getIsPinned, getIsGrouped, columnDef} = column
	const {getIsExpanded, getCanExpand, subRows} = row
	const isGroupedColumn = getIsGrouped()
	const canExpand = getCanExpand()
	const context = getContext()
	const disabled = useAdvancedTableRoleMessage(cell)
	const styles = formatCssVars({'left-offset': getLeftOffset(index, context)})
	const lastPinned = isLastPinnedColumn(index, context)
	const showCollapseChevron = isGroupedColumn && canExpand
	const pinnedColumns = getPinnedColumns(context)
	const withCheckbox = multiEdit && pinnedColumns[0] === columnName
	const classNames = utils.makeCns(
		makeCnVariants('advanced-table-body-cell', getIsPinned() && 'pinned', lastPinned && 'last-pinned', disabled && 'disabled'),
		columnName,
	)
	cell.hasSubRows = utils.exists(subRows)

	const layoutProps = {
		height: 32,
		padding: 8,
		width: columnDef.width || (isGroupedColumn ? GROUPED_CELL_WIDTH : DEFAULT_CELL_WIDTH),
		horizontal: showCollapseChevron && 'last-item-end',
	}

	let content
	if (disabled) {
		content = '?'
	} else if (canExpand) {
		content = <CollapsibleCell isGroupedColumn={isGroupedColumn} row={row} cell={cell} />
	} else {
		content = <TableText>{renderCell(cell)}</TableText>
	}

	return (
		<Layout onClick={handleExtLinkCellClick} className={classNames} key={cell.id} {...layoutProps} style={styles} disabled={disabled}>
			{withCheckbox && <CellCheckbox row={row} cell={cell} />}
			{content}
			{showCollapseChevron && <CollapseChevron open={getIsExpanded()} />}
		</Layout>
	)
}
