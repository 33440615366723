import {RouteSelect} from 'components/routing'
import useSelector from 'hooks/use_selector'
import {roleOrgEnvsSelector} from 'selectors/new_settings'

export const EnvRouteSelect = ({envId, urlFormat, ...props}) => {
	const adminEnvs = useSelector(roleOrgEnvsSelector, 'admin')
	const items = adminEnvs.map(e => ({label: e.naam, value: urlFormat.replace(':envId', e.id)}))
	if (!items || items.length < 2) return null
	return <RouteSelect modal currentVal={urlFormat.replace(':envId', envId)} items={items} {...props} />
}
