import {useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {contractJoins as joins} from 'constants/users'
import {getHoliday} from 'helpers'
import {useOrgEnvSettingKeyValue} from 'hooks'
import {date} from 'initializers/date'
import {TimeRegistrationShift} from 'models'
import Allowance from 'models/allowance'

export const useWageCosts = ({shifts, revenueTeams}) => {
	const users = useAll('users', joins)
	const wageCosts = calcWageCosts(shifts, users, revenueTeams)
	return wageCosts
}

export const useSquashedWageCosts = props => {
	const wageCosts = useWageCosts(props)
	return utils.reduceObjects(Object.values(wageCosts))
}

const calcWageCosts = (shifts, users, revenueTeams) => {
	// => [{revenue_group_id: x, amount: y, hours: z} ] => should we add shift_ids?
	let wageCostObj = {}
	const filteredShifts = shifts.filter(s => s.user_id)
	const teams = useAll('teams')
	const allowances = Allowance.where({active: true})
	const employmentTypes = useAll('employmentTypes')
	const holidays = useOrgEnvSettingKeyValue('holidays', 'active')

	filteredShifts.forEach(s => {
		const user = users.find(u => u.id == s.user_id)
		const revTeams = getRevTeams(s, user, revenueTeams)
		const {costs} = calcShiftCost({shift: s, user, holidays, teams, allowances, employmentTypes})
		revTeams.forEach(rTeam => {
			const groupId = rTeam.omzet_groep_id
			if (!wageCostObj[groupId]) wageCostObj[groupId] = {omzet_groep_id: groupId, amount: 0, hours: 0}
			wageCostObj[groupId].amount += costs * (rTeam.fract / 100)
			wageCostObj[groupId].hours += s.totalHours() * (rTeam.fract / 100)
		})
	})
	return wageCostObj
}

const getRevTeams = (shift, user, revenueTeams) => {
	let revTeams = revenueTeams.filter(r => r.team_id == shift.team_id)

	if (revTeams.length == 0) {
		const firstTeam = revenueTeams[0]
		revTeams = revenueTeams.filter(t => t.team_id == firstTeam.team_id)
	}
	return revTeams
}

export const calcShiftCost = props => {
	const {user} = props
	if (!user) return {costs: 0}
	if (user.cost_per_hour) return calcCostPerHour(props)
	return calcSalary(props)
}

const getNearest = salaries => {
	const diffs = salaries.map(s => {
		const startDiff = date().diff(s.dateRange.start, 'days')
		const endDiff = date().diff(s.dateRange.end, 'days')
		return _.min(Math.abs(startDiff, endDiff))
	})
	const minIdx = diffs.indexOf(_.min(diffs))
	return salaries[minIdx]
}

const calcCostPerHour = props => {
	const {user, shift} = props
	const allowanceFactor = calculateAllowanceFactor(props) || 1
	const costPerHour = user.cost_per_hour * allowanceFactor

	const costs = costPerHour * shift.totalHours()
	return {costs, allowanceFactor, hourlyCost: user.cost_per_hour}
}

const calcSalary = props => {
	const {shift, user, employmentTypes} = props
	const momentDate = date(shift.date)
	const {salaries = []} = user
	let salary = user.salary(momentDate)
	if (!salary) {
		salary = getNearest(salaries)
	}
	if (!salary) return {costs: 0}
	const userEmpType = user.userEmploymentType(momentDate) || user.userEmploymentTypes?.[0]
	const empType = employmentTypes.find(e => e.id == userEmpType?.employment_type_id)
	const conversionFactor = empType?.conversion_factor || 1
	const allowanceFactor = calculateAllowanceFactor(props)
	const costPerHour = salary.amount * allowanceFactor
	const grossCosts = costPerHour * shift.totalHours()
	const costs = grossCosts * conversionFactor
	return {costs, grossCosts, wage: salary.amount, conversionFactor, allowanceFactor}
}

const calculateAllowanceFactor = ({shift, allowances, teams, holidays}) => {
	const {typ} = shift
	if (TimeRegistrationShift.improductiveTypes.includes(typ)) return 1

	const {functionAllowance, irregularModifier, holidayAllowance} = getAllowances({shift, allowances, teams, holidays})

	let allowanceModifier = 100
	if (functionAllowance) allowanceModifier += functionAllowance.amount

	if (holidayAllowance) {
		allowanceModifier += holidayAllowance.amount
		return allowanceModifier / 100
	}

	if (irregularModifier) {
		allowanceModifier += irregularModifier
	}
	return allowanceModifier / 100
}

const getAllowances = ({shift, allowances, teams, holidays}) => {
	const weekdays = shift.allDates().map(d => date(d).weekday())
	const envId = shift?.environment_id || teams.find(t => t.id === shift.team_id)?.environment_id
	allowances = allowances.where({environment_id: envId})

	const activeHolidays = holidays[envId]?.map(s => s.toLowerCase())
	const _holidayAllowance = allowances.find(a => !!a.is_holiday)
	const holiday = getHoliday(shift.date)?.toLowerCase()
	const isActiveHoliday = activeHolidays?.includes(holiday)

	const functionAllowance = shift?.allowance_id && allowances.find(a => a.id === shift.allowance_id)

	const irregularAllowances = allowances.filter(a => a.activeOnAnyDay(weekdays))

	let irregularModifier = 0
	irregularAllowances.forEach(allowance => {
		const modifier = allowance.irregularModifier(shift.actualDateRange())
		irregularModifier += modifier
	})

	const holidayAllowance = isActiveHoliday && _holidayAllowance
	return {functionAllowance, irregularModifier, holidayAllowance}
}

export default useWageCosts
