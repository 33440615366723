import _RangePicker from 'components/date/range_picker'
import {date} from 'initializers/date'
import {useLeaveContext} from 'cores/leave'
import {ListPicker} from 'common/components'
import {useShared} from 'hooks'

// This component acts as a decider and depends on the value set by PeriodTypePicker
export const PeriodPicker = props => {
	const {period} = useLeaveContext()

	if (period === 'custom') return <RangePicker {...props} />
	if (period === 'past') return <YearPicker {...props} />
	return null
}

const RangePicker = ({...rest}) => {
	const {startDate, endDate, setPeriod} = useLeaveContext()

	const onChange = dates => {
		const startDate = dates[0].format()
		const endDate = dates[1].format()
		setPeriod('custom', {startDate, endDate})
	}

	return <_RangePicker value={[startDate, endDate]} onChange={onChange} allowClear={false} {...rest} />
}

const YearPicker = ({...rest}) => {
	const {org} = useShared()
	const {year, setPeriod} = useLeaveContext()

	// Only show years in which the organisation existed as performant & easy proxy for years which may contain leave requests.
	const orgCreatedYear = date(org.created_at).year()
	const currentYear = date().year()
	const yearsAmount = currentYear - orgCreatedYear + 1

	const years = Array.from({length: yearsAmount}).map((_, i) => {
		const year = date().year() - i
		return {value: year, label: year.toString()}
	})

	const changeYear = newYear => {
		if (year === newYear) return
		setPeriod('past', {year: newYear})
	}

	return (
		<ListPicker
			noSort
			single
			raw
			dropdown
			valueField="value"
			labelField="label"
			items={years}
			value={year}
			onChange={changeYear}
			{...rest}
		/>
	)
}
