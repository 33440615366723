import {t} from 'initializers/i18n'
import Drawer from 'components/general/drawer'

const Audits = () => {
	return (
		<Drawer title={t('history')} width={300}>
			<p>yo </p>
		</Drawer>
	)
}

export default Audits
