import {Input} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {usePlanningTeams, usePlanningDates} from 'cores/planning/index'
import {FormSimpleInputNumber} from 'lib/form/fields/simple_input_number'
import UserShiftCard from './user_shift_card'
import OpenShiftCard from './open_shift_card'
import TradeRow from './trade_row'
import '../styles/body.less'
import {WeekDaysPicker, BreaktimePicker, MealPicker} from 'components/shared/index'
import useFormData from 'hooks/use_form_data'
import {ShiftTimePicker} from 'common/components/shift_time_picker'
import ShiftTypePicker from 'common/components/shift_type_picker'
import {useRoleOrgEnvs} from 'hooks'
import {makeCnVariants} from 'helpers'
import {useForm} from '@eitje/form'
import User from 'models/user'
import {SkillSet} from 'models'
import {AllowancePicker, EitjeButton, ListPicker} from 'common/components'
import {CreateButton} from 'common/components/eitje_button/eitje_buttons'

const getPlanningTableWeekDays = shiftDate => {
	const startOfWeek = shiftDate.clone().startOf('week')
	return _.times(7, i => startOfWeek.clone().add(i, 'days'))
}

const Body = ({env, shift, date, user, hideUserPanel, planningTablePage}) => {
	// Correct the dateArray for the planning table page, since that has no relation with the weekly date range
	// of the other planning pages.
	let {dateArray} = usePlanningDates()
	if (planningTablePage) {
		dateArray = getPlanningTableWeekDays(date)
	}

	const teams = usePlanningTeams(env.id)
	const {user_id, team_id} = useFormData()
	const managerEnvs = useRoleOrgEnvs('manager')
	const {setValues} = useForm()
	const userEnvIds = User.find(user_id)?.environment_ids
	const environments = user_id ? managerEnvs.filter(env => userEnvIds?.includes(env.id)) : managerEnvs
	const skillSets = SkillSet.where({planning_active: true, team_ids: team_id})

	const handleSubmitTradeRequest = userId => {
		setValues({user_id: userId})
	}

	const multipleEnvs = managerEnvs.length > 1

	// This need to be in the render or else type 'type' of the button component is undefined
	const placeholderProps = {
		name: 'shift_function',
		subtitle: false,
		animation: 'food-delivery',
		children: <CreateButton link="/skills" t="action_button" width="full" />,
	}

	const classNames = makeCnVariants('planning-shift-form-body', hideUserPanel && !shift?.reg_shift_id && 'full-width')
	return (
		<div className={classNames}>
			<FormRow>
				<div className="eitje-form-2-double-field">
					<WeekDaysPicker dateArray={dateArray} single={shift} minLength={1} required />
					<FormSimpleInputNumber disabled={!!user_id || shift} disableLoop min={1} field="amt_shifts" max={10} />
				</div>
				{user?.id ? (
					<UserShiftCard shift={shift} env={env} user={user} date={date} planningTablePage={planningTablePage} />
				) : (
					<OpenShiftCard shift={shift} />
				)}
			</FormRow>

			{shift?.trade_request_id && shift.startsInFuture() && <TradeRow id={shift?.trade_request_id} afterSucc={handleSubmitTradeRequest} />}

			<FormRow>
				<ShiftTimePicker required field="from" />
				<ShiftTimePicker required field="till" />
			</FormRow>

			<FormRow>
				{multipleEnvs && (
					<ListPicker required dropdown single labelField="naam" label={t('environment')} field="env_id" items={environments} />
				)}
				<ListPicker required field="team_id" labelField="naam" items={teams} dropdown single placement={multipleEnvs && 'left'}>
					<EitjeButton modalLink={`/team/new?environment_id=${env.id}`} width="full" iconLeft="plus" t="common.add_team" />
				</ListPicker>
			</FormRow>

			<FormRow>
				<BreaktimePicker />
				<ShiftTypePicker />
			</FormRow>
			<FormRow>
				<Input textarea field="remarks" autoSize={{minRows: 1, maxRows: 6}} />
				<AllowancePicker environmentId={env.id} />
			</FormRow>
			<FormRow>
				<MealPicker envId={env.id} />
				<ListPicker triggerVisible dropdown single field="skill_set_id" items={skillSets} placeholderProps={placeholderProps}>
					<EitjeButton tabIndex={-1} width="full" link="/skills" iconLeft={null}>
						{t('openFunctions')}
					</EitjeButton>
				</ListPicker>
			</FormRow>
		</div>
	)
}

export default Body
