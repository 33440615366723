export * from './subscription'
export * from './formatting'
import {date} from 'initializers/date'
import {rangeForPeriod, makeRange} from 'helpers/date'

const getWagePeriodRange = ({tense, periods}) => {
	let period
	if (tense == 'next') period = periods[periods.length - 1]
	if (tense == 'current') period = periods[periods.length - 2]
	if (tense == 'previous') period = periods[periods.length - 3]
	return makeRange(period.start, period.eind)
}

export const getDatesFromPeriod = ({tense, period, uren, nul_uren}) => {
	if (period == 'flex_wage_period' || period == 'hours_wage_period') {
		const per = period == 'flex_wage_period' ? nul_uren : uren
		return getWagePeriodRange({tense, periods: per})
	}
	const now = date()
	const method = tense == 'previous' ? 'subtract' : 'add'
	if (tense != 'current') now[method](1, period)
	return rangeForPeriod({date: now, period})
}
