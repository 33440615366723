import {AttachmentPreviewList as StreamAttachmentPreviewList} from 'stream-chat-react'
import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'
import {normalizeAttachment} from 'cores/chat'

const attachmentIcons = {
	audio: 'musical-note',
	file: 'paperclip',
	image: 'picture',
	video: 'film',
}

// align upload and close icon right
const inlineStyles = {marginLeft: 'auto', cursor: 'pointer'}

const Attachment = ({attachment, removeAttachments}) => {
	const {type, title, metadata} = normalizeAttachment(attachment)
	const {id, uploadState} = metadata

	const isUploading = uploadState === 'uploading'
	const isFailed = uploadState === 'failed'

	const removeAttachment = () => removeAttachments(id)

	return (
		<Layout
			colorSet={isFailed && 'color-bordered-color-content'}
			baseColor={isFailed && 'red'}
			padding="8 12"
			width="full"
			border
			borderRadius
			gap={8}
			height={40}
		>
			<Icon name={attachmentIcons[type]} />
			<Text truncate truncateTooltip={false}>
				{title}
			</Text>
			{isUploading && <Icon className="loading-spinner" name="arrow-circle-right" style={inlineStyles} />}
			{!isUploading && <Icon name="cross" onClick={removeAttachment} style={inlineStyles} />}
		</Layout>
	)
}

export const AttachmentPreviewList = props => {
	return (
		<StreamAttachmentPreviewList
			{...props}
			FileAttachmentPreview={Attachment}
			ImageAttachmentPreview={Attachment}
			AudioAttachmentPreview={Attachment}
			VideoAttachmentPreview={Attachment}
		/>
	)
}
