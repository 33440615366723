import {useForm} from '@eitje/form'
import {useAsyncEffect} from '@eitje/react-hooks'
import {useFormData, usePrevious} from 'hooks'
import _ from 'lodash'
import React, {useEffect} from 'react'
import {FormRow} from 'components/ui'

export const CompositeField = ({
	children,
	fields = [],
	rowIdentifier = fields[0],
	compositeName,
	className,
	onDel,
	submitRows,
	autoAdd,
	initialAmount,
	...props
}) => {
	const formData = useFormData()
	const {setValues, removeValues, submit} = useForm()
	const rows = Object.keys(formData).filter(field => field.includes(rowIdentifier))
	const prevFormData = usePrevious(formData)
	const rowKeys = fields
	const compositeField = formData[compositeName]
	const prevCompositeField = usePrevious(compositeField)

	useEffect(() => {
		if (initialAmount) {
			const initialValue = Array.from({length: 3}, () => ({[rowIdentifier]: ''}))
			setValues({[compositeName]: initialValue})
		}
	}, [])

	useEffect(() => {
		if (!prevCompositeField || compositeField.length > prevCompositeField.length) {
			const formFields = {}
			compositeField?.forEach((row, i) => {
				rowKeys.forEach(field => (formFields[`${[field]}-${i + 1}`] = row[field]))
			})

			!_.isEmpty(formFields) && setValues(formFields)
		}
	}, [formData, compositeField?.length, prevCompositeField?.length])

	useEffect(() => {
		if (!autoAdd) return
	}, [formData])

	useAsyncEffect(async () => {
		if (!_.isEqual(filterComposites(prevFormData, compositeName), filterComposites(formData, compositeName))) {
			const updatedValue = []
			let nextRow
			rows.forEach(row => {
				const number = getNumber(row)
				const rowFields = {}
				rowKeys.forEach(field => (rowFields[field] = formData[`${field}-${number}`]))
				updatedValue.push(rowFields)
			})

			if (autoAdd) {
				const lastRowIdentifierKey = `${rowIdentifier}-${rows.length}`
				if (formData[lastRowIdentifierKey]?.length && !prevFormData?.[lastRowIdentifierKey]?.length) {
					const newRowNumber = rows.length + 1
					nextRow = {[`${rowIdentifier}-${newRowNumber}`]: ''}
				}
			}

			if (updatedValue.length || nextRow) {
				await setValues({[compositeName]: updatedValue, ...nextRow})
			}

			if (updatedValue.length > compositeField?.length) {
				submitRows && submit({skipAfterSubmit: true})
			}
		}
	}, [formData])

	const removeFromForm = number => {
		const removeKeys = Object.keys(formData).filter(key => key.endsWith(`-${number}`))
		removeValues(removeKeys)
	}

	const handleDelete = number => {
		const listItem = compositeField.find(row => row[rowIdentifier] === formData[`${rowIdentifier}-${number}`])
		if (onDel && listItem?.id) {
			return onDel?.(listItem.id).then(res => {
				res.ok && removeFromForm(number)
			})
		}
		removeFromForm(number)
	}

	const rowsWithProps = rows.map((row, i) =>
		React.cloneElement(children, {
			...props,
			number: getNumber(row),
			handleDelete,
			isFirst: i === 0,
		}),
	)

	if (className) return <div className={className}>{rowsWithProps}</div>

	return (
		<>
			{rowsWithProps.map(r => (
				<FormRow> {r} </FormRow>
			))}
		</>
	)
}

const getNumber = row => parseInt(row.split?.('-')[1])

const filterComposites = (obj, compositeName) => _.pickBy(obj, (_, key) => !key.includes(compositeName))

export const getNextAvailableNumber = formData => {
	const keyNumbers = Object.keys(formData)
		.map(field => getNumber(field))
		.filter(field => !_.isNaN(field))
	return _.max([...keyNumbers, 0]) + 1
}
