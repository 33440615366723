import {AuthPageLayout} from 'cores/auth'
import {Input} from '@eitje/form-fields-web'
import {useQueryParams} from 'hooks'
import {API} from '@eitje/easy_api'
import {login} from 'actions/auth'
import {PasswordInput, PasswordConfirmInput} from 'lib/form/fields'
import {Redirect} from 'components/routing'
import {isEmail} from 'lib/form/validation'

export const ActivateAccount = () => {
	const {user_id: id, email} = useQueryParams()
	const onSubmit = data => API.update('users', {id, skipSuccessNotif: true, ...data})
	const afterSubmit = ({params}) => {
		const {email, password} = params
		login(email, password, '/user_info')
	}

	// If no email is present in the query param, we want to force users to use the welcome form, so that they may be redirected to the appropriate
	// place and because below email is readOnly for the same reason.
	if (!email || !isEmail(email) || !id) return <Redirect to="/" />

	return (
		<AuthPageLayout
			name="activate_account"
			currentStep={1}
			steps={2}
			backButton
			onSubmit={onSubmit}
			afterSubmit={afterSubmit}
			initialValues={{email}}
		>
			<Input field="email" required readOnly />
			<PasswordInput />
			<PasswordConfirmInput />
		</AuthPageLayout>
	)
}
