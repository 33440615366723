import {NavItemLayout, titleWithCount} from './nav_item'
import {useSelector} from 'react-redux'
import {t} from 'initializers/i18n'
import {useHasRoleInAnyEnv, useShared, usePusher} from 'hooks'
import {InboxPopout} from 'cores/firm'
import {useOperations} from 'cores/firm/pages/inbox'
import {Operation} from 'models'

export const Notifs = () => {
	const ops = useOperations()
	const unreadCount = ops.filter(op => op.id && !op.read).length
	const title = titleWithCount(t('header.notification_center'), unreadCount)
	const anyManager = useHasRoleInAnyEnv('manager')
	const anyApproveHours = useHasRoleInAnyEnv('uren_accorderen')
	const anyFinancial = useHasRoleInAnyEnv('financieel')
	const show = anyManager || anyApproveHours || anyFinancial
	const {env} = useShared()

	usePusher(`${env.id}-inbox`, 'operation', () => Operation.index())

	if (!show) return null
	return (
		<InboxPopout>
			<NavItemLayout title={title} modalLink="/inbox/all" />
		</InboxPopout>
	)
}
