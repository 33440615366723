import {useState} from 'react'
import {useChatContext} from 'stream-chat-react'
import {useAsyncEffect} from 'hooks'
import {getOtherChannelMembers} from 'cores/chat'

export const useGetChannel = fullChannelId => {
	const {client} = useChatContext()
	const [channel, setChannel] = useState()

	useAsyncEffect(async () => {
		const channels = await client.queryChannels({cid: fullChannelId})
		setChannel(channels[0])
	}, [fullChannelId])

	return channel
}

export const useGetOtherChannelMembers = (channel, meId) => {
	const [members, setMembers] = useState()

	useAsyncEffect(async () => {
		if (!channel) return
		const members = await getOtherChannelMembers(channel, meId)
		setMembers(members)
	}, [channel])

	return members
}
