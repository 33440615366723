import ButtonSelect from 'lib/form/fields/button_select'

const WeekDaysPicker = ({dateArray, dateIsDisabled = _.noop, ...props}) => {
	const weekdayItems = dateArray.map(d => {
		const date = d.format()
		return {id: date, disabled: dateIsDisabled(date), name: d.format('dd')[0]}
	})

	// singleIsArray is used here because we always want days to be returned as an array, even if we only allow selection of one
	// this is just easier in handling, as the places that use this sometimes allow for multiple & sometimes for single

	return <ButtonSelect singleIsArray small minLength={1} noSort field="dates" items={weekdayItems} {...props} />
}

export default WeekDaysPicker
