import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import {date} from 'initializers/date'
import {makeRange} from 'helpers/date'
import {LeaveRequest} from 'models'
import {store} from 'index'
import {useLeaveDates} from 'cores/leave'

const LeaveContext = createContext({})
const {Provider} = LeaveContext

export const LeaveProvider = ({children}) => {
	const data = useLeaveDates()
	const {year, startDate, endDate, period, setPeriod} = data
	const dateRange = makeRange(date().startOf('year'), date().endOf('year'))
	const value = {dateRange, year, period, setPeriod, startDate, endDate}
	const filters = getFilters(data)

	useEffect(() => getData(filters), [year, period, startDate, endDate])

	return <Provider value={value}> {children} </Provider>
}

const getFilters = ({period, year, startDate, endDate}) => {
	if (period == 'past') return {year}
	if (period == 'custom') return {start_date: startDate, end_date: endDate}
	return {period}
}

const getData = async filters => {
	store.dispatch({type: 'START_LOADING'})
	await LeaveRequest.index({filters})
	store.dispatch({type: 'STOP_LOADING'})
}

export default LeaveProvider

export const useLeaveContext = () => useContext(LeaveContext)
