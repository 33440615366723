import {PageLayout, Text, Title, Layout, DemoButton, ChatButton, CallButton} from 'common/components'
import {ExtLink} from 'components/routing'
import {OnboardingProgress} from 'cores/onboarding'
import {NAMESPACE} from '.'

export const PageHeader = () => {
	return (
		<Layout width="full" padding="40 - 12 -" horizontal="spaceBetween" borderBottom>
			<Layout direction="vertical">
				<Title t={`${NAMESPACE}.title`} fontSize={32} />
				<OnboardingProgress />
			</Layout>
			<Layout direction="vertical" horizontal="end">
				<Layout>
					<ChatButton />
					<CallButton showPhoneNumberTooltip />
					<DemoButton colorSet="solid" t={`${NAMESPACE}.free_training`} />
				</Layout>
			</Layout>
		</Layout>
	)
}
