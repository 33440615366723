import {PrivateRoute, ProtectedRoute} from 'components/private_route'

import {PerWeek, PerMember, Settings, Meals, New, Edit, MultiEdit, HandleErrors} from 'cores/time_registration/index'

const timeRegistrationForegroundRoutes = [
	<ProtectedRoute path="/hours/per_week" component={PerWeek} roleInAnyEnv="uren_schrijven" fbPath="/user_uren" />,
	<ProtectedRoute path="/hours/per_member" component={PerMember} roleInAnyEnv="uren_schrijven" fbPath="/user_uren" />,
]
const timeRegistrationBackgroundRoutes = [
	<ProtectedRoute path="/time_registration/:envId/settings" role="admin" exact component={Settings} />,
	<ProtectedRoute path="/time_registration/:envId/settings/meals" role="admin" component={Meals} />,
	<ProtectedRoute path="/time_registration/multi_edit" role="uren_schrijven" component={MultiEdit} />,
	<ProtectedRoute path="/time_registration_shifts/handle_errors" role="uren_accorderen" component={HandleErrors} />,
	<ProtectedRoute path="/time_registration/new" roleInAnyEnv="uren_schrijven" component={New} exact />,
	<PrivateRoute path="/time_registration/:id" component={Edit} />,
]

export {timeRegistrationBackgroundRoutes, timeRegistrationForegroundRoutes}
