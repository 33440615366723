import {useFilteredShifts} from 'cores/time_registration'
import Association from 'models/active_record/association'
import {useMemo} from 'react'

export const useNonPlanShifts = () => {
	const {regShifts, checkIns} = useFilteredShifts()
	const nonPlanShifts = useMemo(() => new Association([...regShifts, ...checkIns]), [regShifts, checkIns])

	return nonPlanShifts
}
