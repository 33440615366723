import useSelector from 'hooks/use_selector'
import {roleSelector} from 'selectors/new_settings'
import {anyEnvRoleSelector, roleOrgEnvsSelector} from 'selectors/new_settings'

export const useHasRole = (roleName, envId) => {
	return useSelector(roleSelector, roleName, envId)
}

export const useRoleEnvs = roleName => {
	return useSelector(roleOrgEnvsSelector, roleName)
}
export const useHasRoleInAnyEnv = (role, ids) => useSelector(anyEnvRoleSelector, role, ids)

export default useHasRole
