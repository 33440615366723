import {API} from '@eitje/easy_api'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import {history} from 'index'
import {t} from 'initializers/i18n'
import {SettingsForm} from 'pages/v3/user/contracts/modal/settings'
import React, {useRef} from 'react'
import './styles/employment_type.less'

const EmploymentTypeForm = () => {
	const {id} = useParams()
	const form = useRef(null)
	return (
		<Modal wrapClassName="settings-employment-type-modal" onOk={() => form.current.submit()} width={520} title={t('contractInfo')}>
			<SettingsForm
				showWage
				afterSubmit={history.goBack}
				ref={form}
				hideSubmit
				id={id}
				onSubmit={data => API.update('employment_types', data)}
			/>
		</Modal>
	)
}

export default EmploymentTypeForm
