import {API, find} from '@eitje/easy_api'
import useParams from 'hooks/use_params'
import {t} from 'initializers/i18n'
import React from 'react'
import {useSelector} from 'react-redux'
import {navigateModal} from 'actions/routing'
import {anyEnvRoleSelector} from 'selectors/new_settings'
import '../styles/quiz.less'
import {DeleteButton, OptionsButton, EitjeButton, Layout} from 'common/components'

const PADDING = [16, 24]

// Display a quiz item in a topic along with articles
export const QuizItem = ({item, ...props}) => {
	const {id} = useParams()
	const topic = useSelector(state => find(state, 'topics', id))
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager', topic.environment_ids))

	const handleClick = () => {
		isManager && navigateModal(`${topic.id}/quiz_items/${item.id}`)
	}

	return (
		<Layout {...props} onClick={handleClick} className={'data-display-row'} horizontal="spaceBetween" padding={PADDING} gap={0}>
			<Layout direction="vertical" grow gap={0}>
				<p className="eitje-text-secondary">{t('question')}</p>
				<h4>{item.question} </h4>
			</Layout>
			{isManager && (
				<Layout width="8%">
					<OptionsButton body={<QuizPopover item={item} />} />
				</Layout>
			)}
		</Layout>
	)
}

// Modal is in v3/manual/quiz_items/new.js
const QuizPopover = ({item}) => {
	const {topic_resource_id} = item
	return (
		<Layout direction="vertical" onClick={e => e.stopPropagation()}>
			<EitjeButton width="full" modalLink={`quiz_items/${item.id}`} t="edit" />

			<DeleteButton
				onDel={e => {
					e.stopPropagation()
					return API.destroy('topic_resources', topic_resource_id)
				}}
				afterDel={() => API.index('topics')}
			/>
		</Layout>
	)
}
