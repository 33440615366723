import React, {createContext, useContext, useMemo} from 'react'

const TeamDayContext = createContext()
const {Provider} = TeamDayContext

const TeamDayProvider = ({date, children}) => {
	const val = useMemo(() => ({date}), [date.format()])
	return <Provider value={val}> {children} </Provider>
}

export const useTeamDayContext = () => {
	return useContext(TeamDayContext)
}

export default TeamDayProvider
