import utils from '@eitje/web_utils'

const getTotals = ({regShifts, planShifts}) => {
	const totalWorked = regShifts.sum(s => s.totalMins())
	const totalPlanned = planShifts.sum(s => s.totalMins())
	const totalDiff = totalWorked - totalPlanned
	return {
		totalWorked,
		totalWorkedString: utils.minutesToString(totalWorked),
		totalDiffString: utils.minutesToString(totalDiff),
		totalPlanned,
		totalPlannedString: utils.minutesToString(totalPlanned),
		totalDiff,
	}
}

export default getTotals
