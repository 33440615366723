import {writeStore} from 'common/components/advanced_table'

export const pinOrUnpinColumn = ({tableName, header, pinnedColumns}) => {
	const {column, id: columnName} = header
	const {getIsPinned, pin} = column
	const isPinned = getIsPinned()

	let pinned
	if (isPinned) {
		// meaning the column should be unpinned
		pin(false)
		pinned = pinnedColumns.filter(c => c !== columnName)
	} else {
		// The column should be pinned to the left side
		pin('left')
		pinned = [...pinnedColumns, columnName]
	}
	writeStore(tableName, {pinned})
}
