import {Input, BaseInput, LegacyInput} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {makeField, makeLegacyField} from '@eitje/form'

const maxTwoDigits = /(,\d{1,2})\d*/g
const maxFourDigits = /(,\d{1,4})\d*/g
const maxOneComma = /(,.*?),.*/g
const thousandSep = /\B(?=(\d{3})+(?!\d))/g
const leadingComma = /^,|^\./g
const trailingDot = /\.$/g
const leadingZero = /^0*/g
const twoLeadingZeros = /^00*/g

const dotBeforeMaxTwoNums = /\.(\d{1,2})/g

const allDots = /[\.*]/g
const allCommas = /[,*]/g

const formatNum = (val, maxDigits = maxFourDigits) => {
	val = val.toString()
	return val
		.replace(trailingDot, ',')
		.replace(dotBeforeMaxTwoNums, ',$1')
		.replace(/\.*/g, '')
		.replace(maxOneComma, '$1')
		.replace(maxDigits, '$1')
		.replace(twoLeadingZeros, '0')
}
const formatMoney = val => val.replace(thousandSep, '.')
const formatAll = val => formatMoney(formatNum(val, maxTwoDigits))

const toNum = (val, replaceLeadingZero, min, max) => {
	let parsed = val.replace(/[^0-9,.]/g, '').replace(leadingComma, '')
	if (replaceLeadingZero) {
		parsed = parsed.replace(leadingZero, '')
	}
	parsed = parsed.replace(trailingDot, ',').replace(allDots, '').replace(allCommas, '.')
	if ((min && parsed < min) || (max && parsed > max)) return NaN
	return parsed
}

const InputNumber = props => {
	let {financial, maxDigits, min, max, value} = props
	if (max) max = Number(max)
	if (min) min = Number(min)
	const _val = value || ''

	const val = financial ? formatAll(_val) : formatNum(_val, maxDigits == 2 ? maxTwoDigits : maxFourDigits)
	let condOpts = {}
	// if (!financial) {
	//   // financial needs comma's and all, therefore cant be converted to num
	//   condOpts['onChange'] = val => props.onChange(Number(val))
	//   condOpts['value'] = Number(val)
	// }

	// NOTE: if we want to use InputNumber for new components (not sure, its kinda fucked), we should create another variant with Input & not LegacyInput

	return (
		<>
			<LegacyInput
				className="eitje-input-number"
				prefix={financial ? '€' : ''}
				formatValue={val => toNum(val, financial, min, max)}
				{...props}
				{...condOpts}
				displayValue={val}
			/>
		</>
	)
}

InputNumber.defaultProps = {defaultSubmitStrategy: 'blur'}

const NewInputNumber = props => {
	const {financial, maxDigits, onChange, min, max, value} = props
	const _val = value || ''

	const val = financial ? formatAll(_val) : formatNum(_val, maxDigits == 2 ? maxTwoDigits : maxFourDigits)
	let condOpts = {}

	return (
		<>
			<BaseInput
				className="eitje-input-number"
				prefix={financial ? '€' : ''}
				formatValue={val => toNum(val, financial, min, max)}
				{...props}
				{...condOpts}
				value={val}
			/>
		</>
	)
}

const FormInputNumber = makeLegacyField(InputNumber, {className: 'eitje-input-number-container'})
const NewFormInputNumber = makeField(NewInputNumber, {className: 'eitje-input-number-container'})

const SalaryInput = props => <InputNumber warning={value => value > 50 && t('bigWage')} financial {...props} />

export {SalaryInput, FormInputNumber, InputNumber, NewFormInputNumber}

export default InputNumber

export {NewInputNumber}
