import {useGet} from 'hooks'
import {date} from 'initializers/date'
import {usePlanningDates} from 'cores/planning'
import {formatMapping, getPeriodType} from 'helpers/date'

const generatePeriodList = (periodType, amount, startDate) =>
	[...new Array(amount)].map((_, i) => date(startDate).clone().add(i, periodType).startOf(periodType))

export const useDateCounts = ({team_ids, periodsAmounts, isDraft, is_week}) => {
	const {startDate} = usePlanningDates()
	const period_type = getPeriodType(is_week)

	const periodLists = {
		week: generatePeriodList('week', periodsAmounts.week, startDate),
		month: generatePeriodList('month', periodsAmounts.month, startDate),
	}

	const formatYearPeriods = () => {
		let formattedPeriodObject = {}
		Object.keys(periodLists).forEach(periodType => {
			const yearPeriod = periodType === 'week' ? 'year_weeks' : 'year_months'
			formattedPeriodObject[yearPeriod] = periodLists[periodType].map(dateObject => dateObject.format(formatMapping[periodType]))
		})
		return formattedPeriodObject
	}

	const {data} = useGet(`planning_shifts/date_counts`, {
		params: {planning_shift: {...formatYearPeriods(), team_ids, draft: isDraft}},
		watch: team_ids?.join(),
	})

	const periodData = data && data[`year_${period_type}s`]

	return {data, periodLists, periodData}
}
