import {RawCheckbox as Checkbox} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import useList from 'hooks/use_list'
import {date} from 'initializers/date'
import {AmtWorked} from 'cores/planning/index'
import {ConfirmButton, DeleteButton, Message, Text, Layout} from 'common/components'
import {makeCnVariants} from 'helpers'
import {Label} from 'common/components'
import './styles/approve_pickup.less'
import {EitjeAvatar} from 'common/components'

const ApprovePickup = ({item, afterSucc, hideAmtWorked}) => {
	const {bieder_map, potential_ruiler_ids, bieder_ids, afwijzer_ids} = item
	const bidderMap = _.orderBy(
		Object.keys(bieder_map).map(id => ({id, created_at: bieder_map[id]})),
		'created_at',
	)

	const nonAcceptedUsers = [...afwijzer_ids, ...potential_ruiler_ids.filter(id => !bieder_ids.includes(id))]

	const {list: acceptedList, activeItem} = useList({
		allowDeactivate: true,
		items: bidderMap,
		Placeholder,
		itemProps: {date: item.date, hideAmtWorked},
		ListItem: AcceptedBid,
		def: bidderMap.length == 1 ? bidderMap[0].id : null,
	})
	const {list: notAcceptedList} = useList({
		items: nonAcceptedUsers,
		Placeholder: OtherPlaceholder,
		ListItem: NotAcceptedBid,
		itemProps: {rejectedIds: afwijzer_ids},
	})

	const submitTrade = () => item.approve(Number(activeItem.id), afterSucc)

	return (
		<Layout direction="vertical" className="approve-pickup">
			<Layout grow direction="vertical" className="approve-pickup-body" width="100%">
				<Text bold> {t('selectMember')} </Text>
				{acceptedList}
				<Text bold> {t('common.other_team_members')} </Text>
				{notAcceptedList}
			</Layout>
			<Layout childrenGrow padding={8} className="approve-pickup-footer">
				<DeleteButton t="planning.trade.delete_trade" colorSet="solid" onClick={() => item.destroy()} width="full" />
				<ConfirmButton t="common.schedule_member" colorSet="solid" onClick={submitTrade} disabled={!activeItem} width="full" />
			</Layout>
		</Layout>
	)
}

const baseColorMapping = {
	rejected: 'pink',
	unknown: 'grey',
}

const POSITION = ['-', 12, '-', '-']

const NotAcceptedBid = ({item, rejectedIds}) => {
	const user = useFind('users', item)
	if (!utils.exists(user)) return null
	const status = rejectedIds.includes(item) ? 'rejected' : 'unknown'
	const classNames = makeCnVariants('bid', 'not-accepted')
	return (
		<Layout horizontal="last-item-end" className={`${classNames} ${status}`} gap={8}>
			<EitjeAvatar user={user} />
			<h5 className="bid-user-name"> {user.full_name} </h5>
			<Label baseColor={baseColorMapping[status]} t={`planning.trade.${status}`} absolute={POSITION} />
		</Layout>
	)
}

const AcceptedBid = ({item, _date, isActive, hideAmtWorked}) => {
	const user = useFind('users', item.id)
	if (!utils.exists(user)) return null
	const classNames = makeCnVariants('bid', 'accepted')
	return (
		<div className={classNames}>
			<EitjeAvatar user={user} />
			<div className="accepted-bid-info">
				<h5 className="bid-user-name"> {user.full_name} </h5>
				<h6 className="accepted-bid-timestamp"> {t('planning.trade.accepted_on', {dateTime: item.created_at})} </h6>
				{!hideAmtWorked && <AmtWorked date={date(_date)} user={item} />}
			</div>
			<Checkbox value={isActive} />
		</div>
	)
}

const MARGIN = [8, 0]

const Placeholder = () => <Message margin={MARGIN} t="common.no_team_member_to_pick" />
const OtherPlaceholder = () => <Message margin={MARGIN} t="noAvailableUsers" />

export default ApprovePickup
