import {COLUMNS_WITH_TOTAL_AS_CHILD, getCell, getTranslatedPlaceholder} from 'common/components/advanced_table'
import {BaseCollapsibleCell} from './base'
import {getCellComponent} from '.'
import {t} from 'initializers/i18n'

// If one of the columns is grouped, it has a special collapsible cell, since A) it should always show a collapsible value
// (otherwise you wouldn't see on what there is grouped) and B) there should always be specific count visible, suffixed to
// the name (the amount of sub rows, NOT the sum of the values of the sub rows).

// Prevent the grouped column (with its member count) from having its count overlap the chevron icon
const inlineStyles = {maxWidth: 'calc(100% - 52px)'}

export const GroupedCollapsibleCell = ({cell}) => {
	const {row, column} = cell
	const {subRows} = row
	const {id: columnName} = column
	const {columnDef} = column
	const Component = getCellComponent(cell, DefaultCell)
	let {cell: cellName} = columnDef
	cellName = _.endsWith(cellName, 'Cell') ? _.trimEnd(cellName, 'Cell') : cellName

	// Some cells which have a more complicated layout (such as Status cells which include a dot) don't render properly when they
	// are siblings with the total count suffix and should themselves decide where to put the count.
	const suffix = ` (${subRows.length})`
	const inChild = COLUMNS_WITH_TOTAL_AS_CHILD.includes(cellName)

	const suffixInChild = inChild ? {total: suffix} : null
	const suffixInParent = suffixInChild ? null : suffix

	// If the table is grouped on this column and this collapsible collection is grouped on the fact that there is no value,
	// show something like "Geen (167)" instead of merely "(167)" based on the undefined value.
	const showPlaceholder = subRows[0] && subRows[0].getValue(columnName) === undefined

	return (
		<BaseCollapsibleCell Component={Component} cell={cell} extraProps={suffixInChild} style={inlineStyles}>
			{showPlaceholder && t('none')}
			{suffixInParent && suffix}
		</BaseCollapsibleCell>
	)
}

const DefaultCell = props => {
	// For the grouped column, show the value as how it is shown in a regular, ungrouped row.
	const {column, cell} = props
	const CellComponent = getCell(column)
	const translatedPlaceholder = getTranslatedPlaceholder(cell)

	return <CellComponent {...props} translatedPlaceholder={translatedPlaceholder} />
}
