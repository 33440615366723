import {Icon, PopoutCard} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {ChatButton, DemoButton, Layout, Text} from 'common/components'
import {navigate} from 'components/routing'
import {NAMESPACE, OnboardingProgress, useOnboardingModalTaxonomy, useOnboardingStatus, useOnboardingStepsTaxonomy} from 'cores/onboarding'
import {getBaseUrl, usePathActive} from 'hooks'
import {mdT} from 'initializers/i18n'
import './index.less'

const ns = `${NAMESPACE}.onboarding_popout`

export const OnboardingStepsPopout = ({children}) => {
	return (
		<PopoutCard
			className="onboarding"
			placement="bottom"
			bodyPadding={0}
			title={`${ns}.title`}
			width={320}
			body={<ProgressAndSteps />}
			footer={<ButtonsFooter />}
		>
			{children}
		</PopoutCard>
	)
}

export const ProgressAndSteps = () => {
	const taxonomy = useOnboardingStepsTaxonomy()
	const welcomePage = mdT(`${ns}.welcome_page`)
	const helpCenter = mdT(`${ns}.help_center`)
	const intercomUrl = getBaseUrl()
	const goToWelcome = () => navigate('/onboarding/welcome')
	const goToIntercom = () => window.open(intercomUrl, '_blank')
	const onWelcomePage = usePathActive('/onboarding/welcome')

	return (
		<Layout direction="vertical" gap={0}>
			<Layout direction="vertical" padding={12} borderBottom width="full">
				<OnboardingProgress width={294} showOnboardingStepsPopout={false} />
				{!onWelcomePage && <Text children={welcomePage} onClick={goToWelcome} />}
				<Text children={helpCenter} onClick={goToIntercom} />
			</Layout>

			{taxonomy.map(taxItem => (
				<OnboardingStep {...taxItem} />
			))}
		</Layout>
	)
}

const OnboardingStep = ({id, stepTitle, ...rest}) => {
	const status = useOnboardingStatus()
	const modalTaxonomy = useOnboardingModalTaxonomy()?.find(taxItem => taxItem.id === id) || {}

	// Below should never hit, but add for safety during migration from old to new UI. Can delete later.
	if (!status) return null

	const {modalLink} = modalTaxonomy
	const completed = status.completed[id]
	const icon = completed ? 'checkbox-checked-colored' : 'checkbox-unchecked'
	const classNames = utils.makeCns('onboarding-step', modalLink && 'has-hover-effect')

	return (
		<Layout className={classNames} width="full" height={40} padding="10 12" gap={12} borderBottom modalLink={modalLink}>
			<Icon name={icon} />
			<Text children={stepTitle} strikeThrough={completed} darkGrey={completed} />
		</Layout>
	)
}

const ButtonsFooter = () => {
	return (
		<Layout width="full" horizontal="spaceBetween" gap={8}>
			<ChatButton grow t={`${ns}.chat`} />
			<DemoButton colorSet="solid" grow t={`${ns}.training`} />
		</Layout>
	)
}
