import _ from 'lodash'

const initialState = {
	forecast: [],
	actual: [],
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
		case 'RESET_RECORDS':
			return initialState

		case 'GET_REVENUE_FORECAST':
			return {
				...state,
				forecast: action.forecast,
			}

		case 'UPDATE_REVENUE':
			return {
				...state,
				actual: _.uniqBy([...action.items, ...state.actual], x => x.id),
			}

		case 'GET_REVENUE_ACTUAL':
			return {
				...state,
				actual: action.revenue,
			}

		default:
			return state
	}
}
