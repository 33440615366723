import {Layout, Title} from 'common/components'
import {NAMESPACE, useChannelMetaData, NewChatButton} from 'cores/chat'
import {t} from 'initializers/i18n'
import {useChatContext} from 'stream-chat-react'
import {OnlineMembers} from './online_members'
import {Attachments} from './attachments'
import {ManageGroupChatButton, ManageTeamChatButton} from './manage_chat_buttons'
import {StreamUserProfileButton} from './stream_user_profile_button'

export const PageHeader = () => {
	const {channel} = useChatContext()
	const {title, user, directChannel, groupChannel, teamChannel} = useChannelMetaData(channel)
	const hasUser = !_.isEmpty(user)

	return (
		<Layout className="channel-header" horizontal="spaceBetween" padding="- - 4 -" margin="24 - - -" borderBottom>
			<Layout grow>
				<Title truncate>{title || t(`${NAMESPACE}.chat`)}</Title>
			</Layout>
			<Layout>
				{directChannel && hasUser && <StreamUserProfileButton userId={user.id} />}
				<OnlineMembers />
				<Attachments />
				{groupChannel && <ManageGroupChatButton channel={channel} />}
				{teamChannel && <ManageTeamChatButton channel={channel} />}
				<NewChatButton />
			</Layout>
		</Layout>
	)
}
