import {BasicPopout} from '@eitje/web_components'
import {EitjeButton, DocsButton, EitjeDropdown, Text} from 'common/components'
import {ModalLink} from 'components/routing'
import {NAMESPACE} from 'cores/chat'
import {useRoleOrgEnvs} from 'hooks'
import {DirectChatUserPicker} from './direct_chat_user_picker'

export const NewChatButton = props => {
	const managerEnvs = useRoleOrgEnvs('manager')
	const {id, locked_team_id} = managerEnvs[0] || {}

	const teamChatLink = `/environments/${id}/teams/${locked_team_id}?show-chat-message=true`

	const elements = [
		{
			icon: 'person',
			element: <DirectChatUserPicker />,
		},
		{
			icon: 'person-plus',
			element: (
				<ModalLink to="/chats/group/new">
					<Text name={NAMESPACE} t="group_chat" />
				</ModalLink>
			),
		},
		{
			icon: 'building',
			element: (
				<BasicPopout
					title={`${NAMESPACE}.team_chats`}
					text={`${NAMESPACE}.team_chat_explanation`}
					buttons={[
						<EitjeButton iconLeft="external-link" t={`${NAMESPACE}.team_settings`} modalLink={teamChatLink} enableRole="manager" />,
						<DocsButton t="readExplanation" docsId={6560936} />,
					]}
					trigger="click"
					placement="right-start"
				>
					<Text name={NAMESPACE} t="team_chat" />
				</BasicPopout>
			),
		},
	]

	return (
		<EitjeDropdown elements={elements} trigger="click" placement="bottom-start">
			<EitjeButton className="new-chat-button" colorSet="solid" iconLeft="chat" t={`${NAMESPACE}.new_chat`} {...props} />
		</EitjeDropdown>
	)
}
