import ActiveRecord from './active_record/base'
import {getGlobal} from 'helpers'

class Team extends ActiveRecord {
	static tableName = 'teams'
	static defaultJoins = ['environments']

	fullName() {
		if (!getGlobal('isMultiEnv')) return this.naam
		return `${this.environment?.naam} - ${this.naam} `
	}
}

export default Team
