import {PopoutCard} from '@eitje/web_components'
import InnerShift from './inner_shift/index'
import {useEnvTableContext} from 'cores/planning/index'
import {usePlanAvShift} from 'cores/availability/hooks/use_av_shift'
import {planningEmojis} from 'cores/planning/constants'

const UserShift = ({item}) => {
	const {env} = useEnvTableContext()
	const {avShift, hasConflict} = usePlanAvShift({date: item.date, user_id: item.user_id}, {planShifts: item, envId: env.id})
	if (hasConflict) return <ConflictedShift avShift={avShift} item={item} text={avShift.tooltipText} />
	return <InnerShift item={item} />
}

const ConflictedShift = ({item, text, avShift}) => {
	const emoji = planningEmojis[avShift.status]

	return (
		<PopoutCard title={text}>
			<InnerShift emoji={emoji} item={item} className="conflict-shift" />
		</PopoutCard>
	)
}

export default UserShift
