import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {PlanningShift} from 'models/index'
import {makeRange} from 'helpers/date'
import {useGetPreferredEnv} from './hooks/use_get_preferred_env'
import {navigateModal} from 'actions/routing'
import {buildSearchParams} from 'components/routing'
import {useEnvsContext} from './pages/index/envs_provider'

const getPlannedHours = (shifts = []) => {
	const total = shifts.map(s => s.totalMins()).sum()
	const withUser = shifts
		.filter(s => s.user_id)
		.map(s => s.totalMins())
		.sum()

	const userString = utils.minToTimeString(withUser)
	const totalString = utils.minToTimeString(total)
	const timeString = `${userString} / ${totalString}`

	const namespace = 'planning.week_table_days_header'
	const roundHours = mins => Math.round(mins / 60)
	const hour = t(`${namespace}.hour_letter`)
	const userStringText = `${roundHours(withUser)}${hour} ${t(`${namespace}.planned_users_time`)}`
	const totalStringText = `${roundHours(total)}${hour} ${t(`${namespace}.planned_total_time`)}`
	const timeStringWithText = `${userStringText} | ${totalStringText}`

	return {total, withUser, timeString, timeStringWithText}
}

export const getShiftMins = ({from, till, pauze_duur}) => {
	const breaktimeMins = utils.timeStringToMin(pauze_duur)
	const range = makeRange(from, till)
	const totalMins = range.diff('minutes') - breaktimeMins
	return {totalMins, breaktimeMins}
}

export const shiftMapper = shift =>
	_.omit({...shift, start_date: `${shift.date}T${shift.from}`, end_date: `${shift.date}T${shift.till}`}, 'dateRange')

export const buildPlanningShift = shift => new PlanningShift(shiftMapper(shift))

const getPlanningShiftUrl = ({team, date, shift, user, userId, envId}) => {
	if (shift) return `/planning_shifts/${shift.id}`

	let url = `/shifts/new`
	const searchParams = buildSearchParams({
		team_id: team?.id,
		user_id: user?.id || userId,
		date,
		env_id: envId,
	})
	return url + searchParams
}

export const useGetPlanningShiftUrlWithEnv = props => {
	const envId = useGetPreferredEnv({user: props.user, user_id: props.userId})
	return getPlanningShiftUrl({...props, envId})
}

export const getShiftCondOpts = ({shift, hasManagerRole}) => {
	const {multiEdit, setSelectedShifts} = useEnvsContext()
	let condOpts = {}
	if (hasManagerRole) condOpts['onClick'] = () => navigateModal(`/planning_shifts/${shift.id}`)
	if (multiEdit) condOpts['onClick'] = () => setSelectedShifts([shift.id])
	return condOpts
}

export {getPlannedHours, getPlanningShiftUrl}
