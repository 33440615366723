import {useEffect} from 'react'
import {NewSideMenuModal, EitjeButton} from 'common/components'
import {Operation, Excel, ExportTable} from 'models'
import {useTaxonomy, getTaxItem, InboxList, useInboxData} from './..'
import {useLocation} from 'react-router-dom'
import {t} from 'initializers/i18n'
import utils from '@eitje/utils'
import {useState} from 'react'
import {slug} from 'helpers'
import {Association} from 'models'

export const InboxModal = () => {
	useEffect(() => {
		Operation.index()
		ExportTable.index()
		Excel.index()
	}, [])

	const routes = useTaxonomy().map(t => ({...t, component: InboxList, subRoutes: t.subRoutes?.map(r => ({...r, component: InboxList}))}))
	const {pathname} = useLocation()
	const name = slug(pathname)
	const taxItem = getTaxItem(name)

	// Mark as read button
	let {markRead, buttons} = taxItem

	const items = useInboxData(name)

	const unread = new Association(items.filter(d => d.id && !d.read))
	const unreadCount = unread.length
	const _markRead = () => unread.multiUpdate({read: true})

	// Other buttons: in order to insert the buttons before the back button, we need to create an
	// object with its key enumerating from 1 upwards (since 0 would insert them before the button!)

	if (buttons) {
		buttons = utils.alwaysArray(buttons).map(b => <EitjeButton {...b} />)

		buttons = buttons.reduce((obj, item, index) => {
			obj[index + 1] = item
			return obj
		}, {})
	}

	return (
		<NewSideMenuModal
			basePath="inbox"
			name="inbox"
			width={650}
			routes={routes}
			onSubmit={markRead && _markRead}
			insertAtIndex={buttons}
			okButtonProps={{children: `${t('common.mark_as_read')} (${unreadCount})`, disabled: unreadCount === 0}}
			sideMenuProps={{alwaysExpanded: true}}
		/>
	)
}
