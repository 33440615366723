import React from 'react'
import {exportT, ExportTable, Excel, ExportSubscription, TablesProvider} from 'cores/exports2/index'
import Header, {TypeSelector} from './header/index'
import Body from './body/index'
import Footer from './footer/index'
import {PageLayout} from 'common/components'
import './styles/index.less'

const Inner = () => {
	return (
		<PageLayout name="export-tables" footers={<Footer />}>
			<Header />
			<TypeSelector />
			<div className="full-width-divider" />
			<Body />
		</PageLayout>
	)
}

export const TableIndex = () => {
	ExportTable.index()
	ExportSubscription.index()
	Excel.index()

	return (
		<TablesProvider>
			<Inner />
		</TablesProvider>
	)
}

function areEqual(prevProps, nextProps) {
	return true
}

export default React.memo(TableIndex, areEqual)

export const t = (text, opts = {}) => exportT(`table_index.${text}`, opts)
