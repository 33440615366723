import {AccessSentence} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {ListPicker, TourButton} from 'common/components'
import {Input} from '@eitje/form-fields-web'
import {useSimpleContext} from 'contexts/simple'
import {useTaxonomy} from 'hooks'
import {ContextForm} from '@eitje/form'
import './styles/index.less'

export const Save = () => {
	const {formItem, setUpdateState} = useSimpleContext()
	const roles = useTaxonomy('roles').filter(r => r != 'onroosterbaar' && r != 'admin')
	const roleItems = roles.map(r => ({id: r, name: t(`rolesEnum.${r}`)}))

	return (
		// TODO: the "team-select-container" className is a temporary hack until we redo the list select component
		<div className="exports2-edit-save team-select-container">
			<ContextForm
				hidePrompt
				afterChange={(field, val) => setUpdateState({[field]: val})}
				initialValues={formItem}
				transNamespace="exports"
			>
				<div className="title-and-tour">
					<h3 className="exports-edit-save-title">{t('exports2.table_edit.save.title')}</h3>
					<TourButton height="small" tourId={447859} />
				</div>
				<Input field="name" />
				<Input field="description" />
				<ListPicker field="roles" items={roleItems} icon={false} />
			</ContextForm>
			<Footer />
		</div>
	)
}

const Footer = () => {
	const {formItem} = useSimpleContext()
	return (
		<div className="access-sentence-block">
			<AccessSentence item={formItem} />
		</div>
	)
}
