import {buildField} from '@eitje/form-fields-web'
import {PopoutCard} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import './styles/popout_picker.less'

const _PopoutPicker = props => {
	let {SelectedEl, value, disabled} = props
	return (
		<PopoutCard disabled={disabled} body={<PopoutBody {...props} />}>
			<div>
				<SelectedEl value={value} />
			</div>
		</PopoutCard>
	)
}

const PopoutBody = ({items, Item, value, onChange, popoutContainerClass}) => {
	const classNames = utils.makeCns('popout-picker-items', popoutContainerClass)

	return (
		<div className={classNames}>
			{items.map(i => (
				<Item value={i} onChange={onChange} active={i === value} />
			))}
		</div>
	)
}

export const PopoutPicker = buildField(_PopoutPicker, {withIcon: false, className: 'eitje-popout'})
