import utils from '@eitje/web_utils'
import {ModalLink} from 'components/routing'
import {t, getTimeShiftUrl} from 'cores/time_registration/index'

const ShiftLink = ({children = t('add'), className, ...rest}) => {
	const to = getTimeShiftUrl(rest)

	return (
		<ModalLink className={className} to={to} {...rest}>
			{children}
		</ModalLink>
	)
}

export default ShiftLink
