import {navigate} from 'components/routing'

export const navigateAndReloadHome = () => {
	navigate('/home_page')

	// If a user has forcefully reloaded the page while offline, AuthProvider has lost its children,
	// thus a redirect to /home_page yields a blank page. Therefore trigger a whole reload when this occurs.
	const inState = id => !!document.body.querySelector(`#currently_${id}_page`)?.children.length
	const blankPage = !inState('loading') && !inState('loaded')

	if (blankPage) {
		window.location.reload()
	}
}
