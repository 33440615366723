import {PopoutCard} from '@eitje/web_components'
import {t, FilterSentence, displayFilter, AccessSentence, filterName} from 'cores/exports2/index'
import {useSimpleContext} from 'contexts/simple'
import utils from '@eitje/web_utils'
import {t as baseT} from 'initializers/i18n'
import './styles/index.less'

export const FilterInfo = () => {
	return (
		<div className="export2-tables-filters">
			<FilterBlock />
			<AccessBlock />
		</div>
	)
}

const FilterBlock = () => {
	const {item} = useSimpleContext()
	return (
		<div className="export2-tables-filter-block">
			<h4>{t('table_show.filter_title')} </h4>
			<FilterSentence item={item} />
			<Filters item={item} />
		</div>
	)
}

const Filters = ({item}) => {
	const filters = item.auxFilters().map(c => <p className="exports-filter-card"> {displayFilter(c)} </p>)
	return <MaxPopoverList max={5}>{filters}</MaxPopoverList>
}

const MaxPopoverList = ({children, max}) => {
	const alwaysVisible = children.slice(0, max)
	const popoverChildren = children.slice(max, children.length)

	const className = utils.makeCns('max-popover-list-container')
	const count = popoverChildren.length
	return (
		<div className={className}>
			{alwaysVisible}
			{count > 0 && (
				<PopoutCard body={popoverChildren}>
					<div className="exports-filter-card">
						{count} {baseT('common.others', {count})}
						<img className="footer-card-icon" src="/images/search_glass_icon.png" />
					</div>
				</PopoutCard>
			)}
		</div>
	)
}

const AccessBlock = () => {
	const {item} = useSimpleContext()
	return (
		<div className="export2-tables-filter-block">
			<h4>{t('table_show.access_title')} </h4>
			<AccessSentence item={item} />
		</div>
	)
}

const columnFilterMap = c => {
	const {filter, value} = c
	if (_.isArray(value)) return value.map(v => baseT(v)).join(', ') //

	return filterName(filter, value)
}
