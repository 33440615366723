import ActiveRecord, {DateRangeActiveRecord} from 'models/active_record/base'
import date from 'initializers/date'

class SickPeriod extends DateRangeActiveRecord {
	static defaultJoins = ['users']
	static startDateField = 'start'
	static endDateField = 'end'
	static tableName = 'sickPeriods'

	startDate() {
		return date(this.start_date)
	}

	endDate() {
		return date(this.end_date)
	}

	isOnDate(date) {
		return this.dateRange.contains(date)
	}
}

export default SickPeriod
