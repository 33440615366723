import {backend} from '@eitje/easy_api'
import {AuthPageLayout} from 'cores/auth'
import {Layout, EitjeButton, ChatButton} from 'common/components'
import {useShared} from 'hooks'

export const ServerDown = () => {
	const {me} = useShared()

	// The poll automatically prints a error notification if failed, or redirects if succeeded.
	// Look at the logic in `eitjeServerRunning` in initializers/eitje_api.js
	const pollServer = async () => {
		const arbitraryUrl = `/users/${me.id}/user_status`
		await backend.get(arbitraryUrl)
	}

	return (
		<AuthPageLayout name="server_down" animation="broken-egg" subtitle noForm noLocaleButton>
			<Layout>
				<EitjeButton colorSet="solid" onClick={pollServer} iconLeft="arrow-circle-left" t="common.try_again" />
				<ChatButton />
			</Layout>
		</AuthPageLayout>
	)
}
