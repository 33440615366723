import {PopoutCard} from '@eitje/web_components'
import {Message, goToNext, ListPicker, Layout} from 'common/components'
import {useFormData} from 'hooks'
import {useModalContext} from 'components/common/index'
import {ModalForm, CompositeField} from 'components/form/index'
import {RevenueGroup, Environment, ExtEnvironment} from 'models'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import {useLastPathParam} from 'actions/routing'
import {FormRow} from 'components/ui'

const MapGroups = props => {
	const id = useLastPathParam()
	const environment = Environment.find(id)
	const {item, extEnvironments} = useModalContext()
	const groups = RevenueGroup.where({environment_id: id})
	const items = extEnvironments._map('integrationExtRevenueGroups').flat().uniq('id').filter(Boolean) || []

	const inactive_ids = items.filter(g => g.inactive)._map('id')

	const sharedProps = {
		environment,
		listItemProps: {environment},
		title: t('modals.setup_revenue.groups.ext_name', {type: item.name}),
	}

	const submit = async data => {
		const {inactive_ids} = data
		const inactive = items.map(item => ({id: item.id, inactive: inactive_ids.includes(item.id)}))
		await API.updateMulti('omzet_groepen', data.revenue_groups)
		return API.updateMulti('integration_ext_revenue_groups', inactive)
	}

	return (
		<ModalForm
			afterSubmit={() => goToNext(props)}
			submitInitialValues
			transNamespace="integ_rev_map_groups"
			onSubmit={submit}
			initialValues={{inactive_ids, revenue_groups: groups}}
			modalProps={{confirmSubmit: true}}
		>
			<Layout direction="vertical" gap={0} name="modals.setup_revenue.groups">
				<Message t={{key: 'message', integ_name: item.name}} baseColor="blue" colorSet="color-bordered-fill" />

				<CompositeField fields={['integration_ext_revenue_group_ids', 'id']} compositeName="revenue_groups">
					<CompositeRevGroupPicker groups={groups} {...sharedProps} items={items} />
				</CompositeField>

				<FormRow>
					<InactivePicker {...sharedProps} items={items} />
				</FormRow>
			</Layout>
		</ModalForm>
	)
}

const InactivePicker = props => {
	const {revenue_groups = []} = useFormData()
	const ids = revenue_groups._map('integration_ext_revenue_group_ids').flat()
	let condOpts = {}
	const {item} = useModalContext()
	if (item.grouping_type == 'sales_areas') {
		condOpts = buildSalesAreaItems(props.items)
	}

	const alreadyIntegratedMsg = t('modals.setup_revenue.groups.already_integrated')

	const itemDisabled = item => {
		if (ids.includes(item.id)) return alreadyIntegratedMsg
	}

	return <ListPicker dropdown {...props} {...condOpts} label={t('common.inactive')} itemDisabled={itemDisabled} field="inactive_ids" />
}

const salesAreaProps = {
	collapseGroups: true,
	groupSelectable: true,
	groupField: 'ext_sales_area_id',
}

const buildSalesAreaItems = items => {
	const groupItems = _.uniqBy(
		items.map(i => ({id: i.ext_sales_area_id, name: `${t('integrations.sales_area')}: ${i.sales_area_name}`})),
		'id',
	)
	return {
		...salesAreaProps,
		groupItems,
	}
}

const CompositeRevGroupPicker = ({number, items, groups, ...props}) => {
	const {item} = useModalContext()
	const group = groups[number - 1]
	const {inactive_ids, revenue_groups} = useFormData()
	const otherGroups = revenue_groups.filter(r => number != revenue_groups.indexOf(r) + 1)
	const otherIds = otherGroups._map('integration_ext_revenue_group_ids').flat()

	let condOpts = {}
	if (item.grouping_type == 'sales_areas') {
		condOpts = buildSalesAreaItems(items)
	}

	const alreadyIntegratedMsg = t('modals.setup_revenue.groups.already_integrated')

	const itemDisabled = item => {
		if (inactive_ids.includes(item.id)) return alreadyIntegratedMsg
		if (otherIds.includes(item.id)) return alreadyIntegratedMsg
	}

	return (
		<ListPicker
			dropdown
			label={group.naam}
			itemDisabled={itemDisabled}
			{...props}
			{...condOpts}
			items={items}
			hideClearIcon
			field={`integration_ext_revenue_group_ids-${number}`}
		/>
	)
}

export default MapGroups
