import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'

export const useScript = scriptSrc => {
	useEffect(() => {
		const script = document.createElement('script')
		const scriptText = document.createTextNode(scriptSrc)
		script.appendChild(scriptText)
		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [])
}

export default useScript
