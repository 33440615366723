import {find} from '@eitje/easy_api'
import useParams from 'hooks/use_params'
import {useSelector} from 'react-redux'
import useTopicResources from './use_topic_resources'

function useTopic() {
	const {id} = useParams()
	const topic = useSelector(state => find(state, 'topic', id))

	const topicResources = useTopicResources(topic)
	return {...topic, ...topicResources}
}

export default useTopic
