import {t} from 'initializers/i18n'
import {useFind} from '@eitje/easy_api'
import {ModalLink} from 'components/routing'
import useList from 'hooks/use_list'
import {getTimeShiftUrl} from 'cores/time_registration/index'
import {usePlanningDates, useScheduledShifts, getPlanningShiftUrl} from 'cores/planning/index'
import './styles/shifts_overview.less'
import _ from 'lodash'
import {Team} from 'models'

const ShiftsOverview = ({user}) => {
	const {dateRange} = usePlanningDates()
	const {planShifts, improductiveShifts} = useScheduledShifts({user_id: user.id, dateRange})
	const shifts = _.orderBy([...planShifts, ...improductiveShifts], 'date')
	const {list} = useList({items: shifts, showPlaceholder: true, Placeholder, ListItem: ShiftItem})
	return <div className="shifts-overview">{list}</div>
}

const ShiftItem = ({item}) => {
	const {stringMins, _date, tableName} = item
	const isPlanShift = tableName == 'planningShifts'
	const kind = isPlanShift ? t('common.planned') : t(`common.${item.typ}`)
	const team = Team.find(item.team_id)
	const env = useFind('environments', team.environment_id)
	const to = isPlanShift ? getPlanningShiftUrl({shift: item}) : getTimeShiftUrl({shift: item})

	return (
		<ModalLink to={to} className="shift-item">
			<h4 className="shift-item-day"> {_date().format('dd DD/MM')} </h4>
			<h5 className="shift-item-duration"> {stringMins()} </h5>
			<h5 className="shift-item-type"> {kind} </h5>
			<h5 className="shift-item-env"> {env.naam} </h5>
		</ModalLink>
	)
}

const Placeholder = () => {
	return <h5 className="no-shifts-placeholder"> {t('planning.team_panel.balance.no_shifts')} </h5>
}

export default ShiftsOverview
