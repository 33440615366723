import {API, find} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker, LegacySwitch as Switch} from '@eitje/form-fields-web'
import {changeLocale, updateUserSetting} from 'actions/settings'
import PasswordForm from 'components/forms/password_form'
import UserForm from 'components/forms/user_form'
import {SyncAgendaBtn} from 'components/general'
import Modal from 'components/general/modal'
import {Routes, SideMenu} from 'components/routing'
import {useOneSignal} from 'hooks/use_one_signal'
import useShared from 'hooks/use_shared'
import Form from 'initializers/form'
import I18n, {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'
import {useParams, useRouteMatch} from 'react-router-dom'
import {getUserSetting} from 'selectors/new_settings'

import './styles/user_preferences.less'

const UserPreferences = () => {
	const {me, hasManagerRole} = useShared()
	const {id} = useParams()
	const match = useRouteMatch()

	const user = useSelector(state => find(state, 'users', id))
	const isSelf = me.id === Number(id)
	const routeProps = {user}
	const path = match.path
	const basePath = `/forms/user_preferences/${id}`
	const routes = isSelf ? selfRoutes : otherRoutes

	return (
		<Modal
			wrapClassName="user-preferences modal-wrapper"
			className="user-preferences-page"
			size={'md'}
			redirectIf={!isSelf && !hasManagerRole}
			title={t('prefs')}
		>
			<div className="modal-col left-navigation-column vertical-padding-xs gap-xss padding-top-xss">
				<SideMenu routes={routes} routeProps={routeProps} path={basePath} />
			</div>
			<div className="right-settings-column modal-col-body-area">
				<Routes routes={routes} path={path} routeProps={routeProps} />
			</div>
		</Modal>
	)
}

export default UserPreferences

const lngs = ['en', 'nl']

// THIS LOOKS UNUSED?
const handleMailSub = (val, sub, typ) => {
	if (val) {
		API.create('mail_subscriptions', {type: typ})
	} else {
		API.destroy('mail_subscriptions', sub.id)
	}
}

const submitSubscription = (data, ids) => {
	const type = Object.keys(data)[0]
	const val = data[type]
	if (val) {
		return API.create('mail_subscriptions', {type})
	} else {
		const id = ids[type]
		return API.destroy('mail_subscriptions', id)
	}
}

const LanguageSettings = ({user}) => {
	const {hasManagerRole, hasContractRole} = useShared()
	const locale = useSelector(state => getUserSetting(state, 'locale')) || I18n.language
	const receiveMail = useSelector(state => getUserSetting(state, 'receive_mail'))

	const {handleOneSignalPrompt, notifState} = useOneSignal()

	const contractSubscription = useSelector(state => find(state, 'mailSubscriptions', {type: 'ExpiringContract'}))
	const birthdaySubscription = useSelector(state => find(state, 'mailSubscriptions', {type: 'BirthdayAlert'}))

	const ids = {
		BirthdayAlert: birthdaySubscription.id,
		ExpiringContract: contractSubscription.id,
	}

	return (
		<div>
			<DropdownPicker
				style={{width: '120px', minWidth: '120px'}}
				value={locale}
				onChange={loc => changeLocale(loc)}
				field="locale"
				items={lngs}
			/>

			{/* div for the extra title & description for the field. */}
			<div className="email-notifications-title">
				<p className="bold">{t('mailSubscriptions')}</p>
				<p className="eitje-text-secondary">{t('mailSubscriptionsSub')}</p>
			</div>

			<Switch field="receive_mail" onChange={val => updateUserSetting({receive_mail: val})} value={receiveMail} />

			<Form initialValues={ids} debounceTime={50} useSubmitStrategy onSubmit={data => submitSubscription(data, ids)}>
				{hasContractRole && <Switch field="ExpiringContract" />}

				{hasManagerRole && <Switch field="BirthdayAlert" value={!!birthdaySubscription.id} />}
			</Form>

			<div className="browser-notifications-title vertical-padding-xl padding-top-xl">
				<p className="bold">{t('browserNotifications')}</p>
				<p className="eitje-text-secondary">
					{t('browserNotifSubline')} (
					<a
						target="_blank"
						href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac#:~:text=Remove%20stored%20cookies%20and%20data%3A%20Click%20Manage%20Website%20Data%2C%20select%20one%20or%20more%20websites%2C%20then%20click%20Remove%20or%20Remove%20All."
					>
						Safari,{' '}
					</a>
					<a
						target="_blank"
						href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop#:~:text=In%20Chrome,Click%20Clear%20data."
					>
						Chrome
					</a>
					){' '}
				</p>
			</div>

			<Switch field="enableNotifications" onChange={handleOneSignalPrompt} value={notifState} />
		</div>
	)
}

const SyncAgenda = () => (
	<div className="sync-agenda-container">
		<div className="elementContainer row">
			<p className="eitje-label">{t('syncAgendaUser')}</p>
			<div style={{maxWidth: 220}}>
				<SyncAgendaBtn />
			</div>
		</div>
	</div>
)

const otherRoutes = [{name: 'profile_info', component: UserForm, exact: true, path: null}]
const selfRoutes = [
	...otherRoutes,
	{name: 'change_password', component: PasswordForm},
	{name: 'settings', component: LanguageSettings},
	{name: 'sync_agenda', component: SyncAgenda},
]
