import {backend} from '@eitje/easy_api'

import utils from '@eitje/web_utils'
import {TimeRegistrationShift} from 'models/index'
import {t} from 'cores/time_registration/index'
import {setArbitrary} from 'actions/arbitrary'

export const approveShifts = async shifts => {
	const items = shifts.map(s => {
		if (s.isPlan()) {
			return {plan_shift_id: s.id, published: true}
		} else {
			return {id: s.id, published: true}
		}
	})

	const res = await TimeRegistrationShift.multiUpdate(items)

	if (res.ok) {
		const opts = {count: res.data?.items?.time_registration_shifts?.length}
		utils.successNotif({title: t('approved_notif.title', opts), content: t('approved_notif.content', opts)})
	}

	return res
}

export const getPeriods = async () => {
	const res = await backend.get(`environments/${window.envId}/wage_periods`)
	if (res.ok) {
		setArbitrary('periods', res.data.periods)
	}
}
