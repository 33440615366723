import {Layout, Title, Text} from '@eitje/web_components'
import {useSelector, useHasRoleInAnyEnv, useQueryParams} from 'hooks'
import {t} from 'initializers/i18n'
import {rolesSelector} from 'selectors/new_settings'
import {EitjeModal} from 'components/common'
import {EitjeButton} from 'common/components'
import {ModalLink} from 'components/routing'

const tables = {
	hours: ['WorkedHoursByUser', 'WorkedHoursByTeam', 'WorkedHoursByDate', 'WorkedHoursOverview'],
	planning: ['PlanningGrid', 'PlanningList'],
	labour_costs: ['LabourCostsEnv', 'Tunica'],
	integrations: ['ConnexieUpload', 'HorecalonenUpload'],
}

const Exports = () => {
	const buttons = [<EitjeButton colorSet="solid" iconLeft="pencil" t="exports.buttons.create_your_own_export" link="/exports2/tables" />]

	return (
		<EitjeModal name="ready_made_exports" width={400} buttons={buttons}>
			<Layout direction="vertical" padding="20 24" gap={16}>
				<ExportSection type="hours" role="uren_accorderen" />
				<ExportSection type="planning" role="manager" />
				<ExportSection type="labour_costs" role="financieel" />
				<ExportSection type="integrations" role="admin" />
			</Layout>
		</EitjeModal>
	)
}

const ExportSection = ({type, role}) => {
	const exportTemplates = tables[type]
	const inlineStylesLayout = {borderRadius: 4}
	const inlineStylesModalLink = {width: 'calc((100% - 4px) / 2)'} // childrenPerRow prop doesn't work because ModalLink spawns an `a` tag
	const hasRole = useHasRoleInAnyEnv(role)
	const {filter} = useQueryParams()
	const includedInFilter = !filter || filter.split(',').includes(type)

	if (role && !hasRole) return null
	if (!includedInFilter) return null

	return (
		<Layout width="full" direction="vertical">
			<Title t={`sidemenu.exports.${type}`} />
			<Layout wrap width="full">
				{exportTemplates.map(e => (
					<ModalLink to={`/static_exports/${e}`} style={inlineStylesModalLink}>
						<Layout colorSet="grey-bordered" padding="8 12" border style={inlineStylesLayout}>
							<Text t={`exports.${e}.title`} />
						</Layout>
					</ModalLink>
				))}
			</Layout>
		</Layout>
	)
}

export default Exports
