import {t} from 'initializers/i18n'
import {useWhere} from '@eitje/easy_api'
import {UserImage} from 'components/ui'
import BasicList from './basic_list'

const UserList = ({userIds, title = t('teammember', {count: userIds.length}), ...rest}) => {
	const users = useWhere('users', userIds)
	return <BasicList items={users} title={title} ListItem={User} {...rest} />
}

const User = ({item}) => {
	return (
		<div className="user-row">
			<UserImage user={item} />
			<p className="user-name"> {item.full_name} </p>
		</div>
	)
}

export default UserList
