import {EitjeButton, DocsButton, Layout} from 'common/components'
import {useIntegrationConstants} from 'cores/integrations'

const IntegrationCard = ({item}) => {
	const {logo, docsLink} = useIntegrationConstants(item.id)
	return (
		<Layout parentGap={12} direction="vertical" type="card" horizontal="center" padding={24} gap={12}>
			<Layout className="logo-layout" height={36} horizontal="center">
				{logo}
			</Layout>
			<Layout horizontal="center">
				<EitjeButton iconLeft="plus" modalLink={`/integrations/${item.id}/activate`} height="small" t="common.add" />
				<DocsButton truncate={false} to={docsLink} height="small" t="common.info" colorSet="grey-bordered" />
			</Layout>
		</Layout>
	)
}

export default IntegrationCard
