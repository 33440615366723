import {useEnvSettingGroup} from 'hooks/use_env_setting'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import {date} from 'initializers/date'
import {ContextForm} from '@eitje/form'
import Header from './header'
import Form from './form/index'
import {saveSettingGroup} from 'actions/settings'
import './styles/index.less'

export const Settings = () => {
	const {envId} = useParams()
	const initialValues = useEnvSettingGroup('uren_reg', envId)
	return (
		<ContextForm
			fieldProps={{submitStrategy: 'throttledChange'}}
			throttleTime={1200}
			onSubmit={data => saveSettings(data, envId)}
			initialValues={initialValues}
			transNamespace="time_reg_settings"
		>
			<Modal hasCancel wrapClassName="eitje-modal-2 time-registration-settings-modal">
				<Header envId={envId} />
				<Form />
			</Modal>
		</ContextForm>
	)
}

const saveSettings = (data, envId) => {
	if (data['vanaf']) {
		data['vanaf'] = date(data['vanaf'])
	}

	return saveSettingGroup('uren_reg', data, {env_id: envId})
}
