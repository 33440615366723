import {EitjeModal} from 'components/common'
import {Input} from '@eitje/form-fields-web'
import {useFormData, useQueryParams} from 'hooks'
import {t} from 'initializers/i18n'
import './styles/index.less'
import {API} from '@eitje/easy_api'
import pluralize from 'pluralize'
import {PlanningShift} from 'models/index'
import {ContextForm} from '@eitje/form'
import {FormRow} from 'components/ui'
import {EitjeButton, DeleteButton} from 'common/components'

const typeMapping = {
	allow_pickup: {
		submit: ({ids}) => PlanningShift.arbitrary('change_pickups', {planning_shift_ids: ids}),
		record: 'shift',
		verb: 'confirm',
	},
	deny_pickup: {
		submit: ({ids}) => PlanningShift.arbitrary('change_pickups', {destroy: true, planning_shift_ids: ids}),
		record: 'shift',
		verb: 'confirm',
	},
}

const defaultSubmitFn = ({ids, modelName}) => API.multiDestroy(modelName, ids)

export const InnerComponent = ({history}) => {
	const {ids, type} = useQueryParams()
	const {confirmText} = useFormData()
	const overwriteValues = typeMapping[type] || {}

	const verb = overwriteValues.verb || 'delete'
	const isDelete = verb === 'delete'
	const count = ids.length
	const valueToMatch = `${verb.toUpperCase()} ${count}`
	const disabled = confirmText !== valueToMatch
	const modelName = pluralize(type)

	const handleSubmit = async () => {
		const submit = overwriteValues.submit || defaultSubmitFn
		const res = await submit({modelName, ids})
		if (res.ok) {
			history.goBack()
		}
	}
	const _DeleteButton = <DeleteButton onDel={handleSubmit} disabled={disabled} colorSet="solid" />
	const _SubmitButton = <EitjeButton onClick={handleSubmit} disabled={disabled} children={verb} colorSet="solid" />
	const Button = isDelete ? _DeleteButton : _SubmitButton

	const localizeParams = {
		action: t(`modals.confirm_modal.actions.${verb}`),
		count,
		record: t(`records.${overwriteValues.record || type}.name`, {count}),
	}

	const title = `${localizeParams.action} ${count} ${localizeParams.record}`
	const headerKey = overwriteValues.header || `modals.confirm_modal.namespaces.${type}.header`
	const headerText = t([headerKey, 'modals.confirm_modal.text'], localizeParams)

	const actionText = t('modals.confirm_modal.submit_text', localizeParams)

	return (
		<EitjeModal className="confirm-modal" title={title} name="confirm" insertAtIndex={{1: Button}}>
			<div className="confirm-modal-header">
				<div className="modal-info-banner">
					<h5>{headerText}</h5>
				</div>
			</div>
			<FormRow>
				<Input label={actionText} field="confirmText" placeholder={valueToMatch} bordered={false} icon={null} />
			</FormRow>
		</EitjeModal>
	)
}

export const ExplicitConfirmModal = props => {
	return (
		<ContextForm transNamespace="explicit_confirm" hidePrompt>
			<InnerComponent {...props} />
		</ContextForm>
	)
}
