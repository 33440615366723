import {filterRecords} from '@eitje/easy_api'
import useShared from 'hooks/use_shared'
import {hasConflict, useAvParts, buildAvShift} from 'cores/availability/index'
import {useHasRole, useOrgEnvsSettingGroup} from 'hooks'

const useConflictedShifts = (shifts = [], date) => {
	const {envId} = useShared()
	const hasManagerRole = useHasRole('manager', envId)
	const userIds = shifts
		.map(s => s.user_id)
		.uniq()
		.filter(Boolean)
	let {avShifts, leaveRequests, sickPeriods, avSettings: currentEnvAvSettings} = useAvParts({date, user_ids: userIds, envId})
	const orgEnvSettings = useOrgEnvsSettingGroup('beschikbaarheid')

	return shifts.filter(s => {
		if (!s.user_id) return false
		const params = {user_id: s.user_id, date: s.date}
		const paramsWithDateRange = {user_id: s.user_id, dateRange: s.date}

		const userAvShifts = filterRecords(avShifts, params)
		const sickPeriod = filterRecords(sickPeriods, paramsWithDateRange)
		const leaveRequest = filterRecords(leaveRequests, paramsWithDateRange)?.[0] // buildAvShift expects one leave request
		const avSettings = orgEnvSettings[s.team?.environment_id] || currentEnvAvSettings // TODO: When no team or environment_id is present we should log an error
		const avShift = buildAvShift({avSettings, date: s.date, avShifts: userAvShifts, sickPeriod, leaveRequest})

		return hasConflict(s, avShift) && (hasManagerRole || avShift.status == 'sick')
	})
}

export default useConflictedShifts
