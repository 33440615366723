import {Icon} from '@eitje/web_components'
import {DeletePopout} from 'common/components'
import _ from 'lodash'

export const DeleteCross = ({color = 'red', size = 12, confirm = true, onDel = _.noop}) => {
	const Wrapper = confirm ? DeletePopout : 'div'
	const props = confirm ? {onDelete: onDel} : {onClick: onDel}
	return (
		<Wrapper className="delete-cross-wrapper" {...props}>
			<Icon name="cross" size={size} fill={color} />
		</Wrapper>
	)
}
