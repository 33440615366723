import {useState} from 'react'
import _ from 'lodash'

const useMergeState = (def = {}) => {
	const [state, setState] = useState(def)
	const setMergeState = newState => {
		setState(currState => ({...currState, ...newState}))
	}

	const removeKey = key => setState(currState => _.omit(currState, key))

	return [state, setMergeState, {setState, removeKey}]
}

export default useMergeState

//1. get all users including contract
//2. when submitting, check if user's inside object, if not apply default
