import {Button} from 'antd'
import {useEffect} from 'react'
import {navigateModal} from 'actions/routing'
import useAsyncEffect from 'hooks/use_async_effect'
import {t} from 'initializers/i18n'
import QueryString from 'query-string'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {BaseConnection} from 'models/index'

export const LightspeedKActivated = ({location}) => {
	const [loading, setLoading] = useState(true)
	const [id, setId] = useState(false)

	const lsState = useSelector(state => state.auth.lightspeedState)
	const {code, state} = QueryString.parse(location.search)
	const isValid = !!(lsState === state && code)
	useAsyncEffect(async () => {
		if (isValid) {
			const res = await BaseConnection.create({provider: 'lightspeedK', code})
			setLoading(false)
			if (res.ok) {
				setId(res?.data.item.id)
			}
		}
	}, [])

	if (id) return <Redirecter id={id} />
	return (
		<div
			className="shadow lightspeed"
			style={{
				width: '25%',
				border: '1px solid #eee',
				margin: 'auto',
				borderRadius: 4,
				backgroundColor: 'white',
				padding: '80px 24px 40px 24px',
				marginTop: 120,
				boxShadow: '0 5px 20px 0 rgba(34,43,55,.15)',
			}}
		>
			<div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
				<img style={{width: 320, marginBottom: 40}} src="/images/integrations/lightspeed_redirect.png" />

				{loading && <span style={{display: 'block'}}>{t('lightspeed1')}</span>}
				{id && (
					<div>
						<span style={{marginBottom: 24, display: 'block'}}>{t('lightspeed2')}</span>
						<Button>
							<Link to="/integrations">{t('goToSettings')}</Link>
						</Button>
					</div>
				)}
				{!id && !loading && (
					<div>
						<span style={{marginBottom: 24, display: 'block'}}>{t('lightspeed3')}</span>
						<Button>
							<Link to="/integrations">{t('goToSettings')}</Link>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

const Redirecter = ({id}) => {
	useEffect(() => {
		navigateModal(`/integrations/lightspeed_k/${id}/setup_revenue`, {bgPath: `/integrations/my/lightspeed_k/`})
	}, [])
	return null
}

export default LightspeedKActivated
