import utils from '@eitje/web_utils'
import {PlanningShift, CheckIn} from 'models/index'
import {TimeBlock} from 'cores/time_registration/index'
import {useSimpleContext} from 'contexts/simple'
import {Layout} from 'common/components'

const HoursOverview = () => {
	const {shift} = useSimpleContext()

	return (
		<Layout direction="vertical" gap={8} padding={12} className="hours-overview">
			{shift.isReg() ? <Blocks shift={shift} /> : <TimeBlock shift={shift} />}
		</Layout>
	)
}

const Blocks = ({shift}) => {
	const {relatedShifts = {}} = useSimpleContext()

	const {check_ins = [], planning_shifts = []} = relatedShifts
	const checkIns = check_ins.map(c => new CheckIn(c))
	const planningShifts = planning_shifts.map(s => new PlanningShift(s))

	const shifts = [...planningShifts, ...checkIns, shift].filter(u => utils.exists(u))
	return (
		<>
			{shifts.map(s => (
				<TimeBlock shift={s} />
			))}
		</>
	)
}

export default HoursOverview
