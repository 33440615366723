import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useRecordErrors} from 'hooks'
import {EitjeButton} from 'common/components'

export const RecordErrorsButton = ({recordTypes = [], kind}) => {
	recordTypes = utils.alwaysDefinedArray(recordTypes)
	const errors = useRecordErrors(recordTypes)
	if (!kind) kind = utils.camelToSnake(recordTypes[0])

	if (!utils.exists(errors)) return null
	return (
		<EitjeButton className="shift-errors-button" baseColor="red" modalLink={`/${kind}/handle_errors`}>
			{t('common.errors')} ({errors.length})
		</EitjeButton>
	)
}
