import {useLocalValue} from 'hooks'
import utils from '@eitje/web_utils'
import {ShiftCard} from 'common/components/shift_card'
import {t} from 'initializers/i18n'

const Inner = props => {
	const {
		hasManagerRole,
		item: {user_id, user, hasTradeRequest, trade_request_id, team, reg_shift_id},
		className,
		showTeamColor,
	} = props
	const showTrade = useLocalValue('planningShowTrade')
	const canSeeTrade = hasTradeRequest?.() && showTrade && hasManagerRole
	const classNames = utils.makeCns('planning-shift', canSeeTrade && 'has-trade-request', !user_id && 'open-shift', className)

	const teamColorStyle = {...(team?.color && showTeamColor && {backgroundColor: team.color})}
	const name = user?.full_name || t('common.open_shift')

	return (
		<ShiftCard
			trade_request_id={trade_request_id}
			canSeeTrade={canSeeTrade}
			style={teamColorStyle}
			name={name}
			dragDisabledMessage={reg_shift_id && t('planning.no_drag_hours_written_message')}
			{...props}
			className={classNames}
			hasDragRole={hasManagerRole}
		/>
	)
}

export const ShiftCardPlanning = Inner
