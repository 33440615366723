import EitjeButton from '../eitje_button'
import {useDocsLink} from 'hooks'
import {SHARED_SUPPORT_PROPS} from '../eitje_button/eitje_buttons'

export const DocsButton = props => {
	const {collection, to, t = 'learnMore'} = props
	const condOpts = useDocsLink({collection, to})

	return <EitjeButton data-intercom-target="docs_button" className="docs-button" {...SHARED_SUPPORT_PROPS} t={t} {...props} {...condOpts} />
}
