import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import {useTeamContext, getPlannedHours, TeamDayProvider, useTeamShifts, useTeamDayContext} from 'cores/planning/index'
import TeamDayAvailability from './availability/index'

const TeamAvailabilityDay = ({date}) => {
	return (
		<TeamDayProvider date={date}>
			<TeamDayAvailability date={date} />
		</TeamDayProvider>
	)
}

export default React.memo(TeamAvailabilityDay)
