import {date} from 'initializers/date'
import {rangeForPeriod} from 'helpers/date'
import {exportT} from 'cores/exports2'
import {t} from 'initializers/i18n'
import {getTableName} from 'helpers'
import {ShiftType} from 'models'

export const columnTitle = c => t(`exports2_backend.columns.${c}.title`)
export const columnTooltip = c => t(`exports2_backend.columns.${c}.tooltip`)
export const columnExample = (c, format) => t(`exports2_backend.columns.${c}.mocks.${format}`)
export const columnGroupName = g => t(`exports2_backend.column_group.${g}.title`, t(`exports2.column_group.${g}.name`))
export const formatTranslation = id => t(`exports2_backend.formats.${id}.title`)
export const filterName = (f, o) => t(`exports2_backend.filters.${f}.${o}.title`)
const localFilterName = f => t([`exports2.filters.${f}.name`, `records.default.fields.${f}`])

export const flatToFilter = obj => {
	const filters = []
	Object.keys(obj).forEach(k => {
		filters.push({filter: k, value: obj[k]})
	})

	return filters
}

export const filterToFlat = arr => {
	let filters = {}
	arr.forEach(a => (filters[a.filter] = a.value))
	return filters
}

export const displayFilter = filter => {
	const {value, filter: id} = filter
	if (id == 'shift_types' && value.some(s => _.isNumber(s))) return <ShiftTypes ids={value} name={id} />
	if (_.isArray(value)) return `${localFilterName(id)}: ${value.map(v => t(v)).join(', ')}`
	return filterName(id, value)
}

const ShiftTypes = ({ids}) => {
	const items = ShiftType.where(ids)
	const names = items._map('name').join(', ')
	return `${t('shiftType')}: ${names}`
}
