import {date} from 'initializers/date'
import {t as baseT} from 'initializers/i18n'
import {t, usePerMemberContext} from 'cores/time_registration/index'
import useArbitrary from 'hooks/use_arbitrary'

export const useWagePeriods = ({pageContext}) => {
	const allPeriods = useArbitrary('periods')
	const {startDate, endDate, period: basePeriodType} = pageContext()
	const periodType = basePeriodType.replace('wage_', '')
	let periods = allPeriods[periodType] || []
	periods = periods.map(p => periodMap(p))

	const period =
		periods.find(p => {
			return date(p.start).format() == startDate.format() && date(p.eind).format() == endDate.format()
		}) || periods[periods.length - 1]

	return {periods, allPeriods, period, flex: allPeriods['nul_uren'], fixed: allPeriods['uren'], periodType}
}

const periodMap = period => {
	return {
		...period,
		id: `${period.year}-${period.period}`,
		name: `${baseT('common.period')} ${period.period} | ${humanDate(period.start)} - ${humanDate(period.eind)}  `,
	}
}

const humanDate = _date => date(_date).format('DD/MM/YY')
