const initialState = {
	analytics: {},
	orgAnalytics: {},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState
		case 'SET_VALUE':
			return {
				...state,
				[action.name]: action.payload,
			}
		default:
			return state
	}
}
