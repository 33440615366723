import {useLocalValue} from 'hooks/use_local'
import usePlanningDay from 'cores/planning/hooks/use_planning_day'
import {getPlannedHours} from 'cores/planning/helpers'
import {useDayShifts} from 'cores/planning/hooks/use_filtered_env_shifts'
import {WeatherDay} from 'models'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import {NAMESPACE, useCheckboxLeft} from '.'
import {useEditableShifts} from 'cores/planning/hooks/use_editable_shifts'
import utils from '@eitje/web_utils'

function useWeather(date) {
	const {temperature_low, temperature_high, precip_probability, city} = WeatherDay.find({date})
	const show = useLocalValue('planningShowWeather')
	const hasValue = utils.exists(temperature_low) && utils.exists(temperature_high)
	const placeholder = t(`${NAMESPACE}.weather_unavailable`)
	const value = `${temperature_low}-${temperature_high} °C, ${precip_probability}% ${t(`${NAMESPACE}.rain`)}`

	if (!show) return
	if (hasValue) return value
	return placeholder
}

function useAvailabilityLocked(date) {
	const {orgEnvs} = useShared()
	const show = useLocalValue('planningShowAvLock')
	const envs = orgEnvs.map(e => usePlanningDay(date, e.id)) // ideally would not use hook in map, but this almost never changes...
	const allOpen = envs.every(s => !s.closed)
	const allClosed = envs.every(s => s.closed)

	if (!show) return

	const transKey = allOpen ? 'open' : allClosed ? 'locked' : 'mixed'
	return t(`${NAMESPACE}.availability_${transKey}`)
}

function useTotalTimes(date) {
	const show = useLocalValue('planningShowPlannedTimes')
	const dayShifts = useDayShifts(date)
	const {timeStringWithText} = getPlannedHours(dayShifts)

	return show && timeStringWithText
}

export function useDayItems(date) {
	return [useWeather(date), useAvailabilityLocked(date), useTotalTimes(date)]
}

export function useDayCheckbox(date) {
	const shifts = useEditableShifts(useDayShifts(date))
	return useCheckboxLeft(shifts)
}
