import Shift from './shift'
import {API} from '@eitje/easy_api'

class TimeRegistrationShift extends Shift {
	static defaultJoins = ['users']
	static improductiveTypes = ['verlof', 'bijzonder_verlof', 'ziek']
	static tableName = 'timeRegistrationShifts'

	isImproductive() {
		return this.constructor.improductiveTypes.includes(this.typ)
	}

	improductiveType() {
		if (this.typ === 'verlof') return 'leave'
		if (this.typ === 'bijzonder_verlof') return 'special_leave'
		if (this.typ === 'ziek') return 'sick'
		return null
	}

	relevantForWage() {
		return this.typ != 'verlof'
	}

	static unproductive(params, opts) {
		const prms = {typ: this.improductiveTypes, ...params}
		return TimeRegistrationShift.where(prms, opts)
	}

	async destroy() {
		const res = await super.destroy()
		if (res?.ok && this.plan_shift_id) {
			API.getByIds('planning_shifts', [this.plan_shift_id])
		}
	}
}

export default TimeRegistrationShift
