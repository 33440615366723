import {AuthPageLayout} from 'cores/auth'
import {login} from 'actions/auth'
import {useQueryParams} from 'hooks'
import {EmailInput, PasswordInput} from 'lib/form/fields'
import {Redirect} from 'components/routing'

export const Login = () => {
	const {email} = useQueryParams()

	// If no email is present in the query param, we want to force users to use the welcome form, so that they may be redirected to the appropriate
	// place and because below email is readOnly for the same reason.
	if (!email) return <Redirect to="/" />

	return (
		<AuthPageLayout
			autoFocus={false}
			name="login"
			callToActions="forgot_password"
			onSubmit={data => login(data.email, data.password)}
			initialValues={{email}}
			backButton
		>
			<EmailInput />
			<PasswordInput autoFocus validate={false} />
			{/*Don't show password requirements for login in, only relevant when setting a password*/}
		</AuthPageLayout>
	)
}
