import utils from '@eitje/web_utils'
import {InputNumber} from 'antd'
import {makeField} from '@eitje/form'
import './styles/simple_input_number.less'

const SimpleInputNumber = props => {
	const {onChange, min, max, disableLoop, placeholder = '-', value, disabled} = props
	const allowDecrease = !utils.exists(min) || !utils.exists(value) || value > min
	const allowIncrease = !utils.exists(max) || !utils.exists(value) || value < max

	const decrease = () => {
		if (!disableLoop) {
			if (!utils.exists(value)) return onChange(max)
			if (value <= min) return onChange(null)
		}
		allowDecrease && onChange(value - 1)
	}

	const increase = () => {
		if (!disableLoop && value >= max) return onChange(null)
		if (!allowIncrease) return
		onChange(utils.exists(value) ? value + 1 : min)
	}

	return (
		<div className="eitje-simple-input-number">
			{!disabled && <img onClick={decrease} src={'/images/web/icons/soft-right-pointer.png'} width={5} height={8} className="left-arrow" />}
			<InputNumber bordered={false} {...props} />
			{!disabled && (
				<img onClick={increase} src={'/images/web/icons/soft-right-pointer.png'} width={5} height={8} className="right-arrow" />
			)}
		</div>
	)
}

const FormSimpleInputNumber = makeField(SimpleInputNumber, {className: 'eitje-simple-input-number-container'})
export {FormSimpleInputNumber}
export default SimpleInputNumber
