import {NAMESPACE} from 'cores/onboarding'
import {useDocsLink} from 'hooks'
import {t} from 'initializers/i18n'

export const useOnboardingStepsTaxonomy = () => {
	const teamVsEnvDocs = useDocsLink({to: 9925228})
	const setupClockingDocs = useDocsLink({to: 6037228})

	const baseTaxonomy = [
		{
			id: 'create_environment',
			youTubeId: '_R_XtNDr03A',
			hasModal: false,
		},
		{
			id: 'create_team',
			youTubeId: 'tHG_CGDh2oo',
			tourId: 565735,
			tourLink: '/users/in_service',
			extraInfoButton: {
				popoutButton: {
					iconLeft: 'document',
					...teamVsEnvDocs,
				},
			},
		},
		{
			id: 'invite_users',
			youTubeId: '2FYEL1_4MJ0',
			tourId: 565740,
			tourLink: '/users/in_service',
			extraInfoButton: {
				popoutButton: {
					iconLeft: 'play',
					link: '/integrations/new',
				},
			},
		},
		{
			id: 'create_planning_shift',
			youTubeId: 'CmvgzQZlngI',
			tourLink: '/planning/per_team',
			tourId: 536520,
		},
		{
			id: 'create_time_registration_shift',
			youTubeId: 'S-cshNmSb8w',
			tourId: 536553,
			tourLink: '/hours/per_week',
			extraInfoButton: {
				popoutButton: {
					iconLeft: 'document',
					...setupClockingDocs,
				},
			},
		},
	]

	return baseTaxonomy.map(taxItem => {
		const {id} = taxItem
		const ns = `${NAMESPACE}.steps.${id}`

		return {
			...taxItem,
			stepTitle: t(`${ns}.step_title`),
		}
	})
}

export const useOnboardingModalTaxonomy = () => {
	const baseTaxonomy = useOnboardingStepsTaxonomy().filter(taxItem => taxItem.hasModal !== false)

	return baseTaxonomy.map(taxItem => {
		const {id, extraInfoButton} = taxItem
		const ns = `${NAMESPACE}.steps.${id}`
		const buttonNs = `${ns}.modal_extra_info_button`

		const extraInfo = extraInfoButton && {
			modalExtraInfoButton: {
				...extraInfoButton,
				label: t(`${buttonNs}_label`),
				title: t(`${buttonNs}_title`),
				body: t(`${buttonNs}_body`),
				button: t(`${buttonNs}_button`),
			},
		}

		return {
			...taxItem,
			...extraInfo,
			namespace: ns,
			modalLink: `/onboarding/${id}`,
			previewTitle: t(`${ns}.preview_title`),
			previewSubtitle: t(`${ns}.preview_subtitle`),
			modalTitle: t(`${ns}.modal_title`),
			modalSubtitle: t(`${ns}.modal_subtitle`),
			modalDescription: t(`${ns}.modal_description`),
			modalActionButtonLabel: t(`${ns}.modal_action_button_label`),
		}
	})
}
