import FlipMove from 'react-flip-move'
import utils from '@eitje/web_utils'
import {Notification} from './notification'
import {Layout} from '../layout'
import './styles/index.less'

export const NotificationsContainer = () => {
	const {notifications, centerNotifications, removeNotification, removeCenterNotification} = utils.useNotifContext()
	return (
		<>
			<Layout className="notifications-container" direction="vertical" gap={8} horizontal="end">
				<FlipMove typeName={null}>
					{notifications.map(item => (
						<Notification key={item.id} handleClose={() => removeNotification(item.id)} {...item} />
					))}
				</FlipMove>
			</Layout>
			<Layout className="center-notifications" direction="vertical" gap={8}>
				{centerNotifications.map(item => (
					<Notification small key={item.id} handleClose={() => removeCenterNotification(item.id)} {...item} />
				))}
			</Layout>
		</>
	)
}
