import {API} from '@eitje/easy_api'
import {store} from 'index'
import api from 'initializers/api'
import _ from 'lodash'

function scrubPost(post) {
	post.gs_id = post.id
	post.id = post.object.id
	post.team_id = post.actor.id
	return post
}

export function postScrubber(posts) {
	if (!_.isArray(posts)) {
		return scrubPost(posts)
	}
	posts = posts.map(post => scrubPost(post))
	return posts.filter(p => !_.isString(p.author))
}

export async function getPosts({refresh = false, isOlder}) {
	const state = store.getState()
	const {items} = state.news
	store.dispatch({type: 'FETCHING_POSTS'})
	const stamp = items.length > 0 && !refresh && isOlder ? _.last(state.news.items).created_at : null
	const res = await api.get('posts', {lastUpdatedStamp: stamp, newest: true})
	store.dispatch({type: 'FETCHED_POSTS'})

	if (res.ok) {
		const posts = postScrubber(res.data.posts)
		store.dispatch({type: 'POSTS', items: refresh ? posts : items.concat(posts), done: posts.length < 10})
	}
}

export function cheer(id) {
	API.toggle('posts', {id, field: 'liking_users'})
}

export async function postComment(body, postId) {
	if (!body || body.length == 0) return
	return API.create('comments', {post_id: postId, body}, {localKind: 'posts'})
}
