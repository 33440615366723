import {usePlanningContext, useScheduledShifts} from 'cores/planning/index'
import {filterActive} from 'helpers'
import {t} from 'initializers/i18n'
import {useFilterFooterContext, EitjeButton} from 'common/components'
import {useRoleMessage} from 'hooks'

export const Publish = props => {
	const {dateRange} = usePlanningContext()
	const {
		envs,
		hasFiltered,
		filterValues: {teamIds},
	} = useFilterFooterContext()
	let {planShifts} = useScheduledShifts({dateRange, teamIds: teamIds})
	const unpublished = planShifts._filter('!published')

	const anyUnpublished = unpublished.length > 0
	const notManager = useRoleMessage({roles: 'manager', env: envs})
	const disabled = notManager || (!anyUnpublished && t('planning.disabled.no_unpublished_shifts'))
	const baseLabel = t('planning.environment_table.publish')
	const label = disabled ? baseLabel : `${baseLabel} (${unpublished.length})`

	return (
		<EitjeButton
			{...props}
			modalLink={`/planning/publish`}
			className="button-publish"
			colorSet="solid"
			iconLeft="paper-plane"
			disabled={disabled}
			children={label}
			queryParams={{
				team_ids: teamIds,
				env_ids: envs.ids(),
				...filterActive(hasFiltered.team),
			}}
		/>
	)
}
