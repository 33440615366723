import React, {useState, useEffect, Fragment} from 'react'
import {useAsyncEffect, useIncrementState} from '@eitje/react-hooks'
import {backend} from '@eitje/easy_api'

export const useGet = (endPoint, {params = {}, watch, skipInitial} = {}) => {
	const [retries, retry] = useIncrementState()
	const [data, setData] = useState({})
	const [error, setError] = useState()
	const [loading, setLoading] = useState(true)
	useAsyncEffect(async () => {
		if (!skipInitial || retries > 0) {
			setLoading(true)
			const res = await backend.get(endPoint, params)
			setLoading(false)

			if (res.ok) {
				setData(res.data)
			} else {
				setError(true)
			}
		}
	}, [retries, watch])

	return {data: data || {}, error, loading, retry}
}

export default useGet
