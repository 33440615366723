import {useTeamFilterForm} from 'hooks/use_team_filter'
import {useManagerTeams} from 'hooks'

export const usePlanningTeamFilter = props => {
	const {team_ids} = props
	const managerTeamIds = useManagerTeams(team_ids).ids()

	const {filter: teamFilter, ...rest} = useTeamFilterForm({
		form: true,
		easyRemove: true,
		schedulableTeams: true,
		icon: null,
		...props,
		filterTeam: ({id}) => managerTeamIds.includes(id),
	})
	return {teamFilter, ...rest}
}
