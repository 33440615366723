import _ from 'lodash'

export const isTimeDisabled = (now, props) => {
	const {formData, isStart, isEnd, startDateField = 'start_time', endDateField = 'end_time'} = props

	let disabledHours = []
	let disabledMinutes = () => []

	if (!isStart && !isEnd) return {disabledHours: () => [1, 2, 3]}
	const endVal = formData[endDateField]
	const startVal = formData[startDateField]

	if (isStart && endVal) {
		const [hour, min] = endVal.split(':').map(v => Number(v))

		disabledMinutes = selHour => (hour == selHour ? _.range(min, 60) : [])
		disabledHours = _.range(min == 0 ? hour : hour + 1, 24)
	}
	if (isEnd && startVal) {
		const [hour, min] = startVal.split(':').map(v => Number(v))
		disabledMinutes = selHour => (hour == selHour ? _.range(0, min + 1) : [])
		disabledHours = _.range(0, hour)
	}

	return {disabledHours: () => disabledHours, disabledMinutes}
}
