import {t} from 'initializers/i18n'
import {useLastPathParam} from 'actions/routing'
import {Environment, Allowance} from 'models'
import {ModalForm, CompositeField} from 'components/form/index'
import {useModalContext} from 'components/common/'
import {useGet, useParams} from 'hooks'
import {Message, Layout, ListPicker, Text} from 'common/components'
import {Spin} from 'antd'
import {FormRow} from 'components/ui'
import {useFormData} from 'hooks'
import date from 'initializers/date'
import {goToNext} from 'common/components'
import {history} from 'index'

const dayNums = [...Array(28).keys()].map(i => i + 1)

export const NmbrsSettings = props => {
	const {item} = props
	return (
		<ModalForm afterSubmit={() => goToNext(props)} onSubmit={item.update} transNamespace="integ_hr_settings" initialValues={item}>
			<Body item={item} />
		</ModalForm>
	)
}

const Body = ({item}) => {
	const data = useFormData()

	const {renumeration_period, current_period} = item
	if (!current_period) return <p> {t('unexpectedIssue')} </p>
	const [year, period] = current_period.split('-')
	const {startDate, endDate} = getStartDate({...item, year, period, ...data, renumeration_period: item['renumeration_period']})
	const name = 'modals.setup_hr.nmbrs_settings'
	return (
		<Layout width="full" name={name} direction="vertical">
			<Message
				t={{
					key: 'message',
					period,
					startDate: startDate.ftime('date'),
					endDate: endDate.ftime('date'),
					type: item.renumerationTypeTranslation(),
				}}
			/>
			<FormRow>
				<ListPicker
					disabled={renumeration_period != 'month' && `${name}.only_monthly`}
					showSearch={false}
					required
					noSort
					single
					buildLabel={item => item}
					items={dayNums}
					dropdown
					field="renumeration_start_day"
				/>
			</FormRow>
		</Layout>
	)
}

const getStartDate = ({renumeration_period, current_period, ...rest}) => {
	if (renumeration_period == 'month') return getMonthDate(rest)
	if (renumeration_period == 'week') return getWeekDate(rest)
	return getFourWeekDate(rest)
}

const getMonthDate = ({year, period, renumeration_start_day}) => {
	let startDate
	if (renumeration_start_day == 1) {
		startDate = date().year(year).startOf('year')
	} else {
		startDate = date()
			.year(year - 1)
			.month(11) // moment months start at 0
			.date(renumeration_start_day)
	}

	return {startDate: startDate.add(period - 1, 'months'), endDate: startDate.clone().add(1, 'month').subtract(1, 'day')}
}

const getWeekDate = ({year, period}) => {
	const startDate = date().year(year).startOf('year').startOf('isoWeek')

	const amtWeeks = period - 1
	return {startDate: startDate.add(amtWeeks, 'weeks'), endDate: startDate.clone().endOf('week')}
}

const getFourWeekDate = ({year, period}) => {
	const amtWeeks = (period - 1) * 4
	const startDate = date().year(year).startOf('year')
	return {startDate: startDate.add(amtWeeks, 'weeks'), endDate: startDate.clone().add(4, 'weeks').subtract(1, 'day')}
}
