import {useCachedFile} from 'actions/file_cacher'

export const useExportTaxonomy = () => {
	const exportTax = useCachedFile('export2_taxonomy')
	if (!exportTax) return {}

	const {tables} = exportTax
	const exporterNames = Object.keys(tables)
	const recordNames = exporterNames.map(e => e.replace('_table', ''))

	// let columnGroupNames = exporterNames
	//   .map(e => exporters[e].auxiliary_column_groups)
	//   .flat()
	//   .uniq()
	// const exporterSnakeNames = exporterNames.map(e => _.snakeCase(e))
	// columnGroupNames = [...exporterSnakeNames, ...columnGroupNames]
	// const columnNames = Object.keys(exportTax.columns)

	return {...exportTax, recordNames, names: exporterNames}

	// return {...exportTax, columnNames, columnGroupNames, exporterNames, exporterSnakeNames}
}

export default useExportTaxonomy
