import React, {Fragment} from 'react'
import {date} from 'initializers/date'
import {Checkbox} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import Dropdown from 'components/dropdown'
import {useForm} from '@eitje/form'
import {DeleteButton, EitjeButton, Text} from 'common/components'
import {EitjeAvatar, Layout} from 'common/components'
import './styles/open_shift_card.less'

const getSubtext = ({allow_pickup, pickup_notification, hideTrade, trade_request_id}) => {
	if (hideTrade) return 'trade_expired'
	if (trade_request_id) return 'trade_active'
	if (!allow_pickup) return 'no_trade'
	if (allow_pickup && pickup_notification) return 'trade_and_notif'
	return 'trade'
}

const base = 'planning.shift_modal.open_shift'

const OpenShiftCard = ({shift = {}}) => {
	const {getData} = useForm()
	const data = getData()
	const {dates = []} = data
	const {trade_request_id} = shift

	const _date = dates[0]
	const hideTrade = date(_date).inPast()

	const _data = {...shift, ...data, hideTrade}
	const subtext = t(`${base}.${getSubtext(_data)}`)

	return (
		<div className="user-shift-card">
			<Layout gap={8}>
				<EitjeAvatar radius={40} type="open-shift" />

				<Layout grow direction="vertical" gap={0}>
					<Text bold> {t('common.open_shift')} </Text>
					{_date && (
						<Text darkGrey mini>
							{subtext}
						</Text>
					)}
				</Layout>

				{!hideTrade && _date && (
					<Fragment> {trade_request_id ? <DestroyTrade id={trade_request_id} /> : <TradeDropdown {...data} />} </Fragment>
				)}
			</Layout>
		</div>
	)
}

const DestroyTrade = ({id}) => {
	const _base = `${base}.destroy_trade`
	return (
		<DeleteButton
			iconLeft={null}
			height="mini"
			className="destroy-trade-button"
			text={t(`${_base}.confirm`)}
			onClick={() => API.destroy('trade_requests', id)}
		>
			{t(`${_base}.button`)}
		</DeleteButton>
	)
}

const TradeDropdown = ({allow_pickup}) => {
	const elements = [<Checkbox field="allow_pickup" />, <Checkbox disabled={!allow_pickup} field="pickup_notification" />]

	return (
		<Dropdown overlayClassName="allow_pickup-dropdown" visibleAfterClick elements={elements}>
			<EitjeButton height="mini" className="allow-pickup-button">
				{t(`${base}.trade_button`)} ⭣{' '}
			</EitjeButton>
		</Dropdown>
	)
}

export default OpenShiftCard
