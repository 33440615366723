import _ from 'lodash'

_.mixin({
	deeply: function (map) {
		return function (obj, fn) {
			return map(
				_.mapValues(obj, function (v) {
					return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v
				}),
				fn,
			)
		}
	},
	deepTransformValues: (obj, mapper) => {
		return _.deeply(_.mapValues)(obj, mapper)
	},
	isEqIgnoreFn: (item1, item2) => {
		return _.isEqualWith(item1, item2, compare)
	},
})

const compare = (item1, item2) => {
	if (_.isFunction(item1) && _.isFunction(item2)) return `${item1}` == `${item2}`
	return undefined
}
