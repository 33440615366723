import {t} from 'initializers/i18n'
import {PlanningShift} from 'models'
import {ModalLink} from 'components/routing'
import {MultiAction as _MultiAction} from 'components/common/index'
import {Text} from 'common/components'

const ns = 'planning.multi_edit.multi_action'

// This floating MultiAction bar is used by three pages, separated into two components:
// 1. for planning per team and per week, which relies on the useEnvsContext for its state management
// 2. for planning per table, which diverts its state management to the TanStack table (just like
// 		the time registration AdvancedTable instance).
// All consumer code lives in the consumer directory, below should only be the shared dropdown options.

export const MultiAction = ({shiftIds, close}) => {
	const count = shiftIds.length
	const titleKey = count > 0 ? 'has_selected' : 'select_to_edit'
	const title = t(`${ns}.${titleKey}`, {count})

	const togglePublishedStatus = bool => updateField({shiftIds, field: 'published', value: bool})

	const elements = [
		<ModalLink t={`${ns}.edit`} to={`/planning_shifts/multi_edit?ids=${shiftIds}`} />,
		<ModalLink t={`${ns}.save_as_template`} to={`/planning_templates/save?ids=${shiftIds}`} />,
		<ModalLink t={`${ns}.delete`} to={`/confirm?ids=${shiftIds}&type=planning_shift`} />,
		{
			title: t(`${ns}.pickup_status`),
			children: [
				<ModalLink t={`${ns}.allow_pickup`} to={`/confirm?ids=${shiftIds}&type=allow_pickup`} />,
				<ModalLink t={`${ns}.deny_pickup`} to={`/confirm?ids=${shiftIds}&type=deny_pickup`} />,
			],
		},

		{
			title: t(`${ns}.publish_status`),
			children: [
				<Text onClick={() => togglePublishedStatus(true)} t={`${ns}.publish_selected`} />,
				<Text onClick={() => togglePublishedStatus(false)} t={`${ns}.depublish_selected`} />,
			],
		},
	]

	return <_MultiAction count={count} elements={elements} close={close} title={title} />
}

const updateField = ({shiftIds, field, value}) => {
	const items = shiftIds.map(id => ({id, [field]: value}))
	PlanningShift.multiUpdate(items)
}
