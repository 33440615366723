import {finishArticle, finishQuizItem} from 'actions/training'
import useParams from 'hooks/use_params'
import {useSelector} from 'react-redux'
import {trainingItemsSelector} from 'selectors/training'
import Index from './index'

const nextStep = ({activeIdx, training_resources}) => {
	const nextIdx = training_resources.findIndex(tr => training_resources.indexOf(tr) != activeIdx && !tr.finished)
	return {
		done: nextIdx == -1,
		nextIdx,
	}
}

const Page = () => {
	const {id} = useParams()
	const trainingItems = useSelector(trainingItemsSelector)
	const trainingItem = trainingItems.find(t => t.id == id) || {}
	const {training_resources = []} = trainingItem
	const currentIdx = training_resources.findIndex(tr => !tr.finished)

	return (
		<Index
			increment={p => nextStep({...p, training_resources})}
			onNextArticle={finishArticle}
			onNextQuizItem={finishQuizItem}
			initialIdx={currentIdx}
		/>
	)
}

export default Page
