import {exportT} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {steps} from '../constants'
import {BackButton, NextButton} from 'common/components'
import {useSimpleContext} from 'contexts/simple'
import {history} from 'index'
import {Steps} from 'antd'
import utils from '@eitje/web_utils'
import './styles/index.less'
import {useHotkeys} from 'react-hotkeys-hook'

const AntStep = Steps.Step
export const Footer = () => {
	const {activeStep, activeStepItem, item, baseUrl, updateState, resetUpdateState, nextStep} = useSimpleContext()
	const isDone = activeStepItem?.done(updateState)

	const goToNext = async () => {
		if (!isDone) return utils.errorNotif({content: t('exports2.table_edit.save.name_required')})
		await item.update(updateState)
		if (nextStep) return history.push(`${baseUrl}/${nextStep?.name}`)
		return history.push(`/exports2/tables/${item.id}`)
	}

	const goToPrev = async () => {
		await resetUpdateState?.()
		return history.goBack()
	}

	useHotkeys('meta+enter', goToNext)

	return (
		<div className="table-edit-footer">
			<BackButton onClick={goToPrev} />

			<Steps size="small" labelPlacement="vertical" className="table-edit-footer-progress" current={activeStep}>
				{steps.map((s, i) => (
					<Step isActive={activeStep == i} step={s} />
				))}
			</Steps>

			{item && (
				<div className="table-edit-footer-right">
					<p className="next-step-text"> {t('common.next_cmd_enter')} </p>
					<NextButton onClick={goToNext} />
				</div>
			)}
		</div>
	)
}

const Step = ({step, isActive, ...props}) => {
	const {item, updateState} = useSimpleContext()
	const {name, FooterSub, done} = step
	const fullItem = {...item, ...updateState}
	const isDone = done(fullItem)
	const title = exportT(`table_edit.footer.${name}.title`)
	const descr = isDone ? <FooterSub item={item} /> : null
	const status = isActive ? 'process' : 'waiting'
	return <AntStep title={title} description={descr} {...props} status={status} />
}
