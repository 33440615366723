import {BasicPopout} from '@eitje/web_components'
import {YouTube, Layout, Text, EitjeButton} from 'common/components'
import {EitjeModal} from 'components/common'
import {NAMESPACE, useOnboardingModalTaxonomy, useOnboardingStatus} from 'cores/onboarding'
import {navigate} from 'components/routing'
import {mdT, t} from 'initializers/i18n'

export const YouTubeModal = ({youTubeId, name, title, subtitle, buttons, location}) => {
	return (
		<EitjeModal name={name} title={title} buttons={buttons} width={700} goBackToForeground>
			<Layout direction="vertical" gap={16} padding={24}>
				<YouTube id={youTubeId} autoPlay />
				<Text children={subtitle} />
			</Layout>
		</EitjeModal>
	)
}
