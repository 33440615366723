import {store} from 'index'
import {dateToString} from 'helpers'

// The action is the name of the reducer, so that below function may be reused by different cores, which target different
// slices of the cache.

export function baseSetRawDates(action) {
	return (start, end, period) => {
		store.dispatch({
			type: action,
			startDate: dateToString(start),
			endDate: dateToString(end),
			period,
		})
	}
}

export function baseSetPeriod(action) {
	return period => {
		store.dispatch({
			type: action,
			period,
		})
	}
}

export function getPeriod(startDate, endDate) {
	const diff = endDate.startOf('day').diff(startDate.startOf('day'), 'days')
	if (startDate.clone().startOf('month').format() == startDate.format() && endDate.clone().endOf('month').format() == endDate.format())
		return 'month'

	if (diff == 0) return 'day'
	if (diff == 6) return 'week'
	return 'custom'
}
