import {usePlanningContext} from 'cores/planning/index'
import {WeekTableDaysHeader as BaseHeader, checkboxAsLeftContent} from 'common/components/week_table'
import {useDayItems, useDayCheckbox} from './day_content'
import {usePanelItems, usePanelCheckbox} from './panel_content'
import {DayDropdown, PanelDropdown} from './components/dropdown'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {MultiActionCheckbox} from 'cores/planning/components/multi_action_checkbox'

export const NAMESPACE = 'planning.week_table_days_header'

export const WeekTableDaysHeader = () => {
	const {dateArray} = usePlanningContext()
	return (
		<BaseHeader
			dateArray={dateArray}
			PanelDropdown={PanelDropdown}
			DayDropdown={DayDropdown}
			dayContent={useDayItems}
			dayContentLeft={useDayCheckbox}
			panelContent={usePanelItems}
			panelContentLeft={usePanelCheckbox}
		/>
	)
}

export function useCheckboxLeft(shifts) {
	const {multiEdit} = useEnvsContext()
	return checkboxAsLeftContent({shifts, checkbox: multiEdit && MultiActionCheckbox, isOrganisationLevel: true})
}
