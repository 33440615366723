const {useEffect} = require('react')

export const useNodeAddedObserver = ({ref, nodeName, cb}) => {
	const container = ref?.current
	useEffect(() => {
		if (!container) return

		const observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.addedNodes?.length) {
					Array.from(mutation.addedNodes).forEach(node => {
						if (node.nodeName === nodeName) {
							cb?.()
						}
					})
				}
			})
		})

		observer.observe(container, {
			childList: true,
			subtree: true,
		})

		return () => {
			observer.disconnect()
		}
	}, [container])
}
