import {TABLE_INFO} from 'common/components/advanced_table/instances/planning'
import {ColumnGroupingPickers} from 'common/components/advanced_table/consumers'
import {PeriodPickers} from 'common/components/advanced_table/consumers'
import {PageHeader as _PageHeader} from 'common/components'
import {Help} from './help'
import {usePlanningTableContext} from '..'
import {RouteSelect} from 'cores/planning/components'
import {Tools} from './tools'

export const PageHeader = () => {
	const context = {pageContext: usePlanningTableContext}

	return (
		<_PageHeader
			label="common.planning_shifts"
			height="small"
			left={[<RouteSelect currentVal="/planning_table" />, <PeriodPickers {...context} />]}
			right={[<ColumnGroupingPickers {...TABLE_INFO} />, <Tools />, <Help />]}
		/>
	)
}
