import utils from '@eitje/web_utils'

String.prototype.hashCode = function () {
	var hash = 5381,
		i = this.length
	while (i) hash = (hash * 33) ^ this.charCodeAt(--i)
	return hash >>> 0
}

String.prototype.capitalize = function () {
	return utils.capitalize(this)
}

String.prototype.lowercaseFirstLetter = function () {
	return this.charAt(0).toLowerCase() + this.slice(1)
}
