import {TABLE_INFO} from 'common/components/advanced_table/instances/users_out_of_service'
import {PageHeader as _PageHeader, useFilterFooterContext} from 'common/components'
import {ColumnGroupingPickers} from 'common/components/advanced_table/consumers'
import {HelpButton, RouteSelect, Tools} from 'cores/firm/pages/users/in_service/page_header'

export const PageHeader = () => {
	// We currently don't have a tour for team members out of service, but the in service tour won't work, so overwrite.
	const tourId = null

	return (
		<_PageHeader
			height="small"
			label="common.users_out_of_service"
			left={[<RouteSelect currentVal="out_of_service" />]}
			right={[<ColumnGroupingPickers {...TABLE_INFO} />, <Tools />, <HelpButton tourId={tourId} />]}
		/>
	)
}
