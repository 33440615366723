import useEventListener from './use_event_listener'

export const useCatchCssChunkError = () => {
	const handleError = event => {
		if (/Loading chunk [\d]+ failed./i.test(event.message)) {
			window.location.reload()
		}
	}
	useEventListener('error', handleError)
}
