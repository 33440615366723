import utils from '@eitje/web_utils'
import {useEffect} from 'react'

const parentCheck = (classNames = [], event) => {
	if (event?.target?.parentElement) {
		const parentsClass = event?.target?.parentElement?.offsetParent?.classList || []
		return classNames.some(c => [...parentsClass].includes(c))
	}
	return false
}

function useOutsideClick(ref, onClick, {classNames = [], checkParents = true} = {}) {
	useEffect(() => {
		function handleClickOutside(event) {
			const refClicked = ref.current && ref.current.contains(event.target)
			const classNameClicked = classNames.some(c => [...event.target.classList].includes(c))
			const offsetClicked = checkParents && parentCheck(classNames, event)
			if (!refClicked && !classNameClicked && !offsetClicked) {
				onClick()
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}

export default useOutsideClick

export const useNewOutsideClick = ({onClick, parentClass, ref}) => {
	parentClass = utils.alwaysArray(parentClass)
	const _handleClickOutside = e => handleClickOutside(e, onClick, parentClass, ref)
	useEffect(() => {
		document.addEventListener('mousedown', _handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', _handleClickOutside)
		}
	}, [])
}

export const handleClickOutside = (event, onClick, matchClasses, ref) => {
	const extraCheck = () => ref?.current?.contains(event.target)
	const match = ancestorHasCns(event.target, matchClasses, extraCheck)

	if (!match) onClick()
}

export const ancestorHasCns = (element, classNames) => {
	classNames = utils.alwaysArray(classNames)
	element = element?.parentElement
	let match

	while (element && !match) {
		const classList = Array.from(element.classList)
		match = utils.intersects(classList, classNames)
		element = element?.parentElement
	}

	return !!match
}

export const selfOrAncestorHasCns = (element, classNames, extraCheck) => {
	classNames = utils.alwaysArray(classNames)
	let match

	while (element && !match) {
		const classList = Array.from(element.classList)
		match = utils.intersects(classList, classNames) || extraCheck?.()
		element = element?.parentElement
	}

	return !!match
}
