import {all, API, find, where, whereNot} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker, LegacyInput as Input, LegacyDatePicker as DatePicker} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import {Button} from 'antd'
import useList from 'components/general/list'
import Modal from 'components/general/modal'
import {Routes, SideMenu} from 'components/routing'
import {DeleteButton, EitjeButton, Layout, SubmitButton} from 'common/components'
import {useContract, useSingleContract} from 'hooks/use_contract'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import {ExtraFooter} from 'pages/modals/new_contract'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams, useRouteMatch} from 'react-router-dom'
import Documents from './documents'
import {Salary, SalaryMutation} from './salary'
import {Schedule, ScheduleMutation} from './schedule'
import Settings from './settings'

const hasMutation = contract => contract.salaries?.length > 1 || contract.workSchedules?.length > 1 || contract.userEmpTypes?.length > 1

const Details = ({contract, fullContract}) => {
	const [indefinite, setIndefinite] = useState(false)
	const holders = useSelector(state => where(state, 'contractHolders', {user_id: contract.user_id}))
	const employmentTypes = useSelector(state => all(state, 'employmentTypes'))
	const disabledBefore = utils.getMax(
		holders.filter(h => h.start_date < contract.start_date),
		'end_date',
	)?.end_date // hoogste einddatum van contract die wel eerder begint dan ik
	const disabledAfter = utils.getMin(
		holders.filter(h => h.start_date > contract.start_date),
		'start_date',
	)?.start_date
	const _hasMutation = hasMutation(fullContract)
	return (
		<Form
			fieldProps={{readOnly: _hasMutation, bordered: false}}
			onSubmit={data => API.resourceReq('contract_holders', 'change_period', data)}
			initialValues={contract}
		>
			<div fieldWrapper className="modal-form-field-area">
				<DropdownPicker readOnly items={employmentTypes} buildLabel={val => t(val)} field="employment_type_id" />

				<DatePicker
					placeholder={t('selectDate')}
					disabledBefore={disabledBefore}
					disabledAfter={disabledAfter}
					allowClear={false}
					isStart
					field="start_date"
				/>

				{!indefinite && (
					<DatePicker
						renderExtraFooter={props => <ExtraFooter setIndefinite={() => setIndefinite(true)} {...props} />}
						placeholder={t('selectDate')}
						disabledBefore={disabledBefore}
						placeholder={t('indefinite')}
						allowClear={false}
						disabledAfter={disabledAfter}
						isEnd
						field="end_date"
					/>
				)}

				{indefinite && (
					<Input
						rightChildren={
							<img
								onClick={() => setIndefinite(false)}
								src="/images/easyRemove.png"
								style={{width: 14, position: 'absolute', marginTop: 16, right: 38}}
							/>
						}
						readOnly
						value={t('ccontract2')}
						field="end_date"
					/>
				)}

				{_hasMutation && (
					<div
						className="blocks fRow aCen jCen"
						style={{width: '92%', marginTop: 12, marginLeft: '4%', alignSelf: 'center', textAlign: 'center', padding: 12}}
					>
						<p className="eitje-text-secondary">{t('cantEditContractMutation')} </p>
					</div>
				)}
			</div>

			<div className="modal-inner-footer gap-xss" fieldWrapper>
				<Layout fieldWrapper>
					{!_hasMutation && (
						<DeleteButton className="margin-right-xss" onDel={() => API.destroy('contract_holders', contract.id)}>
							{t('delete')}
						</DeleteButton>
					)}
					<EitjeButton iconLeft="pencil" modalLink="mutation" t="createMutation" />
					<SubmitButton submitButton showAfterTouch />
				</Layout>
			</div>
		</Form>
	)
}

const DetailsMutation = ({contract}) => {
	const {employmentType = {}, user_id, environment_id} = contract
	let employmentTypes = useSelector(state => whereNot(state, 'employmentTypes', {personal: true}))
	employmentTypes = employmentTypes.filter(e => e.environment_id === environment_id)
	return (
		<Form
			fieldProps={{bordered: false}}
			afterSubmit={history.goBack}
			onSubmit={data => API.arbitrary('user_employment_types', 'mutation', {...data, user_id, environment_id: contract.environment_id})}
		>
			<div fieldWrapper className="modal-form-field-area">
				<DatePicker
					placeholder={t('selectDate')}
					defaultPickerValue={employmentType.start_date}
					disabledAfter={contract.end_date}
					disabledBefore={employmentType.start_date}
					field="start_date"
					label="mutationDate"
					required
				/>
				<DropdownPicker placeholder={t('select')} required items={employmentTypes} field="employment_type_id" />
			</div>
			<div className="modal-inner-footer gap-xss" fieldWrapper>
				<Button onClick={history.goBack}> {t('cancel')} </Button>
				<Button style={{marginLeft: 4}} type="primary" submitButton showAfterTouch>
					{t('save')}
				</Button>
			</div>
		</Form>
	)
}

const ContractModal = () => {
	const {hasContractRole} = useShared()
	const {id} = useParams()
	const {path} = useRouteMatch()
	const holder = useSelector(state => find(state, 'contractHolders', id))
	const contract = useSingleContract(holder)
	const fullContract = useContract(holder)
	const basePath = `/forms/contracts/${id}`
	const sharedProps = {contract, fullContract}
	const hasFixed = fullContract.employmentTypes.some(typ => typ.fixed)

	const routes = [
		{
			name: 'details',
			component: Details,
			subRoutes: DetailsMutation,
		},
		{
			name: 'salary',
			component: Salary,
			subRoutes: SalaryMutation,
			count: ({fullContract}) => fullContract.salaries.length,
		},
		hasFixed && {
			name: 'workweek',
			component: Schedule,
			subRoutes: ScheduleMutation,
			count: ({fullContract}) => fullContract.workSchedules.length,
		},
		{name: 'documents', component: Documents},
		{name: 'settings', component: Settings, count: ({fullContract}) => fullContract.employmentTypes.length},
		{name: 'audits', component: Audits},
	].filter(Boolean)

	return (
		<Modal redirectIf={!contract.id || !hasContractRole} wrapClassName="contracts modal-wrapper" size={'md'} title={t('contractInfo')}>
			<div className="modal-col vertical-padding-xs gap-xss padding-top-xss">
				<SideMenu
					subRoutePath="mutation"
					namespace="contract"
					showSearch={false}
					warning={({count}) => count == 0}
					routeProps={sharedProps}
					routes={routes}
					path={basePath}
				/>
			</div>
			<div className="modal-col-body-area">
				<Routes subRoutePath="mutation" routes={routes} path={path} routeProps={sharedProps} />
			</div>
		</Modal>
	)
}

export default ContractModal

const Audits = ({contract}) => {
	const {audits = []} = contract
	const {list} = useList({items: audits, ListItem: Audit})
	return <div>{list}</div>
}

const Audit = ({item}) => {
	const {naam, tijd} = item
	const name = naam || t('integration')
	return (
		<div className="elementContainer">
			<p>
				{t('auditContractMadeBy')}: <b>{name}</b>
			</p>
			<p className="eitje-text-secondary">
				{t('on')} {date(tijd).format('DD-MM-YYYY HH:mm')}
			</p>
		</div>
	)
}
