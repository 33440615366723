import React from 'react'
import {Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import utils from '@eitje/web_utils'
import {history} from 'index'
import {PlanningShift} from 'models/index'
import {useForm, ContextForm} from '@eitje/form'
import {useDateCounts, usePlanningDates} from 'cores/planning/index'
import useQueryParams from 'hooks/use_query_params'
import {EitjeModal} from 'components/common/index'
import {isEmpty} from 'lodash'
import SimpleProvider from 'contexts/simple'
import {PeriodsSelector} from 'common/components/periods_selector'
import {useFormData} from 'hooks'
import {generateYearPeriodKeyValue} from 'helpers/date'
import {usePlanningTeamFilter} from 'cores/planning/hooks/use_planning_team_filter'
import {FilterActiveMessage} from 'cores/planning/components/team_filter_message'

const teamHasShifts = (id, dataObject = {}) => Object.values(dataObject).some(obj => id in (obj?.team_count ?? {}))

const Publish = () => {
	const {team_ids, env_ids, filter_active} = useQueryParams()
	const {submit} = useForm()
	const {team_ids: form_team_ids, is_week} = useFormData()
	const hasMultipleEnvsSelected = env_ids.length > 1

	const periodsAmounts = {
		week: 6,
		month: 2,
	}

	const {periodData} = useDateCounts({
		team_ids,
		periodsAmounts,
		isDraft: true,
		is_week,
	})

	const {teamFilter} = usePlanningTeamFilter({
		team_ids: team_ids.filter(id => teamHasShifts(id, periodData)),
	})

	const formatShiftAmountMessage = shiftAmount => {
		return !shiftAmount ? t('form.publish_shifts.no_shifts') : `${shiftAmount} shift${shiftAmount === 1 ? '' : 's'}`
	}

	const context = {
		formatShiftAmountMessage,
		checkIfDisabled: shiftAmount => !shiftAmount,
	}

	return (
		<EitjeModal
			okButtonProps={{text: t('planning.environment_table.publish'), iconLeft: 'paper-plane', color: 'blue'}}
			form
			docsUrl="6009484"
			name="publish_shifts"
			onOk={submit}
			bodyMaxHeight={840}
		>
			<div className="eitje-form-2 eitje-form-2-use-borders">
				<FilterActiveMessage active={filter_active} />
				<FormRow>{teamFilter}</FormRow>
				{!hasMultipleEnvsSelected && (
					<FormRow>
						<Switch field="close_availability" label />
					</FormRow>
				)}
				<SimpleProvider {...context}>
					<PeriodsSelector isDraft periodsAmounts={periodsAmounts} team_ids={form_team_ids} />
				</SimpleProvider>
			</div>
		</EitjeModal>
	)
}

const Wrapper = () => {
	const {year, week} = usePlanningDates()
	const currentYearWeek = `${year}-${week}`

	const handleSubmit = data => {
		const {is_week, periods} = data
		if (isEmpty(periods))
			return utils.errorNotif({
				content: t('form.publish_shifts.no_periods_error'),
			})

		let payload = {...data, year, week, return_year_week: currentYearWeek, ...generateYearPeriodKeyValue(periods, is_week)}

		return PlanningShift.arbitrary('new_publish', payload)
	}

	return (
		<ContextForm
			onSubmit={handleSubmit}
			afterSubmit={history.goBack}
			transNamespace="publish_shifts"
			initialValues={{
				is_week: true,
			}}
		>
			<Publish />
		</ContextForm>
	)
}

export default Wrapper
