import {WeekTableCell} from 'common/components'
import {t} from 'initializers/i18n'
import {statusColorStrings} from 'cores/availability/constants'

export const AvailabilityCell = ({availabilityShift}) => {
	const {status, timeBlocks, remarks} = availabilityShift

	// Cell items
	const availabilityStatus = {type: 'subtitle', colorCircle: statusColorStrings[status]}

	let unavailableSlots = []
	if (status === 'partly_available') {
		unavailableSlots = timeBlocks.map(b => ({text: `${t('unavailable')} ${b.from} - ${b.till}`}))
	}

	const availabilityRemark = remarks && {text: `${t('note')}: ${remarks}`}

	return (
		<WeekTableCell
			cellType="tile"
			columnType="day"
			dropdownIcon={false}
			dynamicPaddings={false}
			contentLayoutProps={{gap: 2}}
			items={[availabilityStatus, ...unavailableSlots, availabilityRemark]}
		/>
	)
}
