import {exportT, ExportTable, Excel, ExportSubscription, useExportTaxonomy} from 'cores/exports2/index'
import {t} from 'initializers/i18n'

export const useTableTax = table => {
	const {tables, filters} = useExportTaxonomy()
	const tableTax = tables[table]
	const tableColumns = tableTax?.columns
	const tableColumnNames = Object.values(tableColumns).flat()
	const tableGroupNames = Object.keys(tableColumns)
	const enrichedFilters = {
		row: enrichNames(tableTax.filters.row, filters.row),
		column: enrichNames(tableTax.filters.column, filters.column),
	}
	return {
		tableTax,
		rowFirmOptions: tableTax.row_firm_options,
		defaultColumn: tableTax.default_first_column.name,
		tableColumns,
		filters: enrichedFilters,
		tableColumnNames,
		tableGroupNames,
	}
}

const enrichNames = (filterNames, filterObj) => {
	return filterObj.filter(f => filterNames.includes(f.filter))
}
