import {HelpButton} from 'common/components'
import {usePathActive} from 'hooks'

const docsLinks = [
	{id: 8228407, t: 'planning_basics'},
	{id: 5967178, t: 'create_shift'},
	{id: 6009519, t: 'create_template'},
	{id: 6009484, t: 'published_icon'},
]

export const Help = props => {
	// the Help component on the asTable page shares so little that it has its own component.
	const perTeam = usePathActive('per_team')
	const perMember = usePathActive('per_member')

	let tourId
	if (perTeam) tourId = 438668
	if (perMember) tourId = 438670

	return <HelpButton {...props} tourId={tourId} tourRole="manager" docsLinks={docsLinks} collection={3355109} />
}
