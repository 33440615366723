import React, {useState, useContext} from 'react'
import utils from '@eitje/web_utils'
import {ExportTable} from 'cores/exports2/index'
import {useEnvFilter, useHasRoleInAnyEnv} from 'hooks'
import {useSearchInput, filterItems as searchItems} from 'hooks/use_search'

const TablesContext = React.createContext({})
const {Provider} = TablesContext

export const TablesProvider = ({children} = {}) => {
	const isAdmin = useHasRoleInAnyEnv('admin')
	const [activeType, setActiveType] = useState('normal')
	const [activeTables, setActiveTables] = useState([])

	const {search, searchInput, setSearch} = useSearchInput({color: 'black', enableHotkey: true})

	const {filter: envFilter, setEnvFilter, envs} = useEnvFilter()

	let tables = ExportTable.all()

	if (utils.exists(activeTables)) {
		tables = tables.filter(t => activeTables.includes(t.table_name))
	}

	if (utils.exists(search)) {
		tables = searchItems(tables, search)
	}

	if (utils.exists(envs)) {
		tables = tables.filter(t => !utils.exists(t.envs_in_filters) || t.stock || utils.intersects(envs.ids(), t.envs_in_filters))
	}

	const obj = {
		tables,
		isAdmin,
		envs,
		envFilter,
		setEnvFilter,
		activeType,
		searchInput,
		setSearch,
		activeTables,
		setActiveType,
		setActiveTables,
	}

	return <Provider value={obj}>{children}</Provider>
}

export const useTablesContext = () => {
	const context = useContext(TablesContext)
	return context
}

export default TablesProvider
