import {store} from 'index'
import {date} from 'initializers/date'
import createCachedSelector from 're-reselect'
import {createSelector} from 'reselect'
import {getEnvSetting, getEnvSettings} from 'selectors/new_settings'
export const settingsSelector = state => getEnvSettings(state)
const userSettingsSelector = state => state.settings.user
const orgSettingsSelector = state => state.settings.orgSettings
export const rolesSelector = state => state.settings.roles
export const orgRolesSelector = state => state.settings.orgRoles

export {getEnvSetting}

export const magAccorderen = createSelector(rolesSelector, roles => roles['uren_accorderen'])

export const findRole = createCachedSelector(
	orgRolesSelector,
	rolesSelector,
	(state, kind) => kind,
	(state, kind, envId) => envId,
	(roles, envRoles, kind, envId) => {
		return envId ? roles[envId][kind] : envRoles[kind]
	},
)((roles, envRoles, kind, envId) => `${envId}-${kind}`)

export const getRole = createCachedSelector(
	rolesSelector,
	(state, kind) => kind,
	(roles, kind) => roles[kind],
)((roles, kind) => kind)

export const getEnvSettingGroup = createCachedSelector(
	settingsSelector,
	(state, kind) => kind,
	(settings, kind) => settings[kind],
)((settings, kind) => kind)

export const checkLocked = (settings, date) => {
	const lockedDay = date().add(settings['beschikbaarheid']['hvl_dagen'], 'd')
	return lockedDay.isSameOrAfter(date(date))
}

export const getLockedDate = createCachedSelector(
	state => getEnvSetting(state, 'beschikbaarheid', 'hvl_dagen'),
	(state, date) => date,
	(amtDays, date) => date(date).subtract(amtDays, 'days'),
)((state, date) => date)

export const isLocked = createCachedSelector(
	settingsSelector,
	(state, date) => date,
	(settings, date) => checkLocked(settings, date),
)((settings, date) => date)

export const hasRole = kind => {
	const state = store.getState()
	return getRole(state, kind)
}
