import useSelector from 'hooks/use_selector'
import {store} from 'index'

export const useFilter = name => {
	const activeItems = useSelector(state => state.filters[name])
	return activeItems || []
}

export const setFilter = (name, ids) => {
	store.dispatch({type: 'SET_FILTER', name, value: ids})
}

export default useFilter
