import {Text} from 'common/components'

export const MenuItem = props => {
	const {index, text, route} = props
	const {Sub} = route
	return (
		<Text bold>
			{index + 1}. {text} {Sub && <Sub {...props} />}
		</Text>
	)
}
