import {API, backend} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {history, store} from 'index'
import {t} from 'initializers/i18n'
import {saveValue} from 'actions/general'
import {saveSettingGroup} from 'actions/settings'

const findEnv = (state, id) => state.records?.environments?.find(env => env.id === id)

export async function envSwitch(envId) {
	const state = store.getState()
	const active = state.environment.active
	if (active == envId) return
	const curr = window.location.pathname // should we just redirect to home page? can break topics page if the article/topic is in an diff org

	const currentEnv = findEnv(state, active)
	const newEnv = findEnv(state, envId)
	const isSameOrg = currentEnv?.organisation_id === newEnv?.organisation_id

	const opts = isSameOrg ? {discard: ['userTemplateWeeks', 'operations']} : {keep: ['environments', 'teams', 'organisations']}

	history.push('/env_switch')
	if (active != envId && envId) {
		store.dispatch({type: 'RESET_RECORDS', ...opts})
		store.dispatch({type: 'SET_ACTIVE_ENV', env: envId, redUrl: curr})
	}
}

export async function getOrgAnalytics() {
	const res = await backend.get(`org/analytics`)
	if (res.ok) {
		store.dispatch({type: 'SET_VALUE', name: 'orgAnalytics', payload: res.data.data})
	}
}

export const getOrgStatus = async () => {
	const res = await backend.get(`organisations/status`)
	if (res.ok) {
		saveValue('orgStatus', res.data)
	}
}

export const inviteUser = (data, envId) => {
	let extraParams = {}
	if (envId) extraParams['env_id'] = envId
	return API.arbitrary('users', '/invite', {params: data, extraParams})
}

export const delUser = (envId, userId) => {
	const delUrl = `environments/${envId}/users/${userId}`
	return API.arbitrary('users', delUrl, {method: 'delete', user_id: userId})
}

export async function syncNmbrs() {
	const res = await backend.get(`koppelingen/nmbrs/sync_users`)
	if (res.ok) {
		utils.openNotif(t('syncStarted'), 'info', t('syncMsg'))
	}
}

export async function syncVisma() {
	const res = await backend.post(`koppelingen/visma/sync`)
	if (res.ok) {
		utils.openNotif(t('syncStarted'), 'info', t('syncMsg'))
	}
}

export const saveLocation = async data => {
	const {coords, city, geocodeResult, formatted_address} = data
	const {lat, lng} = coords
	const {place_id} = geocodeResult
	if (!lat) return utils.errorNotif({content: t('common.empty_form')})
	const payload = {
		lat,
		lng,
		formatted_address,
		place_id,
		city,
	}

	return saveSettingGroup('info', payload)
}
//TODO: figure out whether it's an org switch and change toKeep correspondingly
