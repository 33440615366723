import React from 'react'
import {ExportTable, Excel} from 'cores/exports2/index'
import SimpleProvider from 'contexts/simple'
import {Header} from './header/index'
import {Subscriptions} from './subscriptions/index'
import {FilterInfo} from './filter_info/index'
import {Excels} from './excels/index'
import {Redirect} from 'components/common/index'
import {useParams} from 'hooks'
import './styles/index.less'
import {PageLayout} from 'common/components'

const Inner = () => {
	const {id} = useParams()
	Excel.index({filters: {table_id: id}})
	return (
		<PageLayout name="export-table-show" headers={<Header />} bodyLayoutProps={{block: true}}>
			<div className="export2-tables-show-body">
				<Subscriptions />
				<FilterInfo />
				<Excels />
			</div>
		</PageLayout>
	)
}

export const TableShow = () => {
	const item = ExportTable.show()
	if (!item.id) return <Redirect to="/exports2/tables" />
	return (
		<SimpleProvider item={item}>
			<Inner />
		</SimpleProvider>
	)
}

function areEqual(prevProps, nextProps) {
	return true
}

export default React.memo(TableShow, areEqual)
