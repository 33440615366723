import _ from 'lodash'
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {crazyFilter} from 'selectors/records'

import FlatList, {FlatListItem} from 'components/list/flat_list'

const GroupedList = ({items, groupIdField = 'id', hideOrphans, groups = [], groupField, ...rest}) => {
	let nonGroupedItems = []
	const groupValues = _.uniq(items.map(i => i[groupField]).flat())

	const groupedItems = val => crazyFilter(items, {[groupField]: val})
	if (!hideOrphans) {
		nonGroupedItems = items.filter(i => i[groupField].length === 0) // NOW ONLY WORKS WITH ARRAY FIELDS
	}

	const availableGroups = groups.filter(g => groupValues.includes(g[groupIdField]))

	return (
		<div className="grouped-list">
			{availableGroups.map(g => (
				<GroupedListItem groupItem={g} items={groupedItems(g.id)} {...rest} />
			))}

			{!hideOrphans && nonGroupedItems.length > 0 && <GroupedListItem items={nonGroupedItems} {...rest} />}
		</div>
	)
}

const GroupedListItem = ({groupItem, items, modifyItems = ({items}) => items, GroupListItem, ListItem, ...rest}) => {
	const _items = modifyItems({items, group: groupItem})

	return (
		<Fragment>
			<div className="grouped-list-group">
				<FlatListItem item={groupItem} {...rest} className="group-field-list-item" ListItem={GroupListItem || ListItem} />
				<FlatList ListItem={ListItem} {...rest} items={_items} />
			</div>
		</Fragment>
	)
}

export {GroupedList, GroupedListItem}
export default GroupedList
