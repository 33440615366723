import {t} from 'initializers/i18n'

import {InfoCard} from 'common/components'

const ShiftTime = ({shift}) => {
	if (!shift) return null
	const totalMins = shift.totalMinsString()
	return <InfoCard icon="clock" label={t('planning.shift_modal.total_shift_time')} children={totalMins} />
}

export default ShiftTime
