import {backend} from '@eitje/easy_api'
import {Redirect, useLocation} from 'react-router-dom'
import {ModalWithNewForm} from 'components/form/new_modal_form'
import {useShared} from 'hooks'
import {GroupChatForm, useGroupChannelDisabled} from 'cores/chat'
import {closeModal} from 'actions'
import {DeleteButton} from 'common/components'
import {useGetChannel, useGetOtherChannelMembers} from './hooks'

const GroupChatModal = ({channel}) => {
	const location = useLocation()
	const {me} = useShared()
	const members = useGetOtherChannelMembers(channel, me.id)
	const user_ids = members?.map(member => Number(member.user_id)) || []
	const initialValues = {name: channel?.data.name, user_ids, remote_avatar_url: channel?.data.image}

	const onSubmit = async data => {
		return await backend.put(`chat/channels/${channel.id}`, {
			...data,
			user_ids: [...data.user_ids, me.id],
		})
	}

	const close = () => closeModal(location)

	const handleDelete = async () => {
		const res = await backend.delete(`chat/channels/${channel.id}`)
		if (res.ok) close()
	}

	return (
		<ModalWithNewForm name="update_group_chat" width={350} modalProps={{buttons: [<DeleteButton onClick={handleDelete} />]}}>
			<GroupChatForm initialValues={initialValues} onSubmit={onSubmit} afterSubmit={close} />
		</ModalWithNewForm>
	)
}

const PermissionCheck = ({channel}) => {
	// we need to have a custom permission check here,
	// due to custom permission rules related to stream
	const disabled = useGroupChannelDisabled(channel)

	if (disabled) return <Redirect to="/home_page" />

	return <GroupChatModal channel={channel} />
}

export const EditGroupChat = ({match}) => {
	const {
		params: {id: groupChatId},
	} = match

	const channel = useGetChannel(groupChatId)
	if (!channel) return null

	return <PermissionCheck channel={channel} />
}
