import React, {useEffect} from 'react'
import {Input, DatePicker} from '@eitje/form-fields-web'
import {useWhere, useFind} from '@eitje/easy_api'
import {usePrevious} from '@eitje/react-hooks'
import {FormRow} from 'components/ui'
import {date} from 'initializers/date'
import {useForm} from '@eitje/form'
import {useFirstRegDate} from 'cores/time_registration/index'
import useFormData from 'hooks/use_form_data'
import UserCard from './user_card'
import {BreaktimePicker, MealPicker} from 'components/shared/index'
import {getEnums} from 'data/enums'
import {dateArrayFromStart} from 'helpers/date'
import {WeekDaysPicker} from 'components/shared/index'
import {useSimpleContext} from 'contexts/simple'
import {AllowancePicker, ListPicker, ShiftTimePicker} from 'common/components'
import User from 'models/user'

const Form = () => {
	const {environment_id, user_id} = useFormData()
	const env = useFind('environments', environment_id)
	const prevEnvId = usePrevious(environment_id)
	const {setValues} = useForm()
	const teams = useWhere('teams', {environment_id})
	const shiftTypes = getEnums('reg_shift').filter(i => i.id != 'now')
	const user = User.find(user_id)

	useEffect(() => {
		let obj = {team_id: null}
		if (!user.environment_ids?.includes(env.id)) obj = {...obj, user_id: null}
		if (prevEnvId) {
			setValues(obj)
		}
	}, [environment_id])

	return (
		<div className="time-shift-form-body">
			<FormRow>
				<DatePickerSwitch />
				<UserCard />
			</FormRow>
			<FormRow>
				<ShiftTimePicker required field="from" />
				<ShiftTimePicker required field="till" />
			</FormRow>

			<FormRow>
				<BreaktimePicker />
				<ListPicker required single dropdown items={teams} labelField="naam" field="team_id" />
			</FormRow>

			<FormRow>
				<ListPicker single dropdown required field="typ" items={shiftTypes} labelField="naam" />
				<MealPicker envId={environment_id} />
			</FormRow>

			<FormRow>
				<Input placeholder="..." textarea field="remarks" autoSize={{minRows: 1, maxRows: 6}} />
				<AllowancePicker environmentId={environment_id} />
			</FormRow>
		</div>
	)
}

const DatePickerSwitch = () => {
	const {isEdit} = useSimpleContext()
	const {date: _date, environment_id} = useFormData()
	const firstRegDate = useFirstRegDate(environment_id)

	if (!_date) return <DatePicker disabledBefore={date(firstRegDate).subtract(1, 'day')} required customFormat="YYYY-MM-DD" field="date" />

	return (
		<WeekDaysPicker
			single={isEdit}
			dateIsDisabled={date => date < firstRegDate}
			dateArray={dateArrayFromStart({start: date(_date).startOf('week')})}
		/>
	)
}

export default Form
