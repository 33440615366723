import {t} from 'initializers/i18n'
import _RouteToggle from 'shared/components/route_toggle'

const RouteToggle = props => {
	const items = [
		{name: t('common.per_member'), path: `/hours/per_week`},
		{name: t('common.per_table'), path: `/hours/per_member`},
	]

	return <_RouteToggle localKey="hoursViewPreference" baseRoute="hours" items={items} {...props} />
}

export default RouteToggle
