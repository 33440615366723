import React, {useEffect, useState, Fragment, useRef, useContext} from 'react'
import {useWhere, useFind, useAll} from '@eitje/easy_api'

const UserContext = React.createContext({})
const {Provider} = UserContext

const UserContextWrapper = ({id, user, children, ...rest} = {}) => {
	const _user = useFind('users', id)
	user = user || _user
	const context = {user, userId: user.id, ...rest}
	return <Provider value={context}>{children}</Provider>
}

export default UserContextWrapper

export const useUserContext = () => {
	const context = useContext(UserContext)
	return context
}
