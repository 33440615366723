import {useEnvSettingGroup} from 'hooks/use_env_setting'
import useParams from 'hooks/use_params'
import {ContextForm} from '@eitje/form'
import Form from './form'
import {saveSettingGroup} from 'actions/settings'
import './styles/index.less'
import {EitjeModal} from 'components/common'
import {t} from 'initializers/i18n'
import {EnvRouteSelect} from 'shared/components/env_route_select'

const Header = props => {
	const {envId} = useParams()
	return (
		<EnvRouteSelect modal shouldTranslate={false} envId={envId} urlFormat="/environment_planning/:envId/planning/settings" {...props} />
	)
}

export const Settings = () => {
	const {envId} = useParams()
	const initialValues = useEnvSettingGroup('planning', envId)

	return (
		<ContextForm
			fieldProps={{submitStrategy: 'throttledChange'}}
			throttleTime={1200}
			onSubmit={data => saveSettings(data, envId)}
			initialValues={initialValues}
			transNamespace="planning_settings"
		>
			<EitjeModal
				title={t('settings')}
				RightHeader={Header}
				name="planning-settings-modal"
				wrapClassName="eitje-modal-2 planning-settings-modal"
			>
				<Form />
			</EitjeModal>
		</ContextForm>
	)
}

export default Settings

const saveSettings = (data, envId) => {
	return saveSettingGroup('planning', data, {env_id: envId})
}
