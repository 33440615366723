import {forwardRef} from 'react'
import {PopoutCard, Icon} from '@eitje/web_components'
import {DocsButton} from '../docs_button'
import {GridLayout} from '../grid_layout'
import {Text} from '../text'
import './styles/index.less'
import {Layout} from '../layout'

export const Legend = forwardRef(({docsId, ...rest}, ref) => {
	const body = <LegendContent {...rest} />
	const footer = docsId && (
		<Layout width="100%">
			<DocsButton to={docsId} width="full" />
		</Layout>
	)

	return <PopoutCard ref={ref} body={body} bodyPadding={0} footer={footer} noPadding width={320} delayedHovering {...rest} />
})

export const LegendContent = ({items, ...rest}) => {
	const anyItemHasDescription = items._some('description')

	// We call the function here instead of the component to make sure we get the rendered output.
	// The gridLayout needs the returned children and not the wrapping component
	return <GridLayout className="eitje-legend">{items?.map(item => LegendItem({...rest, ...item, anyItemHasDescription}))}</GridLayout>
}

const LegendItem = props => {
	return (
		<>
			<LeftContent {...props} />
			<RightContent {...props} />
		</>
	)
}

const LeftContent = props => {
	const {icon, color, Component, text} = props

	let element
	if (color) element = <Layout className="eitje-legend-color-bar" width={6} baseColor={color} colorSet="solid" height="100%" />
	if (text) element = <Text>{text}</Text>
	if (icon) element = <Icon name={icon} fill={color} />
	if (Component) element = <Component {...props} />

	return <Layout>{element}</Layout>
}

const RightContent = ({title, description, anyItemHasDescription}) => {
	return (
		<Layout direction="vertical" gap={0}>
			<Text bold={anyItemHasDescription}>{title}</Text>
			<Text small darkGrey>
				{description}
			</Text>
		</Layout>
	)
}
