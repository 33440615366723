import {PopoutCard} from '@eitje/web_components'
import {EitjeButton, Layout} from 'common/components'
import {InboxList} from 'cores/firm'
import {t} from 'initializers/i18n'

export const InboxPopout = ({children}) => {
	const Body = (
		<Layout direction="vertical" gap={0}>
			<InboxList name="all" width={350} height={287} itemSize="small" />
			<Layout width="full" padding={12} borderTop>
				<EitjeButton modalLink="/inbox/all" colorSet="solid" iconLeft="bell" t="common.show_all_notifications" width="full" />
			</Layout>
		</Layout>
	)

	return (
		<PopoutCard title={t('modals.inbox.title')} trigger="hover" bodyPadding={0} body={Body} strategy="fixed">
			{children}
		</PopoutCard>
	)
}
