import {EitjeDropdown, Text, DeletePopout, Layout, ListPicker, useListPickerItemValidation} from 'common/components'
import {Link} from 'components/routing'
import {delUser} from 'actions/environment'
import {Environment, Team} from 'models'
import {t} from 'initializers/i18n'
import {useShared, useRoleOrgEnvs} from 'hooks'
import {API} from '@eitje/easy_api'
import utils from '@eitje/utils'
import './styles.less'

export const RowWrapper = ({row, ...rest}) => {
	const user = row.original
	const {id, full_name, environment_ids, teams} = user
	const {org, orgEnvs, multiEnvOrg} = useShared()
	const profileLabel = t('common.view', {kind: full_name})

	// Default joins doesn't work for nested objects ATM; also, should we use it here due to performance?
	const environments = Environment.where(environment_ids).filter(e => e.organisation_id === org.id)

	const teamsOfUserEnvs = Team.where(_.flatten(environments._map('team_ids'))).filter(t => !t.locked)
	const idsOfUserTeams = utils.getIdsFromItems(user.team_ids, teamsOfUserEnvs) // to prevent accidentally removing locked teams

	const roleEnvs = useRoleOrgEnvs('manager')

	const groupTeamListPickerProps = multiEnvOrg && {
		groupField: 'environment_id',
		groupItems: orgEnvs,
		groupLabelField: 'naam',
		groupSelectable: false,
	}

	const elements = [
		{
			icon: 'person',
			element: <Link to={`/user/${id}/profile`} children={profileLabel} />,
		},
		{
			listPickerProps: {
				...useListPickerItemValidation({
					roles: 'manager',
					orgEnvs,
					roleEnvs,
				}),
				...groupTeamListPickerProps,
				items: teamsOfUserEnvs,
				labelField: 'naam',
				field: 'team_ids',
				triggerText: t('manageTeams'),
				showToggleSelectAll: false,
				contextFormProps: {
					onSubmit: data => API.addRemoveAssoc('users', data, {team_ids: idsOfUserTeams}, user.id),
					initialValues: {team_ids: idsOfUserTeams},
				},
			},
		},
		multiEnvOrg && {
			listPickerProps: {
				...useListPickerItemValidation({
					roles: 'manager',
					orgEnvs,
					roleEnvs,
					extraValidation: item => item.user_ids.includes(user.id) && t('disabled.only_permitted_to_add_users_to_environments'),
				}),
				items: orgEnvs,
				labelField: 'naam',
				field: 'environment_ids',
				triggerText: t('firms.add_user_to_environment'),
				showToggleSelectAll: false,
				contextFormProps: {
					onSubmit: data => API.addRemoveAssoc('users', data, {environment_ids}, user.id),
					initialValues: {environment_ids},
				},
			},
		},
		{
			icon: 'person-minus',
			title: t('common.make_out_of_service'),
			children: environments.map(env => {
				return {
					icon: 'trash',
					element: (
						<Layout enableRole="manager" roleEnv={env.id}>
							<DeletePopout nonDestructive onDelete={() => delUser(env.id, user.id)} children={env.naam} />
						</Layout>
					),
				}
			}),
		},
	]

	return <EitjeDropdown {...rest} className="user-options-dropdown" placement="bottom-start" trigger="click" elements={elements} />
}
