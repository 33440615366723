import {API} from '@eitje/easy_api'

export const finishOnboardingStep = ({step, org}) => {
	const {onboarding_taxonomy} = org
	// Below should never hit, but add for safety during migration from old to new UI. Can delete later.
	if (!onboarding_taxonomy) return

	const stepPreviouslyCompleted = !!onboarding_taxonomy[step]

	if (stepPreviouslyCompleted) return

	// The API has logic to compute the onboarding progress of a new organisation. Instead of duplicating that
	// logic in the front-end, we simply make a request to the back-end to update the local progression state.
	API.index('organisations', {refresh: true})
}
