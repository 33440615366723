import './styles/week_grid.less'

export const WeekGrid = ({days}) => (
	<div>
		{days?.map(day => (
			<div className="day-placeholder" style={{height: 100}}>
				{day}
			</div>
		))}
	</div>
)
