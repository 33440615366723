import {Fragment, useCallback, useMemo} from 'react'
import {ChannelList as StreamChatChannelList, useChatContext} from 'stream-chat-react'
import {t} from 'initializers/i18n'
import {Layout} from 'common/components'
import {SearchEmpty} from 'hooks'
import {NAMESPACE, channelSearch} from 'cores/chat'
import {ChannelPreview} from './preview'
import {SearchResultsList} from './search_results_list'
import {SearchInput} from '../search_input'

const sort = {last_message_at: -1} // descending direction

const SearchBar = ({onSearch, clearState, query, exitSearch}) => {
	const handleClear = () => {
		clearState()
		exitSearch()
	}

	return (
		<Layout className="search-bar" padding="10 12" borderBottom width="full">
			<SearchInput onChange={onSearch} value={query} onClear={handleClear} placeholder={t(`${NAMESPACE}.search`)} />
		</Layout>
	)
}

export const ChannelList = ({customActiveChannel, ...props}) => {
	const {client} = useChatContext()
	const searchFunction = useCallback((params, event) => channelSearch(params, event, client), [channelSearch, client])
	const additionalChannelSearchProps = useMemo(
		() => ({
			searchFunction,
			SearchResultsList,
			SearchEmpty,
			clearSearchOnClickOutside: false,
			SearchBar,
			SearchResultsHeader: Fragment, // using a fragment to render no search results header
		}),
		[searchFunction],
	)

	const filters = {members: {$in: [client.userID]}}

	return (
		<Layout {...props} className="channel-list">
			<StreamChatChannelList
				customActiveChannel={customActiveChannel}
				Preview={ChannelPreview}
				sort={sort}
				filters={filters}
				showChannelSearch
				setActiveChannelOnMount={false} // If true, sets the most recent channel received from the query as active on component mount.
				additionalChannelSearchProps={additionalChannelSearchProps}
			/>
		</Layout>
	)
}
