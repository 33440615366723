import {ContextForm} from '@eitje/form'
import {EitjeModal} from 'components/common'
import {AddItemForm} from 'components/forms/add_item_form'
import {CompositeField} from 'components/forms/composite_field'
import {Fragment} from 'react'
import {ShiftType} from './shift_type'
import {ShiftType as ShiftTypeModel} from 'models/index'
import {history} from 'index'
import {t} from 'initializers/i18n'
import useSelector from 'hooks/use_selector'
import {roleOrgEnvsSelector} from 'selectors/new_settings'

export const Inner = () => {
	const adminEnvs = useSelector(roleOrgEnvsSelector, 'admin')

	const handleAddShiftType = name => {
		return ShiftTypeModel.update({name, active: true})
	}

	const onDel = id => ShiftTypeModel.destroy(id)

	const multiEnvWarning = adminEnvs.length > 1 && (
		<div className="modal-info-banner">
			<h5>{t('records.shift_type.multi_env_warning')}</h5>
		</div>
	)

	return (
		<Fragment>
			{multiEnvWarning}
			<AddItemForm onAdd={handleAddShiftType} transNamespace="add_shift_type" maxLength={25} />
			<CompositeField
				fields={['name', 'active', 'options', 'emoji', 'id']}
				compositeName="shift_types"
				className="shift-types"
				onDel={onDel}
				submitRows
			>
				<ShiftType />
			</CompositeField>
		</Fragment>
	)
}

export const ShiftTypes = props => {
	const shift_types = ShiftTypeModel.all()

	const handleSubmit = data => {
		const shiftTypes = data.shift_types.map(shiftType => ({
			...shiftType,
			hide_end: shiftType.options?.includes('hide_end'),
			show_call: shiftType.options?.includes('show_call'),
			options: undefined,
		}))
		return ShiftTypeModel.multiUpdate(shiftTypes)
	}

	const afterSubmit = () => history.goBack()

	const transformedShiftTypes = shift_types.map(shift => {
		const options = [shift.hide_end && 'hide_end', shift.show_call && 'show_call'].filter(Boolean)
		return {...shift, options}
	})

	return (
		<ContextForm
			onSubmit={handleSubmit}
			afterSubmit={afterSubmit}
			submitInitialValues
			transNamespace="shift_types"
			initialValues={{shift_types: transformedShiftTypes}}
			overwriteInitialValues
			submitOnEnter={false}
			resetAfterSubmit
		>
			<EitjeModal name="shift_types" form>
				<Inner {...props} />
			</EitjeModal>
		</ContextForm>
	)
}
