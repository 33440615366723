import Shift from './shift'

class PlanningShift extends Shift {
	static defaultJoins = ['users', 'shiftTypes', 'teams']
	static tableName = 'planningShifts'

	indefinite() {
		return this.shiftType?.hide_end
	}
}

export default PlanningShift
