import {usePlanningTeams, AmtWorked, usePlanningDates, getShiftMins} from 'cores/planning/index'

const validateBreaktime = (value, props) => {
	const {from, till} = props.getFormData()
	if (!from || !till) return
	const {totalMins} = getShiftMins({from, till, pauze_duur: value})
	return {valid: totalMins >= 0, message: 'breaktime_longer_than_shift'}
}

export {validateBreaktime}
