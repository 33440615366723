import {forwardRef, useEffect, useState} from 'react'
import {useCountdown} from '@eitje/react-hooks'
import {Icon} from '@eitje/web_components'
import {Text, Title} from '../text'
import {makeCnVariants} from 'helpers'
import {Layout} from '../layout'
import './styles/notification.less'
import {useHover, useLogRocketTrack} from 'hooks'

const HandleCount = ({setCount, duration = 5, handleClose, isHovering}) => {
	const count = useCountdown(duration)

	useEffect(() => {
		if (count <= 0 && !isHovering) handleClose()
		setCount(count)
	}, [count, isHovering])

	return null
}

export const Notification = forwardRef((props, ref) => {
	const {
		title,
		content,
		icon,
		baseColor,
		colorSet = baseColor ? 'color-bordered-outline' : true,
		handleClose = _.noop,
		duration,
		small,
		onClick,
	} = props

	const [count, setCount] = useState(duration)
	const {hoverActions, isHovering} = useHover()
	const countValue = Math.max(0, Math.floor(count))

	// TODO: layout. BaseColor and colorSet is used 3 times here in 4 nested layouts. We can improve on this
	const _colorSet = {
		baseColor,
		colorSet,
	}

	useLogRocketTrack('notification')

	const classNames = makeCnVariants('notification', small ? 'small' : 'large')
	const condOpts = {}
	if (onClick) {
		condOpts.onClick = onClick
	}

	// When the notif has a count prop and the count has reached 0, we ensure nothing is returned.
	// This is to ensure that we don't see a glitch of the disappearning notif, caused by react-flip-move
	if (props.duration > 0 && count <= 0) return null

	return (
		<Layout
			{...hoverActions}
			onClick={onClick}
			ref={ref}
			className={classNames}
			padding={12}
			width={!small && 500}
			direction="vertical"
			{..._colorSet}
		>
			{(props.duration > 0 || small) && (
				<HandleCount setCount={setCount} duration={duration} handleClose={handleClose} isHovering={isHovering} />
			)}
			<Layout horizontal="spaceBetween" {..._colorSet}>
				<Layout gap={8} {..._colorSet}>
					<Icon size="14px" name={icon} />
					<Title>{title}</Title>
				</Layout>

				{!small && (
					<Layout className="notification-right-content" gap={12} vertical="center" {..._colorSet}>
						{props.duration > 0 && <Text>{countValue}</Text>}
						<Icon size="10px" onClick={handleClose} name="cross" />
					</Layout>
				)}
			</Layout>
			{content && (
				<Layout direction="vertical" className="notification-content" gap={8} {..._colorSet}>
					<Text bold> {content} </Text>
				</Layout>
			)}
		</Layout>
	)
})
