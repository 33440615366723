import {ProtectedRoute} from 'components/private_route'
import {ContractSettings} from './pages/contract_settings'

import {TimeSettings} from 'cores/settings/pages/index'

const settingsBackgroundRoutes = [
	<ProtectedRoute path="/settings/time" role="admin" component={TimeSettings} />,
	<ProtectedRoute path="/settings/contract" role="admin" component={ContractSettings} />,
]

export {settingsBackgroundRoutes}
