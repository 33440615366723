import useAvShift from 'cores/availability/hooks/use_av_shift'
import AvailabilityFormProvider from 'cores/availability/components/form/availability_form_provider'
import {useWhere} from '@eitje/easy_api'
import useDay from 'cores/availability/hooks/use_day'
import utils from '@eitje/web_utils'
import useUserWeek from 'cores/availability/hooks/use_user_week'
import {WeekShifts as BaseWeekShifts, AvCell} from '../components'
import {useUserContext} from 'contexts/user'
import Dropdown from 'components/dropdown'
import {Link} from 'components/routing'
import {t} from 'initializers/i18n'
import {loadTemplate} from 'cores/availability/actions'
import {makeRange} from 'helpers/date'
import {Text} from 'common/components'

export const AvailabilityWeeks = ({dates, id}) => {
	const [week1, week2] = _.chunk(dates, 7)

	return (
		<div className="user-availability-table">
			<AvailabilityFormProvider>
				<AvailabilityWeek id={id} dates={week1} />
				<AvailabilityWeek id={id} dates={week2} />
			</AvailabilityFormProvider>
		</div>
	)
}

export const AvailabilityWeek = ({dates = []}) => {
	const {userId} = useUserContext()
	const lastDate = dates[dates.length - 1]
	const dateRange = makeRange(dates[0], lastDate)
	const avShifts = useWhere('availabilityShifts', {date: dateRange, user_id: userId})
	if (dates.length == 0) return null

	const elements = [
		<Link t="templateAvailability" to="availability_template" />,
		<Text onClick={() => loadTemplate({dateRange, user_id: userId, avShifts})} t="loadTemplate" />,
	]

	return (
		<div className="user-availability-table-row">
			{dates.map(d => (
				<AvailabilityDay date={d} />
			))}
			<div className="user-availability-table-row-item">
				<WeekShifts date={dates[0]} />
			</div>

			<div className="user-availability-table-row-item">
				<div className="availability-title-cell">
					<p>Template </p>
				</div>
				<div className="availability-status-cell">
					<Dropdown elements={elements}>
						{/* TODO - get better image quality in correct height 40px */}
						<div className="av-template-return">
							<img src={'/images/web/icons/template-return.png'} width={20} height={20} />
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}

const AvailabilityDay = ({date}) => {
	const {userId} = useUserContext()
	const query = {user_id: userId, date: date.format('YYYY-MM-DD')}
	const avShift = useAvShift(query)
	const {closed} = useDay(date)
	const containerClass = utils.makeCns('default-availability-cell-wrapper', closed && 'av-day-disabled')

	return (
		<div className={containerClass}>
			<div className="availability-title-cell">
				{closed && <img className="lock-icon" src="/images/web/icons/lock_icon.png" />}
				<p>{date.format('dd DD-MM')} </p>
			</div>
			<div className="availability-status-cell">
				<AvCell item={avShift} />
			</div>
		</div>
	)
}

const WeekShifts = props => {
	const {userId} = useUserContext()
	const userWeek = useUserWeek({...props, id: userId})
	return <BaseWeekShifts userWeek={userWeek} {...props} />
}
