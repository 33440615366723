import {API, find} from '@eitje/easy_api'
import {FormWrapper, LegacyMultiForm as MultiForm} from '@eitje/form'
import {LegacyInput as Input} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {Icon} from '@eitje/web_components'
import Modal from 'components/general/modal'
import useMergeState from 'hooks/use_merge_state'
import useParams from 'hooks/use_params'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'
import './new.less'

const createQuizItem = (data, topicId) => {
	if (data.quiz.correct_answer === undefined || data.quiz.correct_answer === null)
		return utils.errNotif(t('oops'), t('correctAnswerRequired'))

	const params = {...data.quiz, answers: data.answers.map(a => a.answer), topic_ids: [topicId]}
	return API.create('quiz_items', params)
}

const New = () => {
	const {id, quizItemId} = useParams()
	const quizItem = useSelector(state => find(state, 'quizItem', quizItemId))
	const {answers = []} = quizItem
	const [state, setState] = useMergeState()
	const {correct_answer = quizItem.correct_answer} = state
	const wrapper = useRef(null)
	const form = useRef(null)
	const multiForm = useRef(null)
	const afterRemove = formNum => {
		if (correct_answer == formNum) {
			form.current.updateField('correct_answer', null)
		} else if (formNum < correct_answer) {
			form.current.updateField('correct_answer', correct_answer - 1)
		}
	}

	return (
		<Modal title={t('question')} wrapClassName={'quiz-question-modal'} onOk={() => wrapper.current && wrapper.current.submit()}>
			<FormWrapper ref={wrapper} onSubmit={data => createQuizItem(data, id)} afterSubmit={history.goBack}>
				<Form
					initialValues={quizItem}
					setState={setState}
					mirrorFields={['correct_answer']}
					formKey="quiz"
					onSubmit={data => data}
					ref={form}
				>
					<Input bordered={false} field="question" maxLength={120} required extraLabel={<p />} />
				</Form>

				<div formWrapper>
					<MultiForm
						initialValues={answers.map(a => ({answer: a}))}
						afterRemove={afterRemove}
						ref={multiForm}
						amtForms={answers?.length || 2}
						maxForms={5}
						hideControls
						allowEmpty={false}
						formKey="answers"
					>
						<CheckMarkInput maxLength={70} required correctAnswer={correct_answer} form={form} field="answer" />
					</MultiForm>
				</div>
			</FormWrapper>
		</Modal>
	)
}

const DefaultAddForm = ({onClick}) => <Icon onClick={onClick} name="plus" size="10px" />
const DefaultRemoveForm = ({onClick}) => <Icon name="cross" onClick={onClick} size="8px" />

const CheckMarkInput = ({correctAnswer, ...props}) => {
	const {form, formIdx, addForm, removeForm, isLast, mayAdd} = props
	const isActive = correctAnswer == formIdx
	const containerClass = utils.makeCns('eitje-check-mark-input', correctAnswer === formIdx && 'active-background-color')
	const label = <p className="eitje-label">{t('answer') + ': ' + String.fromCharCode(65 + formIdx)}</p>
	const mark = (
		<Icon
			className={isActive && 'checkmark-checked'}
			name="checked"
			size="10px"
			fill={isActive && Colors.white}
			onClick={() => form.current.updateField('correct_answer', formIdx)}
		/>
	)

	const inputRightChildren = [
		mark,
		removeForm && <DefaultRemoveForm onClick={removeForm} />,
		isLast && mayAdd && addForm && <DefaultAddForm onClick={addForm} />,
	]

	return (
		<div className={containerClass} fieldWrapper>
			<Input label={label} bordered={false} isLayered placeholder={t('pollAnswer')} {...props} rightChildren={inputRightChildren} />
		</div>
	)
}

export default New
