import './styles/duration_block.less'

const DurationBlock = ({item}) => {
	return (
		<div className="duration-block">
			<h5 className="duration-block-text">
				{item.startTime()} - {item.endTime()}
			</h5>
		</div>
	)
}

export default DurationBlock
