import {useTablesContext, useExportTaxonomy} from 'cores/exports2/index'
import {FilterTrigger} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import './styles/index.less'
import {ListPicker} from 'common/components'

export const Footer = () => {
	const {setActiveTables, activeTables, searchInput, envFilter} = useTablesContext()

	const {recordNames} = useExportTaxonomy()
	const _items = recordNames.map(n => ({id: n, name: t(`records.${n}.name`)}))

	return (
		<div className="export-tables-footer">
			<div className="export-tables-footer-left">
				{envFilter}
				<ListPicker
					raw
					dropdown
					Trigger={FilterTrigger}
					value={activeTables}
					onChange={setActiveTables}
					name="common.table"
					items={_items}
				/>
			</div>
			{searchInput}
		</div>
	)
}

export default Footer
