import {possibleIntegs} from 'cores/integrations/constants'

const useIntegrationConstants = id => {
	const item = possibleIntegs.find(int => int.id == id)
	const logoSrc = `/images/integrations/${id}.png`
	const logo = <img className={`provider-logo provider-logo-${id}`} src={logoSrc} />

	return {logo, logoSrc, issuesLink: item?.docsLink, ...item}
}

export default useIntegrationConstants
