import {WeekTableCell} from 'common/components'
import {t} from 'initializers/i18n'

export const Panel = ({dateArray, PanelDropdown, panelContent, panelContentLeft, ...rest}) => {
	const date = dateArray[0]
	const weekInfo = date.format('W (GGGG)') // GGGG is iso year
	const weekName = `${t('week')} ${weekInfo}`

	return (
		<WeekTableCell
			items={panelContent(date)}
			Wrapper={PanelDropdown}
			displayName={weekName}
			date={date}
			columnType="panel"
			cellType="tile"
			{...panelContentLeft(date)}
			{...rest}
		/>
	)
}
