import {useState, useEffect} from 'react'
import {useWhere} from '@eitje/easy_api'
import UserRow from './user_time_registration_shifts_row'
import {filterItems} from 'hooks/use_search'
import {SearchEmpty} from 'components/common/index'
import {useFilterFooterContext, EitjeButton, EitjeVirtuoso} from 'common/components'
import {useSearchContext} from 'contexts/search'

export * from './user_time_registration_shifts_row'

const SCROLL_PARENT = '.scroll-container'

const Page = () => {
	const {search} = useSearchContext()
	const [scrollParent, setScrollParent] = useState(null)
	const {
		filteredItems: shifts,
		filterValues: {teamIds, hours},
		envs,
	} = useFilterFooterContext()

	const shiftUsers = useWhere('users', shifts.map(s => s.user_id).filter(Boolean))
	let teamUsers = useWhere('users', {team_ids: teamIds})

	teamUsers = teamUsers.filter(u => {
		const userEnvs = envs.filter(e => u.environment_ids.includes(e.id))
		const unschedulableUserIds = _.intersection(...userEnvs.map(e => e.onroosterbaar_user_ids))
		return !unschedulableUserIds.includes(u.id)
	})

	let users = _.uniqBy([...shiftUsers, ...teamUsers], 'id')

	// if both OR none options are passed, all user rows must be shown, thus only check if there is one value
	if (hours?.length === 1) {
		const value = hours[0]
		const hasHours = user => shifts.find(shift => shift.user_id === user.id)
		if (value === 'with_hours') users = users.filter(u => hasHours(u))
		if (value === 'without_hours') users = users.filter(u => !hasHours(u))
	}

	if (search) users = filterItems(users, search, {searchField: 'full_name'})
	const sortedUsers = _.orderBy(users, 'full_name')

	const placeholderProps = {
		animation: 'clock',
		name: 'hours',
		docsId: '3355114',
		collection: true,
		children: <EitjeButton modalLink="/time_registration/new" iconLeft="plus" t="action_button" />,
	}

	useEffect(() => {
		// Rerender Virtuoso after the scroll parent has rendered for the first time. Without the scroll parent's target className being
		// present in the DOM, it won't show any children.
		const parent = document.querySelector(SCROLL_PARENT)
		if (parent) setScrollParent(parent)
	}, [])

	return (
		<EitjeVirtuoso
			data={sortedUsers}
			placeholderProps={placeholderProps}
			customScrollParent={SCROLL_PARENT}
			itemContent={(index, user) => <UserRow key={user.id} user={user} />}
		/>
	)
}

export default Page
