import utils from '@eitje/web_utils'
import {useEffect, useState, useMemo} from 'react'
import {onSortingChange, onGroupingChange, onPinnedColumnOrderChange, onUnpinnedColumnOrderChange} from 'common/components/advanced_table'
import {useReactTable} from '@tanstack/react-table'
import {store} from 'index'
import {useCachedUserPreferences, columnVisibilityObject, getRowId, SETTINGS} from './helpers'

export const useAdvancedTable = instanceTaxonomy => {
	// Destructure taxonomy of the AdvancedTable instance.
	const {meta, initialPinning, columns, TABLE_INFO} = instanceTaxonomy
	const {tableName, fields: allTableColumns} = TABLE_INFO
	const {visibleColumns, pinnedColumns, unpinnedColumns, groupedColumn} = useCachedUserPreferences(TABLE_INFO)

	// Sort the table. Not yet cached but, simply a sane default is chosen.
	const initiallySortedColumn = groupedColumn?.length ? groupedColumn : allTableColumns[0]
	const sort = [{id: initiallySortedColumn, desc: false}]

	// Define states. The states themselves may be shared through the context but often not reused in other places in the
	// application, their main purpose besides sharing information is to trigger renders on changes.
	const [columnVisibility, setColumnVisibility] = useState({})
	const [sorting, setSort] = useState(sort)
	let [grouping, setGrouping] = useState([groupedColumn])
	const [columnOrder, setColumnOrder] = useState(unpinnedColumns)
	const [expanded, setExpanded] = useState({})
	const _setExpanded = fn => _.isFunction(fn) && setExpanded(fn)
	const savedOrTaxonomyPinned = utils.exists(pinnedColumns) ? pinnedColumns : initialPinning
	const [columnPinning, setColumnPinning] = useState({left: savedOrTaxonomyPinned})

	// Create a context with the instance's current state to reuse through all other AdvancedTable files and components.
	const state = useMemo(() => {
		return {sorting, columnVisibility, grouping, expanded, columnPinning, columnOrder, setColumnPinning}
	}, [expanded, grouping, columnVisibility, sorting, columnPinning, columnOrder])

	// Initialize the table
	const advancedTableInstance = useReactTable({
		...SETTINGS,
		...instanceTaxonomy,
		meta: TABLE_INFO,
		state,
		getRowId,
		columnIds: allTableColumns,
		onColumnVisibilityChange: setColumnVisibility,
		onSortingChange: sort => onSortingChange({sort, setSort, instanceTaxonomy}),
		getSubRows: row => row.subRows,
		onExpandedChange: _setExpanded,
		onColumnPinningChange: setColumnPinning,
		onColumnOrderChange: order => onUnpinnedColumnOrderChange({order, setState: setColumnOrder, tableName}), // only triggered when UNPINNED columns ordered!
	})

	// See fn definition for a description of the complicated logic for ordering pinned columns.
	onPinnedColumnOrderChange({pinnedColumns, columnPinning, tableName})

	useEffect(() => {
		// Use effect to hide columns based on the user's cached preferences.
		advancedTableInstance.setColumnVisibility(() => columnVisibilityObject({allTableColumns, visibleColumns}))
	}, [visibleColumns])

	useEffect(
		// Use effect to group the table based on the user's cached preferences.
		() =>
			onGroupingChange({groupedColumn, setGrouping, onSortingChange, setSort, instanceTaxonomy, advancedTableInstance, setColumnPinning}),
		[groupedColumn],
	)

	return advancedTableInstance
}
