import {useSelector} from 'react-redux'
import {getEnvSettingGroup} from 'selectors/new_settings'
import {date} from 'initializers/date'
import {getOpenRange} from 'cores/availability/helpers'

export const useAvailability = envId => {
	const settings = useSelector(state => getEnvSettingGroup(state, 'beschikbaarheid', envId))
	const {planning_ordering, only_managers, disabled} = settings
	const alwaysShowPrefShifts = planning_ordering != 'mixed'
	const openRange = getOpenRange(settings)
	const firstOpenDate = openRange.start
	const isLocked = _date => !openRange.contains(date(_date))
	const isOpen = _date => openRange.contains(date(_date))

	return {firstOpenDate, openRange, isLocked, isOpen, settings, only_managers, alwaysShowPrefShifts, disabled}
}

export default useAvailability
