import {API} from '@eitje/easy_api'
import {store} from 'index'
import Pusher from 'pusher-js'
import * as jsUtils from 'utils/jsutils'

// release for pusher
const push = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
	cluster: 'eu',
	encrypted: false,
})

export function getUserChannelId() {
	return `user-${pusher.userId}`
}

export function getUserChannel() {
	return getChannel(getUserChannelId())
}

export function getChannel(chanId) {
	if (!pusher) return null
	return pusher?.channels.channels[chanId]
}

export default function initPusher(env_id, roles = {}, teams, userId, orgId) {
	push.allChannels().forEach(chan => chan.unbind())
	push.userId = userId
	var msgChan = pusher.subscribe(`${env_id}-msg`)
	msgChan.bind('standard', function (data) {
		jsUtils.openNotif(data.title, data.type, data.desc)
	})

	if (roles['financieel']) {
		const financeChan = pusher.subscribe(`financieel-${env_id}`)
		financeChan.bind('update_entity', handleFinancial)
	}
	setUpOrg(orgId)
	setUpEnv(env_id)
	setUpUser(userId)
	setUpTeams(teams)
	if (roles['manager']) setUpManager(env_id)
	if (roles['uren_schrijven']) setUpWriter(env_id)
}

const setUpOrg = orgId => {
	const chan = push.subscribe(`org-${orgId}`)
	bindEntityChannels(chan)
}

const setUpEnv = envId => {
	const channel = push.subscribe(`env-${envId}`)
	channel.bind('update_settings', ({item, id}) => store.dispatch({type: 'UPDATE_ENV_SETTINGS', id: envId, settings: item}))
	bindEntityChannels(channel)
}

const setUpTeams = (teams = []) => {
	teams.forEach(team => {
		const chan = push.subscribe(`team-${team.id}`)
		bindEntityChannels(chan)
	})
}

const setUpManager = envId => {
	const chan = push.subscribe(`manager-${envId}`)
	bindEntityChannels(chan)
}

const setUpWriter = envId => {
	const chan = push.subscribe(`writer-${envId}`)
	bindEntityChannels(chan)
}

// user channel
const setUpUser = userId => {
	const chan = push.subscribe(`user-${userId}`)
	chan.bind('vimeo_upload', handleVideoProcessing)
}

const bindEntityChannels = chan => {
	chan.bind('update_entity', updateEntity)
	chan.bind('update_entities', updateEntities)
	chan.bind('delete_entity', delEntity)
	chan.bind('records_changed', getUpdatedRecords)
}

const getUpdatedRecords = ({record_names = []}) => {
	record_names.forEach(t => API.index(t))
}

const handleFinancial = ({items}) => {
	API.index('integration_base_connections')
	store.dispatch({type: 'UPDATE_REVENUE', items})
}

const handleVideoProcessing = payload => {
	const edit = {...payload.video, ...payload.progress}
	store.dispatch({type: 'SET_PROCESSING_VIDEO', item: edit})
}

const updateEntity = ({item, name}) => {
	// console.log(`Update entity - ${name} - ${item.id}`)
	API.createLocal(name, item)
}

const updateEntities = ({ids, name}) => {
	// console.log(`Update entities - ${name} - ${ids}`)
	API.getByIds(name, ids)
}

const delEntity = ({id, name}) => {
	// console.log(`Delete entity - ${name} - ${id}`)
	API.destroyLocal(name, id)
}

export const pusher = push
