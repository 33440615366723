import {Text, EitjeDropdown} from 'common/components'
import {t} from 'initializers/i18n'
import {getLeftOffset, isLastPinnedColumn, moveColumn, groupColumn, hideColumn, pinOrUnpinColumn} from 'common/components/advanced_table'
import {formatCssVars, makeCnVariants} from 'helpers'

export const Dropdown = ({header, ...rest}) => {
	// Table taxonomy
	const {column, getContext, index, id: columnName} = header
	const {getIsSorted, getIsPinned, getCanSort, getCanPin, pin, getIsGrouped} = column
	const context = getContext()
	const {
		getLeftLeafColumns,
		setSorting,
		getCenterFlatHeaders,
		getVisibleLeafColumns,
		options: {
			meta: {tableName},
		},
	} = context.table

	// Sorting columns
	const canSort = getCanSort()
	const sortDirection = getIsSorted()
	const isGroupedColumn = getIsGrouped()
	const SortText = <Text t="table.sort" />

	// Pinned columns
	const isPinned = getIsPinned()
	const pinnedColumns = getLeftLeafColumns?.()._map('id')
	const pinnedOffset = formatCssVars({'left-offset': getLeftOffset(index, context)})
	const lastPinned = isLastPinnedColumn(header.index, context)
	const style = isPinned ? pinnedOffset : {}

	// Column positions
	const visibleColumns = getVisibleLeafColumns()
	const unpinnedColumns = getCenterFlatHeaders()._map('id')
	const leftmostPinned = isPinned && pinnedColumns[0] === columnName
	const leftmostUnpinned = unpinnedColumns[0] === columnName
	const rightmostPinned = isPinned && _.last(pinnedColumns) === columnName
	const rightmostUnpinned = _.last(unpinnedColumns) === columnName

	// General
	const cacheKey = `${tableName}_table`
	const classNames = makeCnVariants('advanced-table-header-cell-wrapper', isPinned && 'pinned', lastPinned && 'last-pinned')

	const elements = [
		canSort && {
			icon: 'arrow-up',
			onClick: () => setSorting([{id: columnName, desc: false}]),
			disabled: sortDirection === 'asc' && {key: 'table.already_sorted', direction: t('common.ascending')},
			element: SortText,
		},
		canSort && {
			icon: 'arrow-down',
			onClick: () => setSorting([{id: columnName, desc: true}]),
			disabled: sortDirection === 'desc' && {key: 'table.already_sorted', direction: t('common.descending')},
			element: SortText,
		},
		getCanPin() && {
			icon: 'push-pin',
			element: <Text t={`table.${isPinned ? 'unpin' : 'pin'}`} />,
			disabled: pinnedColumns.length === 1 && isPinned ? 'table.cant_unpin_only' : isGroupedColumn && 'table.cant_unpin_grouped',
			onClick: () => pinOrUnpinColumn({tableName, header, pinnedColumns}),
		},
		{
			icon: 'list',
			element: <Text t={`table.${isGroupedColumn ? 'ungroup' : 'group'}`} />,
			onClick: () => groupColumn({tableName, isGroupedColumn, columnName}),
		},
		{
			icon: 'arrow-left',
			element: <Text t="table.move" />,
			disabled: leftmostUnpinned
				? 'table.move_over_grouped'
				: isGroupedColumn
				? 'table.cant_move_grouped'
				: leftmostPinned && 'table.cant_move_column',
			onClick: () => moveColumn({direction: 'left', header, context, isPinned}),
		},
		{
			icon: 'arrow-right',
			element: <Text t="table.move" />,
			disabled: isGroupedColumn ? 'table.cant_move_grouped' : (rightmostPinned || rightmostUnpinned) && 'table.cant_move_column',
			onClick: () => moveColumn({direction: 'right', header, context, isPinned}),
		},
		{
			icon: 'eye-closed',
			element: <Text t="table.hide" />,
			disabled: isGroupedColumn ? 'table.cant_hide_grouped' : visibleColumns.length < 2 && 'table.cant_hide_all',
			onClick: () => hideColumn({visibleColumns, header, tableName}),
		},
	]

	return <EitjeDropdown elements={elements} trigger="click" style={style} className={classNames} {...rest} />
}
