import {useMemo} from 'react'
import {User, PlanningShift} from 'models'
import {usePlanningTableContext} from 'cores/planning/pages/table'
import {useRoleEnvs} from 'hooks'
import {contractJoins} from 'constants/users'
import {useConflictedShifts} from 'cores/planning/index'

const opts = {joins: ['skillSets', 'allowances', {teams: 'environments'}]}

export const usePlanningTableShifts = () => {
	const {dateRange} = usePlanningTableContext()
	const managerEnvs = useRoleEnvs('manager')
	const managerEnvIds = managerEnvs.ids()
	const contractEnvIds = useRoleEnvs('contracten').ids()
	const baseShifts = PlanningShift.where({date: dateRange}, opts)

	const shifts = useMemo(() => {
		const filteredShifts = baseShifts.filter(shift => {
			// If joins can't find the team, this user apparently does not belong to the environment of the team.
			// Therefore, the user can never have the appropriate role, also the code below would crash.
			if (!shift.team) return false

			const isManager = managerEnvIds.includes(shift.team.environment_id)
			return shift.published || isManager
		})

		return filteredShifts
	}, [baseShifts, managerEnvs])

	// Decorate outside filter to prevent conditionally calling hooks through the memo.
	let decoratedShifts
	decoratedShifts = addContractValuesToShifts(shifts, contractEnvIds)

	const conflictShifts = useConflictedShifts(shifts, dateRange)
	decoratedShifts = addConflictValuesToShifts(decoratedShifts, conflictShifts)

	return decoratedShifts
}

export function addContractValuesToShifts(shifts, contractEnvIds) {
	// Decorate shifts with desired contract data. To prevent blowing up the size of the shift records, we don't use joined objects,
	// but insert flat values into each shift.

	const userIds = shifts._map('user_id').uniq().filter(Boolean)
	const users = User.where({id: userIds}, contractJoins)

	return shifts.map(shift => {
		const user = users.find(u => u.id === shift.user_id)

		if (user) {
			const contractHolder = user.currentContractHolder()
			const contractRole = contractHolder && contractEnvIds.includes(contractHolder.environment_id)

			// Don't check contract role for the environent_id of the shift, since shifts may be worked outside
			// the contract environment of that user, so that would enable some users to see salaries they're not
			// allowed to see, if they do have the role in that shifts' environment.
			if (contractRole) {
				const employmentType = user.currentUserEmploymentType()
				const workSchedule = user.currentWorkSchedule()
				const salary = user.currentSalary()

				shift.activeContract = !_.isEmpty(contractHolder)
				shift.contractEnvironmentId = contractHolder?.environment_id
				shift.contractStartDate = contractHolder?.start_date
				shift.contractEndDate = contractHolder?.end_date
				shift.employmentType = employmentType?.name
				shift.contractHours = workSchedule?.total_mins
				shift.grossHourlyWage = salary?.amount
				shift.costPerHours = user.cost_per_hours
			}
		}
		return shift
	})
}

function addConflictValuesToShifts(shifts, conflictShifts) {
	return shifts.map(shift => {
		const conflict = conflictShifts.find(conflictShift => shift.id === conflictShift.id)
		shift.conflict = !!conflict
		return shift
	})
}
