import {t} from 'initializers/i18n'
import {useShared} from 'hooks'
import {ListPicker} from '../list_picker'

export const EnvironmentFilter = ({environments, ...rest}) => {
	const {orgEnvs} = useShared()
	const items = environments || orgEnvs

	return (
		<ListPicker
			placeholder={t('environment')}
			title={t('common.environment_plural')}
			labelField="naam"
			items={items}
			{...rest}
			name={t('environment')}
		/>
	)
}
