import {t} from 'initializers/i18n'
import {Allowance} from 'models'
import {ListPicker} from '../list_picker'

export const AllowancePicker = ({environmentId, ...rest}) => {
	let allowances = Allowance.where({environment_id: environmentId, active: true})
	allowances = allowances.filter(a => a.isFunction())
	if (!allowances.length) return null
	return <ListPicker title={t('allowance')} single dropdown items={allowances} field="allowance_id" {...rest} />
}
