import {isFunction} from 'helpers'
import {useRoleMessage} from 'hooks'

export function useAdvancedTableRoleMessage(cell) {
	const {enableRole} = cell.column.columnDef
	if (!enableRole) return

	const envId = figureOutEnvId(cell)
	return useRoleMessage({roles: enableRole, env: envId})
}

function figureOutEnvId(cell) {
	const {roleEnv} = cell.column.columnDef

	if (!roleEnv) return null

	if (isFunction(roleEnv)) {
		const record = cell.getContext().row.original
		return roleEnv(record)
	}

	// may be an integer or array
	return roleEnv
}
