import React, {useEffect} from 'react'
import {date} from 'initializers/date'
import SubHeader from './components/sub_header'
import FilterFooter from './components/filter_footer'
import AvailabilityContext from 'contexts/availability'
import EnvTable from './env_table/index'
import {useAvContext} from 'contexts/availability'
import {useQueryParams} from 'hooks/index'
import {PageLayout} from 'common/components'

const Index = () => {
	const {start_date} = useQueryParams()
	const {setDates} = useAvContext()
	useEffect(() => {
		if (start_date) {
			setDates(date(start_date).startOf('week').format())
		}
	}, [])
	return (
		<PageLayout bodyBottomPadding={72} name="availability" headers={<SubHeader />} footers={<FilterFooter />}>
			<EnvTables />
		</PageLayout>
	)
}

const EnvTables = () => {
	const {envs} = useAvContext()
	return (
		<>
			{envs.map(e => (
				<EnvTable env={e} />
			))}
		</>
	)
}

const Wrapper = () => {
	return (
		<AvailabilityContext>
			<Index />
		</AvailabilityContext>
	)
}

export default React.memo(Wrapper, areEqual)

function areEqual(prevProps, nextProps) {
	return true
}
