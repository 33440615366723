import {backend} from '@eitje/easy_api'
import {t} from 'initializers/i18n'
import * as jsUtils from 'utils/jsutils'

export const sendInvitation = async userId => {
	const res = await backend.post(`users/${userId}/invite`)
	if (res.ok) {
		jsUtils.openNotif(t('success'), 'info', t('sentActivation'))
	}
}
