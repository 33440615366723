import {Icon} from '@eitje/web_components'
import {CheckboxButton} from 'components/ui'
import usePlanningDay from 'cores/planning/hooks/use_planning_day'
import {t} from 'initializers/i18n'
import {useHasRole, useShared, useHasRoleInAnyEnv, useRoleOrgEnvs} from 'hooks'
import {NAMESPACE} from 'cores/planning/pages/index/week_table_days_header'
import utils from '@eitje/web_utils'
import {Day} from 'models'
import {useSimpleContext} from 'contexts/simple'
import _ from 'lodash'
import {useEffect} from 'react'
import {Layout, SettingsButton, Text} from 'common/components'

export function useLockOptions(date) {
	const {orgEnvs, multiEnvOrg} = useShared()
	const nested = multiEnvOrg
	let items = orgEnvs.map(env => EnvAvailability({env, date, singleEnv: !nested}))
	// In EnvAvailability we dont want to return a component, but an object, however we also need to call a hook.
	// We use the props of the component to generate the desired object below:
	// We have temporary renamed "disabled" so that it is not passed to CheckboxButton, but instead handled by the dropdown
	items = items.map(i => ({element: i, disabled: i.props.isDisabled, ...i.props}))

	// A) passing children automatically makes it a nested DD, pass null instead
	// B) get first item from array since it is a single env when not nested
	if (!nested) return [{...items[0], children: null}]
	return multiEnvLockOptions({date, items})
}

function multiEnvLockOptions({date, items}) {
	const allDisabled = items._every('disabled')
	let closeAll = CloseAllEnvs({date, envObjects: items, allDisabled})
	closeAll = {element: closeAll, ...closeAll.props}
	return [
		{
			title: t(`common.availability`),
			icon: 'locked',
			children: [...items, closeAll],
		},
	]
}

const EnvAvailability = ({env, date, singleEnv}) => {
	const {closed, condOpts, dateLocked} = usePlanningDay(date, env.id)
	const {onClick} = condOpts
	const {setEnvsStatus} = useSimpleContext()
	const statusKey = closed ? 'closed' : 'open'
	const labelPrefix = singleEnv ? t(`common.availability`) : env.naam
	const label = `${labelPrefix}  (${t(`common.${statusKey}`)})`

	useEffect(() => setEnvsStatus(prev => ({...prev, [env.naam]: {closed, dateLocked}})), [closed, dateLocked])

	const disabled = useAvailabilityIsDisabled({date, env})
	return <CheckboxButton isDisabled={disabled} onChange={onClick} checked={!closed} children={label} />
}

function useAvailabilityIsDisabled({date, env}) {
	const namespace = `${NAMESPACE}.disabled`
	const isManager = useHasRole('manager', env.id)
	const inPast = utils.inPast(date)
	const {dateLocked} = usePlanningDay(date, env.id)

	// Since being a manager or not is more fundamental than the current day being in the past, rather inform user on lacking role.
	if (!isManager) return t(`${namespace}.manager_role`, {environment: _.capitalize(env.naam)})
	if (inPast) return t(`${namespace}.availability_of_past`)

	if (dateLocked)
		return {
			key: t(`availability.date_locked_message`),
			title: t('avPeriod'),
			buttons: <SettingsButton modalLink={`/availability/${env.id}/settings/period`} />,
		}
}

const CloseAllEnvs = ({date, envObjects, allDisabled}) => {
	const managerEnvs = useRoleOrgEnvs('manager')
	const disabled = useCloseAllEnvsIsDisabled({date, allDisabled})
	const {envsStatus, ...rest} = useSimpleContext()
	const allLocked = Object.values(envsStatus)._every('closed')
	const openOrLock = t(`common.${allLocked ? 'open' : 'close'}_all`)
	let lockableAmount = envObjects.filter(env => env.checked && !env.disabled).length
	lockableAmount = lockableAmount ? `(${lockableAmount})` : ''

	const onClick = () => {
		Day.multiUpdate(managerEnvs.map(env => ({date, closed: !allLocked, environment_id: env.id})))
	}

	return (
		<Layout onClick={onClick} disabled={disabled}>
			<Icon name="locked" />
			<Text>
				{openOrLock} {lockableAmount}
			</Text>
		</Layout>
	)
}

function useCloseAllEnvsIsDisabled({date, allDisabled}) {
	const namespace = `${NAMESPACE}.disabled`
	const inPast = utils.inPast(date)
	const isManager = useHasRoleInAnyEnv('manager')

	if (!isManager) return t(`${namespace}.any_manager_role`)
	if (inPast) return t(`${namespace}.availability_of_past`)
	if (allDisabled) return t(`${namespace}.all_availability_envs_disabled`)
}
