import {ENV} from 'initializers/api'
export const falsy = [false, null]

const isDev = process.env.NODE_ENV == 'development'
const envStage = process.env.REACT_APP_STAGE
const isProd = !isDev && envStage == 'production'
export const isTest = envStage == 'test'
export const isStaging = envStage == 'staging'

const isProdClone = ENV == 'mirror' || ENV == 'staging' || ENV == 'test2' // monthly-mirror

const iOSLink = 'https://apps.apple.com/nl/app/eitje/id1268811198'
const androidLink = 'https://play.google.com/store/apps/details?id=com.eitjeapp&hl=nl'
const salesPhoneNumber = '0031202611580'
const salesPhoneNumberFormatted = '020 - 261 15 80'

const languages = ['nl', 'en']

// PR comment: waarom hier een obj exporteren ipv direct de const zelf?
// (voelt voor mij als anti pattern + moet altijd twee plekken editten ipv 1)
export {isDev, isProd, isProdClone, iOSLink, androidLink, salesPhoneNumber, salesPhoneNumberFormatted, languages}

export const cannyLink = 'https://eitje.canny.io/feature-requests'
