import useFilter, {setFilter as saveFilter} from 'hooks/use_filter'

const setFilter = ({activeIds, filterName, ids}) => {
	if (_.isEqual(activeIds, ids)) {
		saveFilter(filterName, [])
	} else {
		saveFilter(filterName, activeIds)
	}
}

export default setFilter
