import {Placeholder as _Placeholder, DocsButton, Text} from 'common/components'
import {ExtLink} from 'components/routing'
import {getBaseUrl} from 'hooks'
import {NAMESPACE} from '.'

const shared = {collection: true, baseColor: 'blue', colorSet: 'grey-bordered'}

export const Placeholder = () => {
	const helpCenterUrl = getBaseUrl()

	return (
		<_Placeholder
			name="welcome_to_eitje"
			animation="winning_man"
			layoutProps={{height: 'auto'}}
			customButtons={[
				<DocsButton to={3355108} t="header.planning" iconLeft="calendar" {...shared} colorSet="solid" />,
				<DocsButton to={3355113} t="header.time_registration" iconLeft="clock" {...shared} />,
				<DocsButton to={3355097} t="header.social" iconLeft="chat" {...shared} />,
			]}
			customChildren={
				<ExtLink to={helpCenterUrl}>
					<Text t={`${NAMESPACE}.view_all_documentation`} blue underline />
				</ExtLink>
			}
		/>
	)
}
