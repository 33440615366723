import {ContextForm} from '@eitje/form'
import {useParams} from 'hooks'
import {useRef} from 'react'
import {useEnvSettingGroup} from 'hooks'
import './styles/index.less'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {saveSettingGroup} from 'actions/settings'
import {MealAllowanceInputsWrapper} from './allowance_input'
import {AddItemForm} from 'components/forms/add_item_form'

const Page = () => {
	const {envId} = useParams()
	const {maaltijd_opts, maaltijd_selected} = useEnvSettingGroup('maaltijd', envId)
	const mealAllowanceRef = useRef()

	const handleAddAllowance = newItem => {
		if (maaltijd_opts.map(str => str.toLowerCase()).includes(newItem.toLowerCase())) {
			return utils.errorNotif({
				content: t('form.add_meal_allowance.fields.duplicate_error'),
			})
		}

		mealAllowanceRef?.current?.addNewAllowanceFromParent(newItem)

		return {ok: true}
	}

	const generateValues = (field, valueFn) => {
		return Object.assign({}, ...maaltijd_opts.map((allowance, i) => ({[`${field}-${i + 1}`]: valueFn ? valueFn(allowance) : allowance})))
	}

	const initialValues = {
		...generateValues('name'),
		...generateValues('active', allowance => maaltijd_selected.includes(allowance)),
	}

	const handleSubmit = data => {
		const filterFormValues = (field, condition) =>
			Object.keys(data).filter(formKey => data[formKey] !== undefined && formKey.includes(field) && (condition ? condition(formKey) : true))

		const nameKeys = filterFormValues('name')
		const activeKeys = filterFormValues('active', formKey => !!data[formKey])

		const payload = {
			maaltijd_opts: nameKeys.map(name => data[name]),
			maaltijd_selected: activeKeys.map(name => data[name.replace('active', 'name')]),
		}

		return saveSettingGroup('maaltijd', payload, {env_id: envId})
	}

	return (
		<div className="meal-allowance">
			<AddItemForm onAdd={handleAddAllowance} transNamespace="add_meal_allowance" />
			<ContextForm
				transNamespace="meal_allowances"
				initialValues={initialValues}
				onSubmit={handleSubmit}
				fieldProps={{submitStrategy: 'throttledChange'}}
				throttleTime={1000}
				submitInitialValues
				resetAfterSubmit
			>
				<div className="meal-form-items">
					<div className="meal-allowance-header">
						<h6>{t('name')}</h6>
						<h6>{t('active')}</h6>
					</div>
					<MealAllowanceInputsWrapper ref={mealAllowanceRef} envId={envId} />
				</div>
			</ContextForm>
		</div>
	)
}

export default Page
