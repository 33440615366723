import {backend} from '@eitje/easy_api'
import {Button} from 'antd'
import useAsyncEffect from 'hooks/use_async_effect'
import {t} from 'initializers/i18n'
import QueryString from 'query-string'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'

const Page = ({location}) => {
	const [valid, setValid] = useState(false)
	const lsState = useSelector(state => state.auth.lightspeedState)
	const connection_id = useSelector(state => state.auth.connection_id)
	const {code, state} = QueryString.parse(location.search)

	const isValid = !!(lsState === state && code)

	const [loading, setLoading] = useState(isValid)

	useAsyncEffect(async () => {
		if (isValid) {
			const res = await backend.post('koppelingen/lightspeed', {code, connection_id})
			setLoading(false)
			if (res.ok) {
				setValid(true)
				// save rev groups
			}
		}
	}, [])

	if (valid) return <Redirect to="/integrations" />

	return (
		<div
			className="shadow lightspeed"
			style={{
				width: '25%',
				border: '1px solid #eee',
				margin: 'auto',
				borderRadius: 4,
				backgroundColor: 'white',
				padding: '80px 24px 40px 24px',
				marginTop: 120,
				boxShadow: '0 5px 20px 0 rgba(34,43,55,.15)',
			}}
		>
			<div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
				<img style={{width: 320, marginBottom: 40}} src="/images/integrations/lightspeed_redirect.png" />
				{loading && <span style={{display: 'block'}}>{t('lightspeed1')}</span>}
				{valid && (
					<div>
						<span style={{marginBottom: 24, display: 'block'}}>{t('lightspeed2')}</span>
						<Button>
							<Link to="/integrations">{t('goToSettings')}</Link>
						</Button>
					</div>
				)}
				{(!valid || !isValid) && !loading && (
					<div>
						<span style={{marginBottom: 24, display: 'block'}}>{t('lightspeed3')}</span>
						<Button>
							<Link to="/integrations">{t('goToSettings')}</Link>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default Page
