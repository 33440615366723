import {EitjeButton, Layout, LogoutButton} from 'common/components'
import {useBillingDone} from 'cores/billing/hooks'
import {useHasRoleInAnyEnv, useShared} from 'hooks'
import {isIOS} from 'helpers'
import {iOSLink, androidLink} from 'constants/general'
import {startConversation, hideLauncher} from 'actions/support'
import {useEffect} from 'react'
import {t} from 'initializers/i18n'
import {getBillingInfo} from 'actions/billing'
import './styles/mobile_blocker.less'
import {logout} from 'actions/auth'

const MobileBlocker = () => {
	const billingDone = useBillingDone()
	const iOS = isIOS()
	const link = iOS ? iOSLink : androidLink
	const {me, envId} = useShared()

	// If the user is a new lead, we don't want to redirect him to the mobile app, since we believe that the web app will
	// be better at converting hesitant leads. Use the isAdmin var as proxy.
	const isAdmin = useHasRoleInAnyEnv('admin')
	const isLead = isAdmin && !billingDone
	const subtitleKey = isLead ? 'admin_subtitle' : 'subtitle'

	useEffect(() => {
		setTimeout(hideLauncher)
		if (!me || !envId) return
		getBillingInfo()
	}, [])

	return (
		<div className="mobile-blocker">
			<a href="https://www.eitje.app">
				<img className="eitje-logo" src="/images/logoLarge1.png" />
			</a>
			<Layout
				className="mobile-blocker-content"
				direction="vertical"
				gap={16}
				name="mobile_blocker"
				padding={'140 24 - 24'}
				horizontal="center"
				height="100vh"
			>
				<p className="mobile-blocker-title">{t('mobile_blocker.title')}</p>
				<p className="mobile-blocker-subtitle">{t(`mobile_blocker.${subtitleKey}`)}</p>
				<Layout direction="vertical" width="100%">
					{!isLead && <EitjeButton externalLink={link} width="full" iconLeft="download" t="download" />}
					{!billingDone && <EitjeButton onClick={startConversation} width="full" iconLeft="chat" t="chat" />}
					{!billingDone && <EitjeButton externalLink={'tel:+31202616671'} width="full" iconLeft="phone" t="call" />}
					{/*Pass name={null} to prevent prefixing translation key*/}
					{<LogoutButton name={null} width="full" />}
				</Layout>
			</Layout>
		</div>
	)
}

export default MobileBlocker
