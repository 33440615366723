import {displayFilter, useTableTax} from 'cores/exports2/index'
import {useSimpleContext} from 'contexts/simple'
import {EitjeButton} from 'common/components'

export const ExtraFiltersRow = () => {
	const {formItem} = useSimpleContext()
	const {filters} = useTableTax(formItem.table_name)
	const extraFilters = filters.row.filter(f => !f.required).map(f => f.filter)
	const formFilters = formItem.row_filters.filter(f => extraFilters.includes(f.filter))

	return (
		<div className="extra-filters">
			<EitjeButton iconLeft="plus" link="extra" t="common.add_filter" />
			{formFilters.map(f => (
				<RemoveCard filter={f} />
			))}
		</div>
	)
}

const RemoveCard = ({filter}) => {
	const id = filter.filter
	const {updateState, setUpdateState} = useSimpleContext()
	const remove = () => {
		const newFilts = updateState.row_filters.filter(f => f.filter != id)
		setUpdateState({row_filters: newFilts})
	}
	const displayVal = displayFilter(filter)
	return (
		<p className="remove-card" onClick={remove}>
			{displayVal} x
		</p>
	)
}
