import {t} from 'initializers/i18n'
import React from 'react'
import './styles/news.less'
import {API} from '@eitje/easy_api'
import {Input} from '@eitje/form-fields-web'
import {useTeamFilterForm} from 'hooks/use_team_filter'
import {EitjeModal} from 'components/common'
import {ContextForm} from '@eitje/form'
import FormRow from 'components/form_row'
import {newImagePicker as ImagePicker} from 'lib/form/fields/image_picker'

const TopicForm = ({edit, onDel, close, ...rest}) => {
	const {filter} = useTeamFilterForm({role: 'manager'})

	const onSubmit = data => API.create('topic', data)
	return (
		<ContextForm transNamespace="topic_form" onSubmit={onSubmit} afterSubmit={close} {...rest}>
			<EitjeModal onCancel={close} onDel={onDel} name="topic_form" form title={t(edit ? 'topic' : 'createTopic')}>
				<FormRow>
					<Input icon={false} bordered={false} required field="title" placeholder={null} label={t('title')} />
				</FormRow>
				<FormRow>
					<Input
						icon={false}
						bordered={false}
						required
						maxLength={240}
						field="subtitle"
						className="eitje-input-with-counter"
						label={t('extraInformation')}
						placeholder={null}
					/>
				</FormRow>
				<FormRow>
					<ImagePicker field="remote_avatar_url" icon={false} />
				</FormRow>

				{!edit && ( // this is for the modal
					<FormRow>{filter}</FormRow>
				)}
			</EitjeModal>
		</ContextForm>
	)
}

export default TopicForm
