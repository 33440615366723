import {t} from 'initializers/i18n'
import pluralize from 'pluralize'
import {Text} from 'common/components'

const docsUrl = path => `https://docs.eitje.app/${path}`

export const resourceT = kind => {
	const translate = (key, opts) => {
		const translations = [`records.${kind}.fields.${key}`, `records.default.fields.${key}`]
		return t(translations, opts)
	}

	return translate
}

export const getEnvVar = name => process.env[`REACT_APP_${name.toUpperCase()}`]

const getTableName = (name = '', {count = 2} = {}) => t(`records.${pluralize.singular(name)}.name`, {count})

export const getEntityName = (entities, type, {maxLen = 2, Component = Text, componentProps} = {}) => {
	if (entities.length <= maxLen) return entities.map(e => getName(e)).join(', ')

	const shownNames = entities
		.slice(0, maxLen)
		.map(e => getName(e))
		.join(', ')
	const andEntities = entities.slice(maxLen)
	const andNames = andEntities.map(e => getName(e)).join(', ')

	return (
		<Component popoutTitle={andNames} {...componentProps}>
			{shownNames} {t('common.and')} {andEntities.length} {type}
		</Component>
	)
}

export function getStringUpToSubstr(str, sub) {
	let index = str.indexOf(sub)
	if (index !== -1) {
		return str.substring(0, index + sub.length)
	} else {
		return 'Substring not found.'
	}
}

export const getName = e => {
	return e.fullName?.() || e.name || e.full_name || e.naam || e
}

function isMobile() {
	// Extended list of common mobile phone user agents
	var mobileAgents = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i

	// Check for touch events, commonly found in mobile phones
	var hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints

	// Set a maximum screen size characteristic to mobile phones
	var maxMobileScreenWidth = 800 // Adjust this value as needed

	// Check if the user agent is in the list and if the device is likely to have a touchscreen
	return mobileAgents.test(navigator.userAgent) && hasTouchScreen && window.innerWidth <= maxMobileScreenWidth
}

function isIOS() {
	return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

function isAndroid() {
	return /Android/.test(navigator.userAgent)
}

export {docsUrl, getTableName, isMobile, isIOS, isAndroid}
