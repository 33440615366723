import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {makeRegisteredField, useForm} from '@eitje/form'
import {CheckboxCard} from 'common/components/checkbox_card'
import {useFormData} from 'hooks'
import {useSimpleContext} from 'contexts/simple'
import './styles/index.less'
import {getPeriodType, formatMapping} from 'helpers/date'

const PeriodSelectPickerComponent = ({periodData, value = [], onChange, periodLists}) => {
	const {
		form: {props: formProps},
	} = useForm()
	const {is_week} = useFormData()
	const period_type = getPeriodType(is_week)

	const labelClassNames = utils.makeCns('eitje-form-2-label', 'period-select-picker-period-list-label')
	const formName = formProps?.transNamespace
	const pickerLabel = t(`form.${formName}.fields.period_list_picker.period_list_${period_type}.label`)
	return (
		<div className="period-select-picker eitje-form-2-field">
			<p className={labelClassNames}>{pickerLabel}</p>
			<div className="period-select-picker-checkbox-cards">
				{periodLists[period_type].map((periodItem, i) => (
					<PeriodItem periodData={periodData} periodItem={periodItem} key={i} value={value} onChange={onChange} periodType={period_type} />
				))}
			</div>
		</div>
	)
}

export const PeriodSelectPicker = makeRegisteredField(PeriodSelectPickerComponent)

const PeriodItem = ({periodItem, periodData, value, onChange, periodType}) => {
	const {is_week} = useFormData()
	const {formatShiftAmountMessage, checkIfDisabled} = useSimpleContext()
	const currentPeriod = periodItem.format(formatMapping[getPeriodType(is_week)])
	const shiftAmount = periodData?.[currentPeriod]?.count || 0
	const prefix = is_week ? 'Week ' : ''
	const format = is_week ? 'W' : 'MMMM'
	const dateLabel = `${prefix}${periodItem.format(format)}`
	return (
		<CheckboxCard
			onChange={() => onChange(utils.toggle(value, currentPeriod))}
			value={!!value.includes(currentPeriod)}
			classNames="period-select-picker-checkbox-card"
			disabled={checkIfDisabled?.(shiftAmount) || false}
		>
			<div>
				<h5 className="period-select-picker-period-title">{dateLabel}</h5>{' '}
				<h6 className="period-select-picker-period-shifts">- {formatShiftAmountMessage(shiftAmount)}</h6>
			</div>
			<h5>
				{t('fromTill', {
					kind: 'empty',
					from: periodItem,
					till: periodItem.clone().endOf(periodType),
				})}
			</h5>
		</CheckboxCard>
	)
}
