import {EitjeModal} from 'components/common'
import {getSettings} from 'actions/settings'
import {NewModalForm, ModalWithNewForm} from 'components/form/new_modal_form'
import {Input, FormRow} from '@eitje/form-fields-web'
import {useShared} from 'hooks'
import {ListPicker, Message, DocsButton} from 'common/components'
import {User} from 'models'
import {t} from 'initializers/i18n'
import usePlacesAutocomplete from 'hooks/use_places_autocomplete'
import {Environment} from 'models'
import {navigateModal} from 'actions/routing'
import {NAMESPACE} from 'cores/firm'

export const CreateEnvironment = ({}) => {
	const {me, org} = useShared()
	const {id: organisation_id} = org
	const initialValues = {admin_ids: [me.id]}
	const users = User.all()
	const onSubmit = data => Environment.create(data)
	const message = `${NAMESPACE}.messages.create_environment_explanation`
	const modalProps = {confirmSubmit: true, buttons: [<DocsButton t="common.explanation" docsId={8374598} />]}

	const afterSubmit = async ({resData}) => {
		const {item} = resData
		const {id} = item
		await getSettings()
		navigateModal(`/environments/${id}/`, {keepBackground: true})
	}

	return (
		<ModalWithNewForm name="create_environment" width={400} modalProps={modalProps}>
			<NewModalForm onSubmit={onSubmit} initialValues={initialValues} afterSubmit={afterSubmit} oneFieldPerFormRow>
				<Message t={message} baseColor="blue" colorSet="color-bordered-outline" />
				<Input field="naam" required />
				<ListPicker title={t('common.team_members')} field="admin_ids" items={users} dropdown required labelField="full_name" />
			</NewModalForm>
		</ModalWithNewForm>
	)
}
