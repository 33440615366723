import {find} from '@eitje/easy_api'
import {PopoutCard} from '@eitje/web_components'
import {EitjeButton} from 'common/components'
import {ModalLink} from 'components/routing'
import {ColorCircle, UserAvatar} from 'components/ui'
import useMissingContract from 'hooks/use_missing_contract'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import {t} from 'initializers/i18n'
import {useRef} from 'react'
import {useSelector} from 'react-redux'
import {NavLink} from 'react-router-dom'
import './styles/user.less'

const urlParamRegex = /[0-9]+/g
//not really battle tested
export const fixProblem = itemId => {
	const pn = history?.location?.pathname
	const newUrl = pn.replace(urlParamRegex, itemId)
	history.push(newUrl)
}

export const User = ({item}) => {
	const {salary, workWeek, contract} = useMissingContract(item.id)
	const status = !item.confirmed ? t('notConfirmed') : !contract ? t('noContract') : null

	return (
		<>
			{(salary || workWeek) && (
				<div className="margin-right-sm warningCircle">
					<p>!</p>
				</div>
			)}

			<UserAvatar user={item} size="sm" className="margin-right-xs" />

			<div className="list-item-text-container">
				<p className="list-item-title">{item.full_name}</p>
				{!!status && <p className="list-item-sub-italic">{status}</p>}
			</div>
		</>
	)
}

export const ManageSkillsBtn = props => <EitjeButton modalLink="/forms/user_skills" t="assignFast" {...props} />

export const Profile = ({user}) => {
	const currentUser = useSelector(state => state.auth.user)
	const isSelf = user.id === currentUser.id
	const {salary, workWeek, contract} = useMissingContract(user.id)
	let missing
	let path
	if (salary || workWeek) {
		missing = salary ? 'salary' : 'workWeek'
		path = `/forms/contracts/${contract?.id}/${missing}/`
	}
	const skillSet = useSelector(state => find(state, 'skillSets', user.skill_set_ids ? user.skill_set_ids[0] : []))
	return (
		<div className="row">
			<div className="user-banner-avatar-container">
				{skillSet.id && <ColorCircle shape="square" color={skillSet.color_code} text={skillSet.abbr} />}
				<UserAvatar user={user} size="xxxl" className="margin-right-lg" />
			</div>
			<div className="fCol jCen aSta">
				<p className="eitje-heading-xxl"> {user.full_name} </p>
				{(user.vloer_code || isSelf) && (
					<p className="eitje-text-secondary">
						{t('vloerCode')}: {user.vloer_code || currentUser.vloer_code}
					</p>
				)}
				{missing && (
					<PopoutCard title={t('missingInfo_contract')}>
						<ModalLink to={path}>
							<div className="fRow aCen errorMissing">
								<div style={{marginRight: 12}} className="warningCircle fRow aCen jCen">
									<p>!</p>
								</div>
								<p>{t('missing', {kind: `$t(${missing})`})}</p>
							</div>
						</ModalLink>
					</PopoutCard>
				)}
			</div>
		</div>
	)
}

const AlertCircle = () => (
	<div style={{position: 'absolute', marginLeft: -24}} className="warningCircle ">
		<p>!</p>
	</div>
)

const useActiveNavLink = () => {
	const ref = useRef()
	const className = ref?.current?.className || ''
	const active = className.includes('active')
	return {ref, active}
}

const NavLinkTab = ({text, path, exact, alert = false, imageURL}) => {
	const {ref, active} = useActiveNavLink()
	const imgSrc = !active ? `${imageURL}.png` : `${imageURL}_active.png`

	return (
		<NavLink ref={ref} exact={exact} className="nav-link-tab" activeClassName="nav-link-tab active" to={path}>
			{alert && <AlertCircle />}
			<img className="nav-link-image" src={imgSrc} />
			<div className="nav-link-text">{t(text)}</div>
		</NavLink>
	)
}

export const SubHeader = ({user}) => {
	const {hasContractRole, me} = useShared()
	const {hasMissing} = useMissingContract(user.id)
	const base = `/user/${user.id}`
	const isSelf = me.id == user.id

	const headerLinks = [
		{text: 'information', path: `${base}/profile`, imageURL: '/images/web/nav_link_images/insightsI'},
		{text: 'skills', path: `${base}/skills`, imageURL: '/images/web/nav_link_images/skillsI'},
		(hasContractRole || isSelf) && {
			text: 'contracts',
			path: `${base}/contracts`,
			exact: false,
			imageURL: '/images/web/nav_link_images/contractI',
			alert: hasMissing,
		},
	].filter(Boolean)

	return (
		<div className="sub-head-nav" style={{paddingTop: 24}}>
			{headerLinks.map(linkProps => (
				<NavLinkTab {...linkProps} />
			))}
		</div>
	)
}
