import {t} from 'initializers/i18n'
import {ColorCircle} from 'components/ui'
import utils from '@eitje/web_utils'
import {TradeRequest} from 'models/index'
import TradeTrigger from 'cores/planning/components/trade/approve_trade/trigger'
import {EitjeButton, Text, Layout} from 'common/components'
import {EntityCount} from 'common/components/entity_count'
import './styles/trade_row.less'

const PADDING = [12, 12, 12, 24]

const Page = ({id, ...props}) => {
	const tradeRequest = TradeRequest.find(id)
	const {bieder_ids = []} = tradeRequest
	if (!utils.exists(tradeRequest)) return null
	const text =
		bieder_ids.length > 0 ? <EntityCount name="common.application" count={bieder_ids.length} /> : t('planning.trade.view_request')
	return (
		<Layout className="trade-row" horizontal="spaceBetween" padding={PADDING}>
			<Layout gap={8}>
				<ColorCircle text={'!'} fontSize={10} size={16} />
				<Text darkGrey mini t="planning.shift_modal.trade_row.title" />
			</Layout>
			<TradeTrigger id={id} {...props}>
				<EitjeButton height="small" iconLeft="checkbox-checked">
					{text}
				</EitjeButton>
			</TradeTrigger>
		</Layout>
	)
}

export default Page
