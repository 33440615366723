import {all, find, where} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import _ from 'lodash'
import {date} from 'initializers/date'
import pluralize from 'pluralize'
import createCachedSelector from 're-reselect'
import {createSelector} from 'reselect'
import Association from 'models/active_record/association'

const defaultFormat = 'YYYY-MM-DD'
export const envIdSelector = state => state.environment.active

export const currentEnvSelector = createSelector(
	state => all(state, 'environments'),
	envIdSelector,
	(envs, envId) => envs.find(e => e.id === envId) || {},
)

export const selfSelector = createSelector(
	state => all(state, 'users'),
	state => state.auth?.user,
	(users, user) => users.find(u => u.id === user?.id) || user,
)

export const extendSelector = createCachedSelector(
	all,
	(state, tableName) => tableName,
	(state, tableName, items) => items,
	(mergeItems, tableName, items) => extendResources(mergeItems, tableName, items),
)((state, tableName, items) => `${tableName}-${JSON.stringify(items)}`)

const extendResources = (mergeItems, tableName, items) => {
	const singularName = pluralize.singular(tableName)
	const example = items[0]
	if (!example) return []
	const singleField = `${singularName}_id`
	const manyField = `${singularName}_ids`
	const isMany = _.has(example, manyField)
	if (isMany) return // not implemented yet
	return items.map(i => {
		const mergeItem = mergeItems.find(i2 => i[singleField] == i2.id)
		return {...i, [singularName]: mergeItem}
	})
}

// uiteindelijk moeten we misschien een hoofdselector hebben, 'betweenDays' waarvan dit een higher-level API is die gewoon (today, date) called
// en dan kunnen we ook een 'daysbeforeToday' hebben die we callen met (date, today)
// enige vraag die dan nog rest is hoe we omgaan met velden die start..end hebben en velden met alleen date.
export const daysAfterToday = createCachedSelector(
	all,
	(state, tableName) => tableName,
	(state, tableName, days) => days,
	(state, tableName, days, {field = 'date'} = {}) => field,
	(records, tableName, days, field) => daysAfterTodayFn(records, days, field),
)((state, tableName, days, field) => `${tableName}-${days}-${field}`)

const daysAfterTodayFn = (records, days, field) => {
	const minDate = date()
	const maxDate = date().add(days, 'days')
	const filtered = records.filter(r => {
		if (!r[field]) return
		const recordDate = date(r[field])
		return maxDate >= recordDate && minDate <= recordDate
	})
	return filtered
}

export const currentOrgSelector = createSelector(
	envIdSelector,
	state => all(state, 'organisations'),
	(envId, orgs) => orgs.find(o => o && o.environment_ids.includes(envId)) || {},
)

export const newCurrentOrgSelector = createSelector(
	envIdSelector,
	state => all(state, 'organisations'),
	(envId, orgs) => orgs.find(o => o && o.environment_ids.includes(envId)) || {},
)

export const orgEnvsSelector = createSelector(
	currentOrgSelector,
	currentEnvSelector,
	state => all(state, 'environments'),
	(org, env, envs) => (org.id ? envs.filter(e => e.organisation_id == org.id) : new Association([env]).filter(Boolean)),
)

export const myOrgEnvsSelector = createSelector(orgEnvsSelector, selfSelector, (orgEnvs, {environment_ids = []} = {}) =>
	orgEnvs.filter(e => environment_ids.includes(e.id)),
)

export const orgUsersSelector = createSelector(
	state => all(state, 'users'),
	orgEnvsSelector,
	(users, orgEnvs) => {
		const envIds = orgEnvs.map(e => e.id)
		return users.filter(u => _.union(envIds, u.environment_ids).length > 0)
	},
)

export const isUserRemovedSelector = createCachedSelector(
	orgEnvsSelector,
	(state, userId) => find(state, 'users', userId),
	(envs, user) => !envs.find(e => user.environment_ids.includes(e.id)),
)((state, userId) => userId)

export const crazyFilter = (entities, query) => {
	return entities.filter(e => {
		const keys = Object.keys(query)
		return filterKeys(keys, query, e)
	})
}

export const userContractsSelector = createCachedSelector(
	(state, userId = state?.auth?.user?.id) => where(state, 'contracts', {user_id: userId}),
	(conts, userId) => conts,
)((state, userId) => userId)

export const activeContractSelector = createCachedSelector(
	userContractsSelector,
	(state, userId) => userId,
	(state, userId, date) => date,
	(contracts, userId, date = date().format('YYYY-MM-DD')) => findActiveContract(contracts, date) || {},
)((state, userId, date) => `${userId}-${date}`)

export const findActiveContract = (contracts = [], date = date().format('YYYY-MM-DD')) => {
	return contracts.find(cont => {
		if (cont.eind) {
			return cont.begin <= date && cont.eind >= date
		} else {
			return cont.begin <= date
		}
	})
}

const filterKeys = (keys, query, record) => {
	return keys.every(k => {
		const queryVal = query[k]
		const recordVal = record[k]
		const match = filterArrays(queryVal, recordVal)
		if (match) return match
		return queryVal === recordVal
	})
}

function filterArrays(arr1, arr2) {
	if (_.isArray(arr1) && _.isArray(arr2)) return _.intersection(arr1, arr2).length > 0
	if (_.isArray(arr1)) return arr1.includes(arr2)
	if (_.isArray(arr2)) return arr2.includes(arr1)
}

const hasManyEnvs = ['users']

export const queryInEnv = createCachedSelector(
	all,
	envIdSelector,
	(state, kind) => kind,
	(state, kind, query) => query,
	(ents, envId, kind, query = {}) => {
		return crazyFilter(ents, {environment_id: envId, ...query})
	},
)((state, kind, query) => `${kind}-${JSON.stringify(query)}`)

export const inEnvTeam = createCachedSelector(
	// return all items that are part of at least one team of env
	all,
	(state, kind) => kind,
	(state, kind, envId) => find(state, 'environments', envId || envIdSelector(state)),
	(state, kind, envId, query) => query || {},
	(ents, kind, env, query) => crazyFilter(ents, {team_ids: env.team_ids, ...query}),
)((state, kind, envId) => `${kind}-${envId}`)

export const inEnv = createCachedSelector(
	all,
	(state, kind) => kind,
	(state, kind, envId) => envId || envIdSelector(state),
	(ents, kind, envId) => {
		const key = hasManyEnvs.includes(kind) ? 'environment_ids' : 'environment_id'
		return crazyFilter(ents, {[key]: utils.alwaysArray(envId)}) || []
	},
)((state, kind, envId) => `${kind}-${envId}`)

export const inOrg = createCachedSelector(
	all,
	(state, kind) => kind,
	currentOrgSelector,
	currentEnvSelector,
	(records, kind, org, env) => {
		const key = hasManyEnvs.includes(kind) ? 'environment_ids' : 'environment_id'
		return crazyFilter(records, {[key]: org.environment_ids || [env.id]})
	},
)((state, kind) => kind)

export const whereInc = createCachedSelector(
	all,
	(state, key) => key,
	(state, key, query) => query,
	(records, key, query) => crazyFilter(records, query) || [],
)((state, key, query) => `${key}-${JSON.stringify(query)}`)

export const activeOnDate = (items, date) => {
	return filterRangeByDate(items, {start_date: date, end_date: date}, {strict: false})
}

export const activeOnToday = items => {
	const _date = date().format('YYYY-MM-DD')
	return activeOnDate(items, _date)
}

export const filterRangeByDate = (
	items,
	{start_date, end_date} = {},
	{startField = 'start_date', endField = 'end_date', strict = true} = {},
) => {
	const filtered = items.filter(e => {
		const startDate = e[startField]

		const startsBeforeEnd = !end_date || !e[startField] || (strict ? e[startField] < end_date : e[startField] <= end_date)
		const startsAfterStart = !start_date || !e[endField] || (!e[endField] || strict ? e[endField] > start_date : e[endField] >= start_date)
		return startsBeforeEnd && startsAfterStart
	})
	return filtered
}

// cont van 1 jan tot 1 feb, en van 1 feb tot 1 mrt
// vraag is welk contract 'actief' is als je vraagt om het contract op 1 feb.

export const onToday = createCachedSelector(
	(state, key, query) => onDate(state, key, date().format('YYYY-MM-DD'), query),
	records => records,
)((state, key, query) => `${key}-${query}`)

export const inFuture = createCachedSelector(
	whereInc,
	(state, key) => key,
	(state, key, query = {}) => query,
	(state, key, query = {}, dateField = 'start_date') => dateField,
	(records, key, query, dateField) => records.filter(r => r[dateField] >= date().format('YYYY-MM-DD')),
)((state, key, query, dateField) => `${key}-${JSON.stringify(query)}-${dateField}`)

export const onDate = createCachedSelector(
	all,
	(state, key) => key,
	(state, key, date) => date,
	(state, key, date, query = {}) => query,
	(records, key, date, query = {}) => {
		let res = activeOnDate(records, date)
		if (utils.exists(query)) {
			res = crazyFilter(res, query)
		}
		return res
	},
)((state, key, date, opts) => `${key}-${date}-${opts}`)

export const rangeBetweenDays = createCachedSelector(
	all,
	(state, key) => key,
	(state, key, query) => query,
	(state, key, query, opts = {}) => opts,
	(items = [], key, {start_date, end_date, ...rest} = {}, opts) => {
		let res = filterRangeByDate(items, {start_date, end_date}, opts)
		if (utils.exists(rest)) {
			res = crazyFilter(res, rest)
		}
		return res
	},
)((state, key, query, opts) => `${key}-${query}-${opts}`)
