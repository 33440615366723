import {PopoutCard} from '@eitje/web_components'
import {t as _t} from 'initializers/i18n'
import {titleWithCount} from 'common/components/headers/navigation_header/nav_item'
import {UserList} from './user_list'

export const UserListPopout = ({users, t = 'users', children}) => {
	const title = titleWithCount(_t(t), users.length)

	return (
		<PopoutCard title={title} body={<UserList users={users} />} bodyPadding={0} placement="right-start" maxHeight={400} width={300}>
			{children}
		</PopoutCard>
	)
}
