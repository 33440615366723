import {backend} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {Modal} from 'antd'
import {store} from 'index'
import I18n, {t} from 'initializers/i18n'
import {find} from 'linkifyjs'
import _ from 'lodash'
import moment from 'moment'
import {extendMoment} from 'moment-range'
import Numeral from 'numeral'

const confirm = Modal.confirm
//

const Moment = extendMoment(moment)

let days = () => [
	I18n.t('maandag'),
	I18n.t('dinsdag'),
	I18n.t('woensdag'),
	I18n.t('donderdag'),
	I18n.t('vrijdag'),
	I18n.t('zaterdag'),
	I18n.t('zondag'),
]

export const handleUpload = (info, {cb, errorCb, loadingCb, noDefaultErr = false} = {}) => {
	if (info.file.status === 'uploading') {
		loadingCb && loadingCb()
	}
	if (info.file.status === 'done') {
		success()
		cb && cb()
	} else if (info.file.status === 'error') {
		errorCb && errorCb()
		!noDefaultErr && errNotif(t('oops'), t('import_wrong_content'))
	}
}

export const isEq = (first, second) => {
	return JSON.stringify(first) === JSON.stringify(second)
}

export const displayNum = num => {
	return _.isNumber(num) && num > 0 ? num : null
}

export const toPrice = (num, {digits = 2} = {}) =>
	num.toLocaleString('nl-NL', {style: 'currency', currency: 'EUR', minimumFractionDigits: digits, maximumFractionDigits: digits})

export const sumObject = (arrOfObjects = [{}]) => {
	const newObj = {}
	if (arrOfObjects.length === 0) return {}
	const keys = Object.keys(arrOfObjects[0])
	arrOfObjects.forEach(obj => {
		keys.forEach(k => {
			if (!newObj[k]) newObj[k] = 0
			newObj[k] += obj[k]
		})
	})
	return newObj
}

export function findDoubles(arr) {
	const counter = arr.reduce((o, n) => {
		n in o ? (o[n] += 1) : (o[n] = 1)
		return o
	}, {})
	return Object.keys(counter).filter(k => counter[k] > 1)
}

export function isTime(time) {
	if (!_.isString(time)) return false
	try {
		const sp = time.split(':')
		return sp[0].length == 2 && sp[1].length == 2 // retard way ah yea
	} catch (e) {
		return false
	}
}

export const tryToInt = num => {
	return _.isNaN(Number(num)) ? num : Number(num)
}

export function arrayContainsArray(superset, subset) {
	return subset.every(function (value) {
		return superset.indexOf(value) >= 0
	})
}

export function getPct(arr1, totalArr) {
	if (!arr1 || !totalArr) return 0
	return Math.round((arr1.length / totalArr.length) * 100)
}

export function isPresent(field) {
	return field === 0 ? true : !!field
}

export function exists(i) {
	return isPresent(i) && i != '' && (!_.isArray(i) || i.length > 0)
}

export function isAfterDate(date, dateTwo = Moment()) {
	dateTwo = dateTwo.startOf('day')
	return date.startOf('day').diff(dateTwo, 'd') > 0
}

export function isBeforeDate(date, dateTwo = Moment()) {
	dateTwo = dateTwo.startOf('day')
	return date.startOf('day').diff(dateTwo, 'd') < 0
}

export function cleanObject(obj) {
	Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
	return obj
}

export function money(int) {
	return int.toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'})
}

export function cssRandId() {
	return Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, '')
		.substr(2, 10)
}

// export function teamFinder(id) {
//   return gon.teams.find(team => team.id == id)
// }

export function prettyDate(date) {
	return Moment(date).format('dddd DD-MM-YYYY [om] H:mm')
}

export function simpleDate(date, short = false) {
	const format = short ? 'D MMM YYYY' : 'D MMMM YYYY'
	return Moment(date).format(format)
}

export function formatTime(date) {
	return Moment(date).format('HH:mm')
}

export function shortDate(date) {
	return Moment(date).format('ddd DD MMM')
}

export function longDate(date) {
	return Moment(date).format('dddd DD MMMM')
}

export function longYearDate(date) {
	return Moment(date).format("dddd DD MMMM 'YY")
}

export function noYearDate(date) {
	return Moment(date).format('DD MMMM')
}

export function bizDate(date) {
	let string = 'DD-MM-YYYY'
	return Moment(date).format(string)
}

export function getWholeMonth(month, year) {
	const startOfMonth = moment().startOf('month')
	const endOfMonth = moment().endOf('month')
	const diff = Math.abs(startOfMonth.diff(endOfMonth, 'd'))

	var obj = {}

	_(diff).times(time => {
		let date = moment().startOf('month').add(time, 'd')
		obj[date.format('YYYY-MM-DD')] = []
	})
	return obj
}

export function addToAssociation({items, assoc, id, assocItem}) {
	let item = items.find(item => item.id == id)
	if (!item[assoc].includes(assocItem)) item[assoc].push(assocItem)
	return items
}

export function removeFromAssociation({items, assoc, id, assocItem}) {
	let item = items.find(item => item.id == id)
	item[assoc] = item[assoc].filter(i => i != assocItem)
	return items
}

export function reportErrors(errors) {
	errors = _.isArray(errors) ? errors : [errors]
	if (errors && _.isArray(errors)) {
		errors.forEach(t => errNotif('Let op!', capitalize(t)))
	} else {
		// sentry?
	}
}

export function assign(obj1, obj2) {
	return Object.assign(cleanObject(obj1), cleanObject(obj2))
}

export function assignAll(first, second, id = 'id') {
	return first.map(i => assign(i, second.find(i2 => i2[`${id}`] == i[`${id}`]) || {}))
}

export function uniqId(items = [{id: `fake-1`}]) {
	if (items.length == 0) return `fake-1`
	const ids = items
		.map(i => i.id)
		.filter(i => i && _.isString(i))
		.map(i => i.split('-')[1])
	return `fake-${_.max(ids) + 1}`
}

export function dateIsInWeek(week, year, date = Moment()) {
	date = Moment(date)
	return date.isoWeek() === week && date.isoWeekYear() === year
}

export function findAndReplace(objects, object, identifier, secondIden, extraIden) {
	objects = objects.map(a => Object.assign({}, a)) // deep-clone because we don't want to mess with life
	identifier = identifier || 'id'
	var iden_two = secondIden || identifier
	let filtered = objects.filter(u => u[`${identifier}`] == object[`${iden_two}`])

	// maak dit toch dynamisch <3
	let obj = extraIden ? filtered.filter(u => u[`${extraIden}`] == object[`${extraIden}`]) : filtered[0]
	//

	if (obj) {
		const idx = objects.indexOf(obj)
		objects[idx] = {...obj, ...object}
	} else {
		objects.push(object)
	}
	return objects
}

export async function updateEnvSetting(obj) {
	const res = await backend.put(`environment/settings`, {
		settings: obj,
		doNotLoad: true,
	})
	if (res.ok) {
		const state = store.getState()
		const envId = state.environment.active
		store.dispatch({type: 'UPDATE_ENV_SETTINGS', settings: obj, id: envId})
		return res
	}
}

export async function updateSetting(obj) {
	let newObj = {}
	newObj[obj.key] = obj.value
	store.dispatch({type: 'UPDATE_USER_SETTING', settings: newObj})

	const res = await backend.put(`users/${obj.id}/settings`, {
		settings: obj,
		doNotLoad: true,
	})

	if (res.ok) {
		return res
	}
}

export async function updateTeamSetting(rec, settingobj, cb = _.noop) {
	const res = await backend.put(`teams/${rec.id}/settings`, {
		settings: settingobj,
	})
	res.ok && cb()
}

export function defaultIntParser(input) {
	Numeral.locale('nl-nl')
	if (input.charAt(input.length - 1) == ',') {
		return `${Numeral(input).value()},`
	}
	return Numeral(input).value()
}

export function defaultIntFormatter(input) {
	return parseFloat(input).toLocaleString('nl-NL', {style: 'currency', currency: 'EUR'})
}

export function errReport(errors, simple = false) {
	for (var errorCat in errors) {
		if (simple) {
			errors[errorCat].forEach(e => errNotif('Er ging iets fout', e))
		} else {
			errors[errorCat].forEach(e => errNotif('Er ging iets fout met: ' + unSnakeCase(errorCat)))
		}
	}
}

export function titleCase(str) {
	if (str.split('_').length > 0) {
		str = unSnakeCase(str)
	}
	return str
		.toLowerCase()
		.split(' ')
		.filter(Boolean)
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase())
		})
		.join(' ')
}

export function capitalize(str) {
	if (!str) {
		return undefined
	}
	if (str.split('_').length > 0) {
		str = unSnakeCase(str)
	}
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function snakeToCamel(s) {
	return s.replace(/(\_\w)/g, function (m) {
		return m[1].toUpperCase()
	})
}

export function handleSuccess(resp, onSucc, simple = false) {
	resp.json().then(json => {
		if (resp.status == 200) {
			onSucc()
			success()
		} else {
			//errReport(json.errors, simple)
		}
	})
}

export function startFetch() {
	this.setState({
		fetching: true,
	})
}

export function stopFetch() {
	this.setState({
		fetching: false,
	})
}

export function showConfirm(title, content, cb) {
	confirm({
		title: title,
		content: content,
		onOk() {
			cb()
		},
		onCancel() {},
	})
}

// export function errReport(errors, simple = false) {
//    for (var errorCat in errors) {
//       if(simple) {
//         errors[errorCat].forEach(e => errNotif("Er ging iets fout", e))

//       } else {
//       errors[errorCat].forEach(e => errNotif("Er ging iets fout met: " + unSnakeCase(errorCat)) )
//     }
//       var formItem = $(`.${errorCat}.errable`)
//       if(formItem) {
//       formItem.className += " formError"
//       var child = formItem.children()[0]
//       if(child) {
//         child.className += " formError"
//       }

//       var input = formItem.find("input")[0]
//       if(input) {
//         input.className += " formError"
//       }
//   }
// }
// }

export function daysToHourMin(days, hoursInDay) {
	const totHours = days * hoursInDay
	const actHours = Math.floor(totHours)
	const minPart = totHours % actHours
	const actMins = Math.round(minPart * 60)
	return {mins: actMins || 0, hours: actHours || 0}
}

export function minToHourArray(time) {
	// => [3, 40] for 03:40
	const str = _.isString(time) ? time : minToTimeString(time)
	const split = str.split(':')
	const hours = Number(split[0])
	const mins = Number(split[1])
	return [hours, mins]
}

export function timeStringToMmt(timeStr, date) {
	const mins = timeStringToMin(timeStr)
	return minToMmt(mins, date)
}

export function minToMmt(time, date) {
	const mmt = date ? Moment(date) : Moment()
	const timeArray = minToHourArray(time)
	mmt.hours(timeArray[0])
	mmt.minutes(timeArray[1])
	return mmt
}

export function timeStringToMin(time) {
	if (!_.isString(time)) {
		return time
	}
	var spT = time.split(':')
	var time1 = spT[0]
	var time2 = spT[1]
	return Number(time1) * 60 + Number(time2)
}

export function minToTimeString(mins) {
	mins = Math.round(mins)
	if (mins < 0) {
		mins = 0
	}
	var mod = mins % 60
	var hr
	if (mod > 0) {
		hr = (mins - mod) / 60
		if (hr < 10) {
			hr = `0${hr}`
		}
		if (mod < 10) {
			mod = `0${mod}`
		}
		return `${hr}:${mod}`
	} else {
		mod = mins / 60
		if (mod < 10) {
			mod = `0${mod}`
		}
		return `${mod}:00`
	}
}

export function signedMinTTS(mins) {
	const sign = mins >= 0 ? '+' : '-'
	return `${sign}${minToTimeString(Math.abs(mins))}`
}

export function sortTimeArr(arr) {
	return _.sortBy(_.sortBy(arr, 'till'), 'from')
}

export function sortShifts(arr, eind = 'end_date', start = 'start_date') {
	return _.sortBy(_.sortBy(arr, eind), start)
}

export const unknownError = () => {
	errNotif(t('oops'), t('unknownError'))
}

export const errNotif = (msg, descr) => {
	utils.errorNotif({
		title: msg,
		content: descr,
	})
}

export const openNotif = (msg, type = 'info', desc = '') => {
	utils.notify({
		title: msg,
		content: desc,
		type,
		duration: 5,
	})
}

export const success = () => {
	utils.success()
}

export function secondsToTimeString(secs) {
	return new Date(secs * 1000).toISOString().substr(11, 8)
}

export function diffInTime(time1, time2, nextDay = false) {
	if (!time1 || !time2) {
		return
	}
	var t1 = time1.split(':')
	var t2 = time2.split(':')
	var d1 = new Date(0, 0, 0, t1[0], t1[1])
	var d2 = new Date(0, 0, 0, t2[0], t2[1])
	var diff = new Date(d2 - d1)
	var hrs = diff.getUTCHours().toString()
	var mins = diff.getUTCMinutes().toString()
	if (hrs.length == 1) {
		hrs = `0${hrs}`
	}
	if (mins.length == 1) {
		mins = `0${mins}`
	}
	return `${hrs}:${mins}`
}

export function two_object_overlap(shift, user, day) {
	var shiftRange = createMomentRange(shift.from, shift.till, day)
	var userRange = createMomentRange(user.from, user.till, day)
	return shiftRange.overlaps(userRange)
}

function createMomentRange(frm, till, mmtstart = Date()) {
	var splitted1 = frm.split(':').map(item => Number(item))
	var splitted2 = till.split(':').map(item => Number(item))
	var mmt1 = Moment(mmtstart).hour(splitted1[0]).minutes(splitted1[1])
	var mmt2 = Moment(mmtstart).hour(splitted2[0]).minutes(splitted2[1])
	if (mmt1 > mmt2) {
		mmt2.add(1, 'd')
	}
	return Moment.range(mmt1, mmt2)
}

export function addOrRemove(arr, nieuw, prop) {
	arr = [...arr]
	// deze functie is bedoeld om te helpen met togglen. Hij kijkt of het item wat getoggled wordt (nieuw) in de array aanwezig is
	// zoja wordt-ie verwijderd, anders toegevoegd. de prop-parameter kan ingevuld worden als het een arr van objecten bevat
	// zodat de aanroeper zelf kan definieren wat-ie bedoelt met aanwezig zijn in de array. Dat weten wij als globale functie niet natuurlijk.
	if (prop) {
		if (arr.some(e => e[prop] == nieuw[prop])) {
			arr = arr.filter(item => item[prop] != nieuw[prop])
		} else {
			arr.push(nieuw)
		}
	} else {
		if (arr.includes(nieuw)) {
			arr = arr.filter(item => item !== nieuw)
		} else {
			arr.push(nieuw)
		}
	}
	return arr
}

export function snakeCase(str) {
	return str.toLowerCase().split(' ').join('_')
}
export function unSnakeCase(str) {
	return str
		.split('_')
		.map(function (item) {
			return item.charAt(0).toUpperCase() + item.substring(1)
		})
		.join(' ')
}

export function removeNewLine(string) {
	return string.replace(/\r?\n|\r/g, ' ')
}

export function getDateFromWeek(day, week, year) {
	let mmt = Moment()
	mmt.isoWeekday(day).isoWeekYear(year)
	mmt.isoWeek(week) // DO NOT CHAIN weekDay/Year/Week because then moment will crash.
	// If you pass undefined as an arg it will not return the moment instance.
	return mmt
}

export const getDateRange = function (startDate, endDate) {
	var dates = [startDate]

	var currDate = Moment(startDate).startOf('day')
	var lastDate = Moment(endDate).startOf('day')

	while (currDate.add(1, 'days').diff(lastDate) <= 0) {
		dates.push(currDate.clone().format('YYYY-MM-DD'))
	}

	return dates
}

export function getWholeWeek(week, year) {
	var datesArr = []
	_(7).times(n => {
		var date = getDateFromWeek(n + 1, week, year)
		datesArr.push({date: date, available: true, remarks: '', dateString: date.format('YYYY-MM-DD')})
	})
	return datesArr
}
export function truncateString(str, num, canBeLink) {
	if (!str || num > str.length) return str
	if (canBeLink) {
		const links = find(str)
		const linkEnd = links.reduce((tally, l) => {
			const idxEnd = str.indexOf(l.value) + l.value.length
			return idxEnd > tally ? idxEnd : tally
		}, 0)

		num = linkEnd > num ? linkEnd : num
	}
	if (num < str.length) {
		return str.slice(0, num) + '...'
	}

	return str
}

export function makeArrayOfLength(number) {
	return Array.apply(null, Array(number))
}
