import {useMemo} from 'react'
import useSelector from 'hooks/use_selector'
import {date} from 'initializers/date'
import {rangeFromStart, rangeToDateArray, makeRange} from 'helpers'
import Moment from 'moment'
import {baseSetRawDates, baseSetPeriod, getPeriod} from 'common/components/advanced_table/consumers'

// Curry functions to share logic with other AdvancedTable instances that use period type and period pickers.
const setRawDates = baseSetRawDates('SET_REG_PER_MEMBER_DATES')
const setPeriod = baseSetPeriod('SET_REG_PER_MEMBER_PERIOD')

export const usePerMemberDates = () => {
	const state = useSelector(state => state.timeRegistration.per_member)
	let {startDate, endDate, period} = state
	startDate = startDate ? date(startDate) : date().startOf('week')
	endDate = endDate ? date(endDate) : date().endOf('week')
	const dateRange = useMemo(() => makeRange(startDate, endDate), [startDate, endDate])
	period = period || getPeriod(startDate, endDate)

	const dateArray = rangeToDateArray(dateRange)
	const diff = endDate.startOf('day').diff(startDate.startOf('day'), 'days')

	const setDates = date => {
		const newRange = rangeFromStart({start: date, length: diff}) // wrong
		setRawDates(newRange.start, newRange.end)
	}

	const values = {setDates, setPeriod, setRawDates, period, diff, dateArray, dateRange, startDate: dateRange.start, endDate: dateRange.end}
	return values
}

export default usePerMemberDates
