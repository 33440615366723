import {AddButton, WeekTableColumn, useFilterFooterContext} from 'common/components'
import {Event} from 'models'
import {Cell} from './cell'
import {useRoleMessage, useHasRoleInAnyEnv} from 'hooks'

export const Column = ({date, envIds, ...rest}) => {
	const events = Event.where({date, environment_id: envIds}).sortBy(['from', 'till'])
	const isManager = useHasRoleInAnyEnv('manager', envIds)
	return (
		<WeekTableColumn hoverChildren={!isManager ? null : <CreateEventButton envIds={envIds} date={date} />} {...rest}>
			{events.map(e => (
				<Cell key={e.id} event={e} />
			))}
		</WeekTableColumn>
	)
}

const CreateEventButton = ({date, envIds}) => {
	const modalLink = `/forms/events?date=${date.format()}&env_ids=${envIds}`
	return (
		<AddButton
			t="planning.environment_table.new_event"
			modalLink={modalLink}
			width="full"
			iconLeft="plus"
			minHeight="100%"
			t="common.event"
			disabled={useRoleMessage('manager')}
		/>
	)
}
