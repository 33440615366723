import {useHistory, useLocation} from 'react-router-dom'

export const useModalBack = ({simpleBack} = {}) => {
	const hist = useHistory()
	const loc = useLocation()

	const goBack = () => {
		if (simpleBack && hist?.length != 2) {
			// hist length 2 can mean 1, we dont know why.. seems like a wicked bug. We encountered this when trying to close a user pref modal
			hist.goBack()
		} else {
			hist.replace(makeUrl(loc?.state?.background), {fromModal: true})
		}
	}

	return {goBack}
}

export default useModalBack

const makeUrl = (routeState = {}) => {
	const {pathname, search} = routeState
	if (!pathname) return '/'
	if (!search) return pathname
	return `${pathname}${search}`
}
