import {EitjeButton} from 'common/components'
import {ExportTable} from 'cores/exports2/index'

export const ExportButton = ({id, ...props}) => {
	const item = ExportTable.find(id)
	const onClick = item.requires_dates ? _.noop : () => item.export()

	return (
		<EitjeButton
			modalLink={!!item.requires_dates && `/exports2/tables/${id}/export`}
			t="common.export"
			blockNamespace
			iconLeft="paper-plane"
			{...props}
			onClick={onClick}
		/>
	)
}
