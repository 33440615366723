import {CHANNEL_TYPES} from 'cores/chat'

const DESC = -1
const DEBOUNCE_MS = 300

const debouncedSearch = _.debounce(async (props, event, client) => {
	const {setResults, setSearching} = props
	const {value} = event.target
	const members = {$in: [client.user.id]}

	const isEmpty = value.length === 0

	const filters = {
		members,
		...(!isEmpty && {
			$or: [
				{type: {$in: [CHANNEL_TYPES.TEAM, CHANNEL_TYPES.GROUP]}, name: {$autocomplete: value}},
				{type: {$eq: CHANNEL_TYPES.DIRECT}, 'member.user.name': {$autocomplete: value}},
			],
		}),
	}

	try {
		const channels = await client.queryChannels(filters, [{last_message_at: DESC}])
		const {results: messages} = await client.search(
			{type: {$in: [CHANNEL_TYPES.TEAM, CHANNEL_TYPES.GROUP, CHANNEL_TYPES.DIRECT]}, members},
			{text: {$autocomplete: value}},
			{sort: [{relevance: DESC}, {updated_at: DESC}]},
		)

		// sort channels on type: messaging first, then group & team
		const sortedChannels = channels.sort((a, _) => (a.type === CHANNEL_TYPES.DIRECT ? -1 : 1))
		setResults([...sortedChannels, ...messages])
		setSearching(false)
	} catch (e) {
		// silently catch stream api errors
		// catching here since stream can throw an error in case of an invalid query:
		// $autocomplete field is empty or contains invalid characters.
		if (!e.message.startsWith('StreamChat error')) {
			throw e
		}
	}
}, DEBOUNCE_MS)

export const channelSearch = async (props, event, client) => {
	const {setSearching, setQuery} = props
	const {value} = event.target

	setQuery(value)
	setSearching(true)
	debouncedSearch(props, event, client)
}
