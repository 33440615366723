import utils from '@eitje/web_utils'
import {makeRange} from 'helpers/date'
import {date} from 'initializers/date'
import {DateRangeActiveRecord} from 'models/active_record/base'

class Shift extends DateRangeActiveRecord {
	actualDateRange() {
		return makeRange(`${this.date} ${this._startDate()}`, `${this.date} ${this._endDate()}`)
	}

	exported() {
		return this.exported_count > 0
	}

	dateAtEnd() {
		return this.actualDateRange().end.format()
	}

	allDates() {
		return [this.date, this.dateAtEnd()]
	}

	_date() {
		return date(this.date)
	}

	isOnDate(date) {
		return this.date === date.format()
	}

	isApproved() {
		return this.isReg() && this.published
	}

	started() {
		return date() > this._dateRange().start
	}

	isReg() {
		return this.tableName == 'timeRegistrationShifts'
	}

	isPlan() {
		return this.tableName == 'planningShifts'
	}

	startTime() {
		return this._dateRange().start.format('HH:mm')
	}

	rawEndTime() {
		return this._dateRange().end.format('HH:mm')
	}

	durationString({isHours} = {}) {
		if (this.isCheckIn()) return `${this.startTime()}  -`
		return `${this.startTime()} - ${this.endTime({isHours})}`
	}

	totalMins() {
		if (!this.end_date) return 0
		return this._dateRange().diff('minutes') - this.breakTime()
	}

	totalMinsString() {
		return utils.minToTimeString(this.totalMins())
	}

	formatDate(format) {
		return this._date().format(format)
	}

	checkInTime() {
		const {checkIns = []} = this
		return checkIns.map(c => c.totalMins()).sum()
	}

	breakTime() {
		const _breakTime = this.pauze_duur || 0
		if (_.isString(_breakTime)) return utils.timeStringToMin(_breakTime)
		return _breakTime
	}

	startsInFuture() {
		return this._dateRange().start > date()
	}

	endTime({isHours} = {}) {
		if (this.indefinite?.() && !isHours) return '?'
		return this.rawEndTime()
	}

	breaktimeString() {
		return utils.minToTimeString(this.pauze_duur) || '00:00'
	}

	isImproductive() {
		return null // time reg shift implements this
	}

	relevantForWage() {
		return true
	}

	isCheckIn() {
		return this.tableName == 'checkIns'
	}

	regStatus() {
		if (this.isCheckIn()) return 'check_in'
		if (!this.isReg()) return 'open'
		if (this.isImproductive()) return this.typ
		return this.published ? 'worked' : 'in_progress'
	}

	regStatusses() {
		const isCheckin = this.isCheckIn()
		const isReg = this.isReg()
		const isImproductive = this.isImproductive()
		const {exported_count = 0} = this

		return _.compact([
			isCheckin && 'check_in',
			!isReg && 'open',
			isImproductive && this.typ,
			!isCheckin && isReg && !isImproductive && (this.published ? 'worked' : 'in_progress'),
			exported_count > 0 && 'exported',
		])
	}

	hasTradeRequest() {
		return this.startsInFuture?.() && this.trade_request_id
	}

	actualSkillSetId() {
		return this.skill_set_id || this.user?.skill_set_ids?.[0]
	}

	editableByWriter() {
		return !this.published || this.isPlan?.()
	}
}

export default Shift
