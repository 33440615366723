import React, {useState, createContext, useMemo, useContext} from 'react'
import {useBoolState} from '@eitje/react-hooks'
import useSelectCheckboxState from 'hooks/use_select_checkbox_state'

const EnvsContext = createContext({})
const {Provider} = EnvsContext

const EnvsProvider = ({children}) => {
	const [multiEdit, setMultiEdit] = useState()
	const [selectedShifts, _setSelectedShifts, setSelectedShiftsRaw] = useSelectCheckboxState({max: 200})

	const setSelectedShifts = props => {
		_setSelectedShifts(props)
		setMultiEdit(true)
	}

	const [conflictFilter, toggleConflictFilter, setConflictFilter] = useBoolState()

	const value = useMemo(
		() => ({
			conflictFilter,
			setConflictFilter,
			toggleConflictFilter,
			multiEdit,
			setMultiEdit,
			setSelectedShifts,
			selectedShifts,
			setSelectedShiftsRaw,
		}),
		[conflictFilter, multiEdit, selectedShifts],
	)
	return <Provider value={value}> {children} </Provider>
}

export const useEnvsContext = () => useContext(EnvsContext)

export default EnvsProvider
