import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import {ModalLink} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {useHistory, useLocation, withRouter, Link} from 'react-router-dom'

const Page = React.memo(() => {
	console.log('page render')
	return (
		<div>
			<Parent />
			<ModalLink to="/modal" t="open modal" />
			{/*      <ModalLink to="/modal" text="open modal" />
			 */}{' '}
		</div>
	)
}, routeEqual)

Page.whyDidYouRender = {
	logOnDifferentValues: true,
}

function routeEqual(prevProps, nextProps) {
	return true
	const noRoutePrev = _.omit(prevProps, 'match')
	const noRouteNext = _.omit(nextProps, 'match')
	const isEq = _.isEqual(noRouteNext, noRoutePrev)
	console.log(`non route props are equal: ${isEq}`)
	return isEq
}

const Parent = React.memo(() => {
	console.log('parent render')
	return (
		<Fragment>
			<Child1 />
		</Fragment>
	)
})

const Child1 = () => {
	console.log('child1 render')

	return <Child2 />
}

const Child2 = () => {
	console.log('child2 render')

	return <Child3 />
}

const Child3 = () => {
	const [linkVis, toggleLinkV] = useBoolState(false)
	console.log('child3 render')
	return (
		<div>
			<p onClick={toggleLinkV}> toggle link v </p> {linkVis && <FastLink to="/modal">hi</FastLink>}
		</div>
	)
}

let FastLink = ({to}) => {
	return (
		<div onClick={() => navigateModal(to)}>
			<p>{to} </p>
		</div>
	)
}

FastLink = withRouter(FastLink)

const expensiveFunction = count => {
	// artificial delay (expensive computation)
	for (let i = 0; i < 3000000000; i++) {}
	return count * 3
}

const count = 5

const ExpensiveChild = () => {
	console.log('expensive render')
	const myCount = expensiveFunction(count)
	return <p>im expensive: {myCount} </p>
}

export default Page
