import utils from '@eitje/web_utils'
import {Layout, Title} from 'common/components'
import {childrenWithClonedProps} from 'helpers/children'
import './styles.less'
import {PageHeaderTitle} from './title'

export const PageHeader = ({className, left, right, height, ...rest}) => {
	const classNames = utils.makeCns('page-header', className)

	const format = children => {
		return childrenWithClonedProps({
			children,
			props: {height, trigger: 'hover'},
		})
	}

	const center = format(<PageHeaderTitle {...rest} />)
	left = format(left)
	right = format(right)

	return (
		<Layout childrenGrow borderBottom className={classNames} padding={height === 'small' ? 4 : 16} minWidth={1200}>
			<Layout children={left} />
			<Layout horizontal="center" children={center} />
			<Layout horizontal="end" children={right} />
		</Layout>
	)
}
