import {exportT, useColumnItems, columnGroupName, useTableTax} from 'cores/exports2/index'
import {useEffect} from 'react'
import {t} from 'initializers/i18n'
import {useSimpleContext} from 'contexts/simple'
import utils from '@eitje/web_utils'
import {Table} from './table'
import {TourButton} from 'common/components'
import './styles/index.less'
import './styles/column_picker.less'
import {ListPicker} from 'common/components/list_picker'

export const Columns = () => {
	const {updateState, setUpdateState, item} = useSimpleContext()
	const {tableColumns} = useTableTax(item.table_name)

	const {columns = []} = updateState

	const columnItems = useColumnItems(tableColumns)
	const selectedColumns = columns.map(c => ({...(columnItems.find(c2 => c.column == c2.column) || {}), ...c}))

	const selectedColumnNames = columns.map(c => c.id || c.column)

	const setColumnItems = newColumns => setUpdateState({columns: newColumns})

	const setColumns = columnNames => {
		columnNames = utils.alwaysArray(columnNames).map(c => (_.isObject(c) ? c : {id: c}))
		const newColumns = columnNames.map(
			c => columns.find(c2 => c2.id == c.id || c2.column == c.id) || columnItems.find(c2 => c2.id == c.id) || c,
		)
		// bad design, we should normalize id/column so we don't have to either get it from `columns` or `columnItems`
		// distinction between DB values (columnItems) and local values (columns) should be a lot clearer

		setColumnItems(newColumns)
	}

	const setColumnInfo = obj => {
		const {id} = obj
		const newItems = selectedColumns.map(c => (c.id == id ? {...c, ...obj} : c))
		setColumnItems(newItems)
	}

	const removeColumn = id => {
		setColumnItems(selectedColumns.filter(c => c.id != id))
	}

	const props = {setColumnInfo, removeColumn, setColumns, columnItems, selectedColumns, selectedColumnNames}

	return (
		<div className="page-contents">
			<ColumnPicker {...props} />
			<div className="center-page">
				<div className="table-header">
					<h3>{exportT('table_edit.columns.title')} </h3>
					<SortSelect {...props} />
				</div>
				<Table {...props} />
			</div>
		</div>
	)
}

const fileTypes = ['excel', 'csv']

const sharedListPickerProps = {raw: true, single: true, dropdown: true}

const SortSelect = ({selectedColumns}) => {
	const {setUpdateState, updateState} = useSimpleContext()
	const {row_sort = {}, columns = [], file_type = 'excel'} = updateState

	const sortOrders = ['ascending', 'descending'].map(i => ({id: i, name: t(`common.${i}`)}))

	const setSortField = c => setUpdateState({row_sort: {...row_sort, column: c}})
	const setSortOrder = c => setUpdateState({row_sort: {...row_sort, direction: c}})
	const setFileType = c => setUpdateState({file_type: c})

	useEffect(() => {
		if (!columns.map(c => c.id || c.column).includes(row_sort.column)) {
			setSortField(columns[0]?.column)
		}
	}, [columns])

	return (
		<div className="sort-select">
			<p>{exportT('table_edit.columns.sort_column')} </p>

			<ListPicker
				data-intercom-target="sort-select"
				{...sharedListPickerProps}
				value={row_sort.column}
				items={selectedColumns}
				onChange={setSortField}
			/>
			<p>{exportT('table_edit.columns.sort_order')} </p>
			<ListPicker
				data-intercom-target="sort_order"
				{...sharedListPickerProps}
				value={row_sort.direction}
				items={sortOrders}
				onChange={setSortOrder}
			/>

			<p>{exportT('table_edit.columns.file_type')}</p>

			<ListPicker
				{...sharedListPickerProps}
				data-intercom-target="file-type-select"
				value={file_type}
				items={fileTypes}
				onChange={setFileType}
			/>
		</div>
	)
}

const ColumnPicker = props => {
	const {setColumns, selectedColumnNames, columnItems} = props
	const {item} = useSimpleContext()

	const {tableGroupNames} = useTableTax(item.table_name)

	const groups = ['top_picks', ...tableGroupNames].map(g => ({
		name: columnGroupName(g),
		id: g,
	}))

	return (
		<div className="left-page-column">
			<h3>{t('exports.subtitles.export_options')}</h3>
			<TourButton height="small" tourId={447849} />
			<ListPicker
				raw
				value={selectedColumnNames}
				items={columnItems}
				onChange={setColumns}
				groupItems={groups}
				groupField="groups"
				collapseGroups
				min={1}
				maxHeight={null}
				showSearch
				showPlaceholder
			/>
		</div>
	)
}
