import {DeleteButton} from 'common/components'
import ImagePicker from 'lib/form/fields/image_picker'
import VideoPicker from 'lib/form/fields/video_picker'
import {AssetButton} from 'pages/v3/news/components'
import React, {useRef} from 'react'

const typeKey = {
	image: {text: 'attachPhoto', iconSrc: '/images/web/icons/news/photoIcon.png'},
	file: {text: 'uploadAttachment', iconSrc: '/images/web/icons/news/fileIcon.png'},
	video: {text: 'addVideo', iconSrc: '/images/web/icons/news/videoIcon.png'},
}

export const useAssetPicker = (type, field, ignoreProps) => {
	const {text, iconSrc, className} = typeKey[type]
	const pickRef = useRef(null)
	const triggerButton = () => <AssetButton toolText={text} onClick={() => pickRef.current()} iconSrc={iconSrc} />
	const picker =
		type !== 'video'
			? props => (
					<ImagePicker
						form={false}
						{...props}
						ContainerComponent={props => <div {...props} className="post-asset-container" />}
						DeleteComponent={DeleteButton}
						ignoreFieldProps={data => ignoreProps && !data[field]}
						image={type === 'image'}
						pickImg={pickRef}
						containerStyle={props.formData[field] && props.containerStyle}
						className={className}
						inputHidden
					/>
			  )
			: props => (
					<VideoPicker
						{...props}
						ContainerComponent={props => <div {...props} className="post-asset-container" />}
						ignoreFieldProps={data => ignoreProps && !data[field]}
						labelVisible={false}
						pickVid={pickRef}
						inputHidden
					/>
			  )

	return {triggerButton, picker}
}

export default useAssetPicker
