import utils from '@eitje/web_utils'
import {useExportTaxonomy} from 'cores/exports2'
import {columnTitle, columnTooltip, columnExample} from 'cores/exports2/helpers'

export const useColumnItems = columns => {
	const {columns: allColumns} = useExportTaxonomy()
	const columnNames = Object.values(columns).flat()
	const columnGroupNames = Object.keys(columns)

	const finalColumns = columnNames
		.map(c => {
			if (!_.isObject(c)) c = {id: c}
			const {id} = c
			const extraColumnInfo = allColumns[id]
			if (!extraColumnInfo) return null

			let {formats, favorite, tooltip} = extraColumnInfo
			formats = utils.alwaysArray(formats)

			let format = formats.find(f => f == c.format)
			if (!format) format = extraColumnInfo.default_format

			const relevantGroups = columnGroupNames.filter(n => columns[n].includes(c.id))

			const groups = [favorite && 'top_picks', ...relevantGroups].filter(Boolean)

			const examples = formats.map(f => ({
				id: f,
				name: columnExample(id, f),
			}))

			return {
				...c,
				...extraColumnInfo,
				column: c.column || c.id,
				format,
				tooltip: tooltip && columnTooltip(id),
				examples,
				example: columnExample(id, format),
				name: columnTitle(id),
				groups,
			}
		})
		.filter(c => c && c.groups.length > 0)

	return finalColumns
}

const groupNameMatch = (id, groupName) => {
	const splittedGroup = groupName.split('_')
	const splittedId = id.split('_')
	return splittedGroup.every(g => splittedId.includes(g) && splittedGroup.indexOf(g) == splittedId.indexOf(g))
}

export default useColumnItems
