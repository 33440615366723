import {NewForm, useForm} from '@eitje/form'
import {EitjeButton, ListPicker, NextButton, Layout} from 'common/components'
import User from 'models/user'
import {Input, DatePicker, Switch, Checkbox, TimePicker} from '@eitje/form-fields-web'
import {FormSubmitButton} from 'components/form'

const Form = () => {
	const users = User.all()
	return (
		<Layout horizontal="center" direction="vertical" padding="24 - - -" gap={20}>
			<EitjeButton t="go to modal form" modalLink="/dev/modal_form" colorSet="solid" />

			<NewForm variant="box" onSubmit={() => window.alert('broertjeeee')} name="test">
				<Input required={data => !data.textarea} rightElement="charCounter" maxLength={100} tooltip field="input" />
				<Input tooltip="i am a custom string" textarea required rightElement={<p> hihi </p>} field="textarea" />
				<Input field="required_if_no_date" label="I am required without date" required={data => !data.date} />
				<Input field="hihi" label="I am disabled" disabled />
				<Input field="haha" label="I am read only" readOnly />
				<Input field="password" label="password" secure />
				<DatePicker field="date" extraLabel="i am extralabel" />
				<ListPicker dropdown single form items={users} labelField="full_name" field="listpicker" />
				<TimePicker field="time" />
				<Checkbox field="checkbox" />
				<Switch field="switch" />
				<FormSubmitButton />
			</NewForm>
		</Layout>
	)
}

export default Form
