import {Button} from 'components/ui'
import utils from '@eitje/web_utils'
import {usePicker} from '@eitje/form'
import {buildField, RawCheckbox} from '@eitje/form-fields-web'
import './styles/button_select.less'
import {Fragment} from 'react'
import {useLocation} from 'react-router-dom'
import {makeCnVariants} from 'helpers'

const _ButtonSelect = props => {
	let {value = [], minLength, singleIsArray, small, onChange = _.noop, ...rest} = props
	const single = _.has(props, 'single') ? props.single : true
	const type = small ? 'small' : 'large'
	const {pickerItems} = usePicker(props)

	const _onChange = val => {
		if (single && singleIsArray) return onChange(utils.alwaysArray(val))
		if (single) return onChange(val)
		const newVal = utils.toggle(value, val)
		if (minLength && newVal.length < minLength) return
		onChange(utils.toggle(value, val))
	}

	const classNames = makeCnVariants('eitje-button-select', !props.label && 'no-label', type)
	return (
		<div className={classNames}>
			{pickerItems.map(i => (
				<ButtonSelectButton onChange={_onChange} active={isActive(value, i)} item={i} {...rest} />
			))}
		</div>
	)
}

const isActive = (value, item) => {
	if (_.isArray(value)) return value.includes(item.value)
	return value == item.value
}

const ButtonSelectButton = props => {
	const {item, onChange, readOnly, active, checkbox, ButtonWrapper = Fragment, withQueryParams} = props
	const {search} = useLocation()
	const to = `${item.path}${withQueryParams ? search : ''}`
	const {disabled} = item
	const className = utils.makeCns(
		makeCnVariants('eitje-button-select-button', props.disabled && 'disabled'),
		active && 'active',
		readOnly && 'read-only',
		disabled && 'disabled',
	)
	const act = props.disabled || readOnly || disabled ? _.noop : () => onChange(item.value)
	return (
		<ButtonWrapper to={to}>
			<Button onClick={act} className={className}>
				{checkbox && <RawCheckbox value={active} />}
				{item.label}
			</Button>
		</ButtonWrapper>
	)
}

const getButtonSelectClassName = props => {
	const type = props.small ? 'small' : 'large'
	return utils.makeCns('eitje-button-select-container', `eitje-button-select-container-${type}`)
}

export const ButtonSelect = buildField(_ButtonSelect, {className: getButtonSelectClassName, withIcon: false})

export default ButtonSelect
