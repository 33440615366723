import {t} from 'initializers/i18n'
import Tabs from '../tabs'

export const IsWeekSelector = props => {
	const opts = [
		{name: t('week'), id: true},
		{name: t('month'), id: false},
	]

	return <Tabs noSort field="is_week" items={opts} {...props} />
}
