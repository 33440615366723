import {t} from 'initializers/i18n'
import PlanningShiftForm from './form/index'
import useQueryParams from 'hooks/use_query_params'
import {useDetermineNewShiftEnv} from 'shared'

const defaultInitial = {from: '09:00', till: '17:00', pauze_duur: '00:00', allow_pickup: true, pickup_notification: true}

const NewPlanningShift = () => {
	const queryParams = useQueryParams()
	const envId = useDetermineNewShiftEnv({role: 'manager'})

	let initial = {...defaultInitial, ...queryParams, env_id: envId}
	if (initial.date) {
		initial.dates = [initial.date]
	}
	return <PlanningShiftForm title={t('planning.shift_modal.new_shift')} initialValues={initial} {...queryParams} />
}

export default NewPlanningShift
