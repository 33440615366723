import {ExportSubscription, PeriodPicker} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {EitjeModal} from 'components/common'
import {useActiveUsers, useParams} from 'hooks'
import {FormMultiEmailInput} from 'lib/form/fields/multi_text_input'
import {ContextForm} from '@eitje/form'
import utils from '@eitje/web_utils'
import {ExportPeriodPicker} from './export_period_picker'
import {history} from 'index'
import {FormRow} from 'components/ui'
import {TourButton, EitjeButton, ListPicker, ConfirmButton} from 'common/components'
import date from 'initializers/date'
import {getTableName} from 'helpers'

const PauseButton = ({item}) => {
	const {paused} = item
	const text = paused ? 'unpause' : 'pause'
	const icon = paused ? 'play' : 'pause'

	return <EitjeButton iconLeft={icon} onClick={() => item.toggle('paused')} children={t(`common.${text}`)} />
}

const SendNowButton = ({item}) => {
	return <ConfirmButton iconLeft="paper-plane" onClick={() => item.exportNow()} t="common.send_now" />
}

const Inner = ({item}) => {
	const users = useActiveUsers()
	const isEdit = item?.id
	const name = `${isEdit ? 'edit' : 'new'}_subscription`
	const buttons = isEdit ? [<PauseButton item={item} />, <SendNowButton item={item} />] : <TourButton tourId={448317} />

	return (
		<EitjeModal
			afterDel={() => history.goBack()}
			onDel={isEdit && item.destroy}
			buttons={buttons}
			form
			name={name}
			docsLink="subs_explained"
			className="exports-add-subscription"
			hideCancel={isEdit}
		>
			<PeriodPicker />
			<ExportPeriodPicker />
			<div className="eitje-form-2 eitje-form-2-use-borders">
				<FormRow>
					<ListPicker
						dropdown
						showSearch
						field="user_ids"
						items={users}
						title={getTableName('users')}
						labelField="full_name"
						required={formData => !utils.exists(formData?.emails)}
					/>
				</FormRow>

				<FormRow>
					<FormMultiEmailInput required={formData => !utils.exists(formData.user_ids)} className="select-emails-row" field="emails" />
				</FormRow>
			</div>
		</EitjeModal>
	)
}

const initialValues = {
	tense: 'previous',
	period: 'week',
}

export const AddSubscription = props => {
	const {id} = useParams()
	const {item = {}} = props

	const initial = {
		...initialValues,
		...item,
		...item.mail_schedule,
		...item.export_period,
		day_of_year: date().dayOfYear(item?.mail_schedule?.day_of_year),
		cron_period: item?.mail_schedule?.period,
		period: item?.export_period?.type || initialValues?.period,
	}

	return (
		<ContextForm
			onSubmit={data => submit({...data, table_id: item.id ? undefined : id}, props.item)}
			initialValues={initial}
			afterSubmit={history.goBack}
			transNamespace="export_subscriptions"
		>
			<Inner {...props} />
		</ContextForm>
	)
}

const submit = (data, item) => {
	data = transformData(data)
	if (item?.id) return item.update(data)
	return ExportSubscription.create(data)
}

const transformData = data => {
	let mail_schedule = {
		..._.pick(data, 'time', 'day_of_week', 'day_of_month', 'day_of_quarter', 'day_of_year', 'day_of_period'),
		period: data.cron_period,
	}
	if (data.day_of_year) mail_schedule['day_of_year'] = date(data.day_of_year).dayOfYear()
	const export_period = {type: data.period, tense: data.tense}
	return {...data, mail_schedule, export_period}
}
