import {EitjeModal} from 'components/common'
import {DropdownPicker} from '@eitje/form-fields-web'
import {ContextForm, t} from '@eitje/form'
import {updateOrgSettings} from 'actions/settings'

import FormRow from 'components/form_row'
import {useOrgSettingGroup} from 'hooks'

const timeIntervals = [1, 5, 10, 15, 30]

export const TimeSettings = () => {
	const generalSettings = useOrgSettingGroup('general')
	const timeIntervalDropdownItems = timeIntervals.map(item => {
		const itemLabel = item === 1 ? 'dropdown_label_singular' : 'dropdown_label'
		return {
			id: item,
			name: t(`form.time_settings.fields.shift_minute_interval.${itemLabel}`, {amount: item}),
		}
	})

	return (
		<EitjeModal name="time_settings" className="time-settings-modal">
			<div>
				<ContextForm
					onSubmit={data => {
						const interval = data?.shift_minute_interval
						return updateOrgSettings({general: {shift_minute_interval: timeIntervals.includes(interval) ? interval : 5}})
					}}
					transNamespace="time_settings"
					initialValues={generalSettings}
				>
					<FormRow>
						<DropdownPicker hideClearIcon field="shift_minute_interval" items={timeIntervalDropdownItems} submitStrategy="change" noSort />
					</FormRow>
				</ContextForm>
			</div>
		</EitjeModal>
	)
}
