import {useAdvancedTable, AdvancedTable} from 'common/components/advanced_table'
import {columns} from './columns'
import {TABLE_INFO} from './constants'
import {useFilterFooterContext} from 'common/components'
import {RowWrapper} from './row_dropdown'

export * from './constants'
export * from './columns'

export const UsersInServiceAdvancedTable = ({}) => {
	const {filteredItems: users, filteredOutAmount, clearFilters} = useFilterFooterContext()

	const tableData = useAdvancedTable({
		data: users,
		initialPinning: ['full_name'],
		columns,
		TABLE_INFO,
		filteredOutAmount,
		clearFilters,
	})

	return <AdvancedTable data={tableData} RowWrapper={RowWrapper} />
}
