import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'

class PlanningTemplate extends ActiveRecord {
	static tableName = 'roosterTemplates'
	static defaultJoins = ['environments', 'teams']
	static save(data, rest) {
		return this.arbitrary('save', data, rest)
	}

	withEnv() {
		const envStatus = this.environments.length == 1 ? this.environments[0].naam : t('common.multiple_venues')
		return {...this, envStatus, hasMultiEnv: this.environments.length > 1, nameWithPeriod: `(${this.periodString()}) ${this.naam}  `}
	}

	isDay() {
		return this.kind == 'day'
	}

	periodString() {
		if (this.kind == 'week') return t('common.week')
		return t('common.day')
	}
}

export default PlanningTemplate
