import {EitjeDropdown, Text, useUserPanelDropdownOptions, viewOptionsDropdownItem} from 'common/components'
import {useUserContext} from 'contexts/user'
import {usePerWeekContext, useEditableShifts, getTimeShiftUrl} from 'cores/time_registration'
import {useShared, useRoleMessage} from 'hooks'
import {t} from 'initializers/i18n'
import {userViewOptions, useShiftViewOptions} from 'cores/time_registration/pages/per_week/page_header/view_options'
import {usePageContext} from 'common/components/page_layout'

export const PanelDropdown = ({...rest}) => {
	const {user, shifts} = useUserContext()
	const {dateRange} = usePerWeekContext()
	const {orgEnvs} = useShared()
	const multiEditableShifts = useEditableShifts(shifts)
	const {setMultiEdit, setSelectedShifts} = usePageContext()
	const selectUserShifts = () => {
		setSelectedShifts(multiEditableShifts.ids())
		setMultiEdit(true)
	}
	const createShiftUrl = getTimeShiftUrl({user})
	const userOptions = useUserPanelDropdownOptions({user, dateRange, createShiftUrl})
	const noWriteRole = useRoleMessage({roles: 'uren_schrijven'})
	const namespace = 'time_registration.per_week.disabled'
	const disableSelectShifts = multiEditableShifts.length === 0 && t('time_registration.week_table_days_header.disabled.no_shifts_to_select')

	const elements = [
		...userOptions,
		{
			icon: 'pencil',
			element: <Text t="planning.week_table_days_header.multi_edit_day_shifts" />,
			onClick: selectUserShifts,
			disabled: noWriteRole || disableSelectShifts,
		},
		userViewOptions(),
		useShiftViewOptions(),
	]

	return <EitjeDropdown elements={elements} {...rest} strategy="fixed" trigger="click" />
}
