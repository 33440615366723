import {t} from 'initializers/i18n'
import {NavItemLayout} from './nav_item'
import {OnboardingStepsPopout} from 'cores/onboarding'

export const Onboarding = () => {
	return (
		<OnboardingStepsPopout>
			<NavItemLayout title={t('header.onboarding')} link="/onboarding/welcome" />
		</OnboardingStepsPopout>
	)
}
