import React, {createContext, useContext, useMemo} from 'react'
import {date} from 'initializers/date'
import {API} from '@eitje/easy_api'
import {useAsyncEffect} from '@eitje/react-hooks'
import utils from '@eitje/web_utils'
import {rangeToDateArray} from 'helpers/date'
import {store} from 'index'
import {usePlanningDates} from 'cores/planning/index'
import {PlanningShift, RevenueDay, LeaveRequest, TimeRegistrationShift} from 'models/index'
import usePrevious from 'hooks/use_previous'

const PlanningContext = createContext({})
const {Provider} = PlanningContext

const PlanningProvider = ({children}) => {
	const {dateRange, setDates, startDate, endDate} = usePlanningDates()
	const dateArray = useMemo(() => rangeToDateArray(dateRange), [startDate.format()])
	const diff = startDate.diff(date(), 'days')
	const weatherVisible = diff < 14
	const previousStartDate = usePrevious(startDate)?.format()
	const startDateString = startDate.format()

	useAsyncEffect(async () => {
		const dateHasChanged = startDateString != previousStartDate && previousStartDate

		const {week, year} = utils.getYearWeekFromDate(startDate)
		const weekYearFilters = {weeknumber: [week], year: [year]}
		const filters = {start_date: startDate, end_date: endDate}

		const opts = {refresh: !!dateHasChanged}
		const params = {filters, ...opts}
		store.dispatch({type: 'START_LOADING'})
		const multiIndexPayload = [
			{name: PlanningShift.tableName, ...params},
			{name: TimeRegistrationShift.tableName, ...params},
			{name: 'days', ...params},
			{name: 'trade_requests'},
			{name: 'events', ...params},
			{name: RevenueDay.tableName, ...params},
			{name: LeaveRequest.tableName, filters},
			weatherVisible && {name: 'weather_days', ...params},
			{name: 'user_weeks', filters: weekYearFilters, ...opts},
			{name: 'availability_shifts', ...params},
		].filter(Boolean)

		await API.multiIndex(multiIndexPayload)
		store.dispatch({type: 'STOP_LOADING'})
	}, [startDateString])

	return (
		<Provider
			value={{
				dateRange,
				dateArray,
				setDates,
				startDate,
				endDate,
				weatherVisible,
			}}
		>
			{children}
		</Provider>
	)
}

export const usePlanningContext = () => useContext(PlanningContext)

export default PlanningProvider
