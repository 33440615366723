import {exportT, useExportFilters, FilterBaseSentence, FilterSentence, useTableTax} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {TourButton} from 'common/components'
import {useSimpleContext} from 'contexts/simple'
import {RecordFilters} from './record_filters'
import utils from '@eitje/web_utils'
import {ExtraFilters} from './extra_filter_modal'
import {useRouteMatch, Route} from 'react-router-dom'
import {ExtraFiltersRow} from './extra_filter_row'
import './styles/index.less'
import {DocsButton} from 'common/components'

export const Filters = () => {
	const {item, formItem, updateState, setUpdateState} = useSimpleContext()
	const {rowFilter, columnFilter, tableName} = formItem.filterInfo()
	const {row, column} = useExportFilters(formItem)
	const setFilter = (kind, filter) => {
		const filts = updateState[kind]
		const newFilts = utils.findAndReplace({oldItems: filts, newItems: [filter], oldIden: 'filter', newIden: 'filter'})
		setUpdateState({[kind]: newFilts})
	}

	const setColumnFilter = filter => setFilter('column_filters', filter)
	const setRowFilter = filter => setFilter('row_filters', filter)

	const match = useRouteMatch()

	const {filters, rowFirmOptions} = useTableTax(item.table_name)
	const rowFirmOpts = rowFirmOptions.map(r => r.replace('_ids', ''))
	const allowedExtraFilters = filters.row.filter(f => !f.required)

	return (
		<div className="table-edit-filters">
			<Header />
			<FilterBlock
				docsId={7973786}
				tour
				subtitle={<FilterBaseSentence item={row} />}
				title={exportT('table_edit.filters.row.title', {name: tableName})}
			>
				<RecordFilters includeGroups={rowFirmOpts} value={rowFilter.value} active={rowFilter.singularType} setFirmFilter={setRowFilter} />
			</FilterBlock>

			{item.hasColumnFilter() && (
				<FilterBlock
					subtitle={<FilterBaseSentence item={column} />}
					docsId={7973786}
					title={exportT('table_edit.filters.column.title', {name: tableName, columnNames: column.names})}
				>
					<RecordFilters
						excludeGroups={['contract_environment', 'user']}
						value={columnFilter.value}
						active={columnFilter.singularType}
						setFirmFilter={setColumnFilter}
					/>
				</FilterBlock>
			)}
			{allowedExtraFilters.length > 0 && (
				<FilterBlock docsId={7973786} title={exportT('table_edit.filters.extra.title', {name: tableName})}>
					<ExtraFiltersRow />
				</FilterBlock>
			)}

			{allowedExtraFilters.length > 0 && (
				<Route path={`${match.path}/extra`}>
					<ExtraFilters />
				</Route>
			)}
		</div>
	)
}

const Header = () => {
	const {formItem} = useSimpleContext()

	return (
		<div className="table-edit-filters-header">
			<FilterSentence className="horizontal" item={formItem} />
		</div>
	)
}

const FilterBlock = ({children, tour, title, subtitle, docsId}) => {
	return (
		<div className="filter-block">
			<div className="filter-block-title">
				<div className="filter-block-title-left">
					<h3>{title} </h3>
					{(subtitle || tour) && (
						<div className="subtitle-and-tour">
							{subtitle}
							{tour && <TourButton height="small" tourId={447855} />}
						</div>
					)}
				</div>

				<DocsButton to={docsId} />
			</div>
			{children}
		</div>
	)
}
