import {LegacyInput as Input} from '@eitje/form-fields-web'
import {NimblePicker} from 'emoji-mart'
import data from 'emoji-mart/data/apple.json'
import useHotkey from 'hooks/use_hotkey'
import useOutsideClick from 'hooks/use_outside_click'
import {forwardRef, useRef, useState} from 'react'
import ReactDOM from 'react-dom'

function addEmoji(textarea, value, emoji) {
	const cursorPosition = textarea.selectionEnd
	const start = value.substring(0, textarea.selectionStart)
	const end = value.substring(textarea.selectionStart)
	const text = start + emoji + end
	return text
}

const EmoInput = forwardRef(
	({divClassName = '', extraInputStyle = {}, extraEmoStyle = {}, rightChildren = [], noEmoStyle, ...props}, ref) => {
		const [iconVisible, setIconVisible] = useState(false)
		const [emoVisible, setEmoVisible] = useState(false)
		const {value = '', field, onChange} = props
		const container = useRef(null)
		const textarea = ref || useRef()
		const _textarea = textarea.current

		const selectEmoji = ({native}) => {
			const input = _textarea.resizableTextArea ? ReactDOM.findDOMNode(_textarea.resizableTextArea) : ReactDOM.findDOMNode(_textarea)
			const cursorPosition = input.selectionEnd
			const newText = addEmoji(input, value, native)
			onChange(newText)
			_textarea.focus()
			setTimeout(() => (input.selectionEnd = cursorPosition + native.length), 10) // wait for 10ms to allow react to re-render first
		}

		const onMouseOver = () => setIconVisible(true)
		const onMouseLeave = () => setIconVisible(false)
		useOutsideClick(container, () => setEmoVisible(false))

		const onKeyUp = useHotkey({esc: () => setEmoVisible(false), ctrl_e: () => setEmoVisible(!emoVisible)})

		const _onKeyUp = e => {
			onKeyUp(e)
			if (props.onKeyUp) props.onKeyUp(e)
		}

		const emoIcon = (
			<div
				onClick={() => {
					setEmoVisible(!emoVisible)
					_textarea.focus()
				}}
				className={!noEmoStyle && 'emoInputHolder fRow aCen jCen'}
				style={extraEmoStyle}
			>
				<img src={'/images/web/icons/news/emoticon.png'} className="emoji-icon" />
			</div>
		)

		const _rightChildren = [iconVisible && emoIcon, ...rightChildren]

		const cn = `eitje-emoji-input-container ${divClassName} `

		return (
			<div ref={container} className={cn} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
				<Input {...props} onKeyUp={_onKeyUp} rightChildren={_rightChildren} innerRef={textarea} />

				{emoVisible && <NimblePicker data={data} onSelect={selectEmoji} set="apple" title="Eitje" emoji="cooking" />}
			</div>
		)
	},
)

export default EmoInput
