import React, {Component} from 'react'
import {connect} from 'react-redux'
import {DocsButton, Placeholder} from 'common/components'

class NotFound extends Component {
	render() {
		return (
			<div id="error-page-404" style={{marginTop: '8%'}}>
				<Placeholder name="not_found" animation="egg-with-ribbon" supportButton />
			</div>
		)
	}
}

export default connect(state => ({
	user: state.auth.user,
}))(NotFound)
