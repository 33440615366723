import {User} from 'models'

export const UserCell = ({getValue, translatedPlaceholder}) => {
	const id = getValue()
	const user = User.find(id)

	// Use a translatedPlaceholder when defined in the column's taxonomy. E.g. the 'processor_id' UserCell
	// for the leave AdvancedTable, where we want to show 'not processed' when there is no value in the cell.
	return user.full_name || translatedPlaceholder || null
}
