import ActiveRecord, {DateRangeActiveRecord} from 'models/active_record/base'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'

class LeaveRequest extends DateRangeActiveRecord {
	static defaultJoins = ['users']
	static startDateField = 'start_datetime'
	static endDateField = 'end_datetime'
	static tableName = 'leaveRequests'

	status() {
		const {accepted, denied} = this
		if (accepted) return 'accepted'
		if (denied) return 'denied'
		return 'pending'
	}

	startDate() {
		return date(this.start_datetime)
	}

	endDate() {
		return date(this.end_datetime)
	}

	startTime() {
		return this.startDate().format('HH:mm')
	}

	endTime() {
		return this.endDate().format('HH:mm')
	}

	isOnDate(date) {
		return this.dateRange.contains(date)
	}

	days() {
		return this.dateRange.days() + 1 // we want this to be 't/m' and not tot, but are puzzling how to name that aptly
	}

	isProcessed() {
		const {accepted, denied} = this
		return accepted || denied
	}

	deny(data) {
		return this.update({process_reason: data.process_reason, denied: true})
	}

	approve({total_mins, ...rest}) {
		return this.resourceReq('approve', {mins: total_mins, ...rest})
	}

	reset() {
		return this.resourceReq(`reset`)
	}

	kind() {
		const dayMinutes = 1439
		const minutes = this.dateRange.minutes()
		if (minutes == dayMinutes) return 'single_day'
		if (minutes > dayMinutes) return 'multiple_days'
		return 'few_hours'
	}
}

export default LeaveRequest
