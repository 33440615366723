import React, {Fragment} from 'react'
import {t as baseT} from 'initializers/i18n'
import {DatePicker, TimePicker, Switch} from '@eitje/form-fields-web'
import {FormRow} from 'components/ui'
import useFormData from 'hooks/use_form_data'
import {NewFormInputNumber} from 'components/form/input_number'
import {EitjeButton, ListPicker} from 'common/components'

//   defaults: { vanaf: DateTime.now, new_day_start: "06:00", omrekenfactor: 1, actief: false, eigen_uren: true, hvl_tijd: "altijd",
// koppeling: "geen" }

const i18nOpts = {postProcess: 'markdown-jsx'}

const Fields = () => {
	const {eigen_uren} = useFormData()
	const timeOptions = [
		{id: 24, name: baseT('x_hours', {count: 24})},
		{id: 48, name: baseT(`x_hours`, {count: 48})},
		{id: 'altijd', name: baseT('common.always')},
	]

	const suffix = baseT('common.days_in_past')

	return (
		<>
			<FormRow>
				<Switch field="eigen_uren" />
				<Switch field="create_own_hours" />
			</FormRow>

			<FormRow>
				<TimePicker field="new_day_start" />
				<DatePicker futureDisabled field="vanaf" />

				{false && (
					<Fragment>
						<p>{baseT('common.meals')} </p>
						<EitjeButton modalLink="meals" t="common.manage" />
					</Fragment>
				)}
				{/*  Above is comemented out because meals are really sucky on the back-end currently, thus makes it hard to create a proper front-end. We'll ride the old meals style untill we've rewritten the back */}
			</FormRow>

			{eigen_uren && (
				<FormRow>
					<ListPicker single dropdown field="hvl_tijd" items={timeOptions} />
				</FormRow>
			)}

			<FormRow>
				<NewFormInputNumber placeholder={null} extraLabel i18nOpts={i18nOpts} max={1000} field="write_role_days_past" suffix={suffix} />
			</FormRow>
			<FormRow>
				<NewFormInputNumber placeholder={null} extraLabel i18nOpts={i18nOpts} max={1000} field="accord_role_days_past" suffix={suffix} />
			</FormRow>
		</>
	)
}

export default Fields
