import {useShared} from 'hooks'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {Text, SubmitPopout, useFilterFooterContext} from 'common/components'
import {NAMESPACE} from 'cores/time_registration/pages/per_week/week_table_days_header'
import {approveShifts, useFilteredShifts, usePerWeekContext} from 'cores/time_registration'
import {useHasRole, useHasRoleInAnyEnv} from 'hooks'
import Association from 'models/active_record/association'

export function useApprovePerEnv(date) {
	const {orgEnvs, multiEnvOrg} = useShared()
	const {dateRange} = usePerWeekContext()
	const {planShifts, regShifts} = useFilteredShifts({dateRange})
	const writableRegShifts = regShifts.filter(r => !r.published)
	const writableShifts = new Association([...planShifts, ...writableRegShifts])

	let items = orgEnvs.map(env => ApproveEnv({env, date, writableShifts, singleEnv: !multiEnvOrg}))

	if (!multiEnvOrg) return [{...items[0], children: null}] // passing children automatically makes it a nested DD
	return approveMultiEnv({date, items, writableShifts})
}

function approveMultiEnv({date, items, writableShifts}) {
	const shifts = writableShifts.where({date})
	const disabled = useApproveEnvIsDisabled({shifts})
	const hasAnyApproveRole = useHasRoleInAnyEnv('uren_accorderen')
	const isWeek = !! date.start // start exists for ranges, not for dates
	const periodType = isWeek ? 'week' : 'day'

	const parentItemLabel = hasAnyApproveRole ? t(`common.approve_${periodType}`) : t(`common.write_${periodType}`)

	const approveAllKey = hasAnyApproveRole ? 'approve_all_envs' : 'write_all_envs'
	let approveAllLabel = t(`${NAMESPACE}.${approveAllKey}`)
	approveAllLabel = makeLabel({baseLabel: approveAllLabel, shifts})

	const approveAll = {
		// PR comment: zou rechts moeten staan, want nu is UI clunky met DD items eronder,
		// maar prop moet daarvoor eerst in comp lib worden toegevoegd
		element: (
			<SubmitPopout placement="right" onSubmit={() => approveShifts(shifts)}>
				<Text children={approveAllLabel} />
			</SubmitPopout>
		),
		icon: 'paper-plane',
		disabled: disabled,
	}

	return [
		{
			title: parentItemLabel,
			icon: 'checked',
			children: [...items, approveAll],
		},
	]
}

const ApproveEnv = ({env, date, singleEnv, writableShifts}) => {
	const shifts = writableShifts.where({date}).filter(s => s.team.environment_id === env.id)
	const hasApproveRole = useHasRole('uren_accorderen', env?.id)
	const hasWriteRole = useHasRole('uren_schrijven', env?.id)
	const disabled = useApproveEnvIsDisabled({env, shifts, hasApproveRole, hasWriteRole})

	let baseLabel
	if (singleEnv) {
		const transKey = hasWriteRole && !hasApproveRole ? 'write_all_hours' : 'approve_all_hours'
		baseLabel = t(`${NAMESPACE}.${transKey}`)
	} else {
		baseLabel = env.naam
	}

	const label = makeLabel({baseLabel, shifts})

	return {
		icon: 'checked',
		element: (
			<SubmitPopout onSubmit={() => approveShifts(shifts)} placement="right-end" trigger="click">
				<Text children={label} />
			</SubmitPopout>
		),
		disabled,
	}
}

function useApproveEnvIsDisabled({env, shifts, hasApproveRole, hasWriteRole}) {
	const namespace = `${NAMESPACE}.disabled`
	const hasAnyApproveRole = useHasRoleInAnyEnv('uren_accorderen')
	const noShifts = _.isEmpty(shifts)
	const {envs} = useFilterFooterContext()
	const envInFilters = envs.includes(env)

	if (env && !hasWriteRole) return t(`${namespace}.no_write_role_in_env`)
	if (!env && !hasAnyApproveRole) return t(`${namespace}.no_approve_role_in_org`)
	if (env && !envInFilters) return t(`${namespace}.environment_not_in_current_filters`)
	if (noShifts && hasWriteRole && !hasApproveRole) return t(`${namespace}.no_shifts_to_write`)
	if (noShifts) return t(`${namespace}.no_shifts_to_approve`)
}

function makeLabel({baseLabel, shifts}) {
	const labelCount = _.isEmpty(shifts) ? '' : `(${shifts.length})`
	return `${baseLabel} ${labelCount}`
}
