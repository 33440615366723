import {useUserContext} from '../index'
import useAvShift from 'cores/availability/hooks/use_av_shift'
import AvCell from 'cores/availability/components/availability_cell'
import {useAvContext} from 'contexts/availability'
import {useEnvContext} from '../../contexts/env'
import '../../../styles/availability.less'
import './index.less'

const UserDay = ({date}) => {
	const {env} = useEnvContext()
	const {cellVisible} = useAvContext()
	const {userId} = useUserContext()
	const query = {user_id: userId, date: date.format('YYYY-MM-DD')}
	const avShift = useAvShift(query, {envId: env.id})
	const visible = cellVisible(avShift)

	return (
		<div className="default-availability-cell-wrapper">
			<AvCell envId={env.id} item={avShift} visible={visible} />
		</div>
	)
}

export default UserDay
