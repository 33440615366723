import React, {useEffect, useState, Fragment, useMemo, createContext, useContext, useCallback, useRef} from 'react'

const TeamContext = createContext()
const {Provider} = TeamContext

const TeamProvider = ({children, team}) => {
	const val = useMemo(() => ({team}), [team])
	return <Provider value={val}> {children} </Provider>
}

export const useTeamContext = () => {
	return useContext(TeamContext)
}

export default TeamProvider
