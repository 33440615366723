import {AuthPageLayout} from 'cores/auth'
import {navigate} from 'components/routing'

export * from './use_redirect_when_offline'

export const NoInternetConnection = () => {
	const {onLine} = navigator

	// Never show a confusing "you don't have internet" page when you actually do.
	if (onLine) {
		navigate('/home_page')
		return null
	}

	return <AuthPageLayout name="no_internet_connection" animation="broken-egg" subtitle noForm noLocaleButton />
}
