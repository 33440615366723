import {ToolsButton} from 'common/components'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {t} from 'initializers/i18n'
import {useRoleMessage} from 'hooks'

// Import and reuse tools dropdown options from other two planning pages
import {
	loadTemplates,
	manageTemplates,
	exportPlanning,
	calendar,
	timeSettings,
	sick,
	weekOverview,
} from 'cores/planning/pages/index/page_header/tools.js'

export const Tools = props => {
	const {envs} = useFilterFooterContext()
	const disabled = useRoleMessage({roles: 'manager', env: envs})
	const baseStr = 'planning.environment_table.tools_buttons'

	let elements = []
	if (!disabled)
		elements = [
			// Skip create shift modal for now... since you can't select a date in the modal AND there is no way to deduce the shift's
			// date, we can't set it... When creating the new shift modal, we should change this.
			loadTemplates(),
			manageTemplates(),
			sick(),
			weekOverview(),
			calendar(),
			exportPlanning(),
			timeSettings(),
		]

	return <ToolsButton {...props} disabled={disabled} elements={elements} />
}
