import {stringToCell} from 'common/components/advanced_table'
import {UngroupedCollapsibleCell} from './ungrouped_cell'
import {GroupedCollapsibleCell} from './grouped_cell'

const CELL_TO_COLLAPSED_CELL = {
	TimeCell: 'TimeCell',
	TimeCellWithZero: 'TimeCellWithZero',
	true: 'BasicCell',
	BooleanCell: 'CollapsibleBooleanCell',
	MoneyCell: 'MoneyCellWithoutZero',
}

export const CollapsibleCell = ({cell, isGroupedColumn}) => {
	const {column} = cell
	const {id: columnName, columnDef} = column
	const {aggregates, collapsibleCell, cell: nonCollapsibelCell} = columnDef

	// Always show a value for the grouped column, otherwise it wouldn't be clear what you grouped on.
	if (isGroupedColumn) {
		return <GroupedCollapsibleCell cell={cell} />
	}

	// If not grouped but aggregates still make sense.
	if (aggregates || collapsibleCell || CELL_TO_COLLAPSED_CELL[nonCollapsibelCell]) {
		return <UngroupedCollapsibleCell cell={cell} />
	}

	// If aggregate values make no sense, simply return null.
	return null
}

export const getCellComponent = (cell, Fallback) => {
	const {collapsibleCell, cell: nonCollapsibelCell} = cell.column.columnDef

	const Custom = stringToCell(collapsibleCell)

	const defaultCellName = CELL_TO_COLLAPSED_CELL[nonCollapsibelCell]
	const Default = defaultCellName && stringToCell(defaultCellName)

	return Custom || Default || Fallback
}
