import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export const TimeString = ({value, showHourText, signed}) => {
	let val = utils.minutesToString(value, {sign: signed})

	return `${val} ${showHourText && t('common.hour')}`
}

export default TimeString
