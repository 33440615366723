import {date} from 'initializers/date'
import {API, useWhere as getWhere, useFind as getFind, useAll as getAll, useIndex as doIndex} from '@eitje/easy_api'
import doShow from 'hooks/use_show'
import {useWhereNot as doWhereNot} from 'hooks/use_where_not'
import utils from '@eitje/web_utils'
import {makeRange} from 'helpers/date'
import autoBind from 'lib/autobind'
import './perf_test'

// IMPORTANT NOTE: we used to use JS mixins to organize functionality, but that has a serious perf penalty,
// 								 so we decided to put all methods in the same file. Not very pretty, but fast

class ActiveRecord {
	//
	// BASE
	//

	constructor(props) {
		const okProps = Object.keys(props).filter(p => !_.isFunction(props[p]))
		okProps.forEach(prop => (this[prop] = props[prop]))
		autoBind(this)
	}

	tableName = this.constructor.tableName

	activeRecordDescendant = true

	snakeTableName() {
		return utils.camelToSnake(this.tableName)
	}

	static snakeTableName() {
		return utils.camelToSnake(this.tableName)
	}

	createdAt() {
		return date(this.created_at)
	}

	updatedAt() {
		return date(this.updated_at)
	}

	klass() {
		return this.constructor.name
	}

	//
	// QUERY METHODS
	//

	static all(opts) {
		return getAll(this.tableName, opts)
	}
	static where(query, opts) {
		return getWhere(this.tableName, query, opts)
	}

	static whereNot(query, opts) {
		return doWhereNot(this.tableName, query, opts)
	}

	static find(query) {
		return getFind(this.tableName, query)
	}

	//
	// ACTIONS
	//

	static create(params) {
		return API.create(this.tableName, params)
	}

	static doIndex(params) {
		return doIndex(utils.camelToSnake(this.tableName), params)
	}

	static index(params) {
		return API.index(this.snakeTableName(), params)
	}

	static update = this.create

	static destroy(id) {
		return API.destroy(this.snakeTableName(), id)
	}

	static show(params = {}) {
		return doShow(utils.camelToSnake(this.tableName), params)
	}

	static arbitrary(endpoint, params = {}, rest = {}) {
		return API.arbitrary(utils.camelToSnake(this.tableName), endpoint, {params, ...rest})
	}

	static multiUpdate(params, rest = {}) {
		return API.updateMulti(this.snakeTableName(), params, rest)
	}

	refresh() {
		return API.show(this.tableName, this.id)
	}

	update(params) {
		return API.update(this.tableName, {...params, id: this.id})
	}

	destroy() {
		return API.destroy(this.snakeTableName(), this.id)
	}

	buildUrl(path) {
		return `${utils.camelToSnake(this.tableName)}/${this.id}/${path}`
	}

	addRemoveAssoc(data, assocName) {
		data = _.omit(data, 'id') // we don't wanna pass ID to addRemoveAssoc, it has to be 'pure', only assocs
		return API.addRemoveAssoc(this.snakeTableName(), data, {[assocName]: this[assocName]}, this.id)
	}

	resourceReq(endpoint, params = {}) {
		params = {...params, id: this.id}
		if (params.method) {
			params = {params: _.omit(params, 'method'), method: params.method}
		}

		return API.resourceReq(utils.camelToSnake(this.tableName), endpoint, params)
	}
}

class DateRangeActiveRecord extends ActiveRecord {
	static startDateField = 'start_date'
	static endDateField = 'end_date'

	_startDate = () => this[this.constructor.startDateField]
	_endDate = () => this[this.constructor.endDateField]

	dateRange() {
		return this.__dateRange()
	}

	_dateRange() {
		return typeof this.dateRange == 'function' ? this.dateRange() : this.dateRange
		// in av we use the non-model based PlanningShift, which depends on dateRange property being set by eitje_api.js
	}

	__dateRange() {
		return makeRange(this._startDate(), this._endDate())
	}

	totalMins() {
		return this._dateRange().diff('minutes')
	}

	totalDays() {
		return this._dateRange().diff('days')
	}

	stringMins() {
		return utils.minToTimeString(this.totalMins())
	}

	totalHours() {
		return this.totalMins() / 60.0
	}

	static totalMins() {
		return utils.sum(this.map(i => i.totalMins()))
	}
}

export {DateRangeActiveRecord}

export default ActiveRecord
