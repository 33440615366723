import React, {createContext, useContext} from 'react'
import {useSearchInput} from 'hooks/use_search'
import {t} from 'initializers/i18n'

const SearchContext = createContext({})
const {Provider} = SearchContext

const SearchProvider = ({children, color = 'black', searchFieldName = t('common.team_member')}) => {
	const {search, setSearch, searchInput, searchInputRef, clearSearch} = useSearchInput({
		enableHotkey: true,
		color,
		debounce: 300,
		placeholder: searchFieldName && `${t('common.search_by')} ${searchFieldName}`,
	})

	const val = {searchInputRef, search, setSearch, clearSearch, searchInput}

	return <Provider value={val}> {children} </Provider>
}

export const useSearchContext = () => useContext(SearchContext)

export default SearchProvider
