import {makeField, makeLegacyField} from '@eitje/form'
import {BaseInput} from '@eitje/form-fields-web'
import utils from '@eitje/utils'
import useHotkey from 'hooks/use_hotkey'
import usePrevious from 'hooks/use_previous'
import {useEffect, useRef, useState} from 'react'
import './styles/hour_minute_picker.less'
import {EitjeIcon} from 'components/common/index'
import {formatCssVars} from 'helpers'
const numOnly = val => val.replace(/[^0-9.]/g, '')

const HourMinutePickerInput = ({onChange, showArrows, minimum = 0, field, autoFocus, value, max, debug, maxHourDigits = 2, ...rest}) => {
	const hourInput = useRef(null)
	const minInput = useRef(null)
	const [hour, setHour] = useState(null)
	const [min, setMin] = useState(null)
	const prevValue = usePrevious(value)

	const changeHour = val => {
		val = val.toString()
		if (_.isString(val)) val = val.slice(0, maxHourDigits)
		const fullVal = getVal({hours: val}) / 60
		if (fullVal > max) return
		if (fullVal < minimum) return
		if (isNaN(fullVal)) return
		setHour(val)
		setHourMin({hours: val})
	}

	const changeMin = val => {
		const numVal = Number(val)
		if (numVal > 59) return

		const fullVal = getVal({mins: val}) / 60
		if (fullVal > max) return

		val = val.slice(0, 2)
		setMin(val)
		setHourMin({mins: val})
	}

	const setHourMin = obj => {
		const val = getVal(obj)
		onChange(val)
	}

	const getVal = ({mins = min, hours = hour}) => {
		const timeString = `${hours || 0}:${mins || 0}`
		return utils.timeStringToMin(timeString)
	}

	useEffect(() => {
		if (value != prevValue) {
			const [_hours, _mins] = utils.minToHourArray(value || 0)
			const _prev = prevValue
			setHour(utils.zeroOrNaN(_hours) ? null : _hours)
			setMin(utils.zeroOrNaN(_mins) ? null : _mins)
		}
	}, [value])

	const onKeyDown = e => {
		const currentSel = e.target?.selectionEnd
		if (currentSel != 0) return
		hourInput.current?.focus()
	}

	const focusMin = e => {
		if (e.key == 'ArrowLeft' || e.key == 'Backspace') return
		const currentSel = e.target?.selectionEnd

		if (e.key === 'ArrowRight') {
			if (currentSel == `${hour}`.length) {
				minInput.current?.focus()
				return
			}
		}

		if (currentSel >= maxHourDigits) minInput.current?.focus()
	}

	const _onKeyDown = useHotkey({backspace: onKeyDown})

	const _onKeyUp = useHotkey({arrowleft: onKeyDown})

	const styles = formatCssVars({'hour-digits': hour >= 100 ? 3 : 2})

	return (
		<div style={styles} className="eitje-hour-minute-picker-input">
			<BaseInput
				{...rest}
				className="eitje-hour-minute-picker-input-left"
				bordered={false}
				placeholder="00"
				onKeyDown={focusMin}
				value={hour}
				formatValue={numOnly}
				onChange={changeHour}
				innerRef={hourInput}
				autoFocus={autoFocus}
			/>
			<span className="eitje-hour-minute-picker-divider">:</span>
			<BaseInput
				{...rest}
				className="eitje-hour-minute-picker-input-right"
				bordered={false}
				onFocus={() => !hour && !min && hourInput.current?.focus()}
				placeholder="00"
				onKeyDown={_onKeyDown}
				onKeyUp={_onKeyUp}
				innerRef={minInput}
				value={min}
				formatValue={numOnly}
				onChange={val => changeMin(val)}
			/>
			{showArrows && <Arrows onChange={num => changeHour(hour + num)} />}
		</div>
	)
}

const Arrows = ({onChange}) => {
	return (
		<div className="eitje-hour-minute-picker-arrows">
			<EitjeIcon src="arrow_filled_up" onClick={() => onChange(1)} />
			<EitjeIcon src="arrow_filled_down" onClick={() => onChange(-1)} />
		</div>
	)
}

const HourMinutePicker = makeField(HourMinutePickerInput, {className: 'eitje-hour-minute-picker-container'})
const LegacyHourMinutePicker = makeLegacyField(HourMinutePickerInput, {className: 'eitje-hour-minute-picker-container'})

export default LegacyHourMinutePicker
export {HourMinutePicker, HourMinutePickerInput}
