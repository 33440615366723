import {WeekTableRow} from 'common/components'
import {Panel} from './panel'
import {Column} from './column'
import {usePlanningContext} from 'cores/planning'

export const OpenShiftsRow = ({}) => {
	const {dateArray} = usePlanningContext()

	return (
		<WeekTableRow
			className="open-shifts-row"
			Panel={Panel}
			Day={Column}
			dateArray={dateArray}
			minHeight={48}
			initialBackground={Colors.white}
		/>
	)
}
