import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import 'styles/app/components/drag_drop.less'

const DefaultDragDrop = props => <div className="drag-drop-container" {...props} />

const FileDragAndDrop = ({
	onDrop,
	text,
	accept,
	//Requires a react component not a function. having the component computed here wouldn't trigger popup file selection
	Component = DefaultDragDrop,
	custom,
	placeholder,
}) => {
	const _onDrop = useCallback(acceptedFiles => {
		onDrop(acceptedFiles)
	}, [])

	let condOpts = {}

	if (accept) {
		condOpts['accept'] = accept
	}

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: _onDrop, ...condOpts})

	return (
		<Component {...getRootProps({isDragActive})}>
			<input {...getInputProps()} />
			{!custom && (
				<div style={{flex: 1}}>
					<p className="drag-drop-container-title">{text}</p>
					<p className="drag-drop-container-sub-title">{placeholder}</p>
				</div>
			)}
		</Component>
	)
}

export default FileDragAndDrop
