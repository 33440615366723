import {ColorCircle} from 'components/ui'
import utils from '@eitje/web_utils'
import {PopoutCard} from '@eitje/web_components'
import {TradeRequest} from 'models/index'
import ApproveTrade from 'cores/planning/components/trade/approve_trade/index'
import {makeCnVariants} from 'helpers'

import './styles/trigger.less'

const TradeStatus = ({id, children, inlineTrigger, ...props}) => {
	const tradeRequest = TradeRequest.find(id)
	if (!utils.exists(tradeRequest) || tradeRequest.published) return null

	const noBorder = !!children
	const accordable = tradeRequest.accordable()
	const classNames = makeCnVariants('planning-trade-status', noBorder && 'no-border')

	const defaultChild = (
		<>
			{accordable && <ColorCircle text={'!'} fontSize={6} size={8} />}
			<img className="trade-icon" src="/images/trade/trade_icon.png" />
		</>
	)

	return (
		<PopoutCard
			trigger="click"
			bodyPadding={0}
			body={<ApproveTrade item={tradeRequest} {...props} />}
			className={classNames}
			inlineTrigger={inlineTrigger}
			placement="right"
		>
			{children || defaultChild}
		</PopoutCard>
	)
}

export default TradeStatus
