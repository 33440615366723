import {backend} from '@eitje/easy_api'
import {store} from 'index'
import I18n from 'initializers/i18n'
import {crazyFilter} from 'selectors/records'
import * as jsUtils from 'utils/jsutils'

export async function getEnums() {
	const res = await backend.get('enums')
	if (res.ok) {
		store.dispatch({type: 'GET_ENUMS', enums: res.data.enums, eventKinds: res.data.event_kinds})
	}
}

export function getEnum(className, val) {
	const enums = store.getState().general.enums
	try {
		let i18n = I18n
		let loc = I18n.language
		let text = enums[className][val][loc]
		return text ? text : jsUtils.capitalize(val)
	} catch (e) {
		return jsUtils.capitalize(val)
	}
}

export const partition = (items, query) => {
	const matched = crazyFilter(items, query)
	const unmatched = items.filter(i => !matched.includes(i))
	return [matched, unmatched]
}

export const saveValue = (name, payload) => {
	store.dispatch({type: 'SET_VALUE', name, payload})
}
