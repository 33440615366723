import {Text, ToolsButton} from 'common/components'
import {ModalLink, Link} from 'components/routing'
import {useShared, useRoleMessage} from 'hooks'

export const Tools = props => {
	const {env} = useShared()
	const disabled = useRoleMessage('verlof')
	const notAdmin = useRoleMessage('admin')
	const notAdminInCurrentEnv = useRoleMessage({roles: 'admin', env: env.id})

	let elements = []
	if (!disabled) {
		elements = [
			{icon: 'pencil', element: <ModalLink to="/leave/new" t="leave.new" />, disabled},
			{icon: 'upload', element: <Link to="/exports2/tables/new" t="cores.leave.create_your_own_export" />, disabled: notAdmin},
			{icon: 'cog-wheel', element: <ModalLink to={`/leave/${env.id}/settings`} t="common.settings" />, disabled: notAdminInCurrentEnv},
		]
	}

	return <ToolsButton {...props} disabled={disabled} elements={elements} />
}
