import utils from '@eitje/utils'
import {useState} from 'react'

const useToggleState = def => {
	const [state, setState] = useState(def)
	const setToggleState = newEl => {
		return setState([...utils.toggle(state, newEl)])
	}
	return [state, setToggleState, setState]
}

export default useToggleState
