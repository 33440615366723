import {store} from 'index'
let authChannel

if ('BroadcastChannel' in window) {
	authChannel = new BroadcastChannel('myUniqueApp_auth_channel')
	console.log('Cross-tab sync is on yo')
} else {
	console.warn('BroadcastChannel is not supported in this browser. Cross-tab synchronization is disabled.')

	// No-op implementation
	authChannel = {
		postMessage: () => {},
		onmessage: null,
		addEventListener: () => {},
		removeEventListener: () => {},
		close: () => {},
	}
}
authChannel.onmessage = event => {
	const action = event.data
	store.dispatch({type: action.type, ...action.payload})
}

export {authChannel}
