import {API, backend, where} from '@eitje/easy_api'
import NewsForm from 'components/forms/news_form'
import useList from 'components/general/list'
import Dashboard from 'components/news_dashboard'
import useTeamFilter from 'hooks/use_team_filter'
import {t} from 'initializers/i18n'
import React, {useEffect, useRef, useState} from 'react'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import {useSelector} from 'react-redux'
import {PostVideoUpload, ScheduledPosts} from './components'
import Post from './post'
import './styles/news.less'
import {useAsyncEffect, useConcatState} from '@eitje/react-hooks'
import {EitjeButton, Layout, PageLayout, Placeholder} from 'common/components'
import {SearchEmpty} from 'hooks'
import utils from '@eitje/web_utils'

const filters = {published: true}

const News = () => {
	const [initialFetchDone, setInitialFetchDone] = useState()
	const [noItemsLeft, setNoItemsLeft] = useState()
	const newsFormRef = useRef()

	useAsyncEffect(async () => {
		await Promise.all([API.index('posts', {filters}), API.index('posts', {filters: {published: false}})])
		setInitialFetchDone(true)
	}, [])

	const [readPosts, setReadPosts] = useConcatState([])

	let posts = useSelector(state => where(state, 'posts', filters))
	posts = _.sortBy(posts, [({published_at, created_at} = {}) => published_at || created_at]).reverse()

	const {filter, items, clearListPicker, hasFiltered} = useTeamFilter(posts, {
		dropdown: false,
		showToggleSelectAll: false,
		showClearAll: true,
	})

	const onEndReached = async () => {
		const res = await API.index('posts', {filters, params: {doNotLoad: false, doLoad: true}, inverted: true})
		if (!utils.exists(res?.data?.items)) setNoItemsLeft(true)
		return res
	}

	const {list} = useList({
		items,
		disableParentClick: true,
		itemProps: {setReadPosts},
		virtual: true,
		customScrollParent: '.page-body',
		onEndReached: noItemsLeft ? _.noop : onEndReached,
		allowDeactivate: true,
		ListItem: Post,
	})

	useEffect(() => {
		return () => {
			if (readPosts.length > 0) {
				backend.post('posts/read', {
					doNotLoad: true,
					ids: readPosts.uniq(),
				})
			}
		}
	}, [readPosts])

	const showPlaceholder = items.length == 0 && initialFetchDone
	const placeholder = hasFiltered ? (
		<SearchEmpty size="small" action={clearListPicker} />
	) : (
		<Layout type="card" horizontal="center">
			<Placeholder animation="notebook" name="post" subtitle={false} docsId="6010099">
				<EitjeButton onClick={() => newsFormRef.current.focus()} iconLeft="pencil" colorSet="solid" t="action_button" />
			</Placeholder>
		</Layout>
	)

	return (
		<PageLayout name="news">
			<div className="news-posts-container">
				<NewsForm ref={newsFormRef} />
				<PostVideoUpload />
				<ScheduledPosts />

				<div className="news-filter-container">
					<p className="news-filter-title">{t('filterNews')}</p>
					<div className="team-select-container">{filter}</div>
				</div>

				{showPlaceholder && placeholder}

				{list}
			</div>

			<aside>
				<Dashboard />
			</aside>
		</PageLayout>
	)
}

export default News
