import {useShared} from 'hooks'
import {envSwitch} from 'actions/environment'
import {AuthPageLayout} from 'cores/auth'
import {Redirect} from 'components/routing'
import useOrgLocked from 'cores/billing/hooks/use_org_locked'
import {EitjeButton, ListPicker} from 'common/components'
import {salesPhoneNumberFormatted} from 'constants/general'
import {mdT} from 'initializers/i18n'
import {Environment} from 'models'
import {getLanguage} from 'initializers/i18n'

export const TrialEnded = () => {
	const {env, hasAdminRole} = useShared()
	const {locked} = useOrgLocked()

	if (!locked) return <Redirect to="/home_page" />

	const rawSubtitle = mdT('auth.pages.trial_ended.subtitle', {environmentName: env.naam.trim(), phone: salesPhoneNumberFormatted})

	return (
		<AuthPageLayout
			name="trial_ended"
			callToActions="logout"
			rawSubtitle={rawSubtitle}
			noForm
			extraHeaderItems={<EnvSwitch />}
			animation="credit-card-and-lock"
			authedPage={true}
		>
			{hasAdminRole && (
				<EitjeButton iconLeft="credit-card" colorSet="solid" modalLink="/billing/sign" t="billing.buttons.setup_payment_details" />
			)}
		</AuthPageLayout>
	)
}

const EnvSwitchTrigger = () => {
	const {env} = useShared()
	return <EitjeButton iconLeft="building" children={env.naam} dropdown />
}

const EnvSwitch = () => {
	const {env} = useShared()
	const envs = Environment.all()
	if (envs.length <= 1) return null

	return (
		<ListPicker
			raw
			dropdown
			Trigger={EnvSwitchTrigger}
			trigger="hover"
			onChange={envSwitch}
			single
			value={env.id}
			items={envs}
			labelField="naam"
			strategy="fixed"
		/>
	)
}
