import {date} from 'initializers/date'

const mmt = date()

const initialState = {
	week: mmt.isoWeek(),
	year: mmt.isoWeekYear(),
	shifts: [],
	checkIns: [],
	fetching: false,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'WEEK_HOURS_LOADING':
			return {
				...state,
				fetching: true,
			}

		case 'GET_WEEK_HOURS':
			return {
				...state,
				week: action.week,
				year: action.year,
			}

		case 'WEEK_HOURS_DONE':
			return {
				...state,
				fetching: false,
			}

		default:
			return state
	}
}
