import {useEffect} from 'react'
import {useChannelActionContext} from 'stream-chat-react'
import {useJumpToMessageProviderContext} from 'cores/chat'

const JUMP_TO_PAGE_SIZE = 100 // page size to use when searching for the message to highlight, automatically handled by stream
const HIGHLIGHT_DURATION = 1000 // yellow background highlight, in ms

export const JumpToMessage = () => {
	const {messageId, setMessageId} = useJumpToMessageProviderContext()
	const {jumpToMessage} = useChannelActionContext()

	useEffect(() => {
		if (messageId) {
			jumpToMessage(messageId, JUMP_TO_PAGE_SIZE, HIGHLIGHT_DURATION)
			setMessageId(null)
		}
	}, [messageId, jumpToMessage, setMessageId])

	return null
}
