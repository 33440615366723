import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import {isDev, isProd} from 'constants/general'
import {useBillingDone, useShared} from 'hooks'
import date from 'initializers/date'
import {isInternalUser} from 'helpers'
import utils from '@eitje/web_utils'
import {useLocation} from 'react-router-dom'
import * as Sentry from '@sentry/browser'

const rnd = Math.random()

export const LogRocketRegistrar = () => {
	const {me, env, org} = useShared()
	const loc = useLocation()
	if (env?.status == 'eitje' || isInternalUser(me)) return null
	if (!isProd) return null
	const authed = me.id
	const isNewUser = loc.pathname == '/create_account'

	const isLoggingIn = !authed && !isNewUser

	if (org?.billing_done) {
		if (rnd > 0.15) return null
	}

	if (isLoggingIn) {
		if (rnd > 0.05) return null
	}

	// we only want to capture a portion of normal sessions, because our LR max is 10K and we want to capture all demo clients

	if (!LogRocket._isInitialized) {
		LogRocket.init('tprhp0/eitje-prod')
		setupLogRocketReact(LogRocket)

		LogRocket.getSessionURL(function (sessionURL) {
			if (utils.exists(window?.heap?.identify?.name)) {
				window.heap.track('LogRocket', {sessionURL: sessionURL})
			}

			Sentry.configureScope?.(scope => {
				scope.setExtra('LogRocket URL', sessionURL)
			})
		})
	}

	if (!authed) return null

	LogRocket.identify(me.id, {
		name: me.full_name,
		email: me.email,
		env_name: env?.naam,
		env_id: env?.id,
		org_id: org?.id,
		org_name: org?.name,
		org_created: date(org.created_at).format(),
		amt_users: env?.user_ids?.length,
	})

	return null
}
