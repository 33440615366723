import {PopoutCard} from '@eitje/web_components'
import {Input, TimePicker, DatePicker} from '@eitje/form-fields-web'
import {FormRow} from 'components/ui'
import {ContextForm} from '@eitje/form'
import {formMapper} from 'cores/leave/index'
import {useModalContext} from 'components/common/index'
import {useSimpleContext} from 'contexts/simple'
import './styles/leave_summary.less'
import {EditButton} from 'common/components'

const Page = () => {
	const {t} = useModalContext()
	const {request, processed, hasLeaveRole} = useSimpleContext()
	const kind = request.kind()
	const tooltipTitle = hasLeaveRole ? 'edit_disabled' : 'edit_disabled_user'
	return (
		<div className="leave-summary">
			<PopoutCard hidden={!processed} title={t(tooltipTitle)}>
				<EditButton onClick={e => processed && e.preventDefault()} height="small" disabled={processed} modalLink={`/leave/${request.id}`} />
			</PopoutCard>
			<ContextForm
				initialValues={formMapper(request)}
				transNamespace="add_leave"
				className="eitje-form-2 eitje-form-2-use-borders"
				fieldProps={{readOnly: true}}
			>
				<DatePicker field="start_datetime" />

				{kind == 'few_hours' && (
					<FormRow>
						<TimePicker field="start_time" />
						<TimePicker field="end_time" />
					</FormRow>
				)}

				{kind == 'multiple_days' && (
					<FormRow>
						<DatePicker field="end_datetime" />
					</FormRow>
				)}

				<FormRow>
					<Input textarea field="reden" />
				</FormRow>
			</ContextForm>
		</div>
	)
}

export default Page
