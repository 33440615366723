import {t as baseT} from 'initializers/i18n'
export * from './actions'
export * from './pages/index'
export * from './hooks/index'
export * from './components/index'
export * from './constants'
export * from './models/index'
export * from './helpers/index'

const t = (text, opts = {}) => baseT(`exports2.${text}`, opts)
const exportT = t

export {t, exportT}
