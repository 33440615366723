import {t} from 'initializers/i18n'
import {useModalContext} from 'components/general/modal'
import {EitjeButton} from 'common/components'

const ModalBackButton = () => {
	const {goBack} = useModalContext()
	return <EitjeButton iconLeft="arrow-small-left" children={t('common.close')} onClick={goBack} />
}

export default ModalBackButton
