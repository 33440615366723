import {createContext, useCallback, useContext, useState} from 'react'
import {useChatContext} from 'stream-chat-react'

const ChatWindowContext = createContext({})

export const ChatWindowProvider = ({children}) => {
	const {setActiveChannel} = useChatContext()
	const [chatWindowOpen, setChatWindowOpen] = useState(false)

	const openChatWindow = useCallback(() => {
		setActiveChannel(null)
		setChatWindowOpen(true)
	}, [])

	const closeChatWindow = useCallback(() => setChatWindowOpen(false), [])

	const openChatWindowChannel = useCallback(channel => {
		setActiveChannel(channel)
		setChatWindowOpen(true)
	}, [])

	const state = {
		chatWindowOpen,
		openChatWindow,
		closeChatWindow,
		openChatWindowChannel,
	}

	return <ChatWindowContext.Provider value={state} children={children} />
}

export const useChatWindowContext = () => useContext(ChatWindowContext)
