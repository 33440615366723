import {API, find, where} from '@eitje/easy_api'
import utils from '@eitje/utils'
import {ContextForm} from '@eitje/form'
import {delUser, inviteUser} from 'actions/environment'
import {ModalLink} from 'components/routing'
import {ConfirmButton, DeleteButton, ListPicker} from 'common/components'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {roleOrgEnvsSelector, roleSelector} from 'selectors/new_settings'
import {envIdSelector, inEnv, whereInc} from 'selectors/records'
import './styles/users.less'

export const DelButton = ({user}) => {
	const envId = useSelector(envIdSelector)
	return (
		<DeleteButton nonDestructive onDel={() => delUser(envId, user.id)} iconLeft="person-minus">
			{user.voornaam} {t('outOfService')}
		</DeleteButton>
	)
}

const DropdownP = ({triggerText, ...props}) => (
	<p {...props} className="eitje-text-secondary defaultRow">
		<p className="dropdown-fff-select-label">{triggerText}</p>
	</p>
)

export const DelPopOver = ({user, popover, buttonStyle, style}) => {
	const act = async () => {
		await inviteUser({email: user.email})
		popover?.hide()
	}

	return (
		<div className="rehire-popout" style={style}>
			<ConfirmButton width="full" style={buttonStyle} onClick={() => act()}>
				{t('takeBackEmployee')}
			</ConfirmButton>
		</div>
	)
}

const listPickerProps = {
	decorated: false,
	dropdown: true,
	showCount: false,
	showSearch: false,
	Trigger: DropdownP,
	labelField: 'naam',
	submitStrategy: 'throttledChange',
}

export const PopoverContent = ({user, disableTeams, env}) => {
	const hasManagerRole = useSelector(state => roleSelector(state, 'manager', env))
	const managerOrgEnvs = useSelector(state => roleOrgEnvsSelector(state, 'manager'))
	const relevantOrgEnvs = managerOrgEnvs.filter(e => !user.environment_ids.includes(e.id))
	const teams = useSelector(state => inEnv(state, 'teams', env)).filter(t => !t.locked)
	const userEnvTeams = utils.getIdsFromItems(user.team_ids, teams) // to prevent accidentally removing locked teams

	return (
		<div onClick={e => e.stopPropagation()} className="edit-team-member-popover-content veedree">
			<ModalLink to={`/forms/user_preferences/${user.id}`}>
				<p className="defaultRow">{t('editUser')}</p>
			</ModalLink>

			<ContextForm
				onSubmit={data => API.addRemoveAssoc('users', data, {team_ids: userEnvTeams}, user.id)}
				initialValues={{team_ids: userEnvTeams}}
			>
				{!disableTeams && hasManagerRole && (
					<ListPicker {...listPickerProps} field="team_ids" items={teams} triggerText={t('manageTeams')} />
				)}
			</ContextForm>

			{hasManagerRole && relevantOrgEnvs.length > 0 && (
				<Form onSubmit={data => API.addAssoc('users', {...data, id: user.id})}>
					<ListPicker form={false} {...listPickerProps} field="environment_ids" items={relevantOrgEnvs} triggerText={t('manageEnvs')} />
				</Form>
			)}

			<div className="delete-button-container">
				<DelButton user={user} />
			</div>
		</div>
	)
}

export const ContractEnv = ({id}) => {
	const env = useSelector(state => find(state, 'environments', id))
	return <Fragment> {env.naam} </Fragment>
}

export const DeletedEnvs = ({ids}) => {
	const envs = useSelector(state => where(state, 'environments', ids))
	const names = envs.map(t => t.naam)
	return <Fragment>{`(${names.length}) ${names.join(', ')}`}</Fragment>
}

export const TableTeams = ({user}) => {
	const envId = useSelector(envIdSelector)
	const teams = useSelector(state => whereInc(state, 'teams', {id: user.team_ids, environment_id: envId, locked: false}))
	const teamNames = teams.map(t => t.naam)
	return (
		<Fragment>
			<p className="eitje-text-secondary" style={{whiteSpace: 'pre-wrap'}}>
				{!!teams.length && `(${teams.length}) ${teamNames.join(', ')}`}
			</p>
		</Fragment>
	)
}
