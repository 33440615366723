import {useWhere, useAll} from '@eitje/easy_api'
import {usePlanningDates, useScheduledShifts} from 'cores/planning/index'
import utils from '@eitje/web_utils'
import {contractJoins as opts} from 'constants/users'

const useAmtWorkedUserMap = () => {
	const {dateRange} = usePlanningDates()
	const users = useAll('users', opts)
	const userIds = users.map(u => u.id)
	const {planShifts, improductiveShifts} = useScheduledShifts({dateRange, user_id: userIds})
	const shifts = [...planShifts, ...improductiveShifts]
	const userWeeks = useUserWeeks({userIds, dateRange})
	const props = {shifts, dateRange, userWeeks}
	const userMap = calcAmtWorked(users, props)
	return userMap
}

const useUserWeeks = ({userIds, dateRange}) => {
	const {week, year} = utils.getYearWeekFromDate(dateRange.start)
	const params = {weeknumber: week, year, user_id: userIds}
	const weeks = useWhere('userWeeks', params)
	const defaultWeeks = useWhere('userTemplateWeeks', {user_id: userIds})
	return userIds
		.map(id => {
			return weeks.find(w => w.user_id == id) || defaultWeeks.find(w => w.user_id == id)
		})
		.filter(Boolean)
}

const calcAmtWorked = (users, props) => {
	let obj = {}
	users.forEach(user => {
		obj[user.id] = fillUser(user, props)
	})
	return obj
}

const fillUser = (user, {dateRange, shifts, userWeeks}) => {
	shifts = shifts.filter(s => s.user_id == user.id)

	const minsWorked = shifts.sum(s => s.totalMins())
	const daysWorked = shifts.map(s => s.date).uniq().length
	const daysPreferred = userWeeks.find(w => w.user_id == user.id)?.preferred_shifts
	const schedule = user.workSchedule(dateRange)
	const contractMins = schedule?.total_mins || 0

	const minsRemaining = contractMins ? contractMins - minsWorked : null
	const daysRemaining = daysPreferred ? daysPreferred - daysWorked : null

	return {daysWorked, daysPreferred, contractMins, daysRemaining, minsRemaining, minsWorked}
}

// {389: {days_worked: 1, days_pref: 2, days_remaining: 1,  mins_worked: 220, contract_mins: 180, mins_remaining: 40} }

// 1. alle shifts in week
// 2. contracturen
// 3. user weeks

export default useAmtWorkedUserMap
