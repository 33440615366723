import {useState} from 'react'
import {useIntersectionObserver} from './use_observers'

export function useOnScreen(ref, opts = {}) {
	const [isOnScreen, setIsOnScreen] = useState(false)

	const handleChange = entry => {
		setIsOnScreen(entry.isIntersecting)
	}

	useIntersectionObserver(ref, handleChange, opts)

	return isOnScreen
}
