import {useWhere} from '@eitje/easy_api'
import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'
import {getTableName} from 'helpers/index'
import pluralize from 'pluralize'
import {history} from 'index'
import {createExportNotification, ExportSubscription} from 'cores/exports2'
import utils from '@eitje/web_utils'
import {Excel} from 'models'

export class ExportTable extends ActiveRecord {
	static tableName = 'export2Tables'

	kind() {
		if (this.draft) return 'draft'
		if (this.stock) return 'stock'
		return 'normal'
	}

	_tableName() {
		return getTableName(this.table_name)
	}

	filterInfo() {
		const {filtered_column_groups = []} = this

		const tableName = this._tableName()

		const columnTableNames = filtered_column_groups.map(c => getTableName(c))

		return {tableName, columnTableNames, columnFilter: this.getFilterType('column_filters'), rowFilter: this.getFilterType('row_filters')}
	}

	async duplicate() {
		const res = await this.resourceReq('duplicate', {method: 'post'})
		if (res.ok) {
			ExportSubscription.index()
			history.push(`/exports2/tables/${res.item.id}`)
		}
	}

	edit() {
		history.push(`/exports2/tables/${this.id}/edit`)
	}

	show() {
		history.push(`/exports2/tables/${this.id}`)
	}

	hasColumnFilter() {
		const {filtered_column_groups} = this
		return filtered_column_groups.length > 0
	}

	columnsDone() {
		const {columns} = this
		return utils.exists(columns)
	}

	filtersDone() {
		const {row_filters} = this
		return utils.exists(row_filters)
	}

	async export(params = {}) {
		const res = await this.resourceReq('export', {...params, method: 'post'})
		if (res.ok) {
			createExportNotification()
		}
		return res
	}

	auxFilters(type = 'row_filters') {
		return this[type].filter(t => t.filter != 'firms')
	}

	getFilterType(filterName) {
		const filters = this[filterName]
		if (!filters) return {}
		const firmFilter = filters.find(c => c.filter == 'firms')
		if (!firmFilter) return {}
		const keyWithIds = Object.keys(firmFilter['value'])[0]
		const singularType = keyWithIds.replace('_ids', '')
		return {singularType, type: pluralize(singularType), value: firmFilter['value'][keyWithIds]}
	}
}

export default ExportTable
