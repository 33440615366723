export function setGlobalData(val) {
	if (!window.globals) window.globals = {}
	window.globals = {...window.globals, ...val}
}

export function getGlobal(val) {
	const globals = window.globals || {}
	return globals[val]
}

// We use window variaables for two purposes:

// - super global data (like envName) that should be accessible everywhere
// - shiort lived data, the wndow resets on every reload so it's the perfect store if you don't want your data to persist.
