import ActiveRecord from 'models/active_record/base'

class WeatherDay extends ActiveRecord {
	static types = ['partly-cloudy-day', 'cloudy', 'sleet', 'snow', 'rain', 'clear-day', 'partly-cloudy-night', 'fog', 'clear-night', 'wind']
	static tableName = 'weatherDays'

	imgSrc = () => {
		if (this.hasType()) return `/images/${this.type}.png`
		return this.defaultSrc
	}

	hasType = () => WeatherDay.types.includes(this.type)

	defaultSrc = `/images/partly-cloudy-day.png`
}

export default WeatherDay
