import {PlanningShift, TimeRegistrationShift, User} from 'models/index'
import Association from 'models/active_record/association'

const useShiftsByIds = (id, opts) => {
	const regShifts = TimeRegistrationShift.where({id}, opts)
	const planShifts = PlanningShift.where({id, published: true}, opts).filter(s => s.user_id)
	return new Association([...regShifts, ...planShifts])
}

export default useShiftsByIds
