import {t} from 'initializers/i18n'

export const getShiftStatusFilter = ({withOpenShifts}) => {
	const statusItems = [
		{id: 'published', name: t('records.default.fields.published_true')},
		{id: 'unpublished', name: t('records.default.fields.published_false')},
		{id: 'trade_request', name: t('common.trade_request')},
	]

	const itemsWithOpenShifts = [...statusItems, {id: 'open_shift', name: t('records.shift.open_shift_plural')}]
	const items = withOpenShifts ? itemsWithOpenShifts : statusItems

	const filterProps = {
		noSort: true,
		name: 'status',
		items,
	}

	const filterFunction = (shift, statusses) => {
		const statusObj = Object.fromEntries(statusses.map(s => [s, true]))
		if (statusObj.published && !statusObj.unpublished && !shift.published) return false
		if (statusObj.unpublished && !statusObj.published && shift.published) return false
		if (statusObj.trade_request && !shift.hasTradeRequest()) return false
		if (statusObj.open_shift && shift.user_id) return false
		return true
	}

	return {
		filterProps,
		filterFunction,
	}
}
