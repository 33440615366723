import {useSelector} from 'react-redux'
import {roleOrgEnvsSelector, roleSelector} from 'selectors/new_settings'
import {envIdSelector, orgEnvsSelector, selfSelector, whereInc} from 'selectors/records'

const useAdminTeams = query => {
	const me = useSelector(selfSelector)
	const envId = useSelector(envIdSelector)
	if (!query) query = {environment_id: envId}
	const _envId = query['environment_id'] || envId
	const teams = useSelector(state => whereInc(state, 'teams', query))
	const isAdmin = useSelector(state => roleSelector(state, 'admin', _envId))
	if (isAdmin) return teams
	return teams.filter(t => me.team_ids.includes(t.id) || t.user_ids.includes(me.id))
}

export default useAdminTeams

export const useAdminOrgTeams = () => {
	const me = useSelector(selfSelector)
	const adminEnvIds = useSelector(state => roleOrgEnvsSelector(state, 'admin')).map(e => e.id)
	const orgEnvIds = useSelector(orgEnvsSelector).map(e => e.id)
	const teams = useSelector(state => whereInc(state, 'teams', {environment_id: orgEnvIds}))
	return teams.filter(t => {
		return adminEnvIds.includes(t.environment_id) || me.team_ids.includes(t.id) || t.user_ids.includes(me.id)
	})
}
