import ActiveRecord from 'models/active_record/base'

class RevenueDay extends ActiveRecord {
	static tableName = 'omzetDags'

	amt() {
		return this.amt_in_cents / 100
	}

	forecastAmt() {
		return this.forecast_amt_in_cents / 100
	}

	dateGroup() {
		return `${this.date}-${this.omzet_groep_id}`
	}
}

export default RevenueDay
