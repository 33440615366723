import {useCallback, useState} from 'react'
import {useAsyncEffect} from 'hooks'
import {useChatContext} from 'stream-chat-react'
import {useChannelMetaData, useJumpToMessageProviderContext} from 'cores/chat'
import {BasePreview} from './base'

export const SearchResultMessagePreview = ({message}) => {
	const {setMessageId} = useJumpToMessageProviderContext()
	const {client, setActiveChannel} = useChatContext()
	const [channel, setChannel] = useState(null)
	const {title, image, user, directChannel} = useChannelMetaData(channel)

	const {
		id,
		channel: {id: channelId, type: channelType},
	} = message

	useAsyncEffect(async () => {
		const channelWithState = client.channel(channelType, channelId)
		setChannel(channelWithState)
	}, [client, id, channelId, channelType])

	const handleClick = useCallback(() => {
		setActiveChannel(channel)
		setMessageId(id)
	}, [id, channel])

	return (
		<BasePreview
			className="search-result-message-preview"
			title={title}
			image={image}
			userId={user?.id}
			onClick={handleClick}
			lastMessage={message}
			includeSenderName={!directChannel}
		/>
	)
}
