import {MessageStatus as StreamMessageStatus, useChatContext, useMessageContext} from 'stream-chat-react'
import {Icon} from '@eitje/web_components'
import {UserListPopout} from 'common/components'
import {NAMESPACE} from 'cores/chat'

const MessageReadStatus = () => {
	const {client} = useChatContext()
	const {readBy} = useMessageContext('MessageStatus')
	const users = readBy
		.filter(user => user.id !== client.userID)
		.map(({id, name, image}) => ({
			id: parseInt(id, 10),
			full_name: name,
			avatar: image,
		}))

	return (
		<UserListPopout users={users} t={`${NAMESPACE}.read_by`}>
			<Icon name="eye-open" />
		</UserListPopout>
	)
}

export const MessageStatus = props => {
	return <StreamMessageStatus {...props} MessageReadStatus={MessageReadStatus} />
}
