import {PlanningShift} from 'models'

export const useTotalShiftsPerTeam = ({team_ids, dateRange, ...rest}) => {
	const allShifts = PlanningShift.where({team_id: team_ids, date: dateRange, ...rest})
	let shiftsPerTeam = _.groupBy(allShifts, 'team_id')
	return {shiftsPerTeam, allShifts}
}

export const usePublishedShiftPerTeam = props => {
	return useTotalShiftsPerTeam({...props, published: true})
}

export const useUnpublishedShiftPerTeam = props => {
	return useTotalShiftsPerTeam({...props, published: false})
}
