import {useFormField} from '@eitje/form'
import {Icon} from '@eitje/web_components'
import React, {useState, Fragment} from 'react'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {makeRegisteredField} from '@eitje/form'

const translate = item => t(item, item)

const DefaultTrigger = ({value, buildPlaceholder = translate, buildTriggerLabel = translate, placeholder = '...'}) => {
	const label = utils.exists(value) ? buildTriggerLabel(value) : buildPlaceholder(placeholder)
	return <h5 className="eitje-default-trigger"> {label} </h5>
}

// const components = {
//   DatePicker: RawDatePicker,
//   TimePicker: RawTimePicker,
// }

// we cant use this due to issue with name disappearing if its a HOC

const FormTrigger = props => {
	const [elementV, setElementV] = useState(false)
	const {Trigger = DefaultTrigger, triggerProps = {}, Element, placeholder, elementProps = {}, ...rest} = props
	const {error, disabled} = useFormField(props)
	return (
		<Fragment>
			<div
				className={utils.makeCns('trigger-wrapper', disabled && 'disabled', error && 'error')}
				fieldWrapper
				onMouseEnter={() => !disabled && setElementV(true)}
				onMouseLeave={() => !disabled && setElementV(false)}
			>
				<Trigger placeholder={placeholder} {...triggerProps} {...rest} />
				<Icon name="chevron-small-down" />
				{elementV && <Element {...rest} {...elementProps} open />}
				{/*{error && error}*/}
				{/* We're hiding the error above due to stylingissues, for now we'll just stick with a red border. */}
			</div>
		</Fragment>
	)
}

export const NewFormTrigger = makeRegisteredField(FormTrigger)
export default FormTrigger //makeField(FormTrigger, {className: 'eitje-form-trigger', withLabel: false})
