import {useEnvSettingGroup} from 'hooks/use_env_setting'
import {useWhere, useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {useHasRole} from 'hooks'
import useShared from 'hooks/use_shared'
import {planningEmojis} from 'cores/planning/constants'

const availableStatuses = ['available', 'partly_available']
const unavailableStatuses = ['unavailable']
const improdStatuses = ['sick', 'leave_accepted']

const getPlanningStatus = (avShift, shift) => {
	let {status, avStatus} = avShift
	if (status == 'leave_asked') return 'pending_improductive'
	if (shift && avShift.status == 'partly_available') return shift.dateRange().overlaps(avShift.dateRange) ? 'unavailable' : 'available'
	if (availableStatuses.includes(status)) return 'available'
	if (unavailableStatuses.includes(status)) return 'unavailable'
	if (improdStatuses.includes(status)) return 'improductive'
}

export const useAvParts = ({date, user_ids, envId}) => {
	const avShifts = useWhere('availabilityShifts', {date, user_id: user_ids})
	const leaveRequests = useWhere('leaveRequests', {user_id: user_ids, dateRange: date, denied: false})
	const sickPeriods = useWhere('sickPeriods', {user_id: user_ids, dateRange: date})
	const planShifts = useWhere('planningShifts', {user_id: user_ids, date})
	const avSettings = useEnvSettingGroup('beschikbaarheid', envId)

	return {avShifts, leaveRequests, sickPeriods, avSettings, planShifts}
}

export const filterAvParts = ({sickPeriods, leaveRequests, avShifts, planShifts, userId}) => {
	const sickPeriod = sickPeriods.find(p => p.user_id == userId)
	const leaveRequest = leaveRequests.find(l => l.user_id == userId)
	const userAvShifts = avShifts.filter(avUser => avUser.user_id == userId)
	const userPlanShifts = planShifts.filter(p => p.user_id == userId)
	return {sickPeriod, leaveRequest, userAvShifts, userPlanShifts}
}

export const useAvShifts = ({date, user_ids, envId, shift}) => {
	const {avShifts, leaveRequests, sickPeriods, avSettings, planShifts} = useAvParts({date, user_ids, envId})
	if (!user_ids) return []
	const data = user_ids.map(u => {
		const {sickPeriod, leaveRequest, userAvShifts, userPlanShifts} = filterAvParts({
			sickPeriods,
			leaveRequests,
			avShifts,
			planShifts,
			userId: u,
		})

		const avShift = buildAvShift({avSettings, date, avShifts: userAvShifts, sickPeriod, leaveRequest})
		const planningStatus = userPlanShifts.length > 0 ? 'scheduled' : getPlanningStatus(avShift, shift)
		return {...avShift, user_id: u, planningStatus} // shouldn't we just merge this with underneath logic?
	})

	return data
}

export const buildAvShift = ({leaveRequest = {}, date, sickPeriod, avSettings, avShifts}) => {
	sickPeriod = utils.alwaysDefinedArray(sickPeriod)
	let prettified
	if (avShifts.length == 0) avShifts = [defaultAv(date, avSettings.default)]
	const prettifiedAv = prettifyShifts(avShifts)
	if (sickPeriod.length > 0) {
		prettified = {status: 'sick', secondaryStatus: prettifiedAv.status, tooltipText: t('sickSince', {date: sickPeriod[0].start})}
	} else if (leaveRequest.id) {
		prettified = prettifyLeave(leaveRequest, avShifts)
	} else {
		prettified = prettifyShifts(avShifts)
	}

	return prettified
}

export const hasConflict = (planShifts, avShift, avShifts = []) => {
	// Open shifts can never be conflicts, since there is no user availability to conflict with.
	if (planShifts && utils.alwaysArray(planShifts).every(shift => !shift.user_id)) return

	const {status} = avShift
	const needsOverlapCheck =
		status == 'partly_available' || ((status == 'leave_accepted' || status == 'leave_asked') && avShift.start_datetime)
	if (needsOverlapCheck) return hasOverlap(planShifts, avShift)

	return status == 'unavailable' || status == 'sick' || status == 'leave_accepted' || status == 'leave_asked'
}

const useBaseAvShift = (query, {kind = 'availabilityShifts', planShifts, isPlanning, envId} = {}) => {
	planShifts = utils.alwaysDefinedArray(planShifts)
	const {user_id, date} = query
	let avShifts = useWhere(kind, query)
	const leaveRequest = useFind('leaveRequests', {user_id, dateRange: date, denied: false})
	const sickPeriod = useFind('sickPeriods', {user_id, dateRange: date})
	const avSettings = useEnvSettingGroup('beschikbaarheid', envId)
	const prettified = buildAvShift({leaveRequest, date, sickPeriod, avSettings, avShifts})

	if (planShifts.length > 0) {
		prettified.secondaryStatus = 'scheduled'
		prettified.conflict = hasConflict(planShifts, prettified, avShifts)

		if (prettified.conflict) {
			if (prettified.tooltipText) prettified.tooltipText += '\n'

			if (!prettified.tooltipText) prettified.tooltipText = ''
			if (!isPlanning) {
				const planShift = planShifts[0]
				const {end_date, start_date} = planShift
				prettified.tooltipText += t('fromTillTime', {kind: 'scheduled', from: start_date, till: end_date})
			} else {
				prettified.tooltipText += getAvTooltipText(prettified)
			}
		}
	}

	return prettified
}

export const usePlanAvShift = (query, opts = {}) => {
	const {envId} = useShared()
	const hasManagerRole = useHasRole('manager', opts.envId || envId)
	const avShift = useBaseAvShift(query, {...opts, isPlanning: true})
	const hasConflict = avShift.conflict && (hasManagerRole || avShift.status == 'sick')
	const emoji = planningEmojis[avShift.status]
	return {avShift, hasConflict, emoji}
}

const useAvShift = (query, opts = {}) => {
	const {user_id, date} = query
	const planShifts = useWhere('planningShifts', {user_id, date})
	return useBaseAvShift(query, {...opts, planShifts})
}

const getAvTooltipText = prettified => {
	if (prettified.status == 'unavailable') return t('planning.env_table.day_unavailable')
	const {timeBlocks = []} = prettified
	return timeBlocks.map(({from, till}) => t('fromTillTime', {kind: 'unavailable', from, till})).join('\n')
}
//

const hasOverlap = (planShifts, avShift) => {
	planShifts = utils.alwaysDefinedArray(planShifts)
	const ranges = avShift.dateRanges || [avShift.dateRange]
	return planShifts.some(p => ranges.some(r => r.overlaps(p.dateRange)))
}

const defaultAv = (date, defValue) => ({
	available: defValue == 'available',
	unknown: defValue == 'unknown',
	date,
})

const prettifyLeave = (leaveReq, avShifts = []) => {
	const {start_datetime, end_datetime, startDate, endDate} = leaveReq

	const status = leaveReq.accepted ? 'leave_accepted' : 'leave_asked'
	const avShift = combineAvShifts(avShifts)

	const from = startDate().format()
	const till = endDate().format()
	const avStatus = getStatus(avShift)
	const kind = leaveReq.accepted ? 'leaveTitle' : 'leave_asked'
	const tooltipText =
		leaveReq.kind() == 'few_hours' ? t('fromTillTime', {kind, from: start_datetime, till: end_datetime}) : t('fromTill', {kind, from, till})

	return {
		timeBlocks: avShift.timeBlocks,
		...leaveReq,
		status: status,
		secondaryStatus: avStatus,
		remarks: leaveReq.reden,
		avStatus: getStatus(avShift),
		tooltipText,
	}
}

export const prettifyShifts = shifts => {
	const avShift = combineAvShifts(shifts)
	return avShiftMapper(avShift)
}

export const combineAvShifts = shifts => {
	shifts = utils.alwaysArray(shifts)
	const shift = shifts[0]
	const timeBlocks = _.orderBy(shifts, 'from')
		.map(s => ({from: s.from, till: s.till}))
		.filter(s => s.from)
	return {
		ids: shifts.map(s => s.id),
		timeBlocks,
		isMultiple: timeBlocks.length > 1,
		dateRanges: shifts.map(s => s.dateRange),
		...shift,
	}
}

const avShiftMapper = shift => {
	const status = getStatus(shift)
	return {...shift, status}
}

const getStatus = shift => {
	if (shift.from || shift.isMultiple) return 'partly_available'
	if (shift.unknown) return 'unknown' // change for env default if we'd like to do that
	return shift.available ? 'available' : 'unavailable'
}

export default useAvShift
