import {Layout} from 'common/components'
import {ColorCircle} from 'components/common'

export const ProcessDots = ({currentStep, steps}) => {
	if (!currentStep || !steps) return null
	const shared = {size: 12}
	const pendingAmt = steps - currentStep

	return (
		<Layout gap={8} horizontal="center" className="process-dots">
			{_.times(currentStep, () => (
				<ColorCircle color={Colors.mediumBlue} {...shared} />
			))}
			{_.times(pendingAmt, () => (
				<ColorCircle {...shared} borderColor={Colors.mediumBlue} />
			))}
		</Layout>
	)
}
