export const useBlendRevDaysInForecast = (days, revDays) => {
	const updatedDays = [...days]
	revDays.forEach(day => {
		updatedDays.push({
			...day,
			custom: true,
			amt: day.forecast_amt_in_cents / 100,
			amt_in_cents: day.forecast_amt_in_cents,
		})
	})

	return updatedDays
}
