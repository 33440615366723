import utils from '@eitje/utils'
import {RawCheckbox} from '@eitje/form-fields-web'

import './styles/index.less'

export const CheckboxCard = ({children, classNames, value, onChange, disabled}) => {
	const cardClassName = utils.makeCns('checkbox-card', value && 'checkbox-card-selected', disabled && 'checkbox-card-disabled', classNames)

	return (
		<div className={cardClassName} onClick={() => !disabled && onChange()}>
			<div className="checkbox-card-checkbox-wrapper">
				<RawCheckbox value={value} disabled={disabled} />
			</div>
			<div className="checkbox-card-children">{children}</div>
		</div>
	)
}
