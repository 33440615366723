import {AuthPageLayout, notify} from 'cores/auth'
import {Input} from '@eitje/form-fields-web'
import {EmailInput, PasswordInput} from 'lib/form/fields'
import {backend} from '@eitje/easy_api'
import {useQueryParams} from 'hooks'
import {navigate} from 'components/routing'
import {login} from 'actions/auth'

export const CreateAccount = () => {
	// When a user follows a link from elsewhere on the website (e.g. CTA), show a back button for if he clicked accidentally and is a user, as
	// to prevent him from creating a new env unnecessarily. But if we share this link directly with interested leads (e.g. in Poached, mail or
	// marketing site, don't show the CTA to leave the page).
	const {back_button} = useQueryParams()

	const onSubmit = data => {
		return backend.post(`/create_trial_admin`, {...data, skipSuccessNotif: true})
	}

	const afterSubmit = ({resData, params}) => {
		const {user, confirmed, new_prospect} = resData
		const {email, password} = params
		const encodedEmail = encodeURIComponent(email)

		if (confirmed) {
			notify('known_confirmed_user')
			return navigate(`/login?email=${encodedEmail}`)
		}
		if (user && !new_prospect) {
			notify('known_unconfirmed_user')
			return navigate(`/activate_account?user_id=${user}&email=${encodedEmail}`)
		}
		login(email, password, false)
		navigate('/create_environment')
	}

	return (
		<AuthPageLayout
			name="create_account"
			subtitle
			currentStep={1}
			steps={2}
			showSupport
			onSubmit={onSubmit}
			afterSubmit={afterSubmit}
			backButton={back_button}
		>
			<Input field="voornaam" required />
			<EmailInput />
			<PasswordInput autocomplete="new-password" />
		</AuthPageLayout>
	)
}
