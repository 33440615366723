export const NAMESPACE = 'chat'

export const CHANNEL_TYPES = {
	DIRECT: 'messaging',
	GROUP: 'group',
	TEAM: 'team',
}

export const ATTACHMENT_TRANSKEYS = {
	audio: 'audioFile',
	image: 'image',
	file: 'file',
	video: 'video',
}
