import NewSickPeriod from './new_sick_period'
import CurrentSickPeriods from './current_sick_periods'
import PastSickPeriods from './past_sick_periods'
import './styles/index.less'

const Page = () => {
	return (
		<div className="team-panel-sick">
			<NewSickPeriod />
			<CurrentSickPeriods />
			<PastSickPeriods />
		</div>
	)
}

export default Page
