import {useState} from 'react'
import {useMutationObserver} from './use_mutation_observer'

const observeOptions = {
	childList: true,
	subtree: true,
	attributes: true,
	attributeFilter: ['class'],
}

export const useClassNameObserver = (className, element = document) => {
	const [classNamePresent, setClassNamePresent] = useState()

	const checkForClassName = () => {
		const elements = element.querySelectorAll(`.${className}`)
		setClassNamePresent(elements.length > 0)
	}

	const mutationFunction = mutation => {
		if (mutation.type === 'childList' || mutation.type === 'attributes') {
			checkForClassName()
			return
		}
	}

	useMutationObserver({mutationFunction, observeOptions, dependency: className})

	return classNamePresent
}
