import React, {useEffect, useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import {date} from 'initializers/date'
import {DatePicker, Button} from 'antd'
import {rangeToDateArray, rangeForMonth} from 'helpers/date'
import {t} from 'initializers/i18n'
import {Layout} from 'common/components'

const MonthPicker = ({onChange, initialValue = date(), ...props}) => {
	let [date, setDate] = useState(initialValue.startOf('month'))
	date = date.clone()
	const range = rangeForMonth({date})

	const makeAndSetDates = () => {
		const dateArr = rangeToDateArray(range)
		onChange(dateArr)
	}

	useEffect(() => {
		makeAndSetDates()
	}, [date.format('YYYY-MM-DD')])

	const today = date()
	const isToday = range.contains(today)

	const TodayButton = () => {
		return <p onClick={() => setDate(today)}> {t('today')} </p>
	}

	return (
		<Layout>
			<Button onClick={() => setDate(date.subtract(1, 'month'))}>{'<'}</Button>
			<PopoutCard hidden={isToday} body={TodayButton}>
				<DatePicker format="MMM 'YY" value={date} {...props} onChange={setDate} picker="month" />
			</PopoutCard>
			<Button onClick={() => setDate(date.add(1, 'month'))}>{'>'}</Button>
		</Layout>
	)
}

export default MonthPicker
