import {Placeholder as PlaceholderComponent} from '@eitje/web_components'
import {SupportButton, TourButton, VideoButton} from 'common/components'
import {DocsButton} from '../docs_button'

export const Placeholder = props => {
	const {supportButton, docsId, tourId, videoId, collection} = props
	const customButtons = [
		docsId && <DocsButton iconLeft="external-link" to={docsId} collection={collection} />,
		tourId && <TourButton tourId={tourId} />,
		videoId && <VideoButton videoId={videoId} />,
		supportButton && <SupportButton />,
	]

	return <PlaceholderComponent customButtons={customButtons} {...props} />
}
