import {Layout} from 'common/components'

const FormRow = ({children}) => {
	return (
		<Layout childrenEqual className="eitje-form-2-row" gap={0} vertical="start">
			{children}
		</Layout>
	)
}

export default FormRow
