import {createCachedSelector} from 're-reselect'

const anaSelector = state => state.arbitrary.orgAnalytics

export const analyticsSelector = createCachedSelector(
	anaSelector,
	(state, id) => id,
	(data, id) => makeAnaObject(data, id),
)((state, id) => Math.random())

const makeAnaObject = (data, id) => {
	const obj = {}
	Object.keys(data).map(key => (obj[key] = data[key][id] || 0))

	return obj
}
