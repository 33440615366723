import {all, API, find} from '@eitje/easy_api'
import {getTraining} from 'actions/training'
import useList from 'components/general/list'
import {ModalLink} from 'components/routing'
import {BoxedRow} from 'components/ui'
import {getImage} from 'helpers/images'
import useTeamFilter from 'hooks/use_team_filter'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {anyEnvRoleSelector} from 'selectors/new_settings'
import {hasTrainingResource, topicIsTrainable, usersCompletedTraining} from 'selectors/training'
import {LooseArticlesContainer} from '../articles/components'
import '../styles/topics.less'
import {SectionHeader, Tile, Training} from './components'
import {useTeamTitle} from 'hooks/index'
import Header from './header'
import {Label, PageLayout} from 'common/components'
import EitjeButton from 'common/components/eitje_button'

const newTopicButton = <EitjeButton modalLink="/forms/topics" t="createTopic" />

const appendChild = (
	<ModalLink to="/forms/topics">
		<div className="topic-tile-container placeholder">
			<p className="eitje-text-secondary bold">+ {t('createTopic')}</p>
		</div>
	</ModalLink>
)

const Topics = () => {
	const allTopics = useSelector(state => all(state, 'topics'))
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager'))

	const topics = _.orderBy(allTopics, [item => item.title.toLowerCase()], ['asc']).sort((x, y) =>
		x.published === y.published ? 0 : x.published ? -1 : 1,
	)

	const searchProps = {
		searchField: ['title', 'subtitle'],
		placeholder: t('searchTopics'),
	}

	useEffect(() => {
		getTraining()
		API.index('topics')
		API.index('infos')
	}, [])

	const placeholderProps = {
		name: 'theme',
		animation: 'pull-ups',
		docsId: '6009233',
		children: isManager && <EitjeButton modalLink="/forms/topics" colorSet="solid" iconLeft="plus" t="action_button" />,
		size: 'default',
	}

	const {filter, items} = useTeamFilter(topics, {placeholder: t('filterOnTeams'), rowContainerStyle: {width: '180px'}})

	const {list, searchInput} = useList({
		items,
		appendChild: isManager && appendChild,
		hasSearch: true,
		searchProps,
		ListItem: Topic,
		placeholderProps,
	})

	return (
		<PageLayout name="topics" bodyLayoutProps={{block: true}} headers={<Header filter={filter} searchInput={searchInput} />}>
			<Training />

			<div className="fRow jCen">
				<div className="topic-container">
					<SectionHeader head={t('allTopics')} subText={t('allTopicsDescrip')} button={isManager && newTopicButton} />
					<div className="topics-list">{list}</div>
					<LooseArticlesContainer />
				</div>
			</div>
		</PageLayout>
	)
}

const inputboxProps = {
	img: '/images/web/icons/powerIcon.png',
}

const getTrainingDateComponent = ({id, userDoneIds = [], valid_untill, starts_at}) => {
	if (userDoneIds.includes(id)) return <BoxedRow text={t('completed')} />
	if (valid_untill) {
		const format = date(valid_untill).format(`Do MMM 'YY`)
		return <BoxedRow text={date(valid_untill).isBefore(Date.now()) ? `-` : format} />
	}
	if (starts_at) {
		const text = date(starts_at).isBefore(Date.now()) ? 'Next in line' : `${t('trainingStarts')} ${date(starts_at).format(`DD MMM 'YY`)}`
		return <BoxedRow disabled text={text} />
	}
	return <BoxedRow {...inputboxProps} text={`-`} />
}

const TopicDateBox = ({id}) => {
	const {valid_untill} = useSelector(state => find(state, 'topics', id))
	const {starts_at, trainable} = useSelector(state => hasTrainingResource(state, id)) || {}
	const userDoneIds = useSelector(usersCompletedTraining) || []

	return getTrainingDateComponent({id, userDoneIds, valid_untill, starts_at, trainable})
}

const Topic = ({item}) => {
	const {title, subtitle, id, team_ids, published} = item
	const _img = getImage(item, 'medium') || '/images/placeholder_manual_img.png'
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager', item.environment_ids))
	const teamTitle = useTeamTitle(team_ids)
	const {trainable} = useSelector(state => topicIsTrainable(state, id)) || {}

	const trainButton = trainable && <EitjeButton width="full" colorSet="solid" modalLink={`/topics/${id}/train`} t="train" />

	return (
		<Link to={`/topics/${id}`}>
			<div style={{width: 'fit-content', position: 'relative'}}>
				{!published && (
					<div style={{position: 'absolute', zIndex: '2', top: '126px', left: '12px'}}>
						<Label baseColor="blue" t="draft" colorSet="solid" />
					</div>
				)}
				<Tile link imgSrc={_img} title={title} subtitle={subtitle} small>
					<div className="column full-width gap-xss">
						{isManager && (
							<>
								<BoxedRow text={teamTitle} img={'/images/web/icons/eye-view-icon.png'} />
							</>
						)}
						{trainButton || <TopicDateBox id={id} />}
					</div>
				</Tile>
			</div>
		</Link>
	)
}

export default Topics
