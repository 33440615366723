const {NimblePicker} = require('emoji-mart')
import {makeField} from '@eitje/form'
import {PopoutCard, Icon} from '@eitje/web_components'

import data from 'emoji-mart/data/apple.json'
import {useRef} from 'react'
import './styles/emoji_picker.less'

const Trigger = ({value}) => {
	return (
		<>
			<div className="emoji-picker-trigger">
				<p>{value || '-'}</p>
			</div>
			<Icon className="emoji-arrow-down" name="chevron-small-down" fill={Colors.black} />
		</>
	)
}

const Element = ({onChange, hide, ...rest}) => {
	const handleSelect = ({native}) => {
		onChange(native)
		hide?.()
	}
	return <NimblePicker data={data} onSelect={handleSelect} {...rest} />
}

const Picker = props => {
	const popperRef = useRef()
	const {value} = props
	const hide = popperRef.current?.hide
	return (
		<PopoutCard className="emoji-picker" ref={popperRef} trigger="click" body={<Element hide={hide} {...props} />}>
			<Trigger value={value} />
			{value && <p onClick={() => props.onChange(null)}> x </p>}
		</PopoutCard>
	)
}

export const EmojiPicker = makeField(Picker, {withIcon: false, withLabel: false})
