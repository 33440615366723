import React, {useEffect} from 'react'
import Modal from 'components/general/modal'
import utils from '@eitje/web_utils'
import {t} from 'cores/integrations/index'
import {lightspeedKUrl, lightspeedKId} from 'initializers/api'
import {store} from 'index'
import {isProd} from 'constants/general'
import useQueryParams from 'hooks/use_query_params'

const redirectUri = !isProd ? `https://webtest.eitje.app/oauth/lightspeed-k` : `https://web.eitje.app/oauth/lightspeed-k`

export * from './activated'

export const ActivateLightspeedK = () => {
	const {connection_id} = useQueryParams()
	debugger
	useEffect(() => {
		const state = utils.randomId()
		const url = `${lightspeedKUrl}?response_type=code&client_id=${lightspeedKId}&scope=financial-api&state=${state}&redirect_uri=${redirectUri}`
		store.dispatch({type: 'SET_LIGHTSPEED_STATE', payload: state, connection_id})

		window.location.replace(url)
	}, [])

	return (
		<Modal className="integration-redirect-modal">
			<img className="integration-redirect-image" src="/images/integrations/lightspeed_redirect.png" />
			<h3 className="integration-redirect-title"> {t('oauth_redirect.title')} </h3>
			<h5 className="integration-redirect-text"> {t('oauth_redirect.subtitle')} </h5>
		</Modal>
	)
}

export default ActivateLightspeedK
