import {API} from '@eitje/easy_api'
import {history} from 'index'
import TopicForm from './form'

const Edit = ({item, closeModal}) => {
	const initialValues = {...item, remote_avatar_url: item.avatar}

	const onDel = async () => {
		const res = await API.destroy('topics', item.id)
		if (res.ok) history.goBack()
	}
	return <TopicForm useSubmitStrategy initialValues={initialValues} edit onDel={onDel} close={closeModal} />
}

export default Edit
