import Modal from 'components/general/modal'
import {Redirect} from 'components/routing'
import useList from 'hooks/use_list'
import {t, useErroredShifts} from 'cores/time_registration/index'
import {t as baseT} from 'initializers/i18n'
import ErrorRow from './error_row'
import './styles/index.less'

const searchProps = {
	searchField: 'user.full_name',
}

export const HandleErrors = () => {
	const items = useErroredShifts()
	const {list, searchInput} = useList({items, ListItem: ErrorRow, searchProps})
	if (items.length == 0) return <Redirect to="/time_registration/per_week" />
	return (
		<Modal hasCancel wrapClassName="registration-errors-modal" width={680}>
			<Header searchInput={searchInput} />
			<h5 className="registration-errors-description"> {t('handle_errors.description', {count: items.length})} </h5>
			{list}
		</Modal>
	)
}

const Header = ({searchInput}) => {
	return (
		<div className="registration-errors-header">
			<h3>{baseT('handle_errors.title')} </h3>
			{searchInput}
		</div>
	)
}
