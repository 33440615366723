import {useAsyncEffect} from 'hooks'
import {useEffect, useState} from 'react'
import {useChatContext} from 'stream-chat-react'
import {getOtherChannelMembers} from 'cores/chat'

const CHANNEL_SUBSCRIBER_EVENTS = ['member.added', 'member.removed', 'member.updated']
const CLIENT_SUBSCRIBER_EVENT = 'user.presence.changed'

export const useChannelMembers = channel => {
	const {client} = useChatContext()
	const [channelState, setChannelState] = useState({
		members: [],
		onlineMemberCount: null,
	})

	// in order for the client to receive updates for user presence,
	// ensure that we are watching the channel
	useAsyncEffect(async () => {
		if (channel) {
			await channel.watch({presence: true})
		}
	}, channel)

	useEffect(() => {
		let isSubscribed = true

		const updateChannelUsers = async event => {
			// check if the updated user is a member of this channel
			const memberRemovedEvent = event?.type === 'member.removed'
			const eventUserIsChannelMember = channel.state.members[event?.user.id] !== undefined

			if (!event || memberRemovedEvent || eventUserIsChannelMember) {
				try {
					const meId = client.userID
					const members = await getOtherChannelMembers(channel, meId)
					const onlineMemberCount = members.filter(({user}) => user.online).length

					isSubscribed && setChannelState({members, onlineMemberCount})
				} catch (err) {}
			}
		}

		if (!(client && channel)) return

		updateChannelUsers()
		const channel_subscriptions = CHANNEL_SUBSCRIBER_EVENTS.map(e => channel.on(e, updateChannelUsers))
		const client_subscription = client.on(CLIENT_SUBSCRIBER_EVENT, updateChannelUsers)

		return () => {
			isSubscribed = false
			channel_subscriptions.map(sub => sub.unsubscribe())
			client_subscription.unsubscribe()
		}
	}, [client, channel])

	return channelState
}
