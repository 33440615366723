import {t} from 'initializers/i18n'
import {EitjeButton} from 'common/components'
import {NAMESPACE} from 'cores/chat'

const styleProps = {
	width: 46,
	height: 'full',
}

export const SendButton = ({disabled, sendMessage}) => (
	<EitjeButton
		disabled={disabled && t(`${NAMESPACE}.send_button_disabled`)}
		onClick={sendMessage}
		colorSet="solid"
		iconLeft="paper-plane"
		margin="- - - 4"
		{...styleProps}
	/>
)

export const FileUploadButton = () => (
	<EitjeButton colorSet="color-bordered-outline" iconLeft="paperclip" margin="- 4 - -" {...styleProps} />
)
