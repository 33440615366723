import {UserRowPanel, usePageContext, ContractHoursCellItem} from 'common/components'
import {useUserContext} from 'contexts/user'
import {MultiActionCheckbox, useEditableShifts, usePerWeekContext} from 'cores/time_registration'
import utils from '@eitje/web_utils'
import {PanelDropdown} from './panel_dropdown'
import {useFind} from '@eitje/easy_api'

export const Panel = ({...rest}) => {
	const {multiEdit} = usePageContext()
	const {shifts, user} = useUserContext()
	const [totalPlanned, totalWorked] = _.partition(shifts, s => s.isPlan())
	const namespace = 'time_registration.per_week.user_row'
	const format = shifts => utils.minutesToString(shifts.sum(s => s.totalMins()))
	const {dateRange} = usePerWeekContext()
	const workSchedule = useFind('workSchedules', {user_id: user.id, dateRange})
	const shared = {type: 'greySubtitle', namespace}

	const items = [
		{value: format(totalWorked), localValue: 'hoursUserWorked', ...shared},
		{value: format(totalPlanned), localValue: 'hoursUserPlanned', ...shared},
		workSchedule.id && <ContractHoursCellItem workSchedule={workSchedule} localValue="hoursContractHours" {...shared} />,
	]

	return (
		<UserRowPanel
			multiEdit={multiEdit}
			PanelDropdown={PanelDropdown}
			useEditableShifts={useEditableShifts}
			MultiActionCheckbox={MultiActionCheckbox}
			{...rest}
			items={items}
		/>
	)
}
