import utils from '@eitje/web_utils'

export const BoxedRow = ({img, text, label, disabled, hidden, dropdown, title, shadow, className, containerClass, ...rest}) => {
	const _text = text || title
	const classNames = utils.makeCns('boxed-row', dropdown && 'dropdown', shadow && 'shadow', containerClass, disabled && 'box-disabled')
	return (
		<div className={classNames}>
			{img && <img src={img} className="boxed-row-icon" />}
			{/*{label && label}*/}
			{/* this fucks up styling now, but is necessary to also allow DD list select to have both a label & a placeholder*/}
			<p className={`boxed-row-text ${className}`}>{_text}</p>

			{!hidden && <img className="boxed-row-dropdown-icon" src={'/images/web/icons/downArrow.png'} />}
		</div>
	)
}
// Rounded row allows for my variation in style, box row is meant to mimic a button
export const RoundedRow = ({img, text, dropdown, title, shadow, containerClass, disabled, style = {}}) => {
	const _text = text || title
	const className = utils.makeCns('rounded-row', dropdown && 'dropdown', shadow && 'shadow', containerClass, disabled && 'rounded-disabled')
	return (
		<div className={className} style={style}>
			{img && <img src={img} className="rounded-row-icon" />}

			<p className="rounded-row-text">{_text}</p>

			<img className="rounded-row-dropdown-icon" src={'/images/web/icons/downArrow.png'} />
		</div>
	)
}
