import {makeLegacyField, makeField} from '@eitje/form'
import {EasyRemoveArea} from '@eitje/web_components'
import useHotkey from 'hooks/use_hotkey'
import React, {useState} from 'react'
import {LegacyInput as Input, BaseInput} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {AddButton} from 'common/components'

// the diff between those 2 components is that the above is used in combination with the old form and below is used with context form

let MultiTextInput = props => {
	const {onChange = _.noop, isValid = e => true, value = [], placeholder} = props
	const [text, setText] = useState('')
	const add = () => {
		if (text) {
			const entries = parseText(text)
			const validEntries = entries.filter(e => isValid(e))
			const invalidEntries = entries.filter(e => !isValid(e)).filter(e => utils.exists(e))
			if (invalidEntries.length > 0)
				utils.errorNotif({content: t('form.validation.multi_email_input.invalid_email', {emails: invalidEntries.join(', ')})})
			onChange([...value, ...validEntries])
			setText(invalidEntries.join(','))
		}
	}

	const onKeyDown = useHotkey({enter: add})

	return (
		<div>
			<Input
				onKeyDown={onKeyDown}
				value={text}
				onChange={setText}
				suffix={<AddButton height="small" onClick={add} />}
				placeholder={placeholder}
			/>
			<EasyRemoveArea action={newVal => onChange(value.filter(v => v != newVal))} items={value} />
		</div>
	)
}

let NewMultiTextInput = props => {
	const {onChange = _.noop, isValid = e => true, value = [], placeholder} = props
	const [text, setText] = useState('')
	const add = () => {
		if (text) {
			const entries = parseText(text)
			const validEntries = entries.filter(e => isValid(e))
			const invalidEntries = entries.filter(e => !isValid(e)).filter(e => utils.exists(e))
			if (invalidEntries.length > 0)
				utils.errorNotif({content: t('form.validation.multi_email_input.invalid_email', {emails: invalidEntries.join(', ')})})
			onChange([...value, ...validEntries])
			setText(invalidEntries.join(','))
		}
	}

	const onKeyDown = useHotkey({enter: add})

	return (
		<div>
			<BaseInput
				onKeyDown={onKeyDown}
				value={text}
				onChange={setText}
				suffix={<AddButton height="small" onClick={add} />}
				placeholder={placeholder}
			/>
			<EasyRemoveArea action={newVal => onChange(value.filter(v => v != newVal))} items={value} />
		</div>
	)
}

const parseText = text => {
	return text.split(/,| /)
}

MultiTextInput = makeLegacyField(MultiTextInput, {className: 'eitje-multi-text-input'})
export const FormMultiTextInput = makeField(NewMultiTextInput, {className: 'eitje-multi-text-input', withIcon: false})
export default MultiTextInput

export const MultiEmailInput = props => (
	<MultiTextInput placeholder={t('form.placeholder.multi_email_input')} {...props} isValid={utils.isEmail} />
)

export const FormMultiEmailInput = props => (
	<FormMultiTextInput placeholder={t('form.placeholder.multi_email_input')} {...props} isValid={utils.isEmail} />
)
