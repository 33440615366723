import {useUserContext} from '../index'
import {dropdownElements, Subline} from './components'
import {UserHoverCard} from 'components/shared/index'
import '../../../styles/availability.less'
import './index.less'

const UserCard = () => {
	const {user} = useUserContext()
	const els = dropdownElements()
	const weekShiftIdx = els.length - 1
	const dropdownProps = {visibleKeysAfterClick: weekShiftIdx}
	return (
		<div className="users-availability-table-row-first-cell">
			<UserHoverCard dropdownProps={dropdownProps} user={user} Subline={Subline} dropdownElements={els} />
		</div>
	)
}

export default UserCard
