import useSelector from 'hooks/use_selector'
import {date} from 'initializers/date'
import {makeRange, makeYearRange} from 'helpers/date'
import {store} from 'index'

const defaultStartDate = date().startOf('month').format()
const defaultEndDate = date().endOf('month').format()
const defaultYear = date().year()
export const useLeaveDates = () => {
	const setPeriod = (period, props) => {
		store.dispatch({type: 'SET_LEAVE_PERIOD', period, ...props})
	}
	const state = useSelector(state => state.leave) || {}
	let {startDate, endDate = defaultEndDate, year, period = 'future'} = state
	if (!year) year = defaultYear

	if (!startDate) startDate = defaultStartDate
	if (!endDate) endDate = defaultEndDate

	const dateRange = makeDateRange({startDate, endDate, year, period})

	return {setPeriod, dateRange, period, startDate: dateRange.start, endDate: dateRange.end, year}
}

const makeDateRange = ({startDate, endDate, year, period}) => {
	if (period == 'custom') return makeRange(startDate, endDate)
	if (period == 'present') return makeRange(date().startOf('day'), date().endOf('day'))
	if (period == 'future') return makeRange(date().add(1, 'day'), null)
	if (period == 'past') return makePastRange(year)
}

const makePastRange = year => {
	if (year == defaultYear) return makeRange(date().startOf('year'), date().subtract(1, 'day'))
	return makeYearRange(year)
}
