import {ModalLink} from 'components/routing'
import {useEnvsContext, usePlanningContext} from 'cores/planning/index'
import {TimeSettingsLink} from 'cores/time_registration'
import {filterActive} from 'helpers'
import {Text, ToolsButton} from 'common/components'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {t} from 'initializers/i18n'
import {useRoleMessage} from 'hooks'

const NAMESPACE = 'planning.environment_table.tools_buttons'

export const Tools = props => {
	const {startDate, endDate} = usePlanningContext()
	const {setMultiEdit} = useEnvsContext()
	const {
		filteredItems,
		hasFiltered,
		envs,
		filterValues: {teamIds},
	} = useFilterFooterContext()
	const disabled = useRoleMessage({roles: 'manager', env: envs})
	const state = {start_date: startDate.format(), end_date: endDate.format()}
	const queryParams = {team_ids: teamIds, ...filterActive(hasFiltered.team)}

	let elements = []
	if (!disabled) {
		elements = [
			editShifts(setMultiEdit),
			loadTemplates(queryParams),
			saveTemplate(filteredItems, queryParams),
			manageTemplates(queryParams),
			sick(),
			weekOverview(),
			clearWeek(queryParams),
			calendar(),
			exportPlanning(state),
			timeSettings(),
		]
	}

	return <ToolsButton {...props} disabled={disabled} elements={elements} />
}

////////////////////////
// Make reusable dropdown items
// Separate them so we can cherry pick what we need and we may easily change the order.
////////////////////////

export function exportPlanning(state) {
	return {
		icon: 'document-blank',
		element: <ModalLink to="/exports?filter=planning" state={state} t={`${NAMESPACE}.export_planning`} />,
	}
}

export function calendar() {
	return {
		icon: 'calendar',
		element: <ModalLink to="/forms/events" t={`${NAMESPACE}.add_event`} />,
	}
}

export function timeSettings() {
	return {
		icon: 'cog-wheel',
		element: <TimeSettingsLink />,
	}
}

export function loadTemplates(queryParams) {
	return {
		icon: 'download',
		element: <ModalLink to="/planning_templates/load" queryParams={queryParams} t={`${NAMESPACE}.load_template`} />,
	}
}

export function manageTemplates(queryParams) {
	return {
		icon: 'copy',
		element: <ModalLink to="/planning_templates" t={'planning.save_template.edit_template'} queryParams={queryParams} />,
	}
}

export function sick() {
	return {
		icon: 'bed',
		element: <ModalLink to="/planning/team_panel/sick" t={`${NAMESPACE}.sick_overview`} />,
	}
}

export function weekOverview() {
	return {
		icon: 'person',
		element: <ModalLink to="/planning/team_panel/balance" t={`${NAMESPACE}.week_overview`} />,
	}
}

export function editShifts(setMultiEdit) {
	return {
		icon: 'pencil',
		element: <Text onClick={() => setMultiEdit(true)} t="planning.multi_edit.title" />,
	}
}

export function saveTemplate(filteredItems, queryParams) {
	return {
		icon: 'floppy',
		element: <ModalLink to="/planning_templates/save" queryParams={queryParams} t={`${NAMESPACE}.save_template`} />,
		disabled: filteredItems.length == 0 && t('planning.save_template.disabled'),
	}
}

export function clearWeek(queryParams) {
	return {
		icon: 'trash',
		element: <ModalLink to="/planning/clear_week" queryParams={queryParams} t={`${NAMESPACE}.clear_week`} />,
	}
}
