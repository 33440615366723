import {all, where} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {totalDays, totalMins} from 'helpers/schedule'
import pluralize from 'pluralize'
import {useSelector} from 'react-redux'
import {activeOnToday, filterRangeByDate, whereInc} from 'selectors/records'

const filterObjOfArrs = (obj, filterFunc) => {
	const copy = {...obj}
	Object.keys(obj).forEach(key => {
		copy[key] = obj[key].filter(el => filterFunc(el))
	})
	return copy
}

export const useAllActiveContracts = users => {
	const {employmentTypes, ...allParts} = useParts()
	const allHolders = useSelector(state => all(state, 'contractHolders'))
	return users.map(u => {
		const userHolder = activeOnToday(allHolders).find(h => h.user_id == u.id)
		if (!userHolder) return {...u, contract: null}
		let userParts = filterObjOfArrs(allParts, val => val.user_id == u.id)
		userParts = filterObjOfArrs(userParts, val => activeOnToday([val]))

		const cont = makeSingleContract(userHolder, {employmentTypes, ...userParts})
		const {salary, userEmpType, employmentType, workSchedule} = cont
		return {...u, contract: cont, salary, userEmpType, employmentType, workSchedule}
	})
}

const enrichSchedules = (schedules, weeks) => {
	return schedules.map(w => {
		const scheduleWeeks = weeks.filter(w2 => w2.work_schedule_id === w.id)

		return {...w, totalMins: totalMins(scheduleWeeks), totalDays: totalDays(scheduleWeeks), workWeeks: _.orderBy(scheduleWeeks, 'position')}
	})
}

const useParts = id => {
	const query = id ? {user_id: id} : {}
	const salaries = useSelector(state => where(state, 'salaries', query))
	const userEmpTypes = useSelector(state => where(state, 'userEmploymentTypes', query))
	let workSchedules = useSelector(state => where(state, 'workSchedules', query))
	const employmentTypes = useSelector(state => all(state, 'employmentTypes'))
	const scheduleIds = workSchedules.map(s => s.id)
	const workWeeks = useSelector(state => whereInc(state, 'workWeeks', {work_schedule_id: scheduleIds}))
	workSchedules = enrichSchedules(workSchedules, workWeeks)

	return {salaries, userEmpTypes, workSchedules, employmentTypes}
}

const useContractParts = id => {
	const query = {user_id: id}
	const holders = useSelector(state => where(state, 'contractHolders', query))
	const obj = useParts(id)
	return {holders, ...obj}
}

export const useContract = holder => {
	const parts = useParts(holder?.user_id)
	return makeContract(holder, parts)
}

export const useSingleContract = holder => {
	const parts = useParts(holder.user_id)
	return makeSingleContract(holder, parts)
}

const useSingleContracts = id => {
	const {holders, ...rest} = useContractParts(id)
	const contracts = holders.map(h => makeSingleContract(h, rest))
	return contracts
}

export const useSingleSalaries = id => {
	const {salaries, ...rest} = useContractParts(id)
	return salaries.map(h => makeSingleContract(h, rest))
}

export const useSingleSchedules = id => {
	const {workSchedules, ...rest} = useContractParts(id)
	return workSchedules.map(h => makeSingleContract(h, rest))
}

// this method is literally copied from selectors/records, called filterRangeByDate there
// this is because mutations have a special logic, namely that its 'tot' and not 't/m'
// A mutation thus isn't active on its end date, but is on its start date.
// lets think how to incorporate this into base method

// const filterMutations = (
//   items,
//   {start_date, end_date} = {},
//   {startField = 'start_date', endField = 'end_date'} = {},
// ) => {
//   const filtered = items.filter(e => {

//     const recordStartDate = e[startField]
//     const recordEndDate = e[endField]

//     //record is salary/schedule/emp_type, query is holder.

//     const startsBeforeEnd = !end_date || recordStartDate < end_date
//     const startsAfterStart = !recordEndDate || recordEndDate > start_date

//     return startsBeforeEnd && startsAfterStart
//   })
//   return filtered
// }

// IN THE END WE HAVE DECIDED TO USE FILTERRANGEBYDATE'S STRICT FEATURE.
// it looks like there are no issues what that, but we'll keep the above method commented out
// untill we're sure there're no issues.
// The only case I can come up with is a contract that's only active for 1 day, which is impossile through the UI, and maybe we should just add a validation that skips the c in integs.

const makeContract = (holder, {employmentTypes, ...others}) => {
	const relevant = _.mapValues(others, o => _.orderBy(filterRangeByDate(o, holder), 'end_date', 'desc'))
	const empTypes = utils.mergeJoinItems(relevant['userEmpTypes'], employmentTypes, 'employment_type_id')
	return {...relevant, employmentTypes: empTypes, ...holder}
}

export function makeSingleContract() {
	const cont = makeContract(...arguments)

	let newCont = _.mapKeys(cont, (v, k) => (_.isArray(v) ? pluralize.singular(k) : k))
	newCont = _.mapValues(newCont, (v, k) => (_.isArray(v) && k != 'workWeeks' ? v[0] : v))
	newCont = {...cont, ...newCont}

	newCont.hours = newCont.workSchedule ? newCont.workSchedule.totalMins / 60 : null
	if (newCont.hours) newCont.uren = newCont.hours
	newCont.amt_werkdagen = newCont.workSchedule?.totalDays
	newCont.contract_id = newCont?.holder?.id || newCont.id
	newCont.employment_type_id = newCont?.employmentType?.employment_type_id
	newCont.uurloon_in_cents = (newCont.salary?.amount || 0) * 100
	const empName = newCont.employmentType?.name
	newCont.typ = empName

	return newCont
}

export default useSingleContracts
