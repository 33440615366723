import {ProtectedRoute, Route} from 'components/private_route'
import {Firms, CreateTeam, CreateEnvironment, UsersInService, UsersOutOfService} from '.'

export const firmRoutes = [
	<ProtectedRoute roleInAnyEnv="manager" path="/environments" component={Firms} />,
	<ProtectedRoute roleInAnyEnv="manager" path="/team/new" component={CreateTeam} />,
	<ProtectedRoute roleInAnyEnv="admin" path="/environment/new" component={CreateEnvironment} />,
]

export const userForegroundRoutes = [
	<Route path="/users/in_service" component={UsersInService} />,
	<ProtectedRoute roleInAnyEnv="manager" path="/users/out_of_service" component={UsersOutOfService} />,
]
