import moment from 'moment'
import momentTz from 'moment-timezone'
import {createMoment as date} from 'helpers/date'

moment.defaultFormat = 'YYYY-MM-DD'

export const setTimezone = (tz = 'Europe/Amsterdam') => {
	if (tz == 'Amsterdam') tz = 'Europe/Amsterdam'
	momentTz.tz.setDefault(tz)
}

export {date}
export * from 'helpers/date'
export default date
