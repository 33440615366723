import {ModalForm} from 'components/form/index'
import {useModalContext} from 'components/common/index'
import {ListPicker, goToNext} from 'common/components'
import {FormRow} from 'components/ui'

const items = ['only_paid', 'registered']

export const Settings = props => {
	const {item} = useModalContext()

	const submit = data => {
		const only_paid = data['bill_type'] == 'only_paid'
		return item.update({only_paid})
	}

	const initialValues = {bill_type: item.only_paid ? 'only_paid' : 'registered'}

	return (
		<ModalForm afterSubmit={() => goToNext(props)} transNamespace="setup_revenue.settings" onSubmit={submit} initialValues={initialValues}>
			<FormRow>
				<ListPicker hideClearIcon dropdown single field="bill_type" items={items} />
			</FormRow>
		</ModalForm>
	)
}
