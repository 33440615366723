import ArticleEditor from 'lib/redactor/article-editor.js'


// 

ArticleEditor.add('plugin', 'list-plugin', {
        start: function() {
            this.app.toolbar.add('list-plugin', {
                title: 'List',
                icon: '<img src="/images/listIconRedactor.png" style="width: 16px; height: 16px"/>',
                command: 'list-plugin.popup'
            });
        },
        popup: function(params, button) {
            // create
            this.app.popup.create('list-plugin', {
                items: {
                    item1: {
                        title: 'Ordered list',
                        icon: '<img src="/images/orderedlistIconRedactor.png" style="width: 17px;height: 15px;margin-top: -5px;"/>',
                        command: 'block.format',
                        params: {tag: 'ol'}
                    },
                    item2: {
                        title: 'Unordered list',
                        icon: '<img src="/images/listIconRedactor.png" style="width: 17px;height: 15px;margin-top: -5px;"/>',
                        command: 'block.format',
                        params: {tag: 'ul'}
                    }
                }
            });

            // open
            this.app.popup.open({ button: button });
        },
        toggle: function(params, button, name) {
            this.app.popup.close();

            alert('Item "' + name + '" is toggled!');
        }
    });


// ArticleEditor.add('plugin', 'list-plugin', {
//        start: function() {

//            this.app.addbar.add('ol', {
//                title: 'Ordered list',
//                icon: '<p>Ordered list </p>',
//                command: 'list-plugin.addEl',
//            });

//            this.app.addbar.add('ul', {
//             title: 'Ordered list',
//             icon: '<p>Unordered list </p>',
//             command: 'list-plugin.addEl',
//            })
//        },

//        addEl: function(params, button, name) {
//         this.app.popup.close();
//         if(!this.app.block.get()) {
//           const source = this.dom("<ul>")
//           const newBlock = this.app.create(`block.list`, source)
//           this.app.block.add({instance: newBlock, caret: 'start'})
//         } else {
//           this.app.block.format({ tag: name });
//         }
//        }
     
//    });

// Dropdown btn exxample


// CONTROL EXAMPLE

// ArticleEditor.add('plugin', 'controlbtns', {
//        start: function() {
//            this.app.control.add('mybutton', {
//                title: 'My Button',
//                icon: '<i class="far fa-compass"></i>',
//                command: 'block.format',
//                params: {tag: 'ul'}
//            });
//        },
//        toggle: function(params, button) {
//            alert('Button Toggle');
//        }
//    });
