import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'

export const getDaysToStart = ({number, date}) => {
	if (number || number === 0) return number + 1
	if (date) return date(date).subtract(1, 'day').format('YYYY-MM-DD')
}

export const mapTopicResources = (topic, recs, recType) => {
	const mappedRecs = recs
		.map(t => {
			const topicRec = topic.resource_map.find(r => r.resource_id == t.id && r.kind == recType)
			if (!topicRec) return
			return {
				...t,
				order: topicRec.order,
				topic_resource_id: topicRec.id,
			}
		})
		.filter(Boolean)

	return _.orderBy(mappedRecs, 'order')
}

export const onDragEnd = items => {
	const _items = items.map((item, index) => ({
		id: item.topic_resource_id,
		order: index,
	}))
	API.updateMulti('topic_resources', _items)
}

export const getQuizScore = (userAnswers = []) => {
	const totalCorrect = userAnswers.filter(u => u.correct).length
	return totalCorrect ? ((totalCorrect / userAnswers.length) * 100).toFixed(2) : 0
}

export const quizPercent = (quizItem, userAnswers = []) => {
	const {correct_answer} = quizItem
	if (userAnswers.length === 0) return 0
	const correct = userAnswers.filter(({answer}) => answer == correct_answer)
	return (correct.length / userAnswers.length) * 100
}

const highLowQuestions = (items, scores, min) => {
	const idx = scores.indexOf(min ? Math.min(...scores) : Math.max(...scores))
	const question = items[idx]?.question

	return question ? `${question} (${Math.round(scores[idx])}%)` : 'n/a'
}

export const topicQuestionOverview = (userAnswers, quizItems) => {
	const scores = quizItems.map(q => quizPercent(q, userAnswers))
	const averageScore = scores.reduce((acc, curr) => acc + curr, 0) / scores.length

	return {
		averageScore: `${Math.round(averageScore)}%`,
		topQuestion: highLowQuestions(quizItems, scores),
		worstQuestion: highLowQuestions(quizItems, scores, true),
	}
}

export const topicSchedfields = ['days_to_start', 'valid_untill', 'always_trainable', 'no_training']
export const topicSchedFieldLabels = topicSchedfields.map(str => ({name: t(str + '_primary'), id: str}))

export const findTopicSchedState = topic => {
	const index = topicSchedfields.map(str => utils.exists(topic[str])).indexOf(true)
	if (index > -1) return topicSchedfields[index]
	return 'no_training'
}
