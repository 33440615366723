import {Switch} from '@eitje/form-fields-web'
import {FormRow} from 'components/ui'
import Fields from './fields'
import useFormData from 'hooks/use_form_data'

const Form = () => {
	const {actief} = useFormData()
	return (
		<div className="time-registration-form eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<Switch field="actief" />
			</FormRow>
			{actief && <Fields />}
		</div>
	)
}

export default Form
