import React, {createContext, useMemo, useContext} from 'react'
import useSelector from 'hooks/use_selector'
import {DropdownWithCounter} from 'components/ui'
import useShared from 'hooks/use_shared'
import useEnvFilter from 'hooks/use_env_filter'
import useFilter from 'hooks/use_filter'
import setFilter from 'helpers/set_filter'
import useTeamFilter from 'hooks/use_team_filter'

const EnvsContext = createContext({})
const {Provider} = EnvsContext

export const EnvsProvider = ({children}) => {
	const role = 'verlof'
	const {org} = useShared()
	const filterName = `$leaveEnvs-${org.id}`
	const filterValue = useSelector(state => state.filters[filterName])

	const initialValue = filterValue

	const afterChange = (activeIds, {possibleEnvs}) => {
		setFilter({activeIds, ids: possibleEnvs.map(e => e.id), filterName})
		setTeamFilter([])
		setFilter({filterName: teamFilterName})
	}

	const strategy = 'fixed'

	const {
		envs,
		filter: envFilter,
		hasFiltered,
		setEnvFilter,
	} = useEnvFilter({
		role,
		initialValue,
		afterChange,
		strategy,
	})
	const envIds = envs.map(e => e.id)

	const teamFilterName = `leaveTeams-${org.id}`
	const teamInitialValue = useFilter(teamFilterName)

	const {
		filter: teamFilter,
		activeTeamIds,
		setFilter: setTeamFilter,
		hasFiltered: hasTeamFiltered,
	} = useTeamFilter([], {
		allTeams: true,
		Component: DropdownWithCounter,
		initialValue: teamInitialValue,
		strategy,
		role,
		filterTeam: t => envIds.includes(t.environment_id),
		afterChange: (activeIds, {teamIds}) => setFilter({activeIds, ids: teamIds, filterName: teamFilterName}),
	})

	const value = useMemo(
		() => ({envs, teamFilter, activeTeamIds, envIds, envFilter, hasFiltered: hasTeamFiltered || hasFiltered, setTeamFilter, setEnvFilter}),
		[envs, activeTeamIds],
	)
	return <Provider value={value}> {children} </Provider>
}

export const useEnvsContext = () => useContext(EnvsContext)

export default EnvsProvider
