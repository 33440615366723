import {Layout, Message, goToNext} from 'common/components'
import {useModalContext} from 'components/common/index'
import {useShared} from 'hooks'
import {ExtEnvironment} from 'models'
import {t} from 'initializers/i18n'
import {useFormData} from 'hooks'
import {ListPicker} from 'common/components'
import {ModalForm} from 'components/form/index'
import {CompositeField} from 'components/form/index'
import {FormRow} from 'components/ui'
import {Checkbox, Switch} from '@eitje/form-fields-web'

export const ConnectionSettings = props => {
	const {item} = useModalContext()
	return (
		<ModalForm
			modalProps={{confirmSubmit: true}}
			transNamespace="integ_hr_settings"
			onSubmit={item.update}
			initialValues={item}
			afterSubmit={() => goToNext(props)}
		>
			<Layout width="full" direction="vertical">
				<FormRow>
					<Switch tooltip field="auto_remove_users" />
				</FormRow>
			</Layout>
		</ModalForm>
	)
}
