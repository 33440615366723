import {t as baseT} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {WorkSchedulePopout} from 'cores/leave/index'
import {useGet} from 'hooks/index'
import {useSimpleContext} from 'contexts/simple'
import {InfoCard} from 'common/components'

export const ContractHours = ({record, range, ...rest}) => {
	const {user_id} = record
	const {schedules = []} = useSimpleContext()
	const {start, end} = range
	const params = {start_date: start, end_date: end}
	const watch = `${start.formatDate()}-${end.formatDate()}-${user_id}`

	const {data} = useGet(`users/${user_id}/contract_mins_in_period`, {params, watch})

	let {mins = 0} = data

	if (mins > range.minutes()) mins = range.minutes()
	const hasSchedule = schedules.length > 0
	const subtitle = hasSchedule ? utils.minToTimeString(mins) : '-'

	return (
		<InfoCard
			popoutBody={hasSchedule && <WorkSchedulePopout request={record} />}
			label={baseT('contract.uren')}
			icon="document-signed"
			children={subtitle}
			{...rest}
		/>
	)
}
