import Modal from 'components/general/modal'
import {history} from 'index'
import {useForm, ContextForm} from '@eitje/form'
import {t as baseT} from 'initializers/i18n'
import {t, useIntegrationConstants, getIntegrationResources} from 'cores/integrations/index'
import {BaseConnection} from 'models/index'
import useQueryParams from 'hooks/use_query_params'
import {DocsButton} from 'common/components'
import {navigateModal} from 'actions/routing'
import {useParams} from 'hooks'
import Form from './form'
import {Oauth} from './oauth/activate'
import './styles/layout.less'

const Index = ({providerName}) => {
	const {submit} = useForm()
	const constantData = useIntegrationConstants(providerName)
	if (constantData.oauth) return <Oauth {...constantData} />

	return (
		<Modal width="690px" hasCancel onOk={submit} okText={t('activate')} className={`activate-integration-modal activate-${providerName}`}>
			<Header {...constantData} providerName={providerName} />
			<Info {...constantData} providerName={providerName} />
			<Form {...constantData} />
		</Modal>
	)
}

const Info = ({logo, type, name, providerName}) => {
	const title = [`integrations.activate_provider.${providerName}.title`, `integrations.activate_provider.${type}.title`]
	const subtitle = [`integrations.activate_provider.${providerName}.subtitle`, `integrations.activate_provider.${type}.subtitle`]
	name = name.capitalize()
	return (
		<div className="provider-info">
			<div className="provider-info-logo">{logo}</div>
			<div className="provider-info-text">
				<h4>{baseT(title, {name})} </h4>
				<h5>{baseT(subtitle, {name})} </h5>
			</div>
		</div>
	)
}

const Header = ({docsLink, providerName}) => {
	return (
		<div className={`activate-integration-modal-header activate-${providerName}-header`}>
			<h3>{t('activate')} </h3>
			<DocsButton to={docsLink} size="small" />
		</div>
	)
}

export const ActivateIntegration = () => {
	const {providerName} = useParams()
	const {connection_id} = useQueryParams()
	const {initialValues = {}} = useIntegrationConstants(providerName)
	const conn = BaseConnection.find(connection_id)

	const activateIntegration = data => {
		const prms = {...data, provider: providerName}
		return conn.id ? conn.update(prms) : BaseConnection.create(prms)
	}

	const goToItem = res => {
		const {sanitized_type, id, hr} = res.data.item
		getIntegrationResources()
		const suffix = sanitized_type == 'piggy' ? 'base' : hr ? 'hr' : 'revenue'

		navigateModal(`/integrations/${sanitized_type}/${id}/setup_${suffix}`, {
			bgPath: `/integrations/my/${sanitized_type}`,
			replace: true,
		})
	}

	const initial = {...initialValues, ...conn}

	return (
		<ContextForm
			handleAllErrors
			fieldProps={{required: true, disableAutoComplete: true}}
			initialValues={initial}
			onSubmit={activateIntegration}
			afterSubmit={goToItem}
			transNamespace={`integrations.${providerName}`}
		>
			<Index Form={Form} providerName={providerName} />
		</ContextForm>
	)
}

export default ActivateIntegration
