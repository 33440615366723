import React, {useEffect, useState, Fragment, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import I18n, {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import useShared from 'hooks/use_shared'
import {date} from 'initializers/date'
import {useScript} from 'hooks/index'
import {isInternalUser} from 'helpers'

const noSupportUrls = ['/planning', '/uren', '/uren_accorderen', '/inwerken', '/chat', '/train', '/availability', '/environments']

const HeapRegistrar = () => {
	const {me, env, myOrgEnvs, orgEnvs, org} = useShared()

	const loaded = useRef()

	if ((!loaded.current && me.id && org.id) || isInternalUser(me)) return <HeapInner loaded={loaded} />

	return null
}

const HeapInner = ({loaded}) => {
	useScript(
		`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load(Number(${process.env.REACT_APP_HEAP_ID}));`,
	)
	const {me, env, myOrgEnvs, orgEnvs, org} = useShared()
	const {email, avatar, full_name, phone, date_started, id} = me
	const roles = useSelector(state => state.settings.roles) || {}
	const envRoles = roles[env.id] || []

	const data = {
		id: me.id,
		email,
		name: full_name,
		environment_name: env.naam,
		environment_id: env.id,
		organisation_name: org.name,
		organisation_id: org.id,
		organisation_created_at: date(date_started).format('DD-MM-YYYY'),
		phone,
		roles: envRoles.join(', '),
	}

	// Heap's snippet has some issues: https://github.com/segmentio/analytics.js/issues/605#issuecomment-689950482
	// The GH link describes the ref trick, but we've also had to introduce a fn name check..
	// we've discovered that heap's identify fn's name is reset to '' when the crash occurs.
	if (utils.exists(window?.heap?.identify?.name)) {
		window.heap?.identify(me.id)
		window.heap?.addUserProperties(data)
		loaded.current = true
	}
	return null
}

export default HeapRegistrar
