import {Button, Modal as AntModal} from 'antd'
import {ModalRedirect} from 'components/routing'
import {BackButton, DeleteButton, SubmitButton, Layout} from 'common/components'
import useModalBack from 'hooks/use_modal_back'
import {history} from 'index'
import {t} from 'initializers/i18n'
import React, {useContext} from 'react'

const ModalContext = React.createContext({})
const ModalProvider = ModalContext.Provider

const modalSizes = {
	sm: 520,
	md: 750,
	lg: 800,
	xl: 1200,
	fs: undefined,
}

const Modal = props => {
	let {
		onOk,
		size = 'sm',
		hideOk,
		okText = t('submit'),
		hideClose,
		hasCancel,
		cancelText,
		footerBackButton,
		insertAtIndex = {},
		hideDel,
		form,
		width,
		redirectIf,
		onDel,
		onCancel,
		afterOk = _.noop,
	} = props
	const {goBack} = useModalBack(props)
	if (!onCancel) onCancel = goBack

	if (redirectIf) return <ModalRedirect />

	let condOpts = {}
	if (!onOk && !footerBackButton && !hasCancel) condOpts['footer'] = null

	const _onDel = async () => {
		form && form.current && form.current.unTouch()
		await onDel()
		goBack()
	}

	const backButton = <Button onClick={history.goBack}>{t('back')}</Button>

	const _onOk = () => {
		onOk()
		afterOk()
	}

	let footerItems = [
		footerBackButton && backButton,
		!hideDel && onDel && <DeleteButton onDel={_onDel} />,
		hasCancel && (
			<BackButton key="back" onClick={onCancel}>
				{cancelText || t('cancel')}
			</BackButton>
		),
		!hideOk && onOk && (
			<SubmitButton key="submit" onClick={_onOk}>
				{okText}
			</SubmitButton>
		),
	].filter(Boolean)

	Object.keys(insertAtIndex).forEach(idx => {
		const comp = insertAtIndex[idx]
		footerItems.splice(idx, 0, comp)
	})

	const footer = <Layout horizontal="end"> {footerItems} </Layout>

	// Footer back button is defaulting to true therefore footer is only when the array is greater than 1
	let className = [
		size === 'fs' && 'modal-fullscreen',
		footerItems.length > 0 && 'footer',
		hideClose && 'hide-close',
		footerBackButton && 'left-footer',
	]
		.filter(Boolean)
		.join(' ')

	return (
		<ModalProvider value={{goBack}}>
			<AntModal
				className={className}
				footer={footer}
				{...condOpts}
				visible
				onCancel={onCancel}
				{...props}
				width={width || modalSizes[size]}
			></AntModal>
		</ModalProvider>
	)
}

export default Modal

const confirm = ({okText = t('ok'), content, title = t('confirm'), closable = true, cancelText = t('cancel'), ...rest}) =>
	AntModal.confirm({
		okText,
		cancelText,
		title,
		content,
		closable,
		...rest,
	})

const useModalContext = () => useContext(ModalContext)

export {ModalContext, ModalProvider, useModalContext, confirm}
