const initialState = {
	user: {},
	environments: {},
	roles: [],
	periods: {uren: [], nul_uren: []},
	taxonomy: {},
}

export default function reduce(state = initialState, action) {
	const {id, groupName, settings} = action

	switch (action.type) {
		case 'GET_ROLES':
			return {
				...state,
				roles: {...roles, ...action.roles},
			}

		case 'GET_SETTINGS':
			const {environments, user, periods, roles, organisation, taxonomy} = action.payload
			return {
				...state,
				environments,
				user,
				periods,
				roles,
				taxonomy,
				organisation,
			}

		case 'FINISH_ONBOARDING_SUBSTEP':
			return {
				...state,
				taxonomy: {
					...state.taxonomy,
					onboarding_steps: action.steps,
				},
			}

		case 'UPDATE_ENV_SETTINGS':
			const {id, settings} = action
			return {
				...state,
				environments: {...state.environments, [id]: {...state.environments[id], ...settings}},
			}

		case 'UPDATE_ENV_SETTING_GROUP':
			const env = state.environments[action.id]
			const group = env[action.groupName]
			const newEnv = {...env, [action.groupName]: {...group, ...action.settings}}
			const envs = {...state.environments, [action.id]: {...newEnv}}
			return {
				...state,
				environments: envs,
			}

		case 'UPDATE_ORG_SETTINGS':
			return {
				...state,
				organisation: {...state.organisation, ...action.organisation},
			}

		case 'UPDATE_USER_SETTING':
			const newState = {
				...state,
				user: {...state.user, ...action.settings},
			}
			return newState

		default:
			return state
	}
}
