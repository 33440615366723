import {find, where} from '@eitje/easy_api'
import FileList from 'components/file_list'
import {RouteSelect} from 'components/routing'
import useSingleContracts from 'hooks/use_contract'
import {useSelector} from 'react-redux'
import {Route, Switch, useParams, useRouteMatch} from 'react-router-dom'
import {ContractAnalytics, EmptyCont} from './components'
import {ContractTable, SalaryTable, ScheduleTable} from './data_display'
import {useRoleEnvs, useShared} from 'hooks'

const kinds = [{label: 'contracts', value: null}, 'salary', 'hours']

const Contracts = ({isRemoved, userBanner}) => {
	const {id} = useParams()
	const {me} = useShared()
	const user = useSelector(state => find(state, 'users', id))

	// Quick fix: extra filter check to only show contracts
	const contractRoleEnvIds = useRoleEnvs('contracten').map(env => env.id)
	const contracts = useSingleContracts(id).filter(c => {
		const hasRole = contractRoleEnvIds.includes(c.environment_id)
		if (hasRole) return true

		const myContract = c.user_id == me.id
		return myContract
	})

	const attachments = useSelector(state => where(state, 'contractHolders', {user_id: user.id}))
		.map(c => c.attachments)
		.flat()
	let {date_started} = user
	const firstContract = contracts[contracts.length - 1]
	date_started = date_started || firstContract?.start_date

	return (
		<div className="user-inner-grid">
			<div>
				{userBanner}
				{contracts.length > 0 && <TableRoutes id={id} />}

				{contracts.length === 0 && !isRemoved && <EmptyCont id={id} />}

				{contracts.length > 0 && (
					<div className={'row justify-content-flex-end bold-dropdown'}>
						<RouteSelect baseUrl={`/user/${id}/contracts`} items={kinds} />
					</div>
				)}
			</div>

			<div className="user-details-column">
				<ContractAnalytics date={date_started} user={user} />
				<FileList itemProps={{hideLink: true}} items={attachments} />
			</div>
		</div>
	)
}

const TableRoutes = props => {
	const {path} = useRouteMatch()
	return (
		<Switch>
			<Route path={path} exact render={() => <ContractTable {...props} />} />
			<Route path={`${path}/salary`} exact render={() => <SalaryTable {...props} />} />
			<Route path={`${path}/hours`} exact render={() => <ScheduleTable {...props} />} />
		</Switch>
	)
}

export default Contracts
