export const supportCollectionsTaxonomy = () => {
	return [
		{
			name: 'welcome_to_eitje',
			collection: 3943804,
			icon: 'play',
		},
		{
			name: 'planning',
			collection: 3355108,
			icon: 'calendar',
		},
		{
			name: 'time_registration',
			collection: 3355113,
			icon: 'clock',
		},
		{
			name: 'clocking',
			collection: 3943577,
			icon: 'stopwatch',
		},
		{
			name: 'integrations',
			collection: 3355085,
			icon: 'link',
		},
		{
			name: 'settings',
			collection: 9024650,
			icon: 'three-sliders',
		},
		{
			name: 'export',
			collection: 3355125,
			icon: 'upload',
		},
		{
			name: 'team',
			collection: 3355132,
			icon: 'chart-circles',
		},
		{
			name: 'communication',
			collection: 3355097,
			icon: 'chat',
		},
		{
			name: 'knowledge',
			collection: 3355101,
			icon: 'book',
		},
		{
			name: 'videos',
			extLink: 'https://www.youtube.com/watch?v=Xu1F5ONEYpA&list=PLGvlDn3BX9jbMbKvHsS6en6gECIrog9Jb&ab_channel=Eitje',
			icon: 'video',
		},
		{
			name: 'faq',
			collection: 3355129,
			icon: 'question-mark-circled',
		},
	]
}
