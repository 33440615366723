import {getAccordShifts} from 'actions/rooster'
import {shiftMapper} from 'actions/shifts'
import {DatePicker, Spin} from 'antd'
import RegModal from 'components/modals/reg_modal'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import React from 'react'
import {connect} from 'react-redux'
import {envTeamsSelector} from 'selectors/entities'
import * as jsUtils from 'utils/jsutils'
import 'styles/legacy/roster.css'
const {RangePicker} = DatePicker

const _RegKaartje = ({item, teams, environments, onP, color}) => {
	const env = environments.find(e => e.id == item.environment_id) || {}
	const team = teams.find(t => t.id == item.team_id) || {}
	const hasEnvs = environments.length > 1
	return (
		<div
			onClick={onP}
			style={{width: '96%', marginLeft: '2%', marginTop: 4, borderRadius: 4, paddingTop: 12, paddingBottom: 12, backgroundColor: 'white'}}
			className="border regKaartUser"
		>
			<div style={{display: 'inline-block', width: '25%'}}>
				<p style={{lineHeight: '60px', fontWeight: 500, textAlign: 'center', fontSize: 24, color: color, marginLeft: 12}}>
					{' '}
					{date(item.date).format('dd').toUpperCase()}{' '}
				</p>
			</div>

			<div className="userUrenn" style={{display: 'inline-block', width: '50%', verticalAlign: 'middle', marginTop: -12}}>
				<p>
					{item.from.format('HH:mm')} - {item.till.format('HH:mm')}{' '}
				</p>
				<p style={{}}> {jsUtils.titleCase(date(item.date).format('D MMMM YYYY'))}</p>
				{hasEnvs ? (
					<p>
						{team.naam} - {env.naam}{' '}
					</p>
				) : (
					<p>{team.naam}</p>
				)}
			</div>

			<div style={{display: 'inline-block', width: '25%'}}>
				<p style={{fontSize: 14, color: color, fontWeight: 500, position: 'relative', top: -6}}>
					{' '}
					{jsUtils.minToTimeString(item.total_mins)}{' '}
				</p>
			</div>
		</div>
	)
}

const RegKaartje = connect(state => ({
	environments: state.entities.envs,
	teams: state.entities.teams,
}))(_RegKaartje)

class UserUren extends React.Component {
	componentDidMount() {
		this.getReg()
	}

	constructor(props) {
		super(props)
		this.state = {
			startDate: date().startOf('month'),
			endDate: date().endOf('month'),
			modalV: false,
			fetching: false,
		}
		this.openModal = this.openModal.bind(this)
		this.handleCancel = this.handleCancel.bind(this)
		this.getReg = this.getReg.bind(this)
	}

	getReg() {
		const {startDate, endDate} = this.state
		getAccordShifts(startDate, endDate, {onlySelf: true})
	}

	openModal(shift) {
		this.setState({
			regShift: shift,
			modalV: true,
		})
	}

	handleCancel() {
		this.setState({
			modalV: false,
		})
	}

	onDateChange = date => {
		this.setState({startDate: date[0], endDate: date[1]}, () => this.getReg())
	}

	render() {
		const {startDate, endDate, modalV, regShift, fetching} = this.state
		let {shifts, teams, user} = this.props
		shifts = shifts.map(s => shiftMapper(s))
		const open = jsUtils.sortShifts(shifts.filter(s => !s.reg))
		const pending = jsUtils.sortShifts(shifts.filter(s => s.reg && !s.published))
		const done = jsUtils.sortShifts(shifts.filter(s => s.reg && s.published))
		const totalWorked = done.reduce((tally, s) => tally + s.total_mins, 0)
		const totalMins = shifts.reduce((tally, s) => tally + s.total_mins, 0)
		return (
			<div className="userUren" style={{zIndex: 999}}>
				<div className="relative veetwee32" style={{height: 57}}>
					<div className="subHeaderv3">
						<RangePicker
							defaultValue={[startDate, endDate]}
							format={'DD/MM/YYYY'}
							onChange={this.onDateChange}
							style={{float: 'left', marginLeft: 4, marginRight: 4, width: 260}}
							allowClear={false}
						/>

						<div className="fRow aCen jEnd">
							<div className="accordTotalTime fRow aCen jBetween" style={{marginRight: 4, height: 32, width: 120}}>
								<span style={{fontSize: 8, color: '#828282', textTransform: 'uppercase', marginRight: 12}}>{t('total')}:</span>
								<span style={{fontWeight: 600, color: '#2b2b2b'}}>{jsUtils.minToTimeString(totalMins)}</span>
							</div>

							<div className="accordTotalTime fRow aCen jBetween" style={{height: 32}}>
								<span style={{fontSize: 8, color: '#828282', textTransform: 'uppercase', marginRight: 12}}>{t('only_reg')}:</span>
								<span style={{fontWeight: 600, color: '#24D9AE'}}>{jsUtils.minToTimeString(totalWorked)}</span>
							</div>
						</div>
					</div>
				</div>

				<Spin style={{position: 'absolute', left: '50%', zIndex: 3000, top: 60}} spinning={fetching}></Spin>

				<div className="urenOutline">
					<div className="headerUrenUser">
						<p>{t('userUren1')}</p>
					</div>

					<div className="urenContainer openReg">
						{open.map(item => (
							<RegKaartje color="#0496ff" onP={() => this.openModal(item)} item={item} />
						))}
						{open.length > 1 && <div style={{height: 200, width: 20}}></div>}
						{open.length == 0 && <p style={{marginTop: 24, marginLeft: 12, fontSize: 10, color: '#828282'}}>{t('userUren4')}</p>}
					</div>
				</div>

				<div className="urenOutline">
					<div className="headerUrenUser" style={{borderWidth: 1, borderColor: '#eee', borderStyle: 'solid', borderTopWidth: 0}}>
						<p style={{color: '#FF8D2A'}}>{t('userUren2')}</p>
					</div>
					<div
						className="urenContainer"
						style={{paddingBottom: 160, borderWidth: 1, borderColor: '#eee', borderStyle: 'solid', borderTopWidth: 0, borderBottomWidth: 0}}
					>
						{pending.map(item => (
							<RegKaartje onP={() => this.openModal(item)} color="#FF8D2A" item={item} />
						))}
						{pending.length == 1 && <div style={{height: 200, width: 20}}></div>}
						{pending.length == 0 && <p style={{marginTop: 24, fontSize: 10, color: '#828282'}}>{t('userUren5')}</p>}
					</div>
				</div>

				<div className="urenOutline">
					<div className="headerUrenUser">
						<p style={{color: '#25D9AF'}}>{t('userUren3')}</p>
					</div>
					<div className="urenContainer">
						{done.map(item => (
							<RegKaartje color="#25D9AF" item={item} />
						))}
						{done.length > 1 && <div style={{height: 200, width: 20}}></div>}
						{done.length == 0 && <p style={{marginTop: 24, fontSize: 10, color: '#828282'}}>{t('userUren6')}</p>}
					</div>
				</div>

				{modalV && (
					<RegModal
						handleSuccess={this.getReg}
						userId={user.id}
						onCancel={this.handleCancel}
						shift={regShift}
						date={regShift.date}
						planShift={regShift}
					/>
				)}
			</div>
		)
	}
}

export default connect(state => ({
	shifts: state.rooster.accordShifts,
	user: state.auth.user,
	teams: envTeamsSelector(state),
}))(UserUren)
