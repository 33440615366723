import {useFilterFooterContext} from 'common/components'
import {MultiAction as _MultiAction} from 'cores/planning/components'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'

export const MultiAction = () => {
	const {multiEdit, selectedShifts, setMultiEdit, setSelectedShiftsRaw} = useEnvsContext()
	const {filteredItems: shifts} = useFilterFooterContext()

	if (!multiEdit) return null

	const _selectedShifts = shifts.filter(s => selectedShifts.includes(s.id))
	const shiftIds = _selectedShifts.map(s => s.id)

	const close = () => {
		setMultiEdit(false)
		setSelectedShiftsRaw([])
	}

	return <_MultiAction shiftIds={shiftIds} close={close} />
}
