import utils from '@eitje/web_utils'
import './styles/skills.less'
import {SkillSet} from 'models'
import {useLocalValue} from 'hooks'

const Skills = ({user, team}) => {
	let skillSets = SkillSet.where({team_ids: team.id, planning_active: true})
	return (
		<div className="availability-skills">
			{skillSets.map(s => {
				const hasSkillSet = userHasSkillSet(s, user)
				if (!hasSkillSet) return null
				return <_SkillSet skillSet={s} hasSkillSet={hasSkillSet} />
			})}
		</div>
	)
}

const _SkillSet = ({skillSet, hasSkillSet}) => {
	const classNames = utils.makeCns('availability-skill-letter', hasSkillSet && 'has-skill-set')
	let condStyle = {}
	if (hasSkillSet) condStyle = {borderColor: skillSet.color_code, backgroundColor: skillSet.color_code}
	return (
		<div className={'availability-skill'} style={condStyle}>
			<h6 className={classNames}> {skillSet.abbr} </h6>
		</div>
	)
}

const userHasSkillSet = (set, user) => {
	return set.skill_ids && set.skill_ids.length > 0 && set.skill_ids.every(s => user.skill_ids.includes(s))
}

const Wrapper = props => {
	const showSkills = useLocalValue('planningShowSkills')
	if (!showSkills) return null
	return <Skills {...props} />
}

export default Wrapper
