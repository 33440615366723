import {API} from '@eitje/easy_api'
import {LegacyInput as _Input} from '@eitje/form-fields-web'
import {Button} from 'antd'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef} from 'react'

const Input = props => <_Input bordered={false} {...props} />

const PasswordForm = ({user}) => {
	const formRef = useRef()
	const handleFormSubmission = () => formRef.current.submit()

	return (
		<Form ref={formRef} onSubmit={data => API.update('users', data)} initialValues={user}>
			<div fieldWrapper className="modal-form-field-area">
				<Input secure required field="password" />
				<Input secure required field="password_confirmation" />
			</div>

			<div className="modal-inner-footer gap-xss" fieldWrapper>
				<Button type="primary" onClick={handleFormSubmission}>
					{t('save')}
				</Button>
			</div>
		</Form>
	)
}

export default PasswordForm
