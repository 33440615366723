import useShared from 'hooks/use_shared'
import {useRoleOrgEnvs} from 'hooks'
import {useFind} from '@eitje/easy_api'
import {Team} from 'models'
import User from 'models/user'
import useQueryParams from 'hooks/use_query_params'

export const useDetermineNewShiftEnv = ({role} = {}) => {
	const {user_id, env_id, team_id} = useQueryParams()
	const {env} = useShared()
	const roleOrgEnvIds = useRoleOrgEnvs(role).ids()
	const user = User.find(user_id)

	// When no user is passed in the query params, we dont do the intersection,
	// but instead just use the roleOrgEnvIds
	let userEnvIds = _.isEmpty(user) ? roleOrgEnvIds : _.intersection(user.environment_ids, roleOrgEnvIds)
	// when we use an envId from user.environment_ids, we prefer the env.id (from useShared) if it is present there
	const userEnvId = _.sortBy(userEnvIds, item => item !== env.id)[0]

	const team = Team.find(team_id)
	const teamEnvId = useFind('environments', team.environment_id)?.id

	return env_id || teamEnvId || userEnvId || env.id
}
