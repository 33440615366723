import {AuthPageLayout} from 'cores/auth'
import {LogoutButton} from 'common/components'
import {useShared} from 'hooks'
import {navigate} from 'components/routing'

export const NoEnvironment = ({skipRedirect}) => {
	const {env} = useShared()
	const hasEnv = !_.isEmpty(env)
	if (hasEnv && !skipRedirect) {
		// Make this route inaccessible for people who actually belong environments but have somehow accessed this URL.
		// Use skipRedirect to prevent an infinite loop from occurring when using this component directly instead of via a route.
		navigate('/')
	}

	return (
		<AuthPageLayout name="no_environment" subtitle noForm>
			<LogoutButton colorSet="solid" />
		</AuthPageLayout>
	)
}
