import {PopoutCard} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {Columns} from './columns'
import {Filters} from './filters'
import {Save} from './save'
import {t} from 'initializers/i18n'
import {getTableName} from 'helpers'
import {columnTitle, FilterSentence} from 'cores/exports2'

const SaveFooter = () => t('common.save')

const TableFooter = ({item = {}}) => {
	if (!item.table_name) return null
	return getTableName(item.table_name)
}

const ColumnsFooter = ({item = {}}) => {
	const {columns} = item
	if (!utils.exists(columns)) return null
	const body = columns.map(c => columnTitle(c.column)).join(', ')
	return <HoverText body={body}> {columns.length} </HoverText>
}

const FiltersFooter = ({item = {}}) => {
	const {row_filters} = item
	if (!utils.exists(row_filters)) return null

	return <HoverText body={<FilterSentence item={item} />}> {row_filters.length} </HoverText>
}

let steps = [
	{name: 'table', Component: null, FooterSub: TableFooter, done: () => true},
	{name: 'columns', Component: Columns, FooterSub: ColumnsFooter, done: () => true},
	{name: 'filters', Component: Filters, FooterSub: FiltersFooter, done: () => true},
	{name: 'save', Component: Save, FooterSub: SaveFooter, done: item => !!item.name},
]

steps = steps.map(s => ({...s, idx: steps.indexOf(s)}))
export {steps}

const HoverText = ({children, body}) => {
	const isPopover = utils.exists(body)
	const Wrapper = isPopover ? PopoutCard : 'div'
	return (
		<Wrapper bodyLayoutProps={{maxWidth: 500}} body={body} layoutProps={{horizontal: 'center'}}>
			{children}
			{isPopover && <img className="footer-card-icon" src="/images/search_glass_icon.png" />}
		</Wrapper>
	)
}
