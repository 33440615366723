import {useAdvancedTable, AdvancedTable} from 'common/components/advanced_table'
import {columns} from './columns'
import {TABLE_INFO} from './constants'
import {useFilterFooterContext} from 'common/components'
import {ExtLink} from 'components/routing'

export * from './constants'

const placeholderProps = {
	animation: 'receipt',
	name: 'invoice',
}

export const InvoicesAdvancedTable = ({}) => {
	const {filteredItems: invoices, filteredOutAmount, clearFilters} = useFilterFooterContext()

	const tableData = useAdvancedTable({
		data: invoices,
		initialPinning: ['formatted_invoice_number', 'status'],
		columns,
		TABLE_INFO,
		filteredOutAmount,
		clearFilters,
	})

	return <AdvancedTable data={tableData} RowWrapper={RowWrapper} placeholderProps={placeholderProps} />
}

const RowWrapper = ({row, children}) => {
	const {pdf} = row.original
	return <ExtLink to={pdf}>{children}</ExtLink>
}
