import {CreateButton, Placeholder} from 'common/components'

const EmptyTeamRow = ({env}) => {
	return (
		<Placeholder name="team" animation="high-five" docsId="5967127">
			<CreateButton modalLink={`/team/new?environment_id=${env.id}`} t="action_button" />
		</Placeholder>
	)
}

export default EmptyTeamRow
