import {t} from 'initializers/i18n'
import {useFilterFooterContext, Layout, Title} from 'common/components'

// Below is a much simplified version of the earlier implementation which also printed the environment
// names which were set by the FilterFooter. If we want to reroll this or reuse that logic for another
// purpose, simply browse this file's commit history.

export const PageHeaderTitle = ({label}) => {
	const {filteredItems} = useFilterFooterContext()
	const title = `${filteredItems.length} ${t(label)}`

	return (
		<Layout gap={8}>
			<Title semiBold>{title} </Title>
		</Layout>
	)
}
