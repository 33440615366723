export * from './hook_helpers'

import {PeriodTypePicker, PeriodPicker} from './components'

// This easily combines the period type and period pickers which may be used in the top, left side of the PageHeader.

export const PeriodPickers = ({pageContext}) => {
	return (
		<>
			<PeriodTypePicker pageContext={pageContext} />
			<PeriodPicker pageContext={pageContext} />
		</>
	)
}
