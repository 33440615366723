import _ from 'lodash'

const initialState = {
	startDate: null,
	endDate: null,
	year: null, // year is relevant for past, start/end for custom period..
	period: 'future',
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'SET_LEAVE_PERIOD':
			const obj = _.pick(action, 'startDate', 'endDate', 'period', 'year')

			return {
				...state,
				...obj,
			}

		default:
			return state
	}
}
