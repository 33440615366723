import {getCell, COLUMNS_WITHOUT_AGGEGRATES, GROUPED_CELL_WIDTH, DEFAULT_CELL_WIDTH, getColumnName} from 'common/components/advanced_table'

export const buildColumn = column => {
	if (_.isString(column)) column = {accessorKey: column}
	const id = getColumnId(column)
	const cell = getCell(column)
	const aggregates = hasAggregates(column)
	let obj = {cell, ...column, aggregates, aggregates}

	if (!obj.accessorKey) obj['id'] = id
	if (!obj.accessorFn && !obj.accessorKey) {
		obj['accessorKey'] = id
	}
	return obj
}

export const hasAggregates = column => {
	const {aggregates, accessorKey} = column.columnDef || column
	const {cell} = column

	// Always prefer the manually set taxonomy value if present
	if (aggregates === true) return true
	if (aggregates === false) return false

	// Return false by default for BasicCell's (e.g. when there is no cell given in the taxonomy)
	if (cell === undefined) return false

	// Fallback to general column settings (since most columns either always or never have an aggregate, such as dates,
	// which aren't summable). This only works for cells that are written as strings within the taxonomy, if you want to
	// use a custom component, you should explicitly write `aggregates: true` if you want to include them.
	return !COLUMNS_WITHOUT_AGGEGRATES.map(getColumnName).includes(column.cell)
}

export const getColumnIds = columns => {
	return columns.map(getColumnId)
}

export const getDefaultVisibleIds = columns => {
	const filteredObjects = columns.filter(column => column.defaultVisible !== false)
	return getColumnIds(filteredObjects)
}

const getColumnId = column => column.id || column.accessorKey

export const isLastPinnedColumn = (index, context) => {
	const {
		table: {getLeftFlatHeaders},
	} = context
	const pinnedAmount = getLeftFlatHeaders?.().length
	return pinnedAmount === index + 1
}

export const getLeftOffset = (index, context) => {
	const {
		table: {getLeftFlatHeaders},
	} = context
	const pinnedColumns = getLeftFlatHeaders?.()
	return pinnedColumns
		.slice(0, index)
		.map(c => {
			const isGroupedColumn = c.column.getIsGrouped()
			return c.column.columnDef.width || (isGroupedColumn ? GROUPED_CELL_WIDTH : DEFAULT_CELL_WIDTH)
		})
		.sum()
}
