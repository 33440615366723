import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {Link as RouterLink, Redirect, Switch, useHistory, matchPath, useLocation, useRouteMatch} from 'react-router-dom'
import {makePath, Link} from 'components/routing'
import useSubRoutes from 'hooks/use_sub_routes'
import './side_menu.less'
import useSearch from 'hooks/use_search'

const DefaultWarning = ({className}) => (
	<div className={className}>
		<p>!</p>
	</div>
)

const DefaultMenuItem = ({text, className, count, showCountFrom = 2}) => (
	<p className={className}>
		{text}
		{!!count && count >= showCountFrom && <span className="side-menu-item-count"> ({count}) </span>}
	</p>
)

const replaceParams = (route, path) => {
	// this actually is a bit bad.. it's because we've coupled sidemenu/route so frigging tightly that you can't have links that don't have their own routes..

	if (route.id) {
		path = path.replace(':id', route.id)
	}
	return path
}

const SideMenuRoute = props => {
	const {
		loc,
		path,
		extraPath,
		children,
		Warning = DefaultWarning,
		namespace,
		MenuItem = DefaultMenuItem,
		routeProps,
		warning,
		route,
	} = props
	let pathOpts = {}
	if (_.has(props, 'exact')) pathOpts['exact'] = props.exact
	if (_.has(route, 'exact')) pathOpts['exact'] = route.exact

	const r = route
	const extraPaths = [extraPath, route.extraPath].flat().filter(Boolean)
	let finalPath = makePath(path, r)

	finalPath = replaceParams(route, finalPath)

	const otherPaths = extraPaths.map(p => makePath(path, r, p))
	const allPaths = [finalPath, ...otherPaths]

	const isActive = allPaths.some(p => isPathActive(loc.pathname, p, pathOpts))
	const count = utils.funcOrVal(r.count, routeProps)

	const hasWarning = utils.funcOrVal(warning, {...routeProps, count})

	const className = ['side-menu-container', hasWarning && 'error', isActive && 'selected'].filter(Boolean).join(' ')

	return (
		<Link className={className} to={{pathname: finalPath, state: loc.state}}>
			<MenuItem {...props} text={route.displayName} count={count} className="side-menu-item" />
			{hasWarning && <Warning className="side-menu-warning warningCircle fRow aCen jCen" />}

			{isActive && children}
		</Link>
	)
}

const SideMenuRouteGroup = props => {
	const {route, path} = props
	const {subRoutes, subLinks} = route
	const allSubs = [...subRoutes, ...subLinks]
	const mainPath = makePath(path, route)
	return (
		<>
			<SideMenuRoute {...props} exact={subRoutes.length == 0}>
				{allSubs
					.filter(r => r.name)
					.map(r => (
						<SideMenuRouteGroup {...props} path={mainPath} route={r} />
					))}
			</SideMenuRoute>
		</>
	)
}

//

const getTranslation = (route, props) => {
	const {namespace} = props
	const {name, noTranslate} = route
	if (noTranslate) return name
	const transKey = name || route
	const namespaceTrans = `sidemenu.${namespace}.${transKey}`
	const normalTrans = `sidemenu.${transKey}`
	return namespace ? t(namespaceTrans, t(normalTrans, transKey)) : t(normalTrans, transKey)
}

const buildRoute = (route, props) => {
	const subRoutes = useSubRoutes(route).map(r => buildRoute(r, props))
	const subLinks = useSubRoutes({subRoutes: route.subLinks}).map(r => buildRoute(r, props))
	const displayName = getTranslation(route, props)
	return {
		...route,
		subRoutes,
		subLinks,
		displayName,
	}
}

export const SideMenu = ({routes, children, showSearch = routes.length > 5, ...props}) => {
	const loc = useLocation()
	routes = routes.map(r => buildRoute(r, props))
	const {searchInput, filteredItems = []} = useSearch(routes, {...props, searchField: 'displayName', showSearch})

	return (
		<div className="side-menu">
			{showSearch && searchInput}
			{filteredItems.map(r => (
				<SideMenuRouteGroup {...props} route={r} loc={loc} />
			))}
			{children}
		</div>
	)
}

const trailingSlash = /\/$/g

const isPathActive = (currentPath, path, {exact = true}) => {
	// we'll remove the trailing slash from the currentPath
	const sanitizedPath = currentPath.replace(trailingSlash, '')
	path = path.replace(trailingSlash, '')
	return matchPath(sanitizedPath, {path, exact})
}

export default SideMenu
