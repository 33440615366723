import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import Modal from 'components/general/modal'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import SimpleInputNumber from 'lib/form/fields/simple_input_number'
import '../index/styles/availability.less'
import './index.less'

const {weekdays} = utils

const TemplateTeamDays = () => {
	const {envId} = useParams()
	const teams = useWhere('teams', {environment_id: envId, locked: false, onroosterbaar: false})
	const teamDays = useWhere('team_template_days', {team_ids: teams.map(t => t.id)})
	return (
		<Modal width={1000} title={t('autoLockTemplateExplained')} wrapClassName={'availability-default-locks-modal'} hideClose hasCancel>
			<h5 className="modal-info-banner"> {t('autoTeamLockInfo')}</h5>

			<div className="default-locks-table">
				<div className="default-locks-table-header">
					{weekdays.map(name => (
						<WeekDay name={name} />
					))}
				</div>

				<div>
					{teams.map(t => (
						<TeamWeek team={t} />
					))}
				</div>
			</div>
		</Modal>
	)
}

const TeamWeek = ({team}) => {
	const userCount = team.user_ids.length
	return (
		<div className="default-locks-table-row">
			<div className="default-locks-table-cell">
				<div className="default-locks-table-inner-cell">
					<div className="default-locks-table-first-cell-of-row">
						<h4>{team.naam} </h4>
						<h5>
							{userCount} {t('teammember', {count: userCount})}
						</h5>
					</div>
				</div>
			</div>

			{weekdays.map((name, idx) => (
				<TeamDay team={team} dayOfWeek={idx} />
			))}
		</div>
	)
}

const TeamDay = ({team, dayOfWeek}) => {
	const query = {team_id: team.id, day_of_week: dayOfWeek}
	const teamDay = useFind('team_template_days', query)
	return (
		<div className="default-locks-table-cell">
			<div className="default-locks-table-inner-cell">
				<Form initialValues={teamDay} onSubmit={data => API.create('team_template_days', {...data, ...query})}>
					<SimpleInputNumber submitStrategy="throttledChange" field="min_av_count" min={1} max={team.user_ids.length} placeholder={'-'} />
				</Form>
			</div>
		</div>
	)
}

const WeekDay = ({name}) => (
	<div className="day-of-week-header">
		<h4>{t(name)}</h4>
	</div>
)

export default TemplateTeamDays
