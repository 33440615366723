import {Button} from 'antd'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import {history} from 'index'
import {t} from 'initializers/i18n'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import useTopic from '../topics/use_topic'
import Article from './article'
import {ProgressDots} from './components'
import {SingleOverview} from './overview'
import QuizItem from './quiz_item'
import '../index.less'

const Index = ({initialIdx = 0, onNextQuizItem, onNextArticle, increment, test}) => {
	const {id} = useParams()
	const modalRef = useRef(null)
	const userId = useSelector(state => state.auth.user.id)
	const [quizVal, setQuizVal] = useState(null)
	const [activeIdx, setIdx] = useState(initialIdx)
	const [done, setDone] = useState(false)
	const topic = useTopic()
	const {publishedTopicResources: topicResources, topicQuizItems} = topic
	const totalLen = topicResources.length

	const topicResource = topicResources[activeIdx] || {}
	const isArticle = !!topicResource?.title

	const [mayPass, setMayPass] = useState()

	useEffect(() => {
		setMayPass(isArticle)
		setQuizVal(null)
	}, [activeIdx])

	useEffect(() => {
		if (modalRef && modalRef.current) {
			modalRef.current.scrollTo(0, 0)
		}
	}, [activeIdx])

	const next = async () => {
		const nextFunc = isArticle ? onNextArticle : onNextQuizItem

		if (nextFunc) {
			const res = await nextFunc(topicResource, quizVal, userId)
			if (!res || !res.ok) return
		}
		_increment()
	}

	const defaultIncrement = () => {
		const nextIdx = activeIdx + 1
		return {
			done: nextIdx >= totalLen,
			nextIdx,
		}
	}

	const _increment = () => {
		const incrementFunc = increment || defaultIncrement
		const {nextIdx, done} = incrementFunc({activeIdx})
		if (done) {
			setDone(true)
		}
		setIdx(nextIdx)
	}

	const returnToPreviousItem = () => {
		if (activeIdx <= 0) return
		setQuizVal(null)
		setIdx(s => s - 1)
	}

	if (!topicResource || (done && test)) {
		return <Redirect to={`/topics/${id}`} />
	}

	return (
		<Modal size="fs" ref={modalRef} closable={false} hideClose footerBackButton={false}>
			<div className="modal-fullscreen-header">
				<div className="modal-fullscreen-header-left">
					<Button onClick={history.goBack} style={{width: 'fit-content', minWidth: 'fit-content'}}>
						{t('leaveTraining')}
					</Button>
				</div>

				<div className="modal-fullscreen-header-center">
					<ProgressDots total={totalLen} currentIdx={done ? totalLen : activeIdx} />
				</div>

				<div className="modal-fullscreen-header-right">
					{!done && !!activeIdx && <Button onClick={returnToPreviousItem}>{t('previousStep')}</Button>}

					{!done && (
						<Button onClick={next} disabled={!mayPass}>
							{t('nextStep')}
						</Button>
					)}
				</div>
			</div>

			<div className="modal-fullscreen-body">
				{done && <SingleOverview />}

				{!done && (
					<>
						{isArticle && (
							<div className="very-large-padding-top">
								<Article item={topicResource} />
							</div>
						)}

						{!isArticle && (
							<QuizItem
								setVal={setQuizVal}
								setMayPass={setMayPass}
								idx={topicQuizItems.findIndex(q => q.id == topicResource.id)}
								item={topicResource}
							/>
						)}
					</>
				)}
			</div>
		</Modal>
	)
}

export default Index
