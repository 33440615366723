import useEnvSetting from 'hooks/use_env_setting'
import {validateDayStart} from 'cores/availability/index'

const useValidateDayStart = () => {
	const newDayStart = useEnvSetting('uren_reg', 'new_day_start')
	const validateFromTill = (value, props) => validateDayStart(props.getFormData(), newDayStart)

	return validateFromTill
}

export default useValidateDayStart
