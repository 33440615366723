import useEventListener from 'hooks/use_event_listener'
import {useState} from 'react'

export const useIsDragging = mousedown => {
	const [isDragging, setIsDragging] = useState()

	const mouseMoveHandler = () => setIsDragging(mousedown)

	useEventListener('mousemove', mouseMoveHandler)

	return isDragging
}
