import React from 'react'
import {RecordErrorsButton} from 'shared'
import {PerMemberProvider, usePerMemberShifts} from 'cores/time_registration/index'
import {PageHeader} from './page_header'
import {TimeRegistrationAdvancedTable} from 'common/components/advanced_table/instances/time_registration'
import SearchProvider from 'contexts/search'
import {exportedFilterObject, statusFilterObject} from 'cores/time_registration/helpers/status_filter'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import {useMultiEdit} from 'hooks'

const _PerMember = () => {
	const items = usePerMemberShifts()
	const multiEditProps = useMultiEdit()

	const filters = ['environment', 'team', statusFilterObject, exportedFilterObject('common.exported')]
	const rightElements = [<RecordErrorsButton recordTypes={['timeRegistrationShifts', 'planningShifts']} />]

	return (
		<SearchProvider>
			<FilterFooterProvider id="time_registration" items={items} filters={filters} rightElements={rightElements} role="uren_schrijven">
				<PageLayout state={multiEditProps} name="hours-per-member" headers={<PageHeader />} footers={<FilterFooter />}>
					<TimeRegistrationAdvancedTable />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}

const Wrapper = () => {
	return (
		<PerMemberProvider>
			<_PerMember />
		</PerMemberProvider>
	)
}

function areEqual() {
	return true
}

export const PerMember = React.memo(Wrapper, areEqual)
