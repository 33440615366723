import {Layout, Text} from 'common/components'
import utils from '@eitje/web_utils'
import {Link} from 'components/routing'
import {NAMESPACE} from 'cores/auth'
import {logout} from 'actions/auth'

const TAXONOMY = {
	forgot_password: {
		link: '/forgot_password',
	},
	create_environment: {
		link: `/create_account?back_button=true`,
	},
	logout: {
		onClick: logout,
	},
}

export const CallToActions = ({callToActions}) => {
	if (!callToActions) return null
	const ctas = utils.alwaysArray(callToActions).map(cta => ({key: cta, ...TAXONOMY[cta]}))

	return (
		<Layout direction="vertical" gap={12} horizontal="center">
			{ctas.map(cta => {
				const {key, link, onClick} = cta
				return <Link to={link} onClick={onClick} styleType="hyperlink" t={`${NAMESPACE}.call_to_actions.${key}`} />
			})}
		</Layout>
	)
}
