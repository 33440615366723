import {t} from 'initializers/i18n'
import {STATUSSES} from '../constants'
import utils from '@eitje/utils'

const statusT = label => {
	// We use a different label here to make it more clear what "goedgekeurd" means in this context
	if (label === 'worked') label = 'gewerkte_uren'
	return t(`time_registration.statusses.${label}`)
}

const statusFilterFunction = (shift, statusses) => {
	let status = shift.regStatus() //
	return !statusses.length || statusses.includes(status)
}

export const statusFilterObject = {
	name: 'status',
	items: [...STATUSSES.unapproved, {name: 'approved', children: STATUSSES.approved}],
	labelT: statusT,
	filterFunction: statusFilterFunction,
}

// Use fn over object to pass title to translate on locale switch
export const hoursFilterObject = title => {
	return {
		name: 'hours',
		items: ['with_hours', 'without_hours'],
		filterFunction: (item, filterValue) => {
			// Although we filter rows, return false for the item if it is not visible, to maintain integrity for filteredItemsCount
			// We should fix this within the FilterFooter
			const filter = filterValue[0]
			return !filter || filter == 'with_hours'
		},
		labelT: label => t(`time_registration.${label}`),
		title,
	}
}

// Use fn over object to pass title to translate on locale switch
export const exportedFilterObject = title => {
	return {
		name: 'exported',
		items: ['exported', 'not_exported'],
		filterFunction: (item, filterValue) => {
			const exported = item.exported()
			if (!utils.exists(filterValue)) return true
			if (filterValue.length == 2) return true
			return filterValue == 'exported' ? exported : !exported
		},
		title,
		labelT: label => t(`${label == 'exported' ? 'yes' : 'no'}`),
	}
}
