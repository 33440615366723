import {date} from 'initializers/date'

export const sortRowsOnTimes = ({accessorFn, row1, row2}) => {
	const value1 = accessorFn(row1)
	const value2 = accessorFn(row2)

	if (!value1 && value2) return -1
	if (value1 && !value2) return 1
	if (!value1 && !value2) return 0

	const compare1 = dateToSortableValue(date(value1))
	const compare2 = dateToSortableValue(date(value2))

	let value
	if (compare1 < compare2) value = -1
	if (compare1 > compare2) value = 1
	if (compare1 === compare2) value = 0

	return value
}

const dateToSortableValue = date => {
	return parseInt(`${date.hours()}${date.minutes().toString().padStart(2, '0')}`)
}

export const dateToTime = _date => {
	// Use this helper in the accessorFn for TimeCell instances. Initially we used moment objects as values (by having the column's accessorFn
	// return a moment object), but this screws the grouping of times when looking at a period bigger than one day. Identical values, e.g. two times
	// '18:00', were not grouped if they're not on the same date and therefore where not equal values but were displayed as equal values.
	return _date && date(_date).format('HH:mm')
}
