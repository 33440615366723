import {Fragment, useRef} from 'react'
import utils from '@eitje/utils'
import {Input} from 'antd'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {useState} from 'react'
import {useBodyHotkey} from 'hooks/index'
import './styles/use_search.less'
import {makeCnVariants} from 'helpers'
import {useDebounce} from 'use-debounce/lib'
import {EitjeButton, Placeholder} from 'common/components'
import {isEmptyObject} from 'helpers/object'

export const filterItems = (items, query, {searchField = 'name', filterFunc, showSearch = true} = {}) => {
	if (!showSearch || !utils.exists(query)) return items
	if (filterFunc) return filterFunc(items, query)
	return utils.search(items, query, {field: searchField})
}

const useSearch = (items, props = {}) => {
	const {search, setSearch, searchInput, focusSearchInput} = useSearchInput(props)
	const {searchEmptyProps = {}, placeholderProps, alwaysShowPlaceholder, hideEmptyPlaceholder} = props
	const filteredItems = filterItems(items, search, props)

	const emptyElement =
		(filteredItems.length == 0 || alwaysShowPlaceholder) &&
		(!search
			? !isEmptyObject(placeholderProps) && <Placeholder {...placeholderProps} />
			: !hideEmptyPlaceholder && (
					<SearchEmpty
						action={() => {
							setSearch(null)
							focusSearchInput()
						}}
						{...searchEmptyProps}
					/>
			  ))

	return {
		filteredItems,
		searchItems: (items, _props = {}) => filterItems(items, search, {...props, ..._props}),
		search,
		setSearch,
		searchInput: items.length > 0 && searchInput,
		emptyElement,
		focusSearchInput,
	}
}
export default useSearch

export const useSearchInput = ({
	inputProps,
	enableHotkey,
	placeholder = t('typeToSearch'),
	defaultSearch,
	className,
	color,
	wrapperClassName,
	debounce,
} = {}) => {
	const [search, setSearch] = useState(defaultSearch)
	const [hasFocus, setHasFocus] = useState()
	const isActive = hasFocus || search
	const inputClassName = utils.makeCns(makeCnVariants('search-filter', color, isActive && 'active'), className)
	const searchInputRef = useRef(null)
	const clearSearch = () => setSearch('')
	const Wrapper = wrapperClassName ? 'div' : Fragment
	const searchInputProps = {
		placeholder,
		value: search,
		autocomplete: 'off',
		className: inputClassName,
		onChange: e => setSearch(e.target.value),
		...inputProps,
	}

	const [debouncedSearch] = useDebounce(search, debounce || 0)
	const focusSearchInput = () => searchInputRef.current?.focus()

	const handleClearClick = () => {
		setSearch('')
		focusSearchInput()
	}

	const searchInput = (
		<Wrapper className={wrapperClassName}>
			<div className="search-container">
				<img className="search-icon" src={`/button_icons/icon-search-${isActive ? 'blue' : 'black'}.png`} />
				{search && <img onClick={handleClearClick} className="search-clear-icon" src="/button_icons/icon-cross-circled-blue.png" />}
				<Input
					onFocus={() => setHasFocus(true)}
					onBlur={() => setHasFocus(false)}
					ref={searchInputRef}
					id={'search-filter'}
					{...searchInputProps}
				/>
				{enableHotkey && <BodyHotkey searchInputRef={searchInputRef} />}
			</div>
		</Wrapper>
	)
	return {search: debouncedSearch, focusSearchInput, setSearch, searchInput, searchInputRef, clearSearch}
}

const BodyHotkey = ({searchInputRef}) => {
	useBodyHotkey({ctrl_k: () => searchInputRef?.current?.focus()})
	return null
}

export const SearchEmpty = props => {
	const {action = _.noop, ...rest} = props
	return (
		<Placeholder animation="magnifying-glass" title="common.no_results" subtitle={false} {...rest}>
			{props.action && <EitjeButton onClick={action} iconLeft="trash" t="common.clear_search" />}
		</Placeholder>
	)
}
