import {Children, useRef} from 'react'
import {BasicPopout} from '@eitje/web_components'
import {BackButton} from 'common/components/eitje_button/eitje_buttons'
import {t} from 'initializers/i18n'
import EitjeButton from 'common/components/eitje_button'

export const DeletePopout = ({
	buttons,
	title = t('popouts.delete.title'),
	text = t('popouts.delete.text'),
	onCancel = _.noop,
	onDelete,
	deleteText = t('delete'),
	disabled,
	nonDestructive,
	...rest
}) => {
	const ref = useRef()

	const handleCancel = e => {
		ref.current?.hide()
		onCancel(e)
	}

	const handleDelete = e => {
		ref.current?.hide()
		onDelete(e)
	}

	if (nonDestructive) {
		text = t('popouts.delete.non_destructive_text')
	}

	const _buttons = [
		<BackButton onClick={handleCancel} />,
		...Children.toArray(buttons),
		onDelete && (
			<EitjeButton iconLeft="trash" colorSet="solid" baseColor="red" onClick={handleDelete} children={deleteText} disabled={disabled} />
		),
	]

	return <BasicPopout trigger="click" ref={ref} title={title} text={text} buttons={_buttons} disabled={disabled} {...rest} />
}
