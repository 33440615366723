import utils from '@eitje/web_utils'
import {useCountup} from '@eitje/react-hooks'

export const Clock = ({start}) => {
	const timeElapsed = useClock(start)
	return <h5 className="clock"> {timeElapsed} </h5>
}

export const useClock = start => {
	const timeElapsed = useCountup({defaultTime: start})
	return utils.secondsToTimeString(timeElapsed)
}
