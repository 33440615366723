import {Children, useRef} from 'react'
import utils from '@eitje/web_utils'
import {getSpacing} from '@eitje/web_components'
import {addDividers, setChildrenClassNames, setPaddingCustomProperties, unwrapFragments} from './helpers'
import './styles/index.less'

export const GridLayout = props => {
	let {children, columns = 'max-content 1fr', gap = 16, rowPadding = '10 16', margin, padding, className} = props
	const gridLayoutRef = useRef()

	let rowLength = columns.split(' ').length

	if (gap) {
		// When a row is wrapped in a Fragment, unwrap it so every child is top level
		children = Children.toArray(children).flatMap(unwrapFragments)

		// Add a div in between each item in a row that acts as a gap
		children = children.flatMap((c, i) => addDividers(c, i, rowLength))

		// Add this gap to grid-template-columns css
		columns = columns.split(' ').join(` ${gap}px `)
	}

	if (rowPadding && gridLayoutRef?.current) {
		setChildrenClassNames(gridLayoutRef, rowLength, gap)
	}

	const styles = {
		gridTemplateColumns: columns,
		margin: getSpacing(margin),
		padding: getSpacing(padding),
		...(rowPadding && setPaddingCustomProperties(rowPadding)),
	}

	const classNames = utils.makeCns('grid-layout', className)

	return (
		<div ref={gridLayoutRef} style={styles} className={classNames}>
			{children}
		</div>
	)
}
