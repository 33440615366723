import {ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'

const useTeamDropdown = ({defValue = [], ...rest} = {}) => {
	const [teamIds, setTeamIds] = useState(defValue)
	const envTeams = useSelector(state => inEnv(state, 'teams'))
	const dropdown = (
		<ListPicker
			raw
			dropdown
			onChange={val => setTeamIds(val)}
			title={t('filterOnTeam')}
			value={teamIds}
			items={envTeams}
			labelField="naam"
			{...rest}
		/>
	)
	const teams = envTeams.filter(e => teamIds.includes(e.id))
	return {dropdown, teamIds, teams}
}

export default useTeamDropdown
