import Table from './table'
import MultiAction from './multi_action'

export * from './table'

// use explicit div style instead of Layout properties because the inner virtuoso and Layout don't play together well
const style = {width: '100%'}

const Body = () => {
	return (
		<div className="time-reg-per-week-body" style={style}>
			<Table />
			<MultiAction />
		</div>
	)
}

export default Body
