import {useMemo} from 'react'
import Association from 'models/active_record/association'
import {useFilterFooterContext} from 'common/components'

// Only use this hook if you need a part of the shifts on page. If you need all, use the
// filteredItems from the FilterFooterContext instead.

const useFilteredShifts = () => {
	const {filteredItems} = useFilterFooterContext()

	const checkIns = useMemo(() => filteredItems.where({tableName: 'checkIns'}), [filteredItems])
	const regShifts = useMemo(() => filteredItems.where({tableName: 'timeRegistrationShifts'}), [filteredItems])
	const planShifts = useMemo(() => filteredItems.where({tableName: 'planningShifts'}), [filteredItems])

	const _obj = {regShifts, planShifts, checkIns}
	const obj = useMemo(() => _obj, [filteredItems])

	return obj
}

export default useFilteredShifts
