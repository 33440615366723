import _ from 'lodash'
import _createCachedSelector from 're-reselect'

export const createCachedSelector = (...args) => {
	args = _.flatten(args)
	return _createCachedSelector(...args)(buildCacheKey)
}

function buildCacheKey() {
	let str = ''
	const args = Array.from(arguments).slice(1, arguments.length) // first arg is always state
	args.forEach(arg => {
		str += `${makeCacheItem(arg)}-` // - is important to ensure 64 46 doesnt produce same result a 6 464
	})
	const key = str.hashCode()
	return key
}

const makeCacheItem = arg => {
	if (_.isNumber(arg) || _.isString(arg)) return arg
	if (_.isArray(arg)) return arrToCacheKey(arg) // use char that's never used in strings to prevent potential conflict
	if (_.isPlainObject(arg)) return JSON.stringify(arg)
}

const arrToCacheKey = arr => {
	return arr.map(a => makeCacheItem(a))
}

export default createCachedSelector
