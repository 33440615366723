import React, {Component, Fragment} from 'react'
import * as Sentry from '@sentry/browser'
import {EitjeButton, Placeholder} from 'common/components'
import {logout} from 'actions/auth'

const layoutProps = {
  padding: [180, 0],
}

class OuterErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
    this.fixError = this.fixError.bind(this)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(error.stack)
    return {hasError: true, error: error.message, stack: error.stack}
  }

  componentDidCatch(error) {
    if (error.name === 'ChunkLoadError') {
      // Refresh the page
      window.location.reload()
      return
    }

    const id = Sentry?.captureException?.(error)
    this.setState({errorId: id})
  }

  fixError() {
    this.setState({hasError: false})
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return (
        <Fragment>
          <ErrorView fixError={this.fixError} id={this.state.errorId} />
        </Fragment>
      )
    }

    return this.props.children
  }
}

const ErrorView = ({id, fixError}) => {
  const _logout = async () => {
    await logout()
    fixError()
  }

  return (
    <div id="error-page-500">
      <Placeholder layoutProps={layoutProps} name="oops" animation="broken-egg" supportButton>
        <EitjeButton iconLeft="arrow-circle" blockNamespace t="signOut" colorSet="solid" onClick={_logout} />
      </Placeholder>

      {id && <p> errorID: {id} </p>}
    </div>
  )
}

export default OuterErrorBoundary
