import {t} from 'initializers/i18n'
import {useModalContext} from 'components/general/modal'
import utils from '@eitje/web_utils'
import {date} from 'initializers/date'
import {useForm} from '@eitje/form'
import {FormSubmitButton} from 'components/form'
import './styles/footer.less'
import useHasRole from 'hooks/use_has_role'
import {ShiftTime, ShiftCosts, Audits} from 'components/shared/index'
import {DeleteButton, Layout, EitjeButton} from 'common/components'
import {history} from 'initializers/history'

const omitKeys = ['shift_date', 'swap_shift_date']

const Footer = ({env, shift = {}, localFormShift}) => {
	const {getData} = useForm()
	const shiftData = getData()

	const {audits = []} = shift
	const relevantAudits = audits.filter(a => utils.exists(a.changes))

	audits.forEach(a => (a.changes = _.omit(a.changes, omitKeys))) // should we incorporate this as props?

	const {goBack} = useModalContext()
	const hasContractRole = useHasRole('contracten', env.id)

	return (
		<Layout className="shift-form-footer" padding={16} horizontal="spaceBetween">
			<Layout>
				<ShiftTime shift={localFormShift} />
				{shiftData.user_id && hasContractRole && <ShiftCosts data={shiftData} placement="top" />}
				{relevantAudits.length > 0 && <Audits getAuditValue={getAuditValue} audits={relevantAudits} placement="top" />}
			</Layout>
			<Layout>
				<EitjeButton iconLeft="arrow-small-left" onClick={goBack}>
					{t('common.close')}
				</EitjeButton>
				{shift.id && !shift.reg_shift_id && (
					<DeleteButton onDel={shift.destroy} afterDel={history.goBack}>
						{t('common.delete')}
					</DeleteButton>
				)}
				{!shift?.reg_shift_id && <FormSubmitButton text={t('common.save')} />}
			</Layout>
		</Layout>
	)
}

const getAuditValue = props => {
	const {keyName, value} = props
	if (keyName == 'user_id' && !value) return t('common.open_shift')
	if (keyName == 'start' || keyName == 'end') return date(value).format('HH:mm')
}

export default Footer
