export * from './column_picker'
export * from './grouping_picker'

import {ColumnPicker} from './column_picker'
import {GroupingPicker} from './grouping_picker'

export const ColumnGroupingPickers = props => {
	return (
		<>
			<ColumnPicker {...props} />
			<GroupingPicker {...props} />
		</>
	)
}
