import utils from '@eitje/web_utils'

export const makeCnVariants = (baseName, ...rest) => {
	return utils.makeCns(
		baseName,
		rest.filter(Boolean).map(cn => baseName + '-' + cn),
	)
}

export const validCssSize = size => {
	const knownUnits = ['em', 'rem', 'px', 'vh', '%', 'vw', 'calc(', 'auto', 'unset']

	if (!isNaN(size)) return `${size}px`

	return typeof size === 'string' && knownUnits.some(unit => size.includes(unit)) && size
}

export const formatCssVars = obj => {
	return Object.keys(obj).reduce((result, key) => {
		result['--' + _.kebabCase(key)] = obj[key]
		return result
	}, {})
}

export const getCssVariables = obj => _.pickBy(obj, (value, key) => _.startsWith(key, '--'))
