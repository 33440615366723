import {Team} from 'models'
import {prettyPrintArray} from 'helpers'

export const TeamCell = ({getValue}) => {
	const id = getValue()
	const team = Team.find(id)
	return team.naam || null
}

export const TeamsCell = ({getValue}) => {
	const teams = getValue()
	if (!teams) return null

	const names = teams._map('naam')
	return prettyPrintArray({array: names, joinWord: 'and', quotes: false})
}
