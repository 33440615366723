import {createCachedSelector} from 're-reselect'
import {createSelector} from 'reselect'

export const trainingItemsSelector = state => state.arbitrary.training.items || []
export const usersCompletedTraining = state => state.arbitrary.training.done_ids || []

export const promptableTraining = createSelector(trainingItemsSelector, items => items.find(i => i.promptable) || {})

export const nextTrainingItem = createSelector(trainingItemsSelector, trainingItems => trainingItems[0] || {})

export const topicIsTrainable = createCachedSelector(
	trainingItemsSelector,
	nextTrainingItem,
	(state, topicId) => topicId,
	(trainingItems, nextItem, topicId) => trainingItems.find(t => t.id === topicId && t.trainable),
)((state, topicId) => topicId)

export const hasTrainingResource = createCachedSelector(
	trainingItemsSelector,
	(state, topicId) => topicId,
	(trainingItems, topicId) => trainingItems.find(t => t.id === topicId),
)((state, topicId) => topicId)

// een schouwspel van emoties
