import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {PlanningTemplate} from 'models/index'
import EitjeButton from 'common/components/eitje_button'
import TemplateSummary from './template_summary'
import {useEffect} from 'react'
import {useForm} from '@eitje/form'
import {useQueryParams, useRoleOrgEnvs, useFormData} from 'hooks'
import {ListPicker} from 'common/components/list_picker'
import utils from '@eitje/web_utils'
import {usePlanningDates} from 'cores/planning'
import dateFn from 'initializers/date'
import {Team, PlanningShift} from 'models'

const periodsAmounts = {
	week: 6,
	month: 2,
}

const translatedWeekObj = utils.weekdays.map((day, index) => ({name: t(day), id: index}))
const weekOrMonth = ['week', 'month']

const LoadForm = ({id, kind}) => {
	const {setValues} = useForm()
	const {week_or_month} = useFormData()
	let {team_ids: teamIdsFromUrl} = useQueryParams()
	const template = PlanningTemplate.find(id)

	const managerEnvs = useRoleOrgEnvs('manager')
	const managerTeams = Team.where({environment_id: managerEnvs._map('id')})

	if (!utils.exists(teamIdsFromUrl)) {
		teamIdsFromUrl = managerTeams._map('id')
	}

	const {dateRange} = usePlanningDates()

	const planningShifts = PlanningShift.where({team_id: teamIdsFromUrl, date: dateRange})

	const placeholderProps = {
		animation: 'roster',
		name: 'no_template',
		docsId: 6009519,
		children: (
			<EitjeButton
				disabled={planningShifts.length == 0 && t('planning.save_template.disabled')}
				iconLeft="plus"
				colorSet="solid"
				t="action_button"
				modalLink="/planning_templates/save"
			/>
		),
	}

	let planningTemplates = PlanningTemplate.where({team_ids: teamIdsFromUrl}).map(t => t.withEnv())
	if (kind) {
		planningTemplates = planningTemplates.filter(p => (kind == 'day' ? p.isDay() : !p.isDay()))
	}
	const opts = [
		{name: t('planning.load_template.only_shifts'), id: true},
		{name: t('planning.load_template.shifts_and_teammembers'), id: false},
	]

	useEffect(() => {
		setValues({periods: []})
	}, [week_or_month])

	useEffect(() => {
		!id && setValues({periods: []})
	}, [id])

	const isDay = template.isDay?.()

	return (
		<div className="eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<ListPicker
					single
					dropdown
					showSearch
					required
					field="id"
					labelField="nameWithPeriod"
					groupField="envStatus"
					items={planningTemplates}
					placeholderProps={placeholderProps}
				/>
			</FormRow>
			{id && (
				<>
					<TemplateSummary id={id} />
					<FormRow>
						<ListPicker single dropdown tooltip field="ignore_users" items={opts} />
					</FormRow>

					<FormRow>
						{isDay ? (
							<ListPicker title={t('common.days')} items={translatedWeekObj} noSort dropdown required field="days" />
						) : (
							<ListPicker single field="week_or_month" dropdown items={weekOrMonth} required noSort />
						)}
					</FormRow>

					{week_or_month && !isDay && (
						<FormRow>
							<PeriodPicker isWeek={week_or_month == 'week'} />
						</FormRow>
					)}
				</>
			)}
		</div>
	)
}

const PeriodPicker = ({isWeek}) => {
	const {startDate} = usePlanningDates()
	const periodType = isWeek ? 'week' : 'month'
	const amount = periodsAmounts[periodType]
	const prefix = isWeek ? 'Week ' : ''
	const format = isWeek ? 'W' : 'MMMM'
	const suffixFormat = 'DD/M'
	const items = [...new Array(amount)].map((_, i) => {
		const date = dateFn(startDate.clone()).add(i, periodType).startOf(periodType)
		const suffix = !isWeek ? '' : `(${date.format(suffixFormat)} - ${date.clone().endOf('week').format(suffixFormat)})`
		let periodNum = date[periodType]()
		if (!isWeek) periodNum += 1
		return {
			id: `${date.isoWeekYear()}-${periodNum}`,
			name: `${prefix} ${date.format(format)} ${suffix} `,
		}
	})

	const title = isWeek ? 'common.week' : 'common.month'

	return <ListPicker required title={t(title)} i18nOpts={{kind: t(title)}} dropdown items={items} field="periods" noSort />
}

export default LoadForm
