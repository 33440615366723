import React from 'react'
import {t} from 'initializers/i18n'
import FilterFooter from '../filter_footer'
import {useSchedulableOrgUsers} from 'hooks/use_schedulable_users'
import UserRow from './user_row/index'
import './styles/index.less'
import useList from 'hooks/use_list'
import {useTeamPanelFilter} from '../hooks/use_team_panel_filter'

const searchProps = {searchField: 'full_name', color: 'black'}

const Page = () => {
	const {filteredItems, date, setFilteredItems, prevFilteredItems} = useTeamPanelFilter()
	const users = useSchedulableOrgUsers()

	const {list, searchInput} = useList({
		items: filteredItems,
		showPlaceholder: true,
		Placeholder,
		allowDeactivate: true,
		searchProps,
		ListItem: UserRow,
		itemProps: {
			date,
		},
	})

	return (
		<div className="team-panel-balance">
			<Header />
			{list}
			<FilterFooter
				items={users}
				prevFilteredItems={prevFilteredItems}
				setFilteredItems={setFilteredItems}
				searchInput={searchInput}
				date={date}
			/>
		</div>
	)
}

const Placeholder = () => {
	return <h5 className="team-panel-balance-empty"> {t('team_panel.balance.no_users')} </h5>
}

const Header = () => {
	return (
		<div className="team-panel-balance-header">
			<h4>{t('common.teammember')} </h4>
			<h4>{t('common.total')}</h4>
		</div>
	)
}

export default Page
