import {all, where} from '@eitje/easy_api'
import {totalDays, totalMins} from 'helpers/schedule'
import {makeSingleContract} from 'hooks/use_contract'
import {date} from 'initializers/date'
import createCachedSelector from 're-reselect'
import {createSelector} from 'reselect'
import {getRoles, rolesSelector} from 'selectors/new_settings'
import {filterRangeByDate} from 'selectors/records'
// selector die useContracts nabootst, maar dan alleen op user-niveau (legacy heeft niet meer nodig geloof ik)

export const composedContractSelector = createCachedSelector(
	(state, userId = state?.auth?.user?.id) => where(state, 'contractHolders', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => where(state, 'salaries', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => where(state, 'userEmploymentTypes', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => all(state, 'employmentTypes'),
	(state, userId = state?.auth?.user?.id) => where(state, 'workSchedules', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => all(state, 'workWeeks'),
	(state, userId) => userId,
	(state, userId, date) => date,
	(holders, salaries, userEmploymentTypes, employmentTypes, workSchedules, workWeeks, userId, _date = date().format('YYYY-MM-DD')) => {
		const query = {start_date: _date}
		holders = filterRangeByDate(holders, query)
		salaries = filterRangeByDate(salaries, query)

		workSchedules = workSchedules.map(w => {
			const weeks = workWeeks.filter(w2 => w2.work_schedule_id === w.id)
			return {...w, totalMins: totalMins(weeks), totalDays: totalDays(weeks), workWeeks: weeks}
		})
		userEmploymentTypes = filterRangeByDate(userEmploymentTypes, query)
		if (holders.length == 0) return {}
		return makeSingleContract(holders[0], {salaries, workSchedules, employmentTypes, userEmpTypes: userEmploymentTypes})
	},
)((state, userId = state?.auth?.user?.id) => userId)

export const composedContractsSelector = createCachedSelector(
	(state, userId = state?.auth?.user?.id) => where(state, 'contractHolders', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => where(state, 'salaries', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => where(state, 'userEmploymentTypes', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => all(state, 'employmentTypes'),
	(state, userId = state?.auth?.user?.id) => where(state, 'workSchedules', {user_id: userId}),
	(state, userId = state?.auth?.user?.id) => all(state, 'workWeeks'),
	(state, userId) => userId,
	(state, userId, date) => date,
	(holders, salaries, userEmploymentTypes, employmentTypes, workSchedules, workWeeks, userId, _date = date().format('YYYY-MM-DD')) => {
		const query = {start_date: _date}
		// holders = filterRangeByDate(holders, query)
		// salaries = filterRangeByDate(salaries, query)

		workSchedules = workSchedules.map(w => {
			const weeks = workWeeks.filter(w2 => w2.work_schedule_id === w.id)
			return {...w, totalMins: totalMins(weeks), totalDays: totalDays(weeks), workWeeks: weeks}
		})
		// userEmploymentTypes = filterRangeByDate(userEmploymentTypes, query)
		if (holders.length == 0) return []
		return holders.map(h => makeSingleContract(h, {salaries, workSchedules, employmentTypes, userEmpTypes: userEmploymentTypes}))
	},
)((state, userId = state?.auth?.user?.id) => userId)

// selector for old-style roles

const makeStupidObj = (roles = []) => {
	let roleObj = {}
	roles.forEach(role => {
		roleObj[role] = true
	})
	return roleObj
}

export const legacyOrgRolesSelector = createSelector(
	state => state.settings.roles,
	roles => {
		let obj = {}
		Object.keys(roles).forEach(envId => (obj[envId] = makeStupidObj(getRoles(roles[envId]))))
		return obj
	},
)

export const legacyRolesSelector = createSelector(rolesSelector, makeStupidObj)
