import {ToolsButton, Text} from 'common/components'
import {ModalLink} from 'components/routing'
import {useIntegration} from 'components/hr_sync_button'
import {t} from 'initializers/i18n'
import {BasicPopout} from '@eitje/web_components'
import {useHasRoleInAnyEnv} from 'hooks'

export const Tools = props => {
	const {integration, sync} = useIntegration()
	const manager = useHasRoleInAnyEnv('manager')
	let syncLabel = `${t(`common.sync`)}`
	if (integration) syncLabel += ` ${integration.name}`

	const elements = [
		{
			icon: 'plus',
			element: (
				<ModalLink
					t="header.invite_team_members"
					to="/forms/invite"
					popoutTitle="header.invite_team_members"
					popoutBody={t('firms.popouts.add_users.body')}
					PopoutComponent={BasicPopout}
				/>
			),
			enableRole: 'manager',
		},
		{
			icon: 'plus',
			element: <ModalLink t="common.create_team" to="/team/new" />,
			enableRole: 'manager',
		},
		{
			icon: 'download',
			element: (
				<Text
					t={syncLabel}
					onClick={sync}
					popoutTitle={integration && syncLabel}
					popoutBody={integration && t('firms.popouts.sync_users.body')}
					PopoutComponent={BasicPopout}
				/>
			),
			enableRole: 'manager',
			disabled: manager && !integration && `disabled.no_active_hr_integration`,
		},
		{
			icon: 'building',
			element: <ModalLink t="header.firms" to="/environments" />,
			enableRole: 'manager',
		},
	]

	return <ToolsButton {...props} elements={elements} />
}
