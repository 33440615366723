import LeaveSummary from './leave_summary'
import UsersWithLeave from './users_with_leave'
import {useSimpleContext} from 'contexts/simple'
import './styles/index.less'
import {EitjeButton} from 'common/components'

const Page = () => {
	const {request, hasLeaveRole} = useSimpleContext()
	return (
		<div className="approve-leave-right-panel">
			<LeaveSummary />
			<UsersWithLeave />
			{hasLeaveRole && (
				<div className="view-period-button-container">
					<EitjeButton
						search={{start_date: request.startDate()}}
						height="small"
						width="full-minus-margin"
						link="/availability"
						t="leave.view_av_period"
					/>
				</div>
			)}
		</div>
	)
}

export default Page
