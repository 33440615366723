import {Layout, Text, Title} from 'common/components'
import {BODY_WIDTH} from 'cores/onboarding/pages/welcome'
import {t} from 'initializers/i18n'
import './index.less'

const PREVIEW_GAP = 16
const PREVIEW_WIDTH = (BODY_WIDTH - 2 * PREVIEW_GAP) / 3

export const VideoCollection = ({title, videos}) => {
	return (
		<Layout className="video-collection" direction="vertical" width="full" margin="40 - - -">
			<Layout borderBottom padding="- - 12 - " width="full">
				<Title fontSize={24} t={title} />
			</Layout>

			<Layout gap={PREVIEW_GAP} wrap>
				{videos.map(video => (
					<VideoPreview {...video} />
				))}
			</Layout>
		</Layout>
	)
}

const VideoPreview = ({id, modalLink, namespace}) => {
	const videoPreview = `/images/onboarding/${id}.png`
	const title = t(`${namespace}.preview_title`)
	const subtitle = t(`${namespace}.preview_subtitle`)
	const formattedKey = _.last(namespace.split('.')).replaceAll('_', '-')
	const analyticsId = `${formattedKey}-video-preview`

	return (
		<Layout
			className="video-preview"
			id={analyticsId}
			direction="vertical"
			width={PREVIEW_WIDTH}
			gap={12}
			margin="12 - - -"
			modalLink={modalLink}
		>
			<Layout gap={12} direction="vertical">
				<div className="video-placeholder" style={{width: PREVIEW_WIDTH}}>
					<img src={videoPreview} />
				</div>

				<Layout direction="vertical" gap={0}>
					<Text children={title} bold />
					<Text children={subtitle} small darkGrey />
				</Layout>
			</Layout>
		</Layout>
	)
}
