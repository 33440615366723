export const onSortingChange = ({sort, setSort, instanceTaxonomy}) => {
	const primarySort = sort?.[0]

	// The UI won't allow to set a secondary sort, but in some peculiar cases we want to automatically sort secondary, such
	// as for the time registration table where we always want to sort on date second.
	let secondarySort
	if (instanceTaxonomy.secondarySort) secondarySort = {id: instanceTaxonomy.secondarySort, desc: primarySort.desc}

	const sortings = [primarySort, secondarySort].filter(Boolean)
	setSort(sortings)
}
