import {t} from 'initializers/i18n'
import React, {useEffect, useState} from 'react'
import {FilterTrigger} from '@eitje/web_components'
import useTeamFilter from 'hooks/use_team_filter'
import useEnvFilter from 'hooks/use_env_filter'
import utils from '@eitje/web_utils'
import _ from 'lodash'
import {pickerMap} from 'helpers/array'
import {ListPicker} from 'common/components'

const SearchFooter = ({items, setFilteredItems, searchInput, date, prevFilteredItems, noContractTypeFilter}) => {
	const [contractFilter, setContractFilter] = useState()
	const {envs, filter: envsFilter} = useEnvFilter()
	const filterTeam = team => envs.ids().includes(team.environment_id)
	const {filter: teamFilter, activeTeamIds} = useTeamFilter([], {filterTeam})

	let filteredItems = items.filter(item => utils.intersects(activeTeamIds, item.user?.team_ids || item.team_ids))

	if (contractFilter) {
		filteredItems = filteredItems.filter(u => {
			return contractFilter.includes(u.userEmploymentType(date)?.name)
		})
	}

	useEffect(() => {
		if (!_.isEqual(prevFilteredItems, filteredItems)) {
			setFilteredItems(filteredItems)
		}
	}, [activeTeamIds, contractFilter, envs.ids()])

	const contractTypes = pickerMap(['Nul uren', 'Uren contract'])

	const contractTypeFilter = !noContractTypeFilter && (
		<ListPicker
			single
			raw
			dropdown
			allowClear
			Trigger={FilterTrigger}
			name={t('common.contract_type')}
			value={contractFilter}
			items={contractTypes}
			onChange={setContractFilter}
			labelField="name"
			placement="top"
		/>
	)

	return (
		<div className="search-footer">
			<div className="search-footer-dropdowns">
				{envsFilter}
				{teamFilter}
				{contractTypeFilter}
			</div>
			{searchInput}
		</div>
	)
}

export default SearchFooter
