import {RawCheckbox as Checkbox} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useStatusFilterValue} from 'cores/time_registration/index'
import useFilter, {setFilter} from 'hooks/use_filter'

export const StatusFilter = ({data, filterName, transKey, allText = 'all', statusses}) => {
	const _setFilter = val => setFilter(filterName, val)
	const length = Object.values(data).flat().length

	const setAll = () => _setFilter([])

	const filter = useFilter(filterName)
	const hasFilter = utils.exists(filter)
	const _t = key => t(`${transKey}.${key}`)

	return (
		<div className="per-member-filter status-filters">
			<h4 className="per-member-filter-title"> {t('common.status')} </h4>
			<InnerStatusFilter
				setFilter={_setFilter}
				t={_t}
				className={hasFilter ? '' : 'active'}
				onClick={setAll}
				count={length}
				text={allText}
			/>
			{Object.keys(statusses).map(s => (
				<NestedStatusFilters
					filterName={filterName}
					setFilter={_setFilter}
					t={_t}
					counts={data}
					status={s}
					subStatusses={statusses[s]}
					statusses={statusses}
				/>
			))}
		</div>
	)
}

const NestedStatusFilters = ({status, counts, filterName, subStatusses, ...rest}) => {
	const filter = useFilter(filterName)

	const isActive = filterIsActive(filter, status)
	const classNames = utils.makeCns('status-filter', isActive && 'active')

	const totalCount = subStatusses.map(s => counts[s]?.length || 0).sum()

	return (
		<div className={classNames}>
			<InnerStatusFilter count={totalCount} status={status} text={`statusses.${status}`} {...rest} />
		</div>
	)
}

const filterIsActive = (filter, status) => {
	return filter == status || (_.isObject(filter) && Object.keys(filter).includes(status))
}

const InnerStatusFilter = ({text, className, t, setFilter, status, onClick, count}) => {
	if (!onClick) onClick = () => setFilter({[status]: []})
	const classNames = utils.makeCns('status-filter', className)
	return (
		<div className={classNames}>
			<h5 className="per-member-filter-item" onClick={onClick}>
				{t(text)}
				<h5 className="per-member-filter-item-count"> {count || '-'} </h5>
			</h5>
		</div>
	)
}
