import {Icon} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {Layout, NewSideMenuModal, Text} from 'common/components'
import {MenuItem, ModalTitle, NmbrsSettings, useIntegration, useIntegrationConstants, usePushDone} from 'cores/integrations'
import {t} from 'initializers/i18n'
import {BaseConnection, Environment, ExtEnvironment} from 'models'
import MapEnvs from './map_envs'
import MapPayrollComponents from './map_payroll_components'
import {ConnectionSettings} from './settings/connection'

const settingsRoute = {name: 'settings', component: ConnectionSettings}

const Decider = ({isSub, ...props}) => (isSub ? <SubItem {...props} /> : <MenuItem {...props} />)

const SubItem = ({text, route}) => {
	const {item} = route
	const done = usePushDone(item.id)[item.id]

	const neverDone = route?.path.includes('wage_periods')

	return (
		<Layout gap={12}>
			{done && !neverDone && <Icon name="checked" />}
			<Text>{text} </Text>
		</Layout>
	)
}

const makeRecordRoutes = (items, component) => {
	return items.map(item => ({
		name: item.name,
		component,
		path: item.id,
		item,
	}))
}

export const SetupHr = () => {
	const routes = [
		{
			name: 'environments',
			component: MapEnvs,
			modalProps: {confirmSubmit: true, okButtonProps: {confirmText: t('modals.setup_hr.map_envs.confirm_text')}},
		},
	]

	const item = BaseConnection.show()
	const isNmbrs = item.sanitized_type == 'nmbrs'
	const {hasPush, hasSettings} = useIntegrationConstants(item.sanitized_type)

	const contextProps = {item}
	const {currentStep} = useIntegration(item.id)
	const sideMenuProps = {MenuItem: Decider, initialRoute: currentStep}
	const extEnvironments = ExtEnvironment.where(item.ext_environment_ids)
	const envs = Environment.where({id: extEnvironments._map('environment_ids').flat()})

	const payrollComponentsRoute = {
		name: 'payroll_components',
		subRoutes: envs.map(e => ({
			name: e.naam,
			component: MapPayrollComponents,
			path: e.id,
			item: e,
		})),
	}

	const activeExtEnvs = extEnvironments.filter(e => utils.exists(e.environment_ids))

	const nmbrsSettingsRoute = {
		name: 'wage_periods',
		subRoutes: makeRecordRoutes(activeExtEnvs, NmbrsSettings),
	}

	const _routes = [...routes, hasPush && payrollComponentsRoute, isNmbrs && nmbrsSettingsRoute, hasSettings && settingsRoute].filter(
		Boolean,
	)

	return (
		<NewSideMenuModal
			hideCancel
			title={<ModalTitle envs={envs} item={item} />}
			width={600}
			height={600}
			contextProps={contextProps}
			sideMenuProps={sideMenuProps}
			name="setup_hr"
			routes={_routes}
		/>
	)
}
