import {API} from '@eitje/easy_api'
import {UserImage} from 'components/ui'
import useShared from 'hooks/use_shared'
import _ from 'lodash'
import {t} from 'initializers/i18n'

export const makeTeamName = (team, orgEnvs) => {
	if (orgEnvs.length == 1) return team?.naam
	if (!team) return
	const env = orgEnvs.find(e => e.id == team.environment_id)
	if (!env) return team.naam
	return `${env.naam}: ${team.naam}`
}

export const topInputProps = isEdit => {
	const {me} = useShared()
	return isEdit
		? {extraEmoStyle: {right: 10}}
		: {
				leftChildren: <UserImage divClassName="ava40 floatL" extraStyle={{marginRight: 12, minWidth: '42px'}} user={me} />,
				containerStyle: {display: 'flex'},
				noEmoStyle: true,
				extraEmoStyle: {alignSelf: 'center'},
		  }
}

export const envTeamName = team => `${team.environment.naam}: ${team.naam}`

export const enforcePublish = publishType => {
	switch (publishType) {
		case 'sendNow':
			return {published: true, sync_moment: null}
		case 'saveDraft':
			return {published: false, sync_moment: null}
		case 'sendLater':
			return {published: false}
		default:
			return
	}
}

export const findPublishType = item => {
	if (item.published) return 'sendNow'
	if (!item.sync_moment) return 'saveDraft'
	return 'sendLater'
}

export const makeButtonText = (publishType, publishedStatus) => {
	if (publishedStatus) return t('save')
	if (publishType === 'sendNow') return t('send')
	return t(publishType)
}

export const submitPost = async ({post, poll_attributes = {}, answers_attributes = [], publishType}) => {
	const pollObj = {poll_attributes: {...poll_attributes, answers_attributes}}
	if (answers_attributes.length > 0) post = {...post, ...pollObj}
	return await API.create('posts', post)
}

export const outsideClickClassNames = [
	'ant-select-item-option-content',
	'sync-moment-input',
	'ant-picker-cell',
	'ant-picker-input',
	'ant-picker-time-panel-column',
	'ant-popover',
]

export const getFuturePost = (unpublishedArr, type) => {
	return unpublishedArr.filter(post => (type === 'scheduled' ? post.sync_moment : !post.sync_moment))
}

export const getOldestPost = posts => {
	return _.orderBy(posts, 'created_at', ['asc'])[0]
}
