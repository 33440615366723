import {ListPicker} from '../list_picker'
import {t} from 'initializers/i18n'
import {useShared} from 'hooks'
import {Team} from 'models'

export const TeamFilter = ({filterFunction, ...rest}) => {
	const {orgEnvs} = useShared()
	let teams = Team.all()

	if (filterFunction) {
		teams = teams.filter(filterFunction)
	}

	const teamEnvs = teams._map('environment_id').uniq()

	let teamSelectProps = {}
	if (teamEnvs.length > 1) {
		teamSelectProps = {groupField: 'environment_id', groupItems: orgEnvs, groupLabelField: 'naam', groupReadOnly: true}
	}

	return (
		<ListPicker
			placeholder={t('teams')}
			collapseGroups
			title={t('teams')}
			labelField="naam"
			items={teams}
			{...teamSelectProps}
			{...rest}
			name="teams"
		/>
	)
}
