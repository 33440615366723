import {PlanningShift} from 'models/index'
import ApproveSwap from './approve_swap'
import ApprovePickup from './approve_pickup'
import './styles/index.less'
import ShiftFetcher from './shift_fetcher'

const Decider = ({item, ...props}) => {
	const shift = PlanningShift.find(item.shift_id)
	if (!shift.id) return <ShiftFetcher id={item.shift_id} />
	if (item.swap_shift_id) return <ApproveSwap {...props} shift={shift} item={item} />
	return <ApprovePickup {...props} shift={shift} item={item} />
}

export default Decider
