import React, {useContext} from 'react'
import {filterRecords} from '@eitje/easy_api'
import {DefaultListPickerTrigger} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {useAvContext} from 'contexts/availability'
import {usePlanningTeams, useSaveFiltersOnLeave} from 'cores/planning/index'
import {useAvailability, useFilter, useSchedulableUsers, filterItems as searchItems} from 'hooks'
import useTeamFilter from 'hooks/use_team_filter'

const EnvContext = React.createContext({})
const {Provider} = EnvContext

const EnvContextWrapper = ({env, children}) => {
	const filterName = `planningTeams-${env.id}`
	const {isLocked, firstOpenDate, openRange, settings} = useAvailability(env.id)
	const teams = usePlanningTeams(env)
	const initialActiveTeams = useFilter(filterName)

	const {
		teamFilter,
		filter: filterElement,
		teamFilterState,
		activeTeams,
	} = useTeamFilter([], {
		initialValue: initialActiveTeams,
		Trigger: DefaultListPickerTrigger,
		overwriteTeams: teams,
	})

	useSaveFiltersOnLeave({activeTeams, teams, filterName})

	const {userSearch, contractTypeFilter, dateRange} = useAvContext()
	let baseUsers = useSchedulableUsers(env.id)
	let filteredUsers = searchItems(baseUsers, userSearch, {searchField: 'full_name'})
	filteredUsers = filteredUsers.map(u => ({
		...u,
		contract_types: (u.userEmploymentTypes || []).filter(e => e.dateRange.overlaps(dateRange)).map(e => e.name),
	}))
	let query = {}
	if (utils.exists(contractTypeFilter)) query['contract_types'] = contractTypeFilter
	if (utils.exists(teamFilterState)) query['team_ids'] = teamFilterState

	if (utils.exists(query)) {
		filteredUsers = filterRecords(filteredUsers, query)
	}

	const context = {
		env,
		teamFilterElement: filterElement,
		filterElement,
		settings,
		teams,
		openRange,
		isLocked,
		firstOpenDate,
		allUsers: baseUsers,
		teamFilter,
		users: filteredUsers,
	}
	return <Provider value={context}>{children}</Provider>
}

export default EnvContextWrapper

export const useEnvContext = () => {
	return useContext(EnvContext)
}
