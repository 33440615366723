import {CheckOutlined, EditOutlined} from '@ant-design/icons'
import {backend, whereNot} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker, LegacyDatePicker as FormDatePicker} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {getSettings, updateSettings} from 'actions/settings'
import {Button, DatePicker, Input, InputNumber, Modal, Select, Slider, Spin, Steps, Switch, TimePicker} from 'antd'
import GooglePlaces from 'components/google_places'
import ToeslagModal, {ToeslagKoppelModal} from 'components/modals/toeslag_modal'
import {ModalLink} from 'components/routing'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import Moment from 'moment'
import QueryString from 'query-string'
import React, {useRef} from 'react'
import {connect, useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {getEnvSettings} from 'selectors/new_settings'
import * as jsUtils from 'utils/jsutils'
const Step = Steps.Step
const Option = Select.Option
import {AvSettings, TimeRegSettings, OpenAPI} from 'cores/settings/components'
import './style/settings.less'
import {CreateOrActiveButton, EitjeButton, Text} from 'common/components'
import {SubmitPopout} from 'common/components'
import {isProd} from 'constants/general'
import {useLocal} from 'hooks'

const notJanOrDec = date => {
	return date.month() != 0 && date.month() != 11
}

const WagePeriodModal = ({afterSubmit, onCancel}) => {
	const settings = useSelector(state => getEnvSettings(state))
	const form = useRef(null)

	return (
		<Modal className="veedree modalEitje" title={t('salaryPeriodSet')} visible onCancel={onCancel} onOk={() => form.current.submit()}>
			<div className="modalOverflow">
				<Form
					ref={form}
					fieldProps={{bordered: false}}
					afterSubmit={() => {
						afterSubmit()
						onCancel()
					}}
					styleKind="modal"
					onSubmit={data => updateSettings(data)}
					initialValues={settings}
				>
					<div className="modalRowHeader">
						<p>{t('nul_uren')}</p>
					</div>
					<div fieldWrapper className="form-row">
						<FormDatePicker namespace="nul_uren" field="verloning_start" disabledFn={notJanOrDec} />
						<DropdownPicker namespace="nul_uren" field="verloning_type" items={['wekelijks', '4-wekelijks', 'maandelijks']} />
					</div>

					<div className="modalRowHeader">
						<p>{t('fixedHours')}</p>
					</div>

					<FormDatePicker namespace="uren" field="verloning_start" disabledFn={notJanOrDec} />
					<div fieldWrapper className="form-row">
						<DropdownPicker namespace="uren" field="verloning_type" items={['wekelijks', '4-wekelijks', 'maandelijks']} />
						<DropdownPicker
							namespace="uren"
							buildLabel={num => `${num} Hours`}
							label={t('settings_contracts_hvl_tijd_text')}
							field="hvl_tijd"
							items={[36, 38, 40, 42]}
						/>
					</div>

					<div className="modalRowHeader">
						<p>{t('payroll')}</p>
					</div>
					<div fieldWrapper className="form-row">
						<FormDatePicker namespace="payroll" field="verloning_start" disabledFn={notJanOrDec} />
						<DropdownPicker namespace="payroll" field="verloning_type" items={['wekelijks', '4-wekelijks', 'maandelijks']} />
					</div>
				</Form>
			</div>
		</Modal>
	)
}

const help = (msg, desc) => {
	utils.infoNotif({
		title: msg,
		content: desc,
	})
}

class KoppelModal extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			active: props.active,
			completed: false,
			step: 0,
			key: props.key,
			username: props.username,
			companies: [],
			hasSavedComp: props.isAuth,
			progress: props.progress + 1 || 1,
			isAuth: props.isAuth,
			loading: false,
			companies: props.companies || [],
		}

		this.change = this.change.bind(this)
		this.submit = this.submit.bind(this)
		this.canSubmit = this.canSubmit.bind(this)
		this.connect = this.connect.bind(this)
		this.saveCompany = this.saveCompany.bind(this)
		this.syncUsers = this.syncUsers.bind(this)
		this.resetIntegration = this.resetIntegration.bind(this)
	}

	async submit() {
		const {load} = this.props
		const {active} = this.state
		if (active == 'nmbrs') {
			load()
		} else {
			await this.submitKoppeling(active)
			load()
		}
	}

	submitKoppeling(active) {
		if (!active) return
		return jsUtils.updateEnvSetting({uren_reg: {koppeling: active.toLowerCase()}})
	}

	change(val) {
		this.setState({
			active: val,
			progress: 1,
		})
	}

	canSubmit() {
		const {active} = this.state
		if (active == 'nmbrs') {
			jsUtils.errNotif(t('att'), t('finishPartner'))
		} else if (active) {
			return true
		} else {
			jsUtils.errNotif(t('oops'), t('submitPartner'))
		}
	}

	handleIssue(is) {
		jsUtils.errNotif(t('att'), is.readable)
		if (is.code == 'NMBRS_1001') {
			this.setState({hasSavedComp: false, authErrors: true})
		}
	}

	afterConnect(json) {
		const {progress} = this.state

		var obj = {username: '', key: ''}
		if (json.issues) {
			json.issues.forEach(is => this.handleIssue(is))
			// jsUtils.errNotif()
		} else if (json.active_company) {
			this.setState({progress: progress + 2, loading: false, hasSavedComp: true, ...obj})
		} else {
			jsUtils.openNotif(`${t('att')}, ${t('multipleAPI')}`)
			this.setState({companies: json.companies, loading: false, progress: progress + 1, ...obj})
		}
	}

	async connect(username, key) {
		const {progress} = this.state
		this.setState({loading: true})
		const res = await backend.post('koppelingen/nmbrs', {username, key})
		if (res.ok) {
			this.afterConnect(res.data)
		} else {
			jsUtils.errNotif(t('att'), t('wrongCreds'))
			this.setState({loading: false})
		}
	}

	async saveCompany() {
		const {company} = this.state
		const {progress} = this.state
		this.setState({loading: true})
		const res = await backend.post(`koppelingen/nmbrs/save_company`, {
			company: company,
		})
		if (res.ok) {
			this.setState({loading: false, company: company, hasSavedComp: true, progress: progress + 1})
		}
	}

	async syncUsers() {
		const res = await backend.get(`koppelingen/nmbrs/sync_users`)
		if (res.ok) {
			jsUtils.openNotif(t('syncStarted'), 'info', t('syncMsg'))
			this.setState({isSyncing: true})
		}
	}

	stepClick(step) {
		const {progress} = this.state
		if (step > progress) {
			return
		}
		let obj = {}
		if (step != 2) {
			obj['hasSavedComp'] = false
		}

		if (step == 0) {
			obj['active'] = 'Geen'
		}
		let obj2 = {progress: step, ...obj}
		this.setState(obj2)
	}

	onCancel() {
		if (this.state.active != 'nmbrs') {
			this.props.onCancel()
		}
		if (this.state.active == 'nmbrs' && (this.state.progress == 3 || window.confirm(`${t('finishIntegration')}`))) {
			this.props.onCancel()
		}
	}

	setVerloonDate = date => {
		jsUtils.updateEnvSetting({
			nul_uren: {
				verloning_start: date,
			},
		})
	}

	resetIntegration() {
		this.setState({active: null})
		updateSettings({uren_reg: {koppeling: null}})
	}

	render() {
		let arr = ['nmbrs']

		const {showToeslag, settings, loadSettings} = this.props
		const {submit, change, connect, saveCompany, syncUsers} = this
		const {active, key, username, company, companies, hasSavedComp, progress, authErrors, loading} = this.state
		let hasMultiComp = active == 'nmbrs' && companies.length > 0
		let hasOk = (active != 'nmbrs' && active) || progress == 3
		const verloonDate = (settings['nul_uren'] && settings['nul_uren']['verloning_start']) || Moment().startOf('year').format('DD-MM-YYYY')
		const setActive = act => {
			this.setState({active: act})
		}

		let btns = hasOk
			? [
					<Button key="submit" type="primary" onClick={() => this.submit()}>
						{' '}
						OK{' '}
					</Button>,
			  ]
			: []
		return (
			<div className="veetwee32">
				<Modal
					footer={btns}
					className="modalStyle veetwee32"
					onOk={() => submit()}
					onCancel={() => this.onCancel()}
					title={t('activateIntegration1')}
					visible={true}
				>
					<Spin spinning={loading == true}>
						{active == 'nmbrs' && (
							<Steps size="small" direction="vertical" current={progress || 0}>
								<Step onClick={() => this.stepClick(0)} title={t('activateIntegration2')} />
								<Step onClick={() => this.stepClick(1)} title={t('activateIntegration5')} description={t('activateIntegration3')} />
								<Step onClick={() => this.stepClick(2)} title={t('activateIntegration6')} description={t('activateIntegration4')} />
							</Steps>
						)}

						{active != 'nmbrs' && (
							<div className="MBot">
								<p style={{float: 'left', marginBottom: 16, marginTop: 6, fontSize: 12, color: '#2b2b2b'}}>{t('activateIntegration2')}:</p>
								<Select
									style={{opacity: 1, float: 'right', width: '40%', marginBottom: 8}}
									onChange={val => change(val)}
									value={active}
									defaultValue={t('selectOne')}
								>
									{arr.map(opt => (
										<Option value={opt}>{jsUtils.capitalize(opt)}</Option>
									))}
								</Select>
							</div>
						)}
						<div className="clear"></div>

						{active == 'nmbrs' && progress == 1 && (
							<div>
								<p style={{display: 'inline'}}>{t('partnerNmbrs1')}:</p>
								<a
									style={{marginLeft: 4, display: 'inline', fontSize: 8, color: '#0496ff', fontStyle: 'italic'}}
									target="_blank"
									href="https://intercom.help/eitje/nl/articles/6022146-nmbrs"
								>
									{t('partnerNmbrs2')}?
								</a>
								<Input
									style={{marginTop: 8, marginBottom: 8}}
									value={username}
									onChange={e => this.setState({username: e.target.value})}
									placeholder={t('emailUser')}
								/>
								<Input style={{marginBottom: 8}} value={key} onChange={e => this.setState({key: e.target.value})} placeholder="API Token" />
								<Button type="primary" style={{float: 'right'}} onClick={() => connect(username, key)}>
									{t('activateIntegration1')}
								</Button>
							</div>
						)}
						<div className="clear"></div>

						{hasMultiComp && progress == 2 && (
							<div>
								{' '}
								<p className="MBot">{t('activateIntegration6')}:</p>
								{companies.map(c => (
									<div
										onClick={() => this.setState({company: c})}
										style={{
											cursor: 'pointer',
											padding: '12px 24px 12px 24px',
											backgroundColor: c == company ? '#0496ff' : '#fff',
											border: 'solid 1px #eee',
											margin: 4,
										}}
									>
										<p
											style={{
												cursor: 'pointer',
												fontSize: 12,
												color: c == company ? 'white' : 'black',
												fontWeight: c == company ? '500' : '400',
											}}
										>
											{' '}
											{c.name}{' '}
										</p>
									</div>
								))}
								<div className="borderBot MBot"></div>
								<Button style={{float: 'right'}} onClick={() => saveCompany()}>
									{t('activateIntegration1')}
								</Button>
							</div>
						)}
						<div className="clear"></div>

						{hasSavedComp && progress == 3 && active == 'nmbrs' && (
							<div style={{}}>
								<h3 style={{display: 'inline', color: '#0496ff', marginRight: 4}}>✔</h3>
								<h4 style={{display: 'inline'}}>{t('partnerNmbrs3')}</h4>
								<div style={{marginBottom: 24}}></div>

								<p></p>

								<div className="borderBot MBot MTop"></div>
								<div>
									<p style={{float: 'left', paddingTop: 6}}>{t('partnerNmbrs10')}:</p>
									<DatePicker
										allowClear={false}
										style={{width: 140, float: 'right'}}
										format="DD-MM-YYYY"
										defaultValue={Moment(verloonDate)}
										onChange={val => this.setVerloonDate(val.format('YYYY-MM-DD'))}
									/>
									<div className="clear" />
								</div>
								<div className="borderBot MBot MTop"></div>
								<div>
									<p style={{float: 'left', paddingTop: 6}}>{t('partnerNmbrs4')}:</p>
									<Button style={{width: 150, float: 'right'}} onClick={syncUsers}>
										{t('partnerNmbrs5')}
									</Button>
								</div>

								<div className="clear"></div>

								<div className="borderBot MBot MTop"></div>
								<div>
									<p style={{float: 'left', paddingTop: 6}}>{t('partnerNmbrs6')}:</p>
									<Button style={{width: 150, float: 'right'}} onClick={showToeslag}>
										{t('partnerNmbrs7')}
									</Button>
								</div>

								<div className="clear"></div>

								<div className="borderBot MBot MTop"></div>
								<div>
									<p style={{float: 'left', paddingTop: 6}}>{t('partnerNmbrs8')}:</p>
									<a href="https://intercom.help/eitje/nl/articles/6022146-nmbrs" target="_blank">
										<Button style={{width: 150, float: 'right'}}>{t('partnerNmbrs9')}</Button>
									</a>
								</div>

								<div className="clear"></div>
								<div className="borderBot MBot MTop"></div>
							</div>
						)}
						{active && this.props.active != 'geen' && active != 'geen' && (
							<div
								style={{
									marginTop: active === 'nmbrs' ? 0 : 12,
									paddingTop: active === 'nmbrs' ? 0 : 12,
									borderTop: active === 'nmbrs' ? 0 : '1px solid #eee',
								}}
								className="fRow aCen jBetween"
								onClick={this.resetIntegration}
							>
								<p>{t('resetIntegrationText')} </p>
								<Button> {t('resetIntegration')} </Button>
							</div>
						)}
					</Spin>
				</Modal>
			</div>
		)
	}
}

KoppelModal = connect(state => ({
	settings: getEnvSettings(state),
}))(KoppelModal)

const tText = (key, itemKey) => t(`settings_${key}_${itemKey}_text`)
const tExp = (key, itemKey) => t(`settings_${key}_${itemKey}_exp`)

const Sat = ({item, typ, iden, change, masterSwitch, hasMasterSwitch, masterKey}) => {
	var isDate = false
	let mmt = Moment(item.val)
	const opac = hasMasterSwitch ? (masterSwitch || item.key == masterKey ? 1 : 0.6) : 1
	let hasH = !_.isNaN(mmt.hour())
	if (hasH && !item.opts && typ != 'Number') {
		isDate = true
	}

	const isTime = jsUtils.isTime(item.val)
	const exp = tExp(iden, item.key)

	return (
		<div className="setting-container">
			<div className="setting-container-text-container">
				<span className="setting-container-label">{tText(iden, item.key)}</span>
				{exp && !exp.match(/settings_/) && (
					<span
						style={{color: '#0496ff', fontSize: 10, marginLeft: 8, cursor: 'pointer', fontStyle: 'italic'}}
						onClick={() => help(`${t('hulpSetting')}`, exp)}
					>
						{t('uitleg')}
					</span>
				)}
			</div>

			<div className="setting-container-input" style={{display: 'inline-block', width: '25%'}}>
				{isDate && (
					<DatePicker
						allowClear={false}
						style={{width: 140, opacity: opac, float: 'right'}}
						format="DD-MM-YYYY"
						defaultValue={(item.active && mmt) || Moment()}
						onChange={val => change(val.format('YYYY-MM-DD'))}
					/>
				)}
				{isTime && (
					<TimePicker
						allowClear={false}
						format="HH:mm"
						minuteStep={30}
						style={{width: 140, opacity: opac, float: 'right'}}
						value={Moment(item.val, 'HH:mm')}
						onSelect={val => {
							change(val.format('HH:mm'))
						}}
					/>
				)}

				{typ == 'Boolean' && (
					<Switch style={{opacity: opac, float: 'right', marginTop: 3}} onChange={val => change(val)} checked={item.val} />
				)}
				{typ == 'Number' && (
					<InputNumber
						style={{width: 80, opacity: opac, float: 'right'}}
						onChange={val => change(val, true)}
						min={0}
						step={1}
						defaultValue={item.val}
					/>
				)}
				{item.opts && (
					<Select style={{opacity: opac, float: 'right', margin: 0, width: 140}} onChange={val => change(val)} value={item.val}>
						{item.opts.map(opt => (
							<Option value={_.isString(opt) ? opt : opt.key}>
								{_.isString(opt) ? jsUtils.capitalize(t(`settings_select_${opt}`)) : t(`select_${opt.label}`)}
							</Option>
						))}
					</Select>
				)}
			</div>
		</div>
	)
}
class StreefModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			groepen: [],
		}
	}

	componentDidMount() {
		this.fetch()
		window.addEventListener('beforeunload', function (e) {
			var confirmationMessage = 'Let op!' + 'Als je '

			;(e || window.event).returnValue = confirmationMessage //Gecko + IE
			return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
		})
	}

	async submit() {
		const {onCancel, onDone} = this.props
		const {groepen} = this.state
		const res = await backend.patch(`omzet_groep/update_all`, {
			groepen,
		})
		if (res.ok) {
			onCancel()
			onDone()
		}
	}

	async fetch() {
		const res = await backend.get(`omzet_groepen/old_index`)
		if (res.ok) {
			const json = res.data
			this.setState({groepen: json.groepen})
		}
	}

	onC(val, id) {
		const {groepen} = this.state
		const kloon = [...groepen]
		var groep = kloon.filter(i => i.id == id)[0]
		groep.streef_pct = val
		this.setState({
			groepen: kloon,
		})
	}

	render() {
		const {onCancel} = this.props
		const {groepen} = this.state
		return (
			<Modal className="modalStyle veetwee32" onOk={() => this.submit()} onCancel={onCancel} title={t('revTarget')} visible={true}>
				<p style={{marginBottom: 16, fontSize: 12, color: '#2b2b2b'}}>{t('revTarget1')}</p>
				{groepen.map(groep => (
					<div className="MBot borderBot" style={{paddingBottom: 8}}>
						<p>
							{t('revTarget2')}: {groep.naam}
						</p>
						<span style={{position: 'relative', top: 4}}>{t('revTarget3')}: </span>
						<InputNumber className="floatR" min={0} max={100} onChange={val => this.onC(val, groep.id)} value={groep.streef_pct} />
						<div className="clear" />
					</div>
				))}
			</Modal>
		)
	}
}

class OmzetModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			teams: [],
			groepen: [],
			omzetTeams: [],
			inActiveTeams: [],
		}
		this.submit = this.submit.bind(this)
	}

	someInvalid() {
		const {teams} = this.state
		var invalid = false
		teams.forEach(team => {
			if (this.invalid(team)) {
				jsUtils.errNotif(`${t('att')}`, `${t('revDist')} ${team.naam} ${t('revDist2')}`)
				invalid = true
			}
		})

		return invalid
	}

	async submit() {
		const {onCancel} = this.props
		const {teamObj, omzetTeams} = this.state
		if (this.someInvalid()) {
			return
		}
		const res = await backend.post(`omzet_groepen/assign`, {
			teams: omzetTeams,
		})
		res.ok && onCancel()
	}

	componentDidMount() {
		this.fetch()
	}

	onC(val, idx, teamId, groepId) {
		val = _.isNumber(val) ? val : 0
		const nonIdx = idx == 1 ? 0 : 1
		let {omzetTeams} = this.state

		const omzetTeam = omzetTeams.filter(t => t.team_id == teamId && t.omzet_groep_id == groepId)[0]
		omzetTeam.fract = val
		const omzetTeamz = omzetTeams.filter(t => t.team_id == teamId && t.omzet_groep_id != groepId)

		if (omzetTeamz.length == 1) {
			omzetTeams = jsUtils.findAndReplace(omzetTeams, {...omzetTeamz[0], fract: 100 - val})
		}

		this.setState({
			omzetTeams: jsUtils.findAndReplace(omzetTeams, omzetTeam),
		})
	}

	parseJson(json) {
		const {omzetTeams} = this.state
		var obj = {}
		let split = _.partition(json.teams, t => t.omzet_actief)
		this.setState({
			groepen: json.groepen,
			teams: split[0],
			omzetTeams: json.omzet_teams,
			inActiveTeams: split[1],
		})
	}

	async fetch() {
		const res = await backend.get(`omzet_groepen/old_index`)
		if (res.ok) {
			this.parseJson(res.data)
		}
	}

	findVal(val) {
		return _.isNumber(val) && val > 0 ? val : 0
	}

	omzetTeam(team, idx, groep) {
		const {teamObj, omzetTeams} = this.state
		const omzetTeam = omzetTeams.filter(t => t.team_id == team.id && t.omzet_groep_id == groep.id)[0]
		if (omzetTeam) {
			return (
				<div className="veetwee32">
					<p className="floatL" style={{marginTop: 9, width: 100}}>
						{groep.naam}
					</p>
					<Slider
						style={{width: '56%', float: 'left'}}
						min={0}
						max={100}
						value={omzetTeam.fract}
						onChange={val => this.onC(val, idx, team.id, groep.id)}
					/>
					<InputNumber
						className="floatL MLeft"
						min={0}
						max={100}
						value={omzetTeam.fract}
						onChange={val => this.onC(val, idx, team.id, groep.id)}
					/>
					<div className="clear"></div>
				</div>
			)
		} else {
			return null
		}
	}

	async saveOGroep() {
		const {extraGroep} = this.state
		if (!extraGroep) {
			return
		}
		this.setState({nieuwV: false})
		const res = await backend.post(`omzet_groepen`, {
			naam: extraGroep,
		})
		if (res.ok) {
			this.fetch()
			this.setState({extraGroep: undefined})
		}
	}

	invalid(team) {
		const total = this.total(team)
		return total != 100
	}

	total(team) {
		const {omzetTeams} = this.state
		let corrTeams = omzetTeams.filter(t => t.team_id == team.id)
		const total = _.reduce(
			corrTeams.map(t => t.fract),
			(a, b) => a + b,
			0,
		)
		return total
	}

	async editOGroep(g) {
		backend.put(`omzet_groepen/${g.id}`, {
			naam: g.naam,
		})
	}

	async destroyOGroep(g) {
		const res = await backend.delete(`omzet_groepen/${g.id}`)
		res.ok && this.fetch()
	}

	render() {
		const {onCancel, visible} = this.props
		const {teams = [], groepen, inActiveTeams, omzetTeams, teamObj, nieuwV} = this.state
		return (
			<Modal
				className="veetwee32 modalStyle modalStyle500"
				onOk={() => this.submit()}
				onCancel={onCancel}
				title={t('revDist3')}
				visible={visible}
			>
				<div className="modalOverflow">
					<p style={{marginBottom: 16, fontSize: 12, color: '#2b2b2b'}}>{t('revDist4')}</p>

					<p style={{color: '#0496ff', fontWeight: '500', marginBottom: -20}}>{t('revDist5')}:</p>
					{teams.length <= 0 ? (
						<p style={{marginTop: 20}}>{t('revDist6')}</p>
					) : (
						teams.map(team => (
							<div className="MBot" style={{paddingBottom: 8}}>
								<div className="MBot borderBot MTopxl">
									<span style={{cursor: 'default', fontWeight: '500', fontSize: 12}}>Team: {team.naam}</span>
									<span style={{color: this.invalid(team) ? '#ff0020' : 'black', fontSize: 9, marginLeft: 4}}>
										{' '}
										{this.total(team)}% /
									</span>{' '}
									<span style={{fontSize: 9}}>100%</span>
									<span
										style={{float: 'right'}}
										onClick={() => jsUtils.updateTeamSetting(team, {key: 'actief', value: false}, () => this.fetch())}
									>
										<img style={{width: 16, cursor: 'pointer', top: 0, position: 'relative', right: 8}} src={'/images/notConfirmed.png'} />
									</span>
								</div>

								{groepen.map(g => this.omzetTeam(team, groepen.indexOf(g), g))}
							</div>
						))
					)}

					<p style={{color: '#0496ff', fontWeight: '500', marginTop: 24, marginBottom: 8}}>{t('revDist7')}:</p>
					{inActiveTeams.length <= 0 ? (
						<p>{t('revDist8')}</p>
					) : (
						inActiveTeams.map(team => (
							<div className="MBot ">
								<span style={{cursor: 'default', fontWeight: '500', fontSize: 12}}>Team: {team.naam}</span>
								<span
									style={{float: 'right'}}
									onClick={() => jsUtils.updateTeamSetting(team, {key: 'actief', value: true}, () => this.fetch())}
								>
									<img style={{width: 16, cursor: 'pointer', top: 0, position: 'relative', right: 8}} src={'/images/approve.png'} />
								</span>
							</div>
						))
					)}

					<p style={{cursor: 'default', fontWeight: '500', fontSize: 12, marginTop: 24}}>{t('revDist9')}:</p>
					{groepen.map(g => (
						<div>
							<Input
								style={{width: '95%', borderColor: '#eee', float: 'left', marginBottom: 4}}
								value={g.naam}
								onChange={e => {
									this.setState({groepen: jsUtils.findAndReplace(groepen, {...g, naam: e.target.value})})
									this.editOGroep({...g, naam: e.target.value})
								}}
							/>

							{groepen.length > 1 && (
								<SubmitPopout onSubmit={() => this.destroyOGroep(g)}>
									<img
										style={{width: 16, cursor: 'pointer', marginBottom: 20, position: 'relative', top: 6, left: 8}}
										src={'/images/notConfirmed.png'}
									/>
								</SubmitPopout>
							)}
						</div>
					))}
					{!nieuwV && (
						<Button style={{width: 480, marginTop: 12}} onClick={() => this.setState({nieuwV: true})}>
							{t('revDist10')}
						</Button>
					)}

					{nieuwV && (
						<div style={{marginBottom: 24, marginTop: 12}}>
							<Input
								placeholder={t('revDist11')}
								style={{width: '95%', borderColor: '#eee', float: 'left'}}
								onChange={e => this.setState({extraGroep: e.target.value})}
							/>
							<img
								onClick={() => this.saveOGroep()}
								style={{width: 16, cursor: 'pointer', marginBottom: 6, position: 'relative', top: 6, left: 8}}
								src={'/images/approve.png'}
							/>
						</div>
					)}
				</div>
			</Modal>
		)
	}
}

class Setting extends React.Component {
	constructor(props) {
		super(props)
		const {koppel_modal_open} = QueryString.parse(props.location.search)
		this.state = {
			settings: props.settings,
			modalV: false,
			streefModalV: false,
			groepDone: props.groep_done,
			streefDone: props.streef_done,
			cModalVisible: false,
			nUDone: props.n_u_done,
			urenDone: props.uren_done,
			payrollDone: props.payroll_done,
			maaltijdOpts: props.maaltijdOpts,
			maaltijdSelected: props.maaltijdSelected,
			touchedTyps: [],
			place: {},
			kModalV: koppel_modal_open,
			allowances: props.allowances,
			groepen: props.groepen,
			omzet_koppeling_done: props.omzet_koppeling_done,
			omzet_koppel_type: props.omzet_koppel_type,
			inactiveOmzetIds: props.inactiveOmzetIds,
		}
		this.submit = this.submit.bind(this)
		this.onPlaceFind = this.onPlaceFind.bind(this)
	}

	onChange(typ, item, val, num = false) {
		const sattings = {...this.state.settings}
		var vaal = num ? Number(val) : val
		var corr = sattings[typ].filter(i => i.key == item.key)[0]
		var idx = sattings[typ].indexOf(corr)
		sattings[typ][idx].val = vaal
		this.setState({settings: sattings, touchedTyps: [...this.state.touchedTyps, typ]})
	}

	handleSuccess(typ) {
		if (typ) {
			const sattings = {...this.state.settings}
			sattings[typ][0].active = true
			this.setState({settings: sattings})
		}
	}

	handleE(e) {
		alert(e)
	}

	omzetModal() {
		this.setState({
			modalV: true,
		})
	}

	async submit(typ) {
		const {user, envId} = this.props
		const {settings, touchedTyps, place} = this.state
		const res = await backend.patch(`permissions/update_settings`, {
			place: place,
			settings: _.pick(this.state.settings, [...touchedTyps, typ]),
		})
		if (res.ok) {
			this.handleSuccess(typ)
			getSettings(user.id, envId)
		}
	}

	corrText(bool) {
		return bool ? (
			<div style={{marginTop: -20}}>
				<CheckOutlined style={{marginTop: 4, float: 'right', color: '#0496ff'}} />
				<span>{t('settingsReady')}</span>
			</div>
		) : (
			<div style={{marginTop: -20}}>
				<EditOutlined style={{marginTop: 4, float: 'right', color: '#ff002080'}} />
				{t('noSettings')}
			</div>
		)
	}

	async submitMaaltijd() {
		const {maaltijdOpts, maaltijdSelected} = this.state
		const res = await backend.patch(`permissions/update_maaltijd`, {
			maaltijd_opts: maaltijdOpts,
			maaltijd_selected: maaltijdSelected,
		})
	}

	onPlaceFind(results) {
		if (!results[0]) {
			return
		}
		var loc = results[0].geometry.location
		var addr_component = results[0].address_components.filter(r => r.types.includes('locality'))[0]
		const obj = {
			addr_component: addr_component.long_name,
			formatted_addr: results[0].formatted_address,
			placeId: results[0].place_id,
			lat: loc.lat(),
			lng: loc.lng(),
		}
		this.setState({place: obj})
	}

	toggleToeslagModal = (visible = !this.state.toeslagModalV) => {
		this.setState({toeslagModalV: visible})
	}

	updateToeslagen = (toeslagen, shouldNotif) => {
		//this.toggleToeslagModal(false);
		//this.setState({toeslagKoppelModalV: false})
		this.props.parent.setState({allowances: toeslagen})
	}

	updateChosenHolidays = hd => {
		this.props.parent.setState({chosenHolidays: hd})
	}

	componentDidUpdate(prevProps, prevState) {
		const {user, envId, parent} = this.props
		const currPath = this.props.history.location.pathname
		if (prevProps.routeChange != this.props.routeChange && !currPath.includes('employment_types')) {
			parent.loadSettings()
		}

		if (prevProps.settings != this.props.settings) {
			this.setState({settings: this.props.settings})
		}
	}

	render() {
		const {
			koppeling,
			nmbrs_auth,
			progress,
			koppel_groepen,
			allowances,
			holidays,
			maaltijdCode,
			chosenHolidays,
			toeslagen_done,
			envNaam,
			omzet_key,
			omzet_koppeling_possible,
			cash_registers,
			employmentTypes,
			parent,
			user,
			envId,
		} = this.props
		const {
			settings,
			modalV,
			streefModalV,
			streefDone,
			groepDone,
			wagePeriodModalV,
			cModalVisible,
			groepen,
			maaltijdSelected,
			kModalV,
			toeslagModalV,
			toeslagKoppelModalV,
			inactiveOmzetIds,
		} = this.state
		const uOpac = settings['uren_reg'][0] && settings['uren_reg'][0].val ? 1 : 0.6
		const {onboarding = {}} = settings
		const flexId = employmentTypes.find(e => e.name == 'Nul uren' && e.environment_id == envId)?.id
		const fixedId = employmentTypes.find(e => e.name == 'Uren contract' && e.environment_id == envId)?.id
		const payrollId = employmentTypes.find(e => e.name == 'Payroll' && e.environment_id == envId)?.id
		const hasLegacyInteg = koppeling != 'geen'

		return (
			<div className="veetwee32" style={{marginTop: 24}}>
				<OpenAPI />
				<div className="borderBot MBot">
					<h3 style={{marginBottom: 8, marginTop: 16}}>{t('general')}</h3>
					<Sat
						iden="onboarding"
						change={(val, num = false) => this.onChange('onboarding', onboarding[0], val, num)}
						item={onboarding[0]}
						typ="Boolean"
					/>
				</div>
				<AvSettings />

				<div className="borderBot MBot">
					<h3 style={{marginBottom: 8, marginTop: 16}}>{t('hoursRegistration')}</h3>
					<TimeRegSettings />

					<div className="setting-container">
						<span className="setting-container-label">{t('mealAllowance')}</span>

						<div className="setting-container-input">
							<CreateOrActiveButton isActive={maaltijdSelected.length > 0} modalLink={`/time_registration/${envId}/settings/meals`} />
						</div>
					</div>

					{hasLegacyInteg && (
						<div className="setting-container">
							<span className="setting-container-label">{t('integrateSalary')}</span>

							<div className="setting-container-input">
								<Button style={{width: 220, opacity: uOpac, float: 'right'}} onClick={() => this.setState({kModalV: true})}>
									{koppeling ? (
										<div>
											{jsUtils.capitalize(koppeling)}
											<CheckOutlined style={{marginTop: 4, float: 'right', color: '#0496ff'}} />
										</div>
									) : (
										<div style={{marginTop: 6}}>
											<EditOutlined style={{marginTop: 2, float: 'right', color: '#ff002080'}} />
											Instellen
										</div>
									)}{' '}
								</Button>
							</div>
						</div>
					)}
				</div>

				<div className="borderBot MBot">
					<h3 style={{marginBottom: 8, marginTop: 16}}>{t('financial')}</h3>

					<div className="setting-container">
						<span className="setting-container-label">{t('distributeLabor')}</span>

						<div className="setting-container-input">
							<Button onClick={() => this.omzetModal()}> {this.corrText(groepDone)}</Button>
						</div>
					</div>

					<div className="setting-container">
						<span className="setting-container-label">{t('revTarget')}</span>

						<div className="setting-container-input">
							<Button onClick={() => this.setState({streefModalV: true})}> {this.corrText(streefDone)}</Button>
						</div>
					</div>

					<div style={{}}>
						{settings['statistieken'].map(i => (
							<Sat
								iden="stats"
								change={(val, num = false) => this.onChange('statistieken', i, val, num)}
								item={i}
								typ={i[Object.keys(i)[0]].constructor.name}
							/>
						))}
					</div>

					<div className="setting-container">
						<span className="setting-container-label">{t('allowance')}</span>

						<div className="setting-container-input">
							<Button onClick={() => this.toggleToeslagModal()}>
								{toeslagen_done ? (
									<div>
										<CheckOutlined style={{marginTop: 2, float: 'right', color: '#0496ff'}} />
										{t('allActive')}
									</div>
								) : (
									<div>
										<EditOutlined style={{marginTop: 2, float: 'right', color: '#ff002080'}} />
										{t('create')}
									</div>
								)}
							</Button>
						</div>
					</div>
				</div>

				<div className="borderBot MBot">
					<h3 style={{marginBottom: 8, marginTop: 16}}>{t('contracts')}</h3>

					<div className="setting-container">
						<span className="setting-container-label">{t('Nul uren')} contract</span>

						<div className="setting-container-input">
							<ModalLink to={`/forms/employment_types/${flexId}`}>
								<Button> {this.corrText(settings.nul_uren[0].active)}</Button>
							</ModalLink>
						</div>
					</div>

					<div className="setting-container">
						<span className="setting-container-label">{t('Uren')} contract </span>

						<div className="setting-container-input">
							<ModalLink to={`/forms/employment_types/${fixedId}`}>
								<Button> {this.corrText(settings.uren[0].active)}</Button>
							</ModalLink>
						</div>
					</div>

					<div className="setting-container">
						<span className="setting-container-label">Payroll contract </span>

						<div className="setting-container-input">
							<ModalLink to={`/forms/employment_types/${payrollId}`}>
								<Button> {this.corrText(settings.payroll[0].active)}</Button>
							</ModalLink>
						</div>
					</div>

					<div className="setting-container">
						<span className="setting-container-label">{t('salaryPeriodSet')}</span>

						<div className="setting-container-input">
							<Button onClick={() => this.setState({wagePeriodModalV: true})} style={{width: 220, marginBottom: 8, float: 'right'}}>
								{' '}
								{this.corrText(true)}
							</Button>
						</div>
					</div>

					{cModalVisible && (
						<Modal
							className="modalStyle modalStyle500"
							visible={true}
							title={t(this.state.typ) + ' contract'}
							onOk={() => {
								this.submit(this.state.typ)
								this.setState({cModalVisible: false})
							}}
							onCancel={() => this.setState({cModalVisible: false})}
						>
							<div style={{marginTop: 5}}>
								{settings[this.state.typ].map(i => (
									<Sat
										iden="contracts"
										change={(val, num = false) => this.onChange(this.state.typ, i, val, num)}
										item={i}
										typ={i[Object.keys(i)[0]].constructor.name}
									/>
								))}
							</div>
						</Modal>
					)}

					{wagePeriodModalV && (
						<WagePeriodModal
							afterSubmit={() => parent.loadSettings()}
							onCancel={() => this.setState({wagePeriodModalV: false})}
							settings={settings}
						/>
					)}
				</div>

				{window.google && window.google.maps && window.google.maps.places && (
					<div className="borderBot MBot" style={{paddingBottom: 120}}>
						<h3 style={{marginBottom: 8, marginTop: 16}}>{t('informationHeader')}</h3>

						<div className="setting-container">
							<span className="setting-container-label">{t('location')}:</span>

							<div className="setting-container-input">
								<GooglePlaces address={this.props.place} onSucc={this.onPlaceFind} />
							</div>
						</div>

						{!isProd && <ToggleDebugMode />}
					</div>
				)}

				{modalV && <OmzetModal visible={modalV} onCancel={() => this.setState({modalV: false})} />}
				{streefModalV && (
					<StreefModal onDone={() => this.setState({streefDone: true})} onCancel={() => this.setState({streefModalV: false})} />
				)}
				{kModalV && (
					<KoppelModal
						load={() => {
							this.setState({kModalV: false})
							this.props.load()
						}}
						loadSettings={this.props.load}
						showToeslag={() => this.setState({toeslagKoppelModalV: true})}
						companies={this.props.companies}
						progress={progress}
						isAuth={nmbrs_auth}
						active={koppeling}
						visible={kModalV}
						onCancel={() => this.setState({kModalV: false})}
					/>
				)}
				{toeslagModalV && (
					<ToeslagModal
						afterHoliSucc={holidays => this.updateChosenHolidays(holidays)}
						chosenHolidays={chosenHolidays}
						holidays={holidays}
						allowances={allowances}
						afterSucc={(toeslagen, shoulda) => this.updateToeslagen(toeslagen, shoulda)}
						onCancel={() => this.toggleToeslagModal()}
					/>
				)}
				{toeslagKoppelModalV && (
					<ToeslagKoppelModal
						maaltijdCode={maaltijdCode}
						afterSucc={toeslagen => {
							this.setState({toeslagKoppelModalV: false})
							this.updateToeslagen(toeslagen)
						}}
						allowances={allowances}
						onCancel={() => this.setState({toeslagKoppelModalV: false})}
					/>
				)}

				<div className="saveButtonSettings veedree ">
					<Button onClick={() => this.submit()} type="primary" htmlType="submit" className="settings-save-button">
						{' '}
						{t('save')}{' '}
					</Button>
				</div>
			</div>
		)
	}
}

const BaseSettingsModalRow = ({btnText, to, label}) => {
	return (
		<div className="setting-container">
			<span className="setting-container-label">{label}</span>
			<div className="setting-container-input">
				<EitjeButton modalLink={to}>{btnText}</EitjeButton>
			</div>
		</div>
	)
}

Setting = withRouter(Setting)

export default connect(state => ({
	routeChange: state.general.route,
	user: state.auth.user,
	envId: state.environment.active,
	employmentTypes: whereNot(state, 'employmentTypes', {personal: true}),
}))(Setting)

const ToggleDebugMode = () => {
	const show = !isProd
	const onClick = useLocal('debugMode').toggle
	if (!show) return null

	return (
		<Text darkGrey small onClick={onClick}>
			Toggle debug modus
		</Text>
	)
}
