import Vimeo from '@u-wave/react-vimeo'
import {Progress} from 'antd'

const getVideoId = url => {
	const arr = url.split('/')
	return arr[arr.length - 1]
}

export const Player = ({url}) => {
	return (
		<div style={{width: '600px', height: '338px', backgroundColor: 'black', borderRadius: '8px', overflow: 'hidden'}}>
			{url && <Vimeo video={url} width={600} height={338} />}
		</div>
	)
}

export const UploadProgress = ({percentage}) => {
	return <Progress percent={percentage} status={percentage < 100 ? 'active' : undefined} />
}
