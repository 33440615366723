import {configure, setup} from '@eitje/form'
import {Input} from '@eitje/form-fields-web'
import {Spin, Tooltip} from 'antd'
import {t} from 'initializers/i18n'
import {Prompt} from 'react-router-dom'
import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {Layout} from 'common/components'
const phoneRegex = /^[+#*\(\)\[\]]*([0-9][ ext+-pw#*\(\)\[\]]*){6,45}$/

const a = text => window.alert(a)

const translatorFunc = (text, opts = {}) => {
	if (_.isString(opts)) {
		opts = {defaultValue: opts}
	}
	opts = {...opts, globals: window.globals}
	return t(text, opts)
}

const MyButton = props => (
	<div className="saveButton fRow aCen jCen" {...props}>
		<img src="/images/saveButton.png" />
		<p>{t('save')}</p>
	</div>
)

const MyPrompt = ({message, ...props}) => {
	const loc = useLocation()
	const previousLoc = useMemo(() => loc, [])
	const patchedMessage = (loc, act) => message(loc, act, previousLoc)
	return <Prompt {...props} message={patchedMessage} />
}

const tooltipElement = <img src={'/images/web/icons/infoIcon.png'} className="eitje-form-2-tooltip" />

const MultiFormWrapper = props => <div className="eitje-multi-form" {...props} />
setup({
	translatorFunc,
	tooltipElement,
	Layout,
	scoped: true,
	alert: a,
	button: MyButton,
	Prompt: MyPrompt,
	Tooltip: Tooltip,
	MultiFormWrapper,
	Wrapper: 'form',
	defaultIcon: '/images/web/icons/dropDown.png',
	clearIcon: '/images/web/icons/remove-black.png',
})

const defaultProps = {DefaultInput: Input, LoadingEl: () => <Spin style={{zIndex: 1000000}} spinning />}

const fieldRules = {}

const fieldMessages = {}

const messages = {field: fieldMessages}
const rules = {field: fieldRules}

const Form = configure({rules, messages, defaultProps})

const modal = {
	labelStyle: {
		color: '#828282',
		fontSize: 12,
		marginBottom: -2,
		letterSpacing: '-0.2px',
		cursor: 'default',
	},
	containerStyle: {
		minHeight: 68,
		display: 'flex',
		flex: 1,
		borderBottom: '1px solid #eee',
		borderRight: '1px solid #eee',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '12px 24px',
		justifyContent: 'center',
	},
	errorStyle: {fontSize: 10, color: '#ff0020'},
}

const tile = {
	labelStyle: {
		color: '#828282',
		fontSize: 12,
		marginBottom: -2,
		letterSpacing: '-0.2px',
		cursor: 'default',
	},
	containerStyle: {
		display: 'flex',
		padding: '12px 0px',
		justifyContent: 'space-between',

		borderRadius: '4px',
		border: 'solid 1px #EEEEEE',
		padding: '8px 16px',
		boxShadow: 'none',
		minHeight: '40px',
		alignItems: 'center',
	},
	errorStyle: {fontSize: 10, color: '#ff0020'},
}

const readOnly = {
	labelStyle: {color: '#828282', fontSize: 12, letterSpacing: '-0.2px', cursor: 'default'},
	containerStyle: {marginBottom: 24, cursor: 'default', flexGrow: 1, minWidth: 288, borderBottom: '1px solid #eee'},
	style: {padding: '0px 8px 4px 12px', cursor: 'default'},
}

const minimal = {
	labelVisible: false,
	containerStyle: {},
}

const settings = {
	labelStyle: {
		color: '#2b2b2b',
		fontSize: 12,
		cursor: 'default',
	},
	containerStyle: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		padding: '8px 0px',
		justifyContent: 'space-between',
	},
	errorStyle: {fontSize: 10, color: '#ff0020'},
}

const general = {
	labelStyle: {
		color: '#828282',
		fontSize: 12,
		marginBottom: -2,
		letterSpacing: '-0.2px',
		cursor: 'default',
	},

	errorStyle: {fontSize: 10, color: '#ff0020'},
}

const postEmoInput = {
	labelStyle: {
		color: '#828282',
		fontSize: 12,
		marginBottom: -2,
		letterSpacing: '-0.2px',
		cursor: 'default',
	},
	containerStyle: {
		minHeight: 68,
		borderBottom: '1px solid #eee',
		borderRight: '1px solid #eee',
		padding: '12px 24px',
		flex: 1,
	},
	errorStyle: {fontSize: 10, color: '#ff0020'},
}

const postModal = {
	...postEmoInput,
	containerStyle: {
		...postEmoInput.containerStyle,
		borderBottom: 'none',
		borderRight: 'none',
	},
}

const commentNews = {
	labelVisible: false,
	containerStyle: {
		border: '1px solid #eee',
		backgroundColor: 'white',
		borderRadius: 8,
		padding: '2px 16px',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-between',
		margin: 0,
		boxShadow: '0 2px 8px hsla(0,0%,66.7%,.15)', //question
		marginBottom: 24,
	},
}

export const styleKinds = {
	general,
	tile,
	modal,
	postModal,
	readOnly,
	minimal,
	commentNews,
	postEmoInput,
	settings,
}

export default Form
