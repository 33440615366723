import {t as baseT} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {NewFormInputNumber} from 'components/form/input_number'

export const Body = () => {
	return (
		<div className="leave-page-settings-body eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<NewFormInputNumber placeholder={null} extraLabel max={10000} field="min_ask_days" suffix={baseT('common.days_in_future')} />
			</FormRow>
			<FormRow>
				<NewFormInputNumber placeholder={null} extraLabel max={10000} field="max_reset_days" suffix={baseT('common.days_in_past')} />
			</FormRow>
		</div>
	)
}

export default Body
