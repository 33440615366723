import Form from 'initializers/form'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import useUserWeek from 'cores/availability/hooks/use_user_week'
import SimpleInputNumber from 'lib/form/fields/simple_input_number'

const WeekShifts = ({kind = 'user_weeks', userId, date}) => {
	// this component should be done properly, it's now copied over from user/availability/components
	// one of the ideas is to share user context, but then we'd need to make an extra context 'UserAvContext'
	const {preferred_shifts} = useUserWeek({date, id: userId})
	return (
		<div className="week-shifts-form">
			<Form
				overrideInitialValues
				debounceTime={200}
				initialValues={{preferred_shifts, date}}
				onSubmit={data => API.create(kind, {date: date.format('YYYY-MM-DD'), ...data, user_id: userId}, {headers: {doNotLoad: true}})}
			>
				<SimpleInputNumber submitStrategy="throttledChange" field="preferred_shifts" min={0} max={7} />
			</Form>
		</div>
	)
}

export default WeekShifts
