import {createContext, useContext, useState, useMemo} from 'react'

const JumpToMessageProviderContext = createContext()

export const JumpToMessageProvider = ({children}) => {
	const [messageId, setMessageId] = useState()

	const state = useMemo(() => ({messageId, setMessageId}), [messageId])

	return <JumpToMessageProviderContext.Provider value={state} children={children} />
}

export const useJumpToMessageProviderContext = () => useContext(JumpToMessageProviderContext)
