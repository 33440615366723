import Modal from 'components/general/modal'
import useList from 'hooks/use_list'
import {useErroredShifts} from 'cores/planning/index'
import ErrorRow from './error_row'
import './styles/index.less'
import {t} from 'initializers/i18n'

const searchProps = {
	searchField: 'user.full_name',
}

export const HandleErrors = () => {
	const items = useErroredShifts()
	const {list, searchInput} = useList({items, ListItem: ErrorRow, searchProps})
	return (
		<Modal redirectIf={items.length == 0} hasCancel wrapClassName="registration-errors-modal" width={680}>
			<Header searchInput={searchInput} />
			<h5 className="registration-errors-description">
				{t('handle_errors.description', {kind: t('records.shift.name', {count: items.length}), count: items.length})}
			</h5>
			{list}
		</Modal>
	)
}

const Header = ({searchInput}) => {
	return (
		<div className="registration-errors-header">
			<h3>{t('handle_errors.title')} </h3>
			{searchInput}
		</div>
	)
}
