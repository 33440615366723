import {EitjeVirtuoso} from 'common/components'
import {useUnschedulableOrgUsers} from 'hooks/use_schedulable_users'
import {useActiveUsers} from 'cores/planning/hooks'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {UserPlanningShiftsRow} from 'cores/planning'

export const PlanningPerMemberTable = () => {
	const activeUsers = useActiveUsers()
	const {
		envs,
		filteredItems: shifts,
		filterValues: {user_shifts},
	} = useFilterFooterContext()

	let users

	// filter schedulable users
	const unschedulableUserIds = useUnschedulableOrgUsers(envs).ids()
	const schedulableUsers = activeUsers.filter(user => !unschedulableUserIds.includes(user.id))
	users = schedulableUsers

	// filters users with shifts
	if (user_shifts?.length === 1) {
		// if both OR none options are passed, all user rows must be shown, thus only check if there is one value
		const value = user_shifts[0]
		const hasShifts = user => shifts.find(shift => shift.user_id === user.id)
		if (value === 'with_shifts') users = users.filter(u => hasShifts(u))
		if (value === 'without_shifts') users = users.filter(u => !hasShifts(u))
	}

	return (
		<EitjeVirtuoso
			data={users}
			customScrollParent=".page-body"
			className="planning-per-member-virtuoso"
			itemContent={(_, user) => <UserPlanningShiftsRow user={user} />}
		/>
	)
}
