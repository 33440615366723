import {useEffect} from 'react'
import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'

export const ResourceFetcher = ({id, kind}) => {
	// this is our 'hack' so we can call hooks conditionally for fetching resources
	useEffect(() => {
		API.getByIds(kind, utils.alwaysDefinedArray(id))
	}, [])
	return null
}
