import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import GridSelect from 'components/form/grid_select'
import Modal from 'components/general/modal'
import {UserAvatar} from 'components/ui'
import useShared from 'hooks/use_shared'
import useTeamDropdown from 'hooks/use_team_dropdown'
import {history} from 'index'
import {t} from 'initializers/i18n'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'
import {inEnv, inEnvTeam} from 'selectors/records'

const renderLabel = item => {
	return (
		<div className="column-with-justify-center align-items-center gap-xss">
			<UserAvatar size="sm" user={item} />
			<h5 className="bold">{utils.truncateString(item.voornaam, 10)}</h5>
		</div>
	)
}

const UserSkills = () => {
	const {hasManagerRole} = useShared()
	const {dropdown, teamIds} = useTeamDropdown({rowContainerStyle: {width: 200}})
	const users = useSelector(state => inEnv(state, 'users'))
	const skills = useSelector(state => inEnv(state, 'skills'))
	const sets = useSelector(state => inEnvTeam(state, 'skillSets'))
	const grid = useRef(null)

	return (
		<Modal redirectIf={!hasManagerRole} onOk={() => grid.current.submit()} size="fs">
			<div className="modal-fullscreen-header">
				<div className="modal-fullscreen-header-left align-items-center">
					<div className="ant-modal-title">{t('fast_assign_skills')}</div>

					<div>{dropdown}</div>
				</div>

				<div className="modal-fullscreen-header-center"></div>

				<div className="modal-fullscreen-header-right"></div>
			</div>
			<div className="modal-fullscreen-body modal-skills-grid">
				<GridSelect
					rowProps={rowProps}
					groupItems={sets}
					groupFilter={{team_ids: teamIds}}
					unassignedLabel={t('unassignedSkills')}
					renderLabel={renderLabel}
					ref={grid}
					groupField="skill_set_ids"
					field="skill_ids"
					afterSubmit={history.goBack}
					onSubmit={({changed}) => API.addRemoveAssocMulti('users', changed)}
					topItems={users}
					leftItems={skills}
				/>
			</div>
		</Modal>
	)
}

export default UserSkills

const rowProps = {
	selectedLblColor: '#828282',
}
