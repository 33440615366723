import {Layout} from 'common/components'
import {useMessageHeadersTaxonomy} from './use_message_headers_taxonomy'
import {MessageHeader} from './message_header'
import './index.less'

export * from './use_message_headers_taxonomy'

export const MessageHeaders = () => {
	const taxonomy = useMessageHeadersTaxonomy()

	if (_.isEmpty(taxonomy)) return null

	return (
		<Layout className="message-headers" direction="vertical" width="full" gap={1}>
			{taxonomy.map(taxItem => (
				<MessageHeader {...taxItem} />
			))}
		</Layout>
	)
}
