import {all, API, useWhere} from '@eitje/easy_api'
import {LegacyInput as Input} from '@eitje/form-fields-web'
import {EasyRemoveArea, RemoveItem, Layout} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import useList from 'components/general/list'
import {ModalLink} from 'components/routing'
import {ColorCircle, SearchInput} from 'components/ui'
import {DeleteButton, OptionsButton, ListPicker, PageLayout, Text} from 'common/components'
import {useShared} from 'hooks/use_shared'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {SkillSetForm} from 'pages/modals/skill_set'
import {ManageSkillsBtn} from 'pages/v3/user/components'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnvTeam, whereInc} from 'selectors/records'
import './styles/skills.less'

const PopoverContent = ({item}) => {
	return (
		<div className="skills_popover popover">
			<SkillSetForm submitOnChange item={item} />
			<div className="padding-sm">
				<DeleteButton width="full" onDel={() => API.destroy('skill_sets', item.id)}>
					{t('delFunc')}
				</DeleteButton>
			</div>
		</div>
	)
}

export const SkillInitial = ({item}) => (
	<Layout grow gap={12}>
		<ColorCircle color={item.color_code} text={item.abbr.toUpperCase()} />
		<Text truncate>{item.name}</Text>
	</Layout>
)

const SkillSet = ({item, search}) => {
	const confirmProps = {
		title: t('deleteFromFunction'),
	}
	const setEnvs = useSelector(state => whereInc(state, 'environments', {team_ids: item.team_ids}))
	const setEnvIds = setEnvs.map(e => e.id)
	const skills = useSelector(state => all(state, 'skills'))
	const notMine = skills.filter(s => !item.skill_ids.includes(s.id) && setEnvIds.includes(s.environment_id))
	let mine = skills.filter(s => item.skill_ids.includes(s.id))
	if (utils.exists(search)) {
		mine = utils.search(mine, search, {field: 'name'})
	}

	return (
		<div className="skill-column">
			<Layout className="skill-title-container" border vertical="center" padding={12} height={54} gap={12}>
				<SkillInitial item={item} />
				<OptionsButton body={<PopoverContent item={item} />} />
			</Layout>

			<div className="skill-list-input-container">
				<ListPicker
					raw
					single
					dropdown
					maxWidth={null}
					showSearch
					title={t('addSkillToFunction')}
					onChange={(_, {changed}) => API.addAssoc('skill_sets', {id: item.id, skill_ids: [changed]})}
					searchProps={searchProps}
					items={notMine}
					value={item.skill_ids}
				/>

				<div className="skill-list-input-area">
					<EasyRemoveArea
						confirmProps={confirmProps}
						items={mine}
						shouldConfirm
						size="small"
						action={(val, _item) => API.removeAssoc('skill_sets', {id: item.id, skill_ids: [_item.id]})}
					/>
				</div>
			</div>
		</div>
	)
}
const UnassignedSkills = ({search}) => {
	const {envId} = useShared()
	let skills = useWhere('skills', {skill_set_ids: [], environment_id: envId}, {exact: true})
	if (skills.length === 0) return null

	if (utils.exists(search)) {
		skills = utils.search(skills, search, {field: 'name'})
	}

	return (
		<div className="skill-column">
			<Layout className="skill-title-container" border vertical="center" padding={12} height={54} gap={12}>
				<Text t="unassignedSkills" />
			</Layout>

			<div className="skill-input-area">
				{skills.map(s => (
					<RemoveItem item={s} shouldConfirm action={() => API.destroy('skills', s.id)} />
				))}
			</div>
		</div>
	)
}

const PlaceholderCircle = () => (
	<div style={{width: 28, minWidth: 28, minHeight: 28, height: 28, borderRadius: '50%', backgroundColor: '#eee'}} />
)

const NewSkill = () => {
	const input = useRef(null)
	return (
		<div className="placeholder-col" onClick={() => input.current?.focus()}>
			<ModalLink to="/forms/skill_set">
				<Layout className="placeholder-title-row" gap={12} padding={12}>
					<PlaceholderCircle />
					<p className="placeholder-text">{t('addSkillSet')}</p>
				</Layout>
			</ModalLink>

			<div className="placeholder-input-area">
				<Form resetAfterSubmit onSubmit={data => API.create('skills', data)}>
					<Input
						required
						innerRef={input}
						style={{width: 180}}
						bordered={false}
						labelVisible={false}
						placeholder={t('addSkill')}
						field="name"
						submitStrategy="blur"
					/>
				</Form>
			</div>
		</div>
	)
}

const Skills = () => {
	const skillSets = useSelector(state => inEnvTeam(state, 'skillSets'))
	const [search, setSearch] = useState(null)
	const {list} = useList({items: skillSets, ...listProps, itemProps: {search}, draggable: true, direction: 'horizontal'})

	const header = (
		<div className="page-menu-container">
			<div className="page-menu">
				<SearchInput style={{width: 200}} autocomplete="off" placeholder={t('searchSkill')} onChange={e => setSearch(e.target.value)} />
				<ManageSkillsBtn />
			</div>
		</div>
	)

	return (
		<PageLayout name="skills" headers={header}>
			<Layout className="skills-page-area" vertical="start" padding={12}>
				<NewSkill />

				<div className="skills-list">{list}</div>

				<UnassignedSkills search={search} />
			</Layout>
		</PageLayout>
	)
}

export default Skills

const listProps = {
	// containerStyle: {display: 'flex'},
	ListItem: SkillSet,
	onDragEnd: items => API.updateMulti('skill_sets', items),
}

const searchProps = {
	placeholder: t('searchSkill'),
}
