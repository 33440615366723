import {useEffect, useState} from 'react'
import LogRocket from 'logrocket'

let trackedEvents = {}

const MAX_EVENTS = 20

export const useLogRocketTrack = eventName => {
	useEffect(() => {
		if (_.isInteger(trackedEvents[eventName]) && trackedEvents[eventName] > MAX_EVENTS) return
		if (!trackedEvents[eventName]) trackedEvents[eventName] = 0
		trackedEvents[eventName] += 1
		LogRocket?.track?.(eventName)
	}, [])

	return null
}

export const LogRocketTracker = ({eventName}) => {
	useLogRocketTrack(eventName)
	return null
}
