import {Layout as _Layout} from '@eitje/web_components'
import {forwardRef} from 'react'
import {history} from 'index'
import {navigateModal} from 'actions/routing'
import {useRoleMessage} from 'hooks'

export const Layout = forwardRef(({extLink, modalLink, to, onClick, enableRole, ...rest}, ref) => {
	// Use below logic instead of `a` tags to prevent changing the DOM structure, which would be of enormous
	// impact since we use Layout for building all DOM elements
	let _onClick
	if (extLink) _onClick = () => window.open(extLink, '_blank')
	if (to) _onClick = () => history.push(to)
	if (modalLink) _onClick = () => navigateModal(modalLink)
	if (onClick) _onClick = onClick

	const Component = enableRole ? RoleLayout : _Layout
	return <Component ref={ref} onClick={_onClick} enableRole={enableRole} {...rest} />
})

const RoleLayout = forwardRef(({enableRole, roleEnv, disabled, ...rest}, ref) => {
	// If no roleEnv prop is passed, simply 'any' env is used.
	const notPermitted = useRoleMessage({roles: enableRole, env: roleEnv})

	// always prefer role message above custom, content-related message, as to be utmost clear
	// e.g. if something is disabled because there is no contract, adding a contract wouldn't suffice since the user doesn't have a contract role
	disabled = notPermitted || disabled

	return <_Layout disabled={disabled} ref={ref} {...rest} />
})
