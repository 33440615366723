import {useMemo} from 'react'
import {useCache} from '.'
import utils from '@eitje/web_utils'

export const useVisibleColumns = ({tableName, allTableColumns, defaultVisibleColumns}) => {
	const cache = useCache({tableName}).visible || []
	return useMemo(() => filterVisibleColumns(cache, allTableColumns, defaultVisibleColumns), [cache, allTableColumns, defaultVisibleColumns])
}

// Use this memoized function to maintain object reference integrity, since without it, the array of columnNames
// would cause infinite loops when used as dependency in a useEffect hook.
const filterVisibleColumns = (cache, allTableColumns, defaultVisibleColumns) => {
	const hasSavedPreferences = utils.exists(cache)
	const hasDefaults = utils.exists(defaultVisibleColumns)

	// Simply return the stored user preference if present.
	if (hasSavedPreferences) {
		return allTableColumns.filter(column => cache.includes(column)).uniq()
	}

	// For performance, some tables have defaults – prefer them over showing all columns.
	if (hasDefaults) {
		return defaultVisibleColumns
	}

	// Simply show all columns otherwise.
	return allTableColumns
}
