import {writeStore} from 'common/components/advanced_table'
import utils from '@eitje/web_utils'

export const groupColumn = ({isGroupedColumn, columnName, tableName}) => {
	// If the column is already grouped, it should be ungrouped by means of a null value
	const grouped = isGroupedColumn ? null : columnName
	writeStore(tableName, {grouped})
}

export const onGroupingChange = ({groupedColumn, setGrouping, onSortingChange, setSort, instanceTaxonomy, setColumnPinning}) => {
	setGrouping(utils.alwaysDefArray(groupedColumn))

	if (groupedColumn) {
		// When grouping a column, sort on that column by default. The user may change it afterwards.
		const sort = [{id: groupedColumn, desc: false}]
		onSortingChange({sort, setSort, instanceTaxonomy})

		// When grouping a column, make that column the most left column and pin it.
		// FIX: no longer pin the currently pinned columns, since this leads to users unconsciously stacking the pinned columns, until you
		// have your whole screen pinned and see no changes/ new columns on horizontal scroll. If we want to revert, check git's file history.
		const pinnedColumns = [groupedColumn].uniq()
		setColumnPinning({left: pinnedColumns})
	}
}
