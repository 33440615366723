import {useFind} from '@eitje/easy_api'
import useFormData from 'hooks/use_form_data'
import {t} from 'cores/time_registration/pages/add/index'
import {useSimpleContext} from 'contexts/simple'
import AmtWorked from 'cores/time_registration/pages/add/amt_worked'
import {EitjeButton} from 'common/components'
import {Input} from '@eitje/form-fields-web'
import {EitjeAvatar} from 'common/components'
import './styles/user_card.less'

const Decider = () => {
	const {user_id} = useFormData()
	if (!user_id) return <Input readOnly required field="user_id" />
	return <UserCard user_id={user_id} />
}

const UserCard = ({user_id}) => {
	const {setIsPickingUser, isPickingUser} = useSimpleContext()
	const user = useFind('users', user_id)
	return (
		<div className="eitje-form-2-field fake-user-card-field">
			<div className="user-image-wrapper">
				<EitjeAvatar user={user} />
			</div>
			<div className="user-info">
				<h4>{user.full_name} </h4>
				<AmtWorked user={user} />
			</div>
			{!isPickingUser && (
				<EitjeButton className="change-user-button" iconLeft="cross-small" height="small" onClick={() => setIsPickingUser(true)}>
					{t('pick_user')}
				</EitjeButton>
			)}
		</div>
	)
}

export default Decider
