import {useEffect} from 'react'
import Form from 'initializers/form'
import {LegacyDropdownPicker as DropdownPicker, LegacySwitch as Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {useMergeState} from '@eitje/react-hooks'
import useParams from 'hooks/use_params'
import {useEnvSettingGroup} from 'hooks/use_env_setting'
import {saveSettingGroup} from 'actions/settings'
import {AvTextHelper} from 'cores/availability/pages/period_settings'
import {Tooltip} from 'antd'
import {EitjeModal} from 'components/common'
import {EnvRouteSelect} from 'shared/components/env_route_select'
import {EitjeButton} from 'common/components'

const defaultOpts = ['available', 'unavailable'] //'unknown' removed for now
const activeOpts = ['active', 'onlyManagers', 'disabled']
const planningOrdering = ['mixed', 'amt_shifts']

export const mapToDropDownTranslation = arr => arr.map(str => ({name: str == 'disabled' ? t('common.off') : t(str), id: str}))

const getLocalActiveVal = settings => {
	const {disabled, only_managers} = settings
	if (disabled) return 'disabled'
	if (only_managers) return 'onlyManagers'
	return 'active'
}

const mirrorFields = ['localActive']

const AvSettings = () => {
	const {envId} = useParams()
	let initialValues = useEnvSettingGroup('beschikbaarheid', envId)
	initialValues = {...initialValues, localActive: getLocalActiveVal(initialValues)}
	const [state, setState] = useMergeState(initialValues)
	const {localActive} = state
	const isDisabled = localActive == 'disabled'
	const onlyManagers = localActive == 'onlyManagers'

	useEffect(() => {
		setState(initialValues)
	}, [envId])

	const Header = props => (
		<EnvRouteSelect modal shouldTranslate={false} envId={envId} urlFormat="/availability/:envId/settings" {...props} />
	)

	return (
		<EitjeModal width={588} name="availability-settings" RightHeader={Header} title={t('availSettings')}>
			<Form
				mirrorFields={mirrorFields}
				setState={setState}
				throttleTime={2500}
				fieldProps={{submitStrategy: 'throttledChange', bordered: false}}
				onSubmit={data => fixSettings(data, envId)}
				initialValues={initialValues}
			>
				<DropdownPicker field="localActive" label={t('avSettingStatus')} items={mapToDropDownTranslation(activeOpts)} />

				<DropdownPicker label={t('defaultAvStatus')} items={mapToDropDownTranslation(defaultOpts)} disabled={isDisabled} field="default" />
				<div fieldWrapper className="form-row">
					<DropdownPicker
						items={mapToDropDownTranslation(planningOrdering)}
						disabled={isDisabled}
						field="planning_ordering"
						label={t('avPlanningOrder')}
						rightChildren={
							<div className="field-tooltip-container">
								<Tooltip placement="right" title={t('planning_ordering_info')}>
									<img src={'/images/web/icons/infoIcon.png'} width={16} height={16} />
								</Tooltip>
							</div>
						}
					/>
					<Switch
						disabled={isDisabled}
						field="comment_required"
						label={t('requireReason')}
						extraLabel={<p className="eitje-extra-label">{t('requireReasonSub')}</p>}
					/>
				</div>

				{!isDisabled && !onlyManagers && <AvPeriod {...initialValues} />}
			</Form>
		</EitjeModal>
	)
}

export const AvPeriod = props => {
	return (
		<div className="elementContainer">
			<p className="eitje-text-secondary margin-bottom-xss"> {t('avPeriod')} </p>

			<div className="modal-info-banner">
				<AvTextHelper {...props} />
				<div className="full-width vertical-padding-sm">
					<EitjeButton modalLink="period" replace width="full" t="configureAvPeriod" />
				</div>
			</div>
		</div>
	)
}

const fixSettings = (data, envId) => {
	const {localActive} = data
	if (localActive == 'disabled') {
		data.disabled = true
		data.only_managers = false
	} else if (localActive == 'onlyManagers') {
		data.only_managers = true
		data.disabled = false
	} else if (localActive == 'active') {
		data.disabled = data.only_managers = false
	}
	delete data.localActive
	return saveSettingGroup('beschikbaarheid', data, {env_id: envId})
}

export default AvSettings
