import {Spin} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import './styles/index.less'
import {useSelector} from 'react-redux'
import {useShared} from 'hooks'
import {pathActive} from 'helpers'

const iconStyles = {fontSize: 48, color: Colors.mediumBlue}
const AUTHED_URLS_WITH_PLAIN_SPINNER = ['user_info', 'create_environment']

export const EitjeSpinner = ({loading, ...props}) => {
	const pendingRequest = useSelector(state => state.general.loading)
	const spinning = loading || pendingRequest || false // Ant requires an explicit false to hide the spinner

	// Make a lighter background for non-authed pages which are emptier and lighter
	const {me} = useShared()
	const loggedIn = !_.isEmpty(me)
	const fakeAuth = AUTHED_URLS_WITH_PLAIN_SPINNER.some(path => pathActive(path, false))
	const backgroundColor = loggedIn && !fakeAuth && 'rgba(4, 150, 255, 0.25)'
	const overwriteAntStyles = {maxHeight: 'unset', backgroundColor}

	return (
		<div className="eitje-spinner">
			<Spin {...props} spinning={spinning} fullscreen indicator={<LoadingOutlined style={iconStyles} spin />} style={overwriteAntStyles} />
		</div>
	)
}

// If we ever want to pimp our loading screen, we may search for an animation on codepen, see examples:
// https://codepen.io/rbxntl/pen/ExxxZBg
// https://codepen.io/AlexWarnes/pen/jXYYKL
// https://codepen.io/Manoz/pen/kyWvQw
// https://codepen.io/zessx/pen/RNPKKK
// https://codepen.io/hexagoncircle/pen/zrvNwg
// https://codepen.io/chrisgannon/pen/KyzNoa
