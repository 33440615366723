import utils from '@eitje/web_utils'
import {useFind, useWhere} from '@eitje/easy_api'
import {useShared} from 'hooks'

const defaultObj = {}

const useUserWeek = ({date, envId, id}) => {
	const {envId: defaultEnvId} = useShared()
	if (!envId) envId = defaultEnvId
	const {week, year} = utils.getYearWeekFromDate(date)
	let obj = {weeknumber: week, year, user_id: id, environment_id: envId}

	const userWeek = useWhere('userWeeks', obj)[0]

	let defaultWeekObj = {user_id: id, environment_id: envId}
	const userDefaultWeek = useWhere('userTemplateWeeks', defaultWeekObj)[0]
	if (!userWeek && !userDefaultWeek) return defaultObj

	return userWeek ? userWeek : userDefaultWeek
}

export default useUserWeek
