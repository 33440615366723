import {WeekTableCell, EitjeDropdown, viewOptionsDropdownItem} from 'common/components'
import {DocsLink} from 'components/routing'
import {t} from 'initializers/i18n'
import {NAMESPACE} from '.'

export const Panel = () => {
	return (
		<WeekTableCell
			cellType="tile"
			columnType="panel"
			initialBackground={Colors.lightBackground}
			displayName={t('improductive')}
			Wrapper={PanelDropdown}
		/>
	)
}

const PanelDropdown = ({...rest}) => {
	const namespace = `${NAMESPACE}.docs_links`

	const elements = [
		{icon: 'question-mark-circled', element: <DocsLink to="6019044" t={t(`${namespace}.register_improductivity`)} />},
		{icon: 'question-mark-circled', element: <DocsLink to="6025153" t={t(`${namespace}.report_sick`)} />},
		useViewOptions(),
	]

	return <EitjeDropdown elements={elements} {...rest} placement="right-start" strategy="fixed" />
}

const buttons = ['improductiveShowHours', 'improductiveShowReason', 'improductiveShowAvatar']

function useViewOptions() {
	return viewOptionsDropdownItem({record: 'absence', buttons})
}
