import utils from '@eitje/web_utils'
import useMergeState from 'hooks/use_merge_state'
import {t} from 'initializers/i18n'
import {useEffect} from 'react'
import {useDebug} from 'helpers'

const totMins = week => utils.reduce(utils.weekdays.map(d => week[d]))
const totDays = week => utils.weekdays.filter(d => week[d] > 0).length
export const totalDays = weeks => utils.reduce(weeks.map(w => totDays(w))) / weeks.length
export const totalMins = weeks => utils.reduce(weeks.map(w => totMins(w))) / weeks.length

export const useWorkDays = (weeks = []) => {
	const [total, setTotal] = useMergeState({days: totalDays(weeks), mins: totalMins(weeks)})

	useEffect(() => {
		setTotal({days: totalDays(weeks), mins: totalMins(weeks)})
	}, [weeks.length])

	const afterChange = (field, data) => {
		if (!utils.weekdays.includes(field)) return
		setTotal({days: totalDays(data), mins: totalMins(data)})
	}
	return {total, afterChange}
}

const FT_MINS = 480

export const makeMinsObj = data => {
	let obj = {}
	let amtDays = Math.ceil(data / FT_MINS)
	if (amtDays > 5) amtDays = 5
	const minsPerDay = data / amtDays
	const workdays = utils.weekdays.slice(0, amtDays)
	const otherDays = utils.weekdays.slice(amtDays, 7)
	workdays.forEach(d => (obj[d] = minsPerDay))
	otherDays.forEach(d => (obj[d] = 0))
	return obj
}

export const Label = ({formData, formIdx}) => {
	const key = utils.isOdd(formIdx) ? t('odd') : t('even')
	const isDebug = useDebug()
	return (
		<p style={{fontSize: 10, color: '#828282'}}>
			{t('workWeekLabel', {days: totalDays([formData]), kind: `$t(${key})`})}

			{isDebug && <> position: {formData.position} </>}
		</p>
	)
}
