import {Placeholder, ChatButton, RequestFeatureButton} from 'common/components'

export const SupportPlaceholder = () => {
	return (
		<Placeholder name="support_collections" animation="magnifying-glass" layoutProps={{height: 'unset', width: 'full'}}>
			<ChatButton colorSet="solid" />
			<RequestFeatureButton />
		</Placeholder>
	)
}
