import {DatePicker} from 'antd'
import {makeCnVariants} from 'helpers'
import './styles/range_picker.less'

const {RangePicker: AntRangePicker} = DatePicker

const RangePicker = props => {
	const {maxDays, value, height} = props
	let condOpts = {}
	if (maxDays) {
		condOpts['disabledDate'] = date => disabledDate(date, {maxDays, dates: value})
	}

	const classNames = makeCnVariants('eitje-range-picker', height === 'small' && 'small')

	return <AntRangePicker {...condOpts} className={classNames} format="DD-MM-YYYY" {...props} />
}

const disabledDate = (current, {maxDays, dates}) => {
	if (!dates) {
		return false
	}
	const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDays
	const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDays
	return !!tooEarly || !!tooLate
}

export default RangePicker
