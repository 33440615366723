import {TeamDayProvider, useTeamShifts} from 'cores/planning/index'
import TeamDayShifts from './shifts/index'

const orderShifts = shifts => _.sortBy(_.sortBy(shifts, 'end_date'), 'start_date')

const TeamShiftsDay = ({date}) => {
	const teamShifts = useTeamShifts()
	const shifts = orderShifts(teamShifts.where({date: date}))
	return (
		<TeamDayProvider date={date}>
			<TeamDayShifts shifts={shifts} />
		</TeamDayProvider>
	)
}

export default TeamShiftsDay
