import {useBirthdays} from './birthdays'
import {useContracts} from './contracts'

export function useReminders(scope) {
	const extraProps = {read: true} // BE doesn't store read status, so always set to true for now
	const birthdays = useBirthdays({extraProps})
	const contracts = useContracts({extraProps})

	if (scope === 'birthdays') return _.flatten(birthdays)
	if (scope === 'contracts') return _.flatten(contracts)
	return _.flattenDeep([birthdays, contracts])
}
