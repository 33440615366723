import utils from '@eitje/web_utils'
import {FilterTrigger} from '@eitje/web_components'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {roleEnvSelector} from 'selectors/new_settings'
import {DropdownWithCounter} from 'components/ui'
import {ListPicker} from 'common/components'

export const useEnvFilter = ({
	dropdown = true,
	role,
	initialValue = [],
	afterChange = _.noop,
	Component = DropdownWithCounter,
	defaultToCurrentEnv,
	raw = true,
	...rest
} = {}) => {
	const {orgEnvs, env: currentEnv} = useShared()
	let possibleEnvs = orgEnvs

	const roleEnvs = useSelector(state => roleEnvSelector(state, role))

	if (role) {
		possibleEnvs = possibleEnvs.filter(e => roleEnvs.includes(e.id))
	}

	let envs = possibleEnvs
	// Need to double check that the role permissions for this allows all users to set this default
	const [envFilter, setEnvFilter] = useState(defaultToCurrentEnv ? [currentEnv.id] : initialValue)
	const hasFiltered = !!envFilter.length && possibleEnvs.length !== 1

	const _setEnvFilter = newVal => {
		setEnvFilter(newVal)
		afterChange(newVal, {possibleEnvs})
	}

	if (utils.exists(envFilter)) {
		envs = envs.filter(e => envFilter.includes(e.id))
	}

	if (!utils.exists(envs)) {
		envs = possibleEnvs
	}

	const filter = (
		<ListPicker
			dropdown={dropdown}
			placeholder={t('common.environment_plural')}
			showCount
			Component={Component}
			name={t('environment')}
			labelVisible
			useSearch={false}
			labelField="naam"
			items={possibleEnvs}
			onChange={_setEnvFilter}
			value={envFilter}
			Trigger={FilterTrigger}
			raw={raw}
			{...rest}
		/>
	)

	return {envs, possibleEnvs, setEnvFilter: _setEnvFilter, hasFiltered, filter: possibleEnvs.length > 1 ? filter : null}
}

export default useEnvFilter
