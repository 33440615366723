import Shift from './shift'
import {makeRange} from 'helpers/date'
import {date} from 'initializers/date'

class CheckIn extends Shift {
	static tableName = 'checkIns'

	dateRange() {
		const end = this.end_date || date()
		return makeRange(this.start_date, end)
	}

	_dateRange() {
		return this.dateRange()
	}

	totalMins() {
		return this._dateRange().diff('minutes') - this.breakTime()
	}
}

export default CheckIn
