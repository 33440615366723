import {useTranslationContext} from 'stream-chat-react'
import {Icon} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {EitjeAvatar, Layout, Text} from 'common/components'
import {ftime} from 'helpers'
import {ATTACHMENT_TRANSKEYS} from 'cores/chat'

export const BasePreview = ({title, image, active, unread, userId, lastMessage, onClick, includeSenderName}) => {
	const {t: streamT} = useTranslationContext()
	const messagePreview = lastMessagePreview(lastMessage, includeSenderName, streamT)

	return (
		<Layout gap={12} padding="10 12" colorSet="grey-bordered" onClick={onClick} active={active} borderBottom height={56}>
			<EitjeAvatar
				imageSrc={image}
				userId={userId}
				colorSet="color-bordered-white"
				style={{'--medium-color': Colors.mediumBlue, backgroundColor: Colors.lightBlue}}
			/>
			<Layout direction="vertical" gap={0} grow>
				<Layout gap={8} width="full">
					{!!unread && <Icon name="dot-small" fill={Colors.mediumBlue} />}
					<Text truncate truncateTooltip={false}>
						{title}
					</Text>
				</Layout>
				<Text darkGrey truncate truncateTooltip={false} fontSize={12}>
					{messagePreview}
				</Text>
			</Layout>
		</Layout>
	)
}

function lastMessagePreview(message, includeSenderName, streamT) {
	if (!message) return null

	const {
		text,
		type,
		command,
		attachments,
		updated_at,
		user: {name},
	} = message
	const lastMessageDisplayTime = ftime(updated_at, 'relativeToDate')
	let lastMessageText

	if (type === 'deleted') {
		lastMessageText = streamT('This message was deleted...')
	} else if (text) {
		lastMessageText = text
	} else if (command === 'giphy') {
		lastMessageText = t('common.gif')
	} else if (attachments?.length) {
		const attachmentCount = attachments.length

		if (attachmentCount > 1) {
			lastMessageText = `${attachmentCount} ${t('attachment')}`
		} else {
			lastMessageText = t(ATTACHMENT_TRANSKEYS[attachments[0].type])
		}
	}

	if (includeSenderName) {
		lastMessageText = `${name}: ${lastMessageText}`
	}

	return [lastMessageDisplayTime, lastMessageText].filter(Boolean).join('∙')
}
