import React, {createContext, useMemo, useContext} from 'react'
import utils from '@eitje/web_utils'
import {usePlanningContext} from './planning_provider'
import {useGet, useOrgEnvsSettingGroup} from 'hooks'

const RevenueContext = createContext({})
const {Provider} = RevenueContext

const RevenueProvider = ({children}) => {
	const {startDate} = usePlanningContext()
	const {week, year} = utils.getYearWeekFromDate(startDate)
	const params = {week, year}
	const revSettings = useOrgEnvsSettingGroup('omzet')
	const watchParams = useMemo(() => [revSettings, startDate], [revSettings, startDate])

	const {
		data: {days = []},
	} = useGet(`revenue/week_forecast`, {params, watch: watchParams})

	const value = {days}
	return <Provider value={value}> {children} </Provider>
}

export const useRevenueContext = () => useContext(RevenueContext)

export default RevenueProvider
