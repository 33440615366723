const initialState = {
	errors: [],
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOCAL_INDEX_RECORDS': {
			// implement with table name!
			const newIds = action.items.map(i => i.id)
			const newErrors = state.errors.filter(e => !newIds.includes(e.id))
			return {
				...state,
				errors: newErrors,
			}
		}
		case 'SET_RECORD_ERRORS':
			return {
				...state,
				errors: action.payload,
			}
		case 'CLEAR_RECORD_ERRORS':
			return initialState
		default:
			return state
	}
}
