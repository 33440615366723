import React from 'react'
import {PageHeader as _PageHeader, EitjeButton, useFilterFooterContext} from 'common/components'
import {Publish} from './publish'
import {Tools} from './tools'
import {Help} from './help'
import {ViewOptions} from './view_options'
import {usePlanningContext} from 'cores/planning/index'
import FixedRangePicker from 'components/date/fixed_range_picker'
import {useRoleMessage, usePathActive} from 'hooks'
import {RouteSelect as _RouteSelect} from 'cores/planning/components'

const PageHeader = () => {
	return (
		<_PageHeader
			height="small"
			label="common.planning_shifts"
			left={[<RouteSelect />, <RangePicker />]}
			right={[<ViewOptions />, <Tools />, <Publish />, <Help />]}
		/>
	)
}

const RangePicker = props => {
	const {setDates, startDate} = usePlanningContext()
	return <FixedRangePicker {...props} onChange={dates => setDates(dates[0])} initialValue={startDate} length={7} />
}

const RouteSelect = props => {
	const perTeam = usePathActive('planning/per_team')
	const currentVal = perTeam ? '/planning/per_team' : '/planning/per_member'

	return <_RouteSelect {...props} currentVal={currentVal} />
}

export default React.memo(PageHeader)
