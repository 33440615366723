import utils from '@eitje/web_utils'
import {flexRender} from '@tanstack/react-table'
import {getAggregateFn, getAggregateCell} from './aggregate_functions'

export const ColumnTotal = ({header, rows}) => {
	const {column, idx, id: columnName} = header
	const {columnDef} = column
	const {headerAggregates = true, aggregates, aggregatesFn, cell, enableRole} = columnDef

	// Show aggregates by default
	const showAggregates = headerAggregates !== false && (aggregatesFn || aggregates !== false)

	// If a role is required to see the value of a cell, simply don't show aggregates for anyone for now.
	// If you would show the aggregates, someone could reveal hidden values by searching a user in the table
	// so that there is only one cell aggregated and then look at the aggregate, e.g. retrieving a salary.
	// Alternatively filter the rows on which one may be shown to the user, but this is heavy and complicated,
	// since for each row, the environmentIds should be retrieved and checked per row, calling multiple hooks
	// per row, which is very intensive when there're thousands of rows.
	if (enableRole) return null

	// Return if no aggregates are required
	if (!showAggregates) return null

	const fn = getAggregateFn(columnDef)
	if (!fn) return null

	const aggregatedRows = rows
		.map(row => {
			const {getIsExpanded, subRows} = row
			if (getIsExpanded()) return null // don't double count collapsible rows
			if (utils.exists(subRows)) return subRows // count subrows if present
			return row // or count the row itself if it's a regular row
		})
		.flat()
		.filter(Boolean)

	const aggregateValue = fn(columnName, aggregatedRows)
	const getValue = () => aggregateValue
	const Component = getAggregateCell(columnDef)
	const props = {getValue, cell, rows, column, isHeader: true}

	return flexRender(Component, props)
}
