import {NavigationHeader} from 'common/components'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {EitjeButton, Placeholder} from 'common/components'
import {logout} from 'actions/auth'

const layoutProps = {
	padding: [180, 0],
}

class Err extends Component {
	render() {
		return (
			<div id="error-page-500">
				<NavigationHeader />
				<Placeholder layoutProps={layoutProps} name="oops" animation="broken-egg" supportButton>
					<EitjeButton iconLeft="arrow-circle" blockNamespace t="signOut" colorSet="solid" onClick={logout} />
				</Placeholder>
			</div>
		)
	}
}

export default connect(state => ({
	user: state.auth.user,
}))(Err)
