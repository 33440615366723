import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {useWhere} from '@eitje/easy_api'
import {PopoutCard} from '@eitje/web_components'
import {useEnvContext} from '../contexts/env'
import {UserAvatar} from 'components/ui'
import Dropdown from 'components/dropdown'
import useList from 'hooks/use_list'
import WeekShifts from 'cores/availability/components/week_shifts'
import {useAvContext} from 'contexts/availability'
import {loadTemplate, loadAllTemplates} from 'cores/availability/actions'
import utils from '@eitje/web_utils'
import './components.less'
import {EitjeButton, Text, Title, Layout} from 'common/components'

const InsertButton = () => {
	const elements = [<WeekShiftsPopover triggerText="amtShiftsInWeek" />, <TemplatePopover triggerText="loadTemplate" />]
	return (
		<Dropdown visibleAfterClick elements={elements}>
			<EitjeButton colorSet="solid" t="insert" />
		</Dropdown>
	)
}

export const UserListContainer = ({list, heading, subHead, search, disableRowSelect, triggerText, footer}) => {
	const listContainerClass = utils.makeCns(
		'popover-list-of-users user-list-sm horizontal-padding-xs flex-grow-one',
		disableRowSelect && 'disable-pointer',
	)

	const header = (
		<Layout direction="vertical" padding={12}>
			<Title>{heading}</Title>
			<Text mini darkGrey>
				{subHead}
			</Text>
			{search}
		</Layout>
	)

	const body = <div className={listContainerClass}>{list}</div>
	return (
		<PopoutCard
			bodyPadding={0}
			trigger="click"
			placement="left-start"
			header={header}
			body={body}
			maxHeight={500}
			width={250}
			footer={footer}
		>
			<div className="full-width">{t(triggerText)}</div>
		</PopoutCard>
	)
}

const WeekShiftsPopover = props => {
	const {startDate} = useAvContext()
	const {allUsers} = useEnvContext()
	const itemProps = {date: startDate}
	const {list, searchInput} = useList({
		items: allUsers,
		searchProps,
		itemProps,
		showPlaceholder: true,
		ListItem: WeekShiftsItem,
	})

	return (
		<UserListContainer
			disableRowSelect
			list={list}
			heading={t('amtShiftsInWeek')}
			subHead={t('amtShiftsInWeekExplained')}
			search={searchInput}
			{...props}
		/>
	)
}

const searchProps = {
	searchField: 'full_name',
}

const TemplatePopover = props => {
	const {env, allUsers} = useEnvContext()
	const {list, searchInput} = useList({items: allUsers, searchProps, ListItem: TemplateItem})
	return (
		<UserListContainer
			list={list}
			heading={t('loadTemplate')}
			subHead={t('insertTemplateExplained')}
			search={searchInput}
			footer={<LoadAll env={env} />}
			{...props}
		/>
	)
}

const LoadAll = ({env}) => {
	const {startDate, endDate} = useAvContext()
	const _loadAll = async () => {
		const params = {start_date: startDate, end_date: endDate}
		loadAllTemplates({params, envId: env.id})
	}

	return <EitjeButton onClick={_loadAll} width="full" colorSet="solid" t="common.load_all" />
}

const TemplateItem = ({item}) => {
	const {dateRange} = useAvContext()
	const userAvShifts = useWhere('availabilityShifts', {date: dateRange, user_id: item.id})

	return (
		<div className="row" onClick={() => loadTemplate({user_id: item.id, dateRange, avShifts: userAvShifts})}>
			<UserAvatar user={item} className="margin-right-sm" />
			<p className="flex-grow-one prevent-text-overflow">{item.full_name} </p>
			<img src="/images/dropDown1.png" width={12} height={12} id="rotate-dropdown-arrow" />
		</div>
	)
}

const WeekShiftsItem = ({item, date}) => {
	return (
		<>
			<UserAvatar user={item} className="margin-right-sm" />
			<p className="flex-grow-one prevent-text-overflow"> {item.full_name} </p>
			<WeekShifts date={date} userId={item.id} />
		</>
	)
}

export {InsertButton}
