import {PopoutCard} from '@eitje/web_components'
import {useFind} from '@eitje/easy_api'
import {DeleteButton, ConfirmButton, Text, Title, Layout} from 'common/components'
import {t} from 'cores/time_registration/index'
import './styles/index.less'

export const CheckInPopoverWrapper = ({children, shift}) => {
	return (
		<PopoutCard className="check-in-popover" trigger="click" body={<CheckInPopover shift={shift} />}>
			{children}
		</PopoutCard>
	)
}

const MARGIN = [12, 0, 0, 0]

const CheckInPopover = ({shift}) => {
	const user = useFind('users', shift.user_id)
	const name = user.full_name
	return (
		<Layout name="time_registration.check_in_popover" direction="vertical">
			<Title t="title" />
			<Text t={{key: 'subtitle', name}} />

			<Layout margin={MARGIN}>
				<ConfirmButton
					iconLeft="checkbox-checked"
					onClick={() => shift.resourceReq('new_web_check_out')}
					children={t('check_in_popover.check_out', {name})}
					colorSet="solid"
					className="button-check-out-user"
				/>
				<DeleteButton onClick={() => shift.destroy()} children={t('destroy')} />
			</Layout>
		</Layout>
	)
}

export default CheckInPopoverWrapper
