import {API, find} from '@eitje/easy_api'
import {EasyRemoveArea, Icon} from '@eitje/web_components'
import {delUser} from 'actions/environment'
import {Layout, ListPicker, Text} from 'common/components'
import {SyncAgendaBtn} from 'components/general'
import {ModalLink} from 'components/routing'
import {RoundedButton} from 'components/ui'
import useAvailability from 'hooks/use_availability'
import useParams from 'hooks/use_params'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {Route} from 'react-router-dom'
import {analyticsSelector} from 'selectors/arbitrary'
import {roleEnvSelector} from 'selectors/new_settings'
import {inEnv, orgEnvsSelector} from 'selectors/records'
import AvailabilityWrapper from './availability/shifts/index'
import AvailabilityTemplate from './availability/template/index'
import './styles/profile.less'

const addToEnv = (user, envId) => {
	API.addAssoc('users', {environment_ids: [envId], id: user.id})
}

const addToTeam = (user, teamId) => {
	API.addAssoc('users', {team_ids: [teamId], id: user.id})
}

const delFromTeam = (user, teamId) => {
	API.removeAssoc('users', {team_ids: [teamId], id: user.id})
}

const ProfilePageTrigger = ({triggerProps: {user}, title, ...rest}) => (
	<Layout gap={16} horizontal="end">
		<Text small greyMedium>
			{t(title, {name: user.voornaam})}
		</Text>
		<Icon name="chevron-small-down" />
	</Layout>
)

const Profile = props => {
	const {isRemoved, userBanner, path} = props
	const {envId, org, me, hasManagerRole} = useShared()

	const {id} = useParams()
	const orgEnvs = useSelector(orgEnvsSelector)
	const user = useSelector(state => find(state, 'users', id))
	const envIds = _.intersection(user.environment_ids, org.environment_ids || [envId])

	const managerEnvs = useSelector(state => roleEnvSelector(state, 'manager'))
	const otherEnvs = orgEnvs.filter(o => managerEnvs.includes(o.id) && !user.environment_ids.includes(o.id))

	const userOrgEnvs = orgEnvs.filter(e => managerEnvs.includes(e.id) && envIds.includes(e.id))
	const isSelf = me.id === user.id

	// AVAILABILITY
	const {disabled, only_managers} = useAvailability()
	const avAllowed = !disabled && ((isSelf && !only_managers) || hasManagerRole)

	return (
		<div className="user-inner-grid">
			<div>
				{userBanner}
				<div>
					{user.note && hasManagerRole && (
						<ModalLink hash="user_note" to={`/forms/user_preferences/${id}`}>
							<div className="user-note-container">
								<img width={16} src="/images/note.png" />
								<div>
									<p className="eitje-text-secondary">{t('noteByManager')}</p>
									<p>{user.note} </p>
								</div>
							</div>
						</ModalLink>
					)}

					{avAllowed && (
						<>
							<Route path={`${path}/profile`} exact>
								<AvailabilityWrapper {...props} id={user.id} />
							</Route>
							<Route path={`${path}/profile/availability_template`} exact>
								<AvailabilityTemplate {...props} id={user.id} />
							</Route>
						</>
					)}

					{orgEnvs.length > 1 && (
						<Layout type="card" padding={16} className="user-profile-environment-teams-container" gap={16}>
							<Layout gap={8}>
								<img width={12} src="/images/organisation.png" />
								<Text truncate={140} bold>
									{t('envs')}
								</Text>
							</Layout>

							<EasyRemoveArea items={userOrgEnvs} shouldConfirm action={(value, item) => delUser(item.id, id)} size="small" />
							<ListPicker
								raw
								dropdown
								single
								onChange={id => addToEnv(user, id)} // listSelect always returns an array
								labelField="naam"
								items={otherEnvs}
								Trigger={ProfilePageTrigger}
								title="addUserToEnv"
								triggerProps={{
									user,
								}}
							/>
						</Layout>
					)}
					{userOrgEnvs.map(e => (
						<TeamSelect user={user} env={e} />
					))}
				</div>
			</div>
			{/* SIDE PANEL */}
			<div className="user-details-column">
				{isSelf && <SyncAgendaBtn />}

				<div className="user-details-column-general-info-block padding-xl border white-background-and-big-shadow full-width large-border-radius">
					{/* This should be a component */}
					<div className="row-with-justify-between margin-bottom-md">
						<div>
							<div className="eitje-heading-xl">{t('personale')}</div>
							<p className="eitje-text-secondary">{t('information')}</p>
						</div>

						{(hasManagerRole || isSelf) && !isRemoved && (
							<ModalLink to={`/forms/user_preferences/${id}`}>
								<RoundedButton imgSrc={'/images/editProfile.png'} />
							</ModalLink>
						)}
					</div>
					<div className={'margin-bottom-md'}>
						<p className="eitje-text-secondary">{t('email')}</p>
						<p>{user.email}</p>
					</div>
					<div>
						<p className="eitje-text-secondary">{t('telefoonnummer')}</p>
						<p>{user.telefoonnummer}</p>
					</div>
				</div>

				{hasManagerRole && <Analytics id={user.id} />}
			</div>
		</div>
	)
}

const StatFooterBlock = ({source, count, title}) => {
	return (
		<div className="row-with-border-and-padding-sm gap-sm">
			<img width={32} src={source} />
			<div>
				<p className="eitje-text-secondary">{title}</p>
				<p className="eitje-heading-md">{count}x</p>
			</div>
		</div>
	)
}

const Analytics = ({id}) => {
	const data = useSelector(state => analyticsSelector(state, id))
	const {accepted_trade, asked_trade, worked_shift, likes, read_manual_items} = data
	return (
		<>
			<StatFooterBlock source={'/images/workedShiftCount.png'} count={worked_shift} title={t('workedShift')} />
			<StatFooterBlock source={'/images/tradeInCount.png'} count={accepted_trade} title={t('acceptTradeReq')} />
			<StatFooterBlock source={'/images/tradeOutCount.png'} count={asked_trade} title={t('asked_trade')} />
			<StatFooterBlock source={'/images/handboekCount.png'} count={read_manual_items} title={t('manual_read')} />
			<StatFooterBlock source={'/images/highfiveCount.png'} count={likes} title={t('highfived')} />
		</>
	)
}

const TeamSelect = ({env, user}) => {
	const envTeams = useSelector(state => inEnv(state, 'teams', env.id)) // should work asses
	const userEnvTeams = envTeams.filter(t => user.team_ids.includes(t.id) && !t.locked)
	const potentialTeams = envTeams.filter(t => !user.team_ids.includes(t.id) && !t.locked)
	return (
		<Layout type="card" padding={16} className="user-profile-environment-teams-container" gap={16}>
			<Layout gap={8}>
				<img width={12} src="/images/location.png" />
				<Text truncate={140} bold>
					{env.naam}
				</Text>
			</Layout>
			<EasyRemoveArea size="small" items={userEnvTeams} shouldConfirm action={(value, item) => delFromTeam(user, item.id)} />
			<ListPicker
				raw
				showSearch={potentialTeams.length > 5}
				onChange={id => addToTeam(user, id)}
				single
				dropdown
				labelField="naam"
				title="addUserToTeam"
				items={potentialTeams}
				Trigger={ProfilePageTrigger}
				triggerProps={{
					user,
				}}
			/>
		</Layout>
	)
}

export default Profile
