import React, {useState} from 'react'
import {LegacySwitch as Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, backend} from '@eitje/easy_api'
import {confirm} from 'components/general/modal'
import utils from '@eitje/web_utils'

let force // looks hacky but seems to be the only way to let a function communicate with a confirm modal, it's just friggin' BS that MyComp can't communicate in another way with onOk / onCancel, and that we can't provide custom footer
// as it's not too much code, I don't really mind, while we wait for a solutioln from Antd

export const loadAllTemplates = props => {
	return confirm({
		content: <MyComp />,
		onOk: () => loadAll({...props}),
	})
}

const loadAll = async ({params, envId}) => {
	params = {...params, force}
	const res = await backend.post(`environments/${envId}/load_availability`, params)
	if (res.ok) {
		utils.openNotif(t('availability.loading_templates_title'), 'info', t('availability.loading_templates'))
	}
	force = false

	return res
}

export const loadTemplate = async ({avShifts, ...rest}) => {
	if (avShifts.length > 0) {
		confirm({
			content: <MyComp />,
			onOk: () => _loadTemplate({...rest}),
		})
	} else {
		_loadTemplate(rest)
	}
}

export const toggleDayClosed = async (date, val, environment_id) => {
	const res = await API.update('days', {id: date.format(), closed: val, environment_id})
}

const _loadTemplate = async ({dateRange, ...rest}) => {
	const start_date = dateRange.start.format()
	const end_date = dateRange.end.format()
	const res = await API.arbitrary('availabilityShifts', 'load_template', {start_date, end_date, force, ...rest})
	force = false // reset, not managed by React
}

const MyComp = () => {
	const [checked, setChecked] = useState()
	const _setChecked = val => {
		force = val
		setChecked(val)
	}
	return (
		<div className=" horizontal-padding-xl">
			<Switch className="default-field" label={t('overrideTemplate')} value={checked} onChange={val => _setChecked(val)} />
		</div>
	)
}
// {/* <p>overwrite, bro? | {checked} </p> */}
