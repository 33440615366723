import {backend} from '@eitje/easy_api'
import {store} from 'index'
import {getTeam} from 'selectors/entities'

export async function getPlusMin() {
	const res = await backend.get(`roosters/new_plus_min_saldo.json`)
	if (res.ok && res.data) {
		const json = res.data
		const obj = {}
		json.users.forEach(u => (obj[u.id] = u.diff))
		store.dispatch({type: 'GET_PLUS_MIN', data: obj})
	}
}
export async function getAccordShifts(startDate, endDate, extra = {}) {
	store.dispatch({type: 'SHIFTS_LOADING'})
	const res = await backend.get('new_uren', {from: startDate, till: endDate, ...extra})

	if (res.ok) {
		const shifts = res.data.shifts.map(s => shiftMapper(s))
		store.dispatch({type: 'GET_SHIFTS', shifts: shifts, checkIns: res.data.check_ins})
	}
	store.dispatch({type: 'SHIFTS_LOADED'})
}

export const shiftMapper = s => {
	const state = store.getState()
	const team = getTeam(state, s.team_id)
	if (team) s.environment_id = team.environment_id
	s.original_start_date = s.start_date
	s.original_end_date = s.end_date
	return s
}
