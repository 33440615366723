import {where} from '@eitje/easy_api'
import {useState} from 'react'
import utils from '@eitje/web_utils'
import {postComment} from 'actions/news'
import EmoInput from 'components/form/emo_input'
import {Player} from 'components/general/video'
import {LightboxImage, MaxList} from 'components/ui'
import {useShared} from 'hooks/use_shared'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import Linkify from 'linkify-react'
import _ from 'lodash'
import React, {useRef, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useOnScreen} from 'hooks/index'
import {Comment, CommentCount, EventRow, File, LikeButton, Likers, ManualItem, Poll, PostHeader, PostVideoUpload} from './components'

const MAX_LEN = 280

const Post = ({item, setReadPosts, onClick}) => {
	const [expanded, setExpanded] = useState(false)
	const {me} = useShared()
	const outer = useRef(null)
	const body = useRef(null)
	const {
		liking_user_ids: liker_ids = [],
		id,
		event_id,
		published,
		info_ids = [],
		poll,
		avatar,
		file,
		reader_ids,
		comments = [],
		video_ids = [],
		video_url_map = {},
		unprocessed,
	} = item

	const {embedded, thumbnail} = video_url_map
	const hasVideo = embedded && thumbnail

	const likers = useSelector(state => where(state, 'users', liker_ids))

	const isVisible = useOnScreen(body)

	useEffect(() => {
		if (isVisible && !reader_ids.includes(me.id)) {
			setReadPosts(id)
		}
	}, [isVisible])

	// If Video is still prcessing display the processing
	if (unprocessed) {
		return <PostVideoUpload postId={item.id} />
	}

	const isTruncated = item.content.length > MAX_LEN
	const content = expanded ? item.content : utils.truncateString(item.content, MAX_LEN, true)

	const hasLiked = liker_ids.includes(me.id)

	const manualClass = utils.makeCns(
		info_ids.length > 1 ? 'news-manual-multi-item-container' : 'manualItemWrapper',
		poll && 'padding-top-xl',
	)
	const bodyClassName = utils.makeCns('news-post-body', !published && 'unpublished')
	const toggleText = expanded ? t('showLess') : t('showMore')
	const imageClassName = utils.makeCns('news-post-image', isTruncated && 'padding-top-xss', liker_ids.length === 0 && 'margin-bottom-sm')
	const commentContainerClassName = utils.makeCns(liker_ids.length === 0 && !avatar && 'padding-top-xl')

	const toggleExpand = e => {
		e.stopPropagation()
		setExpanded(!expanded)
	}

	return (
		<div onClick={() => setExpanded(true)} ref={outer} className="news-post-container">
			<div className="margin-bottom-md">
				<PostHeader postId={item.id} />
			</div>

			<div ref={body} className={bodyClassName}>
				<div onClick={onClick}>
					<span className="news-post-content">
						<Linkify options={{target: '_blank'}} key={item.id}>
							{content}
						</Linkify>
					</span>
					{isTruncated && (
						<span onClick={toggleExpand} className="text-underlined bold padding-left-xs">
							{toggleText}
						</span>
					)}
				</div>

				{hasVideo && (
					<div className="padding-h-sm">
						<Player url={embedded} />
					</div>
				)}

				{avatar && <LightboxImage src={avatar} className={imageClassName} />}

				{poll && <Poll item={poll} />}

				{info_ids.length > 0 && (
					<div className={manualClass}>
						{info_ids.map((infoId, idx) => (
							<ManualItem id={infoId} idx={idx} />
						))}
					</div>
				)}

				{event_id && <EventRow id={event_id} />}

				{liker_ids.length > 0 && <Likers likers={likers} />}

				{published && (
					<div className={commentContainerClassName}>
						<div className="full-width padding-bottom-xs">
							<div className="row-with-justify-between" style={{minHeight: 44}}>
								<LikeButton id={id} hasLiked={hasLiked} />
								{file && <File file={file} />}
								<CommentCount len={comments.length} />
							</div>
						</div>

						<Form resetAfterSubmit onSubmit={data => postComment(data.content, id)}>
							<EmoInput
								placeholder={t('reactNews')}
								required
								labelVisible={false}
								bordered={false}
								field="content"
								submitStrategy="inlineButton"
								SubmitButton={props => <img src="/images/web/icons/resend.png" className=" pointer icon20" />}
							/>
						</Form>

						<MaxList
							containerClassName={['vertical-padding-sm', comments.length >= 3 && 'margin-bottom-xl']}
							customContainerRef={outer}
							moreTitle={t('readMoreComments', {count: comments.length})}
							initialMax={2}
						>
							{_.orderBy(comments, 'created_at', ['asc']).map(c => (
								<Comment item={c} />
							))}
						</MaxList>
					</div>
				)}
			</div>
		</div>
	)
}

export default Post
