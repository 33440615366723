import {PrivateRoute, ProtectedRoute} from 'components/private_route'
import {New, Edit, Approve, Settings, LeaveIndex} from 'cores/leave/pages'

const leaveForegroundRoutes = [<PrivateRoute path="/leave" exact component={LeaveIndex} />]

const leaveBackgroundRoutes = [
	<PrivateRoute path="/leave/new" component={New} />,
	<PrivateRoute path="/leave/:id/approve" component={Approve} />,
	<ProtectedRoute role="admin" path="/leave/:envId/settings" fbPath="/leave" component={Settings} />,
	<PrivateRoute path="/leave/:id" component={Edit} />,
]

export {leaveForegroundRoutes, leaveBackgroundRoutes}
