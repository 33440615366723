import utils from '@eitje/web_utils'
import './styles/index.less'
import {useSimpleContext} from 'contexts/simple'

export const ShiftTitle = ({title, titleAddition}) => (
	<div className="shift-title-container">
		<h5 className="shift-title">{title}</h5>
		{!!titleAddition && <h6 className="shift-title-addition"> {utils.funcOrVal(titleAddition)} </h6>}
	</div>
)

export const Breaktime = () => {
	const {
		item: {pauze_duur},
	} = useSimpleContext()
	if (!pauze_duur) return null
	return <h6 className="break-time">({pauze_duur})</h6>
}
