import {childrenWithClonedProps} from 'helpers/children'
import {useRef, useEffect, useState} from 'react'
import LazyLoad from 'react-lazyload'

const HeightWrapper = ({children, setHeight}) => {
	const ref = useRef()
	useEffect(() => {
		return setHeight(ref.current?.clientHeight)
	}, [])

	return <div ref={ref}>{children}</div>
}

export const EitjeLazyLoad = ({
	children,
	placeholder,
	calculateHeight,
	overflow = true,
	offset = 200,
	unmountIfInvisible = true,
	scrollContainer = '.page-body',
	...rest
}) => {
	const [height, setHeight] = useState()
	const _height = height || rest.height

	const _placeholder = placeholder && childrenWithClonedProps({children: placeholder, props: {height: _height}})
	return (
		<LazyLoad
			{...rest}
			height={_height}
			placeholder={_placeholder}
			overflow={overflow}
			offset={offset}
			unmountIfInvisible={unmountIfInvisible}
			scrollContainer={scrollContainer}
		>
			{calculateHeight ? <HeightWrapper setHeight={setHeight}>{children}</HeightWrapper> : children}
		</LazyLoad>
	)
}
