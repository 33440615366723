import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {ListPicker} from 'common/components'
import useMergeState from 'hooks/use_merge_state'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {forwardRef} from 'react'
import {crazyFilter} from 'selectors/records'
import 'styles/app/components/lists/grid_list.less'

const isOk = res => {
	return (_.isObject(res) && res.ok) || utils.exists(res)
}

const listOpts = {
	groupLabelStyle: {color: 'red'},
	groupStyle: {backgroundColor: '#0496ff20'},
}

export const GridSelect = forwardRef((props, ref) => {
	const [data, setData] = useMergeState({})
	let {
		topItems = [],
		field,
		filter,
		filterTopItems = filter,
		renderLabel,
		leftItems = [],
		onSubmit = () => {},
		afterSubmit = () => {},
		...rest
	} = props

	const submit = async () => {
		// make data into [{skill_ids: {added: [], removed: []}, id: 39 }]
		const arr = []
		if (data.length === 0) return

		Object.keys(data)
			.map(Number)
			.forEach(id => {
				const stored = topItems.find(i => i.id === id)[field]

				// Bugfix: only submit ID's that are present in the current table, to prevent deleting outside IDs from tables
				// with different env data.
				const rows = leftItems._map('id')
				const checked = data[id]
				const unchecked = _.difference(rows, checked)
				const newChecked = _.difference(checked, stored) // filter newly added items only to prevent uniqueness validations

				const newObj = {[field]: API.getAssocDiff(newChecked, unchecked), id}
				arr.push(newObj)
			})
		if (arr.length === 0) return utils.errNotif(t('oops'), t('common.no_changes_made'))
		const res = await onSubmit({data, changed: arr})
		if (isOk(res)) afterSubmit()
	}

	ref.current = {submit}

	if (filterTopItems && utils.objPresent(filter)) {
		topItems = crazyFilter(topItems, filter)
	}

	return (
		<div className="grid-select-container">
			<div className="grid-select-header">{topItems.map((item, idx) => renderLabel(item, idx))}</div>

			<div className="row">
				{/* The empty object is to render an exrta listPicker at the beginning that shows just the labels */}
				{[{}, ...topItems].map((item, idx) => {
					return (
						<div className="grid-select-column">
							<ListPicker
								raw
								groupLabelVisible
								showClearAll={false}
								selectAllLabelVisible={false}
								showToggleSelectAll={false}
								noScroll
								{...listOpts}
								key={item.id}
								filter={filter}
								readOnly={idx === 0}
								showCheckbox={idx !== 0}
								labelVisible={idx === 0}
								onChange={val => setData({[item.id]: val})}
								value={data[item.id] || item[field]}
								labelField="name"
								showSearch={false}
								items={leftItems}
								{...rest}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
})

export default GridSelect

//
