import {t} from 'initializers/i18n'
import {Text, EitjeButton, ListPicker, Layout} from 'common/components'
import './wage_period_picker.less'
import {useWagePeriods} from '.'

export const WagePeriodPicker = ({pageContext}) => {
	const {period: activeWagePeriodType, setRawDates} = pageContext()
	const {periods, period = {}} = useWagePeriods({pageContext})
	const orderedPeriods = periods.reverse()
	const periodIdx = orderedPeriods.indexOf(period)

	const _onChange = periodId => {
		const _period = periods.find(p => p.id == periodId)
		const {start, eind: end} = _period
		setRawDates(start, end, activeWagePeriodType)
	}

	const previousPeriod = () => {
		const period = periods[periodIdx + 1]
		if (period) return _onChange(period.id)
	}

	const nextPeriod = () => {
		const period = periods[periodIdx - 1]
		if (period) return _onChange(period.id)
	}

	const isFirstPeriod = periodIdx == periods.length - 1
	const isLastPeriod = periodIdx == 0

	return (
		<Layout gap={0} className="wage-period-picker">
			{!isFirstPeriod && <EitjeButton height="small" iconLeft="arrow-small-left" padding={4} onClick={previousPeriod} />}
			<ListPicker
				raw
				dropdown
				single
				onChange={_onChange}
				noSort
				small
				value={period.id}
				items={orderedPeriods}
				Trigger={Trigger}
				triggerVisible
			/>
			{!isLastPeriod && <EitjeButton height="small" iconLeft="arrow-small-right" padding={4} onClick={nextPeriod} />}
		</Layout>
	)
}

const Trigger = ({selectedItems, small}) => {
	const item = selectedItems[0]
	const text = `${t('common.period')} ${item.period}`

	return (
		<Layout hasHover colorSet gap={8} padding="4 8" type="card" height={32}>
			<Text children={text} />
		</Layout>
	)
}
