import {WeekTableCell} from 'common/components'
import {t} from 'initializers/i18n'
import {usePerMemberContext} from 'cores/planning/pages/index/per_member/per_member_provider'
import utils from '@eitje/web_utils'
import {getPlannedHours} from 'cores/planning/helpers'
import {PanelDropdown} from './panel_dropdown'

export const Panel = ({...rest}) => {
	const {openShifts} = usePerMemberContext()
	const {total} = getPlannedHours(openShifts)

	const items = [
		{type: 'subtitle', value: openShifts.length, t: 'common.planned_shifts'},
		{type: 'subtitle', value: utils.minToTimeString(total), t: 'common.planned_hours'},
	]

	return (
		<WeekTableCell
			cellType="tile"
			columnType="panel"
			displayName={t('common.open_shifts')}
			items={items}
			Wrapper={PanelDropdown}
			contentLayoutProps={{horizontal: 'start'}}
			{...rest}
		/>
	)
}
