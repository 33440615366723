import {t} from 'initializers/i18n'
import React, {useEffect, useState} from 'react'
import {ListPicker} from 'common/components'
import '../styles/quiz.less'

const QuizItem = ({idx, setVal, item, setMayPass}) => {
	const [answer, setAnswer] = useState(null)
	const {answers = []} = item

	const mappedAnswers = answers.map((a, idx) => ({name: a, id: idx}))

	const _setAnswer = answer => {
		setAnswer(answer)
		setVal(answer)
		setMayPass(true)
	}

	useEffect(() => {
		setAnswer(null)
	}, [idx])

	return (
		<div className="column-with-justify-center full-height">
			<div className="row-with-justify-center gap-xl">
				<div style={{width: '505px'}}>
					<p className="eitje-text-secondary">
						{t('question')} {idx + 1}
					</p>
					<h2>{item.question}</h2>
				</div>
				<div className="quiz-answer-list" style={{width: '505px'}}>
					<ListPicker showCheckbox raw single bold value={answer} onChange={_setAnswer} items={mappedAnswers} />
				</div>
			</div>
		</div>
	)
}

export default QuizItem
