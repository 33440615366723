import {API, find} from '@eitje/easy_api'
import {
	LegacyDropdownPicker as DropdownPicker,
	LegacyInput as Input,
	LegacyDatePicker as DatePicker,
	LegacySwitch as Switch,
} from '@eitje/form-fields-web'
import {getTraining} from 'actions/training'
import InputNumber from 'components/form/input_number'
import Modal from 'components/general/modal'
import {findTopicSchedState, topicSchedFieldLabels, topicSchedfields} from 'helpers/topics'
import useParams from 'hooks/use_params'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

const SetupInput = ({field, ...rest}) => {
	switch (field) {
		case 'days_to_start':
			return (
				<InputNumber
					{...rest}
					extraLabel={<p className="margin-bottom-xss">{t('days_to_start_secondary')}</p>}
					label={t('days_to_start_label')}
					min={0}
					validate={(data, field) => false}
					required
					placeholder={1}
				/>
			)
		case 'valid_untill':
			return (
				<DatePicker
					{...rest}
					bordered={false}
					label={t('valid_untill_label')}
					required
					extraLabel={<p>{t('valid_untill_secondary')}</p>}
					pastDisabled
				/>
			)
		case 'always_trainable':
			return <Switch {...rest} hidden labelVisible={false} containerStyle={{}} />
	}
	return null
}

const onTrainingSetupSubmit = async (data, id) => {
	const {days_to_start} = data
	if (days_to_start && days_to_start > 0) {
		data.days_to_start = days_to_start - 1
	}
	return API.update('topics', {id, ...data})
}

const makeTrainingSetupInitValues = ({days_to_start, ...rest} = {}) => {
	const _topic = rest
	if (days_to_start) {
		_topic.days_to_start = days_to_start + 1
	}
	return _topic
}

const TrainingSetup = () => {
	const {id} = useParams()
	const topic = useSelector(state => find(state, 'topics', id))
	const form = useRef(null)
	const [field, setField] = useState(findTopicSchedState(topic, topicSchedfields))
	const displayForm = !['no_training', 'always_trainable'].includes(field)

	useEffect(() => {
		if (form?.current && field) {
			if (field === 'always_trainable') {
				form.current.setValues({always_trainable: true})
				return
			}
			if (field === 'no_training') {
				form.current.setValues({always_trainable: false, valid_untill: null, days_to_start: null})
				return
			}
			form.current.setValues({always_trainable: false})
		}
	}, [field])

	const handleAfterSubmit = async () => {
		await getTraining()
		return history.replace(`/topics/${id}`)
	}

	return (
		<Modal wrapClassName="training-modal" width={570} onOk={() => form.current && form.current.submit()} title={t('trainingSetup')}>
			<div className="base-modal-section">
				<p className="eitje-text-secondary">{t('trainingSetupDescrip')}</p>
			</div>

			<Form
				afterSubmit={handleAfterSubmit}
				initialValues={makeTrainingSetupInitValues(topic)}
				ref={form}
				submitInitialValues={field === 'no_training' || field == 'always_trainable'}
				onSubmit={data => onTrainingSetupSubmit(data, id)}
			>
				<DropdownPicker value={field} bordered={false} label={t('activateTraining')} items={topicSchedFieldLabels} onChange={setField} />

				{displayForm && <SetupInput fieldWrapper required field={field} />}
			</Form>
		</Modal>
	)
}

export default TrainingSetup
