import {useDrop as _useDrop} from 'react-dnd'

export const useDrop = props => {
	const [properties, dropRef] = _useDrop({
		...props,
		drop: props.onDrop,
		collect: monitor => ({
			isOver: !!monitor.isOver(), // Don't remove double negation: required in some cases to prevent functionality from breaking
			canDrop: !!monitor.canDrop(), // Don't remove double negation: required in some cases to prevent functionality from breaking
			dragItem: monitor.getItem(),
		}),
	})

	const isDragging = !!properties.dragItem

	return {...properties, isDragging, dropRef}
}
