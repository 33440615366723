import ActiveRecord from 'models/active_record/base'

class ExtRevenueGroup extends ActiveRecord {
	static tableName = 'integrationExtRevenueGroups'
	static defaultJoins = ['omzetGroepen', 'environments']

	jointName() {
		return `${this.sales_area_name} - ${this.name}`
	}
}

export default ExtRevenueGroup
