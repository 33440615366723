import useSelector from 'hooks/use_selector'
import {API, useWhere, useFind, useAll, backend} from '@eitje/easy_api'
import {store} from 'index'

export const getFileCachers = async cacheNames => {
	const currentFiles = store.getState()?.fileCacher || {}
	const params = cacheNames.map(c => ({name: c, timestamp: currentFiles[c]?.timestamp}))
	const res = await backend.post('file_cachers/retrieve_multi_cache', {cachers: params, doNotLoad: true})
	if (res.ok) {
		const data = res.data?.data?.filter(d => d.file)
		if (data.length > 0) saveFileCaches(data)
	}
}

export const saveFileCaches = files => {
	store.dispatch({type: 'SET_FILE_CACHES', payload: files})
}

export const saveFileCache = file => {
	if (!file) return
	store.dispatch({type: 'SET_FILE_CACHE', payload: file})
}

export const useCachedFile = fileName => {
	const currentFile = useSelector(state => state.fileCacher[fileName])
	if (!currentFile) return {}
	return currentFile.file
}
