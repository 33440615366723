import {WeekTableRow, EitjeLazyLoad} from 'common/components'
import {usePlanningContext, useImproductiveShifts} from 'cores/planning'
import {Panel} from './panel'
import {Column} from './column'
import {useLocalValue, ShowLocalContent} from 'hooks'
import {useHasRole} from 'hooks'
export const NAMESPACE = 'planning.improductive'

const _ImproductiveRow = () => {
	const {dateArray} = usePlanningContext()
	const height = useEstimateHeight()

	return (
		<EitjeLazyLoad height={height}>
			<WeekTableRow className="improductive-row" Panel={Panel} Day={Column} dateArray={dateArray} minHeight={40} marginTop={40} />
		</EitjeLazyLoad>
	)
}

export const ImproductiveRow = ({envId}) => {
	const isManager = useHasRole('manager', envId)
	if (!isManager) return null
	return <ShowLocalContent value="planningShowImproductive" Component={_ImproductiveRow} />
}

function useEstimateHeight() {
	// What's the highest amount of items in any column
	const weekItems = useImproductiveShifts() // may be reg shifts, leave requests or sick periods
	const {dateArray} = usePlanningContext()
	const dayShifts = dateArray.map(date => weekItems.filter(item => item.isOnDate(date)))
	const maxShifts = dayShifts.max('length')

	// What's the approximate height of an item
	let cellItems = 2 // always present
	cellItems += useLocalValue('improductiveShowHours') ? 1 : 0
	cellItems += useLocalValue('improductiveShowReason') ? 1 : 0
	const cellItemHeight = 16
	const cellPadding = 8
	const columnGap = 4
	const shiftHeight = cellItems * cellItemHeight + (cellPadding + columnGap)

	return maxShifts * shiftHeight
}
