import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import useShared from 'hooks/use_shared'

export const useBillingInfo = () => {
	const {envId} = useShared()
	const billingInfo = useFind('billingInfos', {environment_ids: [envId]})

	return billingInfo
}

export default useBillingInfo
