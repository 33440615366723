import React, {useContext, useEffect, useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import useToggleState from 'hooks/use_toggle_state'
import AvLeaveForm from './availability_leave_form'
import {useUserContext} from 'contexts/user'
import {useMergeState} from '@eitje/react-hooks'
import './styles/availability_form_provider.less'

const AvFormContext = React.createContext({})
const {Provider} = AvFormContext
const AvailabilityFormProvider = ({children, hiddenModes = [], kind = 'availabilityShifts', ...rest}) => {
	const {userId} = useUserContext()
	const [formItem, setFormItem, setRawFormItem] = useMergeState()
	const [formMode, setFormMode] = useState(null)
	const [dates, setDates, setRaw] = useToggleState([])
	const hideForm = () => setRaw([])
	const [initialDate, setInitialDate] = useState(null)

	useEffect(() => {
		if (dates.length == 0) {
			setRawFormItem()
			setInitialDate(null)
		}
	}, [dates.length])

	const setDate = date => {
		if (dates.length == 0) setInitialDate(date)
		setDates(date)
	}

	const popover = <PopoutCard open placement="bottom" bodyPadding={0} body={<AvLeaveForm {...rest} />} />
	const context = {
		selectedDates: dates,
		setDate: setDate,
		hideForm,
		weekKind: kind == 'availabilityShifts' ? 'userWeeks' : 'userTemplateWeeks',
		setFormItem,
		setFormMode,
		formItem: mapFormItem(formItem, formMode),
		setRaw,
		userId,
		kind,
		initialDate,
		hiddenModes,
		showForm: date => date && initialDate == date && popover,
		isActive: date => dates.includes(date),
	}
	return <Provider value={context}>{children}</Provider>
}

export default AvailabilityFormProvider

const mapFormItem = (formItem, mode) => {
	return {...formItem, isMultiple: mode == 'partly_available', status: mode}
}

export const useAvFormContext = () => {
	return useContext(AvFormContext)
}
