import {Popconfirm as AntPopconfirm} from 'antd'
import useShared from 'hooks/use_shared'
import {DOMAIN} from 'initializers/api'
import {t} from 'initializers/i18n'

export const Popconfirm = props => <AntPopconfirm title={t('areYouSure')} {...props} okType="primary" overlayClassName="confirm-popover" />

export const useSyncAgendaLink = () => {
	const {me} = useShared()
	const agendaLink = `webcal://${DOMAIN}/calendars/${me.id}/awrifasnchrndskaolpqeosdfucysd.ics`
	return agendaLink
}

export const SyncAgendaBtn = ({agendaStyle = {}}) => {
	const link = useSyncAgendaLink()
	return (
		<a style={{width: '100%'}} target="_blank" href={link}>
			<div className="row-with-border-and-padding-xs hover-effect" style={{...agendaStyle}}>
				<img width={22} height={22} className="v-mar-xs" src={`/images/web/icons/sync.png`} />
				<p className="bold font-size-sm margin-left-xss">{t('syncToCal')}</p>
			</div>
		</a>
	)
}
