import {useMemo} from 'react'

import {usePerMemberContext, useUnfilteredShifts} from 'cores/time_registration/index'
import {filterItems as searchItems} from 'hooks/use_search'
import {useSearchContext} from 'contexts/search'

const regOpts = {
	joins: ['planningShifts', 'users', {teams: 'environments'}, 'checkIns'],
}

const planOpts = {
	joins: ['users', {teams: 'environments'}],
}

const checkInOpts = {
	joins: ['users', {teams: 'environments'}, 'planningShifts'],
}

export const useBasePerMemberShifts = () => {
	const {dateRange} = usePerMemberContext()
	const {search} = useSearchContext()
	let {regShifts, planShifts, checkIns} = useUnfilteredShifts({regOpts, planOpts, checkInOpts, dateRange})

	const filterShifts = items => {
		return searchItems(items, search, {searchField: 'user.full_name'})
	}

	planShifts = useMemo(() => filterShifts(planShifts), [search, planShifts, checkIns])
	regShifts = useMemo(() => filterShifts(regShifts), [regShifts, search, checkIns])
	checkIns = useMemo(() => filterShifts(checkIns), [checkIns, search])

	return {regShifts, planShifts, checkIns}
}

export const usePerMemberShifts = () => {
	const {regShifts, planShifts, checkIns} = useBasePerMemberShifts()

	const accordedPlanShiftIds = useMemo(() => regShifts.map(r => r.plan_shift_id).filter(Boolean), [regShifts])

	// PR comment: deze planShifts moeten we dan eig ook omschrijven naar useUnfilteredShifts tegen duplicatie van BL..
	// vind je niet? mja kan ook binnenkort op zich
	const unwrittenPlanShifts = useMemo(
		() => planShifts.filter(p => !p.reg_shift_id && !accordedPlanShiftIds.includes(p.id)),
		[planShifts, regShifts],
	)
	const shifts = useMemo(() => {
		return [...regShifts, ...unwrittenPlanShifts, ...checkIns]
	}, [regShifts, planShifts, checkIns])

	return shifts
}

export default usePerMemberShifts
