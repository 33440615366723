import {t as baseT} from 'initializers/i18n'
export * from './pages/index'
export * from './hooks/index'
export * from './components/index'
export * from './providers/index'
export * from './constants'
export * from './helpers'
const t = (text, opts = {}) => baseT(`leave.${text}`, opts)

export {t}
