import {ModalLink} from 'components/routing'
import Form from 'initializers/form'
import {LegacyDatePicker as DatePicker} from '@eitje/form-fields-web'
import {EmptyMsg} from 'components/ui'
import utils from '@eitje/web_utils'
import {API} from '@eitje/easy_api'
import {t, mdT} from 'initializers/i18n'
import {useShared} from 'hooks/use_shared'
import './styles/components.less'
import {OptionsButton} from 'common/components'

export const EmptyCont = ({id}) => (
	<ModalLink to={`/forms/new_contract/${id}`}>
		<div className="add-new-contract-row">
			<div>
				<p className="eitje-text-secondary">{t('newContract')}</p>
				<p>{t('selectContractType')}</p>
			</div>

			<img width={16} src="/images/dropDown1.png" />
		</div>
	</ModalLink>
)

export const DateStartedForm = ({user, popover}) => (
	<Form
		styleKind="general"
		initialValues={user}
		afterSubmit={() => popover?.hide()}
		onSubmit={data => API.update('users', {...data, id: user.id})}
	>
		<div className="veedree" fieldWrapper>
			<DatePicker bordered={false} submitStrategy="change" field="date_started" />
		</div>
	</Form>
)

export const ContractAnalytics = ({date, user}) => {
	const {hasManagerRole} = useShared()
	if (!date && !hasManagerRole) return null

	if (!date)
		return (
			<div className="column-with-border-and-padding-xl gap-sm" style={{minHeight: 200}}>
				<div className="full-width">
					<h3>{t('employment')}</h3>
					<p className="eitje-text-secondary lowercase">{t('details')}</p>
				</div>
				<EmptyMsg className="eitje-text-secondary text-italic" kind="date_started" />
				<div className="full-width">
					<DateStartedForm user={user} />
				</div>
			</div>
		)

	const nextDate = utils.nextDate(date)
	const daysTillNext = utils.daysUntill(nextDate)
	const yearsPassed = utils.yearsPassed(date, nextDate)
	return (
		<div className="column-with-border-and-padding-xl">
			<div className="row-with-justify-between margin-bottom-xl">
				<div>
					<div className="eitje-heading-xl ">{t('employment')}</div>
					<p className="eitje-text-secondary">{t('details')}</p>
				</div>

				{hasManagerRole && <OptionsButton body={<DateStartedForm user={user} />} />}
			</div>

			<div className="emphasized-text-block full-width">
				<p>{mdT('employedSince', {date})} </p>
				<p>{mdT('amtDaysEmployed', {date})} </p>
				<p className="noCapital"> {mdT('daysTillAnni', {days: daysTillNext})} </p>
				<p className="noCapital"> {mdT('contractAnni', {years: yearsPassed})} </p>
			</div>
		</div>
	)
}
