import {WeekTableColumn, AddButton, useFilterFooterContext} from 'common/components'
import {usePerMemberContext} from 'cores/planning/pages/index/per_member/per_member_provider'
import {useGetPlanningShiftUrlWithEnv} from 'cores/planning/helpers'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useRoleMessage} from 'hooks'
import {PlanningShiftCell} from 'cores/planning/components/user_planning_shifts_row/planning_shift_cell'
import {usePlanningShiftDrop} from 'cores/planning/components/user_planning_shifts_row/column'

export const Column = ({date, ...rest}) => {
	const {openShifts} = usePerMemberContext()
	const {teams} = useFilterFooterContext()
	const shifts = openShifts.where({date})
	const {multiEdit} = useEnvsContext()
	const {isOver, canDrop, dropRef} = usePlanningShiftDrop({newDate: date, teams})
	const hoverChildren = !multiEdit && <CreateShiftButton date={date} />

	return (
		<WeekTableColumn ref={dropRef} dragProps={{isOver, canDrop}} hoverChildren={hoverChildren} {...rest}>
			{shifts.map(shift => (
				<PlanningShiftCell shift={shift} />
			))}
		</WeekTableColumn>
	)
}

const CreateShiftButton = ({date}) => {
	const createShiftUrl = useGetPlanningShiftUrlWithEnv({date})
	const disabled = useRoleMessage('manager')

	return (
		<AddButton
			width="full"
			modalLink={createShiftUrl}
			iconLeft="plus"
			colorSet="color-bordered-color-content"
			t="records.planning_shift.name"
			minHeight="100%"
			disabled={disabled}
		/>
	)
}
