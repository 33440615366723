import {useWhere} from '@eitje/easy_api'
import ActiveRecord from 'models/active_record/base'
import I18n, {t} from 'initializers/i18n'
import {getTableName} from 'helpers/index'
import pluralize from 'pluralize'
import {makeSubscriberString} from '../helpers'
import {date} from 'initializers/date'
import utils from '@eitje/web_utils'
import moment from 'moment'

const ordinal = () => moment.localeData(I18n.language).ordinal
const monthday = d => `${t('common.the')}  ${ordinal()(d)} ${t('common.day')}`
const weekday = d => t(utils.weekdays[d])
const yearday = yearday => date().dayOfYear(yearday).format('DD MMM')

export class ExportSubscription extends ActiveRecord {
	static tableName = 'export2Subscriptions'

	cronString() {
		const {mail_schedule} = this
		const {period, time, day_of_year} = mail_schedule

		const everyKey = period == 'year' ? 'elk' : 'every'

		const on = period != 'day' ? t('common.op') : ''
		const cronDay = period != 'day' ? this.cronDay() : ''

		return `${t(`common.${everyKey}`)} ${t(`common.${period}`)} ${on} ${cronDay} ${t('common.at')} ${time} `
	}

	toggle(key) {
		const val = this[key]
		return this.update({[key]: !val})
	}

	recipientCount() {
		const {emails, user_ids} = this
		return emails.length + user_ids.length
	}

	cronDay() {
		const {mail_schedule} = this
		const {period, time, day_of_year, day_of_week, day_of_period, day_of_quarter, day_of_month} = mail_schedule
		const isPeriod = period == 'flex_wage_period' || period == 'hours_wage_period'
		if (period == 'week') return weekday(day_of_week)
		if (period == 'month' || isPeriod) return monthday(day_of_month || day_of_period)
		if (period == 'quarter') return monthday(day_of_quarter)
		if (period == 'year') return yearday(day_of_year)
	}

	exportNow() {
		return this.resourceReq('export_and_mail_now', {method: 'post'})
	}
}

export default ExportSubscription
