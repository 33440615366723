import utils from '@eitje/web_utils'
import {ColorCircle} from 'components/ui'

export const DropdownWithCounter = props => {
	const {count, title, small} = props
	const classNames = utils.makeCns('dropdown-toggle-count', small && 'dropdown-toggle-count-small')
	const titleCn = utils.makeCns('dropdown-toggle-title', 'bold')
	return (
		<div {...props} className={classNames}>
			<div className={titleCn}>{title}</div>
			{!small && (
				<div className="dropdown-toggle-icon-container">
					<img src="/images/web/icons/dropdown_icon.png" width={14} height={14} />
					{!!count && (
						<div className="dropdown-toggle-icon-count">
							<ColorCircle text={count} />
						</div>
					)}
				</div>
			)}
		</div>
	)
}
