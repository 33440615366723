import {WeekTableCell, EitjeAvatar} from 'common/components'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {NAMESPACE} from '.'
import {ModalLink} from 'components/routing'
import {useHasRole} from 'hooks'

export const ABSENCE_TYPES = {
	leave: {baseColor: 'purple', transKey: 'leave.short_name'},
	special_leave: {baseColor: 'pink', transKey: 'common.special_leave'},
	sick: {baseColor: 'pink', transKey: 'ziek'},
}

export const Cell = ({item}) => {
	const isShift = item.typ
	const isLeaveRequest = item.tableName === 'leaveRequests'
	const isSickPeriod = item.tableName == 'sickPeriods'
	const absenceType = isLeaveRequest ? 'leave' : isSickPeriod ? 'sick' : item.improductiveType().toLowerCase()
	const {baseColor, transKey} = ABSENCE_TYPES[absenceType]
	const leftContentProps = {user: item.user, radius: 28}
	const requiredRole = isShift ? 'uren_accorderen' : 'verlof'
	const hasRole = useHasRole(requiredRole, item.environment_id)
	const modalLink = isShift ? `/time_registration/${item.id}` : `/leave/${item.id}/approve`

	// Cell items
	const username = {type: 'title', text: item.user.full_name}
	const improductivityKind = {type: 'subtitle', text: t(transKey), colorCircle: `medium${utils.capitalize(baseColor)}`}

	// Determine wrapper
	let Component = WeekTableCell
	if (isShift) Component = TimeRegistrationShiftCell
	if (isLeaveRequest) Component = LeaveRequestCell
	if (isSickPeriod) Component = SickPeriodCell

	return (
		<Component
			cellType="card"
			colorSet="grey-bordered"
			items={[username, improductivityKind]}
			Wrapper={hasRole && ModalLink}
			wrapperProps={hasRole && {to: modalLink}}
			baseColor={baseColor}
			hasRole={hasRole}
			item={item}
			LeftContent={EitjeAvatar}
			leftContentProps={leftContentProps}
			leftContentLocalValue="improductiveShowAvatar"
		/>
	)
}

const TimeRegistrationShiftCell = ({item, items, hasRole, ...rest}) => {
	const absenceType = item.improductiveType()
	const writtenHoursKey = absenceType === 'leave' ? 'leave_hours_taken' : 'sick_hours_written'
	const writtenHoursLabel = t(`${NAMESPACE}.${writtenHoursKey}`)
	const writtenHours = hasRole && {
		type: 'item',
		text: `${item.totalMinsString()} ${writtenHoursLabel}`,
		localValue: 'improductiveShowHours',
	}

	// The back-end automatically adds "Verlof opgenomen" as reason, which is always the same. Ideally we would
	// show the actual leave request reason.
	const showReason = absenceType === 'leave' && item.remarks && item.remarks !== 'Verlof opgenomen' && hasRole
	const leaveReason = showReason && {
		type: 'item',
		text: `${t('records.leave_request.fields.reden')}: ${item.remarks}`,
		localValue: 'improductiveShowReason',
	}
	return <WeekTableCell items={[...items, writtenHours, leaveReason]} {...rest} />
}

const LeaveRequestCell = ({item, items, hasRole, ...rest}) => {
	const leaveReason = hasRole && {
		type: 'item',
		text: `${t('records.leave_request.fields.reden')}: ${item.reden}`,
		localValue: 'improductiveShowReason',
	}
	return <WeekTableCell items={[...items, leaveReason]} {...rest} />
}

const SickPeriodCell = props => {
	const unsetClick = {Wrapper: null, wrapperProps: null}
	return <WeekTableCell {...props} {...unsetClick} />
}
