import {BaseConnection, ExtEnvironment, Environment, Allowance} from 'models/index'
import {useEnvsSetting} from 'hooks'
import utils from '@eitje/web_utils'

export const usePushDone = envIds => {
	const envs = Environment.where(envIds)
	const allowances = Allowance.where({environment_id: envs._map('id')})
	const mealSettingsPerEnv = useEnvsSetting('maaltijd', 'maaltijd_selected', envs._map('id'))
	let obj = {}
	envs.forEach(env => {
		const hasMeals = utils.exists(mealSettingsPerEnv[env.id])
		const envAllowances = allowances.where({environment_id: env.id})
		const mealsDone = !hasMeals || env.meal_code
		obj[env.id] = mealsDone && envAllowances._every('loon_code')
	})

	return obj
}
