import {NewModalForm, ModalWithNewForm} from 'components/form/new_modal_form'
import {ListPicker, DocsButton} from 'common/components'
import {ContextForm} from '@eitje/form'
import {Input, FormRow} from '@eitje/form-fields-web'
import {Team} from 'models'
import {useShared, useQueryParams, useRoleOrgEnvs} from 'hooks'
import {navigateModal} from 'actions/routing'
import {finishOnboardingStep} from 'cores/onboarding'

export const CreateTeam = ({}) => {
	const {org} = useShared()
	const {environment_id} = useQueryParams()
	const managerEnvs = useRoleOrgEnvs('manager')
	const multiEnvOptions = managerEnvs.length > 1
	let initialValues = {environment_id}
	const modalProps = {buttons: [<DocsButton t="common.explanation" docsId={8374598} />]}

	if (environment_id) {
		// always try to use query params to immediately prefill the correct env
		initialValues.environment_id = environment_id
		// if no query params are present, still try to induce the correct env
	} else if (managerEnvs.length === 1) {
		initialValues.environment_id = managerEnvs[0].id
	}

	const onSubmit = data => {
		const environment_id = multiEnvOptions ? data.environment_id : managerEnvs[0].id
		return Team.create({...data, environment_id})
	}

	const afterSubmit = ({resData}) => {
		const {id, environment_id} = resData.item
		const teamUrl = `/environments/${environment_id}/teams/${id}`
		navigateModal(teamUrl, {keepBackground: true})
		finishOnboardingStep({step: 'create_team', org})
	}

	return (
		<ModalWithNewForm name="create_team" width={400} modalProps={modalProps}>
			<NewModalForm onSubmit={onSubmit} initialValues={initialValues} afterSubmit={afterSubmit} oneFieldPerFormRow>
				<Input field="naam" required />
				{multiEnvOptions && <ListPicker field="environment_id" single dropdown items={managerEnvs} required labelField="naam" />}
			</NewModalForm>
		</ModalWithNewForm>
	)
}
