import utils from '@eitje/web_utils'
import useList from 'components/general/list'
import useSort from 'hooks/use_sort'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import 'styles/app/components/data_display.less'

const Decider = props => {
	const {CustomRow, hasCustomRow, item} = props
	if (CustomRow && hasCustomRow(item)) {
		return <CustomRow {...props} />
	} else {
		return <DataRow {...props} />
	}
}

export const DataDisplay = props => {
	const {
		headers,
		hideHeaders = [],
		placeholderProps,
		preChildren,
		items,
		name,
		renderCell,
		defSortField,
		sortEnabled = !!defSortField,
		onRowClick = _.noop,
		hasCustomRow,
		CustomRow,
		...rest
	} = props

	const itemProps = item => ({
		preChildren,
		name,
		renderCell,
		onRowClick: () => onRowClick(item),
		headers,
	})

	let {sortedItems, changeSort, asc, sortField} = useSort({...props, fields: headers.map(h => h.label || h)})
	const _items = sortEnabled ? sortedItems : items

	const {list} = useList({
		...rest,
		items: _items,
		sortEnabled,
		ListItem: props => <Decider {...props} hasCustomRow={hasCustomRow} CustomRow={CustomRow} />, // props for the custom row aren't being passed to list item
		itemProps,
		placeholderProps,
	})

	return (
		<div className="data-display-container">
			<DataHeader
				sortable={sortEnabled}
				up={asc}
				sortField={sortField}
				name={name}
				changeSort={sortEnabled ? changeSort : _.noop}
				headers={headers}
				hideHeaders={hideHeaders}
			/>
			<div className="data-display-row-container">{list}</div>
		</div>
	)
}

const DataRow = ({item, preChildren, renderCell, name, onRowClick, headers}) => {
	const defaultWidth = 100 / headers.length
	return (
		<div className="data-display-row" onClick={onRowClick}>
			{utils.funcOrVal(preChildren, item)}
			{headers.map(h => (
				<p className="data-display-cell" style={{width: `${h?.width || defaultWidth}%`}}>
					{' '}
					{renderCell(item, h?.label || h, name)}{' '}
				</p>
			))}
		</div>
	)
}

const DataHeader = ({headers, sortField, name, changeSort, ...rest}) => {
	const width = 100 / headers.length
	return (
		<div className="data-display-header-container">
			{headers.map(h => (
				<Header sortField={sortField} name={name} item={h} width={h.width || width} changeSort={changeSort} {...rest} />
			))}
		</div>
	)
}
const bUrl = `/images/web/icons/`
const Header = ({item, sortField, up, sortable, width, name, hideHeaders, changeSort}) => {
	const label = item.label || item
	const active = sortField == label
	let imgUrl = `noOrder.png`

	if (active) {
		imgUrl = up ? `ascending.png` : `descending.png`
	}

	const isHidden = hideHeaders.includes(label)

	return (
		<div className="data-display-header" style={{width: `${width}%`}}>
			<p onClick={() => changeSort(label)} style={{textAlign: item.centered && 'center'}}>
				{!isHidden && renderHeader(label, name)}
			</p>
			{sortable && <img style={{height: 12}} src={`${bUrl}/${imgUrl}`} />}
		</div>
	)
}

const renderHeader = (h, key) => {
	if (h.includes('.')) return t(`dataDisplay.${h}`, t(`records.${h}`))
	return t(`dataDisplay.${key}.${h}`, t(`records.${key}.${h}`))
}

export default DataDisplay
