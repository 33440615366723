import {EitjeModal} from 'components/common'
import Form from './form'
import Header from './header'

export const Meals = () => {
	return (
		<EitjeModal name="meal_allowance" RightHeader={Header}>
			<Form />
		</EitjeModal>
	)
}
