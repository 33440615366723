import {Switch} from '@eitje/form-fields-web'
import {EditButton} from 'common/components'
import {FormRow} from 'components/ui'
import {ModalLinkSkeleton} from 'lib/form'

export const Page = () => {
	return (
		<div className="planning-settings-form eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<ModalLinkSkeleton field="shift_types" icon={null}>
					<EditButton modalLink="/shift_types" height="small" />
				</ModalLinkSkeleton>
			</FormRow>
			<FormRow>
				<Switch field="auto_publish_open_pickup" />
			</FormRow>
			<FormRow>
				<Switch field="auto_publish_trade" />
			</FormRow>
		</div>
	)
}

export default Page
