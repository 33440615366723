const initialState = {}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'RESET_FILE_CACHES':
			return initialState
		case 'SET_FILE_CACHES':
			let obj = {}
			action.payload.forEach(f => (obj[f.name] = f))
			return {
				...state,
				...obj,
			}

		case 'SET_FILE_CACHE':
			const {name} = action.payload
			return {
				...state,
			}
		default:
			return state
	}
}
