import utils from '@eitje/web_utils'
import {useLocalValue} from 'hooks'
import {ShiftType, SkillSet} from 'models'
import {makeCnVariants} from 'helpers'
import {useSimpleContext} from 'contexts/simple'
import './styles/index.less'
import {Breaktime, ShiftTitle} from 'common/components/shift_card/components'
import {EitjeButton} from 'common/components'

export const ShiftName = ({title, emoji}) => {
	const {name, canSeeTrade} = useSimpleContext()
	const fullName = emoji ? `${emoji} ${name}` : name
	const _title = title || fullName
	const classNames = makeCnVariants('shift-name', canSeeTrade && 'with-trade')
	return <h5 className={classNames}>{_title}</h5>
}

export const FromTill = () => {
	const {item} = useSimpleContext()
	return <ShiftTitle title={item.durationString()} titleAddition={Breaktime} />
}

export const NoteTypeRow = () => {
	const {
		item: {remarks, shift_type_id},
	} = useSimpleContext()
	const showRemarks = useLocalValue('planningShowRemarks')
	const showType = useLocalValue('planningShowShiftType')
	const shiftType = ShiftType.find(shift_type_id)

	const _showRemarks = showRemarks && utils.exists(remarks)
	const _showShiftType = showType && shiftType.id
	if (!_showRemarks && !_showShiftType) return null
	return (
		<div className="shift-note-type-row">
			{_showRemarks && <Note note={remarks} />}
			{_showShiftType && (
				<h5 className="shift-type">
					{shiftType.name} {shiftType.emoji}
				</h5>
			)}
		</div>
	)
}

export const Note = ({note}) => {
	return (
		<h5 className="shift-note">
			<img className="shift-note-icon" src="/images/web/icons/planning/shift_remark.png" />
			{note}
		</h5>
	)
}

export const PublishedIcon = () => {
	const {
		item: {published},
	} = useSimpleContext()
	if (published) return null
	return <img style={{height: 12, width: 12}} src="/images/notPublished.png" />
}

export const SkillSetIcon = () => {
	const {
		item: {actualSkillSetId},
	} = useSimpleContext()
	const id = actualSkillSetId()
	const skillSet = SkillSet.find(id)
	const {abbr, color_code} = skillSet
	if (!skillSet.id) return null
	return (
		<div className="shift-skill-set" style={{borderColor: color_code, backgroundColor: color_code}}>
			<h6 className="availability-skill-letter">{abbr}</h6>
		</div>
	)
}

export const ShiftHours = ({hours}) => {
	return (
		<div className="shift-hours">
			<h6>{hours}</h6>
		</div>
	)
}

export const ShiftIcons = () => {
	const {
		item: {published, remarks, shift_type_id, actualSkillSetId},
	} = useSimpleContext()
	const id = actualSkillSetId()
	const showRemarks = useLocalValue('planningShowRemarks')
	const showType = useLocalValue('planningShowShiftType')
	const showSkills = useLocalValue('planningShowSkills')
	const skillSet = SkillSet.find(id)
	const moveIconsUp = (showRemarks && utils.exists(remarks)) || (showType && shift_type_id)
	const classNames = makeCnVariants('shift-icons', moveIconsUp && 'move-up')
	if (!skillSet.id && published) return null
	return (
		<div className={classNames}>
			<PublishedIcon />
			{showSkills && <SkillSetIcon />}
		</div>
	)
}

export const DragHandle = () => <EitjeButton height="small" className="shift-drag-handle" iconLeft="drag-handle" />
