import {API, useWhere, useFind, useAll} from '@eitje/easy_api'

export const useContractMins = id => {
	const workWeeks = useWhere('workWeek', {work_schedule_id: id})
	const totalMins = workWeeks.avg(w => w.totalMins())
	const totalDays = workWeeks.avg(w => w.totalDays())
	return {totalMins, totalDays, workWeeks}
}

export default useContractMins
