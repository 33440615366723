import {HOST} from 'initializers/api'
import 'lib/redactor/article-editor.css'
import ArticleEditor from 'lib/redactor/article-editor.js'
import 'lib/redactor/imageposition.min.js'
import 'lib/redactor/imageresize.min.js'
import 'lib/redactor/inlineformat.min.js'
import 'lib/redactor/reorder.min.js'
import 'lib/redactor/style.min.js'
import 'lib/redactor/list-plugin.js'
import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import '../styles/editor.less'
// import 'styles/arx-frame.css'

const viewOpts = {
	toolbar: false,
	path: false,
	control: false,
}

const Page = ({content, view, subscribe = {}}) => {
	let plugins = ['inlineformat', 'style', 'list-plugin', 'imageposition']
	let condOpts = {}
	const {pathname} = useLocation()
	const isView = view || pathname.includes('view')
	const isTraining = pathname.includes('train') || pathname.includes('test_run')
	const editor = useRef(null)
	const outsideDiv = useRef(null)

	if (isView) {
		plugins.push('reorder')
		condOpts = {...condOpts, ...viewOpts}
	}

	useEffect(() => {
		editor.current = ArticleEditor('#editor', {
			css: '/redactor/',
			content,
			plugins,
			embed: {
				checkbox: true,
			},
			toolbar: {
				hide: ['mobile'],
			},
			image: {
				upload: `${HOST}/files/upload_redactor`,
			},
			subscribe,
			...condOpts,
		})

		return () => {
			editor.current.destroy()
		}
	}, [content])

	useEffect(() => {
		const element = document.getElementsByClassName('arx-bars-container arx-bars-sticky') || []
		if (element.length > 0) {
			if (isView) {
				element[0].style.display = 'none'
				return
			}
			return
		}
	}, [content])

	// This is a 'hack' to fix focus (if you put in the settings, it crashes). This also crashes sadly.. even with a 1 second timeout.
	// Might be some inspiration if they patch the article-editor in the future

	return (
		<div className={!isView && 'editorContainer'}>
			<div className={isTraining && 'noBorder '} style={{position: 'relative'}} ref={outsideDiv}>
				<textarea id="editor" />
			</div>
		</div>
	)
}

export const ArticleView = ({content, id}) => {
	return (
		<div className="vertical-padding-xl" style={{width: 'inherit'}}>
			<div className={'article-viewer'}>
				<div dangerouslySetInnerHTML={{__html: content}} />
			</div>
			{/* <ArticleGlobal />
      <ArticleWrapper>
        <div dangerouslySetInnerHTML={{__html: content}} />
      </ArticleWrapper> */}
		</div>
	)
}

export default Page
