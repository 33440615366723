import {date} from 'initializers/date'
import {RevenueDay} from 'models/index'
import {mdT} from 'initializers/i18n'
import Form from './form/index'
import {Message} from 'common/components'

const submitActual = data => {
	data = data.map(d => ({...d, amt_in_cents: d.amt * 100}))
	return RevenueDay.multiUpdate(data)
}

const Actual = ({dates, revenueDays, ...rest}) => {
	return (
		<div className="revenue-actual">
			<Message margin={16} width="full-minus-margin-16">
				{mdT('revenue.rev_modal.realised_helper')}
			</Message>
			<Form
				dateDisabled={_date => date(_date).startOf('day') > date().startOf('day')}
				showSync
				onSubmit={submitActual}
				initialValue={revenueDays}
				dates={dates}
				{...rest}
			/>
		</div>
	)
}

export default Actual
