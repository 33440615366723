import {t as baseT} from 'initializers/i18n'
import {ModalBackButton} from 'components/ui'
import {DeleteButton, Layout} from 'common/components'
import utils from '@eitje/web_utils'
import {date} from 'initializers/date'
import {buildTimeShift, t} from 'cores/time_registration/index'
import useFormData from 'hooks/use_form_data'
import {FormSubmitButton} from 'components/form'
import {ShiftTime, ShiftCosts, Audits} from 'components/shared/index'
import {useSimpleContext} from 'contexts/simple'
import {useHasRole} from 'hooks/index'
import {history} from 'index'

const Page = () => {
	const data = useFormData()
	const {environment_id, typ} = data
	const {isEdit, shift = {}} = useSimpleContext()
	const {audits = []} = shift
	const relevantAudits = audits.filter(a => utils.exists(a.changes))
	const customValues = {published: t('accorded')}
	const hasContractRole = useHasRole('contracten', environment_id)
	const localFormTimeShift = buildTimeShift(data)
	const relevantForWage = typ != 'verlof'
	return (
		<Layout horizontal="spaceBetween" padding={16} className="time-registration-modal-footer">
			<Layout>
				<ShiftTime shift={localFormTimeShift} />
				{relevantAudits.length > 0 && (
					<Audits
						hideZeros
						getLeadText={getLeadText}
						displayTableName={t('shift')}
						getAuditValue={getAuditValue}
						customValues={customValues}
						audits={relevantAudits}
						auditElements={auditElements}
						placement="top"
					/>
				)}

				{hasContractRole && relevantForWage && <ShiftCosts data={data} placement="top" />}
			</Layout>
			<Layout>
				<ModalBackButton />
				{isEdit && <DeleteButton onDel={() => shift.destroy()} afterDel={history.goBack} />}
				<FormSubmitButton text={baseT('common.approve')} />
			</Layout>
		</Layout>
	)
}

const ExportedCount = () => t('add_modal.audits.exported')

const auditElements = {
	exported_count: ExportedCount,
}

const getLeadText = item => {
	if (item.is_floor) return t('via_floor')
	if (item.action == 'create' && !item.changes.published) return baseT('common.written')
}

const getAuditValue = props => {
	const {keyName, value} = props
	if (keyName == 'typ') return t(`statusses.${value}`, t(`statusses.gewerkte_uren`))
	if (keyName == 'start' || keyName == 'end') return date(value).format('HH:mm')
}

export default Page
