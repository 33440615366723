import useList from 'hooks/use_list'

const BasicListItem = ({item}) => item

const BasicList = ({items, title, ListItem = BasicListItem, hideCount, ...rest}) => {
	const {list} = useList({items, ListItem, ...rest})
	return (
		<div className="basic-list">
			<div className="reader-list-header">
				<h3 className="eitje-heading-xl">{title}</h3>
				{!hideCount && <p className="eitje-text-secondary">({items.length})</p>}
			</div>
			{list}
		</div>
	)
}

export default BasicList
