import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import {Redirect} from 'components/routing'
import useBillingInfo from 'cores/billing/hooks/use_billing_info'
import {getBillingInfo} from 'actions/billing'
import {usePusherUser} from 'hooks/use_pusher'
import {useLocation} from 'react-router-dom'
import useOrgLocked from 'cores/billing/hooks/use_org_locked'
import './index.less'
import {DownloadLink} from 'common/components'

const Signed = () => {
	const billingInfo = useBillingInfo()
	const get = () => {
		getBillingInfo()
		API.index('organisations')
	}
	usePusherUser('billing.signed_contract_done', get)
	const {pdf, is_signing, signed} = billingInfo
	return (
		<Fragment>
			<p className="successfully-signed-notification-text">
				{is_signing ? t('billing.texts.successfully_signed_waiting_on_pdf') : t('billing.texts.successfully_signed_download_pdf')}
			</p>
			{signed && <DownloadLink url={pdf} type="primary" />}
		</Fragment>
	)
}

const Expired = () => {
	const {locked} = useOrgLocked()
	const loc = useLocation()
	if (!locked) return null
	if (!loc.pathname.match('billing') && !loc.pathname.match('mollie_consent')) return <Redirect to="/billing/expired" />
	return null
}

export {Signed, Expired}
