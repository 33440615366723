import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import Moment from 'moment'
import {DateRange, extendMoment} from 'moment-range'

const moment = extendMoment(Moment)

const dateFormats = ['YYYY-MM-DD', 'DD-MM-YYYY']

const timeFormats = [
	'YYYY-MM-DDTHH:mm:ss.SSSZ',
	'YYYY-MM-DDTHH:mm:ss.SSSSZ', // should probably not be required, but is currently needed for Operation.createdAt()
	'YYYY-MM-DDTHH:mm:ss',
	'YYYY-MM-DDTHH:mm',
	'YYYY-MM-DDTHH:mmZ',
	'DD-MM-YYYYTHH:mm:ss.SSSZ',
	'DD-MM-YYYYTHH:mm:ss',
	'DD-MM-YYYYTHH:mm',
	'DD-MM-YYYYTHH:mmZ',
	'HH:mm',
]

const formats = [...dateFormats, ...timeFormats]

export const formatMapping = {
	week: 'GGGG-WW',
	month: 'YYYY-MM',
}

moment.defaultFormat = formats

export function makeRange(start, end) {
	const startMoment = createMoment(start)
	const endMoment = end ? createMoment(end) : moment('2050-01-01')
	// we used to make this an open range, but it seems moment-tz conflicts with moment-range in this aspect.
	// resulting in an invalid end date and thus not responding properly to contains etc
	if (endMoment && endMoment < startMoment) {
		endMoment.add(1, 'day')
	}
	if (start == end && _.isString(start) && !endMoment.isDateTime()) {
		endMoment.endOf('day')
	}
	return moment.range(startMoment, endMoment)
}

export function makeDayRange(start) {
	return makeRange(createMoment(start).startOf('day'), createMoment(start).endOf('day'))
}

export function makeYearRange(year) {
	const start = moment().set({year}).startOf('year')
	const end = start.clone().endOf('year')
	return makeRange(start, end)
}

export function rangeFromStart({start, length = 7, kind = 'day'} = {}) {
	const len = length > 0 ? length - 1 : length
	return moment.rangeFromInterval(kind, len, start ? createMoment(start) : start)
	// we use this strange ternary to ensure a range is returned even if null is passed
	// we discovered this issue way too late, and thus are afraid to change the return type
}

export function dateArrayFromStart({start, length = 7, kind = 'day'} = {}) {
	const range = rangeFromStart({start, length, kind})
	return Array.from(range.by(kind))
}

export function _dateArrayFromStart({start, length = 7, kind = 'day'} = {}) {
	const range = rangeFromStart({start: createMoment(start), length, kind})
	return Array.from(range.by(kind))
}

export function rangeToDateArray(range, kind = 'day') {
	return Array.from(range.by(kind))
}

export function rangeForMonth({date}) {
	return rangeForPeriod({date, period: 'month'})
}

export function rangeForWeek({date}) {
	return rangeForPeriod({date, period: 'week'})
}

export function rangeForYear({date}) {
	return rangeForPeriod({date, period: 'year'})
}

export function rangeForPeriod({date, period}) {
	date = createMoment(date)
	const range = makeRange(date.clone().startOf(period), date.clone().endOf(period))
	return range
}

export function dateArrayForMonth({date, kind = 'day'}) {
	const range = rangeForMonth({date})
	return Array.from(range.by(kind))
}

export function createMoment(date) {
	if (!date) return moment()
	// if (date instanceof moment) return date
	if (isTimeString(date)) {
		return moment(date, 'HH:mm')
	} else {
		// date = date?.replace('Z', '') // fuck timezone
		// we commented this out because it produced issues in the shift modal's audits footer, we don't know why it was turned on exactly.

		return moment(date, formats)
	}
}

const timeStringRegex = /^\d{1,2}:\d{2}$/ // accepts both 09:00 and 9:00, but not 9:0

const isTimeString = date => {
	return date?.match && date.match(timeStringRegex)
}

export const likeDateTime = val => {
	if (!utils.exists(val)) return
	const d = moment(val, timeFormats)
	return d.isDateTime() && d.isValid()
}

export function buildDateTime({date, time}) {
	const format = 'YYYY-MM-DD HH:mm'
	const _date = createMoment(date).format()
	return createMoment(`${_date} ${time}`).format(format) // we want it to be always YYYY-MM-DD when it comes out
}

export function generateYearPeriodKeyValue(periods, is_week) {
	return {[is_week ? 'year_weeks' : 'year_months']: periods}
}

export function getPeriodType(is_week) {
	return is_week ? 'week' : 'month'
}

DateRange.prototype.days = function () {
	const end = this.end.clone().endOf('day')
	const start = this.start.clone().startOf('day')
	const diffInDays = end.diff(start, 'days')
	return diffInDays
}

DateRange.prototype.isValid = function () {
	return this.start.isValid()
}

DateRange.prototype.map = function (fn) {
	return [this.start, this.end].map(d => fn(d))
}

DateRange.prototype.hours = function () {
	const hours = this.end.diff(this.start, 'hours')
	return hours
}

DateRange.prototype.minutes = function () {
	const minutes = this.end.diff(this.start, 'minutes')
	return minutes
}

DateRange.prototype.isSingleDay = function () {
	if (utils.exists(this.hours())) return this.hours() <= 24
}

DateRange.prototype.overlapFactor = function (range) {
	const overlappingMinutes = this.intersect(range)?.diff('minutes') || 0
	const overlapFactor = overlappingMinutes / this.diff('minutes')
	return overlapFactor
}

DateRange.prototype.changeDay = function (date) {
	// In case the end of the shift falls on the next day, adjust the new end date accordingly
	const startEndDiff = this.days()
	return {
		start: moment(buildDateTime({date, time: this.start.formatTime()})),
		end: moment(buildDateTime({date: moment(date).add(startEndDiff, 'days'), time: this.end.formatTime()})),
	}
}

DateRange.prototype.format = function () {
	return `${this.start.ftime('dateTime')} - ${this.end.ftime('dateTime')}`
}

moment.fn.isToday = function () {
	return this.isSame(new Date(), 'day')
}

moment.fn.isYesterday = function () {
	const yesterday = moment().subtract(1, 'days').startOf('day')
	return this.isSame(yesterday, 'day')
}

moment.fn.isDateTime = function () {
	return timeFormats.includes(this._f)
}

moment.fn.formatDateTime = function (format = 'YYYY-MM-DD HH:mm') {
	return this.format(format)
}

moment.fn.formatDate = function ({year, day = true, month = true, time} = {}) {
	let str = ''
	if (day) str += 'DD '
	if (month) str += 'MMM '
	if (year) str += `'YY `
	if (time) str += ', HH:MM'

	return this.format(str)
}

moment.fn.prevWeekday = function (n) {
	if (n > this.weekday()) this.subtract(1, 'week')
	return this.weekday(n)
}

moment.fn.nextWeekday = function (n) {
	if (n < this.weekday()) this.add(1, 'week')
	return this.weekday(n)
}

moment.fn.weekdayName = function () {
	return utils.weekdays[this.weekday()]
}

moment.fn.prevMonthday = function (n) {
	if (n > this.date()) this.subtract(1, 'month')
	return this.date(n)
}

moment.fn.nextMonthday = function (n) {
	if (n < this.date()) this.add(1, 'month')
	return this.date(n)
}

moment.fn.inFuture = function () {
	return this > moment()
}

moment.fn.inPast = function () {
	return !this.inFuture()
}

moment.fn.formatTime = function () {
	return this.format('HH:mm')
}

function formatRelative(date) {
	const now = moment()
	const secs = now.diff(date, 'seconds')
	const mins = now.diff(date, 'minutes')
	const hours = now.diff(date, 'hours')
	const days = now.diff(date, 'days')

	const _t = id => t(`times.one_letter.${id}`)

	if (secs < 60) {
		return `${secs} ${_t('seconds')}`
	} else if (mins < 60) {
		return `${mins} ${_t('minutes')}`
	} else if (hours < 24) {
		return `${hours} ${_t('hours')}`
	} else if (days < 7) {
		return `${days} ${_t('days')}`
	} else {
		return formatDayMonth(date)
	}
}

export function dateToString(_date) {
	return Moment.isMoment(_date) ? _date.format() : _date
}

export function identicalPeriod(startDate_1, endDate_1, startDate_2, endDate_2) {
	const range_1 = [dateToString(startDate_1), dateToString(endDate_1)]
	const range_2 = [dateToString(startDate_2), dateToString(endDate_2)]

	return _.isEqual(range_1, range_2)
}

function monthSuffix() {
	// Moment doesn't suffix month abbreviation with a dot in English, while it is desired to do so.
	return moment.locale() === 'en' ? '.' : ''
}

function formatDayMonth(date) {
	return moment(date, 'DD-MM-YYYY').format(`DD MMM${monthSuffix()}`)
}

function formatDate(date) {
	const dateStamp = date.format('dddd DD MMM')
	const yearStamp = date.format("'YY")

	return `${dateStamp}${monthSuffix()} ${yearStamp}`
}

function formatDateTime(date) {
	const dateStamp = formatDate(date)
	if (!date.isDateTime()) return dateStamp

	const timeStamp = date.format('HH:mm')
	return `${dateStamp} ${t('common.at')} ${timeStamp}`
}

function formatRelativeToDate(date, options = {}) {
	const {includeTime} = options
	const now = moment()
	const secs = now.diff(date, 'seconds')
	const momentDate = moment(date)

	if (secs < 60) {
		return t('times.now')
	} else if (momentDate.isToday()) {
		return momentDate.format('HH:mm')
	} else if (momentDate.isYesterday()) {
		return includeTime ? momentDate.calendar() : t('common.yesterday')
	}

	const format = includeTime ? `DD/MM/YY [${t('common.at')}] HH:mm` : 'DD/MM/YY'
	return momentDate.format(format)
}

function formatRelativeDate(date) {
	const momentDate = moment(date)

	if (momentDate.isToday()) {
		return t('common.today')
	} else if (momentDate.isYesterday()) {
		return t('common.yesterday')
	} else {
		return momentDate.format('DD/MM/YY')
	}
}

export function ftime(date, format) {
	if (!date) return

	// Accept date strings, Moment instances (which have a object type) and functions which return a Moment object (and
	// still need to be invoked).
	// if (typeof date === 'string') date = moment(date)
	if (typeof date === 'function') date = date()

	if (format === 'relative') return formatRelative(date)
	if (format === 'relativeToDate') return formatRelativeToDate(date)
	if (format === 'relativeToDateTime') return formatRelativeToDate(date, {includeTime: true})
	if (format === 'relativeDate') return formatRelativeDate(date)
	if (format === 'dayMonth') return formatDayMonth(date)
	if (format === 'dateTime') return formatDateTime(date)
	if (format === 'date') return formatDate(date)

	console.error('ftime received invalid format')
}

moment.fn.ftime = function (format) {
	return ftime(this, format)
}

export {moment}
