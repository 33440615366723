import {DefaultListPickerTrigger} from '@eitje/web_components'
import {ListPicker} from 'common/components'
import {useFormData} from 'hooks'
import {Environment} from 'models'

export const EnvFormDropdownPicker = ({envs, ...rest}) => {
	const {environment_id} = useFormData()
	const env = Environment.find(environment_id)
	if (!envs) return null
	return (
		<ListPicker
			single
			decorated={false}
			Trigger={DefaultListPickerTrigger}
			dropdown
			form
			title={env.naam}
			labelField="naam"
			field="environment_id"
			{...rest}
			items={envs}
		/>
	)
}
