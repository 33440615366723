import useScheduledShifts from './use_scheduled_shifts'

const useScheduledMins = ({user, dateRange}) => {
	let {planShifts, improductiveShifts} = useScheduledShifts({user_id: user.id, dateRange})
	const planIds = planShifts.ids()
	improductiveShifts = improductiveShifts.filter(s => !planIds.includes(s.plan_shift_id))

	const scheduledMins = planShifts.sum(w => w.totalMins())
	const scheduledDays = planShifts.map(w => w.date)

	const improductiveMins = improductiveShifts.sum(s => s.totalMins())
	const improductiveDays = improductiveShifts.map(w => w.date)

	const totalMins = scheduledMins + improductiveMins
	const totalDays = [...scheduledDays, ...improductiveDays].uniq().length

	return {planShifts, improductiveShifts, scheduledMins, scheduledDays, improductiveMins, improductiveDays, totalMins, totalDays}
}

export default useScheduledMins
