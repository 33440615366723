import useAmtWorkedUserMap from 'cores/planning/hooks/use_amt_worked_users'
import React, {createContext, useContext} from 'react'

const AmountWorkedContext = createContext({})
const {Provider} = AmountWorkedContext

const AmountWorkedProvider = ({children}) => {
	const userMap = useAmtWorkedUserMap()
	const value = {userMap}
	return <Provider value={value}> {children} </Provider>
}

export const useAmountWorkedContext = () => useContext(AmountWorkedContext)

export default AmountWorkedProvider
