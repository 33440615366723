import {API, find, where} from '@eitje/easy_api'
import {FormWrapper} from '@eitje/form'
import {
	LegacyDropdownPicker as DropdownPicker,
	LegacyInput as Input,
	LegacyDatePicker as DatePicker,
	LegacySwitch as Switch,
	LegacyTimePicker as TimePicker,
} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {getQuery} from 'actions/routing'
import EmoInput from 'components/form/emo_input'
import NewsForm from 'components/forms/news_form'
import Modal from 'components/general/modal'
import {Holder, UserImage} from 'components/ui'
import {getEnums} from 'data/enums'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Prompt, useParams} from 'react-router-dom'
import './styles/event.less'
import useQueryParams from 'hooks/use_query_params'
import {useRoleOrgEnvs} from 'hooks'
import {useBoolState} from '@eitje/react-hooks'
import {scrollDelayed} from 'helpers'

const EventForm = () => {
	const {date: dateParam, env_ids} = useQueryParams()
	const {hasManagerRole} = useShared()
	const [touched, setTouched] = useState(null)
	const [date, setDate] = useState(dateParam)

	const {fromPost, title, content} = getQuery()
	const [visible, toggleVisible] = useBoolState(fromPost)
	const {id} = useParams()
	const event = useSelector(state => find(state, 'events', id))

	const form = useRef(null)
	const postForm = useRef(null)
	const formWrapper = useRef(null)
	const enums = getEnums('event')
	const post = useSelector(state => find(state, 'posts', event.post_id))
	const inPast = utils.inPast(post.sync_moment)
	const roleOrgEnvs = useRoleOrgEnvs('manager')
	const activeEnvs = !env_ids ? roleOrgEnvs : roleOrgEnvs.filter(env => env_ids?.includes(env.id))

	const handleToggle = isVisible => {
		isVisible && scrollDelayed('.post-form-submit-container')
		toggleVisible(isVisible)
	}

	return (
		<Modal
			hideDel={!id || !hasManagerRole}
			hideOk={!hasManagerRole}
			form={form}
			onDel={() => API.destroy('events', id)}
			onOk={() => formWrapper.current && formWrapper.current.submit()}
			title={t('createEvent')}
			wrapClassName={'event-modal'}
		>
			<FormWrapper
				afterSubmit={history.goBack}
				onSubmit={({event, post}) => API.create('events', {environment_id: activeEnvs[0], ...event}, {extraParams: {post}})}
				ref={formWrapper}
			>
				<Form
					fieldProps={{readOnly: !hasManagerRole, bordered: false}}
					afterTouch={setTouched}
					afterChange={(field, val) => field === 'date' && setDate(val)}
					formKey="event"
					initialValues={{title, date, environment_id: activeEnvs.length === 1 && activeEnvs[0].id, ...event}}
					ref={form}
				>
					{roleOrgEnvs.length > 1 && (
						<div className="form-row" fieldWrapper>
							<DropdownPicker
								labelField="naam"
								placeholder={t('environment')}
								label={t('environment')}
								items={roleOrgEnvs}
								field="environment_id"
							/>
						</div>
					)}

					<div className="form-row" fieldWrapper>
						<Input required field="title" label="titleEvent" />
						<DatePicker required placeholder={t('selectDate')} pastDisabled field="date" label="dateEvent" />
					</div>

					<div className="form-row" fieldWrapper>
						<TimePicker placeholder={t('selectTime')} label="eventStart" field="from" defaultOpenValue="09:00" />
						<TimePicker placeholder={t('selectTime')} defaultOpenValue="17:00" label="eventEnd" field="till" />
					</div>

					<EmoInput extraEmoStyle={{right: 12}} autoSize={{minRows: 4, maxRows: 6}} textarea label="eventDes" field="remarks" />

					<div className="form-row" fieldWrapper>
						<DropdownPicker labelField="naam" label="pickType" placeholder={t('eventType')} items={enums} field="kind" />

						{!id && (
							<Switch
								label={t('scheduleNews')}
								extraLabel={
									<div style={{height: '40px'}} className="row align-items-center">
										<p>{t('scheduleNews2')}</p>
									</div>
								}
								value={visible}
								onChange={handleToggle}
							/>
						)}
					</div>
				</Form>

				<Holder formWrapper hideChildren={!visible || id}>
					<div formWrapper>
						<NewsForm formKey="post" isForm ref={postForm} item={{date, content}} hiddenFields={['title']} inEvent />
					</div>
				</Holder>

				{id && post.id && <PresenceBlock item={event} />}
			</FormWrapper>
			{post.id && (
				<p className="eitje-text-secondary center mB12 increased-line-height">
					{t(inPast ? 'messageInPast' : 'messageInFuture', {date: utils.dateTime(post.sync_moment)})}{' '}
				</p>
			)}

			<Prompt message={t('unsavedChanges')} when={touched} />
		</Modal>
	)
}

const PresenceBlock = ({item}) => {
	const attendees = useSelector(state => where(state, 'users', item.present_ids))
	const absentees = useSelector(state => where(state, 'users', item.absent_ids))

	return (
		<div className="row-with-justify-center vertical-padding-xl increased-line-height">
			<div className="w50 pL12">
				<p className="bold increased-line-height">
					{t('present')} ({attendees.length}){' '}
				</p>
				{attendees.map(a => (
					<UserBlock item={a} />
				))}
			</div>
			<div className="w50 pL12">
				<p className="bold increased-line-height">
					{t('absent')} ({absentees.length}){' '}
				</p>
				{absentees.map(a => (
					<UserBlock item={a} />
				))}
			</div>
		</div>
	)
}

const UserBlock = ({item}) => (
	<div className="fRow aCen jSta pT12">
		<UserImage user={item} />
		<p className="pL12">{item.full_name}</p>
	</div>
)

export default EventForm
