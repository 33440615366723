import {RevenueGroup} from 'models'

const revFooterHeaderHeight = 64
const revGroupHeight = 66

export const useRevenueFooterHeight = envId => {
	const revenueGroups = RevenueGroup.where({environment_id: envId})
	const revGroupLength = revenueGroups.length
	const revGroupLen = revGroupLength > 1 ? revGroupLength + 1 : revGroupLength // total is always there
	const revFooterHeight = revFooterHeaderHeight + revGroupHeight * revGroupLen
	return revFooterHeight
}
