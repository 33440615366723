const initialState = {
	inboxVisible: false,
	route: 0,
	enums: {},
	eventKinds: {},
	ops: [],
	processingVideos: {},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'GET_OPS':
			return {
				...state,
				ops: action.ops || [],
			}
		case 'GET_ENUMS':
			return {
				...state,
				enums: action.enums,
				eventKinds: action.eventKinds,
			}

		case 'ROUTE_CHANGE':
			return {
				...state,
				route: (state.route || 0) + 1,
			}
			break

		case 'START_LOADING':
			return {
				...state,
				loading: true,
			}

		case 'STOP_LOADING':
			return {
				...state,
				loading: false,
			}

		case 'INBOX_SHOW':
			return {
				...state,
				inboxVisible: true,
			}

		case 'INBOX_HIDE':
			return {
				...state,
				inboxVisible: false,
			}

		case 'SET_PROCESSING_VIDEO':
			const item = action.item
			if (item.percentage === 100) {
				const {[item.id]: omit, ...rest} = state.processingVideos
				return {
					...state,
					processingVideos: rest,
				}
			}
			return {
				...state,
				processingVideos: {...state.processingVideos, [item.id]: item},
			}
		case 'REMOVE_PROCESS_VIDEO':
			const videoId = action.videoId
			const {[videoId]: omit, ...rest} = state.processingVideos
			// processingVideos is an object, the video id is the key from the key/pair. object deconstructure removes the processing
			return {
				...state,
				processingVideos: {...rest},
			}

		default:
			return state
	}
}
