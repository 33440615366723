import {baseColorMapping as leaveColors} from 'cores/leave/constants'
import {baseColorMapping as timeRegistrationColors} from 'cores/time_registration/constants'
import {baseColorMapping as planningColors} from 'cores/planning/constants'
import {baseColorMapping as billingColors} from 'cores/billing/constants'

export const CORE_COLORS = {
	leave: leaveColors,
	time_registration: timeRegistrationColors,
	planning: planningColors,
	billing: billingColors,
}

export const coreStatusColor = ({core, status}) => {
	const coreColors = CORE_COLORS[core]
	const statusColor = coreColors[status] // mapping of arbitrary names to colors
	const colorKey = `medium${_.capitalize(statusColor)}`
	return Colors[colorKey]
}
