import React, {useEffect, useState} from 'react'
import useAvailability from 'hooks/use_availability'
import FixedRangePicker from 'components/date/fixed_range_picker'
import {API} from '@eitje/easy_api'
import {AvailabilityWeeks} from './components'
import utils from '@eitje/web_utils'
import Dropdown from 'components/dropdown'
import {t} from 'initializers/i18n'
import {Link} from 'components/routing'
import {EitjeButton} from 'common/components'
import {AvailabilityShift, Day, PlanningShift, UserWeek} from 'models'

const Availability = ({id}) => {
	const elements = [<Link to="availability_template" t="editTemplate" />, <Link to="/leave" t="leaveRequests" />]
	const {firstOpenDate} = useAvailability()
	const [dates, setDates] = useState([firstOpenDate])
	const firstDate = dates[0]
	const lastDate = dates[dates.length - 1]
	const dateStrings = dates.map(d => d.format('YYYY-MM-DD'))

	useEffect(() => {
		if (dates.length > 1) {
			// 1 because our default is firstOpenDate, should have been configured differently, that this comp receives dates as a prop..
			const {week, year} = utils.getYearWeekFromDate(firstDate)
			const {week: week2, year: year2} = utils.getYearWeekFromDate(lastDate)

			const filters = {start_date: dateStrings[0], end_date: dateStrings[dates.length - 1]}
			const avFilters = {...filters, user_id: id}
			const multiIndexPayload = [
				{name: AvailabilityShift.tableName, filters: avFilters},
				{name: Day.tableName, filters},
				{name: PlanningShift.tableName, filters: avFilters},
				{name: UserWeek.tableName, filters: {user_id: id, weeknumber: [week, week2], year: [year, year2]}},
			]

			API.multiIndex(multiIndexPayload)
		}
	}, [dates, id])

	return (
		<div className="user-availability-table-container">
			<div className="user-availability-header">
				<div className="row">
					<div className="availability-header-text-container">
						<p className="availability-header-title">{t('availability_title')}</p>
						<p className="availability-header-sub-title">{firstDate.format('YYYY')}</p>
					</div>

					<FixedRangePicker length={14} onChange={setDates} initialValue={firstOpenDate} />
				</div>

				<Dropdown elements={elements} arrow>
					<EitjeButton truncate={false} t="common.options" />
				</Dropdown>
				{/* Below is not in use */}
				{false && (
					<EitjeButton modalLink="verlof_verzoeken">
						<p>{t('leaveRequests', {count: 1})} </p>
					</EitjeButton>
				)}
			</div>

			{dates?.length > 1 && <AvailabilityWeeks id={id} dates={dates} />}
		</div>
	)
}

export default Availability
