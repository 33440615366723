import {API, find, where} from '@eitje/easy_api'
import {PopoutCard} from '@eitje/web_components'
import {useFormField} from '@eitje/form'
import utils from '@eitje/web_utils'
import {cheer} from 'actions/news'
import {navigateModal} from 'actions/routing'
import {DatePicker as AntDatePicker, Tooltip} from 'antd'
import useList from 'components/general/list'
import {UploadProgress} from 'components/general/video'
import {ModalLink} from 'components/routing'
import {UserAvatar} from 'components/ui'
import {getFuturePost, makeTeamName, outsideClickClassNames} from 'helpers/posts'
import useOutsideClick from 'hooks/use_outside_click'
import {useShared} from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import moment from 'initializers/date'
import _ from 'lodash'
import {date} from 'initializers/date'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {EitjeButton, DeleteButton, OptionsButton, ListPicker} from 'common/components'

import {futurePostSelector} from 'selectors/entities'
import '../news/styles/news.less'
import {EitjeAvatar} from 'common/components'

const imgSrc = /<img src="([^\s]*)"/ // idea is that we allow any character except a whitespace as we know that means the next prop of img is coming
// first group returns src as a string

const getManualImg = item => {
	const imgMatch = item.content.match(imgSrc)
	return imgMatch ? imgMatch[1] : '/images/placeholder_manual_img.png'
}

const hourMinFormat = 'dddd DD MMM YYYY HH:mm'

const pollExpired = poll => {
	const {created_at, duration_days} = poll
	const expiryMoment = date(created_at).add(duration_days, 'days')
	return date() > expiryMoment
}

export const Poll = ({item}) => {
	const {me} = useShared()
	const {duration_days, answers} = item
	const openTill = date(item.created_at, null).add(duration_days, 'd').format(hourMinFormat)
	const votes = answers.map(a => a.user_ids).flat()
	const voteCount = votes.length
	const voted = votes.includes(me.id)
	const highestVoteId = utils.getMax(
		answers.map(a => ({...a, len: a.user_ids.length})),
		'len',
	)?.id

	const expired = pollExpired(item)
	const itemProps = {myId: me.id, voted: expired || voted, voteCount, highestVoteId}

	const {list} = useList({items: answers, ListItem: PollAnswer, itemProps, highestVoteId})

	const pollKey = expired ? 'pollExpired' : 'pollOpenTill'
	return (
		<div className="news-poll-container">
			<p className="label blue margin-bottom-sm">POLL</p>
			<div className="news-poll-text-container">
				<p className="news-poll-title">{item.question}</p>
				<p className="eitje-text-secondary">
					{t(pollKey)} {openTill} - {t('votesCast', {count: voteCount})}{' '}
				</p>
			</div>
			{list}
		</div>
	)
}

const PollAnswer = ({item, myId, highestVoteId, voted, voteCount}) => {
	const {user_ids, id} = item
	const isHighest = highestVoteId == id

	const {answer} = item

	const act = voted ? () => {} : () => API.create('user_poll_answers', {poll_answer_id: id})

	const votedThisAnswer = user_ids.includes(myId)
	const votePct = Math.round((user_ids.length / voteCount) * 100) || 0

	const className = utils.makeCns('poll-answer-row', voted && 'selected', isHighest && 'selected-heavy')

	return (
		<div
			className={`blocks mB4 fRow ${voted && 'aCen jBetween '} ${!voted && 'vote  aCen jCen center pointer'}`}
			className={className}
			onClick={act}
		>
			<div className="poll-answer-text-container">
				<p className="poll-answer-text">{answer}</p>
				{votedThisAnswer && <div className="mL24 yesCircle" />}
			</div>

			{voted && <p className="poll-answer-perct"> {votePct}% </p>}
		</div>
	)
}

export const ManualItem = ({id, idx}) => {
	const info = useSelector(state => find(state, 'infos', id))
	if (!info?.id) return null
	const infoImg = getManualImg(info)
	return (
		<Link className="fRow aCen news-manual-item" to={`/articles/${id}`}>
			<div>
				<img src={infoImg} />
			</div>
			<div className="fCol aSta mL24 pT4 overflow">
				<p className="eitje-text-secondary">{t('manualItem')}</p>
				<h3>{info.title} </h3>
			</div>
		</Link>
	)
}

const getLikerString = (users = [], max = 4) => {
	if (users.length === 0) return null

	const userNames = users
		.map(u => u.full_name)
		.slice(0, max)
		.join(', ')
	if (users.length <= max) return userNames

	return t('andOthers', {main: userNames, count: users.length - max})
}

export const EventRow = ({id}) => {
	const {me} = useShared()
	const event = useSelector(state => find(state, 'events', id))
	const {present_ids = [], absent_ids = []} = event
	const attendees = useSelector(state => where(state, 'users', event.present_ids))

	const inPast = utils.inPast(event)
	if (!event.id) return null
	return (
		<div className="fCol aSta jCen" style={{paddingBottom: 12}}>
			<ModalLink to={`/forms/events/${id}`}>
				<div className="fRow aCen blocks jCen mT8" style={{width: 600}}>
					<img src="/images/eventAvatar.png" className="openEventIcon avaBG" />
					<p className="pL12 bold">Open event info</p>
				</div>
			</ModalLink>

			{!inPast && (
				<div className="fRow aCen jBetween" style={{width: 600}}>
					<div to={`/forms/events/${id}`} className="fCol">
						<div className="fRow aCen jSta pointer">
							<p style={{fontSize: 14, fontWeight: 600}}>Event {t('on')}:</p>
							<p style={{fontSize: 14}}>&nbsp;{utils.fullHumanDate(event.date)}</p>
						</div>
						<div className="fRow aCen jSta">
							{attendees.length >= 1 ? (
								<div className="fRow aCen jSta pointer">
									<p className="panelUnderlineText">{t('whoAttending')}:</p>
									<p className="panelUnderlineText">&nbsp;{attendees.length}</p>
								</div>
							) : (
								<div>
									<p className="panelUnderlineText">{t('noAttendees')}</p>
								</div>
							)}
						</div>
					</div>

					<div className="dbSwitch fRow aCen jCen" style={{width: 280, marginRight: 0}}>
						<div
							style={{borderRight: '1px solid #eee'}}
							className="dbSwitcher"
							onClick={() => API.toggle('events', {id, field: 'afwezigen'})}
						>
							<p className={absent_ids.includes(me.id) && 'panelActive'}>{t('notAttending')}</p>
						</div>

						<div className="dbSwitcher" onClick={() => API.toggle('events', {id, field: 'aanwezigen'})}>
							<span className={present_ids.includes(me.id) && 'panelActive'}>{t('attending')}</span>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export const CommentCount = ({len}) => (
	<div className="row-with-justify-end gap-sm" style={{width: 140}}>
		<img width={24} height={24} src="/images/web/icons/news_comment.png" />
		<span className="eitje-text-secondary">{t('commentCount', {count: len})}</span>
	</div>
)

export const File = ({file}) => (
	<a href={file} target="_blank" method="_blank">
		<div className="soft-button" style={{minWidth: 200}}>
			<img width={28} height={28} src="/images/newsFileUploadActive.png" />
			<p className="soft-button-text bold">{t('openAttachment')}</p>
		</div>
	</a>
)

export const Likers = ({likers}) => (
	<Tooltip title={getLikerString(likers, 20)}>
		<div className="fRow aCen" style={{padding: '12px 8px'}}>
			<p style={{fontSize: 12, marginRight: 8}}>✋</p>
			<div className="fRow aCen jSta" style={{paddingTop: 2}}>
				<p className="panelUnderlineText">{getLikerString(likers)}</p>
			</div>
		</div>
	</Tooltip>
)

export const LikeButton = ({hasLiked, id}) => (
	<div onClick={() => cheer(id)} className="soft-button" style={{maxWidth: 160}}>
		<span style={{fontSize: 14}}>✋</span>
		<p className={utils.makeCns('soft-button-text', hasLiked ? 'bold' : 'grey-text')}>Highfive</p>
	</div>
)

export const PopoverContent = ({item, hide = _.noop, canEdit}) => (
	<>
		<DeleteButton
			onClick={async () => {
				await API.destroy('posts', item.id)
				hide()
			}}
			t="deletePost"
		/>
		{canEdit && <EitjeButton iconLeft="pencil" width="full" onClick={hide} modalLink={`/forms/posts/${item.id}`} t="editPost" />}
	</>
)

export const DeleteComment = ({item}) => (
	<div className="veedree">
		<DeleteButton className="btn-hover danger" onClick={() => API.destroy('comments', item.id)} t="deleteComment" />
	</div>
)

export const Comment = ({item}) => {
	const {hasManagerRole} = useShared()
	const user = useSelector(state => find(state, 'users', item.user_id))
	return (
		<div className="news-comment-container">
			<div className="left-comment-column">
				<UserAvatar user={user} size="sm" className="margin-right-md" />
				<div className="news-comment-and-timestamp">
					<div className="news-comment-text-container">
						<p className="bold">{user.full_name} </p>
						<p>{item.body}</p>
					</div>
					<h6>{utils.shortDateTime(item.created_at)}</h6>
				</div>
			</div>

			{hasManagerRole && <OptionsButton body={<DeleteComment item={item} />} />}
		</div>
	)
}

export const AssetButton = ({toolText, onClick = () => null, iconSrc}) => {
	const className = utils.makeCns('news-asset-icon')
	return (
		<Tooltip title={t(toolText)}>
			<div onClick={onClick} className={className}>
				<img className="news-asset-icon-img" src={iconSrc} />
			</div>
		</Tooltip>
	)
}

export const PostHeader = ({postId, containerClass}) => {
	const {me, orgEnvs, myOrgEnvs, hasManagerRole} = useShared()
	const item = useSelector(state => find(state, 'posts', postId))
	const {user_id, title, created_at, team_ids = []} = item
	const user = useSelector(state => find(state, 'users', user_id))
	const teams = useSelector(state => where(state, 'teams', team_ids))
	const isSelf = me.id === user.id
	const hasOptions = isSelf || hasManagerRole

	const className = utils.makeCns('row-with-justify-between', containerClass)

	let teamName = teams.length > 1 ? '$t(multipleTeams)' : makeTeamName(teams[0], myOrgEnvs)

	return (
		<div className={className}>
			<div className="row-with-justify-start">
				<UserAvatar size="xl" className="margin-right-md" userId={user_id} />
				<div>
					<p className="bold ">{title} </p>
					<Tooltip hidden={teams.length === 1} title={teams.map(t => makeTeamName(t, orgEnvs)).join(',\n')}>
						<h5>{t('inTeamByUser', {teamName, userName: user.full_name})}</h5>
					</Tooltip>
					<h5>{utils.fullHumanDate(created_at)} </h5>
				</div>
			</div>
			{hasOptions && (
				<div className="align-self-flex-start">
					<OptionsButton body={ref => <PopoverContent canEdit={isSelf} hide={ref?.hide} item={item} />} />
				</div>
			)}
		</div>
	)
}

export const PostVideoUpload = ({postId}) => {
	const processingVideos = useSelector(state => state.general.processingVideos) || {}
	const {video_ids = []} = useSelector(state => find(state, 'posts', {id: postId}))
	const processingState = processingVideos[video_ids[0]]

	if (_.isEmpty(processingState)) return null

	return (
		<div className="news-post-container">
			<PostHeader postId={postId} containerClass="margin-bottom-sm" />
			<div className="padding-bottom-xl">
				<p className="bold">{t('processingVideo')}</p>
				<p className="eitje-text-secondary">{t('videoUploadSubline')}</p>
				<UploadProgress percentage={processingState.percentage} />
			</div>
		</div>
	)
}

export const ScheduledPosts = () => {
	const [parentActive, setParentActive] = useState(false)
	const unpublished = useSelector(futurePostSelector).filter(p => utils.exists(p.team_ids)) || []
	if (unpublished.length === 0) return null
	const schedPosts = getFuturePost(unpublished, 'scheduled').filter(p => utils.exists(p.team_ids))
	const draftPosts = getFuturePost(unpublished, 'draft').filter(p => utils.exists(p.team_ids))

	const subString = [
		schedPosts.length > 0 && `${schedPosts.length} ${t('postsScheduled', {post: pluralize(t('post_translate'), schedPosts.length)})} `,
		draftPosts.length > 0 && `${draftPosts.length} ${t(draftPosts.length > 1 ? 'draftMessage_plural' : 'draftMessage')}`,
	].filter(Boolean)

	return (
		<ModalLink to={'/news/scheduled_posts'}>
			<div
				onMouseOver={() => setParentActive(true)}
				onMouseOut={() => setParentActive(false)}
				className="margin-bottom-xl horizontal-padding-sm vertical-padding-sm saved-and-planned-box"
			>
				<div className="flex-grow-one">
					<p className="bold">{t('scheduledDraftPosts')}</p>
					<p className="eitje-text-secondary">{subString.join(' | ')}</p>
				</div>

				<EitjeButton iconLeft="calendar" />
			</div>
		</ModalLink>
	)
}

const dateBefore = momObj => {
	return date(momObj).isBefore(date())
}

export const ScheduledPostRow = props => {
	const {value = null, onChange, setPubilshType} = props
	const {error} = useFormField(props)
	const container = useRef(null)

	const [isVisible, setIsVisible] = useState(false)

	useOutsideClick(container, () => setIsVisible(false), {
		classNames: outsideClickClassNames,
		checkParents: true,
	})

	const pickerRef = useRef(null)
	const valueDate = value ? date(new Date(value)) : null

	const handleClear = () => {
		onChange(null)
		setPubilshType('sendNow')
	}

	const text = value ? t(`messageInFuture`, {date: valueDate.format(`dddd DD/MM/YYYY [${t('timeAt')}] HH:mm`)}) : t('selectPublishTime')

	return (
		<div className="post-form-schedule-details-container">
			<div className="post-form-schedule-details" ref={container} onClick={() => setIsVisible(!isVisible)}>
				<div className="flex-grow-one">
					<div ref={pickerRef} className="sync-moment-input row">
						<div onClick={e => e.stopPropagation()}>
							<AntDatePicker
								showTime={{format: 'HH:mm'}}
								bordered={false}
								format="HH:mm a DD-MM 'YY "
								className="sync-moment-input"
								dropdownClassName="sync-moment-input"
								disabledDate={momDate => momDate.isBefore(date().startOf('day'))}
								open={isVisible}
								value={valueDate}
								onChange={(mom, str) => !dateBefore(mom) && onChange(mom.toISOString())}
								onOk={() => setIsVisible(false)}
							/>
						</div>
						<div className="row align-items-center eitje-text-secondary gap-xss">
							{text}
							<img width={12} src="/images/dropDown1.png"></img>
						</div>
					</div>
					{error && error}
				</div>
				<div onClick={e => e.stopPropagation()} className="sync-moment-input align-items-center">
					{value && <DeleteButton onDel={handleClear} />}
				</div>
			</div>
		</div>
	)
}
const schedOpts = [
	{name: t('sendNow'), id: 'sendNow'},
	{name: t('sendLater'), id: 'sendLater'},
	{name: t('saveDraft'), id: 'saveDraft'},
]

export const TeamButton = ({onClick, title, disabled}) => {
	return (
		<EitjeButton disabled={disabled} className="team-button" onClick={onClick} iconRight="caret-down">
			{title}
		</EitjeButton>
	)
}

const SmallTeamButton = ({onClick, title}) => {
	return (
		<EitjeButton height="small" className onClick={onClick} dropdown>
			{title}
		</EitjeButton>
	)
}

export const ScheduleOptionButton = ({setPubilshType, active}) => {
	return (
		<ListPicker
			raw
			dropdown
			single
			items={schedOpts}
			onChange={(v, {changed}) => setPubilshType(changed)}
			Trigger={props => <SmallTeamButton {...props} />}
			noSort
			value={active}
			title={t(active)}
		/>
	)
}

export const EventButton = ({mainFormRef}) => {
	return (
		<PopoutCard title={t('createEvent')}>
			<EitjeAvatar
				icon="calendar"
				onClick={() => navigateModal('/forms/events', {search: {...mainFormRef?.current?.getValues?.('title', 'content'), fromPost: true}})}
			/>
		</PopoutCard>
	)
}
