import {useRoleOrgEnvs} from './use_role_org_envs'
import {Team} from 'models'

export const useManagerTeams = teamIds => {
	const roleOrgEnvs = useRoleOrgEnvs('manager')
	const teams = Team.where(teamIds)
	const managerTeamIds = Team.where({environment_id: roleOrgEnvs._map('id')}).ids()
	const managerTeams = teams.where(managerTeamIds)
	return managerTeams
}
