import {PageLayout, Layout, SupportCollections, SupportButton} from 'common/components'
import {supportCollectionsTaxonomy} from 'cores/support'
import {SupportPlaceholder} from './support_placeholder'

export const Collections = () => {
	const supportCollections = supportCollectionsTaxonomy()

	return (
		<PageLayout name="support-collections" horizontal="center">
			<Layout width={800} direction="vertical" gap={8}>
				<SupportCollections title="common.subjects" items={supportCollections} headerButtons={<SupportButton t="common.chat" />} />
				<SupportPlaceholder />
			</Layout>
		</PageLayout>
	)
}
