import {scrollDelayed} from 'helpers/index'
import {ViewButton, viewOptionsDropdownItem} from 'common/components'
import {t} from 'initializers/i18n'
import {useShared, useHasRoleInAnyEnv} from 'hooks'
import {LocalCheckboxButton} from 'components/ui'

export function useShiftViewOptions() {
	const {multiEnvOrg} = useShared()
	const buttons = ['StartEnd', multiEnvOrg && 'Environment', 'Team']
	return viewOptionsDropdownItem({record: 'time_registration_shift', buttons, namespace: 'hoursPerMemberShow'})
}

export function userViewOptions() {
	const buttons = ['UserWorked', 'UserPlanned', 'ContractHours']
	return viewOptionsDropdownItem({record: 'user', buttons, namespace: 'hours'})
}

export const ViewOptions = props => {
	const hasApproveRole = useHasRoleInAnyEnv('uren_accorderen')
	const hasFinancialRole = useHasRoleInAnyEnv('financieel')
	const showCalc = hasApproveRole || hasFinancialRole

	const elements = [
		useShiftViewOptions(),
		userViewOptions(),
		showCalc && {
			icon: 'eye-open',
			title: t('view_options.rows'),
			children: [
				<LocalCheckboxButton
					afterChange={isVisible => isVisible && scrollDelayed('.revenue-footers')}
					kind="timeRegRevenue"
					t="planning.header.calculations"
				/>,
			],
		},
	]

	return <ViewButton elements={elements} {...props} />
}
