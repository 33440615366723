import {t} from 'initializers/i18n'
import {useTotalUnreadMessagesCount} from 'cores/chat'
import {titleWithCount} from 'common/components/headers/navigation_header/nav_item'
import {BaseHeader} from './base'

export const ChannelIndexHeader = () => {
	const unreadChatCount = useTotalUnreadMessagesCount()
	const title = titleWithCount(t('messages'), unreadChatCount)

	return <BaseHeader title={title} />
}
