import {PlanningShift, TimeRegistrationShift, Team} from 'models/index'
import utils from '@eitje/web_utils'

const useScheduledShifts = ({user_id, dateRange, teamIds}) => {
	const unschedulableIds = Team.where({locked: true, onroosterbaar: true}).ids()
	let query = {date: dateRange}
	if (utils.exists(teamIds)) query.team_id = teamIds
	if (utils.exists(user_id)) query.user_id = user_id

	const planShifts = PlanningShift.where(query).filter(s => !unschedulableIds.includes(s.team_id))
	// the problem is that there might be shifts in teams we don't have in the front-end, so we can't filter only on those teams as you might miss shifts
	// this caused planners from orgs with multiple envs to see incorrect counts

	const improductiveShifts = TimeRegistrationShift.where({user_id: user_id, date: dateRange, typ: TimeRegistrationShift.improductiveTypes})
	return {planShifts, improductiveShifts}
}

export default useScheduledShifts
