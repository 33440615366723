import {Fragment, useRef, useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import {useNoNotificationsModus} from 'cores/onboarding'
import {SubmitPopout, EitjeButton} from 'common/components'
import {saveSettingGroup} from 'actions/settings'
import {inviteAllUsers} from 'actions/entities'
import {t} from 'initializers/i18n'

export const EnableNotificationsPopout = ({children}) => {
	const [confirmed, setConfirmed] = useState()
	const modusActive = useNoNotificationsModus()
	const Wrapper = modusActive ? SubmitPopout : Fragment
	const saveSetting = () => saveSettingGroup('onboarding', {active: !modusActive})
	const onChange = modusActive ? _.noop : saveSetting
	const onConfirm = () => {
		saveSetting()
		inviteAllUsers()
	}

	return (
		<PopoutCard className="onboarding-mode-switch" hidden={confirmed}>
			<Wrapper
				onVisibleChange={setConfirmed}
				onSubmit={onConfirm}
				backButton={false}
				submitButtonProps={{iconLeft: 'bell'}}
				submitText={t('onboarding.buttons.send_invite')}
				cancelText={t('onboarding.buttons.no_invite')}
				title={t('onboarding.title.demo_mode')}
				text={t('onboarding.confirm.demo_mode')}
				buttons={<EitjeButton iconLeft="cross-small" onClick={saveSetting} t="onboarding.buttons.no_invite" />}
			>
				{children}
			</Wrapper>
		</PopoutCard>
	)
}
