import {makeLegacyField, useFormField} from '@eitje/form'
import {handleSuccessVideoUpload, handleVideoRecordRemoval, _vidUpload} from 'actions/files'
import {Progress} from 'antd'
import {DeleteButton} from 'common/components'
import {store} from 'index'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {newCurrentOrgSelector} from 'selectors/records'

const useVideoUpload = (inputRef = {current: null}, onChange = () => null, blockSubmit = () => null, selectedVideo) => {
	const [progress, setProgress] = useState(0)
	const [filename, setFileName] = useState('')
	const [uploadId, setUploadId] = useState()

	const {id: orgId} = useSelector(newCurrentOrgSelector)
	const afterSelect = async data => {
		blockSubmit(true)
		setFileName(data[0].name)
		const res = await _vidUpload(data, prog => setProgress(Math.abs(prog) - 10), {orgId})
		if (res && res.ok && res.data) {
			setUploadId(res.data.id)
			handleSuccessVideoUpload(res)
			onChange([res.data.id])
			setProgress(100)
			if (inputRef?.current?.value) inputRef.current.value = null // dom html element needs to cleared to allow for reselecting video, that triggers an onChange event
		}
		blockSubmit(false)
	}

	const handleRemove = async () => {
		onChange(null)
		setProgress(0)
		if (uploadId) {
			removeVideo(uploadId)
		}
	}

	return {handleRemove: uploadId && handleRemove, afterSelect, progress, filename, setProgress}
}

const UploadProgress = ({progress = 0}) => {
	if (progress === 0 || progress === 100) return null
	return <Progress percent={progress} showInfo={false} status="active" style={{width: '100px'}} />
}

const VideoPicker = props => {
	const {
		image = true,
		hidden,
		pickVid = {current: null},
		assocRecord = {},
		inputHidden,
		containerStyle = {},
		blockSubmit = () => {},
		viewProps = {},
		required,
		ContainerComponent = 'div',
		error,
		value,
		onChange = () => {},
		onFileChange,
		hideFileList,
		externalFilename,
		mapping = {},
		...rest
	} = props

	const actualInput = useRef(null)
	const {
		afterSelect,
		progress,
		filename,
		handleRemove: handleUploadRemove,
		setProgress,
	} = useVideoUpload(actualInput, onChange, blockSubmit, value)

	const {disabled} = useFormField(props)

	const hasVideoAsset = Array.isArray(value) ? !_.isEmpty(value) : value

	// const title = filename || externalFilename || ''

	const _handleVideoRecordRemoval = async () => {
		if (Array.isArray(value) && value[0]) {
			handleVideoRecordRemoval(value[0], assocRecord, () => {
				onChange(null)
				setProgress(0)
				store.dispatch({type: 'REMOVE_PROCESS_VIDEO', videoId: value[0]})
			})
		}
	}

	// handleUploadRemove will be present if there is an id from the upload, else use the remove association to remove the video from a post
	const removeVideo = handleUploadRemove || _handleVideoRecordRemoval

	const pickVideo = () => !disabled && actualInput.current.click()
	pickVid.current = pickVideo

	return (
		<>
			{(hasVideoAsset || progress > 0) && (
				<ContainerComponent className="eitje-picker-details-container">
					<div className="fRow aCen">
						<img style={{width: 40}} src="/images/videoIcon.png" />
						{/* <p className={'eitje-text-secondary'} style={{marginLeft: '8px'}}>
              {title}
            </p> */}
					</div>

					<div className="fRow jSta aCen">
						<div style={{marginRight: '24px'}}>{progress < 100 && <UploadProgress progress={progress} />}</div>
						<DeleteButton onDel={removeVideo} />
					</div>
				</ContainerComponent>
			)}
			<input
				style={{display: 'none'}}
				ref={actualInput}
				type="file"
				id="single"
				onChange={async e => await afterSelect(e.target.files)}
				accept={'video/*'}
			/>
		</>
	)
}

export default makeLegacyField(VideoPicker, {withlabel: false})
