import {useChatContext} from 'stream-chat-react'
import {useShared} from 'hooks'
import {CHANNEL_TYPES} from 'cores/chat'

const findOtherMember = (members, meId) => {
	return Object.values(members).find(member => member.user_id !== meId)?.user || {}
}

export const useChannelMetaData = channel => {
	const {client} = useChatContext()
	const {multiEnvOrg, multiOrg} = useShared()

	const {data, state} = channel || {}

	if (!data) return {}

	const groupChannel = channel.type === CHANNEL_TYPES.GROUP
	const teamChannel = channel.type === CHANNEL_TYPES.TEAM
	const directChannel = channel.type === CHANNEL_TYPES.DIRECT
	const channelTypes = {groupChannel, teamChannel, directChannel}

	const {name, image, env_name} = data
	const envName = (multiEnvOrg || multiOrg) && env_name

	if (teamChannel) {
		let title = `Team "${name}"`
		if (envName) title += ` - ${envName}`
		return {title, image, ...channelTypes}
	}

	if (groupChannel) {
		return {title: name, image, ...channelTypes}
	}

	// direct chat
	const otherMember = findOtherMember(state.members, client.userID)
	return {title: otherMember.name, image: otherMember.image, user: otherMember, ...channelTypes}
}
