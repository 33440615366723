import {useEnvTableContext, useFilteredEnvShifts, getPlannedHours} from 'cores/planning/index'

const EnvInfo = () => {
	const {env} = useEnvTableContext()
	const envShifts = useFilteredEnvShifts()
	const {timeString} = getPlannedHours(envShifts)
	return (
		<div className="env-info">
			<img src="/images/web/icons/planning/time.png" className="env-info-icon" />
			<div className="env-info-text">
				<h4>{env.naam} </h4>
				<h5>{timeString}</h5>
			</div>
		</div>
	)
}

export default EnvInfo
