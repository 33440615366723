import {BasicPopout} from '@eitje/web_components'
import {Layout, Text, EitjeButton} from 'common/components'
import {NAMESPACE} from '.'
import {getBaseUrl, useArbitrary} from 'hooks'
import {t, mdT} from 'initializers/i18n'
import {useBillingDone} from 'cores/billing/hooks'
import {navigateModal} from 'actions/routing'

export const WelcomeText = () => {
	const helpCenterUrl = getBaseUrl()
	const popoutNamespace = `${NAMESPACE}.popouts.support_explained`
	const billingDone = useBillingDone()
	const {days_remaining} = useArbitrary('orgStatus') || {}
	const daysRemaining = days_remaining && days_remaining > 0
	const setupBilling = () => navigateModal('/billing/setup')
	const setupBillingText = mdT(`${NAMESPACE}.setup_billing`, {days_remaining})
	const setupDoneText = mdT(`${NAMESPACE}.billing_is_setup`, {days_remaining})

	return (
		<Layout direction="vertical" padding="20 - 12 -" gap={20} width="full">
			{!billingDone && !!daysRemaining && <Text children={setupBillingText} onClick={setupBilling} />}
			{billingDone && !!daysRemaining && <Text children={setupDoneText} />}
			<Text
				t={`${NAMESPACE}.support_explained`}
				PopoutComponent={BasicPopout}
				popoutTitle={t(`${popoutNamespace}.title`)}
				popoutBody={t(`${popoutNamespace}.body`)}
				buttons={
					<EitjeButton t={`${popoutNamespace}.button`} colorSet="color-bordered-fill" baseColor="pink" externalLink={helpCenterUrl} />
				}
			/>
		</Layout>
	)
}
