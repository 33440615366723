import utils from '@eitje/web_utils'
import {Layout} from 'common/components'
import {formatCssVars} from 'helpers'
import {createContext, Fragment, useContext, useEffect, useRef} from 'react'
import './styles/index.less'
import {useTopOffset} from './use_top_offset'

const PageContext = createContext({})
const {Provider} = PageContext

export const PageLayout = ({
	children,
	state,
	name,
	footers,
	headers,
	bodyLayoutProps,
	bodyBottomPadding = 48,
	BodyComponent = Layout,
	unsetPagePadding,
	bodyWidth,
	className,
	...rest
}) => {
	const id = `${name}-page`
	const pageRef = useRef()
	const headerRef = useRef()
	const bodyRef = useRef()
	const footerRef = useRef()
	const topOffset = useTopOffset(pageRef)
	const bodyHeight = `calc(100vh - ${topOffset}px)`
	const layoutClassName = name && _.kebabCase(`${name}-page-layout`)
	const classNames = utils.makeCns('page-layout', layoutClassName, className, unsetPagePadding && 'unset-page-padding')
	const value = {...state, bodyRef}
	const styles = formatCssVars({bodyBottomPadding, 'page-top-offset': topOffset})
	const InnerBodyWrapper = bodyWidth ? Layout : Fragment
	const outerBodyProps = bodyWidth ? {} : bodyLayoutProps
	const innerBodyProps = bodyWidth ? bodyLayoutProps : {}

	return (
		<Provider value={value}>
			<Layout id={id} ref={pageRef} className={classNames} direction="vertical" height={bodyHeight} gap={0}>
				{headers && (
					<Layout ref={headerRef} direction="vertical" className="page-headers" width="100%" gap={0}>
						{headers}
					</Layout>
				)}
				<BodyComponent
					ref={bodyRef}
					grow
					className="page-body"
					direction="vertical"
					horizontal="center"
					gap={0}
					maxWidth="100vw"
					width="100vw"
					relative
					style={styles}
					{...bodyLayoutProps}
					{...rest}
				>
					<InnerBodyWrapper width={bodyWidth} {...bodyLayoutProps}>
						{children}
					</InnerBodyWrapper>
				</BodyComponent>
				{footers && (
					<Layout ref={footerRef} className="page-footers" width="100%" gap={0}>
						{footers}
					</Layout>
				)}
				{state && <StateSaver state={state} />}
			</Layout>
		</Provider>
	)
}

export const usePageContext = () => {
	return useContext(PageContext)
}

const StateSaver = ({state = {}}) => {
	useEffect(() => {
		if (!window.local) window.local = {}
		window.local = {...window.local, ...state}

		return () => {
			window.local = _.omit(window.local, Object.keys(state))
		}
	}, [state])

	return null
}
