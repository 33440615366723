import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import useParams from 'hooks/use_params'
import {useIntegrationConstants} from 'cores/integrations/index'
import {BaseConnection} from 'models/index'
import {DocsButton, Layout, Message} from 'common/components'
import {EitjeModal} from 'components/common/index'
import EitjeButton from 'common/components/eitje_button'
import {goBackToForeground} from 'actions/routing'
import {history} from 'index'

const testIssue = {
	title: 'we can not sync',
	created_at: '2022-01-01T13:00:00',
}

const prefix = `integrations.pos.provider_issue_modal`

export const Issues = () => {
	const {provider_name} = useParams()
	const connection = BaseConnection.show()
	let {last_issue, pending_issue_count} = connection
	const {issuesLink} = useIntegrationConstants(provider_name)

	const docsBtn = <DocsButton to={issuesLink} />

	if (!last_issue) {
		goBackToForeground()
	}

	return (
		<EitjeModal
			name="integration_issues"
			insertAtIndex={{0: docsBtn, 1: <ResetBtn connectionId={connection.id} name={provider_name} />, 2: <SyncBtn item={connection} />}}
			hideCancel
			className="provider-issues-modal"
			width={600}
			title={t(`${prefix}.title`)}
		>
			<Layout padding={12}> {last_issue && <Issue item={connection} count={pending_issue_count} {...last_issue} />} </Layout>
		</EitjeModal>
	)
}

const Issue = ({title, created_at, count, item}) => {
	const {last_sync_datetime} = item
	return (
		<Layout direction="vertical" width="full" gap={8}>
			<Message margin={0} baseColor="red" colorSet="solid">
				{t('common.error')}: {title}
				{t(`${prefix}.description`, {providerName: item.name})}
			</Message>

			<Message margin={0} colorSet="black-bordered">
				{!!last_sync_datetime && t(`${prefix}.last_succeeded_on`)}: {date(last_sync_datetime).formatDateTime()}
				<br />
				{t('integrations.failed_attempts')}: {count}
				<br />
				{t('integrations.last_failed_on')}: {date(created_at).formatDateTime()}
			</Message>
		</Layout>
	)
}

const ResetBtn = ({connectionId, name}) => {
	return (
		<EitjeButton iconLeft="arrow-undo" modalLink={`/integrations/${name}/activate?connection_id=${connectionId}`} t="integrations.reset" />
	)
}

const SyncBtn = ({item}) => {
	const click = () => {
		item.sync()
		history.goBack()
	}
	return (
		<EitjeButton color="blue" onClick={click} iconLeft="arrow-circle">
			{t('common.retry')}
		</EitjeButton>
	)
}
