import {useEffect} from 'react'

export const CANNY_CONFIG = {
	appID: '624e9f95b466065285574055',
	position: 'bottom',
	align: 'right',
	theme: 'light',
}

export const CannyRegistrar = () => {
	useEffect(cannySdk, [])

	return null
}

// Canny needs the element to be in the DOM when its initiated
export function setupCanny(open) {
	const action = open ? 'init' : 'close'
	window.Canny?.(`${action}Changelog`, CANNY_CONFIG)
}

function cannySdk() {
	!(function (w, d, i, s) {
		function l() {
			if (!d.getElementById(i)) {
				var f = d.getElementsByTagName(s)[0],
					e = d.createElement(s)
				;(e.type = 'text/javascript'), (e.async = !0), (e.src = 'https://canny.io/sdk.js'), f.parentNode.insertBefore(e, f)
			}
		}
		if ('function' != typeof w.Canny) {
			var c = function () {
				c.q.push(arguments)
			}
			;(c.q = []),
				(w.Canny = c),
				'complete' === d.readyState ? l() : w.attachEvent ? w.attachEvent('onload', l) : w.addEventListener('load', l, !1)
		}
	})(window, document, 'canny-jssdk', 'script')

	window.Canny('initChangelog', CANNY_CONFIG)
}
