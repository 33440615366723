import {useEffect, createContext, useContext, useMemo} from 'react'
import {usePrevious} from '@eitje/react-hooks'
import {PlanningShift, TimeRegistrationShift, CheckIn, RevenueDay} from 'models/index'
import {useTimeRegistrationDates, useFirstRegDate} from 'cores/time_registration/index'
import {store} from 'index'
import {API} from '@eitje/easy_api'

const TimeRegistrationContext = createContext({})
const {Provider} = TimeRegistrationContext

const TimeRegistrationProvider = ({children}) => {
	const firstRegDate = useFirstRegDate()
	const {dateRange, setDates, startDate, dateArray, endDate} = useTimeRegistrationDates()
	const _value = {dateRange, setDates, startDate, firstRegDate, dateArray, endDate}
	const value = useMemo(() => _value, [startDate, endDate])

	const previousStartDate = usePrevious(startDate)
	useEffect(() => getTimeRegData({startDate, previousStartDate, endDate}), [startDate.format(), endDate.format()])

	return <Provider value={value}> {children} </Provider>
}

const getTimeRegData = async ({startDate, previousStartDate, endDate}) => {
	const startDateString = startDate.format()
	const prevStartDateString = previousStartDate?.format()
	const dateHasChanged = startDateString != prevStartDateString && prevStartDateString
	const filters = {start_date: startDate, end_date: endDate}
	const opts = {refresh: !!dateHasChanged}
	const params = {filters, ...opts}
	const planningParams = {filters: {...filters, time_registration: true}, ...opts}
	store.dispatch({type: 'START_LOADING'})

	const payload = [
		{name: PlanningShift.tableName, ...planningParams},
		{name: TimeRegistrationShift.tableName, ...params},
		{name: CheckIn.tableName, ...opts, filters: {...filters, pending: true}},
		{name: RevenueDay.tableName, ...params},
	]

	await API.multiIndex(payload)

	store.dispatch({type: 'STOP_LOADING'})
}

export default TimeRegistrationProvider

export const usePerWeekContext = () => useContext(TimeRegistrationContext)
