import {useSelector} from 'hooks'
import {store} from 'index'

export const useApplicationHeaders = () => {
	return useSelector(state => state.components.applicationHeaders)
}

export const storeApplicationHeaders = headerNames => {
	const currentState = Object.values(useApplicationHeaders())
	const updateState = !_.isEqual(currentState, headerNames)
	if (updateState) {
		store.dispatch({headerNames, type: 'APPLICATION_HEADERS'})
	}
}
