import I18n from 'initializers/i18n'
import utils from '@eitje/web_utils'

const enums =  {
    
   "shift":{
      "standaard":{
         "nl":"Standaard",
         "en":"Standard",
      },
      "stand-by":{
         "nl":"Stand-by",
         "en":"Stand-by",
      },
      "inwerken":{
         "nl":"Inwerken",
         "en":"First day",
      },
      "openen":{
         "nl":"Openen",
         "en":"Open",
      },
      "tot-nodig":{
         "nl":"Tot nodig",
         "en":"Until necessary",
      },
      "tot-sluit":{
         "nl":"Tot sluit",
         "en":"Until the end",
      },
      "reddende_engel":{
         "nl":"Reddende engel",
         "en":"Lifesaver",
      },
      "overig":{
         "nl":"Overig",
         "en":"Other",
      },
      "leidinggevende":{
         "nl":"Leidinggevende",
         "en":"Manager",
      }
   },
   "event":{
      "sport":{
         "nl":"Sport",
         "en":"Sport",
      },
      "meeting":{
         "nl":"Meeting",
         "en":"Meeting",
      },
      "training":{
         "nl":"Training",
         "en":"Training",
      },
      "feest":{
         "nl":"Feest",
         "en":"Party",
      },
      "overig":{
         "nl":"Overig",
         "en":"Other",
      },
   },
   "reg_shift":{
      "gewerkte_uren":{
         "nl":"Gewerkte uren",
         "en":"Hours worked",
      },
      "verlof":{
         "nl":"Verlof",
         "en":"Leave recorded",
      },
      "bijzonder_verlof":{
         "nl":"Bijzonder verlof",
         "en":"Special leave",
      },
      "ziek":{
         "nl":"Ziek",
         "en":"Sick",
      },
   }
}

export function getEnum(className, val) {
  try {
  let loc = I18n.language
  let text = enums[className][val][loc]
  return text ? text : utils.capitalize(val)
}  catch(e) {
  return utils.capitalize(val);
}
}

export function getEnums(className) {
  const loc = I18n.language
  const arr = []

  Object.keys(enums[className]).forEach(kind => {
    const val = getEnum(className, kind)
    arr.push({id: kind, naam: val})
  })
  
  return arr;
}

export default enums;
