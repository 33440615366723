import {PopoutCard} from '@eitje/web_components'
import {Message, DocsButton, goToNext, Layout} from 'common/components'
import {useModalContext} from 'components/common/index'
import {ModalForm} from 'components/form/index'
import {ExtRevenueGroup} from 'models'
import {t} from 'initializers/i18n'
import {ListPicker} from 'common/components'
import {FormRow} from 'components/ui'

const items = ['groups', 'sales_areas']

const GroupOrZone = props => {
	const {item} = useModalContext()
	const groups = ExtRevenueGroup.where({integration_ext_environment_id: item.ext_environment_id})
	const disabled = groups.some(g => g.omzet_groep_id)
	const insertAtIndex = {
		0: <DocsButton to="8166077" />,
	}

	const _items = items.map(type => ({id: type, name: t(`integrations.pos.${type}`)}))

	return (
		<ModalForm
			transNamespace="group_or_zone"
			afterSubmit={() => goToNext(props)}
			initialValues={item}
			modalProps={{insertAtIndex, confirmSubmit: true}}
			onSubmit={data => item.update(data)}
		>
			<Layout name="modals.setup_revenue.group_or_zone" direction="vertical" gap={0}>
				<Message t="message" baseColor="blue" colorSet="color-bordered-fill" />
				<FormRow>
					<PopoutCard hidden={!disabled} title={t('modals.setup_revenue.group_or_zone.unmap_first')}>
						<ListPicker
							className={disabled && 'disabled'}
							dropdown
							hideClearIcon
							required
							disabled={disabled}
							single
							field="grouping_type"
							items={_items}
						/>
					</PopoutCard>
				</FormRow>
			</Layout>
		</ModalForm>
	)
}

export default GroupOrZone
export * from './progress'
