export const fontWeights = {
	thin: 100,
	extraLight: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
}

export const MINI_BUTTON_HEIGHT = 24
export const SMALL_BUTTON_HEIGHT = 32
export const LARGE_BUTTON_HEIGHT = 40
export const XLARGE_BUTTON_HEIGHT = 48
