import ActiveRecord from 'models/active_record/base'
import _ from 'lodash'

class TradeRequest extends ActiveRecord {
	static tableName = 'tradeRequests'

	accordable = () => {
		return this.bieder_ids.length > 0
	}

	approve = async (userId, afterSucc = _.noop) => {
		const res = await this.update({published: true, ruilee_id: userId})
		if (res.ok) {
			afterSucc(userId)
			return res
		}
	}
}

export default TradeRequest
