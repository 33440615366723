import utils from '@eitje/web_utils'

export const MoneyCell = ({getValue}) => {
	return utils.formatMoney(getValue())
}

export const MoneyCellWithoutZero = ({getValue}) => {
	const value = getValue()
	if (!value) return null
	return utils.formatMoney(value)
}
