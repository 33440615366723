import {useUserContext} from 'contexts/user'
import {WeekTableCell, EitjeAvatar} from 'common/components'

// calculate the extra margin so that the cell contents don't jump when toggling the checkbox
const AVATAR_SIZE = 28
const CHECKBOX_SIZE = 16
const CHECKBOX_MARGIN = (AVATAR_SIZE - CHECKBOX_SIZE) / 2
const STYLE = {marginLeft: CHECKBOX_MARGIN, marginRight: CHECKBOX_MARGIN}

export const UserRowPanel = ({multiEdit, ...rest}) => {
	// Use a decider to only run the slow useEditableShifts function when the multi edit mode is toggled on
	return multiEdit ? <MultiEditPanel {...rest} /> : <AvatarPanel {...rest} />
}

const MultiEditPanel = ({useEditableShifts, MultiActionCheckbox, ...rest}) => {
	const {shifts} = useUserContext() // This panel depends on a week table row being wrapped within a UserContext
	const filteredShifts = useEditableShifts(shifts)
	const leftContentProps = {shifts: filteredShifts, style: STYLE}

	return <BasePanel LeftContent={MultiActionCheckbox} leftContentProps={leftContentProps} {...rest} />
}

const AvatarPanel = props => {
	const {user} = useUserContext() // This panel depends on a week table row being wrapped within a UserContext
	const leftContentProps = {user, radius: 28}

	return <BasePanel LeftContent={EitjeAvatar} leftContentProps={leftContentProps} {...props} />
}

const BasePanel = ({PanelDropdown, ...rest}) => {
	const {user} = useUserContext()
	return <WeekTableCell cellType="userTile" columnType="panel" displayName={user.full_name} Wrapper={PanelDropdown} {...rest} />
}
