import React, {useEffect, useState, Fragment, useCallback, useRef} from 'react'
import {DropdownPicker, BaseInput as Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import useGooglePlacesAutocomplete, {getGeocode, getZipCode, getLatLng} from 'use-places-autocomplete'
import {useNewOutsideClick} from 'hooks/use_outside_click'
import './styles/use_places_autocomplete.less'
import _ from 'lodash'

const placeMap = {
	city: 'locality',
	country: 'country',
	house_number: 'street_number',
	postal_code: 'postal_code',
	street: 'route',
}

const defaultNeededComponents = Object.values(placeMap)

const usePlacesAutocomplete = (props = {}) => {
	const {inputProps, strict = true, neededComponents = defaultNeededComponents, initialValues = {}} = props
	const [placesData, setPlacesData] = useState(initialValues)

	const {
		ready,
		value,
		suggestions: {status, data},
		setValue,
		clearSuggestions,
		...rest
	} = useGooglePlacesAutocomplete()

	useEffect(() => {
		if (initialValues.formatted_address) {
			setValue(initialValues.formatted_address, false)
		}
	}, [])

	const inputEl = (
		<Input
			validate={val => ({valid: utils.exists(placesData), message: 'addressRequired'})}
			onChange={setValue}
			disabled={!ready}
			{...inputProps}
			value={value}
			placeholder={t('form.billing.placeholder.address')}
		/>
	)
	const suggestionsEl = status == 'OK' && (
		<Suggestions
			strict={strict}
			data={data}
			onClick={suggestion => handleSelect({...suggestion, neededComponents, setValue, onSubmit: setPlacesData, clearSuggestions})}
		/>
	)

	useNewOutsideClick({onClick: clearSuggestions, parentClass: 'google-places'})

	const placesInput = (
		<div fieldWrapper className="google-places">
			{inputEl}
			{suggestionsEl}
		</div>
	)

	return {placesInput, value, placesData}
}

const goodTypes = ['street_address', 'premise', 'establishment']

const Suggestions = ({data, strict, onClick}) => {
	let allowed
	if (strict) {
		allowed = data.filter(d => d.terms.length >= 5 || utils.intersects(d.types, goodTypes))
		if (allowed.length == 0) return null
	} else {
		allowed = data
	}
	return (
		<div className="google-places-suggestions">
			{allowed.map(d => (
				<Suggestion data={d} onClick={() => onClick(d)} />
			))}
		</div>
	)
}

const Suggestion = ({data, onClick}) => {
	const {
		place_id,
		structured_formatting: {main_text, secondary_text},
	} = data
	return (
		<div className="google-location" onClick={onClick} key={place_id}>
			<strong>{main_text}</strong>
			<small>{secondary_text}</small>
		</div>
	)
}

const handleSelect = async ({description, neededComponents, onSubmit, clearSuggestions, setValue}) => {
	const geocodeResults = await getGeocode({address: description})
	const geocodeResult = geocodeResults[0]
	const addressComponents = geocodeResult.address_components.filter(a => utils.intersects(a.types, neededComponents))
	if (addressComponents.length < neededComponents.length) {
		utils.errorNotif({content: t('form.validation.address_picker.incomplete')})
		return
	}

	const mapped = _.mapValues(placeMap, v => addressComponents.find(a => a.types.includes(v))?.long_name)
	const coords = await getLatLng(geocodeResult)
	onSubmit({coords, geocodeResult, formatted_address: geocodeResult.formatted_address, ...mapped})
	setValue(geocodeResult.formatted_address, false)
	clearSuggestions()
}

export default usePlacesAutocomplete
