import {usePlanningContext, useEnvTableContext, useEnvTeams} from 'cores/planning/index'
import {Association, LeaveRequest, SickPeriod, TimeRegistrationShift} from 'models'
import {filterItems} from 'hooks/use_search'
import {contractJoins as opts} from 'constants/users'
import utils from '@eitje/web_utils'
import User from 'models/user'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {useSearchContext} from 'contexts/search'

const useFilteredEnvShifts = () => {
	const envTeams = useEnvTeams()
	const {filteredItems: shifts} = useFilterFooterContext()
	const filteredEnvShifts = shifts.where({team_id: envTeams._map('id')})
	return filteredEnvShifts
}

export const useDayShifts = date => {
	const {filteredItems: filteredShifts} = useFilterFooterContext()
	const dayShifts = filteredShifts.where({date})
	return dayShifts
}

const useImproductiveShifts = () => {
	const {dateRange} = usePlanningContext()
	const {env} = useEnvTableContext()
	const envUserIds = User.where({environment_ids: env.id}).ids()

	const leaveRequests = LeaveRequest.where({dateRange, user_id: env.user_ids, accepted: true})
	const sickPeriods = SickPeriod.where({dateRange, user_id: envUserIds})
	const timeShifts = TimeRegistrationShift.unproductive({date: dateRange, user_id: env.user_ids})

	return new Association([...leaveRequests, ...sickPeriods, ...timeShifts])
}

export const useImproductiveUserShifts = user => {
	const {dateRange} = usePlanningContext()

	const leaveRequests = LeaveRequest.where({dateRange, accepted: true, user_id: user.id})
	const sickPeriods = SickPeriod.where({dateRange, user_id: user.id})
	const timeShifts = TimeRegistrationShift.unproductive({date: dateRange, user_id: user.id}, opts)
	return new Association([...leaveRequests, ...sickPeriods, ...timeShifts])
}

const useFilteredImproductiveShifts = items => {
	const {search} = useSearchContext()
	const {
		filterValues: {teamIds},
	} = useFilterFooterContext()

	const filteredByTeams = items.filter(i => utils.intersects(i.user?.team_ids, teamIds))
	const filteredByUserName = filterItems(filteredByTeams, search, {searchField: 'user.full_name'})
	return filteredByUserName
}

const useFavorHourShiftOverItem = (dayItems, dayShifts) => {
	const userIdsWithHours = dayShifts.map(o => o.user_id).uniq()
	const filteredDayItems = dayItems.filter(item => !userIdsWithHours.includes(item.user_id))
	return [...filteredDayItems, ...dayShifts]
}

export const useFilteredImproductiveDayShifts = (items, date) => {
	const dayItems = items?.where({dateRange: date}) || []
	const dayShifts = items?.where({date}) || []

	const filteredDoubleEntries = useFavorHourShiftOverItem(dayItems, dayShifts)
	const filteredBySearch = useFilteredImproductiveShifts(filteredDoubleEntries)
	return filteredBySearch
}

export {useImproductiveShifts, useFilteredImproductiveShifts, useFavorHourShiftOverItem}

export default useFilteredEnvShifts
