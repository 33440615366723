import React, {useEffect, useRef} from 'react'
import Form from 'initializers/form'
import {LegacyMultiForm as MultiForm} from '@eitje/form'
import {LegacyInput as Input, LegacyTimePicker as TimePicker} from '@eitje/form-fields-web'
import {Button} from 'components/ui'
import utils from '@eitje/web_utils'
import {API} from '@eitje/easy_api'
import {makeRange} from 'helpers/date'
import {date} from 'initializers/date'
import {useAvFormContext} from 'cores/availability/components/form/availability_form_provider'
import useEnvSetting from 'hooks/use_env_setting'
import '../../styles/availability.less'
import {t} from 'initializers/i18n'

const AvForm = ({picker, partly, innerRef, mode, avUser, initialValues = {}}) => {
	const newDayStart = useEnvSetting('uren_reg', 'new_day_start')
	const {kind, setFormItem, selectedDates} = useAvFormContext()
	const multiForm = useRef(null) // hacks needed to make multiform as child of form possible.. Let's try to make an HOC that does this automagically
	const isAvailable = mode == 'available'

	useEffect(() => {
		setFormItem(_.omit(avUser, 'secondaryStatus'))
	}, [])

	const remarksRequired = useEnvSetting('beschikbaarheid', 'comment_required') && !isAvailable

	return (
		<Form
			identityField="user_id"
			afterChange={(field, item, newFields) => setFormItem(newFields)}
			initialValues={{...avUser, ...initialValues}}
			onSubmit={data => submitAv(kind, data, selectedDates, isAvailable)}
			ref={innerRef}
		>
			<div className="form-row" fieldWrapper>
				{picker}
				<Input bordered={false} style={{width: 300}} field="remarks" required={remarksRequired} />
			</div>

			{partly && (
				<MultiForm
					hideControls
					initialValues={avUser.timeBlocks}
					allowEmpty={false}
					ref={multiForm}
					amtForms={avUser.timeBlocks ? avUser.timeBlocks.length || 1 : 1}
					maxForms={3}
					field="timeBlocks"
				>
					{({addForm, removeForm, isLast, isFirst, ...rest}) => (
						<div className={`av-time-field-container ${isLast && 'border-bottom'} ${isFirst && 'first-form'}`} fieldWrapper>
							<p className="av-time-field-label"> Onbeschikbaar van/tot </p>
							<div className="av-time-field-picker-container" fieldWrapper>
								<TimePicker
									labelVisible={false}
									validate={(value, props) => validateTimeBlocks(value, props, newDayStart)}
									required
									placeholder={t('selectTime')}
									field="from"
								/>
								<TimePicker
									labelVisible={false}
									validate={(value, props) => validateTimeBlocks(value, props, newDayStart)}
									required
									placeholder={t('selectTime')}
									field="till"
								/>
								{(!isLast || !addForm) && (
									<Button onClick={removeForm}>
										<span className="rotate">+</span>
									</Button>
								)}
								{addForm && isLast && <Button onClick={addForm}>+</Button>}
							</div>
						</div>
					)}
				</MultiForm>
			)}
		</Form>
	)
}

const validateTimeBlocks = (value, {fieldProps, getFormData}, newDayStart) => {
	const crossValid = validateCross({value, ...fieldProps})
	if (!crossValid.valid) return crossValid
	return validateDayStart(getFormData(), newDayStart)
}

export const validateDayStart = (formData, newDayStart) => {
	if (!newDayStart) return true
	const {from, till} = formData
	if (!from || !till) return true
	const range = makeAvRange(from, till)
	if (range.days() == 0) return true
	return {valid: range.end.format('HH:mm') <= newDayStart, message: 'new_day_overflow'}
}

const validateCross = ({value, formIdx, getMultiFormData}) => {
	const data = getMultiFormData()
		.filter(d => d.from && d.till)
		.map(d => ({...d, range: makeAvRange(d.from, d.till)}))
	const otherForms = data.filter((d, i) => i != formIdx)
	const myDateTime = date(value)
	return {valid: otherForms.every(f => !f.range.contains(myDateTime)), message: 'av_overlap'}
}

const makeAvRange = (from, till) => {
	if (till < from) till = date(till).add(1, 'day')
	return makeRange(from, till)
}

const buildPayload = ({timeBlocks, ...data}, dates = [], available) => {
	const items = []
	if (!timeBlocks) timeBlocks = [{from: '00:00', till: '23:59'}]
	dates.forEach(d => {
		timeBlocks.forEach(t => items.push(makeAvItem(t, d, data, available)))
	})
	return items
}

const makeAvItem = (timeBlock, _date, data, available) => {
	let {from, till} = timeBlock
	const tillDate = till < from ? date(_date).add(1, 'day').format('YYYY-MM-DD') : _date
	const start_datetime = utils.buildDateTime({date: _date, time: timeBlock.from})
	const end_datetime = utils.buildDateTime({date: tillDate, time: timeBlock.till})
	return {start_datetime, end_datetime, available, ...data}
}

const submitAv = (kind, data, dates, isAvailable) => {
	const avPayload = buildPayload(data, dates, isAvailable)
	return API.arbitrary(kind, 'multi_create', {items: avPayload})
}

export default AvForm
