import {Input} from '@eitje/form-fields-web'
import {useFind} from '@eitje/easy_api'
import {t as baseT} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {t, HourMinPicker, StatusLabel, getPeriodString} from 'cores/leave/index'
import {useSimpleContext} from 'contexts/simple'
import {UserCard} from 'components/shared/index'
import ContractInfo from './contract_info/index'
import {date} from 'initializers/date'
import {Skeleton} from 'lib/form/index'
import './styles/index.less'

const POSITION = [26, 24, '-', '-']

const Page = () => {
	const {request, processed} = useSimpleContext()
	const {total_mins} = request
	const sub = `${t(`kinds.${request.kind()}`)}: ${getPeriodString(request)} `

	return (
		<div className="approve-leave-body">
			<UserCard user={request.user} size="large" Subline={() => sub} />
			<StatusLabel status={request.status()} absolute={POSITION} />

			{!!total_mins && <HourMinPicker readOnly />}

			<div className="eitje-form-2 eitje-form-2-use-borders">
				{!processed && <ContractInfo />}

				{processed && (
					<FormRow>
						<ProcessedBy request={request} />
					</FormRow>
				)}

				<FormRow>
					<Input field="process_reason" />
				</FormRow>
			</div>
		</div>
	)
}

const ProcessedBy = ({request}) => {
	const {processor_id, processed_at} = request
	const user = useFind('users', processor_id)

	return (
		<Skeleton field="processed_by">
			<p>
				{user.full_name} {baseT('on')} {date(processed_at).formatDate({year: true})}
			</p>
		</Skeleton>
	)
}

export default Page
