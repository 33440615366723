import React from 'react'
import {LeaveAdvancedTable} from 'common/components/advanced_table/instances/leave'
import {FilterFooter, PageLayout} from 'common/components'
import SearchProvider from 'contexts/search'
import {PageHeader} from './page_header'
import {LeaveProvider} from 'cores/leave'
import {FilterFooterProvider} from './filter_footer_provider'

const _LeaveIndex = ({}) => {
	return (
		<SearchProvider>
			<FilterFooterProvider>
				<PageLayout name="leave" headers={<PageHeader />} footers={<FilterFooter />}>
					<LeaveAdvancedTable />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}

const Wrapper = () => {
	return (
		<LeaveProvider>
			<_LeaveIndex />
		</LeaveProvider>
	)
}

export const LeaveIndex = React.memo(Wrapper, () => true)
