import {moment} from 'helpers/date'
import {date} from 'initializers/date'
import pluralize from 'pluralize'
import {makeRange} from 'helpers/date'

export const calcInitialDate = fields => {
	const {close_weekday, close_monthday} = fields
	const {isWeekly, isMonthly} = getPeriodData(fields)
	if (isWeekly) return date().nextWeekday(close_weekday)
	if (isMonthly) return date().nextMonthday(close_monthday)
	return date()
}

export const getOpenRange = props => {
	const start = openStart(props).startOf('day')
	let end = openEnd(props, start)
	if (end) end = end.endOf('day')
	if (!end) end = '2050-01-01'
	const range = makeRange(start, end)
	return range
}

export const openStart = props => {
	const {isDaily} = getPeriodData(props)
	const {startDate, endDate} = getPrevClosePeriod(props)
	const date = endDate || startDate
	return date.add(1, 'day')
}

export const openEnd = (props, start = date()) => {
	start = start.clone()
	const {max_periods_future} = props
	const {transCloseKind, isWeekly, isMonthly} = getPeriodData(props)
	const endDate = max_periods_future ? start.add(max_periods_future, transCloseKind) : null
	if (!endDate) return
	if (isWeekly || isMonthly) endDate.subtract(1, 'day')
	return endDate
}

export const getClosePeriod = (props, closeDate) => {
	let startDate
	let endDate
	const {min_periods_future, amt_periods} = props
	const {isWeekly, isDaily, isMonthly} = getPeriodData(props)

	if (isWeekly) {
		startDate = closeDate.clone().add(min_periods_future, 'weeks').startOf('isoWeek')

		endDate = startDate.clone().add(amt_periods, 'weeks').subtract(1, 'day')
	}

	if (isMonthly) {
		startDate = closeDate.clone().add(min_periods_future, 'months').startOf('month')
		endDate = startDate.clone().add(amt_periods, 'months').subtract(1, 'day')
	}

	if (isDaily) {
		startDate = closeDate.clone().add(min_periods_future, 'day').subtract(1, 'day')
	}

	return {startDate, endDate}
}

export const getNextClosePeriod = props => {
	const closeDate = getNextCloseDate(props)
	return getClosePeriod(props, closeDate)
}

export const getPrevClosePeriod = props => {
	const closeDate = prevCloseDate(props)
	return getClosePeriod(props, closeDate)
}

export const getNextCloseDate = props => {
	const {close_kind, min_periods_future} = props
	const {isDaily, isWeekly, isMonthly} = getPeriodData(props)
	if (isDaily) return date().add(1, 'day')
	return nextCloseDate(props)
}

const nextCloseDate = props => {
	const {kind} = getPeriodData(props)
	const {amt_periods, initial_date} = props
	if (initial_date && initial_date instanceof moment) {
		if (initial_date > date()) return initial_date
		if (initial_date.startOf('day') >= date().startOf('day')) return initial_date.add(1, kind)
	}

	const _date = prevCloseDate(props)
	if (!_date) return
	return _date.add(amt_periods, kind)
}

const prevCloseDate = props => {
	const {close_weekday, close_monthday, amt_periods, initial_date} = props
	const {isWeekly, isDaily, kind} = getPeriodData(props)
	if (isDaily) return date().startOf('day')
	const _date = isWeekly ? date().prevWeekday(close_weekday) : date().prevMonthday(close_monthday)
	if (amt_periods < 2) return _date

	const diff = _date.diff(initial_date, kind)
	const unitsToSubtract = diff % amt_periods
	return _date.subtract(unitsToSubtract, kind)
}

const transCloseKinds = {daily: 'days', weekly: 'weeks', monthly: 'months'}

export const getPeriodData = ({close_kind = 'daily'}) => {
	const transCloseKind = transCloseKinds[close_kind]
	const singularTransKind = pluralize.singular(transCloseKind)
	const isDaily = close_kind == 'daily'
	const isWeekly = close_kind == 'weekly'
	const isMonthly = close_kind == 'monthly'
	return {isDaily, isWeekly, isMonthly, transCloseKind, kind: transCloseKind, singularTransKind}
}
