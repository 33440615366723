import {forwardRef} from 'react'
import {WeekTableTileCell} from './tile'
import {WeekTableCardCell} from './card'
import {WeekTableCellItem} from 'common/components'
import {isPlainObject} from 'helpers'

export const WeekTableCell = forwardRef(({cellType = 'card', items, ...rest}, ref) => {
	if (items && !rest.children) {
		rest.children = makeCellItems({items, ...rest})
	}

	switch (cellType) {
		case 'card':
			return <WeekTableCardCell ref={ref} {...rest} />
		case 'tile':
			return <WeekTableTileCell ref={ref} dynamicPaddings {...rest} />
		case 'userTile':
			// PR comment: userTile heeft tot nu toe alleen nog maar wat andere styling, zou uiteindelijk ook zelf de avatar
			// kunnen renderen, maar wilde daarvoor nog even wachten op tweede instance om te bepalen hoe dat het beste in het
			// systeempje kan worden gefietst
			return <WeekTableTileCell ref={ref} {...rest} cellType="userTile" />
	}
})

function makeCellItems({items, baseColor}) {
	return items.filter(Boolean).map(item => {
		// If props are passed as in a plain object, simply spread them into a cell.
		if (isPlainObject(item)) return <WeekTableCellItem {...item} baseColor={baseColor} />

		// If simply passing a string, like in the weekTableHeader, style that as default
		if (_.isString(item)) return <WeekTableCellItem text={item} type="greySubtitle" baseColor={baseColor} />

		// While passing plain objects is preferable, we may sometimes pass a CellItem directly. We do this when it
		// runs hooks or slow functions and thus should be separated from the Cell instance into another component, that is
		// passed conditionally. This way we don't have to build deciders for a cell when adding a single but slow CellItem.
		return item
	})
}
