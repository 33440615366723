import {t} from 'initializers/i18n'
import {UserProfileButton} from 'common/components'
import {User} from 'models'
import {NAMESPACE} from 'cores/chat'

export const StreamUserProfileButton = ({userId}) => {
	const user = User.find(userId)

	// in the chat we show channels from all organisations.
	// if the user is from a different organisation than the current org
	// we are not able to show this user and therefor this button will be disabled
	const disabled = _.isEmpty(user) && t(`${NAMESPACE}.profile_button_disabled`)

	return <UserProfileButton user={user} disabled={disabled} />
}
