import {all, API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import DataDisplay from 'components/general/data_display'
import {CheckMark} from 'components/ui'
import {useShared} from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {envUsers} from 'selectors/entities'
import {roleSelector} from 'selectors/new_settings'
import {SectionHeader} from '../topics/components'
import {DeleteButton, Label, Layout, OptionsButton} from 'common/components'
import EitjeButton from 'common/components/eitje_button'

const headWidth = {
	title: '42',
	draft: '8',
	type: '8',
	updated_at: '13',
	readCount: '13',
	read: '10',
	actions: '8',
}

const dataHeaders = ['title', 'draft', 'type', 'updated_at', 'readCount', 'read', 'actions'].map(str =>
	str === 'title' ? {label: str, width: headWidth[str]} : {label: str, width: headWidth[str], centered: true},
)

const specialRenderCell = (item, field) => {
	switch (field) {
		case 'actions':
			return (
				<div onClick={e => e.stopPropagation()}>
					<OptionsButton body={<ArticleOptionsMenu item={item} />} />
				</div>
			)
		default:
			return renderCell(item, field)
	}
}

const renderCell = (item, field) => {
	const val = _.get(item, field)

	switch (field) {
		case 'title':
			return <div className="row data-display-cell-main">{val}</div>
		case 'draft':
			return <div className="row-with-justify-center">{!item.published && <Label colorSet="solid" baseColor="blue" t="draft" />}</div>
		case 'updated_at':
			return (
				<div className="row-with-justify-center">
					<img src={'/images/web/icons/smallClockIcon.png'} style={{width: '12px', height: '12px', marginRight: '4px'}} />
					{date(val).format("DD MMM 'YY")}
				</div>
			)
		case 'readCount':
			return (
				<div className="row-with-justify-center">
					<img src={'/images/web/icons/smallViewIcon.png'} style={{width: '14px', height: '14px', marginRight: '4px'}} />
					{val}
				</div>
			)
		case 'read':
			return (
				<div className="row-with-justify-center">
					<UserRead item={item} />
				</div>
			)
		case 'type':
			return (
				<div className="row-with-justify-center">
					<p className="eitje-text-secondary">{item.pdf_leading ? 'PDF' : t('article')}</p>
				</div>
			)
	}
	return val
}

const makeArticleDelete = ({topic_resource_id, id}) => {
	const isTopicArticleMenu = !!topic_resource_id
	return {
		delText: isTopicArticleMenu ? 'removeFromTopic' : 'deleteArticle',
		onDel: isTopicArticleMenu ? () => API.destroy('topic_resources', topic_resource_id) : () => API.destroy('infos', id),
	}
}

const ArticleOptionsMenu = ({item}) => {
	const {id, published} = item
	const {delText, onDel} = makeArticleDelete(item)

	const _onDel = async () => {
		await onDel()
		API.index('topics')
	}

	return (
		<Layout direction="vertical">
			<EitjeButton link={`/articles/${item.id}`} t="edit" width="full" />
			{!published && <EitjeButton onClick={() => API.update('info', {id, published: true})} t="publish" width="full" />}
			<DeleteButton t={delText} onDel={_onDel} />
		</Layout>
	)
}

export const UserRead = ({item}) => {
	const {me} = useShared()
	const read = item.readers.find(r => r.user_id == me.id)
	return <CheckMark checked={read} small />
}

// Loose articles Container
export const LooseArticlesContainer = () => {
	let items = useSelector(state => all(state, 'infos'))
	const allEnvUsersIds = useSelector(envUsers).map(user => user.id)
	items = items.map(({readers = [], ...article}) => ({
		...article,
		readers,
		readCount: utils.sum(
			readers.filter(reader => allEnvUsersIds.includes(reader.user_id)),
			'count',
		),
	}))

	const _articles = items.filter(art => !art.topic_id && art.topic_ids && art.topic_ids.length === 0)
	const isManager = useSelector(state => roleSelector(state, 'manger'))

	const articles = _.sortBy(_articles, item => {
		const {title} = item
		return title ? title.toLowerCase() : ''
	})
	const button = <EitjeButton modalLink="/forms/articles" t="createArticle" />

	const placeholderProps = {
		name: 'article',
		animation: 'notebook',
		docsId: '3355101',
		children: isManager && <EitjeButton modalLink="/forms/articles" colorSet="solid" iconLeft="pencil" t="action_button" />,
		collection: true,
		size: 'default',
	}

	return (
		<div className="loose-articles-container">
			<SectionHeader head={t('looseArtLabel')} subText={t('looseArtDescrip')} button={isManager && button} />
			<ArticleDataDisplay
				placeholderProps={placeholderProps}
				items={articles}
				actions={isManager}
				onRowClick={item => history.push(`/articles/${item.id}`)}
			/>
		</div>
	)
}

// Articles display
export const ArticleDataDisplay = ({items, actions, ...rest}) => {
	const history = useHistory()
	const allEnvUsersIds = useSelector(envUsers).map(user => user.id)
	items = items.map(({readers = [], ...article}) => ({
		...article,
		readers,
		readCount: utils.sum(
			readers.filter(reader => allEnvUsersIds.includes(reader.user_id)),
			'count',
		),
	}))
	const cellRender = actions ? specialRenderCell : renderCell
	const headers = actions ? dataHeaders : dataHeaders.filter(obj => obj.label !== 'actions')
	return (
		<DataDisplay
			items={items}
			name="article"
			defSortField="title"
			sortEnabled={false}
			headers={headers}
			hideHeaders={['draft']}
			renderCell={cellRender}
			{...rest}
			onRowClick={item => history.push(`/articles/${item.id}`)}
		/>
	)
}
