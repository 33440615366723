import {Input, Switch, Checkbox, FormRow} from '@eitje/form-fields-web'
import {API} from '@eitje/easy_api'
import {NewModalForm} from 'components/form/new_modal_form'
import {useHasRole, useShared} from 'hooks'
import {ListPicker} from 'common/components'
import {User} from 'models'
import {getTableName} from 'helpers'
import {useEnvSetting} from 'hooks'

export const EnvironmentForm = ({environment}) => {
	const {naam, user_ids, id} = environment
	const envUserIds = User.where(environment.user_ids).ids()
	const city = useEnvSetting('info', 'city', id)
	const initialValues = {naam, user_ids: envUserIds, stad: city}
	const isAdmin = useHasRole('admin', environment.id)
	const {orgEnvs} = useShared()
	const orgUserIds = _.uniq(_.flattenDeep(orgEnvs.map(e => e.user_ids)))
	const orgUsers = User.where(orgUserIds)

	const onSubmit = ({id, user_ids, ...data}) => {
		// Deconstruct user_ids to prevent them from being sent to the back-end!
		const {id: envId} = environment
		API.update('environments', {id: envId, ...data})
	}

	return (
		<NewModalForm variant="grid" onSubmit={onSubmit} initialValues={initialValues} oneFieldPerFormRow>
			<Input field="naam" required disabled={!isAdmin} />
			<ListPicker
				field="user_ids"
				dropdown
				items={orgUsers}
				labelField="full_name"
				title={getTableName('users')}
				showToggleSelectAll={false}
				allowClear={false}
				readOnly
			/>
		</NewModalForm>
	)
}
