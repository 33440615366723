import {useRef, useEffect} from 'react'
import {MessageInput as StreamMessageInput} from 'stream-chat-react'
import {Layout} from 'common/components'

const useFocusOnKeyDown = ref => {
	const windowKeyDown = e => {
		// Skip focusing if the target is an input-type element
		const tagName = e.target.tagName.toLowerCase()
		if (tagName === 'input' || tagName === 'textarea' || tagName === 'select') return

		// Auto-focus the current input when a key is typed
		const isSpecialKeyPress = e.ctrlKey || e.metaKey || e.altKey || e.key === 'Enter'
		if (!isSpecialKeyPress) ref.current?.focus()
	}

	useEffect(() => {
		window.addEventListener('keydown', windowKeyDown)

		return () => {
			window.removeEventListener('keydown', windowKeyDown)
		}
	}, [])
}

export const MessageInput = ({layoutProps, inputProps}) => {
	const ref = useRef()
	useFocusOnKeyDown(ref)

	return (
		<Layout borderTop padding="12 - 12 12" {...layoutProps}>
			<StreamMessageInput focus grow minRows={2} maxRows={6} {...inputProps} textareaRef={ref} />
		</Layout>
	)
}
