import React, {useEffect, useState, Fragment, useMemo, createContext, useContext, useCallback, useRef} from 'react'

const RevenueFooterContext = createContext({})
const {Provider} = RevenueFooterContext

const RevenueFooterProvider = ({children, ...props}) => {
	const val = props
	return <Provider value={val}> {children} </Provider>
}

export const useRevenueFooterContext = () => useContext(RevenueFooterContext)

export default RevenueFooterProvider
