import useSelector from 'hooks/use_selector'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export const useRecordErrors = (tableNames = []) => {
	const errors = useSelector(state => state.recordErrors.errors) || []
	tableNames = tableNames.map(t => utils.camelToSnake(t))
	const relevantErrs = errors.filter(e => tableNames.includes(e.table_name))
	return relevantErrs
}

export default useRecordErrors
