import {WeekTableCell} from 'common/components'
import utils from '@eitje/web_utils'

export const Day = ({date, DayDropdown, dayContent, dayContentLeft, ...rest}) => {
	const dayName = date.format('ddd D MMM')
	const classNames = utils.makeCns(date.isToday() && 'active')

	return (
		<WeekTableCell
			items={dayContent(date)}
			Wrapper={DayDropdown}
			displayName={dayName}
			date={date}
			columnType="day"
			cellType="tile"
			classNames={classNames}
			{...dayContentLeft(date)}
			{...rest}
		/>
	)
}
