import {useWhere} from '@eitje/easy_api'

import {buildDateTime} from 'helpers/date'
import {LeaveRequest} from 'models/index'
import {ContextForm} from '@eitje/form'
import {t, LeftModalFooter} from 'cores/leave/index'
import useFormData from 'hooks/use_form_data'
import {EitjeModal} from 'components/common/index'
import Body from './body/index'
import SimpleProvider from 'contexts/simple'
import {useHasRole} from 'hooks/index'
import {useModalBack} from 'hooks/index'
import {makeDayRange} from 'helpers/date'
import {TourButton} from 'common/components'

const Add = ({context}) => {
	const {isEdit, request} = context
	const onDel = isEdit && !request.isProcessed() ? request.destroy : null
	let subtitle = !isEdit ? null : request.isProcessed() ? 'processed' : 'pending'
	if (subtitle) subtitle = t(`statusses.${subtitle}`)

	return (
		<EitjeModal
			insertAtIndex={{0: <TourButton tourId={455933} />}}
			subtitle={subtitle}
			width={760}
			name="add_leave"
			form
			onDel={onDel}
			LeftFooter={LeftModalFooter}
		>
			<Body />
		</EitjeModal>
	)
}

const Wrapper = ({request = {}, initialValues = {}}) => {
	const {goBack} = useModalBack()
	const isEdit = !!request.id
	const context = {isEdit, request}

	return (
		<ContextForm afterSubmit={goBack} onSubmit={data => submit(data, request)} transNamespace="add_leave" initialValues={initialValues}>
			<InnerWrapper context={context} />
		</ContextForm>
	)
}

const InnerWrapper = ({context}) => {
	const data = useFormData()
	const formData = transformData(data)
	const {user_id, environment_id} = formData
	const hasLeaveRole = useHasRole('verlof', environment_id)

	const record = new LeaveRequest(formData)
	const range = makeLeaveRange(record)
	const schedules = useWhere('work_schedules', {dateRange: range, user_id})
	const _context = {...context, schedules, hasLeaveRole, range, formRecord: record}

	return (
		<SimpleProvider {..._context}>
			<Add context={_context} />
		</SimpleProvider>
	)
}

const makeLeaveRange = record => {
	const {kind, start_datetime} = record
	if (kind == 'single_day') return makeDayRange(start_datetime)
	return record.__dateRange()
}

const transformData = data => {
	data = {...data}
	let {kind, end_datetime, start_datetime, end_time, start_time} = data
	if (kind == 'few_hours') {
		data['start_datetime'] = buildDateTime({date: start_datetime, time: start_time})
		data['end_datetime'] = buildDateTime({date: start_datetime, time: end_time || start_time}) // this is only so the range is 0 minutes if only start_time is present. By default, we then change the end date to next day.
	} else if (kind == 'multiple_days') {
		data['start_datetime'] = buildDateTime({date: start_datetime, time: '00:00'})
		data['end_datetime'] = buildDateTime({date: end_datetime, time: '23:59'})
	} else {
		data['start_datetime'] = buildDateTime({date: start_datetime, time: '00:00'})
	}

	return data
}

const submit = (data, request) => {
	data = transformData(data)

	if (request?.id) return request.update(data)
	return LeaveRequest.create(data)
}

export default Wrapper
