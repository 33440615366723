import _ from 'lodash'
import * as jsUtils from 'utils/jsutils'

const initialState = {
	envs: [],
	teams: [],
	users: [],
	active: null,
	orgs: [],
	activeTeams: [],
	activeEnvs: [],
	setEnvs: false,
	redUrl: null,
	otherOrgEnvs: [],
	skills: [],
	skillSets: [],
	roosterTemplates: [],
	sickPeriods: [],
	verlofVerzoeken: [],
	omzetGroepen: [],
	regShifts: [],
	checkIns: [],
	omzetTeams: [],
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'GET_ENTITIES':
			return {
				...state,
				[action.kind]: action.items || [],
			}

		case 'CREATE_ENTITY':
			return {
				...state,
				[action.kind]: [...state[action.kind], action.item],
			}

		case 'UPDATE_ENTITY':
			const itemz = [...state[action.kind]]
			const item = {...(itemz.find(i => i.id === action.item.id) || {}), ...action.item}
			return {
				...state,
				[action.kind]: jsUtils.findAndReplace(itemz, item),
			}

		case 'DELETE_ENTITY':
			const delItems = [...state[action.kind]].filter(i => i.id !== action.id)
			return {
				...state,
				[action.kind]: delItems,
			}

		case 'ADD_ENTITIES':
			let items = [...state[action.kind]]
			action.items.forEach(i => {
				items = jsUtils.findAndReplace(items, i)
			})
			return {
				...state,
				[action.kind]: items,
			}

		case 'AUTH_SUCCESS':
			return {
				...state,
				setEnvs: true,
			}
		case 'RESET_TEAM_FILTER':
			return {
				...state,
				activeTeams: [],
			}

		case 'RESET_ENV_FILTER':
			return {
				...state,
				activeEnvs: [],
			}

		case 'ACTIVE_TEAM_TOGGLE':
			return {
				...state,
				activeTeams: jsUtils.addOrRemove(state.activeTeams, action.teamId).filter(s => s),
			}

		case 'ACTIVE_ENV_TOGGLE':
			return {
				...state,
				activeEnvs: jsUtils.addOrRemove(state.activeEnvs, action.id).filter(s => s),
			}

		case 'SET_ENV_FILTER':
			return {
				...state,
				activeEnvs: state.activeEnvs.length ? [action.active] : state.activeEnvs,
			}

		case 'SET_ACTIVE_ENV': {
			if (action.envSwitch) {
				return {
					...state,
					activeEnvs: [action.env],
				}
			}
			return state
		}

		case 'ENVIRONMENTS':
			let act = state.active ? action.envs.find(e => e.id === state.active) : action.envs[0]
			if (!act) act = action.envs[0]
			const actOrg = action.orgs.length > 0 ? action.orgs.find(org => org.environment_ids.includes(act.id)) : null
			return {
				...state,
				envs: action.envs,
				active: act?.id,
				actOrg: actOrg,
				orgs: action.orgs,
				activeEnvs: state.setEnvs && actOrg ? [act.id] : state.activeEnvs,
				setEnvs: false,
				otherOrgEnvs: action.other_org_envs,
			}

			break

		case 'USERS':
			window.users = action.users
			return {
				...state,
				users: _.sortBy(action.users, 'naam'),
			}

		case 'TEAMS':
			window.teams = action.teams
			return {
				...state,
				teams: action.teams,
			}
			break

		case 'UPDATE_TEAM':
			return {
				...state,
				teams: state.teams.map(t => (t.id == action.teamId ? {...t, ...action.team} : t)),
			}

		case 'DEL_TEAM':
			return {
				...state,
				teams: state.teams.filter(t => t.id != action.teamId),
			}

		case 'USER_INVITE':
			return {
				...state,
				users: [...state.users, ...action.users],
			}

		case 'CLEAR_REDIRECTED':
			return {
				...state,
				redUrl: null,
			}

		case 'ENV_SWITCH':
			const activeOrg = state.orgs.length > 0 ? state.orgs.find(org => org.environment_ids.includes(action.active)) : null

			return {
				...state,
				active: action.active,
				actOrg: activeOrg,
				activeEnvs: [],
				activeTeams: [],
				redUrl: action.redUrl,
			}

		case 'GET_WEEK_HOURS':
			return {
				...state,
				regShifts: action.shifts,
				checkIns: action.checkIns,
			}

		default:
			return state
	}
}
