import {Route} from 'react-router-dom'
import {Where} from 'cores/dev/pages/perf/where'

import Routing from 'cores/dev/pages/perf/routing/index'
import ReduxStore from 'cores/dev/pages/perf/redux_store/index'
import ApiPerf from 'cores/dev/pages/perf/api/index'
import NewForm from 'cores/dev/pages/new_form'
import ModalTestForm from 'cores/dev/pages/modal_test_form'

const devForegroundRoutes = [
	<Route path="/dev/redux_store" component={ReduxStore} />,
	<Route path="/dev/routing_perf" component={Routing} />,
	<Route path="/dev/api_perf" component={ApiPerf} />,
	<Route path="/dev/where" component={Where} />,
	<Route path="/dev/form" component={NewForm} />,
]

const devBackgroundRoutes = [<Route path="/dev/modal_form" component={ModalTestForm} />]

export {devForegroundRoutes, devBackgroundRoutes}
