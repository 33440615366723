import {t as baseT} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export const getPeriodString = item => {
	const {start_datetime, end_datetime} = item
	const startDate = utils.humanYearDate(item.startDate())
	const kind = item.kind()

	const params = {kind: 'empty', from: start_datetime, till: end_datetime}

	if (kind == 'multiple_days') return baseT('fromTill', params)
	if (kind == 'single_day') return startDate
	return baseT('fromTillDateTime', params)
}

export const formMapper = req => {
	const kind = req.kind()
	return {...req, kind, start_time: req.startTime(), end_time: req.endTime()}
}
