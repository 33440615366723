import {Text} from 'common/components'
import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/planning/pages/index/week_table_days_header'
import {makeRange} from 'helpers/date'
import {useRoleMessage} from 'hooks'

export function useDaysHeaderMultiEdit({date, shifts, setMultiEdit, setSelectedShifts, role, namespace}) {
	shifts = shifts.where({date})
	const shiftIds = shifts._map('id')

	const onClick = () => {
		setSelectedShifts(shiftIds)
		setMultiEdit(true)
	}

	const element = <Text t={`${NAMESPACE}.multi_edit_day_shifts`} />
	const disabled = useRoleMessage({roles: role}) || (_.isEmpty(shifts) && t(`${namespace}.disabled.no_shifts_to_select`))

	return {icon: 'pencil', element, disabled, onClick}
}
