import {WeekTableRow} from 'common/components'
import {usePlanningContext} from 'cores/planning'
import {Column} from './column'
import {Panel} from './panel'
import {useLocalValue} from 'hooks'

export const EventRow = ({envIds}) => {
	const {dateRange, dateArray} = usePlanningContext()
	const show = useLocalValue('planningShowEvents')

	if (!show) return null

	return (
		<WeekTableRow
			className="event-row"
			Panel={Panel}
			Day={Column}
			dateArray={dateArray}
			minHeight={48}
			envIds={envIds}
			marginTop={-1}
			initialBackground={Colors.white}
		/>
	)
}
