import utils from '@eitje/utils'
import {Text} from '../text'
import {makeCnVariants} from 'helpers'
import './styles/index.less'
import {Layout} from '../layout'

export const Label = ({colorSet = 'solid', ...rest}) => {
	const hasText = rest.children || rest.t
	const padding = hasText ? [2, 8] : 0

	return (
		<Layout
			className="label"
			colorSet={colorSet}
			vertical="center"
			width={!hasText ? 12 : undefined} // explicit undefined is needed for correct width
			height={24}
			padding={padding}
			{...rest}
		>
			{hasText && <Text small {...rest} />}
		</Layout>
	)
}
