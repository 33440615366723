import _ from 'lodash'
import {Checkbox} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {makeField} from '@eitje/form'
import useLocal from 'hooks/use_local'
import {parseT} from 'initializers/i18n'
import './styles/buttons.less'
import {Layout} from 'common/components'

export const HoverButton = ({size = '', ...props}) => {
	return <div {...props} className={`hover-button ${size}`} />
}

export const RoundedButton = ({imgSrc}) => {
	return (
		<div className="rounded-button">
			<img src={imgSrc} />
		</div>
	)
}

export const CheckboxButton = ({onChange = _.noop, afterChange = _.noop, t, value, disabled, textClassName = '', children, ...rest}) => {
	value = rest.hasOwnProperty('checked') ? rest.checked : value
	const containerClass = utils.makeCns('checkbox-button', value && 'active')
	const textClass = utils.makeCns('checkbox-button-text', textClassName)
	return (
		<div
			onClick={() => {
				onChange(!value)
				afterChange(!value)
			}}
			className={containerClass}
		>
			<Checkbox checked={value} disabled={disabled} />
			<div className={textClass}>{parseT(t) || children}</div>
		</div>
	)
}

export const LocalCheckboxButton = ({kind, t, namespace, disabled, ...props}) => {
	const {toggle, value} = useLocal(kind)
	const onChange = disabled ? () => null : toggle

	if (!t) t = `${namespace}.${_.snakeCase(kind)}`
	return <CheckboxButton {...props} t={t} checked={value} onChange={onChange} disabled={disabled} />
}

export const FormCheckboxButton = makeField(CheckboxButton, {className: 'eitje-checkbox-button'})

export const PlusFab = props => {
	return (
		<div {...props} className="plus-float-action-button">
			<svg xmlns="http://www.w3.org/2000/svg" width="20" fill="white" height="20" viewBox="0 0 25 25">
				<path fill="white" d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
			</svg>
		</div>
	)
}

// This can be removed once the message component is merged in
export const ButtonMessageBox = ({text}) => {
	return (
		<div className="button-message-box">
			<p>{text}</p>
		</div>
	)
}
