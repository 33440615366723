import React, {useRef} from 'react'
import TimeRegistrationProvider from './time_registration_provider'
import {
	usePerWeekContext,
	useUnfilteredShifts,
	statusFilterObject,
	hoursFilterObject,
	exportedFilterObject,
} from 'cores/time_registration/index'
import Body from './body/index'
import RevenueFooter from './revenue_footer/index'
import {useMultiEdit, useOnScreen} from 'hooks'
import SearchProvider from 'contexts/search'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import {WeekTableDaysHeader} from './week_table_days_header'
import {PageHeader} from './page_header/index'
import {Layout} from '@eitje/web_components'
import withScrolling from 'react-dnd-scrolling'

export * from './body'

const _ScrollContainer = withScrolling(Layout)
const ScrollContainer = ({children}) => {
	return (
		<_ScrollContainer
			className="scroll-container"
			width="full"
			height="full"
			vertical="start"
			style={{overflow: 'auto scroll'}}
			direction="vertical"
			gap={0}
			relative
			children={children}
		/>
	)
}

const _PerWeek = () => {
	const {dateRange} = usePerWeekContext()
	const filters = [
		'environment',
		'team',
		statusFilterObject,
		hoursFilterObject('common.team_members'),
		exportedFilterObject('common.exported'),
	]
	const items = _.flatten(_.values(useUnfilteredShifts({dateRange})))
	const multiEditProps = useMultiEdit()

	return (
		<div className="time-registration-per-week-page time-registration-page">
			<SearchProvider>
				<FilterFooterProvider filters={filters} items={items} role="uren_schrijven" id="time_registration">
					<PageLayout
						name="hours-per-week"
						state={multiEditProps}
						headers={[<PageHeader />, <WeekTableDaysHeader />]}
						footers={<FilterFooter />}
					>
						<ScrollContainer>
							<Body />
							<RevenueFooterContainer />
						</ScrollContainer>
					</PageLayout>
				</FilterFooterProvider>
			</SearchProvider>
		</div>
	)
}

const RevenueFooterContainer = () => {
	const ref = useRef()
	const visible = useOnScreen(ref)

	return (
		<Layout ref={ref} width="100%" minHeight={100} childrenGrow>
			{visible && <RevenueFooter />}
		</Layout>
	)
}

const Wrapper = () => {
	return (
		<TimeRegistrationProvider>
			<_PerWeek />
		</TimeRegistrationProvider>
	)
}

function areEqual() {
	return true
}

export const PerWeek = React.memo(Wrapper, areEqual)
