import {t} from 'initializers/i18n'

const Days = ({dates}) => {
	return (
		<div className="revenue-modal-column revenue-modal-days">
			<h4 className="revenue-modal-header"> {t('common.date')} </h4>
			{dates.map(d => (
				<DateItem date={d} />
			))}
		</div>
	)
}

const DateItem = ({date}) => {
	return <div className="revenue-modal-day revenue-modal-cell">{date.format('dd DD/MM/YYYY')}</div>
}

export default Days
