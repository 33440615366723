import {columns} from './columns'
import {getColumnIds, getDefaultVisibleIds} from 'common/components/advanced_table'

// Don't inherit from the users_in_service AdvancedTable instance that these columns should
// be shown by default.
const EXTRA_DEFAULT_HIDDEN_COLUMNS = ['active_contract', 'contract_type', 'contract_hours']

export const TABLE_INFO = {
	fields: getColumnIds(columns),
	defaultVisibleColumns: getDefaultVisibleIds(columns).filter(column => !EXTRA_DEFAULT_HIDDEN_COLUMNS.includes(column)),
	tableName: 'users_out_of_service',
	recordType: 'user',
}
