import {FormRow} from 'components/ui'
import useFormData from 'hooks/use_form_data'
import React, {useCallback} from 'react'

const Page = ({formFields}) => {
	const {columns = []} = useFormData()

	const formList = formFields()

	const ConditionalFormRow = useCallback(
		({field, condition, children}) => {
			if ((_.isBoolean(condition) && !condition) || !columns.includes(field)) return null
			return <FormRow>{children}</FormRow>
		},
		[columns],
	)

	return (
		<>
			{formList.map(field => (
				<ConditionalFormRow field={field.field} condition={field.condition}>
					{field.Component}
				</ConditionalFormRow>
			))}
		</>
	)
}

export default Page
