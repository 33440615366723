import {useEffect, useRef, useState} from 'react'
import {Icon} from '@eitje/web_components'
import {Layout} from '../layout'
import {Text} from '../text'
import './styles/index.less'

const PADDING = [4, 8]

export const Message = ({margin = 8, t, children, colorSet = 'black-bordered', icon, textProps = {}, ...rest}) => {
	const [messageHeight, setMessageHeight] = useState(0)
	const layoutRef = useRef()
	const isMarginArray = _.isArray(margin)
	const _margin = 2 * (isMarginArray ? margin[1] : margin)
	const multiLine = messageHeight > 32

	useEffect(() => {
		setMessageHeight(layoutRef.current?.clientHeight)
	}, [children])

	return (
		<Layout
			direction={multiLine && icon ? 'vertical' : 'horizontal'}
			ref={layoutRef}
			horizontal="center"
			className="message"
			colorSet={colorSet}
			padding={PADDING}
			margin={margin}
			style={{
				'--margin': _margin,
			}}
			{...rest}
		>
			{icon && <Icon name={icon} />}
			<Text t={t} {...textProps}>
				{children}
			</Text>
		</Layout>
	)
}
