import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'

class ExtEnvironment extends ActiveRecord {
	static defaultJoins = ['environments', 'integrationExtRevenueGroups', 'integrationBaseConnections']
	static tableName = 'integrationExtEnvironments'

	static extRevenueGroups(items) {
		return items._map('integrationExtRevenueGroups').flat().uniq('id').filter(Boolean) || []
	}

	isPos() {
		return this.integrationBaseConnection?.isPos()
	}

	renumerationTypeTranslation() {
		const {renumeration_period} = this
		if (renumeration_period == 'month') return t('common.monthly')
		if (renumeration_period == 'week') return t('common.weekly')
		return t('common.4_weekly')
	}
}

export default ExtEnvironment
