import React, {Fragment} from 'react'
import {useWhere, useFind} from '@eitje/easy_api'
import useFormData from 'hooks/use_form_data'
import useSearch from 'hooks/use_search'
import AmtWorked from 'cores/time_registration/pages/add/amt_worked'
import {useNoTeamMembersPlaceholder} from 'shared'
import {ListPicker} from 'common/components'
import {EitjeAvatar} from 'common/components'

const searchInputheight = 84
const searchEmptyProps = {size: 'mini', layoutProps: {margin: 0, height: `calc(100% - ${searchInputheight}px`}}

const Page = () => {
	const {environment_id} = useFormData()
	const {onroosterbaar_user_ids} = useFind('environments', environment_id)
	const users = useWhere('users', {environment_ids: environment_id}).filter(u => !onroosterbaar_user_ids.includes(u.id))
	const teamPlaceholderProps = useNoTeamMembersPlaceholder(searchEmptyProps)

	const searchProps = {
		searchField: 'full_name',
		searchEmptyProps,
		wrapperClassName: 'search-input-container',
		placeholderProps: teamPlaceholderProps,
	}

	const {searchInput, emptyElement, filteredItems} = useSearch(users, searchProps)
	return (
		<div className="user-picker">
			{searchInput}
			{filteredItems.length == 0 && emptyElement}

			<ListPicker
				decorated={false}
				required
				virtual={users.length > 20}
				items={filteredItems}
				field="user_id"
				ListItemContent={UserRow}
				listItemProps={{
					layoutProps: {
						height: 60,
					},
				}}
				single
				showSearch={false}
			/>
		</div>
	)
}

const UserRow = ({item}) => {
	return (
		<Fragment>
			<EitjeAvatar user={item} />
			<div className="user-picker-user-info">
				<h5>{item.full_name} </h5>
				<AmtWorked user={item} />
			</div>
		</Fragment>
	)
}

export default Page
