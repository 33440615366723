import utils from '@eitje/web_utils'
import {ContextForm} from '@eitje/form'
import ColumnPicker from './column_picker'
import Columns from './columns'
import {t} from 'initializers/i18n'
import './styles/index.less'
import SimpleContextWrapper from 'contexts/simple'
import {history} from 'index'
import pluralize from 'pluralize'
import {EitjeModal} from 'components/common'

export const Inner = ({type, formFields}) => {
	return (
		<EitjeModal name="multi_edit" className="multi-edit-modal" i18nOpts={{type}} form>
			<div className="multi-edit-hours-form eitje-form-2 eitje-form-2-use-borders">
				<ColumnPicker formFields={formFields} />
				<Columns formFields={formFields} />
			</div>
		</EitjeModal>
	)
}

export const MultiEdit = ({type, submit, formFields}) => {
	const afterSubmit = res => {
		const resp = res.data?.items
		const pluralizedModelName = pluralize(type)
		const items = resp[pluralizedModelName]

		history.goBack()
		utils.successNotif({
			title: t('modals.multi_edit.success_notif.title', {type}),
			content: t('modals.multi_edit.success_notif.body', {count: items?.length, type}),
		})
	}

	return (
		<SimpleContextWrapper type={type}>
			<ContextForm
				untouchOnSubmitFail
				initialValues={{pauze_duur: '00:00', published: true}}
				fieldProps={{i18nOpts: {type}}}
				transNamespace={['shift', 'shift_form']}
				afterSubmit={afterSubmit}
				onSubmit={submit}
			>
				<Inner type={type} formFields={formFields} />
			</ContextForm>
		</SimpleContextWrapper>
	)
}

export default MultiEdit
