import './styles/time_blocks.less'

const TimeBlocks = ({timeBlocks}) => {
	if (timeBlocks.length == 1) return <TimeBlock timeBlock={timeBlocks[0]} />
	return (
		<div className="time-blocks">
			<TimeBlock timeBlock={timeBlocks[0]} />
			<h5 className="time-block-line">|</h5>
			<TimeBlock timeBlock={timeBlocks[1]} />
		</div>
	)
}

const TimeBlock = ({timeBlock}) => {
	const {from, till} = timeBlock
	return (
		<h5 className="time-block">
			{from} - {till}
		</h5>
	)
}

export default TimeBlocks
