import {useLocalValue} from 'hooks'
import {isProd} from 'constants/general'

export function useDebug() {
	const debug = useLocalValue('debugMode')
	return !isProd && debug
}

export function requireProps(errorLabel, requiredProps) {
	_.map(requiredProps, (value, key) => {
		if (!value) {
			throw new Error(`${errorLabel}: required prop '${key}' was not given!`)
		}
	})
}
