import useEnvSetting from 'hooks/use_env_setting'
import {t} from 'initializers/i18n'
import {RevenueGroup} from 'models/index'
import {useLocalValue} from 'hooks/use_local'
import RevenueTotal from './revenue_total'
import RevenueGroupWeek from './revenue_group_week/index'
import Header from './header'
import './styles/index.less'
import useHasRole from 'hooks/use_has_role'
import RevenueFooterProvider, {useRevenueFooterContext} from './revenue_footer_provider'

const RevenueFooter = ({days}) => {
	const {env} = useRevenueFooterContext()
	const revenueGroups = RevenueGroup.where({environment_id: env.id})

	return (
		<div className="revenue-footer">
			<Header />
			{revenueGroups.map(g => (
				<RevenueGroupWeek
					isTotal={revenueGroups.length == 1}
					revenueDays={days.filter(d => d.omzet_groep_id == g.id)}
					group={g}
					key={g.id}
				/>
			))}
			{revenueGroups.length == 0 && <h5 className="revenue-footer-empty"> {t('revenue.revenue_table.no_groups')} </h5>}
			{revenueGroups.length > 1 && <RevenueTotal revenueDays={days} groups={revenueGroups} />}
		</div>
	)
}

const Wrapper = ({env, localName, featureName = 'planning', days, dateRange, ...rest}) => {
	const hasAccordHoursRole = useHasRole('uren_accorderen', env.id)
	const hasFinancialRole = useHasRole('financieel', env.id)
	const hasPermission = hasFinancialRole || hasAccordHoursRole
	const hasHours = useEnvSetting('uren_reg', 'actief', env.id)
	const visible = useLocalValue(localName)
	if (!visible || !hasPermission || !hasHours) return null
	return (
		<RevenueFooterProvider featureName={featureName} env={env} isHours={localName == 'timeRegRevenue'} dateRange={dateRange} {...rest}>
			<RevenueFooter days={days} />
		</RevenueFooterProvider>
	)
}

export default Wrapper
