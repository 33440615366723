import React, {forwardRef, Fragment, useRef, useState} from 'react'
import Lightbox from 'react-image-lightbox'

export const LightboxImage = ({src, ...rest}) => {
	const [open, setOpen] = useState(false)
	return (
		<Fragment>
			<img {...rest} src={src} onClick={() => setOpen(true)} />

			{open && <Lightbox mainSrc={src} onCloseRequest={() => setOpen(false)} />}
		</Fragment>
	)
}
