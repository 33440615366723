import {useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import {EitjeButton, ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {ATTACHMENT_TRANSKEYS, useChannelAttachments} from 'cores/chat'
import Lightbox from 'react-image-lightbox'

export const Attachments = () => {
	const {attachments} = useChannelAttachments()
	const [activeLightboxImage, setActiveLightboxImage] = useState(null)
	const attachmentsCount = attachments?.length

	if (!attachmentsCount) return null

	const handleClick = url => {
		const attachment = attachments.find(a => a.url === url)

		const {type} = attachment
		if (type === 'image') {
			setActiveLightboxImage(url)
		} else {
			window.open(url, '_blank')
		}
	}
	const handleClose = () => setActiveLightboxImage(null)

	const groups = Object.entries(ATTACHMENT_TRANSKEYS).map(([attachmentType, transKey]) => ({
		id: attachmentType,
		name: t(transKey, {
			count: 2, // force plural
		}),
	}))

	return (
		<>
			<PopoutCard
				body={
					<ListPicker
						labelField="title"
						valueField="url"
						items={attachments}
						single
						raw
						onChange={handleClick}
						groupField="type"
						groupItems={groups}
					/>
				}
				bodyPadding={0}
				trigger="click"
				placement="bottom-start"
				width={292}
			>
				<EitjeButton iconLeft="paperclip">{`${t('attachment')} (${attachmentsCount})`}</EitjeButton>
			</PopoutCard>
			{activeLightboxImage && <Lightbox mainSrc={activeLightboxImage} onCloseRequest={handleClose} />}
		</>
	)
}
