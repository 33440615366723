import {Layout, WeekTableRow} from 'common/components'
import {Day} from './day'
import {Panel} from './panel'
import './styles/index.less'

export * from './view_options'
export * from './multi_edit'
export * from './cell_content'

export const WeekTableDaysHeader = ({dateArray = [], ...rest}) => {
	return (
		<WeekTableRow
			minHeight={36}
			dateArray={dateArray}
			Panel={Panel}
			Day={Day}
			className="week-table-days-header"
			colorSet="grey-bordered"
			{...rest}
		/>
	)
}
