import {t} from 'initializers/i18n'
import {useTeamContext, getPlannedHours, getPlanningShiftUrl, useTeamShifts} from 'cores/planning/index'
import {useDrop} from 'hooks'
import {navigateModal} from 'actions/routing'
import {makeCnVariants} from 'helpers'

const TeamHoursDay = ({date}) => {
	const {team} = useTeamContext()
	const teamShifts = useTeamShifts()
	const shifts = teamShifts.where({date: date})
	const {timeString} = getPlannedHours(shifts)

	const {canDrop, dropRef, isOver} = useDrop({
		accept: `availability-card-${team.id}`,
		onDrop: item => navigateModal(getPlanningShiftUrl({user: item, team, date})),
	})

	const classNames = makeCnVariants('planning-day-hours-drop-shift', isOver && 'is-over')

	return (
		<div ref={dropRef} className="planning-day-hours">
			{canDrop && (
				<div className={classNames}>
					<p>{t('planning.environment_table.drop_to_create')} </p>
				</div>
			)}
			{!canDrop && <p>{timeString} </p>}
		</div>
	)
}

export default TeamHoursDay
