import React, {useRef} from 'react'
import {EitjeVirtuoso, Icon} from '@eitje/web_components'
import {useContainerDimensions} from 'hooks'
import {Layout, usePageContext} from 'common/components'
import {TableRow} from '../row'
import './styles/index.less'

export * from './show_more'

export const AdvancedTableBody = ({rows, ...rest}) => {
	const tableBodyRef = useRef()

	return (
		<Layout ref={tableBodyRef} className="advanced-table-body" width="100%" direction="vertical" gap={0}>
			<EitjeVirtuoso
				data={rows}
				customScrollParent=".page-body"
				itemContent={(index, row) => <TableRow key={row?.original?.id || index} row={row} {...rest} />}
			/>
		</Layout>
	)
}
