import {date} from 'initializers/date'
import * as jsUtils from 'utils/jsutils'
export const shiftStatus = shift => {
	if (shift.typ === 'now') return 'NOW'
	if (shift['is_verlof']) return 'verlof'
	if (shift['is_zzp'] && shift['is_exported']) return 'gelapt'
	if (shift['is_exported']) return 'exported'
	if (shift['is_improductief?']) return 'improductief'
	if (!shift.reg) return 'open'
	if (shift.published) return 'geaccordeerd'
	return 'geschreven'
}

export const shiftMapper = shift => {
	let total_plan
	shift = Object.assign({}, shift)
	const mmtFro = date(shift.start_date) // misschien is een prettifier?
	const mmtTo = date(shift.end_date)
	const mmtPauze = jsUtils.minToMmt(shift.pauze_duur)
	const diff = mmtTo.diff(mmtFro, 'm')
	const total_mins = diff - shift.pauze_duur

	if (shift.plan_from) {
		const planFro = date(shift.plan_from)
		const planTo = date(shift.plan_till)
		const planDiff = planTo.diff(planFro, 'm')
		total_plan = planDiff - shift.plan_pauze
	}

	return {
		...shift,
		total_plan: total_plan,
		diff: diff,
		status: shiftStatus(shift),
		from: mmtFro,
		till: mmtTo,
		pauze: mmtPauze,
		total_mins: total_mins,
	}
}
