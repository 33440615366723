import {Link, ModalLink} from 'components/routing'
import {useLocation} from 'react-router-dom'
import {Tabs} from 'common/components'

const RouteToggle = ({items, modal, withSearchQuery, ...rest}) => {
	const {pathname, search} = useLocation()
	const query = withSearchQuery ? search : ''
	const value = pathname + query
	const LinkComponent = modal ? ModalLink : Link
	return <Tabs noSort raw items={items} value={value} valueField="path" TabWrapper={LinkComponent} {...rest} />
}
export default RouteToggle
