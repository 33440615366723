import {NewSideMenuModal} from 'common/components'
import MapEnvs from '../setup_hr/map_envs'
import {BaseConnection, Environment, ExtEnvironment} from 'models'
import {MenuItem, ModalTitle} from 'cores/integrations'

const routes = [{name: 'environments', component: MapEnvs}]

export const SetupBase = () => {
	const item = BaseConnection.show()

	const contextProps = {item}
	const sideMenuProps = {MenuItem, initialRoute: 0}
	const extEnvironments = ExtEnvironment.where(item.ext_environment_ids)
	const envs = Environment.where({id: extEnvironments._map('environment_ids').flat()})

	return (
		<NewSideMenuModal
			hideCancel
			title={<ModalTitle envs={envs} item={item} />}
			width={600}
			height={600}
			contextProps={contextProps}
			sideMenuProps={sideMenuProps}
			name="setup_base"
			routes={routes}
		/>
	)
}
