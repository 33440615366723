const initialState = {
	initialChannel: '',
	unreadMessagesCount: null,
}

export function reduce(state = initialState, action) {
	switch (action.type) {
		case 'SET_INITIAL_CHANNEL':
			return {
				...state,
				initialChannel: action.initialChannel,
			}
		case 'SET_UNREAD_MESSAGES_COUNT':
			return {
				...state,
				unreadMessagesCount: action.unreadMessagesCount,
			}
		default:
			return state
	}
}
