import {usePageContext, useFilterFooterContext} from 'common/components'
import {MultiAction} from 'cores/time_registration/index'

const MultiActionPerWeek = () => {
	const {multiEdit, setMultiEdit, selectedShifts, setSelectedShiftsRaw} = usePageContext()
	const {filteredItems: shifts} = useFilterFooterContext()
	if (!multiEdit) return null
	const _selectedShifts = shifts.filter(s => selectedShifts.includes(s.id))
	return (
		<MultiAction
			close={() => {
				setMultiEdit(false)
				setSelectedShiftsRaw([])
			}}
			selectedShifts={_selectedShifts}
		/>
	)
}

export default MultiActionPerWeek
