import {Title, SupportButton, Layout} from 'common/components'
import {possibleIntegs} from 'cores/integrations/constants'
import {t} from 'initializers/i18n'
import IntegrationCard from './integration_card'
const MARGIN = [0, 'auto']
import './styles/index.less'

const NewIntegration = () => {
	const groupedIntegs = _.groupBy(possibleIntegs, 'type')
	const integTypes = Object.keys(groupedIntegs)
	return (
		<Layout width={800} margin={MARGIN} direction="vertical" className="new-integrations integrations-page-content">
			{integTypes.map(type => (
				<TypeBlock type={type} items={groupedIntegs[type]} />
			))}
		</Layout>
	)
}

const TypeBlock = ({type, items}) => {
	return (
		<>
			<Layout className="title-layout" width="full" padding="8 0">
				<h3>{t(`integrations.${type}.title`)} </h3>
			</Layout>
			<Layout wrap gap={12} margin="12 0" childrenPerRow={3} width="100%">
				{items.map(i => (
					<IntegrationCard item={i} type={type} />
				))}
				<AddIntegration title={t(`integrations.${type}.ask_for_new_title`)} />
			</Layout>
		</>
	)
}

const AddIntegration = ({title}) => {
	return (
		<Layout direction="vertical" type="card" horizontal="center" padding={24} gap={12} parentGap={12}>
			<Title> {title} </Title>

			<SupportButton
				colorSet="color-bordered-fill"
				width="full"
				message={t('integrations.ask_for_new_intercom_msg')}
				t="integrations.ask_for_new"
			/>
		</Layout>
	)
}

export default NewIntegration
