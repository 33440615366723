import {Environment} from 'models'
import {prettyPrintArray} from 'helpers'

export const EnvironmentCell = ({getValue}) => {
	const id = getValue()
	const environment = Environment.find(id)
	return environment.naam || null
}

export const EnvironmentsCell = ({getValue}) => {
	const values = getValue()
	if (!values) return null

	let names
	switch (typeof values[0]) {
		case 'object':
			names = values._map('naam').uniq().sort()
			break
		case 'number':
			names = Environment.where(values)._map('naam').sort()
	}

	return prettyPrintArray({array: names, joinWord: 'and', quotes: false})
}
