import {date as _date} from 'initializers/date'
import {Switch, RawSwitch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {useWhere, useFind} from '@eitje/easy_api'
import {useForm} from '@eitje/form'
import {combineAvShifts} from 'cores/availability/index'
import './styles/conflict_form.less'
import {Layout, Message} from 'common/components'
import {EitjeAvatar} from 'common/components'

const MARGIN = [8, 24]

const ConflictForm = ({data}) => {
	const allIds = data.map(d => d?.shift_id)
	const {setValues, getData} = useForm()
	const formData = getData()
	const allActive = allIds.every(id => formData[`schedule-${id}`])
	const toggleAll = () => {
		const newVals = allIds.map(id => ({[`schedule-${id}`]: !allActive}))
		setValues(Object.assign({}, ...newVals))
	}
	return (
		<div className="conflict-form">
			<Message margin={MARGIN} t="planning.conflict_modal.explainer" />

			<div className="conflict-form-subheader">
				<h4>{t('planning.conflict_modal.conflicts', {count: data.length})} </h4>
				<div className="select-all-container">
					<h6>{t('common.select_all')} </h6>
					<RawSwitch value={allActive} size="small" onChange={toggleAll} />
				</div>
			</div>
			{data.map(d => (
				<ConflictRow {...d} />
			))}
		</div>
	)
}

const ConflictRow = ({shift_id, date, availability_shift_ids, user_id}) => {
	const user = useFind('users', user_id)
	const avShifts = useWhere('availabilityShifts', availability_shift_ids)
	const combined = {...combineAvShifts(avShifts), date}
	return (
		<Layout className="conflict-row" gap={8}>
			<EitjeAvatar user={user} />
			<div className="conflict-row-text-container">
				<h4>{user.full_name} </h4>
				<h5>
					{t('planning.statuses.unavailable')}: <AvString shift={combined} />
				</h5>
			</div>
			<Switch labelVisible={false} field={`schedule-${shift_id}`} />
		</Layout>
	)
}

const AvString = ({shift}) => {
	let {date, timeBlocks} = shift
	const extraStr = timeBlocks.every(t => t.from)
		? timeBlocks.map(block => `${block.from} ${t('common.till')} ${block.till}`).join(' | ')
		: ''

	return `${_date(date).format('dd DD MMMM')} ${extraStr}`
}

export default ConflictForm
