import React, {useContext, createContext, useMemo} from 'react'
import usePlanningDates from 'cores/planning/hooks/use_planning_dates'
import {useFilterFooterContext} from 'common/components/filter_footer/context'

const PerMemberContext = createContext({})
const {Provider} = PerMemberContext

const PerMemberProvider = ({children}) => {
	const {dateRange} = usePlanningDates()

	const {filteredItems: filteredShifts} = useFilterFooterContext()

	let openShifts = filteredShifts.where({user_id: null})

	const val = {planShifts: filteredShifts, openShifts}
	const value = useMemo(() => val, [dateRange, filteredShifts])

	return <Provider value={value}> {children} </Provider>
}

export const usePerMemberContext = () => useContext(PerMemberContext)

export default PerMemberProvider
