import {NewModalForm} from 'components/form/new_modal_form'
import {Input, Switch, FormRow} from '@eitje/form-fields-web'
import {User} from 'models'
import {API} from '@eitje/easy_api'
import {t} from 'initializers/i18n'
import {ListPicker} from 'common/components'
import {TeamColorPicker} from 'lib/form/fields/team_color_picker'
import {AvatarPicker} from 'lib/form/fields/avatar_picker'
import {getTableName} from 'helpers'
import {RemoveUsersMessage} from './remove_users_message'
import {UpdateChatMessage} from './update_chat_message'
import {NAMESPACE} from 'cores/firm'
import {useQueryParams} from 'hooks'

export const TeamForm = ({environment, team}) => {
	const {'show-chat-message': showChatMessage} = useQueryParams()
	const {naam, onroosterbaar, chatgroep_actief, avatar, color, locked} = team
	const teamUserIds = User.where(team.user_ids).ids()
	const initialValues = {
		naam,
		chatgroep_actief,
		schedulable: locked ? false : !onroosterbaar,
		remote_avatar_url: avatar,
		color: color || Colors.lightBlue,
		user_ids: teamUserIds,
	}
	const envUsers = User.where(environment.user_ids)

	const onSubmit = ({schedulable, color, ...data}) => {
		const params = {id: team.id, onroosterbaar: !schedulable, color: color.toUpperCase?.(), ...data}
		return API.update('teams', params)
	}

	const lockedTeamTooltip = locked ? t(`${NAMESPACE}.warning.cant_schedule_locked_team`) : ''

	return (
		<NewModalForm variant="grid" initialValues={initialValues} onSubmit={onSubmit}>
			<RemoveUsersMessage initialValues={initialValues} />
			{showChatMessage && <UpdateChatMessage />}

			<FormRow>
				<Input field="naam" required readOnly={locked} />
			</FormRow>

			<FormRow>
				<ListPicker
					field="user_ids"
					dropdown
					items={envUsers}
					labelField="full_name"
					title={getTableName('users')}
					showToggleSelectAll={false}
					allowClear={false}
					readOnly={locked}
				/>
			</FormRow>

			<FormRow>
				<Switch label={t('team.team_settings.active_schedule.label')} field="schedulable" disabled={locked} tooltip={lockedTeamTooltip} />
				<Switch field="chatgroep_actief" />
			</FormRow>

			<FormRow>
				<AvatarPicker field="remote_avatar_url" />
				<TeamColorPicker field="color" />
			</FormRow>
		</NewModalForm>
	)
}
