import {useShared} from 'hooks'
import User from 'models/user'
import {EitjeButton} from 'common/components'

export const useNoTeamMembersPlaceholder = (props = {}) => {
	const {env} = useShared()
	const envUsers = User.where({environment_ids: env.id})
	const users = props.users || envUsers
	const hasAddedUsers = users.length > 1
	if (hasAddedUsers) return null

	const propz = {
		animation: 'waving-people',
		title: 'noAvailableUsers',
		subtitle: false,
		docsId: '5967145',
		children: <EitjeButton modalLink={`/forms/invite`} colorSet="solid" iconLeft="person" t="add" />,
		...props,
	}
	return propz
}
