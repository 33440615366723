import {store} from 'index'
import {requireProps} from 'helpers'

export const INITIAL_ADVANCED_TABLE_STATE = {
	pinned: [], // used for sort
	unpinned: [], // used for sort
	visible: [],
	grouped: null,
}

export const writeStore = (tableName, value) => {
	store.dispatch({tableName, type: 'ADVANCED_TABLE', value})
}
