import useSelector from 'hooks/use_selector'
import {store} from 'index'

export const useLocalValue = name => {
	return useSelector(state => state.local[name])
}
export const useLocal = name => {
	const value = useSelector(state => state.local[name])
	return {value, toggle: () => toggleLocal(name), setLocal: value => setLocal({name, value})}
}

export const toggleLocal = name => {
	store.dispatch({type: 'TOGGLE_LOCAL_VALUE', name})
}

export const setLocal = ({name, value}) => {
	store.dispatch({type: 'SET_LOCAL_VALUE', name, value})
}

export const ShowLocalContent = ({value, Component}) => {
	const show = useLocalValue(value)
	return show ? <Component /> : null
}

export default useLocal
