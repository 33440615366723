import {backend} from '@eitje/easy_api'
import {store} from 'index'
import i18n from 'initializers/i18n'
import Moment from 'moment'

export const changeLocale = loc => {
	updateUserSetting({locale: loc})
	setLocale(loc || 'nl')
}

export const updateUserSetting = async (settings, {local = false} = {}) => {
	const state = store.getState()
	const id = state.auth.user.id
	store.dispatch({type: 'UPDATE_USER_SETTING', settings})
	!local && backend.put(`users/${id}/update_settings`, {settings})
}

export async function setLocale(locale) {
	if (!locale) locale = 'nl'
	i18n.changeLanguage(locale)
	Moment.locale(locale, {
		week: {
			dow: 1,
		},
	})
}

export async function updateOrgSettings(settings) {
	const res = await backend.post('org/settings', {settings})
	if (res.ok) {
		store.dispatch({type: 'UPDATE_ORG_SETTINGS', organisation: settings})
	}
	return res
}

export async function updateSettings(settings) {
	const state = store.getState()
	const {active} = state.environment
	const res = await backend.post('new_update_settings', {settings})
	if (res.ok) {
		store.dispatch({type: 'UPDATE_ENV_SETTINGS', settings, id: active})
	}
	return res
}

export async function saveLocalEnvSetting(name, params) {
	const state = store.getState()
	const id = state.environment.active
	const settings = state.settings.environments[id]
	const newSettings = {...settings, [name]: {...settings[name], ...params}}
	store.dispatch({type: 'UPDATE_ENV_SETTINGS', settings: newSettings, id})
}

const makeAdminRoles = envsSettings => {
	let obj = {}
	Object.keys(envsSettings).forEach(envId => (obj[envId] = ['admin']))
	return obj
}

export async function saveSettingGroup(groupName, data, extraParams = {}) {
	let {env_id} = extraParams
	const res = await saveSettings({[groupName]: data}, extraParams)
	if (res.ok) {
		if (!env_id) env_id = store.getState().environment.active
		store.dispatch({type: 'UPDATE_ENV_SETTING_GROUP', settings: data, groupName, id: env_id})
	}
	return res
}

export async function saveSettings(data, extraParams = {}) {
	const res = await backend.post(`new_update_settings`, {settings: data, ...extraParams})
	return res
}

const admins = [
	{id: 287, email: 'admin@eitje-app.nl'},
	{id: 394, email: 'baaz@bazen.nl'},
	{id: 7187, email: 'jurriaan@eitje.app'},
	{id: 45532, email: 'tiemo_admin@eitje.appp'},
	{id: 45531, email: 'clara_admin@eitje.appp'},
]

const _isAdmin = user => admins.find(a => a.id == user.id && a.email == user.email)

export async function getSettings() {
	const state = store.getState()
	const authUser = state.auth?.user
	const isAdmin = _isAdmin(authUser)

	const res = await backend.get('app_settings', {new: true})
	if (res.ok && res.data) {
		let {locale, environment, environments, taxonomy, organisation, user, periods, roles} = res.data
		setLocale(locale)
		if (isAdmin) {
			roles = makeAdminRoles(environments)
		}
		const settings = {
			environments,
			user: {...user, locale},
			periods,
			roles,
			taxonomy,
			organisation,
		}
		store.dispatch({type: 'GET_SETTINGS', payload: settings})
	}
}
