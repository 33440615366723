import {backend} from '@eitje/easy_api'
import _ from 'lodash'

export const submitExport = async (name, data) => {
	if (_.has(data, 'contract_types') && !data['contract_types']) {
		delete data['contract_types']
	}

	if (data['contract_types']) {
		data['contract_types'] = [data['contract_types']]
	}

	if (!data['org_filter']) {
		data['org_filter'] = 1
	}

	const params = {exporter: {...data, name}}
	const res = await backend.post(`exports/export`, params)
	return res
}

export const submitNmbrs = async data => {
	const res = await backend.post(`koppelingen/nmbrs/export_period`, data)
	return res
}
