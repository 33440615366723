const initialState = {
	active: null,
	redUrl: null,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'RESET_RECORDS':
			return {
				...initialState,
				active: action.dropEnvs ? null : state.active,
			}

		case 'SET_ACTIVE_ENV':
			return {
				...state,
				active: action.env,
				redUrl: action.redUrl,
			}

		case 'CLEAR_REDIRECTED':
			return {
				...state,
				redUrl: null,
			}

		default:
			return state
	}
}
