import {useWhere} from '@eitje/easy_api'
import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'
import {getTableName} from 'helpers/index'
import pluralize from 'pluralize'
import {date} from 'initializers/date'
import utils from '@eitje/web_utils'

export class Excel extends ActiveRecord {
	static tableName = 'export2Excels'

	startDate() {
		const {date_range} = this
		return date(date_range.start_date)
	}
	endDate() {
		const {date_range} = this
		return date(date_range.end_date)
	}

	hasPeriod() {
		return utils.exists(this.date_range)
	}

	retry() {
		return this.resourceReq('export_again', {method: 'post'})
	}
}

export default Excel
