import {LocalCheckboxButton} from 'components/ui'
import {ViewButton as _ViewButton, viewOptionsDropdownItem} from 'common/components'
import {useCanViewRevenue, useOrgEnvSettingKeyValue, useRoleMessage} from 'hooks'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {pathActive} from 'helpers'
import {
	useShiftViewOptions as _useShiftViewOptions,
	userViewOptions as _userViewOptions,
} from 'cores/planning/components/user_planning_shifts_row/panel_dropdown'

export const ViewOptions = props => {
	const perMember = pathActive('/planning/per_member')
	const perTeam = !perMember
	const {envs} = useFilterFooterContext()
	const disabled = useRoleMessage('manager')
	const shiftViewOptions = _useShiftViewOptions()
	const userViewOptions = _userViewOptions({disabled})
	const baseStr = 'planning.environment_table.tools_buttons'

	const elements = [
		perMember && userViewOptions,

		// Once we implement the WeekTable on planning per team page, share the view options below (since the PlanningShiftCell will be reused too)
		perMember && shiftViewOptions,
		perTeam && legacyShiftViewOptions(),

		perTeam && availabilityViewOptions({disabled}),
		dayViewOptions({disabled}),
		useRowViewOptions({perTeam, perMember, disabled}),
	]
	return <_ViewButton {...props} elements={elements} />
}

function availabilityViewOptions({disabled}) {
	const buttons = ['PlannedExpected', 'Skills']
	return viewOptionsDropdownItem({record: 'availability', buttons, disabled, namespace: 'planningShow'})
}

function legacyShiftViewOptions() {
	const buttons = ['ShiftType', 'Trade', 'Remarks', 'Avatar']
	return viewOptionsDropdownItem({record: 'planning_shift', buttons, namespace: 'planningShow'})
}

function dayViewOptions({disabled}) {
	const buttons = ['Weather', 'AvLock', {kind: 'PlannedTimes', disabled}]
	return viewOptionsDropdownItem({record: 'day', buttons, namespace: 'planningShow'})
}

function useRowViewOptions({perTeam, perMember, disabled}) {
	const canViewRevenue = useCanViewRevenue()
	const hasHoursPerEnv = useOrgEnvSettingKeyValue('uren_reg', 'actief')
	const hasHours = Object.values(hasHoursPerEnv).some(Boolean)
	const showRevenueForecast = perTeam && canViewRevenue && hasHours

	// currently have a different availability key for each page, might merge eventually...?
	const availability = perTeam ? 'QuickPlan' : 'PerWeekShowUserAvailability'

	const buttons = [
		perTeam && {kind: availability, disabled},
		'ShowEvents',
		perTeam && {kind: 'ShowHourRow', disabled},
		perTeam && {kind: 'ShowImproductive', disabled},
		showRevenueForecast && 'Forecast',
	]

	return viewOptionsDropdownItem({record: 'row', buttons, namespace: 'planning', usePerInLabel: false})
}
