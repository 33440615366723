import {BaseConnection} from 'models'
import {ModalWithForm} from 'components/form/index'
import {Layout} from '@eitje/web_components'
import {ListPicker} from 'common/components'
import User from 'models/user'
import {useFormData} from 'hooks'
import {useForm} from '@eitje/form'
import {Input, DatePicker} from '@eitje/form-fields-web'
import {FormRow} from 'components/ui'
import {useEffect} from 'react'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

export const ExportUsers = () => {
	const item = BaseConnection.show()
	return (
		<ModalWithForm
			modalProps={{
				i18nOpts: {integName: item.name},
				confirmSubmit: true,
				okButtonProps: {
					iconLeft: 'upload',
					confirmText: t('modals.integrations.export_users.confirm_text'),
					t: 'integrations.hr.export_users',
				},
			}}
			fieldProps={{required: true}}
			resetAfterSubmit
			name="integrations.export_users"
			onSubmit={data => item.exportUser(data)}
			afterSubmit={() => utils.successNotif({content: t('modals.integrations.export_users.success.body')})}
		>
			<Inner item={item} />
		</ModalWithForm>
	)
}

const Inner = ({item}) => {
	const users = User.all()
		.filter(u => utils.intersects(u.environment_ids, item.environment_ids))
		.map(user => {
			const disabled = user.external_ids.some(id => id.name == 'fooks')
			return {...user, disabled: disabled && 'modals.integrations.export_users.already_exported'}
		})

	const {user_id} = useFormData()
	const {setValues} = useForm()

	useEffect(() => {
		if (user_id) {
			const user = users.find(u => u.id == user_id)
			setValues(user)
		}
	}, [user_id])

	return (
		<Layout direction="vertical">
			<FormRow>
				<ListPicker required single form field="user_id" dropdown items={users} labelField="full_name" />
			</FormRow>
			{user_id && (
				<>
					<FormRow>
						<Input field="voornaam" />
						<Input field="achternaam" />
					</FormRow>
					<FormRow>
						<DatePicker defaultPickerValue={'2008-01-01'} disabledAfter={'2014-01-01'} field="verjaardag" />
					</FormRow>
				</>
			)}
		</Layout>
	)
}
