import {PrivateRoute, ProtectedRoute} from 'components/private_route'
import {onboardingRole} from './constants'
import {FeatureExplanationModal, OnboardingStepModal, WelcomePage} from './pages'

export const onboardingForegroundRoutes = [
	<ProtectedRoute roleInAnyEnv={onboardingRole} path="/onboarding/welcome" component={WelcomePage} />,
]

const step = {roleInAnyEnv: onboardingRole, component: OnboardingStepModal}
const feature = {component: FeatureExplanationModal}

export const onboardingBackgroundRoutes = [
	<ProtectedRoute {...step} path="/onboarding/create_team" />,
	<ProtectedRoute {...step} path="/onboarding/invite_users" />,
	<ProtectedRoute {...step} path="/onboarding/create_planning_shift" />,
	<ProtectedRoute {...step} path="/onboarding/create_time_registration_shift" />,
	<PrivateRoute {...feature} path="/onboarding/firms" />,
	<PrivateRoute {...feature} path="/onboarding/planning" />,
	<PrivateRoute {...feature} path="/onboarding/time_registration" />,
	<PrivateRoute {...feature} path="/onboarding/time_tracking" />,
	<PrivateRoute {...feature} path="/onboarding/mobile_app" />,
]
