import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'

const noUrlIntegs = ['nmbrs', 'ConnexieUpload', 'HorecalonenUpload']

const useExport = id => {
	const base = `exports.${id}`
	const taxonomy = useSelector(state => state.taxonomy.exports)
	const _export = taxonomy[id]

	const title = t(`${base}.title`)
	const subtitle = t(`${base}.subtitle`)
	const downloadLink = !noUrlIntegs.includes(id) && `/exports/${id}.xlsx`
	const imgSrc = `/images/exports/${id}.jpg`
	const categoryTitle = t(`${base}.modalTitle`, t('common.export'))
	const modalTitle = `${categoryTitle} - ${title}`

	return {title, subtitle, downloadLink, imgSrc, modalTitle, opts: _export}
}

export default useExport
