import useAvailability from 'hooks/use_availability'
import useDay from 'cores/availability/hooks/use_day'
import {toggleDayClosed} from 'cores/availability/actions'

const usePlanningDay = (date, envId) => {
	const {isLocked} = useAvailability(envId)
	const {closed} = useDay(date.format(), envId)
	const dateLocked = isLocked(date)
	let condOpts = {
		onClick: () => !dateLocked && toggleDayClosed(date, !closed, envId),
	}

	return {condOpts, closed, dateLocked}
}

export default usePlanningDay
