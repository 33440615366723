import useShared from 'hooks/use_shared'
import {useMemo} from 'react'
import {Team} from 'models'

const usePlanningTeams = env => {
	const {orgEnvs} = useShared()
	const envId = env?.id || env
	let query = {environment_id: envId || orgEnvs.ids(), locked: [false, null], onroosterbaar: [false, null]}
	const teams = Team.where(query)

	return useMemo(() => _.orderBy(teams, 'rooster_order', 'desc'), [teams])
}

export default usePlanningTeams
