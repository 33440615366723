import React, {useEffect} from 'react'
import {date} from 'initializers/date'
import {t as baseT} from 'initializers/i18n'
import {useForm} from '@eitje/form'
import {t, HourMinPicker} from 'cores/leave/index'
import useFormData from 'hooks/use_form_data'
import {useSimpleContext} from 'contexts/simple'
import {useGet} from 'hooks/index'
import {TimeString} from 'components/common/index'
import './styles/index.less'
import {InfoCard, Message, Layout} from 'common/components'

export const HourMinInfoCard = props => {
	const {value} = props
	const val = <TimeString showHourText signed value={value} />
	return (
		<InfoCard placeholder="--:--" {...props}>
			{val}
		</InfoCard>
	)
}

const ContractInfo = () => {
	const {request} = useSimpleContext()
	const {setValues} = useForm()
	const {total_mins = 0} = useFormData()
	const {data, loading} = useGet(request.buildUrl('approve_info'))
	const {
		start_balance,
		end_balance,
		end_date,
		is_end_year,
		no_contract,
		contract_mins_invalid,
		missing_contract_period,
		built_up,
		consumed,
		contract_mins,
	} = data

	const range = request.__dateRange()

	const endBalance = end_balance - total_mins
	useEffect(() => {
		if (contract_mins) {
			const mins = contract_mins > range.minutes() ? range.minutes() : contract_mins
			setValues({total_mins: mins})
		}
	}, [contract_mins])

	// if (!utils.exists(start_balance)) return null

	const warning = contract_mins_invalid ? 'contract_mins_invalid' : missing_contract_period ? 'missing_contract_period' : null

	const tillEndSub = baseT('functions.tillRaw', {date: end_date})

	if (loading) return null

	const title = is_end_year ? baseT('balance.expected_result_end_year') : baseT('balance.expected_result_end_contract', {date: end_date})
	const hasBalance = !!start_balance && !no_contract
	const showWarnings = no_contract || warning
	const warningIcon = !(no_contract && warning) && 'exclamation-mark-circled'

	return (
		<div className="contract-info">
			{hasBalance && (
				<Layout className="info-cards-top-row" data-intercom-target="approve-top-row" childrenEqual padding={12} gap={8}>
					<HourMinInfoCard
						label={`${baseT('balance.disposable')} ${baseT('functions.on', {date: date().format()})} `}
						value={start_balance}
					/>
					{built_up > 0 && <HourMinInfoCard label={`${baseT('balance.to_build_up')} ${tillEndSub}`} value={built_up} />}
					<HourMinInfoCard label={`${baseT('balance.to_consume')} ${tillEndSub}`} value={consumed} />
				</Layout>
			)}
			{showWarnings && (
				<Layout className="contract-warnings" data-intercom-target="approve-top-row" gap={0}>
					{no_contract && <Message icon={warningIcon} baseColor="red" colorSet="color-bordered-fill" t="leave.warnings.no_contract" />}
					{warning && <Message icon={warningIcon} baseColor="red" colorSet="color-bordered-fill" t={`leave.warnings.${warning}`} />}
				</Layout>
			)}
			<HourMinPicker autoFocus showArrows />
			{hasBalance && (
				<Layout horizontal="center" padding={12} gap={8}>
					<HourMinInfoCard label={title} textPopoutTitle={t('after_approval')} value={endBalance} />
				</Layout>
			)}
		</div>
	)
}

export default ContractInfo
