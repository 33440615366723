import {RouteToggle as BaseRouteToggle} from 'components/ui'
import {useLocal} from 'hooks'
import {t} from 'initializers/i18n'

const RouteToggle = ({localKey, baseRoute, items, ...rest}) => {
	const viewPreference = useLocal(localKey)

	const _items = items || [
		{name: t('common.per_team'), path: `/${baseRoute}/per_team`},
		{name: t('common.per_member'), path: `/${baseRoute}/per_member`},
	]

	const handeToggle = val => {
		viewPreference.setLocal(val)
	}

	return <BaseRouteToggle onChange={handeToggle} items={_items} {...rest} />
}

export default RouteToggle
