import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {EitjeModal, FormRow} from 'components/ui'
import useParams from 'hooks/use_params'
import {history} from 'index'
import {PlanningShift} from 'models/index'
import {ContextForm} from '@eitje/form'
import {usePlanningDates} from 'cores/planning/index'
import {useQueryParams, useRoleOrgEnvs} from 'hooks'
import {usePlanningTeamFilter} from 'cores/planning/hooks/use_planning_team_filter'
import {FilterActiveMessage} from 'cores/planning/components/team_filter_message'
import {useTotalShiftsPerTeam} from 'hooks/use_shifts_per_team'
import {labelWithShiftCount} from 'hooks/use_team_filter'
import {getKeys} from 'helpers/object'

const ClearWeek = () => {
	const {team_ids, filter_active} = useQueryParams()
	const {dateRange} = usePlanningDates()
	const {shiftsPerTeam} = useTotalShiftsPerTeam({team_ids, dateRange})
	const teamIdsWithShifts = getKeys(shiftsPerTeam)
	const managerOrgEnvs = useRoleOrgEnvs('manager')
	const showEnvName = managerOrgEnvs.length > 1

	const {teamFilter} = usePlanningTeamFilter({
		team_ids: teamIdsWithShifts,
		buildLabel: (str, item) => `${showEnvName ? `${item.environment.naam}:` : ''} ${labelWithShiftCount(str, item, shiftsPerTeam)}`,
		layoutProps: {width: '100%'},
		hideGroupCount: true,
	})

	return (
		<Fragment>
			<FilterActiveMessage active={filter_active} />
			<FormRow>{teamFilter}</FormRow>
		</Fragment>
	)
}

const Wrapper = () => {
	const {envId} = useParams()
	const {week, year} = usePlanningDates()
	const extraData = {extraParams: {env_id: envId}}

	return (
		<ContextForm
			afterSubmit={history.goBack}
			onSubmit={data => PlanningShift.arbitrary('mass_destroy', {...data, year, week}, extraData)}
			transNamespace="clear_team_shifts"
		>
			<EitjeModal form submitIsDanger name="clear_week" title={t('form.clear_team_shifts.title')} className="clear-week-modal">
				<ClearWeek />
			</EitjeModal>
		</ContextForm>
	)
}

export default Wrapper
