import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {PopoutCard, Icon} from '@eitje/web_components'
import {useWhere} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {Link} from 'components/routing'
import {useForecast} from 'cores/revenue/index'
import useHasRole from 'hooks/use_has_role'
import './styles/revenue_day.less'
import {useRevenueFooterContext} from '../revenue_footer_provider'
import {contractJoins as opts} from 'constants/users'
import {Layout, Text} from 'common/components'

const RevenueDayItem = ({revenueDays, targetPct, revenueTeams, shifts, isTotal, date}) => {
	const {revenue, laborProd, wageCost, wageCostPct, pctString} = useForecast({
		shifts,
		revenueDays,
		revenueTeams,
	})
	const {env} = useRevenueFooterContext()
	const hasContractRole = useHasRole('contracten', env.id)
	const userIds = shifts.map(s => s.user_id).filter(Boolean)
	const color = !targetPct || wageCostPct < targetPct ? '#828282' : '#ff0020'

	return (
		<div className="revenue-group-day">
			{userIds.length > 0 && isTotal && <NoContractUsers date={date} userIds={userIds} />}
			<h4>{utils.money(revenue)}</h4>
			<h6>
				<b>AP:</b> {utils.money(laborProd)}
			</h6>
			<h6 style={{color: color}}>
				{hasContractRole && (
					<Fragment>
						$: {utils.money(wageCost)} | {pctString}
					</Fragment>
				)}
				{!hasContractRole && <Fragment> $: {pctString} </Fragment>}
			</h6>
		</div>
	)
}

const NoContractUsers = ({userIds, date}) => {
	const users = useWhere('users', userIds, opts)
	const noContractUsers = users.filter(u => !u.contractHolder(date))
	if (noContractUsers.length == 0) return null
	return (
		<PopoutCard className="no-contract-users" body={<NoContractPopover users={noContractUsers} />} bodyPadding={0}>
			<img className="no-contract-users-icon" src="/images/countEmployee.png" />
			<div className="no-contract-users-count-wrapper">
				<h5 className="no-contract-users-count"> {noContractUsers.length} </h5>
			</div>
		</PopoutCard>
	)
}

const NoContractPopover = ({users}) => {
	return (
		<div className="no-contract-popover-content">
			<h4 className="no-contract-popover-title">{t('common.no_active_contract')}</h4>
			{users.map(u => (
				<NoContractUser user={u} />
			))}
		</div>
	)
}

const NoContractUser = ({user}) => {
	return (
		<Link to={`/user/${user.id}/contracts`}>
			<Layout horizontal="spaceBetween" className="no-contract-popover-user" padding={8}>
				<Text>{user.full_name}</Text> <Icon name="document-signed" size="12px" />
			</Layout>
		</Link>
	)
}

export default RevenueDayItem
