import {PopoutCard, Icon} from '@eitje/web_components'
import {t, ExportSubscription} from 'cores/exports2/index'
import {useSimpleContext} from 'contexts/simple'
import {t as baseT} from 'initializers/i18n'
import {useList} from 'hooks/index'
import {UserList, BasicList, ModalLink} from 'components/common/index'
import {date} from 'initializers/date'
import {CreateButton} from 'common/components'
import './styles/index.less'

export const Subscriptions = () => {
	const {item} = useSimpleContext()
	const subs = ExportSubscription.where({table_id: item.id})
	const {list} = useList({items: subs, ListItem: Subscription})

	return (
		<div className="export-tables-show-subs">
			<div className="subscriptions-header">
				<h3 className="count"> {subs.length} </h3>
				<div className="titles">
					<h3>{baseT('common.subscription', {count: 2})} </h3>
					<p>{t('table_show.subtitle')} </p>
				</div>
				<CreateButton modalLink="subscriptions/new" t="common.subscription" />
			</div>
			{list}
		</div>
	)
}

const Subscription = ({item}) => {
	const {tense, type} = item.export_period
	const {paused} = item
	const count = item.recipientCount()
	const periodString = `${baseT('common.period')}: ${baseT(`common.${tense}`)} ${baseT(`common.${type}`)}`
	const countString = `${count} ${baseT('common.recipient', {count})}`
	const iconName = paused ? 'pause' : 'play'
	return (
		<ModalLink to={`subscriptions/${item.id}`} className="export-tables-show-sub">
			<h4>{item.cronString()} </h4>
			<PopoutCard body={<RecipientsPopover item={item} />}>
				<h5>
					{periodString} - {countString}
					<img className="footer-card-icon" src="/images/search_glass_icon.png" />
				</h5>
			</PopoutCard>
			<Icon name={iconName} />
		</ModalLink>
	)
}

const RecipientsPopover = ({item}) => {
	const {user_ids, emails, cron_first_time} = item
	return (
		<>
			<UserList userIds={user_ids} />
			<BasicList items={emails} title={baseT('email', {count: emails.length})} />
			{cron_first_time && (
				<>
					<p>----- </p>
					<LastSentAt time={cron_first_time} />
				</>
			)}
		</>
	)
}

const LastSentAt = ({time}) => (
	<span>
		{baseT('common.last_sent_on')} {date(time).format('DD-MM-YYYY HH:mm')}
	</span>
)
