import {EitjeVirtuoso as _EitjeVirtuoso} from '@eitje/web_components'
import {Placeholder as _PlaceHolder, useFilterFooterContext} from 'common/components'
import {useSearchContext} from 'contexts/search'
import {SearchEmpty} from 'components/common'
import {useNoTeamMembersPlaceholder} from 'shared'

export const EitjeVirtuoso = ({data, placeholderProps, ...rest}) => {
	const hasData = data?.length && data.length > 0

	if (!hasData) return <PlaceHolder {...placeholderProps} />
	return <_EitjeVirtuoso data={data} {...rest} />
}

const PlaceHolder = props => {
	const {search, setSearch} = useSearchContext()
	const clearSearch = () => setSearch('')
	const noUsersPlaceholder = useNoTeamMembersPlaceholder()
	const {hasFiltered, clearFilters} = useFilterFooterContext()
	const filter = _.values(hasFiltered).some(Boolean)
	const clearSearchAndFilter = () => {
		clearSearch()
		clearFilters()
	}

	// If searching or filtering, always prefer to offer a clear search action
	if (search || filter) return <SearchEmpty action={clearSearchAndFilter} />

	// If a prospect has just created an account, A) explain why pages are empty and B) motivate them to invite users.
	if (noUsersPlaceholder) <PlaceHolder {...noUsersPlaceholder} />

	// If above conditions are not truthy AND no specific placeholder props are passed, simply show an empty page.
	if (_.isEmpty(props)) return null

	// Show the custom, page specfic Placeholder.
	return <_PlaceHolder {...props} />
}
