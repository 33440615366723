import React, {useEffect, useState, Fragment, useCallback, useRef} from 'react'
import {pusher, getChannel, getUserChannelId} from 'lib/pusher'

export const usePusher = (channelId, message, callback, toWatch) => {
	useEffect(
		() => {
			const chan = getChannel(channelId) || pusher.subscribe(channelId)

			if (!chan) {
				console.error(`Failed to setup pusher for channel ${channelId}, channel undefined.`)
				return
			}

			chan.bind(message, callback)
			return () => chan.unbind()
		},
		toWatch ? [toWatch] : [],
	)
}

export const usePusherUser = (message, callback, toWatch) => {
	return usePusher(getUserChannelId(), message, callback, toWatch)
}

export default usePusher
