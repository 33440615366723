import utils from '@eitje/web_utils'
import {useDrop, useDrag} from 'hooks'
import ReactHoverObserver from 'react-hover-observer'
import {Fragment} from 'react'
import SimpleContextWrapper from 'contexts/simple'
import './styles/index.less'

export const ShiftCard = ({
	item,
	canDrag: canDragProp,
	dropProps,
	dragProps = {},
	condOpts,
	env,
	selected,
	hasManagerRole,
	children,
	Component = 'div',
	WrapperComponent = Fragment,
	className,
	...rest
}) => {
	const canDrag = _.isBoolean(canDragProp) ? canDragProp : !item.reg_shift_id && hasManagerRole
	const {isOver, canDrop, dropRef} = useDrop({accept: '', ...dropProps})
	const {isDragging, dragRef} = useDrag({
		type: `shift`,
		item: {
			...item,
			envId: env?.id, // used on per team to prevent dropping in other envs
		},
		canDrag,
		...dragProps,
	})

	const classNames = utils.makeCns(
		'shift-card',
		isOver && canDrop && 'is-over',
		isOver && !canDrop && 'is-over-disabled',
		selected && `selected`,
		!hasManagerRole && 'no-manager-shift',
		className,
	)

	const contextValue = {...rest, item, canDrag, isDragging, isOver}

	return (
		<SimpleContextWrapper {...contextValue}>
			<ReactHoverObserver hoverDelayInMs={100}>
				{({isHovering}) => (
					<div ref={dragRef}>
						<WrapperComponent shift={item}>
							<Component {...condOpts} className={classNames} ref={dropRef} shift={item} {...rest}>
								{utils.funcOrVal(children, {...contextValue, isHovering})}
							</Component>
						</WrapperComponent>
					</div>
				)}
			</ReactHoverObserver>
		</SimpleContextWrapper>
	)
}
