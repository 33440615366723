import {EitjeModal} from 'components/common/index'
import utils from '@eitje/web_utils'
import {Routes} from 'components/routing'
import {SideMenu} from 'common/components'
import {useRouteMatch, useLocation} from 'react-router-dom'
import {getStringUpToSubstr} from 'helpers'
import {buildRoute} from 'common/components'
// import './styles/side_menu_modal.less'

export const NewSideMenuModal = ({routes, name, children, className, sortFn, sideMenuProps = {}, ...rest}) => {
	const match = useRouteMatch()
	const {path} = match
	const classNames = utils.makeCns('side-menu-modal', className)
	const loc = useLocation()
	const basePath = getStringUpToSubstr(loc.pathname, name)
	const sideMenuRoutes = routes.map(r => buildRoute(r, sideMenuProps, basePath))
	const menuProps = {...sideMenuRoutes, ...sideMenuProps, name}

	if (sortFn) {
		routes = routes.sortBy(item => sortFn(item))
		routes.forEach(route => {
			route.subRoutes = route.subRoutes.sortBy(item => sortFn(item))
		})
	}

	return (
		<EitjeModal
			className={classNames}
			LeftPanel={() => <SideMenu modal children={children} routes={routes} {...menuProps} path={path} />}
			name={name}
			goBackToForeground
			{...rest}
		>
			<Routes routes={routes} path={path} sideMenuRoutes={sideMenuRoutes} {...sideMenuProps} />
		</EitjeModal>
	)
}

export default NewSideMenuModal
