import {Layout} from 'common/components'
import {MessageHeaders, NavigationHeader, useMessageHeadersTaxonomy} from '..'
import {useApplicationHeaders} from './state'

export * from './state'

export const ApplicationHeaders = () => {
	useApplicationHeaders() // Trigger render on state change.

	return (
		<Layout className="application-headers" direction="vertical" relative gap={0}>
			<MessageHeaders />
			<NavigationHeader />
		</Layout>
	)
}
