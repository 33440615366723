import './styles/shift_card.less'

export const ShiftCardPlaceholder = () => (
	<div className="shift-card-placeholder">
		<div className="shift-card-placeholder-avatar" />
		<div className="shift-card-placeholder-content">
			<div className="shift-card-placeholder-title"></div>
			<div className="shift-card-placeholder-subtitle"></div>
		</div>
	</div>
)
