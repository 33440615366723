import {Input} from '@eitje/form-fields-web'
import {Err, FormRow} from 'components/ui'
import {useForm} from '@eitje/form'
import {Layout} from 'common/components'

const Form = ({fields = []}) => {
	const rows = _.chunk(fields, 2)
	const {errors} = useForm()
	const error = errors?.['base']
	return (
		<Layout className="activate-staffable-form eitje-form-2 eitje-form-2-use-borders" direction="vertical">
			{error && <Err error={error} />}
			{rows.map(r => (
				<FormRow>
					<Body fields={r} />
				</FormRow>
			))}
		</Layout>
	)
}

const fieldProps = {
	api_password: {
		secure: true,
	},
}

const Body = ({fields}) => {
	return fields.map(f => <Input field={f} {...(fieldProps[f] || {})} />)
}
export default Form
