import {Link} from 'components/routing'
import {BackButton, EitjeButton} from 'common/components'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {anyEnvRoleSelector} from 'selectors/new_settings'
import {CreateButton} from './components'

const Header = ({topic = {}, searchInput, RightButton = CreateButton, filter, returnToTopics}) => {
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager', topic.environment_ids))

	return (
		<div className="page-menu-container">
			<div className="page-menu">
				<div className="page-menu-options">
					{searchInput}
					{filter}
					{returnToTopics && (
						<Link to={`/topics`}>
							<BackButton onClick={_.noop} />
						</Link>
					)}
				</div>
				<div className="page-menu-options">
					<EitjeButton modalLink="/drawers/article_list" t="viewArticleList" />
					{isManager && <RightButton />}
				</div>
			</div>
		</div>
	)
}

export default Header
