import utils from '@eitje/web_utils'
import {date} from 'initializers/date'

export const TimeCell = ({getValue}) => {
	const time = getValue()
	if (_.isNumber(time)) {
		return utils.minutesToString(time)
	} else {
		const dateTime = date(time)
		if (dateTime.isDateTime()) {
			const formatted = dateTime.format('HH:mm')

			// e.g. occurs when an end time is not visible due to the shift type's settings
			if (formatted === 'Invalid date') return null

			return formatted
		}
	}
	return null
}

export const TimeCellWithZero = ({getValue}) => {
	const time = getValue()
	return _.isNumber(time) && utils.minutesToString(time)
}
