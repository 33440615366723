import {t} from 'initializers/i18n'
import {Button} from 'components/ui'
import {usePosIntegration} from 'cores/integrations/index'

const SyncRow = ({dates, envId}) => {
	return (
		<div className="revenue-modal-column revenue-modal-sync">
			<h4 className="revenue-modal-header"> {t('common.integration')} </h4>
			{dates.map(d => (
				<div className="revenue-modal-cell">
					<SyncButton envId={envId} date={d} />
				</div>
			))}
		</div>
	)
}

const SyncButton = ({date, envId}) => {
	const integration = usePosIntegration(envId)
	return (
		<Button className="sync-button" onClick={() => integration.sync(date)}>
			<img src="/images/web/icons/planning/sync.png" className="sync-icon" />
			{t('common.sync')}
		</Button>
	)
}

export default SyncRow
