import {Layout, Text, Icon, Title} from '@eitje/web_components'
import {exportT, ExportTable, Excel, useExportTaxonomy, tablesWithTooltips} from 'cores/exports2'
import {t} from 'initializers/i18n'
import {EitjeButton, DocsButton, EitjeDropdown, DocsPopout, PageLayout} from 'common/components'
import {history} from 'index'
import {getTableName} from 'helpers'
import {EitjeImage} from 'components/common'
import {Footer} from '../edit/footer'
import {useList} from 'hooks'

const WIDTH = 400

export const TableNew = () => {
	const {recordNames} = useExportTaxonomy()
	const {list} = useList({items: recordNames, ListItem: TableType})

	// TO DO: use a marginBottom temporarily to not make the content disappear underneath the footer, should be fixed
	// when we merge in Chris' PageLayout.
	return (
		<PageLayout className="export-table-new" horizontal="center" footers={<Footer />}>
			<Layout width={WIDTH} direction="vertical" gap={6} margin="48 - 120 -">
				<Title>{exportT('table_new.title')} </Title>
				{list}
				{/* we thought of adding the footer to new at the end of the project, that's why it's imported from Edit. It should all live in the same context, but that was too much effort */}
				<DocsButton docsId={7973781} />
			</Layout>
		</PageLayout>
	)
}

const TableType = ({item}) => {
	const tableName = getTableName(item)
	const namespace = 'exports2.table_new'
	const title = `exports2.dominant_record_name.${item}`
	const onClick = () => createTable(item)
	const inlineStyles = {borderRadius: 4}
	const hasTooltip = tablesWithTooltips.includes(item)
	const transPopout = key => t(`${namespace}.popout.${item}.${key}`)
	const popout = hasTooltip && {popoutTitle: transPopout('title'), popoutBody: transPopout('body')}

	const tables = ExportTable.all()
	const stockTables = tables.filter(t => t.kind() === 'stock' && t.table_name === item)
	const stockDropdown = stockTables.map(stock => ({element: <Text>{stock.name}</Text>, onClick: stock.duplicate}))
	const stocksDisabled = stockTables.length === 0 && t(`${namespace}.no_stocks_available`)

	const dropdownElements = [
		{element: <Text t="common.create_file" />, icon: 'document-blank', onClick},
		{title: <Text t="common.use_template" />, icon: 'document', children: stockDropdown, disabled: stocksDisabled},
	]

	return (
		<EitjeDropdown elements={dropdownElements} placement="right">
			<Layout
				onClick={onClick}
				colorSet="grey-bordered"
				horizontal="spaceBetween"
				width={WIDTH}
				padding={12}
				margin="6 - - -"
				border
				style={inlineStyles}
			>
				<Layout direction="vertical" width={WIDTH - 48}>
					<Text bold t={title} {...popout} PopoutComponent={popout && DocsPopout} docsId={7973781} />
					<Text truncate darkGrey>
						{exportT('table_new.subtitle', {name: tableName})}
					</Text>
				</Layout>
				<Icon name="arrow-small-right" initialFill={Colors.darkGrey} />
			</Layout>
		</EitjeDropdown>
	)
}

const createTable = async name => {
	const res = await ExportTable.create({
		table: name,
	})
	if (res.ok) {
		history.push(`/exports2/tables/${res.item.id}/edit`)
	}
}
