import {useFind} from '@eitje/easy_api'
import {ModalLink} from 'components/routing'
import useShared from 'hooks/use_shared'
import {EitjeButton} from 'common/components'

export const ContractLink = props => {
	const link = useContractLink(props)
	if (!link) return null
	return <ModalLink t="viewContract" to={link} />
}

export const ContractLinkBtn = props => {
	const link = useContractLink(props)
	if (!link) return null
	return <EitjeButton modalLink={link} className="contract-link-button" iconLeft="external-link" height="small" t="viewContract" />
}

const useContractLink = ({userId, dateRange}) => {
	const cont = useFind('contractHolders', {user_id: userId, dateRange})
	const {hasContractRole} = useShared()
	if (hasContractRole && cont.id) return `/forms/contracts/${cont.id}/details`
}

export default ContractLink
