import {API} from '@eitje/easy_api'
import {store} from 'index'
import {api as backend} from 'initializers/eitje_api'
import pluralize from 'pluralize'

const makeFileData = (data, paramName = 'file', extraParams = {}) => {
	const formData = new FormData()
	formData.append(paramName, data)
	formData.append('test', 'haha')
	Object.keys(extraParams).forEach(k => {
		formData.append(k, extraParams[k])
	})
	return formData
}

const makeVidPayload = (data, orgId) => {
	const formData = new FormData()
	formData.append('video_file', data)
	formData.append('external_owner_id', orgId)
	return formData
}

const handleProgress = ({loaded = 0, total = 0}) => {
	const tot = (loaded / total) * 100
	return Math.round(tot || 0)
}

export const upload = async (data, setProgress) => {
	const res = await _upload(data, {setProgress, url: 'files/upload'})
	if (res?.ok && res.data && res.data.message) return res.data.message
}

export const _upload = async (data, {extraParams, url, paramName, setProgress = _.noop, doLoad} = {}) => {
	if (!data || !data.length) return
	const files = Array.from(data)
	const file = files[0] // only signle upload now
	return backend.post(url, makeFileData(file, paramName, extraParams), {
		onUploadProgress: info => setProgress(handleProgress(info)),
		headers: {doLoad, Accept: 'application/json', 'Content-Type': 'multipart/form-data'},
	})
}

// video functions

const headers = {doNotLoad: true, Accept: 'application/json', 'Content-Type': 'multipart/form-data'}

// const _upload = async (data, setProgress = () => {}, url) => {
//   if (!data || !data.length) return
//   const files = Array.from(data)
//   const file = files[0] // only signle upload now
//   return backend.post(url, makeFileData(file), {onUploadProgress: info => setProgress(handleProgress(info)), headers})
// }

export const handleSuccessVideoUpload = res => {
	if (res.ok) {
		const {id, user_id, percentage = 0, step = 'Video Uploaded'} = res.data
		store.dispatch({type: 'SET_PROCESSING_VIDEO', item: {id, user_id, percentage, step}})
	}
}

export const _vidUpload = async (data, setProgress = () => {}, {url = 'eivid/videos/upload_video', orgId}) => {
	if (!data || !data.length || !orgId) return
	const files = Array.from(data)
	const file = files[0] // only signle upload now
	const payLoad = makeVidPayload(file, orgId)
	const res = await backend.post(url, payLoad, {onUploadProgress: info => setProgress(handleProgress(info)), headers})
	return res
}

export function getExtension(name) {
	if (!name) return null
	const split = name.split('.')
	return split[split.length - 1]
}

export const handleVideoRecordRemoval = async (videoId, assocRecord = {}, afterRemove = () => null) => {
	if (videoId && !_.isEmpty(assocRecord)) {
		const modal = Object.keys(assocRecord)[0]
		const res = await API.removeAssoc(pluralize(modal), {video_ids: [videoId], id: assocRecord[modal]})
		if (!res.ok) return
	}
	afterRemove()
}

export const getFileExtenstionColor = (_str = '') => {
	const str = _str.toUpperCase()
	let color
	switch (str) {
		case 'XLS':
			return 'rgba(64, 187, 107, 100)'
		case 'PDF':
			return 'rgba(255, 100, 97, 100)'
		case 'ZIP':
			color = 'rgba(108, 73, 250, 1)'
			break
		case 'IMG':
			color = 'rgba(38, 36, 138, 1)'
			break
		case 'MOV':
			color = 'rgba(228, 132, 76, 1)'
			break
		default:
			color = 'rgba(85, 149, 252, 1)'
	}
	return color
}
