import {t} from 'cores/time_registration/index'
import {EnvRouteSelect} from 'shared/components/env_route_select'

const Header = props => {
	return (
		<div className="settings-header">
			<h3>{t('settings')} </h3>
			<EnvRouteSelect modal shouldTranslate={false} urlFormat="/time_registration/:envId/settings" {...props} />
		</div>
	)
}

export default Header
