import {NAMESPACE} from 'cores/planning/pages/index/week_table_days_header'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useFilterFooterContext} from 'common/components'
import {useDaysHeaderMultiEdit} from 'common/components/week_table'
import {usePlanningContext} from 'cores/planning/index'

export function useEditShifts(date) {
	let {filteredItems: shifts} = useFilterFooterContext()
	const {dateRange} = usePlanningContext()
	if (!date) date = dateRange
	const {setMultiEdit, setSelectedShifts} = useEnvsContext()
	return useDaysHeaderMultiEdit({date, shifts, setMultiEdit, setSelectedShifts, role: 'manager', namespace: NAMESPACE})
}
