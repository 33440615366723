import {Input} from '@eitje/form-fields-web'
import {passwordRequirements} from 'cores/auth'
import {t} from 'initializers/i18n'
import {isEmail, isPass} from 'lib/form/validation'

const validatePassword = (val, {getFormData}) => {
	if (!isPass(val)) return passwordRequirements()
}

const validatePasswordConfirm = (val, {getFormData}) => {
	const {password, password_confirmation} = getFormData()
	if (password && password_confirmation && password != password_confirmation) return t('sysPassConf')
}

const sharedProps = {
	secure: true,
	required: true,
}

export const PasswordInput = props => {
	return <Input field="password" validate={validatePassword} {...sharedProps} {...props} />
}

export const PasswordConfirmInput = props => {
	return <Input field="password_confirmation" validate={validatePasswordConfirm} {...sharedProps} {...props} />
}

export const validateEmail = email => {
	if (!isEmail(email)) return t(`form.general.validation.email`)
}

export const EmailInput = props => {
	return <Input field="email" required validate={validateEmail} {...props} />
}
