import {t} from 'initializers/i18n'
import useUserOvertime from 'hooks/use_user_overtime'
import {AmtWorked} from 'cores/planning/index'
import ShiftsOverview from './shifts_overview'
import './styles/index.less'
import {EitjeAvatar} from 'common/components'

const UserRow = ({item, date, isActive}) => {
	const {timeString, available} = useUserOvertime(item.id)
	return (
		<div className="user-row">
			<div className="user-row-header">
				<div className="user-row-header-left">
					<EitjeAvatar user={item} />
					<div className="user-row-title">
						<h4>{item.full_name} </h4>
						{available && (
							<h5>
								{t('planning.team_panel.planned_hours_balance')} {timeString}
							</h5>
						)}
					</div>
				</div>
				<div className="user-row-header-right">
					<AmtWorked user={item} date={date} />
					<ExpandButton expanded={isActive} />
				</div>
			</div>
			{isActive && <ShiftsOverview user={item} />}
		</div>
	)
}

const ExpandButton = () => (
	<div className="dropdown-button">
		<img src="/images/web/icons/dropDown.png" className="dropdown-icon" />
	</div>
)

export default UserRow
