import {BasicPopout} from '@eitje/web_components'
import {EitjeButton, YouTubeModal} from 'common/components'
import {navigate} from 'components/routing'
import {NAMESPACE, useOnboardingModalTaxonomy, useOnboardingStatus} from 'cores/onboarding'

export const OnboardingStepModal = ({location}) => {
	const taxonomy = useOnboardingModalTaxonomy()
	const {completed: stepsProgress} = useOnboardingStatus()
	// Below should never hit, but add for safety during migration from old to new UI. Can delete later.
	if (!stepsProgress) return null

	const taxonomyKey = _.last(location.pathname.split('/'))
	const taxItem = taxonomy.find(taxItem => taxItem.id === taxonomyKey)
	const {id, youTubeId, tourId, tourLink, modalTitle, modalSubtitle, modalExtraInfoButton: extraInfo, modalActionButtonLabel} = taxItem
	const ns = `${NAMESPACE}.steps.start_tour`
	const prevStep = previousStep(stepsProgress, id)
	const {previousKey} = prevStep
	const previousStepCompleted = stepsProgress[previousKey]
	const startButton = {children: modalActionButtonLabel, colorSet: 'solid', iconLeft: 'arrow-small-hook-right'}

	const startTour = () => {
		navigate(tourLink)
		window.Intercom('startTour', tourId)
	}

	return (
		<YouTubeModal
			name="onboarding-video-modal"
			title={modalTitle}
			buttons={[
				extraInfo && (
					<BasicPopout
						title={extraInfo.title}
						text={extraInfo.body}
						trigger="click"
						buttons={<EitjeButton t={extraInfo.button} colorSet="solid" {...extraInfo?.popoutButton} />}
					>
						<EitjeButton children={extraInfo.label} iconLeft="exclamation-mark-circled" />
					</BasicPopout>
				),

				// If the previous step is NOT completed, show a popout with navigation back to the previous step.
				previousStepCompleted ? (
					<EitjeButton onClick={startTour} {...startButton} />
				) : (
					<PreviousStepButton id={id} prevStep={prevStep} startButton={startButton} />
				),
			]}
			youTubeId={youTubeId}
			subtitle={modalSubtitle}
		/>
	)
}

const PreviousStepButton = ({id, prevStep, startButton}) => {
	const taxonomy = useOnboardingModalTaxonomy()
	const {previousKey} = prevStep
	const previousTaxItem = taxonomy.find(taxItem => taxItem.id === previousKey)
	const {modalLink, stepTitle} = previousTaxItem

	return (
		<BasicPopout
			title={`${NAMESPACE}.texts.previous_step`}
			text={`${NAMESPACE}.steps.${id}.previous_step`}
			trigger="click"
			buttons={<EitjeButton modalLink={modalLink} children={stepTitle} colorSet="solid" iconLeft="arrow-small-hook-right" />}
		>
			<EitjeButton {...startButton} />
		</BasicPopout>
	)
}

const previousStep = (obj, key) => {
	const keys = Object.keys(obj)
	const index = _.findIndex(keys, k => k === key)

	// Usually we would subtract 1 to get the true index, but in this case, we need to readd the fake "create_environment" step,
	// which is not executable by the user and thus does not appear in the onboarding modal taxonomy, but which the user probably
	// expects us to count.
	const previousIndex = index
	const previousKey = keys[index - 1]

	if (index > 0) return {previousKey, previousIndex}
	return {}
}
