import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {PlanningShift, User, RevenueGroup} from 'models/index'
import {NewInputNumber as InputNumber} from 'components/form/input_number'

const Groups = ({data, setData, envId, dates, ...rest}) => {
	const revenueGroups = RevenueGroup.where({environment_id: envId})
	const totalGroup = {naam: t('common.total'), id: 'total'}

	const totalData = _.groupBy(data, 'date')
	const totalSummed = Object.values(totalData).map(dayArray => ({
		date: dayArray[0].date,
		omzet_groep_id: 'total',
		amt: dayArray.map(d => Number(d.amt)).sum(),
	}))

	const _setData = data => {
		setData(data)
	}
	return (
		<div className="revenue-modal-groups">
			<EmptySpaceFiller />
			{revenueGroups.map(r => (
				<RevenueGroupColumn
					className={revenueGroups.length == 1 && 'revenue-modal-group-total'}
					data={data}
					setData={_setData}
					dates={dates}
					group={r}
					{...rest}
				/>
			))}
			{revenueGroups.length > 1 && (
				<RevenueGroupColumn disabled className="revenue-modal-group-total" group={totalGroup} dates={dates} data={totalSummed} {...rest} />
			)}
		</div>
	)
}

const EmptySpaceFiller = () => {
	return (
		<div className="empty-space-filler">
			{_(8).times(i => (
				<div className="empty-space" />
			))}
		</div>
	)
}

const RevenueGroupColumn = ({group, className, dates, ...rest}) => {
	const classNames = utils.makeCns(className, 'revenue-modal-group', 'revenue-modal-column')

	return (
		<div className={classNames}>
			<h4 className="revenue-modal-header"> {group.naam} </h4>
			{dates.map(d => (
				<RevenueGroupInput {...rest} date={d} group={group} />
			))}
		</div>
	)
}

const RevenueGroupInput = ({date, dateDisabled = _.noop, data, setData, group, disabled}) => {
	// known issues: disabled doesn't work & group total ins't displayed, fix is in the form lib
	const iden = `${date}-${group.id}`
	const value = data.find(i => i.date == date && i.omzet_groep_id == group.id)?.amt
	return (
		<div className="revenue-modal-cell">
			<InputNumber
				max={1000000}
				disabled={dateDisabled(date) || disabled}
				financial
				onChange={val => setData({amt: val, date, date_group: iden, omzet_groep_id: group.id})}
				value={value}
			/>
		</div>
	)
}

export default Groups
