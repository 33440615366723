import {API} from '@eitje/easy_api'
import {getBillingInfo} from 'actions/billing'
import {getOrgAnalytics, getOrgStatus} from 'actions/environment'
import {getFileCachers} from 'actions/file_cacher'
import {getPlusMin} from 'actions/rooster'
import {getSettings} from 'actions/settings'
import {getTaxonomy} from 'actions/taxonomy'
import {getTraining} from 'actions/training'
import {cacheNames} from 'cores/exports2/constants'
import {getPeriods} from 'cores/time_registration/actions'
import {store} from 'index'
import {BaseConnection, Excel, ExportTable, ExtEnvironment, Invoice, LeaveRequest, Operation, ShiftType} from 'models'
import {roleSelector} from 'selectors/new_settings'
import {currentOrgSelector} from 'selectors/records'

export const getRecords = async () => {
	getFileCachers(cacheNames)
	await Promise.all([
		getTraining(),
		API.index('organisations', {refresh: true}),
		API.index('quiz_items'),
		API.index('topics'),
		getTaxonomy(),
		API.index('allowances'),
		API.index('sick_periods'),
		API.index('rooster_templates'),
		API.index('salaries'),
		API.index('infos'),
		API.index('employment_types'),
		API.index('posts'),
		API.index('events'),
		API.index('users', {params: {new: true}}),
		API.index('teams'),
		API.index('skill_sets'),
		API.index('skills'),
		API.index('contract_holders'),
		API.index('user_employment_types'),
		API.index('availability_template_shifts'),
		API.index('work_schedules'),
		API.index('work_weeks'),
		ShiftType.index(),
		LeaveRequest.index({filters: {pending: true}}),
		API.index('omzet_groepen'),
		API.index('omzet_teams'),
		API.index('team_template_days'),
		API.index('mail_subscriptions'),
		API.index('user_template_weeks'),
		Invoice.index(),
		Operation.index(),
		Excel.index(),
		ExportTable.index(),
		getSettings(),
		getPeriods(), // Moved up to any role to allow wage period filters on planning table page for everyone
	])
	const state = store.getState()
	const isManager = roleSelector(state, 'manager')
	const isAdmin = roleSelector(state, 'admin')
	const isWriter = roleSelector(state, 'uren_accorderen')
	const currentOrg = currentOrgSelector(state)
	getOrgStatus()

	if (isManager) {
		getPlusMin()
		getOrgAnalytics()
	}

	if (isWriter) {
		BaseConnection.index() // to make rev sync possible
		ExtEnvironment.index()
	}

	if (isAdmin) {
		getBillingInfo()
	}
}
