import React, {useEffect} from 'react'
import {useWhere} from '@eitje/easy_api'
import useShared from 'hooks/use_shared'
import {getTableName} from 'helpers'
import {ListPicker} from 'common/components'

const EnvPicker = ({roleEnvs = [], ...rest}) => {
	return <PickerArea items={roleEnvs} labelField="naam" {...rest} />
}

const OrgSelected = ({...rest}) => {
	const {org} = useShared()

	useEffect(() => {
		rest.onChange(org.id)
	}, [])

	return <PickerArea disabled hidden multiple={false} removeIcon {...rest} value={org.id} items={[org]} labelField="name" />
}

const TeamPicker = ({roleEnvs, ...rest}) => {
	const teams = useWhere('teams', {environment_id: roleEnvs.map(e => e.id)}, {joins: 'environments'})
	const _teams = teams.map(t => ({...t, envLabel: t.fullName()}))

	return <PickerArea labelField="envLabel" items={_teams} {...rest} />
}

const UserPicker = ({roleEnvs, ...rest}) => {
	const users = useWhere('users', {environment_ids: roleEnvs.map(e => e.id)})
	return <PickerArea items={users} labelField="full_name" {...rest} />
}

const PickerArea = ({items, kind, value, dataActive, placeholder, ...rest}) => {
	const val = dataActive ? value : []

	return (
		<ListPicker
			raw
			dropdown
			min={1}
			title={getTableName(kind)}
			value={val}
			placeholder={placeholder}
			items={items}
			showSearch={items.length > 10}
			{...rest}
		/>
	)
}

export const components = {
	organisation: OrgSelected,
	environment: EnvPicker,
	team: TeamPicker,
	contract_environment: EnvPicker,
	user: UserPicker,
}
