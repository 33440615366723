import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {API, useWhere} from '@eitje/easy_api'
import {ButtonMessageBox} from 'components/ui'
import {useEnvContext} from '../../contexts/env'
import {useAvContext} from 'contexts/availability'
import SimpleInputNumber from 'lib/form/fields/simple_input_number'
import {ModalLink} from 'components/routing'
import useTeamDay from 'cores/availability/hooks/use_team_day'
import '../../../styles/availability.less'
import './index.less'
import {EitjeButton} from 'common/components'

const TeamDayFooter = () => {
	const {env} = useEnvContext()
	const teams = useWhere('teams', {environment_id: env.id, locked: false, onroosterbaar: false})
	const hasTeams = teams.length > 0
	return (
		<div className="availability-of-environment-table">
			<div className="availability-of-environment-table-header">
				<div>
					<h4>{env.naam}</h4>
					<h5>{t('avTeamLockSubhead')}</h5>
				</div>
				{hasTeams && (
					<div className="right-side-header-options">
						<ButtonMessageBox text={t('autoLockDesc')} />
						<EitjeButton modalLink={`/availability/${env.id}/team_days`} iconLeft="three-sliders" t="setDefault" />
					</div>
				)}

				{!hasTeams && (
					<ModalLink className="new-team-button" to={`/team/new?environment_id=${env.id}`}>
						<h5>
							<img src="/images/web/icons/planning/plus.png" className="plus-icon" />
							{t('createTeam')}
						</h5>
					</ModalLink>
				)}
			</div>
			{teams.map(t => (
				<TeamDayRow item={t} />
			))}
		</div>
	)
}

const TeamDayRow = ({item}) => {
	const {dates} = useAvContext()
	const amtUsers = item.user_ids.length
	return (
		<div className="availability-of-environment-table-row">
			<div className="availability-of-environment-table-first-cell-of-row">
				<h5 className="team-name">{item.naam} </h5>
				<h5>
					{amtUsers} {t('teammember', {count: amtUsers})}
				</h5>
			</div>

			{dates.map(d => (
				<TeamDayForm item={item} date={d.format('YYYY-MM-DD')} />
			))}
		</div>
	)
}

const TeamDayForm = ({date, item}) => {
	const query = {date: date, team_id: item.id}
	const td = useTeamDay(query)

	return (
		<div className="availability-of-environment-table-cell">
			<div className="availability-of-environment-table-inner-cell">
				<Form
					overrideInitialValues
					initialValues={{min_av_count: null, ...td}}
					throttleTime={1000}
					onSubmit={data => API.create('team_days', {...data, ...query})}
				>
					<SimpleInputNumber submitStrategy="throttledChange" field="min_av_count" max={item.user_ids.length} min={1} />
				</Form>
			</div>
		</div>
	)
}

export default TeamDayFooter
