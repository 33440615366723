import useSelector from 'hooks/use_selector'
import User from 'models/user'
import {schedulableUsersSelector, unschedulableOrgUserIdsSelector} from 'selectors/v3/user'
import {contractJoins as opts} from 'constants/users'

export const useSchedulableUsers = env => {
	return useSelector(schedulableUsersSelector, env)
}

export const useSchedulableOrgUsers = () => {
	const onroosterbaar_user_ids = useSelector(unschedulableOrgUserIdsSelector)
	return User.whereNot(onroosterbaar_user_ids, opts)
}

// This is the most correctl logic. Above hook groups all environments,
// instead of considering them seperately
export const useUnschedulableOrgUsers = envs => {
	const envsUsers = User.where({environment_ids: envs.ids()})
	return envsUsers.filter(user =>
		user.environment_ids.every(envId => {
			const env = envs.find(e => e.id === envId)
			return !env || env.onroosterbaar_user_ids.includes(user.id)
		}),
	)
}

export default useSchedulableUsers
