import {useAvShifts} from 'cores/availability/hooks/use_av_shift'
import useSchedulableUsers from 'hooks/use_schedulable_users'
import {useFind} from '@eitje/easy_api'
import utils from '@eitje/web_utils'

const useTeamUsers = team => {
	const users = useSchedulableUsers(team.environment_id)
	const {user_ids = []} = team
	const teamUsers = users.filter(u => user_ids.includes(u.id))
	return teamUsers
}

const useTeamAvUsers = ({team, date, userId, shift}) => {
	const shiftUser = useFind('users', userId)
	let teamUsers = useTeamUsers(team)
	if (!teamUsers.map(u => u.id).includes(userId)) {
		teamUsers = [...teamUsers, shiftUser].filter(u => utils.exists(u))
	}

	const userIds = [...(team?.user_ids || []), userId].filter(u => utils.exists(u)).uniq()

	const avUsers = useAvShifts({date, envId: team.environment_id, user_ids: userIds, shift})
	const mergedUsers = teamUsers.map(u => ({...avUsers.find(av => av.user_id == u.id), ...u}))
	return mergedUsers
}

export {useTeamUsers, useTeamAvUsers}
