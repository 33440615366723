import {all, API, find, where} from '@eitje/easy_api'
import {LegacyInput as Input, LegacySwitch as Switch} from '@eitje/form-fields-web'
import {PopoutCard} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import FileDragAndDrop from 'components/drag_and_drop'
import FileList from 'components/file_list'
import useList from 'components/general/list'
import PdfViewer from 'components/pdf_viewer'
import {UserAvatar, WarningBanner, RoundedRow} from 'components/ui'
import useParams from 'hooks/use_params'
import {history} from 'index'
import Form from 'initializers/form'
import {mdT, t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {envUsers} from 'selectors/entities'
import {anyEnvRoleSelector} from 'selectors/new_settings'
import {whereInc} from 'selectors/records'
import ArticleViewer from '../training/article'
import {PdfDropBox} from './blank'
import ArticleEditor from './editor'
import '../styles/articles.less'
import {BackButton, DeleteButton, SubmitButton, ListPicker, Text, PageLayout} from 'common/components'

const itemProps = {
	kind: 'info',
	small: true,
}

const removeResizeIcon = content => {
	if (!content || !_.isString(content)) return
	const startIconHtml = content.indexOf('<span id="arx-image-resizer"')
	if (startIconHtml === -1) return content
	const splitSting = content.split('<span id="arx-image-resizer"')
	const bHtml = splitSting[0]
	const indexOfEndingHtml = splitSting[1].indexOf('></span>')
	const eHtml = splitSting[1].substring(indexOfEndingHtml + 8)
	return bHtml + eHtml
}

const submitArticle = data => {
	const contentEl = document.querySelector('#editor')
	const content = contentEl?.value
	if (content) {
		data['content'] = removeResizeIcon(content)
	}
	return API.update('infos', data)
}

const afterSubmit = (res, data, {touchedFields = []} = {}) => {
	if (data['published'] && touchedFields.includes('published')) {
		utils.openNotif(t('saved'), 'success', t('article_published'))
	}
}

const Edit = () => {
	const [touched, setTouched] = useState(false)
	const form = useRef(null)
	const {id} = useParams()
	const item = useSelector(state => find(state, 'infos', id))
	const isManager = useSelector(state => anyEnvRoleSelector(state, 'manager', item.environment_ids))

	const container = useRef(null)
	const {pdf_content, pdf_leading, content, topic_ids} = item
	const isLoose = _.isEmpty(topic_ids)
	const topics = useSelector(state => all(state, 'topics'))
	const isView = !isManager

	useEffect(() => {
		API.arbitrary('infos', `infos/${id}/read`, {extraParams: {doNotLoad: true}})
	}, [])

	const subscribe = {
		'editor.focus': () => setTouched(true),
	}

	useEffect(() => {
		if (touched) {
			form.current.touch()
		}
	}, [touched])

	if (_.isEmpty(item)) {
		return <Redirect push to="/topics" />
	}

	if (isView) {
		const header = (
			<div className="page-menu-container">
				<div className="page-menu">
					<div className="page-menu-options">
						<BackButton />
					</div>
				</div>
			</div>
		)
		return (
			<PageLayout name="article-show" headers={header}>
				<div className="" style={{padding: '80px'}}>
					<ArticleViewer item={item} likePosition={'top'} />
				</div>
			</PageLayout>
		)
	}

	const header = (
		<div className="page-menu-container">
			<div className="page-menu">
				<div className="page-menu-options">
					<BackButton />
				</div>
				<div className="page-menu-options">
					<DeleteButton afterDel={() => history.push('/topics')} onDel={() => API.destroy('infos', id)} />
					<SubmitButton disabled={!touched} onClick={() => form.current && form.current.submit()} />
				</div>
			</div>
		</div>
	)

	return (
		<PageLayout name="article-edit" ref={container} headers={header} bodyLayoutProps={{block: true}}>
			<div className="article-edit-page-content">
				<div className="article-editor-container">
					<Form
						afterSubmit={afterSubmit}
						afterTouch={setTouched}
						styleKind="general"
						onSubmit={data => submitArticle(data)}
						initialValues={item}
						ref={form}
					>
						{!item.published && <WarningBanner type={'article'} />}

						<div className="article-title-header row margin-bottom-sm gap-xss" fieldWrapper>
							<div className="text-input-container" fieldWrapper>
								<Input className="bold" bordered={false} maxLength={75} labelVisible={false} submitStrategy={'blur'} field="title" />
							</div>

							<ListPicker
								raw
								dropdown
								single
								Trigger={props => <RoundedRow {...props} dropdown style={{width: '233px'}} containerClass="vertical-padding-md" />}
								field="pdf_leading"
								submitStrategy="change"
								items={[
									{name: t('articleEditor'), id: false},
									{name: t('pdfEditor'), id: true},
								]}
							/>
						</div>

						{!pdf_content && !pdf_leading && (
							<div fieldWrapper className="editorContainer">
								<ArticleEditor subscribe={subscribe} content={content} />
							</div>
						)}

						{pdf_content && <PdfViewer onRemove={() => API.update('infos', {id, remove_pdf_content: true})} pdf={pdf_content} />}

						{!pdf_content && pdf_leading && (
							<div className="fRow jCen" style={{marginTop: '48px'}}>
								<FileDragAndDrop
									custom
									accept="application/pdf"
									onDrop={file => API.updateAsset('infos', id, file)}
									Component={PdfDropBox}
								/>
							</div>
						)}

						<div className="article-side-panel" fieldWrapper>
							<div className="article-side-panel-container" fieldWrapper>
								<div className="row-with-border-and-padding-sm full-width" fieldWrapper>
									<Switch field="published" label={t('published')} submitStrategy="change" className="" />
								</div>

								<ListPicker
									raw
									dropdown
									showCount
									labelField="title"
									Trigger={props => <RoundedRow {...props} style={{minHeight: '48px'}} containerClass="vertical-padding-sm" dropdown />}
									title={t('records.theme.name_plural')}
									placeholder={t('addToTopicsShort')}
									field="topic_ids"
									items={topics}
								/>
								<ReadStats item={item} />
								<FileDragAndDrop
									text={t('addFiles')}
									placeholder={t('addFilesSub')}
									onDrop={async file => await API.attach('infos', id, file)}
								/>
								{pdf_content && (
									<FileDragAndDrop
										text={t('replacePdf')}
										accept="application/pdf"
										onDrop={async file => await API.updateAsset('infos', id, file)}
									/>
								)}
								<FileList id={id} itemProps={itemProps} items={item.attachments} smallFileItem />
							</div>
						</div>
					</Form>
				</div>
			</div>
		</PageLayout>
	)
}

export default Edit

const ItemReader = ({item}) => {
	return (
		<>
			<UserAvatar user={item} className="margin-right-sm" />
			<div>
				<p>{item.full_name} </p>
				{item.count && <p className="eitje-text-secondary"> {t('readerCount', {count: item.count})}</p>}
			</div>
		</>
	)
}

export const ReadersList = ({title, items, containerClass = ''}) => {
	const {list} = useList({items: items, ListItem: ItemReader})

	return (
		<div className={'reader-list-container ' + containerClass}>
			<div className="reader-list-header">
				<h3 className="eitje-heading-xl">{t(title)}</h3>

				<p className="eitje-text-secondary">({items.length})</p>
			</div>
			<div className="reader-list">{list}</div>
		</div>
	)
}

const ReadStats = ({item}) => {
	// readers includes deleted users / peeps from other envs
	let {published, readers = [], topic_ids = []} = item
	const topics = useSelector(state => where(state, 'topics', topic_ids)) // can be an empty array
	const teamIds = topics.map(t => t.team_ids).flat() // can be an empty array

	const teamUsers = useSelector(state => whereInc(state, 'users', {team_ids: teamIds}))
	const allUsers = useSelector(envUsers)
	const allUserIDs = allUsers.map(user => user.id)
	const _readers = readers.filter(reader => allUserIDs.includes(reader.user_id))

	const users = teamIds.length > 0 ? teamUsers : allUsers

	readers = _readers.map(r => ({
		...r,
		...(users.find(u => u.id === r.user_id) || {}),
	}))

	const readCount = utils.sum(_readers, 'count')

	const readerIds = readers.map(r => r.user_id)
	const nonReaders = users.filter(u => !readerIds.includes(u.id))

	return (
		<div className="column-with-border-and-padding-xl">
			<div className="margin-bottom-xl full-width">
				<h3 className="eitje-heading-xl">{t('article')}</h3>
				<p className="grey-text">{t('readStats')}</p>
			</div>

			{published && (
				<div className="emphasized-text-block">
					<Text>{mdT('articleRead', {count: readCount})}. </Text>

					<PopoutCard bodyPadding={0} body={<ReadersList title="read" items={readers} />}>
						<Text>{mdT('articleReaders', {count: readers.length})}</Text>
					</PopoutCard>

					<PopoutCard bodyPadding={0} body={<ReadersList title="unread" items={nonReaders} />}>
						<Text className="noCapital"> {mdT('articleNonReaders', {count: nonReaders.length})}.</Text>
					</PopoutCard>
				</div>
			)}
			{!published && (
				<div className="emphasized-text-block">
					<span className="vertical-padding-sm"> {t('noTrainingReadStats')} </span>
				</div>
			)}
		</div>
	)
}
