import {Drawer as AntDrawer} from 'antd'
import useModalBack from 'hooks/use_modal_back'

const Drawer = props => {
	const {goBack} = useModalBack(props)

	return <AntDrawer visible onClose={goBack} {...props} />
}

export default Drawer
