import {PrivateRoute, ProtectedRoute} from 'components/private_route'
import MultiEdit from 'cores/planning/pages/multi_edit'
import {HandleErrors} from './pages'

import {
	PlanningIndex,
	NewPlanningShift,
	EditPlanningShift,
	LoadPlanningTemplate,
	SavePlanningTemplate,
	PublishPlanning,
	ManagePlanningTemplates,
	ClearWeek,
	TeamPanel,
	Settings,
	PlanningTable,
} from 'cores/planning/index'
import {ShiftTypes} from 'cores/planning/pages/shift_types/index'

const planningForegroundRoutes = [
	<PrivateRoute path="/planning" component={PlanningIndex} />,
	<PrivateRoute path="/planning_table" component={PlanningTable} />,
]

const planningBackgroundRoutes = [
	<PrivateRoute path="/shifts/new" component={NewPlanningShift} />,
	<PrivateRoute path="/planning_templates/save" component={SavePlanningTemplate} />,
	<PrivateRoute path="/planning_templates/load" component={LoadPlanningTemplate} />,
	<PrivateRoute path="/planning_templates" component={ManagePlanningTemplates} />,
	<PrivateRoute path="/planning/clear_week" component={ClearWeek} />,
	<PrivateRoute path="/planning/publish" component={PublishPlanning} />,
	<PrivateRoute path="/planning/team_panel" component={TeamPanel} />,
	<PrivateRoute path="/planning_shifts/multi_edit" component={MultiEdit} />,
	<ProtectedRoute path="/planning_shifts/handle_errors" role="manager" component={HandleErrors} />,
	<ProtectedRoute role="admin" path="/environment_planning/:envId/planning/settings" component={Settings} />,
	<PrivateRoute path="/planning_shifts/:id" component={EditPlanningShift} exact />,
	<ProtectedRoute path="/shift_types" role="admin" component={ShiftTypes} />,
]

export {planningBackgroundRoutes, planningForegroundRoutes}
