import {API} from '@eitje/easy_api'
import {Input} from '@eitje/form-fields-web'
import useParams from 'hooks/use_params'
import {history} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React from 'react'
import {useSelector} from 'react-redux'
import {allRole} from 'selectors/new_settings'
import './styles/new.less'
import {ListPicker} from 'common/components'
import {ContextForm} from '@eitje/form'
import {EitjeModal} from 'components/common'
import FormRow from 'components/form_row'

const New = () => {
	const {id} = useParams()
	let topics = useSelector(state => allRole(state, 'topics', 'manager')) || []

	const afterSubmit = res => {
		const infoId = res.item.id
		history.replace(`/articles/${infoId}/blank`)
	}

	return (
		<ContextForm afterSubmit={afterSubmit} initialValues={{topic_ids: [id].filter(Boolean)}} onSubmit={data => API.create('infos', data)}>
			<EitjeModal form name="create-article" className="eitje-form-2 eitje-form-2-use-borders" title={t('createArticle')}>
				<FormRow>
					<Input label={t('title')} bordered={false} maxLength={75} required field="title" />
				</FormRow>

				{!_.isEmpty(topics) && (
					<FormRow>
						<ListPicker
							easyRemove
							dropdown
							label={t('addToTopics')}
							labelField="title"
							field="topic_ids"
							title={t('header.topics')}
							showSearch
							items={topics}
						/>
					</FormRow>
				)}
			</EitjeModal>
		</ContextForm>
	)
}

export default New
