import utils from '@eitje/web_utils'

const initialState = {
	seenUpdates: [],
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'READ_WHATS_NEW':
			const id = utils.alwaysDefinedArray(action.id)
			return {
				...state,
				seenUpdates: [...state.seenUpdates, ...id].uniq(),
			}
		default:
			return state
	}
}
