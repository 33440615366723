import {Icon} from '@eitje/web_components'
import {Layout, NewSideMenuModal} from 'common/components'
import MapEnvs from '../setup_hr/map_envs'
import GroupOrZone, {Progress} from './group_or_zone'
import MapGroups from './map_groups'
import {Settings} from './settings'
import {BaseConnection, Environment, RevenueGroup, ExtEnvironment} from 'models'
import {useParams} from 'hooks'
import {Text} from 'common/components'
import {t} from 'initializers/i18n'
import {useIntegrationConstants, MenuItem, ModalTitle, useIntegration} from 'cores/integrations'

const Decider = ({isSub, ...props}) => (isSub ? <SubItem {...props} /> : <MenuItem {...props} />)

const SubItem = ({text, route}) => {
	const {item} = route
	const groups = RevenueGroup.where({environment_id: item.id})
	const done = groups.some(g => g.integrated())

	return (
		<Layout gap={12}>
			{done && <Icon name="checked" />}
			<Text truncate> {text} </Text>
		</Layout>
	)
}

const routes = [
	{
		name: 'environments',
		component: MapEnvs,
	},
	{
		name: 'group_or_zone',
		component: GroupOrZone,
	},
]

const settingsRoute = {
	name: 'settings',
	component: Settings,
}

export const SetupRevenue = () => {
	const {id} = useParams()
	const item = BaseConnection.find(id)
	const {currentStep} = useIntegration(item.id)
	const extEnvironments = ExtEnvironment.where(item.ext_environment_ids)
	const envs = Environment.where({id: extEnvironments._map('environment_ids').flat()})

	const mapGroupsRoute = {
		name: item.grouping_type,
		Sub: Progress,
		subRoutes: envs.map(e => ({
			name: e.naam,
			component: MapGroups,
			path: e.id,
			item: e,
		})),
	}
	const {hasSalesAreas, hasOnlyPaid} = useIntegrationConstants(item.sanitized_type)
	const baseRoutes = hasSalesAreas ? routes : [routes[0]]

	const _routes = [...baseRoutes, mapGroupsRoute, hasOnlyPaid && settingsRoute].filter(Boolean)

	const contextProps = {item, extEnvironments}

	const linkDisabled = ({index}) => {
		if (index == 0) return
		return envs.length == 0
	}

	const sideMenuProps = {
		MenuItem: Decider,
		initialRoute: currentStep,
		linkDisabled,
		disabledPopoutTitle: t('common.first_complete_this_step'),
	}

	return (
		<NewSideMenuModal
			hideCancel
			redirectBack={!item.id}
			title={<ModalTitle item={item} envs={envs} />}
			width={600}
			height={500}
			linkDisabled={linkDisabled}
			contextProps={contextProps}
			sideMenuProps={sideMenuProps}
			name="setup_revenue"
			routes={_routes}
		/>
	)
}
