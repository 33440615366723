import {t, Excel, ExportButton} from 'cores/exports2/index'
import {useSimpleContext} from 'contexts/simple'
import {useList} from 'hooks/index'
import {t as baseT} from 'initializers/i18n'
import {DeleteButton, DownloadLink, EitjeButton} from 'common/components'
import {useFind} from '@eitje/easy_api'
import './styles/index.less'

import utils from '@eitje/web_utils'

export const Excels = () => {
	const {item} = useSimpleContext()
	const excels = Excel.where({table_id: item.id})

	const placeholderProps = {
		name: 'export_excel',
		animation: 'file-with-barchart',
		docsId: '7973781',
		tourId: '447862',
		children: <ExportButton colorSet="solid" id={item.id} />,
	}

	const items = [...excels, ...item.audits]

	const {list} = useList({items, placeholderProps, ListItem: Decider, sortField: 'updated_at'})

	return (
		<div className="export-tables-show-excels">
			<h3>{t('table_show.excels_title')} </h3>
			{list}
		</div>
	)
}

const format = "DD MMM 'YY"
const timeFormat = `${format} HH:mm`

const Decider = props => (props.item.startDate ? <ExcelRow {...props} /> : <Audit {...props} />)

const Audit = ({item}) => {
	const {user_id, updated_at} = item
	const user = useFind('users', user_id)
	return <h5 className="table-audit"> {t('table_show.excel_audit', {user: user.full_name, time: updated_at})} </h5>
}

const ExcelRow = ({item}) => {
	const start = item.startDate().format(format)
	const end = item.endDate().format(format)
	const {status} = item
	const classNames = utils.makeCns('export-tables-show-excel', `excel-${status}`)

	const periodText = item.hasPeriod() ? `${start} ${baseT('common.until')} ${end}` : t('table_show.no_dates')

	return (
		<div className={classNames} a>
			<h5 className="exported-period">
				{baseT('common.period')}: {periodText}
			</h5>
			<StatusString item={item} />
			<StatusActions item={item} />
		</div>
	)
}

const StatusActions = ({item}) => {
	const {status, download_url} = item
	if (status == 'failed')
		return (
			<div className="excel-actions">
				<EitjeButton iconLeft="arrow-circle" onClick={() => item.retry()} text={baseT('common.retry')} />
				<DeleteButton onDel={() => item.destroy()} />
			</div>
		)
	return (
		<div className="excel-actions">
			<DownloadLink iconLeft="download" disabled={!download_url} to={download_url} />
			<DeleteButton onDel={() => item.destroy()} />
		</div>
	)
}

const StatusString = ({item}) => {
	const {status, id} = item
	let text = ''

	if (status == 'failed') {
		text = `${t('table_show.excel_failed')} #${id}`
	} else if (status == 'completed') {
		text = `${baseT('common.file_made')} ${item.createdAt().format(timeFormat)}`
	} else {
		text = `${t('table_show.excel_busy')}`
	}

	return <h5 className="status-string"> {text} </h5>
}
