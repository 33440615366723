import {t} from 'initializers/i18n'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import {Redirect} from 'components/routing'
import PredictionForm from './prediction_form/index'
import {Switch as RouteSwitch, Route, useRouteMatch} from 'react-router-dom'
import Import from './import'
import './styles/index.less'
import {RouteToggle} from 'components/ui'

const Settings = () => {
	const {url} = useRouteMatch()
	const predictionUrl = `${url}/prediction`
	const importUrl = `${url}/import`
	const {envId} = useParams()

	const items = [
		{name: t('revenue.revenue_modal_rev_settings'), path: predictionUrl},
		{name: t('revenue.revenue_modal_rev_import'), path: importUrl},
	]

	return (
		<Modal className="revenue-settings-modal">
			<div className="tabs-container">
				<RouteToggle items={items} modal />
			</div>
			<RouteSwitch>
				<Route path={predictionUrl} render={props => <PredictionForm {...props} envId={envId} />} />
				<Route path={importUrl} component={() => <Import envId={envId} />} />
				<Redirect to={predictionUrl} />
			</RouteSwitch>
		</Modal>
	)
}

export default Settings
