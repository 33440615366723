import {buildColumn, XLARGE_CELL_WIDTH} from 'common/components/advanced_table'

export const columns = [
	{
		id: 'user_id',
		accessorFn: leaveRequest => leaveRequest.user?.full_name,
		width: XLARGE_CELL_WIDTH,
		collapsibleCell: true,
		aggregatesFn: 'countUniqueValues',
		aggregates: true,
	},
	{
		id: 'status',
		accessorFn: leaveRequest => leaveRequest.status(),
		cell: 'StatusCell',
	},
	{
		accessorKey: 'reden',
		width: XLARGE_CELL_WIDTH,
	},
	{
		id: 'start_datetime',
		accessorFn: leaveRequest => leaveRequest.startDate(),
		cell: 'DateCell',
	},
	{
		id: 'end_datetime',
		accessorFn: leaveRequest => leaveRequest.endDate(),
		cell: 'DateCell',
	},
	{
		accessorKey: 'total_mins',
		cell: 'TimeCell',
		aggregates: true,
	},
	{
		id: 'amt_days',
		accessorFn: leaveRequest => leaveRequest.days(),
		aggregatesFn: 'sumValues',
		aggregates: true,
	},
	{
		accessorKey: 'environment_id',
		cell: 'EnvironmentCell',
		collapsibleCell: true,
		aggregatesFn: 'countUniqueValues',
	},
	{
		accessorKey: 'created_at',
		cell: 'DateCell',
		defaultVisible: false,
	},
	{
		accessorKey: 'processor_id',
		cell: 'UserCell',
	},
	{
		id: 'processed_at',
		accessorFn: leaveRequest => leaveRequest.processed_at,
		cell: 'DateTimeCell',
		defaultVisible: false,
	},
].map(buildColumn)
