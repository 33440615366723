import {backend} from '@eitje/easy_api'
import {store} from 'index'

export const getBillingInfo = async (id, afterSucc = () => {}) => {
	const res = await backend.get('billing_info')
	if (res.ok && res?.data?.item) {
		const {item} = res.data
		store.dispatch({type: 'INDEX_RECORDS', force: true, kind: 'billingInfos', items: [item]})
	}
}
