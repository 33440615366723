import {BaseConnection, ExtEnvironment, Environment, Allowance} from 'models'
import {EitjeModal} from 'components/common/index'
import {useEffect} from 'react'
import {ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {ContextForm, useForm} from '@eitje/form'
import {useGet, useFormData} from 'hooks'
import {date} from 'initializers/date'
import {FormRow} from 'components/ui'
import {history} from 'index'
import {useRoleEnvs} from 'hooks'
import utils from '@eitje/web_utils'
export const ExportHours = () => {
	const item = BaseConnection.show()
	const {data} = useGet(item.buildUrl('periods'))
	const {periods} = data
	const submit = data => {
		const envPeriods = periods[data.environment_id]
		const periodObj = envPeriods.find(p => p.start_date == data.period)
		return item.exportHours({...data, period: periodObj})
	}

	const extEnvs = ExtEnvironment.where(item.ext_environment_ids)

	const roleEnvIds = useRoleEnvs('financieel').ids()
	const envIds = extEnvs
		._map('environment_ids')
		.flat()
		.filter(id => roleEnvIds.includes(id))
	const envs = Environment.where(envIds)

	const initialValues = envs.length == 1 ? {environment_id: envs[0].id} : {}
	const afterSubmit = () => {
		utils.successNotif({content: t('integrations.hr.started_export', {integ_name: item.name})})
		history.goBack()
	}

	return (
		<ContextForm
			fieldProps={{required: true}}
			transNamespace="integration_export_hours"
			className="eitje-form-2-use-borders"
			onSubmit={submit}
			afterSubmit={afterSubmit}
			initialValues={initialValues}
		>
			<EitjeModal redirectBack={envs.length == 0} form name="export_hours" title={t('integrations.hr.export_to', {integName: item.name})}>
				<Inner envs={envs} periods={periods} item={item} />
			</EitjeModal>
		</ContextForm>
	)
}

const types = ['fixed', 'flex']
const exportOpts = ['hours', 'meals', 'days', 'allowances']

const extraOpts = ['leave', 'sick']

const prefix = `integrations.hr.export_hours`

const Inner = ({item, envs, periods = {}}) => {
	const {setValues} = useForm()

	const {hideContractType, showImproductive, hide: hideOpts = []} = item.export || {}

	const {environment_id, contract_type} = useFormData()
	const env = envs.find(e => e.id == environment_id)
	const allowances = Allowance.where({environment_id: env?.id})

	let envPeriods = periods[environment_id] || []
	envPeriods = envPeriods.map(periodMapper)
	let opts = exportOpts

	if (showImproductive) opts = [...opts, ...extraOpts]

	// if(!env.meal_code) opts = opts.filter( o => o != 'meals')
	if (contract_type == 'fixed') opts = opts.filter(o => o != 'hours')

	opts = opts.filter(o => !hideOpts.includes(o))

	opts = opts.map(i => ({name: `${t(`common.${i}`)}`, id: i}))

	const itemDisabled = item => {
		const {name} = item
		if (name == 'maaltijden' && !env?.meal_code) return `${prefix}.no_meal`
		if (name == 'toeslagen' && allowances._filter('integration_payroll_component_id').length == 0) return `${prefix}.no_allowance`
	}

	useEffect(() => {
		setValues({export_opts: []})
	}, [contract_type])

	return (
		<div className="eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<ListPicker disabled={envs.length == 1} dropdown hideClearIcon labelField="naam" field="environment_id" single items={envs} />
			</FormRow>
			{environment_id && (
				<>
					{!hideContractType && (
						<FormRow>
							<ListPicker dropdown field="contract_type" single items={types} label={t('records.employmentType.name')} />
						</FormRow>
					)}

					<FormRow>
						<ListPicker dropdown field="period" noSort valueField="start_date" single items={envPeriods} />
					</FormRow>

					<FormRow>
						<ListPicker easyRemove dropdown itemDisabled={itemDisabled} items={opts} field="export_opts" />
					</FormRow>
				</>
			)}
		</div>
	)
}

const periodMapper = period => {
	const {period_number, start_date, year, end_date} = period
	const startDate = date(start_date).format('DD/MM/YY')
	const endDate = date(end_date).format('DD/MM/YY')
	const name = `${t('common.period')} ${period_number} - ${year} (${startDate} - ${endDate})`
	return {...period, name}
}
