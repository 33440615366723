import {PrivateRoute} from 'components/private_route'

import Nmbrs from 'exports/hours/nmbrs'
import Export from 'exports/export'

const routes = [
	<PrivateRoute path="/static_exports/NmbrsUpload" component={Nmbrs} />,
	<PrivateRoute path="/static_exports/:id" component={Export} />,
]

export default routes
