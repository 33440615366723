import {t} from 'initializers/i18n'
import {useFind, useGet} from '@eitje/easy_api'
import {Redirect} from 'components/routing'
import {PlanningShift} from 'models/index'
import ShiftForm from './form/index'
import {ResourceFetcher} from 'components/shared/index'

const EditPlanningShift = () => {
	const shift = PlanningShift.show()
	const {data} = useGet(`planning_shifts/${shift.id}/audits`)
	const {audits = []} = data
	const team = useFind('teams', shift.team_id)
	const env = useFind('environments', team.environment_id)
	if (!shift.id) return <Redirect to="/planning" />

	return (
		<>
			<ShiftForm
				title={t('planning.shift_modal.edit_shift')}
				shift={{...shift, audits}}
				environment={env}
				initialValues={{...shiftMapper(shift), env_id: env.id}}
			/>
			{shift.reg_shift_id && <ResourceFetcher kind="time_registration_shifts" id={shift.reg_shift_id} />}
		</>
	)
}

const shiftMapper = shift => {
	return {
		...shift,
		from: shift.startTime(),
		till: shift.rawEndTime(),
		dates: [shift.date],
		pauze_duur: shift.breaktimeString(),
	}
}

export default EditPlanningShift
