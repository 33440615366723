import {useState} from 'react'
import {PlanningShift} from 'models'
import {date} from 'initializers/date'
import {makeRange} from 'helpers/date'
import {Association} from 'models/index'

export const Where = () => {
	const [num, setNum] = useState(0)
	const shifts = PlanningShift.where({date: date('2022-01-01')})
	const _query = {date: makeRange('2022-01-01', '2022-01-02')}
	const _m = moment
	const m = date()
	const m2 = date()
	const a = Association
	debugger

	return (
		<div>
			<p onClick={() => setNum(num + 1)}> increment </p>
			<Increment query={_query} name="moment" />
		</div>
	)
}

const Increment = ({name, query}) => {
	const [num, setNum] = useState(0)
	const shifts = PlanningShift.where(query)
	return (
		<div>
			<h3>{name} </h3>
			<p onClick={() => setNum(num + 1)}> increment: {num} </p>
		</div>
	)
}
