import {API} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker, LegacyInput as Input, LegacySwitch as Switch} from '@eitje/form-fields-web'
import ColorPicker from 'components/form/color_picker'
import Modal from 'components/general/modal'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'

const FormRow = ({children}) => {
	return <div className="form-row border-dn">{children}</div>
}

const possibleColors = [
	'#F37C22',
	'#F47FA0',
	'#FFAFAA',
	'#FFBC2C',
	'#40D4AB',
	'#0B7E67',
	'#7594A0',
	'#74B9FF',
	'#0071C8',
	'#A29BFE',
	'#5546C9',
	'#2A0606',
]

const ModalPage = () => {
	const form = useRef(null)
	return (
		<Modal className="veedree modalEitje" onOk={() => form.current.submit()} title={t('skillSet')}>
			<div className="modalOverflow fieldWrapModal">
				<SkillSetForm afterSubmit={history.goBack} formRef={form} submitOnChange={false} />
			</div>
		</Modal>
	)
}

export default ModalPage

const defaultSkillSet = {
	planning_active: true,
	color_code: '#F37C22',
}

export const SkillSetForm = ({item, formRef, submitOnChange, ...rest}) => {
	const [tempAbbr, setTempAbbr] = useState(null)
	const Comp = submitOnChange ? 'div' : FormRow //
	const teams = useSelector(state => inEnv(state, 'teams'))
	const strategy = submitOnChange ? 'throttledChange' : null
	return (
		<Form
			debounceTime={250}
			afterChange={(field, val) => field == 'name' && setTempAbbr(val[0]?.toUpperCase())}
			ref={formRef}
			{...rest}
			fieldProps={{submitStrategy: submitOnChange ? 'change' : null}}
			initialValues={{...defaultSkillSet, abbr: tempAbbr, ...item}}
			onSubmit={data => API.update('skill_sets', data)}
		>
			<div></div>
			<Input bordered={false} placeholder={t('insertName')} required submitStrategy={strategy} field="name" />
			<Input
				bordered={false}
				required
				field="abbr"
				submitStrategy={strategy}
				formatValue={t => t.toUpperCase()}
				hideCharCounter
				maxLength={1}
			/>
			<DropdownPicker
				bordered={false}
				minSelected={1}
				placeholder={t('select')}
				items={teams}
				field="team_ids"
				multiple
				required
				showArrow
				labelField="naam"
				className="full-width padding-top-xs"
			/>
			<Comp fieldWrapper>
				<ColorPicker field="color_code" items={possibleColors} />
				<Switch field="planning_active" />
			</Comp>
		</Form>
	)
}
