import {HourMinutePicker} from 'components/form/hour_minute_picker'
import {useModalContext} from 'components/common/index'
import {useSimpleContext} from 'contexts/simple'
import {getPeriodString, WorkSchedulePopout} from 'cores/leave/index'
import {Layout, Text} from 'common/components'
import './styles/index.less'

const PADDING = [44, 0]

export const HourMinPicker = props => {
	const {readOnly} = props
	const {t} = useModalContext()
	const {request, schedule} = useSimpleContext()

	const word = readOnly ? 'processed' : 'unprocessed'
	const label = t(`amt_hours.${word}_title`)
	return (
		<Layout className="leave-hour-min-picker" direction="vertical" horizontal="center" padding={PADDING}>
			<Text popoutBody={!readOnly && schedule && <WorkSchedulePopout request={request} />}>{label}</Text>
			<HourMinutePicker maxHourDigits={3} {...props} label={null} field="total_mins" />
			<h5>
				{t('amt_hours.subtitle')}: {getPeriodString(request)}
			</h5>
		</Layout>
	)
}
