import {useState, useEffect} from 'react'
import {navigate} from 'components/routing'
import {navigateAndReloadHome} from 'cores/auth'
import {useSelector} from 'react-redux'
import {selfSelector} from 'selectors/records'

export const useRedirectWhenOffline = onOffline => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const me = useSelector(selfSelector)

  useEffect(() => {
    // Don't setup redirects to routes that do not exist when you're not logged in.
    const notLoggedIn = _.isEmpty(me)
    if (notLoggedIn) return

    const handleOnline = () => {
      navigateAndReloadHome()
      setIsOnline(true)
    }

    const handleOffline = () => {
      if (isOnline) navigate('/no_internet_connection')
      setIsOnline(false)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [onOffline])
}
