import {RawCheckbox as Checkbox} from '@eitje/form-fields-web'
import {usePageContext} from 'common/components'

const MultiActionCheckbox = ({shifts, disabled, ...rest}) => {
	const {multiEdit, setSelectedShifts, selectedShifts} = usePageContext()
	if (!multiEdit) return null
	const shiftIds = shifts.map(s => s.id)
	const checked = shiftIds.length > 0 && shiftIds.every(id => selectedShifts.includes(id))
	return (
		<Checkbox
			onClick={e => e.stopPropagation()}
			value={checked}
			onChange={() => setSelectedShifts(shiftIds)}
			className="multi-edit-shifts-checkbox"
			disabled={shifts.length == 0 || disabled}
			{...rest}
		/>
	)
}

export default MultiActionCheckbox
