import {Children, Fragment} from 'react'
import {formatCssVars, validCssSize} from 'helpers'

export const unwrapFragments = child => (child.type === Fragment ? Children.toArray(child.props.children) : child)

export const addDividers = (child, index, rowLength) =>
	index % rowLength !== rowLength - 1 ? [child, <div key={`divider-${index}`} />] : child

export const setPaddingCustomProperties = padding => {
	const values = padding.split(' ').map(value => validCssSize(value))
	const propertyNames = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left']
	const [top, right = top, bottom = top, left = right] = values

	const customProperties = propertyNames.reduce((acc, propertyName, index) => {
		acc[propertyName] = [top, right, bottom, left][index]
		return acc
	}, {})

	return formatCssVars(customProperties)
}

export const setChildrenClassNames = (ref, rowLength, gap) => {
	const DOMchildren = ref.current.querySelectorAll(':scope > div')

	// We add a div in between each item to create the gap, which increases the rowLength
	if (gap) rowLength = rowLength * 2 - 1

	DOMchildren.forEach((child, index) => {
		child.classList.add('grid-layout-child')

		const kind = index % rowLength === 0 ? 'first' : index % rowLength === rowLength - 1 ? 'last' : null
		if (kind) child.classList.add(`grid-layout-${kind}-child`)
	})
}
