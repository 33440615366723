import {PopoutCard} from '@eitje/web_components'
import {ShowMoreTooltip} from 'common/components'
import {TableText} from 'common/components/advanced_table'
import {BaseCollapsibleCell} from './base'
import {getCellComponent} from '.'

export const UngroupedCollapsibleCell = ({cell}) => {
	const Component = getCellComponent(cell, DefaultCell)

	return <BaseCollapsibleCell Component={Component} cell={cell} />
}

const DefaultCell = props => {
	const {column, row} = props
	const {columnDef} = column
	const {cell, aggregatesFn} = columnDef
	const {subRows} = row
	const {id: columnName} = column
	const subRowValues = subRows.map(row => row.getValue(columnName))
	const booleanCell = cell === 'BooleanCell'
	const uniqSubRowAmt = subRowValues.uniq().length
	const showSingleValue = booleanCell || uniqSubRowAmt === 1
	const sumValues = aggregatesFn === 'sumValues'
	const formattedValue = booleanCell ? toBooleanFormat(subRowValues) : sumValues ? subRowValues.sum() : toItemFormat(subRowValues)
	const label = sumValues ? subRowValues.sum() : uniqSubRowAmt

	if (showSingleValue) {
		return <TableText children={formattedValue} />
	}

	return (
		<PopoutCard
			className="show-more"
			title={formattedValue}
			children={label}
			layoutProps={{width: '100%', overflow: 'hidden'}}
			wrapperLayoutProps={{width: '100%'}}
		/>
	)
}

const toBooleanFormat = items => {
	let trueAmt = 0
	items.map(item => {
		if (item && item !== 'no') trueAmt += 1
	})
	const totalAmt = items.length
	return `${trueAmt} / ${totalAmt}`
}

const toItemFormat = (items = []) => {
	items = _.flatten(items).filter(Boolean).uniq()

	// If there is only one, single integer passed, this is an ID. Since we won't transform them to names through records,
	// simply show the count "1", which' value the user may see when expanding the collapsed row.
	if (items.length == 1 && typeof items[0] == 'number') return 1

	const stringifiedItems = items
		.map(item => {
			switch (typeof item) {
				case 'string':
					return item
				case 'object':
					return item.name || item.naam
				default:
					return null
			}
		})
		.filter(Boolean)

	// In case of many items, e.g. team names, make sure only unique values are shown.
	// The first `join` flattens all nested formatted strings.
	return stringifiedItems.join(', ').split(', ').uniq().sort().join(', ')
}
