import React, {useEffect, useState, Fragment, useCallback, useMemo, useRef} from 'react'
import useShared from 'hooks/use_shared'
import useArbitrary from 'hooks/use_arbitrary'
import {isDev} from 'constants/general'

const useOrgLocked = () => {
	const {org} = useShared()
	const {locked} = useArbitrary('orgStatus') || {}

	const lockedObj = {locked: org.id ? !org.billing_done && locked : false}
	const obj = useMemo(() => lockedObj, [locked])

	return obj
}

export default useOrgLocked
