import {useEffect, createContext, useContext} from 'react'
import {usePrevious} from '@eitje/react-hooks'
import {PlanningShift, TimeRegistrationShift, CheckIn} from 'models/index'
import {usePerMemberDates} from 'cores/time_registration/index'
import {store} from 'index'
import {API} from '@eitje/easy_api'

const PerMemberContext = createContext({})
const {Provider} = PerMemberContext

export const PerMemberProvider = ({children}) => {
	const {dateRange, setDates, startDate, dateArray, setRawDates, setPeriod, period, diff, endDate} = usePerMemberDates()
	const prevDateRange = usePrevious(dateRange)

	const value = {dateRange, setPeriod, setRawDates, setDates, startDate, diff, period, dateArray, endDate}
	const previousStartDate = usePrevious(startDate)

	useEffect(() => getTimeRegData({startDate, dateRange, previousStartDate, prevDateRange, endDate}), [startDate.format(), endDate.format()])

	return <Provider value={value}> {children} </Provider>
}

const getTimeRegData = async ({startDate, dateRange, prevDateRange, previousStartDate, endDate}) => {
	if (prevDateRange && prevDateRange.contains(dateRange)) return
	const startDateString = startDate.format()
	const prevStartDateString = previousStartDate?.format()
	const dateHasChanged = startDateString != prevStartDateString && prevStartDateString
	const filters = {start_date: startDate, end_date: endDate}
	const opts = {refresh: !!dateHasChanged}
	const params = {filters, ...opts}
	const timeFilters = {...filters, time_registration: true}
	const timeParams = {filters: timeFilters, ...opts}

	store.dispatch({type: 'START_LOADING'})

	const payload = [
		{name: PlanningShift.tableName, ...timeParams},
		{name: TimeRegistrationShift.tableName, ...timeParams},
		{name: CheckIn.tableName, ...params},
	]
	await API.multiIndex(payload)
	store.dispatch({type: 'STOP_LOADING'})
}

export default PerMemberProvider

export const usePerMemberContext = () => useContext(PerMemberContext)
