import {AuthPageLayout, notify} from 'cores/auth'
import {EmailInput} from 'lib/form/fields'
import {backend} from '@eitje/easy_api'
import {useIncrementState} from '@eitje/react-hooks'
import {navigate} from 'components/routing'

export const ForgotPassword = () => {
	const [failedAttempts, increment] = useIncrementState()
	const onSubmit = data => {
		// Check if the email is known and confirmed in the first request, since we only want to reset passwords for known and confirmed users,
		// but redirect them to other flows if they're not.
		return backend.post(`/users/identify_email`, {...data, skipSuccessNotif: true})
	}

	const afterSubmit = ({res, params}) => {
		const {user, confirmed} = res.data
		const {email} = params
		const encodedEmail = encodeURIComponent(email)

		if (!user) {
			notify('invalid_email', {baseColor: 'red', duration: 3})
			return increment()
		}

		if (!confirmed) {
			notify('password_reset_for_unconfirmed_user', {duration: 8})
			return navigate(`/activate_account?user_id=${user}&email=${encodedEmail}`)
		}

		// Only reset password when the user is known and confirmed
		backend.post(`/auth/password`, {email, skipSuccessNotif: true})
		navigate('/reset_password_instructions')
	}

	return (
		<AuthPageLayout
			subtitle={failedAttempts >= 2}
			name="forgot_password"
			onSubmit={onSubmit}
			afterSubmit={afterSubmit}
			backButton
			submitButtonProps={{iconLeft: 'envelope', t: 'common.reset'}}
		>
			<EmailInput />
		</AuthPageLayout>
	)
}
