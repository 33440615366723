import React, {useState} from 'react'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {DownloadLink, Text} from 'common/components'
import {usePusherUser} from 'hooks'
import {Excel} from 'models'
import './styles/export_notification.less'

export const createExportNotification = () => {
	utils.notify({
		title: t('exports.titles.downloading_file'),
		content: <ExportNotification />,
		duration: 0,
		type: 'info',
	})
}

const ExportNotification = () => {
	const [data, setData] = useState({})
	const _setData = payload => {
		setData(payload)
		Excel.index()
	}

	usePusherUser('excel_exported', _setData)

	const {file, status} = data
	const _status = file ? 'completed' : status || 'pending'

	return (
		<>
			<Text t={`exports2.export_notif.${_status}.body`} />
			<Status {...data} status={_status} />
		</>
	)
}

const Status = ({file, status}) => {
	if (file) return <DownloadLink to={file} />
	return null
}
