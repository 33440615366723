import {exportT, ExportTable, PeriodPicker, getDatesFromPeriod} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {EitjeModal} from 'components/common'
import {useFormData} from 'hooks'
import {ContextForm} from '@eitje/form'
import {DatePicker} from '@eitje/form-fields-web'
import {useArbitrary} from 'hooks'
import {history} from 'index'
import {FormRow} from 'components/ui'
import {TourButton, Tabs} from 'common/components'
import './styles/index.less'

const makeItems = items => items.map(i => ({name: t(`common.${i}`), id: i}))

const opts = ['period', 'date_selection']

const Inner = props => {
	const {date_type} = useFormData()

	const item = ExportTable.find()
	return (
		<EitjeModal
			name="export_table"
			okButtonProps={{text: t('common.export'), iconLeft: 'paper-plane', color: 'blue'}}
			width={430}
			RightHeader={RightHeader}
			form
			onSubmit={data => item.export(data)}
			insertAtIndex={{0: <TourButton tourId={448315} />}}
		>
			{date_type == 'period' ? <PickPeriod {...props} /> : <PickDates />}
		</EitjeModal>
	)
}

const RightHeader = () => <Tabs decorated={false} label={null} field="date_type" items={makeItems(opts)} />

const PickPeriod = ({periods}) => {
	const data = useFormData()

	const dateRange = getDatesFromPeriod({...data, ...periods})

	return (
		<div className="pick-period">
			<PeriodPicker />
			<div className="display-period">
				<h5>{exportT('table_export.period_title')} </h5>
				<h4>{t('functions.fromTill', {from: dateRange.start, till: dateRange.end})} </h4>
			</div>
		</div>
	)
}

const PickDates = () => {
	return (
		<div className="pick-dates eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<DatePicker isStart field="start_date" />
			</FormRow>
			<FormRow>
				<DatePicker isEnd field="end_date" />
			</FormRow>
		</div>
	)
}

const initialValues = {
	tense: 'previous',
	period: 'week',
	date_type: 'period',
}

export const TableExport = () => {
	const item = ExportTable.show()
	const periods = useArbitrary('periods')
	return (
		<ContextForm
			afterSubmit={history.goBack}
			onSubmit={data => submit(data, item, periods)}
			transNamespace="export_table"
			fieldProps={{required: true}}
			initialValues={initialValues}
		>
			<Inner periods={periods} />
		</ContextForm>
	)
}

const submit = (data, item, periods) => {
	if (!data.start_date) {
		const dateRange = getDatesFromPeriod({...data, ...periods})
		data.start_date = dateRange.start
		data.end_date = dateRange.end
	}
	return item.export(data)
}
