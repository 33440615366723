import {RouteSelect as _RouteSelect} from 'components/routing'
import {EitjeButton} from 'common/components'
import {t} from 'initializers/i18n'
import {useRoleMessage} from 'hooks'

export const RouteSelect = props => {
	const noRole = useRoleMessage('manager')

	const items = [
		{label: t('common.in_service'), value: 'in_service'},
		{label: t('common.out_of_service'), value: 'out_of_service'},
	]

	// RouteSelect is a ListPicker, which comes from the comp lib and thus doesn't support roles... Fix with a button for now,
	// think a bit longer what would be the best way to fix this fundamentally.
	if (noRole) {
		return <EitjeButton t="common.in_service" dropdown enableRole="manager" height="small" />
	} else {
		return <_RouteSelect currentVal="in_service" {...props} baseUrl={`/users`} items={items} shouldTranslate={false} trigger="click" />
	}
}
