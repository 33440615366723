import React from 'react'
import {RevenueGroup, RevenueDay} from 'models/index'
import {useEnvTableContext, usePlanningContext, useFilteredEnvShifts, useBlendRevDaysInForecast} from 'cores/planning/index'
import RevenueFooter from 'components/shared/revenue_footer/index'
import {useRevenueContext} from '../../revenue_provider'

const RevFooterWrapper = () => {
	const {dateRange, dateArray} = usePlanningContext()
	const {days} = useRevenueContext()
	const {env} = useEnvTableContext()
	const revenueGroups = RevenueGroup.where({environment_id: env.id})
	const groupIds = revenueGroups._map('id')
	const filteredDays = days.filter(d => groupIds.includes(d.omzet_groep_id))

	const revDays = RevenueDay.where({omzet_groep_id: revenueGroups.map(r => r.id), date: dateRange})
	const shifts = useFilteredEnvShifts()
	const _days = useBlendRevDaysInForecast(filteredDays, revDays)

	return <RevenueFooter days={_days} env={env} shifts={shifts} dateArray={dateArray} dateRange={dateRange} localName="planningForecast" />
}

export default React.memo(RevFooterWrapper)
