import InnerShift from './inner_shift/index'
import UserShift from './user_shift'
import ShiftProvider from './context'
import {useEnvTableContext} from 'cores/planning/index'
import useHasRole from 'hooks/use_has_role'

const Shift = ({item}) => {
	const {env} = useEnvTableContext()
	const hasManagerRole = useHasRole('manager', env.id)
	if (!hasManagerRole && !item.published) return null
	if (item.user_id) return <UserShift item={item} />
	return <InnerShift className="open-shift" item={item} />
}

// Shift.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

const Wrapper = props => {
	return (
		<ShiftProvider shift={props.item}>
			<Shift {...props} />
		</ShiftProvider>
	)
}

// Wrapper.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default Wrapper
