const initialState = {
	exports: {},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'TAXONOMY':
			return {
				...state,
				...action.payload,
			}
		default:
			return state
	}
}
