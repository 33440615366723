import {mapTopicResources} from 'helpers/topics'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {whereInc} from 'selectors/records'

const useTopicResources = topic => {
	const query = {topic_ids: [topic.id]}
	const infos = useSelector(state => whereInc(state, 'infos', query))
	const quizItems = useSelector(state => whereInc(state, 'quizItems', query))
	const topicInfos = mapTopicResources(topic, infos, 'Info')
	const topicQuizItems = mapTopicResources(topic, quizItems, 'QuizItem')

	const topicResources = _.orderBy([topicInfos, topicQuizItems].flat(), 'order')
	const publishedTopicResources = topicResources.filter(t => !_.has(t, 'published') || t.published)
	return {topicResources, topicInfos, topicQuizItems, publishedTopicResources}
}

export default useTopicResources
