import {ListPicker} from 'common/components'
import {FormRow} from 'components/ui'
import {t} from 'initializers/i18n'

const tenses = ['previous', 'current', 'next']
const periods = ['day', 'week', 'month', 'flex_wage_period', 'hours_wage_period', 'quarter', 'year']

const makeItems = items => items.map(i => ({name: t(`common.${i}`), id: i}))

export const PeriodPicker = () => {
	return (
		<div className="eitje-form-2 eitje-form-2-use-borders">
			<FormRow>
				<ListPicker dropdown single noSort field="tense" items={makeItems(tenses)} />
			</FormRow>
			<FormRow>
				<ListPicker dropdown single noSort field="period" items={makeItems(periods)} />
			</FormRow>
		</div>
	)
}
