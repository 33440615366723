import {makeField} from '@eitje/form'

let Skeleton = ({children}) => {
	return children
}

export const ModalLinkSkeleton = makeField(Skeleton, {className: 'eitje-modal-link-container'})

Skeleton = makeField(Skeleton, {className: 'eitje-form-skeleton'})

export {Skeleton}
