import {backend} from '@eitje/easy_api'
import {Input, InputNumber, Modal, Select, Spin, Switch, Tabs, TimePicker} from 'antd'
import {t} from 'initializers/i18n'
import * as validator from 'lib/validator'
import _ from 'lodash'
import {date} from 'initializers/date'
import React from 'react'
import * as jsUtils from 'utils/jsutils'
import {NewInputNumber} from 'components/form/input_number'
const Option = Select.Option

//
const MAPPER = {'Alle dagen': [0, 1, 2, 3, 4, 5, 6], maandag: 0, dinsdag: 1, woensdag: 2, donderdag: 3, vrijdag: 4, zaterdag: 5, zondag: 6}
//

const DB_NAMES = {name: 'Type toeslag', relevant_days: 'Kies moment', start_time: 'van', end_time: 'tot', amount: 'percentage'}
const HAP_VAL_DATA = {ui: DB_NAMES, presence: ['relevant_days', 'start_time', 'end_time', 'amount']}
const VAL_DATA = {ui: DB_NAMES, presence: ['name', 'amount']}

const sortAllowances = (a, b) => {
	if (a.is_func && !b.is_func) return -1
	if (!a.is_func && b.is_func) return 1
	if (a.is_hapsnap && !b.is_hapsnap) return -1
	if (!a.is_hapsnap && b.is_hapsnap) return 1

	if (a.is_hapsnap && b.is_hapsnap) {
		return _.min(a.relevant_days) > _.min(b.relevant_days) ? 1 : -1
	}

	if (a.is_func && b.is_func) {
		return a.name > b.name ? 1 : -1
	}
	return 0
}

export class ToeslagKoppelModal extends React.Component {
	constructor(props) {
		super(props)
		const alw = props.allowances.sort((a, b) => sortAllowances(a, b))
		this.state = {
			model: [],
			allowances: [...alw],
			loonModel: [],
			maaltijdCode: props.maaltijdCode,
		}
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = async () => {
		this.setState({fetching: true})
		const res = await backend.get(`koppelingen/hour_model`)
		if (res.ok) {
			const json = res.data
			this.setState({fetching: false, model: json.hour_model, loonModel: json.loon_model})
		}
	}

	submit = async () => {
		const {allowances, maaltijdCode} = this.state
		if (maaltijdCode) {
			jsUtils.updateEnvSetting({nmbrs: {maaltijd_code: maaltijdCode}})
		}
		const res = await backend.post('allowances/mass_assign', {
			allowances: allowances,
			maaltijdCode: maaltijdCode,
		})
		if (res.ok) {
			this.props.afterSucc(res.data.allowances)
		}
	}

	update = (val, idx) => {
		const {allowances} = this.state
		const it = {...allowances[idx]}
		it['loon_code'] = val
		allowances[idx] = it
		this.setState({allowances: allowances})
	}

	getHapsnapName = alw => {
		const weekdays = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo']
		if (alw.relevant_days.length == 7) return 'Alle dagen'

		const days = alw.relevant_days.map(d => weekdays[d])

		if (days.length == 1) {
			return days[0]
		} else {
			const len = days.length
			let string = ''
			days.forEach(d => {
				string += days.indexOf(d) === len - 1 ? ` ${d}` : ` ${d},`
			})
			return string
		}
	}

	render() {
		const {submit, update, getHapsnapName} = this
		const {onCancel} = this.props
		const {allowances, model, fetching, loonModel, maaltijdCode} = this.state
		return (
			<Modal title={t('wagecom1')} visible onCancel={onCancel} onOk={submit}>
				<Spin style={{marginTop: 8, width: '100%'}} spinning={false}>
					<div className="borderBot" style={{paddingBottom: 4, marginBottom: 12}}>
						<p style={{}}>{t('wagecom2')}</p>
					</div>

					{allowances.map(a => {
						const idx = allowances.indexOf(a)
						let naam = a.name ? a.name : a.is_holiday ? 'Feestdagen' : getHapsnapName(a)
						if (a.is_hapsnap) naam = `${naam} (${a.start_time} t/m ${a.end_time})`
						return (
							<div style={{marginBottom: 4}}>
								<p style={{width: '40%', float: 'left', marginTop: 6, fontSize: 10}}>
									{naam} - {a.amount}%
								</p>
								<Select style={{width: '60%', float: 'right'}} onChange={val => update(val, idx)} value={a.loon_code}>
									{model.map(r => (
										<Option value={r.code}>
											{' '}
											{r.code} - {r.description}{' '}
										</Option>
									))}
								</Select>
								<div className="clear" />
							</div>
						)
					})}

					<div>
						<p style={{width: '40%', float: 'left', marginTop: 6, fontSize: 10}}>{t('wagecom3')}</p>
						<Select style={{width: '60%', float: 'right'}} onChange={val => this.setState({maaltijdCode: val})} value={maaltijdCode}>
							{loonModel.map(r => (
								<Option value={r.code}>
									{' '}
									{r.code} - {r.description}{' '}
								</Option>
							))}
						</Select>
					</div>
					<div className="clear" />
				</Spin>
			</Modal>
		)
	}
}

export class FeestdagModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			chosenHolidays: props.chosen || [],
			amount: props.amount,
		}
	}
	submit = async () => {
		const {chosenHolidays, amount} = this.state
		jsUtils.updateEnvSetting({holidays: {active: chosenHolidays}})
		const res = await backend.post('allowances/mass_assign', {
			allowances: [
				{
					amount: amount,
					id: this.props.allowance ? this.props.allowance.id : undefined,
					is_holiday: true,
					active: true,
					naam: 'Feestdagen',
				},
			],
		})
		if (res.ok) {
			const json = res.data
			this.props.isDone()
			this.props.afterSucc(json.allowances)
			this.props.saveHoliday(chosenHolidays)
		}
	}

	render() {
		const {submit} = this
		const {holidays, chosen} = this.props
		const {chosenHolidays, amount} = this.state
		const filteredOpt = holidays.filter(h => !chosenHolidays.includes(h))
		return (
			<div>
				<div className="borderBot" style={{paddingBottom: 16}}>
					<p style={{}}></p>
				</div>

				<div style={{marginTop: 16}}>
					<span style={{float: 'left', width: '50%', paddingLeft: 4, fontSize: 10}}>{t('holidays')}</span>
					<span style={{float: 'left', width: '50%', paddingLeft: 8, fontSize: 10}}>{t('percentage')}</span>
				</div>

				<div>
					<Select
						mode="multiple"
						style={{width: '50%', float: 'left'}}
						value={chosenHolidays}
						placeholder={t('pickHolidays')}
						onChange={(val, opt) => this.setState({chosenHolidays: val})}
					>
						{filteredOpt.map(holiday => (
							<Option value={holiday}> {holiday} </Option>
						))}
					</Select>
					<NewInputNumber
						style={{width: '20%', borderColor: '#eee', marginLeft: 4, borderRadius: 4, float: 'left'}}
						value={amount}
						onChange={val => this.setState({amount: val})}
						min={0}
						step={1}
					/>
				</div>
				<div className="clear" />
			</div>
		)
	}
}

export class HapToeslagModal extends React.Component {
	constructor(props) {
		super(props)
		const hasAllowance = props.allowances.length > 0
		this.state = {
			allowances: hasAllowance ? [...props.allowances] : [{start_time: '00:00', end_time: '23:59', active: true}],
		}
	}

	componentWillReceiveProps(nextProps) {
		// to handle DB-updates
		if (nextProps.allowances.length != this.props.allowances.length) {
			this.setState({allowances: [...nextProps.allowances]})
		}
	}

	submit = async () => {
		const {allowances} = this.state
		const alw = allowances.filter(t => t && _.isObject(t) && _.keys(t).length > 0 && t.relevant_days && t.relevant_days.length > 0) // remove empty object and non-objects (shouldn't happen, but who knows)
		if (!validator.validateAll(alw, HAP_VAL_DATA, {prefix: 'Hapsnap: '})) return
		const res = await backend.post('allowances/mass_assign', {
			allowances: alw,
		})
		if (res.ok && !res.data.error) {
			const json = res.data
			this.props.isDone()
			this.props.afterSucc(json.allowances)
		} else {
			jsUtils.errNotif(`${t('att')}`, `${t('oneHapSnap')}`)
		}
	}

	update = (field, val, idx, opt) => {
		const {allowances} = this.state
		const it = {...allowances[idx]}
		if (field == 'relevant_days' && val.find(i => _.isArray(i))) {
			// means it's added
			const arr = [0, 1, 2, 3, 4, 5, 6]
			if (arr.every(i => val.includes(i))) {
				val = []
			} else {
				val = arr
			}
		}
		it[field] = val
		allowances[idx] = it
		this.setState({allowances: allowances})
	}

	add = () => {
		this.setState({allowances: [...this.state.allowances, {relevant_days: [], start_time: '00:00', end_time: '23:59'}]})
	}

	render() {
		const {add, update, submit} = this
		const {allowances, extraOpt} = this.state
		const keys = Object.keys(MAPPER)
		return (
			<div>
				<div className="borderBot" style={{paddingBottom: 16}}>
					<p style={{}}></p>
				</div>

				<div style={{marginTop: 16}}>
					<span style={{float: 'left', width: '32%', paddingLeft: 8, fontSize: 10}}>{t('dayC')}</span>
					<span style={{float: 'left', width: '18%', paddingLeft: 8, fontSize: 10}}>{t('fromC')}</span>
					<span style={{float: 'left', width: '18%', paddingLeft: 8, marginLeft: 4, fontSize: 10}}>{t('tillC')}</span>
					<span style={{float: 'left', width: '14%', paddingLeft: 8, fontSize: 10}}>{t('percentage')}</span>
					<span style={{float: 'left', paddingLeft: 16, fontSize: 10}}>{t('inUse')}</span>
				</div>

				{allowances.map(a => (
					<div>
						<Select
							mode="multiple"
							style={{width: '32%', float: 'left'}}
							value={a.relevant_days && a.relevant_days.length > 0 ? a.relevant_days.map(a => Number(a)) : undefined}
							placeholder={t('chooseMoment')}
							onChange={(val, opt) => this.update('relevant_days', val, allowances.indexOf(a), opt)}
						>
							{keys.map(key => (
								<Option value={MAPPER[key]}> {jsUtils.capitalize(key)} </Option>
							))}
						</Select>

						<TimePicker
							format="HH:mm"
							minuteStep={30}
							style={{borderColor: '#eee', borderRadius: 4, width: '18%', marginLeft: 0, float: 'left'}}
							value={a.start_time && date(a.start_time, 'HH:mm')}
							onSelect={(val, string) => this.update('start_time', val.format('HH:mm'), allowances.indexOf(a))}
						/>
						<TimePicker
							format="HH:mm"
							minuteStep={30}
							style={{borderColor: '#eee', borderRadius: 4, width: '18%', marginLeft: 4, float: 'left'}}
							value={a.end_time && date(a.end_time, 'HH:mm')}
							onSelect={(val, string) => this.update('end_time', val.format('HH:mm'), allowances.indexOf(a))}
						/>

						<NewInputNumber
							style={{borderRadius: 4, borderColor: '#eee', width: '14%', marginLeft: 4, float: 'left'}}
							value={a.amount}
							onChange={val => update('amount', val, allowances.indexOf(a))}
							min={0}
							step={1}
						/>
						<Switch
							style={{marginBottom: 8, marginTop: 3, marginLeft: 4, float: 'left'}}
							checked={a.active}
							onChange={val => this.update('active', val, allowances.indexOf(a))}
						/>
						{!a.id && allowances.indexOf(a) != 0 && (
							<p
								style={{float: 'right', color: '#ff0020', marginTop: 4, cursor: 'pointer'}}
								onClick={() => this.setState({allowances: allowances.filter(alw => allowances.indexOf(alw) != allowances.indexOf(a))})}
							>
								{' '}
								x{' '}
							</p>
						)}
						<div className="clear" />
					</div>
				))}
				<div style={{textAlign: 'center'}}>
					<p style={{color: '#0496ff', fontWeight: '500', marginTop: 24, fontSize: 10, cursor: 'pointer'}} onClick={() => this.add()}>
						{t('extraAllowance')}
					</p>
				</div>
			</div>
		)
	}
}

class ToeslagModal extends React.Component {
	constructor(props) {
		super(props)
		const hasAllowance = props.allowances.length > 0
		this.state = {
			activeTab: '1',
			allowances: [...props.allowances],
			visitedTabs: [1],
			isDone: [],
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			_.isEqual(this.state.isDone.sort(), this.state.visitedTabs.sort()) &&
			!_.isEqual(prevState.isDone.sort(), prevState.visitedTabs.sort())
		) {
			this.props.onCancel()
		}
	}

	submit = () => {
		const {activeTab} = this.state
		this.setState({isDone: []})
		this['modal_1'] && this['modal_1'].submit()
		this['modal_2'] && this['modal_2'].submit()
		this['modal_3'] && this['modal_3'].submit()
	}

	render() {
		const {add, update, submit} = this
		const {activeTab, isDone, visitedTabs} = this.state
		const {onCancel, onOk, afterHoliSucc, afterSucc, holidays, chosenHolidays} = this.props
		const {allowances} = this.props
		const TabPane = Tabs.TabPane
		const funcAllowances = allowances.filter(a => a.is_func)
		const hapsnapAllowances = allowances.filter(a => a.is_hapsnap)
		const holidayAllowance = allowances.find(a => a.is_holiday)
		return (
			<Modal title={t('manageAllow')} onOk={submit} onCancel={onCancel} visible>
				<div className="default-modal-padding">
					<Tabs onChange={key => this.setState({activeTab: key, visitedTabs: _.uniq([...visitedTabs, Number(key)])})}>
						<TabPane tab={t('functionAllowance')} key="1">
							{t('functionAllowance2')}
							<FunctieToeslag
								isDone={() => this.setState({isDone: [...isDone, 1]})}
								afterSucc={afterSucc}
								ref={r => (this.modal_1 = r)}
								allowances={funcAllowances}
							/>
						</TabPane>
						<TabPane tab={t('irregAllowance')} key="2">
							{t('irregAllowance2')}
							<HapToeslagModal
								isDone={() => this.setState({isDone: [...isDone, 2]})}
								ref={r => (this.modal_2 = r)}
								afterSucc={afterSucc}
								allowances={hapsnapAllowances}
							/>
						</TabPane>
						<TabPane tab={t('holiAllowance')} key="3">
							{t('holiAllowance2')}
							<FeestdagModal
								isDone={() => this.setState({isDone: [...isDone, 3]})}
								ref={r => (this.modal_3 = r)}
								saveHoliday={afterHoliSucc}
								afterSucc={afterSucc}
								amount={holidayAllowance ? holidayAllowance.amount : 0}
								chosen={chosenHolidays}
								holidays={holidays}
								allowance={holidayAllowance}
							/>
						</TabPane>
					</Tabs>
				</div>
			</Modal>
		)
	}
}

class FunctieToeslag extends React.Component {
	constructor(props) {
		super(props)
		const hasAllowance = props.allowances.length > 0
		this.state = {
			allowances: hasAllowance ? [...props.allowances] : [{active: true}],
		}
	}
	submit = async () => {
		const {allowances} = this.state
		const alw = allowances.filter(t => t && _.isObject(t) && _.keys(t).length > 0 && (t.name || t.amount)) // remove empty object and non-objects (shouldn't happen, but who knows)
		if (!validator.validateAll(alw, VAL_DATA, {prefix: 'Functie: '})) return
		const res = await backend.post('allowances/mass_assign', {
			allowances: alw,
		})
		if (res.ok) {
			const json = res.data
			this.props.isDone()
			this.props.afterSucc(json.allowances, true)
		}
	}

	update = (field, val, idx) => {
		const {allowances} = this.state
		const it = {...allowances[idx]}

		it[field] = val
		allowances[idx] = it
		this.setState({allowances: allowances})
	}

	add = () => {
		this.setState({allowances: [...this.state.allowances, {}]})
	}

	render() {
		const {add, update, submit} = this
		const {allowances, extraOpt} = this.state
		return (
			<div>
				<div className="borderBot" style={{paddingBottom: 16}}>
					<p style={{}}></p>
				</div>

				<div style={{marginTop: 16}}>
					<span style={{float: 'left', width: '60%', fontSize: 10}}>{t('alowType')}</span>
					<span style={{float: 'left', width: '20%', paddingLeft: 4, fontSize: 10}}>{t('percentage')}</span>
					<span style={{float: 'left', paddingLeft: 8, fontSize: 10}}>{t('inUse')}</span>
				</div>
				{allowances.map(a => (
					<div>
						<Input
							style={{width: '60%', float: 'left'}}
							value={a.name}
							onChange={e => this.update('name', e.target.value, allowances.indexOf(a))}
							placeholder={t('allowanceName')}
						/>
						<NewInputNumber
							style={{borderRadius: 4, width: '20%', marginLeft: 4, float: 'left'}}
							value={a.amount}
							onChange={val => update('amount', val, allowances.indexOf(a))}
							min={0}
							step={1}
						/>
						<Switch
							style={{marginBottom: 8, marginLeft: 4, marginTop: 3, float: 'left'}}
							checked={a.active}
							onChange={val => this.update('active', val, allowances.indexOf(a))}
						/>
						{!a.id && allowances.indexOf(a) != 0 && (
							<p
								style={{float: 'right', color: '#ff0020', marginTop: 4, cursor: 'pointer'}}
								onClick={() => this.setState({allowances: allowances.filter(alw => allowances.indexOf(alw) != allowances.indexOf(a))})}
							>
								{' '}
								x{' '}
							</p>
						)}
					</div>
				))}
				<div className="clear"></div>

				<div style={{textAlign: 'center'}}>
					<p
						style={{color: '#0496ff', fontWeight: '500', marginTop: 24, fontSize: 10, cursor: 'pointer'}}
						onClick={() => this.setState({allowances: [...allowances, {}]})}
					>
						{t('extraAllowance')}
					</p>
				</div>
			</div>
		)
	}
}

export default ToeslagModal
