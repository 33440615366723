import {useCallback} from 'react'
import {useChatContext} from 'stream-chat-react'
import {useChannelMetaData} from 'cores/chat'
import {BasePreview} from './base'

export const ChannelPreview = ({channel, active, unread, lastMessage}) => {
	const {setActiveChannel} = useChatContext()
	const {title, image, user, directChannel} = useChannelMetaData(channel)

	const handleClick = useCallback(() => {
		setActiveChannel(channel)
	}, [channel])

	return (
		<BasePreview
			className="channel-preview"
			title={title}
			image={image}
			active={active}
			unread={unread}
			userId={user?.id}
			lastMessage={lastMessage}
			onClick={handleClick}
			includeSenderName={!directChannel}
		/>
	)
}
