import * as jsUtils from 'utils/jsutils'

export function validateAll(items, options = {}, extra = {}) {
	return items.every(i => validate(i, options, extra))
}

export function validate(item, options = {}, extra = {}) {
	const {prefix} = extra
	const {ui = {}, presence = []} = options
	let valid = true
	if (presence) {
		presence.forEach(opt => {
			if (!isPresent(item[opt])) {
				valid = false
				const field = isPresent(ui[opt]) ? ui[opt] : opt
				const baseString = `${jsUtils.capitalize(field)} is verplicht`
				const string = prefix ? `${prefix}${baseString}` : baseString
				jsUtils.errNotif('Let op!', string)
			}
		})
	}
	return valid
}

export function isPresent(field) {
	return field === 0 ? true : !!field
}
