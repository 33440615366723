import {useForm, ContextForm} from '@eitje/form'
import {useModalContext} from 'components/common/index'
import {FormSubmitButtonInner} from 'components/form'
import {useEffect} from 'react'
import {EitjeModal} from 'components/common'

const Inner = ({modalProps = {}}) => {
	const {okButtonProps = {}, ...rest} = modalProps
	const {setModalPropsRaw} = useModalContext()
	const {submit, formTouched, getData} = useForm()
	useEffect(() => {
		setModalPropsRaw({
			form: true,
			SubmitButtonOuter: FormSubmitButtonInner,
			okButtonProps: {...okButtonProps, submit, formTouched, getData},
			...rest,
		})
	}, [])

	return null
}

export const ModalWithForm = ({name, modalProps, ...props}) => {
	return (
		<EitjeModal name={name} {...modalProps}>
			<ModalForm {...props} modalProps={modalProps} />
		</EitjeModal>
	)
}

export const ModalForm = ({children, modalProps, ...props}) => {
	const {name} = useModalContext()
	let transNamespace = props.transNamespace || name

	return (
		<ContextForm className="eitje-form-2-use-borders" name={name} {...props} transNamespace={transNamespace}>
			<Inner modalProps={modalProps} />
			{children}
		</ContextForm>
	)
}
