import {t} from 'initializers/i18n'
import {ftime} from 'helpers'
import {date} from 'initializers/date'
import {useActiveUsers} from 'hooks'
const BIRTHDAY_NOTIFS = [7, 0]
const UPCOMING_DAYS = 7
const PAST_DAYS = 90

export function useBirthdays({extraProps}) {
	return BIRTHDAY_NOTIFS.map(days => baseUseBirthdays({days, extraProps}))
}

function baseUseBirthdays({days, extraProps}) {
	let users = useActiveUsers().filter(u => u.birthday()) // skip users which have no birthday set

	users = users.map(u => {
		let age = u.age()
		const zeroDays = days === 0
		const nextBday = u.nextBirthday()
		const prevBday = u.previousBirthday()
		let _date = nextBday
		let label = u.full_name
		const upcoming = nextBday.isSameOrBefore(date().add(UPCOMING_DAYS, 'days')) && !zeroDays
		const pastOrToday = prevBday.isSameOrAfter(date().subtract(PAST_DAYS, 'days')) && zeroDays // check for zeroDays to only show one past birthday

		if (upcoming) {
			age += 1
			label += ` ${t('inbox.age_becomes')} ${age} ${t('common.on')} ${ftime(nextBday, 'dayMonth')}`
		} else if (pastOrToday) {
			_date = prevBday
			label += ` ${t('inbox.age_becomes_today')} ${age} ${t('inbox.age_becomes_today_suffix')}`
		} else {
			return // skip birthdays out of range
		}

		const createdAt = _date.subtract(days, 'days')

		return {
			label,
			createdAt,
			...extraProps,
		}
	})

	users = users.filter(Boolean) // filter birthdays that are out of range

	return users
}
