import {MultiActionCheckbox} from 'cores/planning/components/multi_action_checkbox'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useShiftContext} from '../context'
import {usePlanningContext} from 'cores/planning/pages/index/planning_provider'
import useScheduledMins from 'cores/planning/hooks/use_scheduled_mins'
import {Fragment} from 'react'
import {DragHandle} from 'cores/planning/components/shift_card_planning/components'
import {useLocalValue} from 'hooks'
import {EitjeAvatar} from 'common/components'

export const LeftComponent = ({isDragging, isHovering, hasManagerRole, canDrag, item}) => {
	const {multiEdit} = useEnvsContext()
	const showAvatar = useLocalValue('planningShowAvatar')

	if (!multiEdit && (isDragging || (isHovering && canDrag))) return <DragHandle />
	if (multiEdit && hasManagerRole) return <MultiActionCheckbox shifts={[item]} />
	if (showAvatar) return <ShiftImage />
	return null
}

const ShiftImage = () => {
	const {item} = useShiftContext()
	const {user} = item
	return (
		<div className="shift-image">
			{user && <UserImageWithCount user={user} />}
			{!user && <EitjeAvatar radius={24} type="open-shift" />}
		</div>
	)
}

const UserImageWithCount = ({user}) => {
	const {dateRange} = usePlanningContext()
	const {totalDays} = useScheduledMins({user, dateRange})
	return (
		<Fragment>
			<EitjeAvatar radius={24} user={user} />
			<h5 className="shift-user-shifts-count"> {totalDays} </h5>
		</Fragment>
	)
}
