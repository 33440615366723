import {API, find} from '@eitje/easy_api'
import NewsForm from 'components/forms/news_form'
import Modal from 'components/general/modal'
import {makeButtonText} from 'helpers/posts'
import {history} from 'index'
import {t} from 'initializers/i18n'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import './styles/edit_post_modal.less'

const ModalPage = () => {
	const {id} = useParams()
	const post = useSelector(state => find(state, 'posts', id))
	const {published} = post
	const form = useRef(null)
	const optionRef = useRef(null)

	const [publishType, setPublishType] = useState()
	const buttonText = makeButtonText(publishType, published)
	const canDelete = id

	return (
		<Modal
			okText={buttonText}
			hideClose={!published}
			onDel={canDelete ? () => API.destroy('posts', id) : undefined}
			onOk={() => form.current && form.current.submit()}
			title={t('editPost')}
			wrapClassName="edit-post-modal"
		>
			{!published && <div className="post-modal-options-container postFormTopPanel">{optionRef.current}</div>}

			<NewsForm
				afterSubmit={history.goBack}
				ref={form}
				item={post}
				setEditPublishType={setPublishType}
				editPublishType={publishType}
				optionRef={optionRef}
			/>
		</Modal>
	)
}

export default ModalPage
