import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useFind} from '@eitje/easy_api'
import {PlanningTemplate, Team} from 'models/index'
import './styles/template_summary.less'
import {EntityCount} from 'common/components/entity_count'
import {getKeys} from 'helpers/object'

const TemplateSummary = ({id}) => {
	const template = PlanningTemplate.find(id)
	const {team_shifts = {}, team_ids} = template
	const teamsByEnvName = _.groupBy(Team.where(team_ids), 'environment.naam')

	if (!utils.exists(team_shifts)) return null

	return (
		<div className="template-summaries">
			{Object.keys(teamsByEnvName)?.map(envName => (
				<TemplateEnvironment envName={envName} teams={teamsByEnvName[envName]} shiftsByTeam={team_shifts} />
			))}
		</div>
	)
}

const TemplateEnvironment = ({envName, teams, shiftsByTeam}) => {
	const env = useFind('environments', {naam: envName})
	const teamIdsFromShifts = getKeys(shiftsByTeam)
	const teamIds = Team.where({id: teamIdsFromShifts, environment_id: env.id}).ids()

	const totalShiftAmt = teamIds.map(teamId => shiftsByTeam[teamId]).flat().length
	return (
		<div className="template-summary">
			<div className="template-team template-team-env-name">
				<h6>{envName}</h6>
				<h5>
					<EntityCount name="shift" count={totalShiftAmt} />
				</h5>
			</div>
			{teams.map(team => (
				<TemplateTeam amt={shiftsByTeam[team.id].length} team={team} />
			))}
		</div>
	)
}

const TemplateTeam = ({team, amt}) => {
	return (
		<div className="template-team">
			<h6>{team.naam} </h6>
			<h5>
				<EntityCount name="shift" count={amt} />
			</h5>
		</div>
	)
}

export default TemplateSummary
