import './styles/right_panel.less'

export const ModalRightPanel = props => {
	const {RightPanel, rightPanelWidth = 200, rightPanelProps} = {...props}
	if (!RightPanel) return null
	return (
		<div data-width={rightPanelWidth} className="eitje-modal-2-right-panel">
			<RightPanel {...rightPanelProps} />
		</div>
	)
}
