import {useOrgEnvSetting} from 'hooks/use_env_setting'
import {t} from 'initializers/i18n'
import {useAvContext} from 'contexts/availability'
import FixedRangePicker from 'components/date/fixed_range_picker'
import MonthPicker from 'components/date/month_picker'
import {CheckboxButton} from 'components/ui'
import {Layout, Text} from 'common/components'
import './styles/sub_header.less'

const SubHeader = () => {
	const defaultUnav = useOrgEnvSetting('beschikbaarheid', 'default').every(e => e == 'unavailable')
	return (
		<Layout horizontal="spaceBetween" padding="4 16" width="100%" borderBottom>
			<DateRangePicker />
			{!defaultUnav && <ToggleAutoLock />}
		</Layout>
	)
}

const ToggleAutoLock = () => {
	const {autoLockEnabled, toggleAutoLockEnabled} = useAvContext()

	return (
		<Layout gap={12} vertical="center">
			<Text small>{t('toggleOptions')}:</Text>
			<CheckboxButton t="autoLock" checked={autoLockEnabled} onChange={toggleAutoLockEnabled} textClassName={'padding-right-sm'} />
		</Layout>
	)
}

const DateRangePicker = () => {
	const {mode, setDates, dates} = useAvContext()
	const sharedProps = {initialValue: dates[0], onChange: dates => setDates(dates[0])}

	if (mode == 'monthly') return <MonthPicker {...sharedProps} />
	if (mode == 'weekly') return <FixedRangePicker {...sharedProps} initialLeading length={7} />
}

export default SubHeader
