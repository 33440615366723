import {useMemo} from 'react'
import {usePlanningDates} from 'cores/planning/index'
import {useSelector} from 'hooks'
import {makeRange, rangeToDateArray, rangeFromStart} from 'helpers'
import {date} from 'initializers/date'
import {baseSetRawDates, baseSetPeriod, getPeriod} from 'common/components/advanced_table/consumers'

// Curry functions to share logic with other AdvancedTable instances that use period type and period pickers.
const setRawDates = baseSetRawDates('SET_PLANNING_TABLE_DATES')
const setPeriod = baseSetPeriod('SET_PLANNING_TABLE_PERIOD')

export const usePlanningTableDates = () => {
	const state = useSelector(state => state.planning.table)

	// By default, use the current week dates of planning per team and per member: this is both efficient (don't have to request additional shifts)
	// and intuitive (the user was already looking at that week, besides, this is probably the current week).
	const {startDate: defaultStartDate, endDate: defaultEndDate} = usePlanningDates()
	let {startDate, endDate, period} = state
	startDate = startDate ? date(startDate) : defaultStartDate
	endDate = endDate ? date(endDate) : defaultEndDate
	const dateRange = useMemo(() => makeRange(startDate, endDate), [startDate, endDate])
	const dateArray = rangeToDateArray(dateRange)
	period = period || getPeriod(startDate, endDate)

	const diff = endDate.startOf('day').diff(startDate.startOf('day'), 'days')

	const setDates = date => {
		const newRange = rangeFromStart({start: date, length: diff})
		setRawDates(newRange.start, newRange.end)
	}

	const values = {startDate, endDate, period, dateRange, dateArray, setDates, setRawDates, setPeriod, diff}
	return values
}
