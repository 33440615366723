export const dbConfig = {
	user: {
		hasMany: ['userEmploymentType', 'workSchedule', 'contractHolder', 'salary', 'team'],
	},
	integrationExtEnvironment: {
		hasMany: ['environment', 'integrationExtRevenueGroup'],
	},
	integrationBaseConnection: {
		hasMany: ['integrationExtEnvironment'],
	},
	omzetGroep: {
		hasMany: ['integrationExtRevenueGroup'],
	},
	timeRegistrationShift: {
		hasMany: ['checkIn'],
	},
	roosterTemplate: {
		hasMany: ['team', 'environment'],
	},
}

export default dbConfig
