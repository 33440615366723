import {t} from 'initializers/i18n'
import {DocsButton, Layout} from 'common/components'
import './styles/revenue_info.less'

const RevenueInfo = () => {
	return (
		<Layout direction="vertical" width={250}>
			<Layout className="revenue-info" direction="vertical">
				<div>
					<h3>{t('planning.subheader.calculations')} </h3>
					<h5>{t('planning.revenue_table.helper.subtitle')}</h5>
				</div>
				<RevenueData />
				<div>
					<h4>{t('planning.revenue_table.helper.what_these_numbers_tell')} </h4>
					<h5>
						<b>€:</b> {t('planning.revenue_table.helper.revenue_day')}
					</h5>
					<h5>
						<b>{t('common.ap')}:</b> {t('planning.revenue_table.helper.labor_productivity')}
					</h5>
					<h5>
						<b>$:</b> {t('planning.revenue_table.helper.labor_costs')}
					</h5>
				</div>
			</Layout>
			<DocsButton to="6019536" width="full" height="small" />
		</Layout>
	)
}

const RevenueData = () => {
	return (
		<div className="revenue-data">
			<h4>{t('common.example')} </h4>
			<h5>
				<b> € 1210,75 </b>
			</h5>
			<h5>
				<b> {t('common.ap')}: </b> €48
			</h5>
			<h5>
				<b> $ </b> €403,- | 30%
			</h5>
		</div>
	)
}

export default RevenueInfo
