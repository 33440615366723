import {all} from '@eitje/easy_api'
import {date} from 'initializers/date'
import {createSelector} from 'reselect'
import {envIdSelector} from 'selectors/entities'

const verzoekenSel = state => all(state, 'leaveRequests')

const notInPast = _date => {
	const dateStart = date(_date).startOf('day')
	const today = date().startOf('day')
	return dateStart.isSameOrAfter(today)
}

export const pendingVerzoekenSel = createSelector(verzoekenSel, verzoeken =>
	verzoeken.filter(v => !v.accepted && !v.denied && notInPast(v.start_datetime)),
)

export const envPendingVerzoekenSel = createSelector(pendingVerzoekenSel, envIdSelector, (vzks, envId) =>
	vzks.filter(v => v.environment_id === envId),
)
