import ActiveRecord from 'models/active_record/base'
import utils from '@eitje/web_utils'

class RevenueGroup extends ActiveRecord {
	static tableName = 'omzetGroepen'
	static defaultJoins = ['integrationExtRevenueGroups']

	integrated() {
		return utils.exists(this.integration_ext_revenue_group_ids)
	}
}

export default RevenueGroup
