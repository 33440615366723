import {useEnvSettingGroup} from 'hooks/use_env_setting'
import {DropdownPicker} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {useForm, ContextForm} from '@eitje/form'
import {predictionModes} from 'cores/revenue/index'
import {FormSimpleInputNumber} from 'lib/form/fields/simple_input_number'
import {FormSubmitButton} from 'components/form'
import {saveSettingGroup} from 'actions/settings'
import {BackButton} from 'common/components'

const PredictionForm = () => {
	const modes = predictionModes.map(v => ({id: v, name: t(`revenue.revenue_modal.forecast_${v}`)}))
	const {getData} = useForm()
	const {forecast_mode} = getData()
	return (
		<div className="revenue-form">
			<div className="revenue-modal-body">
				<FormRow>
					<DropdownPicker required labelField="name" field="forecast_mode" items={modes} />
				</FormRow>
				<FormRow>
					<FormSimpleInputNumber extraLabel placeholder="" min={1} disabled={forecast_mode != '1_year'} field="forecast_pct" />
				</FormRow>
			</div>

			<div className="revenue-modal-footer">
				<div className="footer-buttons-container">
					<BackButton goBackToForeground />
					<FormSubmitButton />
				</div>
			</div>
		</div>
	)
}

const Wrapper = ({envId}) => {
	const initialValues = useEnvSettingGroup('omzet', envId)
	return (
		<ContextForm
			transNamespace="revenue_forecast_settings"
			initialValues={initialValues}
			onSubmit={data => saveSettingGroup('omzet', data, {env_id: envId})}
			className="eitje-form-2-use-borders"
		>
			<PredictionForm />
		</ContextForm>
	)
}

export default Wrapper
