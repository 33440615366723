import {PlanningShift} from 'models/index'
import useQueryParams from 'hooks/use_query_params'
import {useWhere} from '@eitje/easy_api'
import {BreaktimePicker, MealPicker} from 'components/shared'
import {Input, Switch, TimePicker} from '@eitje/form-fields-web'
import useValidateDayStart from 'hooks/validation/use_validate_day_start'
import {transformShiftData} from 'shared/helpers'
import {useShared} from 'hooks'
import {ListPicker, MultiEdit as BaseMultiEdit, ShiftTypePicker} from 'common/components'

const TeamPicker = () => {
	const {envId} = useShared()
	const teams = useWhere('teams', {environment_id: envId, locked: false})
	return <ListPicker single dropdown required title="teams" items={teams} labelField="naam" field="team_id" />
}

const MultiEdit = () => {
	const {ids} = useQueryParams()
	const shifts = PlanningShift.where({id: ids})
	const validate = useValidateDayStart()
	const {multiActiveEnvOrg} = useShared()

	// TODO: refactor once planning allows team general

	const teamField = {
		field: 'team_id',
		Component: <TeamPicker />,
	}

	const formFields = () => {
		return [
			{
				field: 'from',
				Component: <TimePicker hideClearIcon={false} field="from" validate={validate} />,
			},
			{
				field: 'till',
				Component: <TimePicker field="till" validate={validate} />,
			},
			{
				field: 'pauze_duur',
				Component: <BreaktimePicker />,
			},
			{
				field: 'maaltijd',
				Component: <MealPicker />,
			},
			{
				field: 'typ',
				Component: <ShiftTypePicker stretchToParent={false} />,
			},
			{
				field: 'remarks',
				Component: <Input textarea field="remarks" autoSize={{minRows: 1, maxRows: 6}} />,
			},
			{
				field: 'published',
				Component: <Switch field="published" />,
			},
			!multiActiveEnvOrg && teamField,
		].filter(Boolean)
	}

	return <BaseMultiEdit shifts={shifts} type="planning_shift" submit={data => handleSubmit({shifts, data})} formFields={formFields} />
}

const handleSubmit = ({shifts, data}) => {
	transformShiftData(data)

	const items = shifts.map(s => ({id: s.id, ...data}))
	return PlanningShift.multiUpdate(items)
}

export default MultiEdit
