import {DocsButton, Title, EitjeButton, Layout} from 'common/components'
import {useIntegrationConstants} from 'cores/integrations'
import {t} from 'initializers/i18n'
import Integration from './integration'

import './styles/index.less'

const MARGIN = ['0', 'auto']

const MyIntegrations = ({items}) => {
	const grouped = _.groupBy(items, 'sanitized_type')
	const integTypes = Object.keys(grouped)

	return (
		<Layout direction="vertical" className="my-integrations integrations-page-content" margin={MARGIN} gap={40}>
			{integTypes.map(type => (
				<IntegGroup type={type} items={grouped[type]} />
			))}
		</Layout>
	)
}

const IntegGroup = ({items, type}) => {
	const {docsLink, name} = useIntegrationConstants(type)
	return (
		<Layout className="provider-connections" direction="vertical" gap={8}>
			<Layout className="title-header" width={800} horizontal="spaceBetween" height={60}>
				<Title semiBold> {name} </Title>
				<Layout className="provider-index-header-right">
					<EitjeButton
						iconLeft="plus"
						modalLink={`/integrations/${type}/activate`}
						t={{key: 'integrations.new_specific', integ_name: name}}
					/>
					<DocsButton to={docsLink} colorSet="grey-bordered">
						{t('integrations.specific_help', {integ_name: name})}
					</DocsButton>
				</Layout>
			</Layout>

			{items.map(i => (
				<Integration item={i} />
			))}
		</Layout>
	)
}

export default MyIntegrations
