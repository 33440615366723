import {useState, useEffect} from 'react'
import {date} from 'initializers/date'
import {useInterval} from '@eitje/react-hooks'

const useCountup = ({defaultValue = 0, defaultTime} = {}) => {
	let def = defaultValue

	if (defaultTime) {
		def = date().diff(date(defaultTime), 's')
	}

	const [secondsElapsed, setSeconds] = useState(def)

	useInterval(() => {
		setSeconds(secs => secs + 1)
	}, 1000)

	useEffect(() => {
		if (defaultTime) {
			setSeconds(date().diff(date(defaultTime), 's'))
		}
	}, [defaultTime])

	return secondsElapsed
}

export default useCountup
