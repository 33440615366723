import {Layout, Text} from 'common/components'
import {mdT} from 'initializers/i18n'
import {Fragment} from 'react'

export const MessageHeader = ({id, labelProps, Wrapper, ...rest}) => {
	const message = mdT(`message_header.headers.${id}.label`, labelProps)
	Wrapper = Wrapper || Fragment

	return (
		<Wrapper>
			<Layout horizontal="center" vertical="center" height={28} colorSet="solid" {...rest}>
				<Text white bold children={message} />
			</Layout>
		</Wrapper>
	)
}
