import {useTableTax, filterName, flatToFilter, filterToFlat} from 'cores/exports2/index'
import {t} from 'initializers/i18n'
import {EitjeModal} from 'components/common'
import {useSimpleContext} from 'contexts/simple'
import {FormRow} from 'components/ui'
import {ContextForm} from '@eitje/form'
import {useTaxonomy} from 'hooks'
import {history} from 'index'
import utils from '@eitje/web_utils'
import {getEnums} from 'data/enums'
import {ShiftType} from 'models'
import {ListPicker} from 'common/components'

const useMapping = item => {
	const {table_name} = item
	const contractTypes = useTaxonomy('contract_types')
	const regShiftTypes = getEnums('reg_shift')
	const planShiftTypes = ShiftType.all()
	const regParams = {options: regShiftTypes, labelField: 'naam'}

	const planParams = {
		options: planShiftTypes,
	}

	const shiftParams = table_name == 'planning_shift' ? planParams : regParams

	return {
		shift_types: {...shiftParams, title: 'types'},
		user_status: {
			single: true,
		},
		contract_guess: {
			single: true,
		},
		contract_active: {
			single: true,
		},
		external_id: {
			single: true,
		},
		contract_types: {
			options: contractTypes,
			title: t('common.type'),
			makeLabel: (filter, o) => t(o),
		},
	}
}

const Inner = () => {
	const {item} = useSimpleContext()
	const {filters} = useTableTax(item.table_name)
	const mapping = useMapping(item)

	const allowedFilters = filters.row.filter(f => !f.required)

	return (
		<EitjeModal form name="export_filters">
			<div className="eitje-form-2 eitje-form-2-use-borders">
				{allowedFilters.map(f => (
					<FilterComponent data={{...f, ...mapping[f.filter]}} />
				))}
			</div>
		</EitjeModal>
	)
}

const FilterComponent = ({data}) => {
	let {filter, options = [], makeLabel = filterName, ...rest} = data
	options = options.map(o => (_.isObject(o) ? o : {id: `${o}`, name: makeLabel(filter, o)}))
	return (
		<FormRow>
			<ListPicker dropdown items={options} field={filter} {...rest} />
		</FormRow>
	)
}

export const ExtraFilters = () => {
	const {updateState, setUpdateState} = useSimpleContext()
	const {row_filters} = updateState
	const submit = data => {
		const filters = flatToFilter(data)
		let newFilts = utils.findAndReplace({oldItems: row_filters, newItems: filters, oldIden: 'filter', newIden: 'filter'})

		newFilts = newFilts.filter(f => !_.isArray(f.value) || f.value.length > 0) // get out empty arrays, we don't want to get out false/null so can't use exists

		setUpdateState({row_filters: newFilts})
		return {ok: true}
	}

	const initial = filterToFlat(row_filters)

	return (
		<ContextForm
			afterSubmit={history.goBack}
			fieldProps={{placeholder: t('common.select_a_filter')}}
			onSubmit={submit}
			initialValues={initial}
			transNamespace="export_filters"
		>
			<Inner />
		</ContextForm>
	)
}
