import React from 'react'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {ModalLink} from 'components/routing'
import {
	useEnvTableContext,
	getPlanningShiftUrl,
	usePlanningContext,
	useTeamShifts,
	getPlannedHours,
	useEnvsContext,
} from 'cores/planning/index'
import TeamProvider from './context'
import '../styles/index.less'
import '../styles/planning_shifts_row.less'
import '../styles/planning_hours_row.less'
import '../styles/planning_availability_row.less'
import {Arrows, reorder} from 'components/list/draggable_list'
import {useLocalValue} from 'hooks/use_local'
import TeamHoursDay from './team_day/team_hours_day'
import TeamAvailabilityDay from './team_day/team_availability_day'
import TeamShiftsDay from './team_day/team_shifts_day'
import useHasRole from 'hooks/use_has_role'
import {MultiActionCheckbox} from 'cores/planning/components/multi_action_checkbox'
import {EitjeLazyLoad} from 'common/components/eitje_lazyload'
import {WeekWithShifts} from 'common/components/lazyload_placeholders'
import {EitjeButton} from 'common/components'
import {isSafari, isFirefox} from 'react-device-detect'

const lazyLoadProps = {
	height: 500,
	placeholder: !isSafari && !isFirefox && <WeekWithShifts />,
	calculateHeight: true,
}

const TeamRow = ({item, idx}) => {
	return (
		<div className="planning-row">
			<TeamProvider team={item}>
				<TeamRowInner item={item} idx={idx} />
			</TeamProvider>
		</div>
	)
}

const TeamRowInner = ({item, idx}) => {
	const {env} = useEnvTableContext()
	const hasManagerRole = useHasRole('manager', env.id)

	return (
		<EitjeLazyLoad {...lazyLoadProps}>
			<TeamShiftsRow item={item} idx={idx} />
			{hasManagerRole && <TeamHoursRow />}
			{hasManagerRole && <TeamAvailabilityRow />}
		</EitjeLazyLoad>
	)
}

const TeamAvailabilityRow = () => {
	const {dateArray} = usePlanningContext()
	const visible = useLocalValue('planningQuickPlan')
	if (!visible) return null
	return (
		<div className="planning-availability-row">
			<div className="planning-availability-title">
				<div className="planning-availability-title-content">
					<h4 className="planning-availability-title-text"> {t('common.availability')} </h4>
				</div>
			</div>
			{dateArray.map(d => (
				<TeamAvailabilityDayWrapper date={d} />
			))}
		</div>
	)
}

const TeamAvailabilityDayWrapper = ({date}) => {
	const teamShifts = useTeamShifts()
	const shifts = teamShifts.where({date: date})
	if (shifts.length == 0) return <EmptyTeamAvailability />
	return <TeamAvailabilityDay date={date} />
}

const EmptyTeamAvailability = () => <div className="planning-day-availability" />

const TeamHoursRow = () => {
	const teamShifts = useTeamShifts()
	const {total, withUser} = getPlannedHours(teamShifts)
	const {dateArray} = usePlanningContext()
	const showHourRow = useLocalValue('planningShowHourRow')
	if (!showHourRow) return null
	return (
		<div className="planning-hours-row">
			<div className="planning-day-hours-title">
				<p>
					{utils.minToTimeString(withUser)} / {utils.minToTimeString(total)}
				</p>
			</div>
			{dateArray.map(d => (
				<TeamHoursDay date={d} />
			))}
		</div>
	)
}

const TeamShiftsRow = ({item, idx}) => {
	const {dateArray} = usePlanningContext()
	const {teams, env} = useEnvTableContext()
	const {multiEdit} = useEnvsContext()
	const teamShifts = useTeamShifts()
	const hasManagerRole = useHasRole('manager', env.id)
	const onPosChange = (amt, idx) => {
		const startIdx = idx
		const endIdx = amt + idx
		const newTeams = reorder(teams, startIdx, endIdx)
		API.updateMulti(
			'teams',
			newTeams.map((t, idx) => ({id: t.id, rooster_order: newTeams.length - idx})),
		)
	}

	const checkbox = teamShifts.length ? <MultiActionCheckbox shifts={teamShifts} /> : null
	const teamColor = item.color || Colors.lightBlue
	const newShift = <EitjeButton modalLink={getPlanningShiftUrl({team: item})} height="small" iconLeft="plus-small" />

	return (
		<div className="planning-shifts-row">
			<div className="planning-day-shifts-title">
				<div className="planning-day-shifts-title-content">
					<div className="planning-day-shifts-title-left-content">
						{hasManagerRole && <Arrows hideCount onPosChange={onPosChange} totalLength={teams.length} idx={idx} />}
						<ModalLink
							className="planning-day-shifts-team-title"
							to={`/environments/${item.environment_id}/teams/${item.id}/`}
							hidden={!hasManagerRole}
						>
							<div className="planning-day-shifts-team-color" style={{backgroundColor: teamColor}} />
							<div className="team-name-text">
								<h4>{item.naam}</h4>
							</div>
						</ModalLink>
					</div>
					{hasManagerRole && (multiEdit ? checkbox : newShift)}
				</div>
			</div>
			{dateArray.map(d => (
				<TeamShiftsDay date={d} />
			))}
		</div>
	)
}

export default React.memo(TeamRow)
