import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {BaseConnection, ExtEnvironment, ExtRevenueGroup} from 'models/index'
import {useShared} from 'hooks/use_shared'

export const useEnvIntegration = ({envId, ...opts} = {}) => {
	const {envId: defaultEnvId} = useShared()
	const env = useFind('environments', envId || defaultEnvId)
	const conn = BaseConnection.find({environment_ids: [env.id], ...opts})
	return conn
}

const useBaseIntegration = envId => {
	const env = useFind('environments', envId)
	return BaseConnection.where({environment_ids: [env.id]})
}

export const usePosIntegration = envId => {
	const conns = useBaseIntegration(envId)
	return conns.find(c => c.isPos())
}

export default useEnvIntegration
