import {createContext, useContext, useMemo} from 'react'
import {useShared, setFilter, useRoleOrgEnvs} from 'hooks'
import {formatFilterValue, useClearFilters, useFilteredItems, useSyncTeamsWithEnvs} from './helpers'
import {useSearchContext} from 'contexts/search'
import {useForceCheck} from 'cores/planning'
import {FilterFooter} from '.'
import {useSelector} from 'react-redux'
import {Team} from 'models'

const FilterContext = createContext({})
const {Provider} = FilterContext

export const FilterFooterProvider = props => {
	let {id, children, rightElements, items, watchedValue, role} = props
	const {search} = useSearchContext()
	const {org, orgEnvs} = useShared()

	const storeFilters = useSelector(state => state.filters)
	const currentFilters = _.pickBy(storeFilters, (_, key) => key.endsWith(`-${id}-${org.id}`))
	const filterValues = _.mapKeys(currentFilters, (_, key) => key.split('-')[0])
	const clearFilters = useClearFilters({currentFilters})
	const filters = props.filters
		.filter(Boolean)
		.map(filter => (_.isString(filter) ? {name: filter} : filter))
		.map(filter => ({...filter, filterFunction: (item, value) => (filter.filterFunction ? filter.filterFunction(item, value) : item)}))

	const hasFiltered = filters.reduce((acc, filter) => {
		const {name} = filter
		acc[name] = filterValues[name]?.length > 0
		return acc
	}, {})

	const filterProps = filters.reduce((acc, item) => {
		if (_.isObject(item?.contextProps)) {
			return {...acc, ...item.contextProps}
		}
		return acc
	}, {})

	// uses the environmentIds array from filterValues to get the filtered envs
	const roleOrgEnvs = useRoleOrgEnvs(role)
	const currentEnvs = role ? roleOrgEnvs : orgEnvs
	let envs = filterValues.environment?.length ? currentEnvs?.filter(env => filterValues.environment.includes(env.id)) : currentEnvs

	const formattedFilterValues = formatFilterValue({filterValues, filters, envs})
	const filteredItems = useFilteredItems({...props, ...filterProps, search, filterValues: formattedFilterValues})
	const filteredOutAmount = items.length - filteredItems.length

	const teamIds = formattedFilterValues?.teamIds
	const teams = Team.where(teamIds)

	const activeEnvIds = teams.length > 0 ? teams?._map('environment_id').uniq() : envs?._map('id')
	const filterEnvsWithActiveTeams = env => activeEnvIds?.includes(env.id)
	envs = envs?.filter(filterEnvsWithActiveTeams)

	const handleSetFilterValue = (name, value) => {
		setFilter([`${name}-${id}-${org.id}`], value)
	}

	useSyncTeamsWithEnvs({...filterValues, handleSetFilterValue, envs, filters})
	useForceCheck(envs, filterValues.team, search)

	const value = useMemo(
		() => ({
			core: id,
			filters,
			unformattedValues: filterValues,
			filterValues: formattedFilterValues,
			filteredItems,
			rightElements,
			handleSetFilterValue,
			FilterFooter,
			clearFilters,
			hasFiltered,
			filteredOutAmount,
			currentEnvs,
			envs,
			teams,
			...filterProps,
		}),
		[storeFilters, filteredOutAmount, teams, search, items, watchedValue],
	)

	return <Provider value={value}> {children} </Provider>
}

export const useFilterFooterContext = () => useContext(FilterContext)
