import React, {createContext, useMemo, useContext} from 'react'

const ShiftContext = createContext()
const {Provider} = ShiftContext

const ShiftProvider = ({children, shift}) => {
	const val = useMemo(() => ({item: shift}), [shift.updated_at, shift.shiftType, shift.team.updated_at])
	return <Provider value={val}> {children} </Provider>
}

export const useShiftContext = () => {
	return useContext(ShiftContext)
}

export default ShiftProvider
