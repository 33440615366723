import _ from 'lodash'

const initialState = {
	startDate: null,
	endDate: null,
	per_member: {
		startDate: null,
		endDate: null,
		period: null,
	},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'SET_TIME_REGISTRATION_DATES':
			const obj = _.pick(action, 'startDate', 'endDate', 'period')
			return {
				...state,
				...obj,
			}

		case 'SET_REG_PER_MEMBER_DATES':
			const obj2 = _.pick(action, 'startDate', 'endDate', 'period')

			return {
				...state,
				per_member: {
					...state.per_member,
					...obj2,
				},
			}
		case 'SET_REG_PER_MEMBER_PERIOD':
			return {
				...state,
				per_member: {
					...state.per_member,
					period: action.period,
				},
			}

		default:
			return state
	}
}
