import utils from '@eitje/utils'
import useSearch from 'hooks/use_search'
import _ from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import List from 'components/list/list'
import DraggableList from 'components/list/draggable_list'
import {Placeholder as _Placeholder} from 'common/components'

export const useList = props => {
	let {
		def,
		items = [],
		draggable,
		allowDeactivate,
		searchProps = {},
		activeImperative,
		valueField = 'id',
		placeholderProps = {},
		multiple,
		Placeholder = _Placeholder,
		...rest
	} = props
	const randomId = useMemo(() => utils.randomId(), [])
	let [active, setActive] = useState(def)
	const {visible, trigger, setVisible} = useDropdownList(rest)
	active = activeImperative || active
	const hasSearch = _.has(rest, 'hasSearch') ? rest.hasSearch : utils.exists(searchProps)

	const {search, filteredItems, searchInput, setSearch, emptyElement} = useSearch(items, {...searchProps, showSearch: hasSearch})
	items = hasSearch ? filteredItems : items

	const value = utils.alwaysDefinedArray(active)

	const getNewValue = newVal => {
		if (multiple) return utils.toggle(value, newVal)
		if (allowDeactivate) return active === newVal && utils.exists(value) ? null : newVal
		return newVal
	}

	const onClick = item => setActive(getNewValue(item[valueField]))

	const activeItems = items.filter(i => value.includes(i[valueField]))
	const activeItem = activeItems[0]

	useEffect(() => {
		if (!activeItem && active && items) {
			const item = items[0] || {}
			onClick(item)
		}
	}, [activeItem?.id])

	useEffect(() => {
		if (!multiple && active && randomId) {
			const activeIdx = items.indexOf(activeItem)
			if (activeIdx > -1) {
				const node = document.querySelector(`#${randomId}-${activeIdx}`)
				node && node.scrollIntoViewIfNeeded && node.scrollIntoViewIfNeeded()
			}
		}
	}, [randomId])

	const isActive = (i = {}) => value.includes(i[valueField])

	const ListEl = draggable ? DraggableList : List
	let list

	if (!visible) {
		list = null
	} else if (items.length === 0 && (!_.isEmpty(placeholderProps) || props.Placeholder) && !search) {
		list = <Placeholder size="small" {...placeholderProps} />
	} else if (items.length === 0 && hasSearch) {
		list = emptyElement
	} else {
		list = <ListEl draggable={draggable} id={randomId} isActive={isActive} onClick={onClick} {...rest} items={items} />
	}

	return {active, trigger, list, activeItem, activeItems, setActive, searchInput: visible ? searchInput : null, setSearch, setVisible}
}

const useDropdownList = props => {
	const {dropdown, Trigger = 'p'} = props
	const [visible, setVisible] = useState(!dropdown)
	const trigger = (
		<div className="dropdown-list-trigger-container" onClick={() => setVisible(!visible)}>
			<Trigger />
		</div>
	)
	return {trigger, visible, setVisible}
}

export default useList
