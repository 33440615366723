import utils from '@eitje/utils'
import FileList from 'components/file_list'
import PdfViewer from 'components/pdf_viewer'
import {useShared} from 'hooks/use_shared'
import React, {useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {ArticleView} from '../articles/editor'

const Page = ({item, likePosition}) => {
	const {me} = useShared()
	const {pathname} = useLocation()
	const scrollRef = useRef(null)

	const isTest = pathname.includes('test_run')
	const {title, content, id, liker_ids = [], pdf_content, attachments} = item

	const containerClass = utils.makeCns('training-article-container', pdf_content && 'wide')

	return (
		<>
			<div ref={scrollRef} className="row-with-justify-center">
				<div className={containerClass}>
					<div className="margin-bottom-md">
						<h3>{title} </h3>
					</div>

					{!pdf_content && <ArticleView id={id} content={content} view />}

					{pdf_content && <PdfViewer pdf={pdf_content} modalView />}
				</div>
			</div>

			<aside className="training-file-list-container">
				<FileList items={attachments} smallFileItem itemProps={{disableRemove: true, width: '214px'}} />
			</aside>
		</>
	)
}

export default Page
