// Easily log multi variables with both their name and value in one shorthand, e.g. `log({envs, data})`
// Also prints the keys and values of objects, instead of the non descriptive [object, oject]
// Add a positional `title` argument if you want to identify different loggers, e.g. in different files

export const log = (variablesObject, logTitle) => {
	let title = '\n\n--- log'
	if (logTitle) title += ` ${logTitle}`
	title += ':'
	console.log(title)

	_.map(variablesObject, (varValue, varName) => {
		let prettyValue = varValue

		if (_.isObject(varValue)) {
			prettyValue = _.map(varValue, (value, key) => {
				const isFunction = typeof value === 'function'

				value = isFunction ? 'function' : value

				return `\t${key} = ${value}`
			}).join('\n')

			varName = `${varName}:\n`
		} else {
			varName += ' = '
		}

		console.log(`${varName}${prettyValue}`)
	})
}
