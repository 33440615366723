import {Icon} from '@eitje/web_components'
import {Layout, Text} from 'common/components'
import {useState, useEffect} from 'react'
import utils from '@eitje/web_utils'
import {Fragment} from 'react'
import './styles/user_tile.less'
import './styles/tile.less'
import {requireProps, isFunction} from 'helpers'

const STYLES = {
	baseTile: {
		layout: {horizontalPadding: 12},
		content: {horizontal: 'center'},
		label: {fontSize: 14},
		dropdown: {},
	},

	userTile: {
		layout: {horizontalPadding: 4},
		content: {horizontal: 'start', gap: 0},
		label: {fontSize: 12},
		dropdown: {placement: 'right-start'},
	},
}

export const WeekTableTileCell = ({
	children,
	Wrapper,
	displayName,
	columnType,
	cellType = 'baseTile',
	date,
	LeftContent,
	leftContentProps,
	classNames,
	dynamicPaddings,
	contentLayoutProps,
	dropdownIcon = true,
	...rest
}) => {
	requireProps('WeekTableTileCell', {columnType, cellType})
	const gap = 4
	const namespace = 'week-table-cell'
	const baseClassName = `${namespace}-${columnType}`
	const _classNames = utils.makeCns(namespace, 'week-table-tile', baseClassName, `${namespace}-${_.kebabCase(cellType)}`, classNames)
	const style = STYLES[cellType]
	const {horizontalPadding} = style['layout']
	const defaultPadding = `6 ${horizontalPadding}`
	Wrapper = Wrapper || Fragment

	const [padding, setPadding] = useState(defaultPadding)
	useEffect(() => {
		if (!dynamicPaddings) return

		const leftContentElement = document.querySelector(`.week-table-days-header .${baseClassName} .left-content-wrapper`)

		if (leftContentElement) {
			// If there is left content, we should implement a padding right, so that the cell contents remain centred horizontally
			const paddingRight = leftContentElement.offsetWidth + gap + horizontalPadding
			const padding = `6 ${paddingRight} 6 12`
			setPadding(padding)
		}

		if (!leftContentElement) setPadding(defaultPadding)
	}, [LeftContent])

	return (
		<Wrapper
			date={date}
			visibleAfterClick
			wrapperLayoutProps={{gap: 0}}
			layoutProps={{width: 'full', height: 'full'}}
			{...style['dropdown']}
		>
			<Layout
				{...rest}
				className={_classNames}
				horizontal="center"
				width="full"
				height="full"
				padding={padding}
				gap={gap}
				{...style['layout']}
			>
				{!!LeftContent && (
					<Layout gap={0} className="left-content-wrapper">
						<LeftContent {...leftContentProps} />
					</Layout>
				)}

				<Layout direction="vertical" grow {...style['content']} {...contentLayoutProps}>
					<Text truncate {...style['label']}>
						{displayName}
					</Text>
					{children}
				</Layout>

				{dropdownIcon && <Icon name="chevron-small-down" className="arrow-icon" size={14} />}
			</Layout>
		</Wrapper>
	)
}
