import i18n from 'i18next'
import {useTranslation, initReactI18next} from 'react-i18next'
import en from 'data/locales/en/en.json'
import nl from 'data/locales/nl/nl.json'
import LanguageDetector from 'i18next-browser-languagedetector'
import utils from '@eitje/web_utils'
import markdownJsx from 'i18next-markdown-jsx-plugin'
import pluralize from 'pluralize'
import {date} from 'initializers/date'
import {ENV} from 'initializers/api'

const getNthDay = day => {
	const m = date()
	m.date(day)
	return m.format('Do')
}

const languages = ['en', 'nl']

export const getLanguage = () => {
	const lng = i18n.language
	if (languages.includes(lng)) return lng
	return languages.find(l => lng.includes(l)) || 'nl'
}

function format(value, format, lng) {
	if (format == 'dateIndef') return value ? utils.fullHumanDate(value, {dayname: false}) : t('indefinite')
	if (format == 'date') return utils.fullHumanDate(value, {dayname: false})
	if (format == 'time') {
		return date(value).format('HH:mm')
	}

	if (format == 'dateTime') {
		return date(value).format('DD-MM-YYYY HH:mm')
	}

	if (format == 'dayMonthTime') {
		return date(value).format('DD MMMM, HH:mm')
	}

	if (format == 'dayMonthTimeShort') {
		return date(value).format('DD MMM, HH:mm')
	}

	if (format === 'fullDate') return utils.fullHumanYearDate(value)
	if (format === 'amountDays') return utils.daysPassed(value)
	if (format == 'countOrNull') return value && value > 0 ? value : null
	if (format === 'pluralize') return pluralize(value)
	if (format === 'capitalize') return utils.capitalize(value)
	if (format === 'uppercase') return value.toUpperCase()
	if (format === 'nth') return getNthDay(value)
	if (format === 'toHours') return utils.minToTimeString(value)

	return value
}

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(markdownJsx)
	.init({
		resources: {
			en: {
				translation: en,
			},
			nl: {
				translation: nl,
			},
		},
		fallbackLng: 'en',
		debug: false,
		interpolation: {
			format,
			escapeValue: false, // not needed for react as it escapes by default
		},
	})

export default i18n
// postProcess: 'markdown-jsx'
// we should get this in, but breaks v3/users

const devT = (text, opts) => {
	if (window.ignoreTranslations) return ignoreTrans(text, opts)
	const val = i18n.t(text, opts)
	// if (opts && opts['count'] && val) {
	//   const val = i18n.t(text, opts)
	//   return val && !val.includes(' ') ? pluralize(val) : val
	// }

	if (val) return val

	return text
}

const ignoreTrans = (text, opts) => {
	if (_.isArray(text)) {
		const i = i18n
		const val = text.find(t => {
			const translated = i18n.t(t)
			return translated != t
		})

		return val
	}

	const val = i18n.t(text)
	if (val == text && opts?.defaultValue) return opts?.defaultValue
	return text
}

const prodT = (text, opts) => {
	return i18n.t(text, opts)
}

export const t = ENV == 'production' ? prodT : devT

export const parseT = (T, name, blockNamespace) => {
	if (!T) return
	if (_.isString(T)) T = {key: T}

	let {key} = T
	if (name && !blockNamespace && key) key = `${name}.${key}`

	return t(key, _.omit(T, 'key'))
}

export const mdT = (text, opts) => t(text, {postProcess: 'markdown-jsx', ...opts})
