import {useEffect, useState} from 'react'
import {backend} from '@eitje/easy_api'

export const useStreamToken = () => {
	const [token, setToken] = useState()

	useEffect(() => {
		let isSubscribed = true

		const fetchToken = async () => {
			const res = await backend.post('chat/stream_token', {doNotLoad: true})

			if (isSubscribed && !res.problem) {
				const {token} = res.data
				setToken(token)
			}
		}

		try {
			fetchToken()
		} catch (error) {
			console.log('Error fetching stream token', error)
		}

		return () => (isSubscribed = false)
	}, [])

	return token
}
