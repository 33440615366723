import {ModalLink} from 'components/routing'
import useShared from 'hooks/use_shared'
import {useEnvIntegration} from 'cores/integrations'

export const ExportHoursLink = () => {
	const {hasAccordHoursRole} = useShared()
	if (!hasAccordHoursRole) return null
	return <ModalLink t="export_excel" to="/exports/hours?filter=hours,labour_costs" />
}

export const TimeSettingsLink = () => {
	const {hasAdminRole} = useShared()
	if (!hasAdminRole) return null
	return <ModalLink t="time_settings" to="/settings/time" />
}

export const IntegrationLink = () => {
	const {hasFinancialRole} = useShared()
	const modernInteg = useEnvIntegration({hr: true})
	if (!hasFinancialRole || !modernInteg.hasPush) return null
	return <ModalLink t={{key: 'integrations.hr.export_hours_to', providerName: modernInteg.name}} to={modernInteg.exportHoursUrl()} />
}
