import {t} from 'initializers/i18n'
import {useFind} from '@eitje/easy_api'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import {RevenueGroup, RevenueDay} from 'models/index'
import Forecast from './forecast'
import Actual from './actual'
import {Route, useRouteMatch} from 'react-router-dom'
import {usePlanningDates} from 'cores/planning/index'
import './styles/index.less'
import {rangeFromStart, rangeToDateArray} from 'helpers/date'
import useQueryParams from 'hooks/use_query_params'
import {RouteToggle} from 'components/ui'
import {buildSearchParams} from 'components/routing'

const Insert = () => {
	const {url} = useRouteMatch()
	const forecastUrl = `${url}/forecast`
	const actualUrl = `${url}/actual`
	const {envId} = useParams()
	const env = useFind('environments', envId)
	const {startDate} = useQueryParams()
	let {dateArray, dateRange} = usePlanningDates()
	let dates
	if (startDate) {
		dateRange = rangeFromStart({start: startDate})
		dates = rangeToDateArray(dateRange)
	}

	if (!dates) dates = dateArray

	const revenueGroups = RevenueGroup.where({environment_id: envId})
	let revenueDays = RevenueDay.where({date: dateRange, omzet_groep_id: revenueGroups.map(r => r.id)})

	revenueDays = revenueDays.map(d => ({...d, amt: d.amt(), date_group: d.dateGroup()}))

	const searchParams = buildSearchParams({startDate})

	const items = [
		{name: t('revenue.rev_modal.forecast'), path: forecastUrl + searchParams},
		{name: t('revenue.rev_modal.realization'), path: actualUrl + searchParams},
	]

	return (
		<Modal title={t('revenue.rev_modal.title', {envName: env.naam})} width="760px" className="revenue-modal">
			<div className="tabs-container">
				<RouteToggle items={items} modal withSearchQuery />
			</div>

			<Route path={forecastUrl} render={() => <Forecast dates={dates} revenueDays={revenueDays} envId={envId} />} />
			<Route path={actualUrl} render={() => <Actual dates={dates} revenueDays={revenueDays} envId={envId} />} />
		</Modal>
	)
}

export default Insert
