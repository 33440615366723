import {Button, Input, Popconfirm, Steps} from 'antd'
import {mdT, t} from 'initializers/i18n'
import React, {forwardRef} from 'react'
import './styles/ui.less'

import CheckMark from 'components/common/check_mark'
import ProgressionBlock from 'components/common/progression_block'
import {
	ButtonMessageBox,
	HoverButton,
	RoundedButton,
	CheckboxButton,
	LocalCheckboxButton,
	PlusFab,
	FormCheckboxButton,
} from 'components/common/buttons'
import {DeleteCross} from 'components/common/delete_elements'
import {ColorCircle} from 'components/common/color_circle'
import {LightboxImage} from 'components/common/lightbox_image'
import {MaxList} from 'components/common/max_list'
import {UserImage, UserAvatar} from 'components/common/user_image'
import {BoxedRow, RoundedRow} from 'components/common/row'
import {DropdownWithCounter} from 'components/common/dropdown_with_counter'
import {UrlCopy} from 'components/common/url_copy'
import UserList from 'components/list/implementations/user_list'
import BasicList from 'components/list/implementations/basic_list'
import Affix from 'components/common/affix'
import FormRow from 'components/form_row'
import {Link} from 'components/routing'
import RouteToggle from 'components/common/route_toggle'
import {Label} from 'common/components'
export * from 'components/common/index'

export {UserList, BasicList}

const {Step} = Steps

export {Button, Steps, Step, Popconfirm}
export {
	ButtonMessageBox,
	HoverButton,
	RoundedButton,
	CheckboxButton,
	LocalCheckboxButton,
	PlusFab,
	FormCheckboxButton,
	FormRow,
	RouteToggle,
}
export {UserImage, UserAvatar}
export {DeleteCross}
export {CheckMark, ProgressionBlock, LightboxImage, ColorCircle, DropdownWithCounter, BoxedRow, RoundedRow, MaxList, UrlCopy, Affix}

export const EmptyMsg = ({kind, style, className}) => (
	<p className={className} style={{...style}}>
		{mdT('notYet', {kind: `$t(${kind})`})}
	</p>
)

// make new popover component who implements content as a function that also returns the ref

export const Holder = ({children, hideChildren, ...rest}) => <div {...rest}> {!hideChildren && children} </div>

export const Horizontal = forwardRef((props, ref) => <div ref={ref} className="horizontal" {...props} />)

const warningTypes = {
	article: 'isDraftMessage',
}

export const WarningBanner = ({type}) => {
	return (
		<div className="warning-banner-container">
			<span className="warning-banner-emph">{t('att')}:</span>
			<span className="warning-banner-body">{t(warningTypes[type])}</span>
		</div>
	)
}

export const Underline = () => <div className="eitje-underline" />

const SearchIcon = () => {
	return <img src="/images/search.png" height={18} />
}

export const SearchInput = props => {
	return <Input prefix={<SearchIcon />} bordered={false} className="search_input" placeholder="Search Input" {...props} />
}

export const BackBreadcrumb = ({to, text = t('common.back')}) => {
	return (
		<Link to={to} className="back-breadcrumb">
			<h4 className="back-breadcrumb-text">{'<'}</h4>
			<h4 className="back-breadcrumb-text">{`${text}`} </h4>
		</Link>
	)
}

export const Err = ({error}) => {
	return (
		<div className="eitje-error">
			<Label baseColor="red" t="error" />
			<h5 className="eitje-error-text">{error}</h5>
		</div>
	)
}
