import {EitjeDropdown, SubmitPopout, Layout} from 'common/components'
import {Link} from 'components/routing'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import {useShared} from 'hooks'

export const RowWrapper = ({row, ...rest}) => {
	const {orgEnvs} = useShared()
	const user = row.original
	const {id, full_name} = user
	const profileLabel = t('common.view', {kind: full_name})

	const elements = [
		{
			icon: 'person',
			element: <Link to={`/user/${id}/profile`} children={profileLabel} />,
		},
		{
			icon: 'person-plus',
			title: t('common.make_back_in_service'),
			children: orgEnvs.map(env => {
				return {
					icon: 'plus',
					element: (
						<Layout enableRole="manager" roleEnv={env.id}>
							<SubmitPopout onSubmit={() => API.addAssoc('users', {environment_ids: [env.id], id: user.id})} children={env.naam} />
						</Layout>
					),
				}
			}),
		},
	]

	return <EitjeDropdown {...rest} className="user-options-dropdown" placement="bottom-start" trigger="click" elements={elements} />
}
