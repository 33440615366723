import {useEnvContext} from '../contexts/env'
import {InsertButton} from './components'
import {useAvContext} from 'contexts/availability'
import {CurrentWeek} from 'components/shared/days_header/current_week'
import {SettingsButton, Text, Layout} from 'common/components'
import '../../styles/availability.less'
import './index.less'

const PADDING = [0, 24]
const MARGIN = [0, 24, 0, 0]

const Header = ({disabled}) => {
	const {env, teamFilterElement} = useEnvContext()
	const {dates} = useAvContext()

	return (
		<Layout className="environment-availability-header" height={64} padding={PADDING} horizontal="spaceBetween">
			<Layout vertical="center">
				<Layout direction="vertical" gap={0} margin={MARGIN}>
					<Text truncate={250} bold>
						{env?.naam}
					</Text>
					<CurrentWeek dateArray={dates} />
				</Layout>
				{teamFilterElement}
			</Layout>

			<Layout>
				<SettingsButton modalLink={`/availability/${env.id}/settings`} />

				{!disabled && <InsertButton />}
			</Layout>
		</Layout>
	)
}

export default Header
