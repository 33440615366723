import {t} from 'initializers/i18n'

export const BasicCell = ({getValue}) => {
	return getValue() || null
}

export const BasicWithZeroCell = ({getValue}) => {
	const value = getValue()

	if (value || value === 0) return value
	return null
}
