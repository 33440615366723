import {Text, Layout} from 'common/components'
import {Fragment} from 'react'
import {useLocalValue} from 'hooks'
import {ColorCircle} from 'components/common/color_circle'
import {t as _t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

const STYLES = {
	title: {
		layout: {},
		text: {fontSize: 12, color: 'black', bold: true},
	},

	subtitle: {
		layout: {height: 14},
		text: {fontSize: 10, color: 'black', light: true},
	},

	greySubtitle: {
		layout: {height: 14},
		text: {fontSize: 12, color: 'grey', light: true},
	},

	item: {
		layout: {height: 14},
		text: {fontSize: 10, color: 'grey', light: true},
	},
}

export const WeekTableCellItem = ({
	text, // If you want full control and no label, e.g. when printing the name of an user
	value, // combines with a label (generated by t or localValue) and overwrites text
	t,
	type = 'item',
	RightContent,
	rightContentProps = {},
	Wrapper,
	wrapperProps,
	colorCircle,
	baseColor,
	namespace,
	localValue,
	textProps,
}) => {
	const show = useLocalValue(localValue)
	const style = STYLES[type] || STYLES['item']

	if (localValue && !show) return null

	if (utils.exists(value)) {
		// Instead of passing a completely parsed string, you may also just pass a value, for which a suffix translation key will be automatically generated.
		namespace = namespace ? namespace + '.' : ''
		const localValueKey = `${namespace}${_.snakeCase(localValue)}_abbreviated`
		const key = t || localValueKey
		value = `${value} ${_t(key)}`
	}

	colorCircle = colorCircle && <ColorCircle color={Colors[colorCircle]} size={10} />
	// TMP fixed: pass baseColor so that the second Layout child of cell has the correct color for coloring its children.... prefer to abstract
	// consider to do after Chris' current colorSet changes

	Wrapper = Wrapper || Fragment

	return (
		<Wrapper {...wrapperProps}>
			<Layout className="week-table-cell-item" {...style['layout']} maxWidth="100%" baseColor={baseColor}>
				{colorCircle}
				<Text {...style['text']} truncate {...textProps} baseColor={baseColor}>
					{value || text}
				</Text>
				{RightContent && <RightContent {...rightContentProps} />}
			</Layout>
		</Wrapper>
	)
}
