import {formatCssVars} from 'helpers'
import {IconAvatar, ImageAvatar, OpenShiftAvatar, UserAvatar, UserAvatarWithUserId} from './avatar_types'
import './styles/index.less'
import {Layout} from '../layout'

export const EitjeAvatar = props => {
	const {icon, type, user, userId, imageSrc, radius = 32} = props

	let Component = InnerAvatar
	if (type === 'open-shift') Component = OpenShiftAvatar
	if (icon) Component = IconAvatar
	if (user) Component = UserAvatar
	if (userId) Component = UserAvatarWithUserId
	if (imageSrc) Component = ImageAvatar

	return <Component radius={radius} {...props} />
}

export const InnerAvatar = ({radius, colorSet = 'color-bordered-outline', style = {}, ...rest}) => {
	const styles = {
		...formatCssVars({radius}),
		...style,
	}

	return <Layout className="eitje-avatar" colorSet={colorSet} horizontal="center" sizePx={radius} style={styles} {...rest} />
}
