import {useState, useRef, useEffect} from 'react'
import {useEnvSettingGroup} from 'hooks/use_env_setting'
import {date} from 'initializers/date'
import Form from 'initializers/form'
import {LegacyDropdownPicker as DropdownPicker} from '@eitje/form-fields-web'
import {t, mdT} from 'initializers/i18n'
import {useMergeState} from '@eitje/react-hooks'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import InputNumber from 'components/form/input_number'
import {saveSettingGroup} from 'actions/settings'
import {getNextCloseDate, getNextClosePeriod, getPeriodData, calcInitialDate} from 'cores/availability/helpers'
import '../styles/period_settings.less'

import {history} from 'index'
import {EitjeModal} from 'components/common'
import {EnvRouteSelect} from 'shared/components/env_route_select'

const closeKindOpts = ['daily', 'weekly', 'monthly', 'none']
const mirrorFields = ['close_kind', 'close_weekday', 'max_periods_future', 'min_periods_future', 'amt_periods', 'close_monthday']
const initialDateFields = ['close_kind', 'amt_periods', 'close_weekday', 'close_monthday']

const mapToDropDownTranslation = arr => arr.map(str => ({name: t(str), id: str}))
const translatedWeekObj = utils.weekdays.map((day, index) => ({name: t(day), id: index}))

const PeriodSettings = () => {
	const {envId} = useParams()
	const periodForm = useRef()
	const initialValues = useEnvSettingGroup('beschikbaarheid', envId)
	const [state, setState] = useMergeState(initialValues)
	const [initialDate, setInitialDate] = useState(initialValues.initial_date)

	const [periodDisabled, setPeriodDisabled] = useState()

	useEffect(() => {
		setState(initialValues)
	}, [envId])

	let {close_kind, max_periods_future, min_periods_future} = state
	if (close_kind == 'none') close_kind = 'daily'

	const {isDaily, isWeekly, isMonthly, transCloseKind, singularTransKind} = getPeriodData({...state, close_kind})
	const makeLabel = ({field}) => t(`periodSettings.${field}`, {kind: transCloseKind})

	const makeSuffix = ({value}) => t(singularTransKind, {count: Number(value)})

	const sharedProps = {label: makeLabel, suffix: makeSuffix}

	const _setInitialDate = (field, val, newFields) => {
		if (val == 'none') return disableAvPeriod()

		if (!initialDateFields.includes(field)) return
		const initDate = calcInitialDate(newFields)
		setInitialDate(initDate.format('YYYY-MM-DD'))
	}

	const disableAvPeriod = async () => {
		await setPeriodDisabled(true)
		await submitSettings({close_kind: 'daily', max_periods_future: null, min_periods_future: null})
		history.goBack()
	}

	const helperState = {...state, close_kind, initial_date: date(initialDate)}

	const submitSettings = data => {
		const mmt = date(initialDate)
		if (mmt._isValid) data['initial_date'] = mmt.format('YYYY-MM-DD')
		if (!data['max_periods_future']) data['max_periods_future'] = null

		return saveSettingGroup('beschikbaarheid', data, {env_id: envId})
	}

	const Header = props => (
		<EnvRouteSelect modal shouldTranslate={false} envId={envId} urlFormat="/availability/:envId/settings/period" {...props} />
	)

	return (
		<EitjeModal
			width={588}
			onSubmit={() => periodForm.current.submit()}
			title={t('availPeriodSettings')}
			name="availability-settings-period"
			RightHeader={Header}
			wrapClassName="period-settings-modal"
		>
			<div className="elementContainer">
				<p className="eitje-text-secondary margin-bottom-xss"> {t('avPeriod')} </p>
				<div className="modal-info-banner">
					<AvTextHelper {...helperState} />
				</div>
			</div>

			<Form
				ref={periodForm}
				onSubmit={submitSettings}
				hidePrompt={periodDisabled}
				setState={setState}
				afterChange={_setInitialDate}
				mirrorFields={mirrorFields}
				initialValues={initialValues}
				afterSubmit={() => history.goBack()}
				overrideInitialValues
			>
				<DropdownPicker bordered={false} items={mapToDropDownTranslation(closeKindOpts)} field="close_kind" noSort />

				{!isDaily && <InputNumber {...sharedProps} required validate={validate} extraLabel="amt_periods_sub" field="amt_periods" />}

				<InputNumber
					{...sharedProps}
					max={max_periods_future}
					validate={validate}
					required
					extraLabel={'min_periods_future_sub'}
					field="min_periods_future"
				/>

				{isWeekly && <DropdownPicker required bordered={false} noSort items={translatedWeekObj} field="close_weekday" />}
				{isMonthly && <InputNumber min={1} max={28} required field="close_monthday" extraLabel="close_monthday_future_sub" />}

				<InputNumber {...sharedProps} extraLabel="max_periods_future_sub" field="max_periods_future" />

				{!isDaily && (
					<InputNumber field="notify_days" extraLabel="notify_days_future_sub" suffix={({value}) => t('day', {count: Number(value)})} />
				)}

				{min_periods_future && <ClosedDates {...helperState} />}
			</Form>
		</EitjeModal>
	)
}

const largerThanZero = val => {
	return {valid: !utils.exists(val) || val > 0, message: 'higherThanZero'}
}

const validate = val => {
	const zeroValidated = largerThanZero(val)
	if (!zeroValidated.valid) return zeroValidated
	return wholeNumber(val)
}

const wholeNumber = val => {
	return {valid: val % 1 === 0, message: 'onlyWholeNumbers'}
}

export const AvTextHelper = props => {
	const {amt_periods, close_weekday, close_monthday, max_periods_future, min_periods_future} = props
	const {isDaily, isWeekly, isMonthly, singularTransKind: kind} = getPeriodData(props)
	const suffix = isDaily ? 'daily' : 'periodly'
	const weekday = utils.weekdays[close_weekday]

	return (
		<div className="modal-info-banner-text-container">
			<span> {mdT(`avCloses1_${suffix}`, {count: Number(amt_periods), kind})}. </span>
			{!!min_periods_future && <span> {mdT(`avCloses2_${suffix}`, {count: Number(min_periods_future), kind, weekday})} </span>}
			{(isMonthly || isWeekly) && (
				<span> {mdT(`avCloses2_1_${kind}`, {count: Number(min_periods_future), kind, weekday, monthday: close_monthday})}. </span>
			)}
			{!!max_periods_future && <span> {mdT(`avCloses3_${suffix}`, {count: Number(max_periods_future), kind})}. </span>}
		</div>
	)
}

const ClosedDates = props => {
	const nextCloseDate = getNextCloseDate(props)
	const {startDate, endDate} = getNextClosePeriod(props)
	if (!nextCloseDate) return null
	// this isn't correct yet, we need to implement nextCloseDate properly
	return (
		<div className="elementContainer">
			<div className="av-settings-banner">
				<DateBlock date={nextCloseDate} text="openDate" />
				<DateBlock date={startDate} text="closeDate" />
				{endDate && <DateBlock date={endDate} text="upUntil" />}
			</div>
		</div>
	)
}

const DateBlock = ({date, text}) => {
	return (
		<div className="av-settings-date-block">
			<div className="av-settings-date-block-header">{t(text)}</div>
			<p className="eitje-text-secondary"> {date.format('dddd')} </p>
			<p className="bold"> {date.format('DD-MM-YYYY')} </p>
		</div>
	)
}

export default PeriodSettings
