import {Checkbox} from '@eitje/web_components'
import {getSelectedRows} from 'common/components/advanced_table'
import {Layout, Text} from 'common/components'
import {HeaderTitle} from '.'

export const HeaderWithCheckbox = ({Element, elementProps = {}, aggregates, tooltipProps, ...rest}) => {
	if (!Element) {
		elementProps = {value: rest.header.id, context: rest.header.getContext(), ...rest}
		Element = HeaderTitle
	}

	return (
		<Layout vertical="center">
			<HeaderCheckbox table={rest.table} />
			<Layout direction="vertical" gap={0}>
				<Element {...rest} {...elementProps} tooltipProps={tooltipProps} />
				<Text semiBold fontSize={8}>
					{aggregates}
				</Text>
			</Layout>
		</Layout>
	)
}

const HeaderCheckbox = ({table}) => {
	const toggleHandler = table.getToggleAllRowsSelectedHandler()
	const allSelected = table.getIsAllRowsSelected()
	const selectedRows = getSelectedRows(table)
	const indeterminate = selectedRows?.length && !allSelected
	return (
		<Checkbox
			checked={allSelected}
			indeterminate={indeterminate}
			onClick={e => {
				e.stopPropagation()
				toggleHandler(e)
			}}
		/>
	)
}
