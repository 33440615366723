import {EitjeDropdown} from 'common/components'
import {useLockOptions} from './lock_options'
import {useEditShifts} from './edit_shifts'
import SimpleContextWrapper, {useSimpleContext} from 'contexts/simple'
import {useLoadTemplate, useSaveTemplate} from './template'
import {useState} from 'react'
import {NAMESPACE} from 'cores/planning/pages/index/week_table_days_header'
import {sharedViewOptions} from 'common/components/week_table'

export const DayDropdown = props => {
	const [envsStatus, setEnvsStatus] = useState({})
	const contextValues = {envsStatus, setEnvsStatus}

	return (
		<SimpleContextWrapper {...contextValues}>
			<Inner {...props} />
		</SimpleContextWrapper>
	)
}

const Inner = ({date, ...rest}) => {
	const elements = [...useLockOptions(date), useEditShifts(date), useLoadTemplate(date), useSaveTemplate(date), useViewOptions()]
	return <EitjeDropdown elements={elements} {...rest} trigger="click" />
}

export const PanelDropdown = ({...rest}) => {
	const elements = [useEditShifts(), useLoadTemplate(), useSaveTemplate(), useViewOptions()]
	return <EitjeDropdown elements={elements} {...rest} trigger="click" />
}

function useViewOptions() {
	const options = ['planningShowWeather', 'planningShowAvLock', 'planningShowPlannedTimes']
	return sharedViewOptions(options, NAMESPACE)
}
