import utils from '@eitje/web_utils'
import {Button} from 'antd'
import {t} from 'initializers/i18n'

const copy = async text => {
	await navigator.clipboard.writeText(text)
	utils.success(t('copied_to_clip'))
}

export const UrlCopy = ({url}) => {
	return (
		<div className="fRow aCen jCen urlCopy" onClick={() => copy(url)}>
			<div className="linkUrl fRow aCen">
				<p className="noCapital eitje-text-secondary line-height-1">{url}</p>
			</div>

			<div>
				<Button className="copyBtn">copy</Button>
			</div>
		</div>
	)
}
