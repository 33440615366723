import {EitjeModal} from 'components/common'
import {useMemo, useEffect} from 'react'
import {t} from 'cores/integrations/index'
import {store} from 'index'
import {loketClientId, loketUrl} from 'initializers/api'
import utils from '@eitje/web_utils'

const redirectUri = `${window.location.origin}/oauth/loket`

const useLoketRedirect = () => {
	const state = useMemo(() => utils.randomId(), [])
	useEffect(() => {
		store.dispatch({type: 'SET_VALUE', name: 'loketState', payload: state})
	}, [])
	const base = `${loketUrl}?client_id=${loketClientId}&redirect_uri=${redirectUri}`
	const extra = `&response_type=code&scope=all&state=${state}`

	setTimeout(() => window.location.replace(`${base}${extra}`), 700)
}

export const ActivateLoket = () => {
	useLoketRedirect()
	return (
		<EitjeModal name="loket" hideHeader hideFooter className="integration-redirect-modal">
			<img className="integration-redirect-image" src="/images/integrations/loket.png" />
			<h3 className="integration-redirect-title"> {t('oauth_redirect.title')} </h3>
			<h5 className="integration-redirect-text"> {t('oauth_redirect.subtitle')} </h5>
		</EitjeModal>
	)
}

export default ActivateLoket
