import {CellTaxonomy, BasicCell} from 'common/components/advanced_table'
import utils from '@eitje/web_utils'
import {flexRender} from '@tanstack/react-table'
import {t} from 'initializers/i18n'
import {isFunction} from 'helpers'
///////////
// Get cell components through a) lookup with string name, b) from tax as explicit component or c) the fallback compoent
///////////

export const stringToCell = cell => {
	if (_.isString(cell)) return CellTaxonomy[cell]
	if (isFunction(cell)) return cell
	return null
}

export const getCell = column => {
	const {cell} = column.columnDef || {}
	return stringToCell(cell) || BasicCell || null
}

///////////
// Render functions
///////////

export const renderCell = cell => {
	// Generically fallback on `null` instead of values such as `undefined` to prevent React crashes.
	const {column, getValue, getContext} = cell
	const {columnDef} = column
	const {customPlaceholder, cell: cellName, id: columnName} = columnDef
	const translatedPlaceholder = getTranslatedPlaceholder(cell)

	// Usually we want falsy values to simply be empty, but not when it is a 'yes' or 'no' kind of situation.
	// Printing 'no' is more consistent and much clearer when grouping on a boolean column.
	const showFalsyValue = cellName === 'BooleanCell'

	const value = getValue() || null
	const noValue = !utils.exists(value) && !translatedPlaceholder && !customPlaceholder && !showFalsyValue
	if (noValue) return null

	const renderProps = {...getContext(), translatedPlaceholder}
	return flexRender(getCell(column), renderProps)
}

export const getTranslatedPlaceholder = cell => {
	let {translatedPlaceholder} = cell.column.columnDef
	if (!translatedPlaceholder) return null
	return t(translatedPlaceholder)
}
