import {date} from 'initializers/date'
import {t, mdT} from 'initializers/i18n'
import {useFind} from '@eitje/easy_api'
import {PlanningShift} from 'models/index'
import {AmtWorked} from 'cores/planning/index'
import './styles/approve_swap.less'
import ShiftFetcher from './shift_fetcher'
import {EitjeButton, DeleteButton} from 'common/components'
import {EitjeAvatar} from 'common/components'

const ApproveSwap = ({item, shift, afterSucc, hideAmtWorked}) => {
	const {bieder_map, ruiler_id} = item
	const dateTime = Object.values(bieder_map)[0]

	const asker = useFind('users', ruiler_id)
	const swapShift = PlanningShift.find(item.swap_shift_id)
	const receiver = useFind('users', swapShift.user_id)

	if (!swapShift.id) return <ShiftFetcher id={item.swap_shift_id} />
	const sameWeek = date(shift.date).isoWeek() == date(swapShift.date).isoWeek()
	const _hideAmtWorked = hideAmtWorked || !sameWeek
	return (
		<div onClick={e => e.stopPropagation()} className="approve-trade">
			<div className="approve-trade-header">
				<h4>{t('common.trade_request')} </h4>
				{dateTime && <h6 className="approve-trade-header-info">{t('planning.trade.accepted_on', {name: receiver.full_name, dateTime})}</h6>}
				{!dateTime && <h6 className="approve-trade-header-info">{t('planning.trade.unanswered')}</h6>}
			</div>
			<div className="approve-trade-body">
				<Asker hideAmtWorked={_hideAmtWorked} shift={shift} user={asker} />
				<Receiver hideAmtWorked={_hideAmtWorked} date={shift.date} shift={swapShift} user={receiver} />
				<div className="approve-trade-buttons">
					<DeleteButton t="planning.trade.delete_trade" height="small" colorSet="solid" onClick={() => item.destroy()} />
					{dateTime && (
						<EitjeButton onClick={() => item.approve(receiver.id, afterSucc)} height="small" iconLeft="checkbox-checked" colorSet="solid">
							{t('planning.trade.approve_trade')}
						</EitjeButton>
					)}
				</div>
			</div>
		</div>
	)
}

const ShiftDate = ({shift}) => (
	<h6 className="approve-trade-shift-date">
		{t('planning.trade.shift_date', {date: date(shift.date).format('DD MMMM YYYY'), from: shift.startTime(), till: shift.endTime()})}
	</h6>
)

const Asker = ({shift, hideAmtWorked, user}) => {
	return (
		<div className="approve-trade-deal-member approve-trade-asker">
			<EitjeAvatar user={user} />
			<div className="approve-trade-deal-member-info">
				<h5 className="approve-trade-deal-member-name">
					<b>{user.full_name}</b> {t(`planning.trade.wants_to_trade`)}
				</h5>
				<ShiftDate shift={shift} />
				{!hideAmtWorked && <AmtWorked user={user} date={shift.date} />}
			</div>
		</div>
	)
}

const Receiver = ({shift, hideAmtWorked, user, date}) => {
	return (
		<div className="approve-trade-deal-member approve-trade-receiver">
			<div className="approve-trade-deal-member-info">
				<h5 className="approve-trade-deal-member-name">{mdT(`planning.trade.offered_to`, {name: user.full_name})}</h5>
				<ShiftDate shift={shift} />
				{!hideAmtWorked && <AmtWorked user={user} date={date} />}
			</div>
			<EitjeAvatar user={user} />
		</div>
	)
}

export default ApproveSwap
