import {t} from 'initializers/i18n'

export const randomTip = () => tipArray[Math.floor(Math.random() * tipArray.length)]
export const tipArray = [
	{
		title: t('tip1'),
		linkNL: 'articles/6021818-handige-tips-voor-omzetregistratie',
		linkEN: 'articles/6021818-handige-tips-voor-omzetregistratie',
	},

	{
		title: t('tip2'),
		linkNL: 'articles/6010068-chat-berichten',
		linkEN: 'articles/6010068-chat-berichten',
	},

	{
		title: t('tip3'),
		linkNL: 'articles/6021852-beschikbaarheid#h_abd9235c78',
		linkEN: 'articles/6021852-beschikbaarheid#h_abd9235c78',
	},

	{
		title: t('tip4'),
		linkNL: 'collections/3364552-eitjeopdevloer',
		linkEN: 'collections/3364552-eitjeopdevloer',
	},

	{
		title: t('tip5'),
		linkNL: 'articles/6015145-aanmaken-van-functies-en-vaardigheden',
		linkEN: 'articles/6015145-aanmaken-van-functies-en-vaardigheden',
	},

	{
		title: t('tip6'),
		linkNL: 'articles/6028425-open-shifts-publiceren',
		linkEN: 'articles/6028425-open-shifts-publiceren',
	},

	{
		title: t('tip7'),
		linkNL: 'articles/6021818-handige-tips-voor-omzetregistratie',
		linkEN: 'articles/6021818-handige-tips-voor-omzetregistratie',
	},

	{
		title: t('tip8'),
		linkNL: 'articles/6028446-video-s-toevoegen-aan-een-artikel',
		linkEN: 'articles/6028446-video-s-toevoegen-aan-een-artikel',
	},

	{
		title: t('tip9'),
		linkNL: 'collections/3355125-data-exporteren',
		linkEN: 'collections/3355125-data-exporteren',
	},

	{
		title: t('tip10'),
		linkNL: 'articles/6021976-financieel',
		linkEN: 'articles/6021976-financieel',
	},

	{
		title: t('tip11'),
		linkNL: 'articles/6020103-toeslagen-op-shifts',
		linkEN: 'articles/6020103-toeslagen-op-shifts',
	},

	{
		title: t('tip12'),
		linkNL: '/',
		linkEN: '/',
	},
]
