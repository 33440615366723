import React, {useEffect, useContext, useState, Fragment, useRef} from 'react'
import {useWhere, useFind, useAll} from '@eitje/easy_api'
import {useAvContext} from 'contexts/availability'
import UserCard from './user_card/index'
import UserDay from './user_day/index'
import AvailabilityFormWrapper, {useAvFormContext} from 'cores/availability/components/form/availability_form_provider'
import UserContextWrapper, {useUserContext as useBaseUserContext} from 'contexts/user'
import utils from '@eitje/utils'
import '../../styles/availability.less'
import './index.less'

const UserAvContext = React.createContext()
const {Provider} = UserAvContext

const DaysRow = ({user}) => {
	const {dates} = useAvContext()
	const {selectedDates = []} = useAvFormContext()
	const rowClassName = utils.makeCns(
		'users-availability-table-row',
		selectedDates.length > 0 && 'active-within',
		`users-availability-table-row-${user.id}`,
	)

	return (
		<div className={rowClassName}>
			<UserCard />
			{dates.map(d => (
				<UserDay date={d} />
			))}
		</div>
	)
}

const UserRow = React.memo(({user}) => {
	const {dateRange} = useAvContext()
	const userAvShifts = useWhere('availabilityShifts', {date: dateRange, user_id: user.id})
	const context = {user, userAvShifts}
	return (
		<UserContextWrapper user={user}>
			<Provider value={context}>
				<AvailabilityFormWrapper>
					<DaysRow user={user} />
				</AvailabilityFormWrapper>
			</Provider>
		</UserContextWrapper>
	)
})

export const useUserContext = () => {
	const avContext = useContext(UserAvContext)
	const userContext = useBaseUserContext()
	return {...avContext, ...userContext}
}

export default UserRow
