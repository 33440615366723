import {t} from 'initializers/i18n'
import {RouteSelect as _RouteSelect} from 'components/routing'

export const RouteSelect = props => {
	const items = [
		{label: t('header.planning_per_team'), value: '/planning/per_team'},
		{label: t('header.planning_per_member'), value: '/planning/per_member'},
		{label: t('header.planning_table'), value: '/planning_table'},
	]

	return <_RouteSelect {...props} items={items} shouldTranslate={false} trigger="click" noSort />
}
