import {ContractHolder} from 'models'
import {ftime} from 'helpers'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import {useActiveUsers} from 'hooks'

const CONTRACT_NOTIFS = [30, 7, 0]

export function useContracts({extraProps}) {
	return CONTRACT_NOTIFS.map(days => baseUseContracts({days, extraProps}))
}

function baseUseContracts({days, extraProps}) {
	const startOfToday = date().startOf('day')
	const _date = startOfToday.clone().add(days, 'days')
	const today = date()
	const activeUserIds = useActiveUsers().ids()
	const futureWarning = days !== 0

	let contracts = ContractHolder.where({user_id: activeUserIds}).groupBy(c => c.user_id)

	// Only show info for users' last contract
	contracts = _.map(contracts, (userContracts, userId) => {
		if (userContracts.some(c => !c.end_date)) return // skip users with indefinite contracts
		return userContracts.sortBy(c => c.endDate()).last()
	})

	contracts = contracts
		.filter(Boolean) // filter out indefinite contracts
		.filter(c => {
			const end = c.endDate()
			const endsInFuture = end.isSameOrAfter(today)
			const withinRange = end.isSameOrBefore(_date)
			const alreadyEnded = end.isSameOrBefore(today)
			return (alreadyEnded && !futureWarning) || (endsInFuture && withinRange)
		})
		.sort(c => c.endDate())
		.reverse()

	contracts = contracts.map(c => {
		const todaysWarning = c.endDate().isSame(today)
		const endingContract = `${t('inbox.the_contract_of')} ${c.user.full_name}`
		const willEndAt = `${t('inbox.contract_ends_on')} ${ftime(c.endDate(), 'dayMonth')}`
		const hasEnded = `${t('inbox.contract_ended')}`
		const endsToday = `${t('inbox.contract_ended_today')}`
		const dateSuffix = futureWarning ? willEndAt : todaysWarning ? endsToday : hasEnded
		const label = `${endingContract} ${dateSuffix}`
		const endDate = c.endDate()
		const to = `/user/${c.user_id}/contracts`
		let createdAt

		if (!futureWarning) {
			createdAt = endDate.isBefore(startOfToday) ? endDate : startOfToday
		} else {
			createdAt = endDate.subtract(days, 'days')
		}

		return {
			label,
			createdAt,
			to,
			...extraProps,
		}
	})

	return contracts
}
