import {all} from '@eitje/easy_api'
import {Button, Dropdown as AntDropdown, Input, Menu} from 'antd'
import {store} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Component, createRef, Fragment, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {legacyOrgRolesSelector} from 'selectors/legacy'
import {currentEnvSelector, currentOrgSelector, inEnv, inOrg, orgEnvsSelector} from 'selectors/records'
import * as jsUtils from 'utils/jsutils'
import 'styles/legacy/ant_overwrite.scoped.css'
import utils from '@eitje/web_utils'

export class Dropdown extends Component {
	state = {
		visible: false,
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	setVisible = visible => {
		this.setState({visible: visible})
	}

	handleClickOutside = e => {
		const node = document.querySelector(`.${this.props.menuKlass}`)
		if (!node || node.contains(e.target)) {
			// inside click
			return
		}
		// outside click
		this.setState({visible: false})
	}

	render() {
		const {visible} = this.state
		const {disabled, children} = this.props
		return (
			<div style={{pointerEvents: disabled ? 'none' : 'default'}} className="dropdown">
				<AntDropdown {...this.props} visible={visible}>
					<div onClick={() => this.setState({visible: true})}>{children}</div>
				</AntDropdown>
			</div>
		)
	}
}

export const MenuSelect = ({
	options,
	optRender,
	disabled,
	after = () => null,
	showSearch = true,
	clearFilter,
	actives = [],
	onClick = () => {},
	dividerItems,
	dividerKey,
	displayField = 'naam',
	keyField = 'id',
	...other
}) => {
	const [search, setSearch] = useState()
	const opts = utils.search(options, search, {field: displayField})
	if (dividerKey && dividerItems.length > 1) {
		const groupedOpts = _.groupBy(opts, dividerKey)
		const keys = Object.keys(groupedOpts)
		return (
			<Menu className="noselect" style={{width: 'inherit'}} onClick={onClick} {...other} prefixCls="ant-menu" style={{maxWidth: 309}}>
				{showSearch && (
					<Fragment>
						<Input
							className="borderZeroRadius borderOnlyLeftRadius"
							placeholder={t('searchInList')}
							style={{width: 'inherit'}}
							onChange={e => setSearch(e.target.value)}
						/>
						{clearFilter && (
							<Button
								className="borderZeroRadius borderOnlyRightRadius"
								style={{marginLeft: -1, float: 'right'}}
								onClick={() => clearFilter()}
							>
								{t('clearFilters')}
							</Button>
						)}
					</Fragment>
				)}
				{keys.map(key => {
					const item = dividerItems.find(i => i.id === jsUtils.tryToInt(key))
					if (!item) return null
					return (
						<Menu.ItemGroup className="menu-group-class" title={item.naam}>
							{groupedOpts[key].map(opt => {
								const isActive = actives.includes(opt[keyField])
								return (
									<Menu.Item
										disabled={disabled}
										className="labelHoverDropDown"
										style={{backgroundColor: isActive ? '#fafafa' : 'white'}}
										key={opt[keyField]}
									>
										<p style={{color: isActive ? '#2b2b2b' : '#828282', fontWeight: isActive ? 600 : ''}} className="prevent-text-overflow">
											{' '}
											{optRender ? optRender(opt[displayField], opt) : opt[displayField]}{' '}
										</p>
									</Menu.Item>
								)
							})}
						</Menu.ItemGroup>
					)
				})}
			</Menu>
		)
	} else {
		return (
			<Menu className="noselect" style={{width: 'inherit'}} onClick={onClick} {...other}>
				{showSearch && (
					<Fragment>
						<Input
							className="borderZeroRadius borderOnlyLeftRadius"
							placeholder={t('searchInList')}
							style={{width: 'inherit'}}
							onChange={e => setSearch(e.target.value)}
						/>
						{clearFilter && (
							<Button
								className="borderZeroRadius borderOnlyRightRadius button-delete-filters"
								style={{marginLeft: -1, float: 'right'}}
								onClick={() => clearFilter()}
							>
								{t('clearFilters')}
							</Button>
						)}
					</Fragment>
				)}
				{opts.map(opt => {
					const isActive = actives.includes(opt[keyField])
					return (
						<Menu.Item
							disabled={disabled}
							className="labelHoverDropDown"
							style={{backgroundColor: isActive ? '#fafafa' : 'white'}}
							key={opt[keyField]}
						>
							<p style={{color: isActive ? '#2b2b2b' : '#828282', fontWeight: isActive ? 600 : ''}}>
								{' '}
								{optRender ? optRender(opt[displayField], opt) : opt[displayField]}{' '}
							</p>
						</Menu.Item>
					)
				})}
				{after()}
			</Menu>
		)
	}
}

let TeamDD = ({activeTeams, onClick, filterFunc = () => true, role = null, teams, planningActive, envOnly, withLocked = false}) => {
	const {t} = useTranslation()
	const ref = createRef()
	let realTeams = withLocked ? teams : teams.filter(t => !t.locked)
	const realTeamIds = realTeams.map(t => t.id)
	if (planningActive) realTeams = realTeams.filter(t => !t.onroosterbaar)
	let actTeams = activeTeams.map(e => realTeams.find(o => o.id == e)).filter(Boolean)

	activeTeams = activeTeams.filter(t => realTeamIds.includes(t))
	const allSelected = activeTeams.length === realTeams.length

	return (
		<div style={{backgroundColor: activeTeams.length > 0 && !allSelected && '#E6F7FF'}} className="eitjeDrop pointer">
			<Dropdown
				ref={ref}
				menuKlass="teamMenuSelect"
				overlay={
					<TeamMenuSelect onC={onClick} activeTeams={activeTeams} teams={realTeams} role={role} withLocked={withLocked} envOnly={envOnly} />
				}
			>
				<div className="row full-width">
					<p
						className="flex-grow-one"
						style={{color: allSelected ? '#000000a6' : '#2b2b2b', fontWeight: allSelected ? 400 : activeTeams.length === 0 ? 400 : 600}}
						onClick={() => ref.current.setVisible(true)}
					>
						{allSelected || activeTeams.length == 0
							? t('allTeams')
							: activeTeams.length === 1
							? actTeams[0].naam
							: `${activeTeams.length} ${t('team_plural', {count: activeTeams.length})}`}
					</p>
					<img width={12} height={12} className="rounded-row-dropdown-icon" src={'/images/web/icons/downArrow.png'} />
				</div>
			</Dropdown>
		</div>
	)
}

export const TeamDropdown = connect((state, ownProps) => ({
	teams: ownProps.teams ? ownProps.teams : ownProps.envOnly ? inEnv(state, 'teams') : inOrg(state, 'teams'),
	activeTeams: ownProps.activeTeams ? ownProps.activeTeams : state.entities.activeTeams,
}))(TeamDD)

let TMenuSelect = ({
	teams,
	onC = ({key}) => store.dispatch({type: 'ACTIVE_TEAM_TOGGLE', teamId: Number(key)}),
	envs,
	role,
	orgRoles,
	actOrg,
	activeEnvs,
	activeTeams,
	envOnly,
	withLocked = false,
	...other
}) => {
	let environments = envs
	let corrTeams = withLocked ? teams : teams.filter(t => !t.locked)
	if (role) {
		environments = envs.filter(e => orgRoles[e.id] && orgRoles[e.id][role])
	}
	return (
		<MenuSelect
			dividerItems={environments}
			dividerKey={actOrg && !envOnly ? 'environment_id' : null}
			className="teamMenuSelect noselect"
			actives={activeTeams}
			options={_.sortBy(
				_.sortBy(
					corrTeams.filter(t => activeEnvs.length == 0 || !actOrg || envOnly || activeEnvs.includes(t.environment_id)),
					te => -te.rooster_order,
				),
				'environment_id',
			)}
			onClick={onC}
			clearFilter={() => store.dispatch({type: 'RESET_TEAM_FILTER'})}
		/>
	)
}

export const TeamMenuSelect = connect((state, ownProps) => ({
	activeTeams: ownProps.activeTeams ? ownProps.activeTeams : state.entities.activeTeams,
	activeEnvs: state.entities.activeEnvs,
	envs: all(state, 'environments'),
	actOrg: currentOrgSelector(state)?.id,
	orgRoles: legacyOrgRolesSelector(state),
}))(TMenuSelect)

let EnvDD = ({activeEnvs, orgEnvs, orgRoles, actOrg, role = null}) => {
	const {t} = useTranslation()
	const ref = createRef()
	if (actOrg && orgEnvs.length > 1) {
		const actEnvs = activeEnvs.map(e => orgEnvs.find(o => o.id == e))
		let allowedEnvs = orgEnvs
		if (role) {
			allowedEnvs = orgEnvs.filter(e => orgRoles[e.id] && orgRoles[e.id][role])
		}
		const allSelected = activeEnvs.length === orgEnvs.length || activeEnvs.length === 0
		return (
			<div style={{backgroundColor: !allSelected && '#E6F7FF'}} className="eitjeDrop pointer">
				<Dropdown ref={ref} menuKlass="envMenuSelect" overlay={<EnvMenuSelect orgEnvs={allowedEnvs} />}>
					<div className="row full-width">
						<p
							style={{
								color: activeEnvs.length === allowedEnvs.length ? '#000000a6' : '#2b2b2b',
								fontWeight: activeEnvs.length === allowedEnvs.length ? 400 : activeEnvs.length === 0 ? 400 : 600,
							}}
							onClick={() => ref.current.setVisible(true)}
						>
							{actEnvs.length >= allowedEnvs.length || actEnvs.length == 0
								? t('allEnvs')
								: activeEnvs.length === 1
								? actEnvs[0].naam
								: `${activeEnvs.length} ${t('env_plural', {count: activeEnvs.length})}`}
						</p>
						<img width={12} height={12} className="rounded-row-dropdown-icon" src={'/images/web/icons/downArrow.png'} />
					</div>
				</Dropdown>
			</div>
		)
	} else {
		return null
	}
}

export const EnvDropdown = connect((state, ownProps) => ({
	orgEnvs: orgEnvsSelector(state),
	activeEnvs: state.entities.activeEnvs,
	actOrg: currentOrgSelector(state),
	orgRoles: legacyOrgRolesSelector(state),
}))(EnvDD)

const UserMenuSelect = ({actives, options, clearFilter, klass, onClick, ...other}) => (
	<MenuSelect
		className="userMenuSelect noselect"
		actives={actives}
		options={options}
		onClick={onClick}
		clearFilter={clearFilter}
		displayField="full_name"
	/>
)

let EMenuSelect = ({orgEnvs, activeEnvs, ...other}) => {
	return (
		<MenuSelect
			className="envMenuSelect noselect"
			actives={activeEnvs}
			options={orgEnvs}
			clearFilter={() => store.dispatch({type: 'RESET_ENV_FILTER'})}
			onClick={({key}) => store.dispatch({type: 'ACTIVE_ENV_TOGGLE', id: Number(key)})}
		/>
	)
}

export const EnvMenuSelect = connect(state => ({
	activeEnvs: state.entities.activeEnvs,
}))(EMenuSelect)

const UserDD = ({actives, options, environment, withOnroosterbaar = false, roles, ...other}) => {
	const {t} = useTranslation()
	const ref = createRef()
	let opts = options
	if (!withOnroosterbaar && environment) {
		opts = options.filter(u => !environment.onroosterbaar_user_ids.includes(u.id))
	}
	const activeUsers = actives.map(i => options.find(u => u.id == i))
	const allSelected = actives.length === 0 || actives.length === options.length
	return (
		<div style={{backgroundColor: !allSelected && '#E6F7FF'}} className="eitjeDrop pointer">
			<Dropdown ref={ref} menuKlass="userMenuSelect" overlay={<UserMenuSelect {...other} actives={actives} options={opts} />}>
				<div className="row full-width">
					<p
						style={{
							color: actives.length === opts.length ? '#000000a6' : '#2b2b2b',
							fontWeight: actives.length === opts.length ? 400 : actives.length === 0 ? 400 : 600,
						}}
						onClick={() => ref.current.setVisible(true)}
					>
						{actives.length === opts.length || actives.length == 0
							? t('all_users')
							: actives.length === 1
							? activeUsers[0].full_name
							: `${actives.length} ${t('users', {count: actives.length})}`}
					</p>
					<img width={12} height={12} className="rounded-row-dropdown-icon" src={'/images/web/icons/downArrow.png'} />
				</div>
			</Dropdown>
		</div>
	)
}

export const UserDropdown = connect(state => ({
	environment: currentEnvSelector(state),
}))(UserDD)
