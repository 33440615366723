import FixedRangePicker from 'components/date/fixed_range_picker'
import RangePicker from 'components/date/range_picker'
import {WagePeriodPicker} from '.'

export const PeriodPicker = ({pageContext, ...rest}) => {
	const {startDate, diff, endDate, period, setRawDates} = pageContext()

	if (period == 'custom')
		return (
			<RangePicker
				maxDays={60}
				allowClear={false}
				value={[startDate, endDate]}
				onChange={dates => setRawDates(...dates, 'custom')}
				{...rest}
				height="small"
			/>
		)

	if (period.includes('wage')) {
		return <WagePeriodPicker pageContext={pageContext} {...rest} />
	}

	return (
		<FixedRangePicker
			initialLeading
			onChange={dates => setRawDates(dates[0], dates[dates.length - 1])}
			isMonthPicker={period == 'month'}
			initialValue={startDate}
			length={diff + 1}
			{...rest}
			height="small"
		/>
	)
}
