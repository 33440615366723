import {useClassNameObserver} from 'hooks/use_classname_observer'
import {NavItemLayout} from './nav_item'
import {t} from 'initializers/i18n'
import {setupCanny} from 'lib/canny'
import {useEffect, useState} from 'react'

export const ProductUpdates = () => {
	const [show, setShow] = useState()
	const hasCannyBadge = useClassNameObserver('Canny_Badge')
	const style = {visibility: show ? 'visible' : 'hidden'}

	// To ensure show never goes back to false once the updates were viewed
	useEffect(() => {
		if (hasCannyBadge) setShow(true)
	}, [hasCannyBadge])

	// We call the setup function here so we can be sure the data-canny-changelog
	// attribute is in the DOM once the fn is run
	useEffect(() => {
		setupCanny(true)
	}, [])

	return <NavItemLayout title={t('header.product_updates')} layoutProps={{'data-canny-changelog': 'true', style}} />
}
