import {isDev} from 'constants/general'

if (isDev) {
	const ignoreErrors = [
		/Warning: Invalid prop `.*` supplied to `React.Fragment`./,
		/(Uncaught \(in promise\) Error|Unhandled Rejection \(Error\)): {"code":5,"StatusCode":401,"message":"WS failed with code 5 and reason - Connect failed with error: "Token signature is invalid","isWSFailure":false}/,
	]

	const consoleError = console.error
	console.error = function (message, ...args) {
		for (let i = 0; i < ignoreErrors.length; i++) {
			if (ignoreErrors[i].test(message)) {
				return
			}
		}

		consoleError.apply(console, [message, ...args])
	}
}
