import {Button} from 'antd'
import Modal from 'components/general/modal'
import {t} from 'initializers/i18n'
import React, {useRef} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import ExportForm from './form'
import useExport from './use_export'
import './styles/export_hours.less'
import {DownloadLink} from 'common/components'

const ModalPage = () => {
	const loc = useLocation()
	const {state = {}} = loc
	const {id} = useParams()

	const {title: innerTitle, subtitle, modalTitle, downloadLink, imgSrc, opts} = useExport(id)

	const form = useRef(null)

	return (
		<Modal size={'lg'} onOk={() => form.current.submit()} footerBackButton title={modalTitle}>
			<div className="export-preview">
				<img src={imgSrc} className="export-preview-image" />

				<div className="export-header-container">
					<p className="export-header-title">{innerTitle}</p>
					<p className="export-header-subtitle">{subtitle}</p>

					{downloadLink && (
						<div className="export-preview-link">
							<DownloadLink url={downloadLink}> {t('viewPreviewFile')} </DownloadLink>
							{false && <Button> {t('automateExport')} </Button>}
						</div>
					)}
				</div>
			</div>

			<ExportForm initialValues={state} name={id} formRef={form} opts={opts} />
		</Modal>
	)
}

export default ModalPage
