import {PrivateRoute} from 'components/private_route'

import Audits from 'cores/availability/pages/audits'
import Settings from 'cores/availability/pages/settings'
import PeriodSettings from 'cores/availability/pages/period_settings'
import TeamDays from 'cores/availability/pages/team_days/index'

const avForegroundRoutes = []

const avBackgroundRoutes = [
	<PrivateRoute path="/availability/:envId/audits" component={Audits} exact />,
	<PrivateRoute path="/availability/:envId/settings" component={Settings} exact />,
	<PrivateRoute path="/availability/:envId/settings/period" component={PeriodSettings} exact />,
	<PrivateRoute path="/availability/:envId/team_days" component={TeamDays} exact />,
]

export {avForegroundRoutes, avBackgroundRoutes}
