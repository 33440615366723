import {persist, store, history} from 'index'
import * as jsUtils from 'utils/jsutils'
import {backend} from '@eitje/easy_api'

export async function getTaxonomy() {
	const res = await backend.get('taxonomy')
	if (res.ok) {
		store.dispatch({type: 'TAXONOMY', payload: res.data})
	}
	return res
}
