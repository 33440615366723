import {Input, Checkbox} from '@eitje/form-fields-web'
import {FormRow} from 'components/ui'
import {DeleteButton as DeleteButtonComponent} from 'common/components'
import {useFormData} from 'hooks'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import useHover from 'hooks/use_hover'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {useForm} from '@eitje/form'

export const AllowanceInput = ({number}) => {
	const formData = useFormData()
	const {setValues, submit} = useForm()
	const {hoverActions, isHovering} = useHover()
	const [deleteBtnHovering, setDeleteBtnHovering] = useState()
	const isActive = formData[`active-${number}`]
	const inputClassNames = utils.makeCns('inputs-list-wrapper', isActive && 'inputs-list-wrapper-active')

	const handleDelete = async () => {
		await setValues({
			[`name-${number}`]: undefined,
			[`active-${number}`]: undefined,
		})
		submit()
	}

	const DeleteButton = (
		<DeleteButtonComponent height="small" onDel={handleDelete} onVisibleChange={isVisible => setDeleteBtnHovering(isVisible)} />
	)

	return (
		<FormRow>
			<div className={inputClassNames} {...hoverActions}>
				<Input
					field={`name-${number}`}
					placeholder={t('mealAllowance')}
					bordered={false}
					suffix={(isHovering || deleteBtnHovering) && DeleteButton}
					hideClearIcon
					labelVisible={false}
				/>
			</div>
			<Checkbox field={`active-${number}`} label="" />
		</FormRow>
	)
}

export const MealAllowanceInputsWrapper = forwardRef(({envId}, ref) => {
	const {resetValues, setValues, submit} = useForm()
	const formData = useFormData()
	const nameKeys = Object.keys(formData).filter(field => field.includes('name'))
	const mealAmount = nameKeys.length

	useEffect(() => {
		resetValues()
	}, [envId])

	const addNewAllowance = async name => {
		await setValues({[`name-${mealAmount + 1}`]: name, [`active-${mealAmount + 1}`]: true})
		submit()
	}

	useImperativeHandle(ref, () => ({
		addNewAllowanceFromParent(name) {
			addNewAllowance(name)
		},
	}))

	const activeFields = nameKeys
		.filter(nameKey => !!formData[nameKey])
		.map(nameKey => <AllowanceInput key={nameKey} number={nameKey.split('-')[1]} />)

	return <div>{activeFields}</div>
})
