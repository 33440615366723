import {useRef, useEffect} from 'react'
import {tableTypes, useTablesContext} from 'cores/exports2/index'
import {t} from 'cores/exports2/pages/tables/index/index'
import {useList, useOnScreen} from 'hooks/index'
import utils from '@eitje/web_utils'
import {TableCard} from './table_card'
import './styles/index.less'
import {DocsButton} from 'common/components'
import {CreateButton} from 'common/components/eitje_button/eitje_buttons'

const typeData = {
	normal: {
		docsUrl: '7973781',
	},
	stock: {
		docsUrl: '7973783',
	},
	draft: {},
}

export const Body = () => {
	const {isAdmin} = useTablesContext()
	const types = isAdmin ? tableTypes : [tableTypes[0]]
	return (
		<div className="exports-tables-body">
			{types.map(t => (
				<TableBlock type={t} />
			))}
		</div>
	)
}

const TableBlock = ({type}) => {
	const {tables, setActiveType, isAdmin} = useTablesContext()
	const items = tables.filter(t => t.kind() == type)

	const placeholderProps = {
		name: `export_table${isAdmin ? '_admin' : ''}`,
		animation: 'bar-chart',
		docsId: '7973781',
		tourId: isAdmin && '447826',
		children: isAdmin && <CreateButton link="new" t="action_button" />,
	}

	const {list} = useList({items, ListItem: TableCard, onClick: onItemClick, placeholderProps})
	const titleEl = useRef(null)
	const visible = useOnScreen(titleEl, {rootMargin: '-64px'}) // -64px is the margin of the filter footer

	useEffect(() => {
		visible && setActiveType(type)
	}, [visible])

	if (items.length == 0 && type == 'draft') return null
	const classNames = utils.makeCns(`exports-tables-block`, `exports-tables-block-${type}`)

	const _typeData = typeData[type]
	const {docsUrl} = _typeData

	return (
		<div className={classNames}>
			<div className="exports-tables-block-title-container">
				<h3 ref={titleEl}>
					{t(`blocks.${type}.title`)} ({items.length})
				</h3>
				{docsUrl && <DocsButton to={docsUrl} children={t(`blocks.${type}.docsText`)} height="small" />}
			</div>
			{list}
		</div>
	)
}

const onItemClick = item => {
	if (item.stock) return item.duplicate()
	if (item.draft) return item.edit()
	return item.show()
}

export default Body
