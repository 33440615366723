import {backend, find} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import useList from 'components/general/list'
import Modal from 'components/general/modal'
import {CheckMark, UserAvatar, UserImage} from 'components/ui'
import {getQuizScore} from 'helpers/topics'
import useAsyncEffect from 'hooks/use_async_effect'
import useParams from 'hooks/use_params'
import {t} from 'initializers/i18n'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import {whereInc} from 'selectors/records'
import useTopicResources from '../topics/use_topic_resources'
import {TrainingResults} from './components'
import '../styles/overview.less'

const Overview = () => {
	const [topicData, setTopicData] = useState({})
	const {id} = useParams()
	let topic = useSelector(state => find(state, 'topics', id))
	topic = {...topic, ...topicData}
	const {publishedTopicResources: topicResources, topicQuizItems} = useTopicResources(topic)

	const [main, setMain] = useState(true)
	const users = useSelector(state => whereInc(state, 'users', {team_ids: topic.team_ids}))

	useAsyncEffect(async () => {
		const res = await backend.get(`topics/${id}/training_overview`)
		if (res.ok) {
			setTopicData(res.data.item)
		}
	}, [])

	const userItemProps = {
		topic,
		quizItems: topicQuizItems,
	}

	// The User List

	const {list, searchInput, activeItems, setActive} = useList({
		items: users,
		itemProps: userItemProps,
		ListItem: UserRow,
		onClick: item => {
			setMain(false)
			setActive(item.id)
		},
		searchProps,
	})

	// Topic Info List
	const itemProps = {
		userId: activeItems[0]?.id,
		topic,
	}

	const {list: topicList} = useList({
		items: topicResources,
		ListItem: Decider,
		itemProps,
	})

	const handleMainScreen = () => {
		setMain(true)
		setActive(null)
	}

	const displayContainer = main ? <TrainingResults topic={topic} /> : topicList

	return (
		<Modal wrapClassName="training-overview-page modal-wrapper" size="md" closable={false}>
			<div className="left-modal-column modal-col">
				<div className="column-header" onClick={handleMainScreen}>
					<h3 className="title">{t('analytics')}</h3>
				</div>
				<div className="search-input">{searchInput}</div>
				<div className="user-list-sm selected-bg">{list}</div>
			</div>

			<div className="right-modal-column">{displayContainer}</div>
		</Modal>
	)
}

const AnswerText = ({text, idx, isCorrect, isUserAnswer, startCharCode = 65}) => {
	const char = String.fromCharCode(startCharCode + idx)
	const className = ['eitje-text-secondary', isCorrect && 'correct-answer', isUserAnswer && 'user-answer'].filter(Boolean).join(' ')
	return (
		<p className={className}>
			{char}: {text}
		</p>
	)
}
// Answers for the full screen training modal
const QuizAnswerBox = ({quizResource, num}) => {
	const {quiz_item_id, answer, correct} = quizResource
	const {answers, question, correct_answer} = useSelector(state => find(state, 'quizItems', quiz_item_id))
	return (
		<div class="blocks fRow aCen jBetween quiz-answer-box" style={{padding: '24px', margin: '12px'}}>
			<div>
				<p style={{minHeight: '60px'}} className="bold">
					{question}
				</p>
				{answers.map((text, idx) => (
					<AnswerText text={text} isUserAnswer={idx == answer} isCorrect={idx === correct_answer} idx={idx} />
				))}
			</div>
			<div className="align-self-flex-start">
				<CheckMark checked={correct} cross={!correct} />
			</div>
		</div>
	)
}
// analytics answer boxes
const QuizItem = ({item, userId, topic}) => {
	const {question, answers, correct_answer, id} = item
	const {user_answers: _user_answers = []} = topic // note that a topic stores all user_answers to all questions
	const user_answers = _user_answers.filter(ans => ans.quiz_item_id === id)
	const userAnswerIdx = user_answers.find(ansObj => ansObj.user_id == userId)
	const userGaveCorrectAnswer = userAnswerIdx?.answer == correct_answer

	const _checkMark =
		userAnswerIdx !== undefined ? <CheckMark checked={userGaveCorrectAnswer} cross={!userGaveCorrectAnswer} /> : <CheckMark />

	return (
		<div class="row-with-border-and-padding-xl margin-bottom-md quiz-answer-box">
			<div className="flex-grow-one">
				<p style={{minHeight: '60px'}} className="bold">
					{item.question}
				</p>
				{answers.map((a, idx) => (
					<AnswerText isUserAnswer={idx == userAnswerIdx?.answer} isCorrect={correct_answer == idx} text={a} idx={idx} />
				))}
			</div>
			<div className="align-self-flex-start">{_checkMark}</div>
		</div>
	)
}

const Article = ({item, userId}) => {
	const reader = item.readers.find(r => r.user_id === userId)
	return (
		<div class="row-with-border-and-padding-xl horizontal-padding-sm margin-bottom-md">
			<div className="flex-grow-one">
				<p className="bold">{item.title} </p>
				<p className="eitje-text-secondary"> {reader ? t('readerCount', {count: reader.count}) : t('notRead')} </p>
			</div>
			<CheckMark checked={!!reader} />
		</div>
	)
}

const Decider = props => <div> {!!props.item.answers ? <QuizItem {...props} /> : <Article {...props} />} </div>

const hasAnsweredCorrectly = (quizItem, userAnswers) => {
	const usersAnswer = userAnswers.find(ans => ans.quiz_item_id === quizItem.id) || {}
	return usersAnswer.answer === quizItem.correct_answer
}

const userRowText = (isDone, hasStarted) => {
	if (isDone) return 'finished'
	return hasStarted ? 'notDoneYet' : 'notStartedYet'
}

const UserRow = ({item, quizItems, isActive, topic, onClick}) => {
	const {user_answers = [], finished = [], started = []} = topic
	const hasStarted = started.includes(item.id)
	const isDone = finished.includes(item.id)

	const rowUsersAnsers = user_answers.filter(ans => ans.user_id === item.id) || []
	const correctAnswerCount = quizItems.filter(q => hasAnsweredCorrectly(q, rowUsersAnsers)).length

	const hasQuiz = user_answers.length > 0

	const correctPct = utils.pct({total: quizItems.length, active: correctAnswerCount})

	const subhead =
		isDone && hasQuiz
			? `${correctAnswerCount} / ${quizItems.length} ${t('answeredCorrectly')} | ${correctPct}%`
			: t(userRowText(isDone, hasStarted))

	return (
		<>
			<UserAvatar user={item} className="margin-right-xss" />

			<div className="list-item-text-container">
				<p className="list-item-title">{item.full_name}</p>
				<p className="list-item-sub">{subhead}</p>
			</div>
		</>
	)
}

const searchProps = {
	searchField: 'full_name',
}

export const SingleOverview = () => {
	const {id} = useParams()
	const [answers, setAnswers] = useState([])
	const [loaded, setLoaded] = useState(null)

	useAsyncEffect(async () => {
		const res = await backend.get(`topics/${id}/user_answers`)
		if (res.ok) {
			setAnswers(res.data.items)
		}
		setLoaded(true)
	}, [])

	if (!loaded) return null
	if (!answers || answers.length === 0) return <Redirect to={`/topics/${id}`} />

	const score = getQuizScore(answers)
	return (
		<div className="veedree" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
			<div style={{width: '570px'}}>
				<h3 className="results-header">
					{t('results')} - {score}%
				</h3>
				<div style={{overflow: 'scroll'}}>
					{answers.map((item, i) => (
						<QuizAnswerBox quizResource={item} num={i + 1} />
					))}
				</div>
			</div>
		</div>
	)
}

export default Overview
