import {all} from '@eitje/easy_api'
import Drawer from 'components/general/drawer'
import useList from 'components/general/list'
import {Link} from 'components/routing'
import useTeamFilter from 'hooks/use_team_filter'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {UserRead} from './components'
import './styles/index.less'

const orderArticles = ({items, group = {}}) => {
	return _.orderBy(
		items,
		i => {
			const {resource_map = []} = group
			return resource_map.find(r => r.resource_id == i.id && r.kind == 'Info')?.order
		},
		['asc'],
	)
}

const ArticleList = () => {
	let articles = useSelector(state => all(state, 'infos'))
	const _topics = useSelector(state => all(state, 'topics'))

	const topics = _.orderBy(_topics, [item => item.title.toLowerCase()], ['asc']).sort((x, y) =>
		x.published === y.published ? 0 : x.published ? -1 : 1,
	)

	const {filter, items} = useTeamFilter(topics, {placeholder: t('filterOnTeams')})

	const listProps = {
		groups: items,
		groupField: 'topic_ids',
		ListItem: Article,
		GroupListItem: ArticleGroup,
		modifyItems: orderArticles,
	}

	const searchProps = {
		searchField: 'title',
	}

	articles = _.orderBy(articles, a => a.title.toLowerCase())
	const {searchInput, list} = useList({items: articles, hasSearch: true, searchProps, ...listProps})

	return (
		<Drawer className="article-drawer" title={t('articles')} width={420}>
			<div className="drawer-header">{filter}</div>
			{searchInput}
			<div className="grouped-list-sm">{list}</div>
		</Drawer>
	)
}

const ArticleGroup = ({item = {}}) => {
	const Wrapper = item.id ? Link : Fragment
	return (
		<Wrapper to={item.id ? `/topics/${item.id}` : ''}>
			<p className="font-size-md bold">{item.title || t('looseArtLabel')}</p>
		</Wrapper>
	)
}

const Article = ({item}) => {
	return (
		<Link to={`/articles/${item.id}`}>
			<p className="grey-text"> {item.title} </p>
			<UserRead item={item} />
		</Link>
	)
}

export default ArticleList
