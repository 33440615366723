import {API} from '@eitje/easy_api'
import {LegacyDatePicker as DatePicker} from '@eitje/form-fields-web'
import {Button} from 'antd'
import {DeleteButton, EitjeButton} from 'common/components'
import {SalaryInput} from 'components/form/input_number'
import useList from 'components/general/list'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import _ from 'lodash'

export const Salary = ({fullContract}) => {
	let {salaries, user_id} = fullContract
	const dateFields = _.pick(fullContract, 'start_date', 'end_date')

	const itemProps = {
		onSubmit: data => API.update('salaries', data),
		allowDelete: salaries.length > 1,
		def: salaries[0]?.id,
	}

	const {list} = useList({items: salaries, ListItem: SalaryRow, itemProps})
	return (
		<>
			{salaries.length === 0 && (
				<SalaryRow onSubmit={data => API.create('salaries', {...data, ...dateFields, user_id})} item={dateFields} />
			)}
			{list}

			{salaries.length > 0 && (
				<div className="modal-inner-footer gap-xss" fieldWrapper>
					<EitjeButton iconLeft="pencil" modalLink="mutation" t="createMutation" />
				</div>
			)}
		</>
	)
}

export const SalaryMutation = ({contract}) => {
	const {salary} = contract
	return (
		<Form
			onSubmit={data =>
				API.arbitrary('salaries', 'mutation', {...data, user_id: contract.user_id, environment_id: contract.environment_id})
			}
			afterSubmit={history.goBack}
		>
			<div fieldWrapper className="modal-form-field-area">
				<DatePicker
					placeholder={t('selectDate')}
					defaultPickerValue={salary.start_date}
					disabledAfter={contract.end_date}
					disabledBefore={salary.start_date}
					label="mutationDate"
					required
					bordered={false}
					field="start_date"
				/>

				<SalaryInput required field="amount" />
			</div>

			<div className="modal-inner-footer gap-xss" fieldWrapper>
				<Button onClick={history.goBack}> {t('cancel')} </Button>
				<Button type="primary" submitButton showAfterTouch>
					{t('save')}
				</Button>
			</div>
		</Form>
	)
}

const SalaryRow = ({item, isFirst, allowDelete, onSubmit}) => {
	const {id, amount, end_date, start_date} = item
	return (
		<Form fieldProps={{bordered: false}} resetAfterSubmit initialValues={{amount, id}} onSubmit={onSubmit}>
			<div fieldWrapper className="modal-form-field-area">
				<SalaryInput
					field="amount"
					submitStrategy="inlineButton"
					rightChildren={isFirst && allowDelete && <DeleteButton iconOnly onDel={() => API.destroyMutation('salaries', id)} />}
					label={t('fromTill', {from: start_date, till: end_date, kind: 'salary'})}
				/>
			</div>
		</Form>
	)
}
