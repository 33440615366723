import {t} from 'initializers/i18n'

export const pickerMap = array => array.map(v => ({id: v, name: t(v)}))

export const prettyPrintArray = ({array, quotes = true, joinWord = 'or'}) => {
	const empty = _.isEmpty(array) || array.length === 0
	if (empty) return ''

	const quote = quotes ? "'" : ''
	const items = array.map(item => `${quote}${item}${quote}`)

	const singleItem = array.length === 1
	if (singleItem) return items[0]

	const lastItem = items.pop()
	joinWord = t(`common.${joinWord}`)
	return `${items.uniq().join(', ')} ${joinWord} ${lastItem}`
}
