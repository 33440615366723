import {createContext, useContext, useEffect} from 'react'
import {usePlanningTableDates} from 'cores/planning/pages/table'
import {usePrevious} from '@eitje/react-hooks'
import {store} from 'index'
import {API} from '@eitje/easy_api'
import {PlanningShift, AvailabilityShift, LeaveRequest} from 'models/index'
import {identicalPeriod} from 'helpers'

const PlanningTableContext = createContext({})
const {Provider} = PlanningTableContext

export const PlanningTableProvider = ({children}) => {
	const values = usePlanningTableDates()
	const {startDate, endDate} = values
	const previousStartDate = usePrevious(startDate)
	const previousEndDate = usePrevious(endDate)

	useEffect(() => getPlanningTableData({previousStartDate, previousEndDate, ...values}), [startDate.format(), endDate.format()])
	return <Provider value={values}>{children}</Provider>
}

export const usePlanningTableContext = () => useContext(PlanningTableContext)

const getPlanningTableData = async ({startDate, endDate, previousStartDate, previousEndDate}) => {
	// Prevent unnecessarily requesting the back-end and refreshing the Redux store.
	const identical = identicalPeriod(startDate, endDate, previousStartDate, previousEndDate)

	// Request the required planning shifts from the back-end AND clear the current planning shifts from
	// Redux to prevent a bloating store, which would slow the application down.
	const filters = {start_date: startDate, end_date: endDate}
	const refresh = !identical

	const multiIndexPayload = [
		{name: PlanningShift.tableName, filters, refresh},
		{name: AvailabilityShift.tableName, filters, refresh},
		{name: LeaveRequest.tableName, filters}, // never refresh, keep records in store
	]

	store.dispatch({type: 'START_LOADING'})
	await API.multiIndex(multiIndexPayload)
	store.dispatch({type: 'STOP_LOADING'})
}
