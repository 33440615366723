import Reactotron from 'reactotron-react-js'
import {reactotronRedux} from 'reactotron-redux'
import apisaucePlugin from 'reactotron-apisauce'

let reactotron

if (process.env.NODE_ENV !== 'production') {
	reactotron = Reactotron.configure({name: 'eitje_web'}).use(apisaucePlugin()).use(reactotronRedux()).connect()
	console.tron = Reactotron
} else {
	console.tron = {log: () => {}}
}

export default reactotron
