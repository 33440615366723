import {useChatContext} from 'stream-chat-react'
import {PopoutCard} from '@eitje/web_components'
import {ListPicker, Text} from 'common/components'
import {NAMESPACE, CHANNEL_TYPES, useCreateChannel, useActiveUsersWithoutMe} from 'cores/chat'
import {useShared} from 'hooks'

export const DirectChatUserPicker = () => {
	const {setActiveChannel} = useChatContext()
	const {me} = useShared()
	const {createChannel} = useCreateChannel()
	const users = useActiveUsersWithoutMe()

	const handleClick = async user => {
		const userIds = [user, me.id]

		const channel = await createChannel({
			type: CHANNEL_TYPES.DIRECT,
			userIds,
		})

		setActiveChannel(channel)
	}

	return (
		<PopoutCard
			title={`${NAMESPACE}.pick_team_member`}
			body={<ListPicker labelField="full_name" items={users} single raw onChange={handleClick} showSearch />}
			bodyPadding={0}
			trigger="click"
			placement="right-start"
		>
			<Text name={NAMESPACE} t="direct_chat" />
		</PopoutCard>
	)
}
