import React, {useCallback, useState} from 'react'
import {t} from 'initializers/i18n'
import GroupedAvailability from './grouped_availability'
import AvailabilityList from './availability_list'
import {useTeamContext, useEnvTableContext, useTeamAvUsers} from 'cores/planning/index'
import {EitjeButton} from 'common/components'
const MAX_AMOUNT = 10

const Availability = ({date}) => {
	const [showAll, setShowAll] = useState()
	const {alwaysShowPrefShifts} = useEnvTableContext()
	const {team} = useTeamContext()
	const mergedUsers = useTeamAvUsers({team, date})

	const availableUsers = mergedUsers.filter(u => u.planningStatus == 'available')
	const unavUsers = mergedUsers.filter(u => u.planningStatus == 'unavailable')
	const improdUsers = mergedUsers.filter(u => u.planningStatus == 'improductive')
	const pendingImprodUsers = mergedUsers.filter(u => u.planningStatus == 'pending_improductive')

	const amtWorkedReplacement = useCallback(
		user => <h5 className="planning-day-availability-leave">{t(`planning.statuses.${user.status}`)}</h5>,
		[],
	)

	const hasMoreUsersToShow = unavUsers.length || improdUsers.length || availableUsers?.length > MAX_AMOUNT
	const maxAmount = showAll ? undefined : MAX_AMOUNT

	return (
		<div className="planning-day-availability">
			{alwaysShowPrefShifts ? (
				<AvailabilityList users={availableUsers} maxAmount={maxAmount} />
			) : (
				<GroupedAvailability date={date} users={availableUsers} maxAmount={maxAmount} />
			)}
			{showAll && unavUsers.length > 0 && <AvailabilityList users={unavUsers} label={t('planning.environment_table.unavailable')} />}
			{/*  {scheduledUsers.length > 0 && (
        <AvailabilityList
          hideTime
          users={scheduledUsers}
          label={t('planning.environment_table.scheduled')}
          color={statusColors['scheduled']}
        />
      )}*/}
			{showAll && improdUsers.length > 0 && (
				<AvailabilityList
					users={improdUsers}
					label={t('planning.environment_table.improductive')}
					color="white"
					noSort
					amtWorkedReplacement={amtWorkedReplacement}
				/>
			)}

			{showAll && pendingImprodUsers.length > 0 && (
				<AvailabilityList amtWorkedReplacement={() => ' '} users={pendingImprodUsers} label={t('leaveRequested')} color="white" noSort />
			)}
			{!showAll && hasMoreUsersToShow && (
				<EitjeButton
					height="mini"
					margin="4 - - -"
					className="show-more-button"
					onClick={() => setShowAll(true)}
					width="full-minus-margin-4"
					iconRight="chevron-small-down"
				>
					{t('more')}
				</EitjeButton>
			)}
		</div>
	)
}

export default Availability
