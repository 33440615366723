import React, {Fragment} from 'react'
import {EitjeVirtuoso} from '@eitje/web_components'
import useEnvSetting from 'hooks/use_env_setting'
import Header from './header/index'
import Days from './components/days'
import UserRow from './user_row/index'
import {useAvContext} from 'contexts/availability'
import EnvContext, {useEnvContext} from './contexts/env'
import TeamDayFooter from './components/team_day_footer/index'
import useAvailability from 'hooks/use_availability'
import {SearchEmpty} from 'hooks/use_search'
import {useNoTeamMembersPlaceholder} from 'shared'
import {Placeholder} from 'common/components'
import './index.less'
import '../styles/availability.less'

const EnvTable = ({env}) => {
	const {autoLockEnabled} = useAvContext()
	const {disabled} = useAvailability(env.id)
	const defaultUnav = useEnvSetting('beschikbaarheid', 'default', env.id) == 'unavailable'

	return (
		<div className="environment-availability">
			<EnvContext env={env}>
				<Header disabled={disabled} />
				{!disabled && (
					<Fragment>
						<div className={'users-availability-table'}>
							<Days />
							<UserRows />
						</div>

						{autoLockEnabled && !defaultUnav && <TeamDayFooter />}
					</Fragment>
				)}
			</EnvContext>
		</div>
	)
}

const UserRows = () => {
	const {resetSearchAndFilters} = useAvContext()
	const {users} = useEnvContext()
	const teamPlaceholderProps = useNoTeamMembersPlaceholder()

	if (users.length === 0) {
		return teamPlaceholderProps ? <Placeholder {...teamPlaceholderProps} /> : <SearchEmpty action={resetSearchAndFilters} />
	}

	return (
		<EitjeVirtuoso
			className="env-user-rows"
			data={users}
			customScrollParent=".page-body"
			itemContent={(index, user) => <UserRow key={user.id} user={user} />}
		/>
	)
}

export default EnvTable
