import {useFind} from '@eitje/easy_api'
import {useSimpleContext} from 'contexts/simple'
import {UserCard, ContractLinkBtn} from 'components/shared/index'
import {t} from 'cores/leave/index'
import {ScheduleSummary, ScheduleDaysOverview} from 'cores/contract/index'
import {DocsButton, Layout} from 'common/components'
import './styles.less'

const MARGIN = [24, 0]

export const WorkSchedulePopout = ({request}) => {
	const {schedules} = useSimpleContext()
	const dateRange = request.__dateRange()
	const user = useFind('users', request.user_id)
	const schedule = schedules[0]

	return (
		<Layout direction="vertical">
			<Layout className="work-schedule-popout" vertical="start" horizontal="spaceBetween">
				<UserCard user={user} size="large" Subline={ScheduleSummary} sublineProps={{schedule}} noBorders />
				<ContractLinkBtn userId={user.id} dateRange={dateRange} />
			</Layout>

			<Layout width={532} direction="vertical" gap={8} margin={MARGIN}>
				<h4>{t('work_schedule_popover.title')} </h4>
				<h5>{t('work_schedule_popover.text')} </h5>
				<DocsButton to="6019593" height="small" />
			</Layout>
			{schedules.map(s => (
				<ScheduleDaysOverview schedule={s} />
			))}
		</Layout>
	)
}

export default WorkSchedulePopout
