import {history} from 'index'

export const hideHeaders = ['avatar']

export const tableFields = ['avatar', 'full_name', 'email', 'telefoonnummer', 'verjaardag']
export const managerFields = ['team_ids', 'date_started', 'vloer_code', 'confirmed']

export const contractFields = [
	'contract.environment_id',
	'employmentType.name',
	'contract.start_date',
	'contract.end_date',
	'salary.amount',
	'workSchedule.totalMins',
]

export const activeFields = tableFields.slice(0, 7)

export const tableFieldsDeleted = ['avatar', 'full_name', 'email', 'telefoonnummer', 'verjaardag', 'deleted_at', 'del_env_ids']

export const activeFieldsDeleted = tableFieldsDeleted.slice(0, 7)

export const showEmpty = ['avatar', 'confirmed']

export const stickyFields = ['full_name', 'avatar']

const searchField = ['full_name', 'email']
export const searchProps = {searchField}

const firstColumnStyle = {backgroundColor: '#fff'}

const makeColumnProps = idx => {
	if (idx === 0 || idx === 1) return {style: {}}
	return {}
}

export const rowProps = {placement: 'left', columnProps: makeColumnProps, onClick: item => history.push(`/user/${item.id}/profile`)}

export const contractJoins = {joins: ['userEmploymentTypes', 'salaries', 'contractHolders', 'workSchedules']}
