import {t as baseT} from 'initializers/i18n'
import {DeleteCross} from 'components/ui'
import {EitjeButton, Layout} from 'common/components'
import {EitjeDropdown} from 'common/components'
import './styles/index.less'

export const MultiAction = ({elements, count, title, close}) => {
	return (
		<Layout className="multi-action" gap={24} padding="0 16 0 24" colorSet="color-bordered-outline">
			<h4 className="multi-action-description"> {title} </h4>
			<Layout gap={16}>
				<EitjeDropdown disabled={count == 0} elements={elements}>
					<EitjeButton dropdown colorSet="solid" children={baseT('common.perform_action')} />
				</EitjeDropdown>
				<DeleteCross confirm={false} color={Colors.mediumBlue} onDel={close} />
			</Layout>
		</Layout>
	)
}
