import {useChatContext} from 'stream-chat-react'
import {Layout} from 'common/components'
import {useChatWindowContext, Channel, ChannelList, JumpToMessageProvider} from 'cores/chat'
import {ChannelShowHeader, ChannelIndexHeader} from './header'

const FOOTER_HEIGHT = 52
const POSITION = ['-', '-', FOOTER_HEIGHT + 20, 20]
// zIndex of one higher than week table days header
const inlineStyles = {zIndex: 101, overflow: 'unset', background: Colors.white}
const channelListInlineStyles = {flex: 1, overflowY: 'auto'}

export const ChatWindow = () => {
	const {channel} = useChatContext()
	const {chatWindowOpen} = useChatWindowContext()

	if (!chatWindowOpen) return null

	return (
		<Layout
			id="chat-window"
			absolute={POSITION}
			gap={0}
			border
			width={350}
			height={500}
			minHeight={250}
			maxHeight="calc(100vh - 250px)" // don't make too small, 250px stills feels good
			borderRadius={4}
			direction="vertical"
			style={inlineStyles}
		>
			<JumpToMessageProvider>
				{!channel && (
					<>
						<ChannelIndexHeader />
						<ChannelList width="full" style={channelListInlineStyles} />
					</>
				)}
				{channel && (
					<>
						<ChannelShowHeader channel={channel} />
						<Channel messageInputProps={{maxRows: 3}} messageInputLayoutProps={{padding: 8}} width="full" grow />
					</>
				)}
			</JumpToMessageProvider>
		</Layout>
	)
}
