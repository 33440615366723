import utils from '@eitje/web_utils'
import {PopoutCard, Icon} from '@eitje/web_components'
import {Text} from '../text'
import './styles/index.less'
import {Layout} from '../layout'

const PADDING = [0, 12]

export const InfoCard = ({
	icon,
	baseColor = 'blue',
	label,
	children,
	className,
	popoutTitle,
	popoutBody,
	colorSet = !!popoutBody,
	placeholder,
	width,
	textPopoutTitle,
	...rest
}) => {
	const popoutClassNames = utils.makeCns('info-card ', className)

	return (
		<PopoutCard
			className={popoutClassNames}
			title={popoutTitle}
			body={popoutBody}
			hidden={!popoutBody}
			layoutProps={{
				gap: 8,
				baseColor,
				colorSet,
				padding: PADDING,
				height: 40,
				type: 'card',
				width,
			}}
			{...rest}
		>
			{icon && <Icon name={icon} />}
			<Layout direction="vertical" gap={0}>
				<Text mini darkGrey popoutTitle={textPopoutTitle}>
					{label}
				</Text>
				<Text semiBold small>
					{children || placeholder}
				</Text>
			</Layout>
			{!!popoutBody && <Icon name="caret-down" />}
		</PopoutCard>
	)
}
