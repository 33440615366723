import {FormRow} from 'components/ui'
import {Input, Checkbox} from '@eitje/form-fields-web'
import {DefaultListPickerTrigger} from '@eitje/web_components'
import {useState} from 'react'
import useHover from 'hooks/use_hover'
import utils from '@eitje/web_utils'
import {useFormData} from 'hooks'
import {t} from 'initializers/i18n'
import {EmojiPicker} from 'components/form/emoji_picker'
import {resourceT} from 'helpers'
import {DeleteButton as DeleteButtonComponent, ListPicker} from 'common/components'
import './styles/index.less'

export const ShiftType = ({number, handleDelete, isFirst}) => {
	const formData = useFormData()
	const [deleteBtnHovering, setDeleteBtnHovering] = useState()
	const {hoverActions, isHovering} = useHover()
	const isActive = formData[`active-${number}`]
	const inputClassNames = utils.makeCns('inputs-list-wrapper', isActive && 'inputs-list-wrapper-active')

	const DeleteButton = (
		<DeleteButtonComponent
			height="small"
			title={t('records.shift_type.delete_message')}
			onDel={() => handleDelete(number)}
			onVisibleChange={setDeleteBtnHovering}
		/>
	)

	const options = ['hide_end', 'show_call'].map(option => ({
		name: t(`records.shift_type.fields.${option}`),
		id: option,
	}))

	return (
		<>
			{isFirst && <Header />}
			<FormRow>
				<div className={inputClassNames} {...hoverActions}>
					<Input
						field={`name-${number}`}
						bordered={false}
						icon={null}
						labelVisible={false}
						hideClearIcon
						suffix={(isHovering || deleteBtnHovering) && DeleteButton}
					/>
				</div>
				<div className="shift-row-right">
					<EmojiPicker field={`emoji-${number}`} />
					<ListPicker
						dropdown
						field={`options-${number}`}
						Trigger={DefaultListPickerTrigger}
						decorated={false}
						small
						showCountOnly
						title={t('common.options')}
						placeholder={null}
						items={options}
					/>
					<div className="checkbox-container">
						<Checkbox field={`active-${number}`} label={null} />
					</div>
				</div>
			</FormRow>
		</>
	)
}

const Header = () => {
	const _t = resourceT(`shift_type`)
	return (
		<span className="shift-type-header">
			<h6>{_t('name')}</h6>
			<div className="shift-row-right">
				<h6>{_t('emoji')}</h6>
				<h6>{_t(`extra_options`, {count: 2})}</h6>
				<h6>{_t('active')}</h6>
			</div>
		</span>
	)
}
