import {ListPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {useLeaveContext} from 'cores/leave'

const periods = ['present', 'future', 'past', 'custom']

export const PeriodTypePicker = ({...rest}) => {
	const {period, setPeriod} = useLeaveContext()
	const items = periods.map(period => ({id: period, name: t(`common.periods.${period}`)}))

	const changePeriod = newPeriod => {
		if (period === newPeriod) return
		setPeriod(newPeriod)
	}

	return <ListPicker {...rest} noSort single raw dropdown items={items} value={period} onChange={changePeriod} trigger="click" />
}
