const initialState = {
	startDate: null,
	endDate: null,
	table: {
		startDate: null,
		endDate: null,
		period: null,
	},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'SET_PLANNING_DATES':
			return {
				...state,
				startDate: action.startDate,
				endDate: action.endDate,
			}

		case 'SET_PLANNING_TABLE_DATES':
			const values = _.pick(action, 'startDate', 'endDate', 'period')

			return {
				...state,
				table: {
					...state.table,
					...values,
				},
			}

		case 'SET_PLANNING_TABLE_PERIOD':
			return {
				...state,
				table: {
					...state.table,
					period: action.period,
				},
			}

		default:
			return state
	}
}
