export const statusColorStrings = {
	partly_available: 'mediumOrange',
	unavailable: 'mediumRed',
	available: 'mediumGreen',
	leave: 'mediumPurple',
	leave_asked: 'mediumPurple',
	leave_accepted: 'mediumPurple',
	conflict: 'mediumRed',
	scheduled: 'mediumBlue',
	unknown: 'mediumBorder',
}

export const statusColors = _.mapValues(statusColorStrings, name => Colors[name])

const outlinedColors = ['leave_asked', 'unknown', 'leave']

export const isOutlinedStatus = status => outlinedColors.includes(status)

export const statusColorNames = [
	'scheduled',
	'unknown',
	'available',
	'unavailable',
	'partly_available',
	'leave_asked',
	'leave_accepted',
	'sick',
	'conflict',
]
