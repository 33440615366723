const showSupport = () => {
	if (window.Intercom) {
		window.Intercom('show')
	}
}

const startConversation = message => {
	if (window.Intercom) {
		const args = ['showNewMessage', message].filter(s => _.isString(s))
		window.Intercom(...args)
	}
}

const hideLauncher = () => {
	window.Intercom('update', {hide_default_launcher: true})
}

export {showSupport, hideLauncher, startConversation}
