import * as Sentry from '@sentry/react'
import {history} from 'initializers/history'
const maskOptions = {maskAllText: false, maskAllInputs: false, blockAllMedia: false}

const performanceOptions = {routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)}

if (process.env.NODE_ENV == 'production') {
	Sentry.init({
		dsn: 'https://27720104b3b249a5a76f6c403e2a2ad8@o360459.ingest.sentry.io/6590921',

		ignoreErrors: [
			'ResizeObserver loop limit exceeded',
			/ResizeObserver loop/,
			'{"code":43,"StatusCode":401,"message":"WS failed with code 43 and reason - JWTAuth error',
		],
		release: process.env.REACT_APP_VERSION,
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,
		tracesSampleRate: 0.05,
		sampleRate: 1.0,
		tunnel: '/unblocksentry',
		integrations: [new Sentry.BrowserTracing(performanceOptions), new Sentry.Replay(maskOptions)],
	})
}
