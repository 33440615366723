import './styles.less'
import {
	PageLayout,
	Title,
	Text,
	Layout,
	BackButton,
	TrialButton,
	SupportButton,
	CallButton,
	NoAuthLocaleButton,
	DemoButton,
	AuthLocaleButton,
} from 'common/components'
import {ProcessDots, CallToActions, NAMESPACE} from 'cores/auth'
import {NewForm} from '@eitje/form'
import {isMobile} from 'helpers'
import {FormSubmitButton} from 'components/form'
import {t} from 'initializers/i18n'
import {AnimatedIcon} from '@eitje/web_components'

const inlineStyles = {position: 'absolute', left: 24, top: 24}

export const AuthPageLayout = ({
	name,
	subtitle,
	rawSubtitle,
	backButton,
	children,
	noForm,
	onSubmit,
	submitButtonProps,
	animation,
	...rest
}) => {
	const autoFocus = 'autoFocus' in rest ? rest.autoFocus : !isMobile()
	// The hiding of the application headers for authed page is not managed here, but instead in auth_provider.js
	const namespace = `${NAMESPACE}.pages.${name}`
	const _subtitle = rawSubtitle || (subtitle && t(`${namespace}.subtitle`))
	return (
		<PageLayout name={name} margin="80 - - -" {...rest} className="auth-page-layout" headers={<AuthPageHeader {...rest} />}>
			<Layout relative horizontal="center">
				{backButton && <BackButton height="small" colorSet="solid" style={inlineStyles} />}
				<Layout className="auth-page-layout-column" direction="vertical" horizontal="center" width={360} gap={20}>
					<ProcessDots {...rest} />
					{animation && <AnimatedIcon animation={animation} width={96} />}
					<Title t={`${namespace}.title`} fontSize={24} />
					{_subtitle && <Text children={_subtitle} center />}
					{/* Don't show prompt, since it disturbs to user flow when using the back button and all forms are very small anyway */}
					<NewForm fieldProps={{autoFocus}} onSubmit={onSubmit} variant="box" hidePrompt {...rest}>
						{children}
						{children && !noForm && <FormSubmitButton iconLeft="arrow-right" t="common.next" {...submitButtonProps} />}
					</NewForm>

					<CallToActions {...rest} />
				</Layout>
			</Layout>
		</PageLayout>
	)
}

const AuthPageHeader = ({showSupport, demoButton, trialButton, extraHeaderItems, authedPage, noLocaleButton}) => {
	// The hiding of the Intercom widget is not managed through `showSupport` prop, but instead in src/lib/intercom.js

	return (
		<Layout className="no-auth-header" horizontal="spaceBetween" height={80} vertical="center" padding="- 20">
			<a href="https://www.eitje.app" target="_blank">
				<img className="eitje-logo" style={{height: 40}} src="/images/brand/logo-with-text.png" />
			</a>

			<Layout gap={10} className="no-auth-header-right">
				{trialButton && <TrialButton colorSet="solid" />}
				{showSupport && <SupportButton colorSet="solid" t="common.chat" />}
				{showSupport && <CallButton />}
				{(showSupport || demoButton) && <DemoButton />}
				{extraHeaderItems}
				{!noLocaleButton && (authedPage ? <AuthLocaleButton /> : <NoAuthLocaleButton />)}
			</Layout>
		</Layout>
	)
}
