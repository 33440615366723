import {t} from 'initializers/i18n'
import {Icon} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {flexRender} from '@tanstack/react-table'
import {Layout, Text} from 'common/components'
import {HeaderWithCheckbox, Dropdown} from '.'
import {
	GROUPED_CELL_WIDTH,
	DEFAULT_CELL_WIDTH,
	getPinnedColumns,
	getTableName,
	HeaderTitle,
	ColumnTotal,
} from 'common/components/advanced_table'

export const HeaderCell = ({header, rows = [], multiEdit, ...rest}) => {
	//
	// Read taxonomy
	//
	const {column, id: columnName, getContext} = header
	let {getIsSorted, columnDef, getIsGrouped} = column
	const {headerAggregates = true, tooltipTitle, hideTooltip, width: taxWidth, tooltipElement, aggregates: taxAggregates} = columnDef

	//
	// Get states
	//
	const context = getContext()
	const tableName = getTableName(context)
	const pinnedColumns = getPinnedColumns(context)
	const isGroupedColumn = getIsGrouped()
	const sortDirection = getIsSorted()
	const sortIcon = 'arrow-' + (sortDirection === 'asc' ? 'up' : 'down')
	const withCheckbox = multiEdit && (isGroupedColumn || pinnedColumns[0] === header.id)
	const width = taxWidth || (isGroupedColumn ? GROUPED_CELL_WIDTH : DEFAULT_CELL_WIDTH)

	//
	// Tooltip
	//
	const defaultPopoutTitle = t(`table.${tableName}.tooltips.${columnName}`, 'untranslated')
	const popoutTitle = utils.funcOrVal(tooltipTitle || defaultPopoutTitle)
	const tooltipUnset = popoutTitle == 'untranslated' && !tooltipElement
	const showTooltip = !hideTooltip && !tooltipUnset
	const tooltipProps = {
		...(showTooltip && tooltipElement && {popoutBody: tooltipElement(), bodyLayoutProps: {padding: 0}}),
		...(showTooltip && !tooltipElement && {popoutTitle: popoutTitle}),
		maxWidth: 320,
	}

	return (
		<Dropdown header={header} width={width}>
			<Layout className="advanced-table-header-cell" width={width} padding="2 4 2 8" height={32} colorSet>
				<Layout direction="vertical" gap={0} grow height={withCheckbox ? 'unset' : undefined}>
					<HeaderCellContent rows={rows} header={header} tooltipProps={tooltipProps} withCheckbox={withCheckbox} />
				</Layout>
				{sortDirection ? (
					<Icon name={sortIcon} size="14px" />
				) : (
					<Icon name="chevron-small-down" size="14px" initialFill={Colors.darkGrey} />
				)}
			</Layout>
		</Dropdown>
	)
}

const HeaderCellContent = ({header, tooltipProps, withCheckbox, rows}) => {
	const {column, getContext} = header
	const {columnDef} = column
	const {header: headerValue, aggregates: taxAggregates} = columnDef
	const context = getContext()
	const value = utils.funcOrVal(headerValue, context)
	const checkboxCellProps = {...context, aggregates: taxAggregates, tooltipProps}
	const aggregates = <ColumnTotal header={header} rows={rows} />

	if (withCheckbox) {
		return flexRender(props => <HeaderWithCheckbox {...context} {...props} aggregates={aggregates} />, checkboxCellProps)
	}

	return (
		<>
			<HeaderTitle value={value} context={context} tooltipProps={tooltipProps} />
			{aggregates && (
				<Text fontSize={8} semiBold truncate>
					{aggregates}
				</Text>
			)}
		</>
	)
}
