import {t} from 'initializers/i18n'
import {PlanningShift, User, RevenueDay, RevenueTeam} from 'models/index'
import {RevenueGroupWeekInner} from './revenue_group_week/index'
import RevenueFooterProvider, {useRevenueFooterContext} from './revenue_footer_provider'

const RevenueTotal = ({groups, revenueDays}) => {
	const {dateRange} = useRevenueFooterContext()
	const totalGroup = {naam: t('common.total')}
	const groupIds = groups.map(g => g.id)
	const revenueTeams = RevenueTeam.where({omzet_groep_id: groupIds})
	return (
		<div className="revenue-total">
			<RevenueGroupWeekInner isTotal group={totalGroup} revenueDays={revenueDays} revenueTeams={revenueTeams} dateRange={dateRange} />
		</div>
	)
}

export default RevenueTotal
