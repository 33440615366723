import {API, backend} from '@eitje/easy_api'

import * as Sentry from '@sentry/react'
import {authChannel} from 'auth_channel'
import {history, store} from 'index'

export async function login(email, password, redirect = 'home_page') {
	const body = {email, password, grant_type: 'password', scope: 'user', skipSuccessNotif: true}
	const resp = await backend.post('oauth/token', body)
	if (!resp.problem) {
		const {data} = resp
		const obj = {
			token: data.access_token,
			expires_at: data.created_at + data.expires_in,
			user: data.user,
			refresh_token: data.refresh_token,
		}
		store.dispatch({type: 'AUTH_SUCCESS', ...obj})

		authChannel.postMessage({type: 'AUTH_SUCCESS', payload: obj})

		if (redirect) history.push(redirect)
		return obj
	}
}

export async function refreshToken() {
	const {auth} = store.getState()
	const prms = {scope: 'user', refresh_token: auth.refresh_token, doNotLoad: true, grant_type: 'refresh_token'}
	const resp = await backend.post(`oauth/token`, prms)
	const {data} = resp
	if (!resp.problem) {
		const obj = {
			type: 'SET_TOKENS',
			token: data.access_token,
			refresh_token: data.refresh_token,
			expires_at: data.created_at + data.expires_in,
		}
		store.dispatch(obj)
		authChannel.postMessage({type: 'SET_TOKENS', payload: obj})

		return {...data, access_token: data.access_token, expires_at: data.created_at + data.expires_in}
	} else {
		logout()
		return false
	}
}

let refreshingToken = false
let refreshTokenPromise = null

export async function refreshTokenIfNeeded() {
	if (refreshingToken) return refreshTokenPromise

	const cs = Math.round(new Date().getTime() / 1000)
	const state = store.getState()

	if (state.auth.refresh_token && state.auth.expires_at < cs) {
		refreshingToken = true
		let _refreshTokenPromise = async () => {
			try {
				let tokenData = await refreshToken()
				return tokenData.access_token
			} finally {
				refreshingToken = false
			}
		}
		refreshTokenPromise = _refreshTokenPromise()
		return refreshTokenPromise
	} else {
		return state.auth.token
	}
}

export async function handleRegistration(previousRes, data) {
	const {id} = previousRes.data
	const res = await API.update('users', {id, ...data})
	if (res.ok) {
		await login(data.email, data.password)
	}
}

export function checkAuth() {
	const state = store.getState()
	return !!state.auth.refresh_token
}

export function hasRole(role) {
	const roles = store.getState().settings.roles
	return !!roles[role]
}

export async function logout() {
	//store.dispatch({type: 'LOGOUT'})
	// const prg2 = await purgeStoredState({key: 'primary', storage: localForage})
	// const prg = await persist.purge();
	store.dispatch({type: 'LOGOUT'})
	store.dispatch({type: 'RESET_RECORDS', dropEnvs: true})

	authChannel.postMessage({type: 'LOGOUT'})
	authChannel.postMessage({type: 'RESET_RECORDS', dropEnvs: true})

	history && history.push('/')
}

export async function authLogout() {
	Sentry.captureMessage(`forceful auth logout. user_id: ${window.user?.id}`)
	logout()
}
