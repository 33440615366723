import {date} from 'initializers/date'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'

const useTeamDay = query => {
	const {date: _date} = query
	const teamDay = useFind('teamDays', query)
	const dayOfWeek = date(_date).weekday() // we want 0-6!
	const templateDay = useFind('teamTemplateDays', {day_of_week: dayOfWeek, team_id: query.team_id})
	return teamDay.min_av_count ? teamDay : {...templateDay, id: null}
}

export default useTeamDay
