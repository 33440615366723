import React, {useEffect} from 'react'
import {FilterTrigger} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {useAvContext} from 'contexts/availability'
import {isOutlinedStatus, statusColorNames, statusColors} from 'cores/availability/constants'
import {ColorCircle} from 'components/ui'
import utils from '@eitje/utils'
import {SickStatus} from 'cores/availability/components/availability_cell'
import '../styles/availability.less'
import './styles/filter_footer.less'
import useHotkey from 'hooks/use_hotkey'
import {ListPicker} from 'common/components'

const FilterFooter = () => {
	const {envFilter, userSearchInput, searchInputRef} = useAvContext()
	const onKeyUp = useHotkey({ctrl_f: searchInputRef?.current?.focus})

	useEffect(() => {
		document.body.onkeyup = onKeyUp
	}, [searchInputRef.current])

	return (
		<div className="fixed-footer availability-footer">
			<div>
				{envFilter}
				<ContractTypeLabel />
				<AvStatusLabel />
			</div>
			<div>{userSearchInput}</div>
		</div>
	)
}

const FilterStatusRow = ({item: {label, value}, isSelected}) => {
	const textCn = utils.makeCns(!isSelected && 'grey-text', isSelected && 'bold')
	const isSick = label === 'sick'
	const color = statusColors[value]
	const isBorderColor = isOutlinedStatus(value)

	return (
		<div className="availability-filter-status">
			{isSick ? <SickStatus /> : <ColorCircle color={isBorderColor ? null : color} borderColor={isBorderColor ? color : null} />}
			<p className={textCn}>{label}</p>
		</div>
	)
}

const listPickerProps = {
	raw: true,
	dropdown: true,
	Trigger: FilterTrigger,
	noSort: true,
}

const ContractTypeLabel = () => {
	// const contractTypes = useSelector(taxonomySelector, 'contract_types')
	const contractTypes = ['Nul uren', 'Uren contract', 'Payroll', 'ZZP']
	const {setContractTypeFilter, contractTypeFilter} = useAvContext()

	return (
		<ListPicker
			{...listPickerProps}
			name={t('ccontract1')}
			noSort
			value={contractTypeFilter}
			items={contractTypes}
			onChange={setContractTypeFilter}
		/>
	)
}

const AvStatusLabel = () => {
	const {statusFilter, setStatusFilter} = useAvContext()
	const items = statusColorNames.filter(str => str !== 'unknown').map(i => ({id: i, name: t(i)}))

	return (
		<ListPicker
			{...listPickerProps}
			name={t('common.status')}
			value={statusFilter}
			items={items}
			ListItemContent={FilterStatusRow}
			onChange={setStatusFilter}
		/>
	)
}

export default FilterFooter
