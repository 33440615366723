import {all, backend} from '@eitje/easy_api'
import {filterUsers as userFilter} from 'actions/entities'
import {Button, Checkbox, DatePicker, Input, InputNumber, Modal, Select, Spin, Tooltip} from 'antd'
import {Users} from 'components/besch'
import {TeamDropdown} from 'components/general/menu_select'
import SaldoModal from 'components/modals/start_saldo_modal'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import QueryString from 'query-string'
import React, {Fragment} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {orgEnvsSelector} from 'selectors/entities'
import {legacyRolesSelector} from 'selectors/legacy'
import {getEnvSetting, getEnvSettings} from 'selectors/new_settings'
import {currentOrgSelector, inEnv} from 'selectors/records'
import {ModalLink} from 'components/routing'
import Dropdown from 'components/dropdown'
import utils from '@eitje/web_utils'
import * as jsUtils from 'utils/jsutils'
import {Affix} from 'components/ui'
import 'styles/legacy/roster.css'
import {PageLayout} from 'common/components'

const Option = Select.Option

const headerTitlesO = [
	`${t('amountShifts')}`,
	`${t('scheduledHours')}`,
	`${t('hoursWorked')}`,
	`${t('result')}`,
	`${t('meals')}`,
	`${t('unproductive')}`,
]
const headerTO_EX = {
	'aantal shifts':
		'Aantal shifts dat teamlid is ingepland in geselecteerde periode / Aantal urenregels dat teamlid heeft geschreven in geselecteerde periode',
	'geplande uren': 'Aantal geplande uren bij elkaar opgeteld in geselecteerde periode',
	'gewerkte uren': 'Aantal gerealiseerde uren bij elkaar opgeteld in geselecteerde periode',
	resultaat: 'Gewerkte uren - Geplande uren',
	uurloon: 'Het uurloon uit contract * omreken factor',
	improductief: 'De totale som van geschreven improductieve uren in de geselecteerde periode',
	maaltijden: 'Het totaal van genuttigde maaltijden',
}

const headerTitlesD = [
	`${t('amountShifts')}`,
	`${t('startTime')}`,
	`${t('endTime')}`,
	`${t('break')}`,
	`${t('type')}`,
	`${t('meals')}`,
	`${t('total')}`,
]
const headerTD_EX = {
	'aantal shifts': 'De som van het aantal shifts dat teamlid is ingepland en geregistreerd heeft op deze dag',
	'begintijd planning': 'Tijdstip waarop teamlid haar shift zal starten',
	'eindtijd planning': 'Tijdstip waarop teamlid haar shift afloopt',
	begintijd: 'Tijdstip waarop teamlid haar shift is gestart',
	eindtijd: 'Tijdstip waarop teamlid haar shift is geëindigd',
	pauze: 'Hoeveel tijd is er aan pauze geschreven',
	type: 'Het type uren van de geregistreerde shift',
	maaltijden: 'Als er maaltijden zijn geregistreerd bij de shift worden die hier weergegeven',
	totaal: 'Gewerkte tijd - de geschreven pauze',
}
const headerTitlesPM = [
	`${t('overtime')}`,
	`${t('onContract')}`,
	`${t('hoursWorked')}`,
	`${t('adjustments')}`,
	`${t('difference')}`,
	t('pmsaldo'),
]

const headerTitlesV = [
	`${t('leaveSaldi')}`,
	`${t('accumulated')}`,
	`${t('assimilated')}`,
	`${t('adjustments')}`,
	`${t('difference')}`,
	'verlof saldo',
]
const headerV_EX = {}
const headerV_SUB = [`${t('on')}`, `${t('inPeriod')}`, `${t('inPeriod')}`, `${t('inHours')}`, `${t('inPeriod')}`, `${t('on')}`]

const headerTitlesVS = ['Verlof saldo', 'in uren', 'in dagen', 'in loonkosten']
const headerTVS_EX = {'Verlof saldo': 'jo'}

const headerTPM_EX = {
	'op contract': 'Het aantal uren dat gewerkt zou moeten zijn volgens het contract in geselecteerde periode',
	gewerkt: 'Het aantal definitief gewerkte uren volgens de urenregistratie in geselecteerde periode',
	correcties: 'Voer hiermee correcties door op het plus/min saldo van teamleden',
	verschil: 'Is er in de geselecteerde periode meer of minder gewerkt dan volgens het contract had moeten gebeuren.',
	'plus/min saldo': 'Het plus/min saldo van teamleden met een urencontract op de einddatum van de ingestelde periode',
}
const headerTPM_SUB = [
	'op begindatum',
	`${t('inPeriod')}`,
	`${t('inPeriod')}`,
	`${t('inHours')}`,
	`${t('inPeriod')}`,
	`${t('on')}`,
	'totaal',
]
const headerTPMS = ['plus/min saldo', 'in uren', 'in dagen', 'in loonkosten']
const headerTPMS_EX = {
	'plus/min saldo': 'Het huidige plus/min saldo van teamleden met een urencontract',
	'in uren': 'Het aantal uren dat je teamlid in de plus of min staat',
	'in dagen': 'Het aantal dagen dat je teamlid in de plus of min staat',
	'in loonkosten': 'De verwachte loonkosten voor het huidige plus/min saldoHeader',
}
const serverFormat = 'YYYY/MM/DD'
const dateFormat = 'DD/MM/YYYY'
const {RangePicker} = DatePicker

class CorrectieModal extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			amt: 0,
			remarks: '',
			audits: [],
			date: null,
		}
	}

	componentDidMount() {
		this.fetchAudits()
	}

	fetchAudits = async () => {
		const {user, typ} = this.props
		const res = await backend.get(`tijd_correcties/audits/${user.id}?typ=${typ}`)
		if (res.ok) {
			const json = res.data
			this.setState({audits: json.audits})
		}
	}

	async submit() {
		const {amt, remarks, amt_m, neg, date: _date} = this.state
		const {user, typ} = this.props
		var obj = {amt: (amt * 60 + (amt_m || 0)) * (neg ? -1 : 1), user_id: user.id, omschrijving: remarks, date: _date, typ: typ}
		if (!date) {
			jsUtils.errNotif(t('att'), t('needDate'))
			return
		}

		const res = await backend.post(`tijd_correcties`, {
			tijd_correctie: obj,
		})
		if (res.ok) {
			// update local state
			// stop spinner
			this.props.afterSuccess()
			this.props.onCancel()
		}
	}

	render() {
		const {onCancel, user} = this.props
		const {amt, audits, neg, date: _date} = this.state
		return (
			<Modal className="modalStyle modalStyle340" onOk={() => this.submit()} onCancel={onCancel} title={t('addAdjust')} visible={true}>
				<div className="pickDay floatL modalStyle">
					<div className="marginModal floatL">
						<p>{t('dayC')}:</p>
						<div>
							{' '}
							<DatePicker
								style={{width: 180}}
								allowClear={false}
								format="DD-MM-YYYY"
								value={_date}
								onChange={val => this.setState({date: date(val)})}
							/>{' '}
						</div>
					</div>

					<div className="marginModal MLefts floatL">
						<p>{t('user')}:</p>
						<div className="modalDagBox" style={{width: 180}}>
							<p style={{}}>{user.naam} </p>
						</div>
					</div>

					<div className="clear"></div>

					<div className="marginModal floatL">
						<div style={{marginTop: 2, marginBottom: 5}}>
							<Checkbox checked={!neg} onChange={() => this.setState({neg: false})} />
							<p style={{display: 'inline', marginLeft: 4}}>{t('positive')}</p>
						</div>

						<div>
							<Checkbox style={{}} checked={neg} onChange={() => this.setState({neg: true})} />
							<p style={{display: 'inline', marginLeft: 4}}>{t('negative')}</p>
						</div>
					</div>

					<div className="clear"></div>

					<div className="marginModal floatL" style={{}}>
						<p style={{}}>{t('adInHours')}: </p>
						<InputNumber
							min={0}
							style={{width: 180, borderRadius: 4}}
							placeholder={t('inHours')}
							onChange={val => this.setState({amt: val})}
						/>
					</div>

					<div className="marginModal floatL" style={{marginLeft: 8}}>
						<p style={{}}>{t('adInMin')}: </p>
						<InputNumber
							max={59}
							min={0}
							style={{width: 180, borderRadius: 4}}
							placeholder={t('inMin')}
							onChange={val => this.setState({amt_m: val})}
						/>
					</div>

					<div className="clear"></div>

					<div className="modalStyle marginModal floatL">
						<p>{t('remarkAdjust')}:</p>
						<div style={{width: 368}}>
							<Input.TextArea
								autosize={{minRows: 4, maxRows: 4}}
								onChange={e => this.setState({remarks: e.target.value})}
								style={{width: 368, maxWidth: 368}}
							/>
						</div>
					</div>
					<div className="modalStyle marginModal floatL">
						{audits.length > 0 && <p>{t('earlierAdjust')}: </p>}
						{audits.length > 0 &&
							audits.map(audit => (
								<div style={{paddingBottom: 2}}>
									<p>
										- {audit.naam || 'onbekend'} {t('wrote')} {jsUtils.signedMinTTS(audit.changes.amt)} {t('for')} {audit.changes.date} (
										{t('createdAt')} {date(audit.tijd).format('DD/MM/YYYY')}){' '}
									</p>
									{audit.changes.omschrijving && (
										<p>
											{t('description')}: {audit.changes.omschrijving}{' '}
										</p>
									)}
								</div>
							))}
					</div>
				</div>
				<div className="clear"></div>
			</Modal>
		)
	}
}

const OverzichtTableHeader = ({items, first, uitleg, sub, plusMin, klass}) => (
	<Affix offsetTop={62}>
		<table className={'Table' + ' ' + klass} style={{marginTop: -1}}>
			<tbody>
				<tr className=" fixme tableHeader daysText">
					<td className="border" style={{color: '#2b2b2b'}}>
						<p style={{fontSize: 12, padding: 8}} className="breakWord">
							{first}
						</p>
					</td>

					{items.map(title => (
						<Tooltip title={uitleg[title]}>
							<td key={title} className="border verlofWidth">
								{title.toUpperCase()}
								{sub && <p style={{fontSize: 9, fontWeight: 500}}> {sub[items.indexOf(title)]} </p>}
							</td>
						</Tooltip>
					))}
				</tr>
			</tbody>
		</table>
	</Affix>
)

function transformDDay(day) {
	if (!day || day.plan) {
		return day || {}
	}
	var nDay = {}
	if (day.length == 1) {
		return day[0]
	} else {
		day = day.filter(d => !d.plan)
		nDay.from = nDay.till = 'Meerdere shifts'
		nDay.pauze = _.reduce(
			day.map(d => d.pauze),
			(tally, am) => tally + am,
			0,
		)
		nDay.totaal = _.reduce(
			day.map(d => d.totaal),
			(tally, am) => tally + am,
			0,
		)
		nDay.typ = day.map(d => jsUtils.capitalize(d.typ)).join(' / ')
		var mTijdMap = day.map(d => d.maaltijden)
		nDay.maaltijden = [].concat.apply([], mTijdMap).join(' / ')
		return nDay
	}
}

const DetailRow = ({day, nDay}) => {
	const dDay = transformDDay(nDay)
	const isEmpty = dDay.plan
	return (
		<tr className="tablebody detailTD">
			<td>
				{' '}
				<div className="cardUserUrenDetails" style={{paddingTop: 9}}>
					{day.format('dddd DD MMM')}
				</div>{' '}
			</td>

			<td>
				{' '}
				<div className="cardUserDetails detailsCard">{nDay && nDay.length}</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">{isEmpty ? '-' : dDay && dDay.from}</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">{isEmpty ? '-' : dDay && dDay.till}</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">{isEmpty ? '-' : dDay && dDay.pauze && jsUtils.minToTimeString(dDay.pauze)}</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">
					{isEmpty ? '-' : dDay && nDay && (nDay.length > 1 ? dDay.typ : jsUtils.capitalize(dDay.typ))}
				</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">
					{isEmpty ? '-' : dDay && dDay.maaltijden && (nDay.length > 1 ? dDay.maaltijden : dDay.maaltijden.join(' / '))}
				</div>{' '}
			</td>
			<td>
				{' '}
				<div className="cardUserDetails detailsCard">
					{isEmpty ? '-' : dDay && dDay.totaal && jsUtils.minToTimeString(dDay.totaal)}
				</div>{' '}
			</td>
		</tr>
	)
}

const DetailUser = ({user, amtDays, stDay, shiftDays}) => (
	<table className="Table">
		<tbody>
			{_(amtDays).times(n => (
				<DetailRow key={n} nDay={shiftDays[date(stDay).add(n, 'days').format('DD/MM/YYYY')]} day={date(stDay).add(n, 'days')} />
			))}
		</tbody>
	</table>
)

class Overzicht extends React.Component {
	UPD =
		this.props.settings['uren']['hvl_tijd'] && _.isNumber(this.props.settings['uren']['hvl_tijd'] / 5)
			? this.props.settings['uren']['hvl_tijd'] / 5
			: 7.6
	MINSALDO = 5 * this.UPD
	MAXSALDO = 20 * this.UPD

	constructor(props) {
		super(props)
		const week = jsUtils.getWholeWeek()
		const isPm = !!QueryString.parse(window.location.search).plus_min
		const isVerlof = !!QueryString.parse(window.location.search).verlof
		this.state = {
			teams: props.teams.map(team => team.id),
			users: [],
			vanaf: date().startOf('month').format('YYYY-MM-DD'),
			tot: date().endOf('month').format('YYYY-MM-DD'),
			detailUser: null,
			days: [],
			activeEnvs: props.orgEnvs.map(t => t.id),
			fetching: false,
			plusMinUsers: [],
			currPMUsers: [],
			anim_ready: false,
			modalV: false,
			plusMin: isPm,
			verlof: isVerlof,
			overzicht: !isPm && !isVerlof,
			verlofUsers: [],
			currVUsers: [],
		}
		this.onChange = this.onChange.bind(this)
		this.filterUsers = this.filterUsers.bind(this)
		this.calcTotal = this.calcTotal.bind(this)
		this.fetchPlusMin = this.fetchPlusMin.bind(this)
		this.filterSaldoUsers = this.filterSaldoUsers.bind(this)
	}

	componentDidMount() {
		this.onChange()
		const isPm = !!QueryString.parse(window.location.search).plus_min
		if (isPm) {
			this.fetchPlusMin()
		}
	}

	fixGhosts = (user, teams) => {
		const {envId} = this.props
		return user.environment_ids.includes(envId)
	}

	overzichtUsers = () => {
		const {users} = this.state
		const {actOrg, activeTeams, activeEnvs, envId} = this.props

		let filteredUsers = userFilter({
			users: users,
			mustBeInCurrent: true,
			orFunc: (user, teams) => user && !user.environment_ids.includes(envId),
		})
		return filteredUsers.map(u => {
			const n = {}
			let s = u.shifts.filter(s => activeTeams.length == 0 || activeTeams.includes(s.team_id))
			if (actOrg) s = s.filter(s1 => activeEnvs.length == 0 || activeEnvs.includes(s1.environment_id))
			const s_pl = s.filter(s1 => !s1.reg)
			const s_reg = s.filter(s1 => s1.reg && s1.status == 'productief')
			const s_improd = s.filter(s1 => s1.status == 'improductief')
			n.shifts_count = s_pl.length
			n.reg_count = s_reg.length + s_improd.length
			n.planned_time = s_pl.reduce((tally, sh) => tally + sh.total_mins, 0)
			n.reg_time = s_reg.reduce((tally, sh) => tally + sh.total_mins, 0)
			n.diff_time = n.reg_time - n.planned_time
			n.maaltijden = s_reg.reduce((tally, sh) => tally + sh.maaltijden, 0)
			n.improd_total = s_improd.reduce((tally, sh) => tally + sh.total_mins, 0)
			return {...n, ...u}
		})
	}

	filterUsers() {
		const {envId} = this.props
		const {users, plusMinUsers, plusMin, verlof, verlofUsers} = this.state
		var gebruikers = verlof ? verlofUsers : plusMin ? plusMinUsers : users
		if (verlof || plusMin) {
			return gebruikers
		}
		return userFilter({users: gebruikers, orFunc: (user, teams) => !user.environment_ids.includes(envId)})
	}

	filterSaldoUsers() {
		const {envId} = this.props
		const {currPMUsers, currVUsers, verlof} = this.state
		const users = verlof ? currVUsers : currPMUsers
		return users
		//return userFilter({users: users, orFunc: (user, teams) => !user.environment_ids.includes(envId) })
	}

	async fetchVerlofSaldo() {
		const {UPD, MAXSALDO, MINSALDO} = this
		const res = await backend.get(`/roosters/verlof_saldo.json`)
		const json = res.data
		if (res.ok && json && json.users) {
			const maxSaldoU = _.max(json.users, user => Math.abs(user.diff)) || {diff: 0}
			var maxSaldo = Math.round(Math.abs(maxSaldoU.diff) / 60)
			maxSaldo = Number((Math.round(maxSaldo / UPD) * UPD).toFixed(1)) + UPD
			if (_.isNaN(maxSaldo)) maxSaldo = MAXSALDO
			this.setState({currVUsers: json.users, max: maxSaldo > MAXSALDO ? MAXSALDO : maxSaldo < MINSALDO ? MINSALDO : maxSaldo})
			const that = this
			setTimeout(function () {
				that.setState({
					anim_ready: true,
				})
			}, 50)
		}
	}

	async fetchPlusMin() {
		const {UPD, MAXSALDO, MINSALDO} = this
		const res = await backend.get(`roosters/plus_min_saldo.json`)
		const json = res.data
		if (res.ok && json) {
			const maxSaldoU = _.max(json.users, user => Math.abs(user.diff)) || {diff: 0}

			var maxSaldo = Math.round(Math.abs(maxSaldoU.diff) / 60)
			maxSaldo = Number((Math.round(maxSaldo / UPD) * UPD).toFixed(1)) + UPD
			this.setState({currPMUsers: json.users, max: maxSaldo > MAXSALDO ? MAXSALDO : maxSaldo < MINSALDO ? MINSALDO : maxSaldo})
			const that = this
			setTimeout(function () {
				that.setState({
					anim_ready: true,
				})
			}, 50)
		}
	}

	scrubOverzicht = json => {
		const {users} = this.props
		const newUsers = json.users.map(u => {
			const u2 = users.find(u3 => u3.id == u.id)
			return {...u2, ...u}
		})
		this.setState({users: newUsers, vanaf: json.from, tot: json.till, fetching: false})
	}

	async onChange(_date = undefined, string = [this.state.vanaf, this.state.tot]) {
		const fromTill = string.map(s => date(s).format('YYYY-MM-DD'))
		this.setState({vanaf: fromTill[0], tot: fromTill[1], fetching: true})
		const {detailUser, plusMin, verlof} = this.state
		const endPoint = verlof ? 'verlof_saldo' : plusMin ? 'plus_min' : detailUser ? `users/${detailUser.id}/detail` : 'new_overzicht'
		let url = `roosters/${endPoint}.json`
		if (string[0] && string[1]) {
			url += `?vanaf=${string[0]}&tot=${string[1]}`
		}
		const res = await backend.get(url)
		if (res.ok && res?.data?.users) {
			const json = res.data

			verlof
				? this.setState({fetching: false, verlofUsers: json.users})
				: plusMin
				? this.setState({fetching: false, plusMinUsers: json.users})
				: detailUser
				? this.setState({days: json.days, fetching: false})
				: this.scrubOverzicht(json)
		} else {
			this.setState({fetching: false})
		}
	}

	calcTotal() {
		const users = this.overzichtUsers()
		var obj = {
			diff_time: 0,
			loonkosten: 0,
			planned_time: 0,
			shifts_count: 0,
			reg_time: 0,
			total_loon: 0,
			maaltijden: 0,
			loon_in_cents: 0,
			reg_count: 0,
			improd_total: 0,
		}
		users.forEach(
			u =>
				(obj = {
					diff_time: (obj.diff_time += u.diff_time),
					planned_time: (obj.planned_time += u.planned_time),
					shifts_count: (obj.shifts_count += u.shifts_count),
					reg_time: (obj.reg_time += u.reg_time),
					improd_total: (obj.improd_total += u.improd_total),
					loonkosten: (obj.loonkosten += u.loonkosten),
					reg_count: (obj.reg_count += u.reg_count),
					maaltijden: (obj.maaltijden += u.maaltijden || 0),
				}),
		)
		return obj
	}

	didUpdateChange() {
		const dateRange = [this.state.vanaf, this.state.tot]
		this.onChange(undefined, dateRange)
	}

	componentDidUpdate(prevProps, prevState) {
		const toPm = !prevState.plusMin && this.state.plusMin
		let toOverview = !prevState.overzicht && this.state.overzicht
		let toVerlof = !prevState.verlof && this.state.verlof

		if (prevProps.location.search !== this.props.location.search) {
			const search = QueryString.parse(this.props.location.search)
			if (search.plus_min) {
				this.setState({plusMin: true, overzicht: false, verlof: false, detailUser: false})
			} else if (search.verlof) {
				this.setState({verlof: true, plusMin: false, overzicht: false, detailUser: false})
			} else {
				this.setState({verlof: false, plusMin: false, overzicht: true, detailUser: false})
			}
		}

		if (toPm || toOverview) {
			this.didUpdateChange()
			this.fetchPlusMin()
		}

		if (toVerlof) {
			this.didUpdateChange()
			this.fetchVerlofSaldo()
		}
	}

	correctieModalOpen = user => {
		this.setState({
			modalV: true,
			correctieUser: user,
		})
	}

	disabledDate = _date => {
		const {saldoDate} = this.props
		const {verlof, plusMin} = this.state
		if (verlof || plusMin) {
			return _date < date(saldoDate)
		} else {
			return false
		}
	}

	init = () => {
		this.onChange()
		const {verlof} = this.state
		verlof ? this.fetchVerlofSaldo() : this.fetchPlusMin()
	}

	render() {
		const {UPD, MINSALDO, MAXSALDO} = this
		const {
			users,
			total,
			vanaf,
			tot,
			detailUser,
			days,
			fetching,
			plusMin,
			currPMUsers,
			currVUsers,
			anim_ready,
			modalV,
			correctieUser,
			max,
			verlof,
			nmbrsA,
			saldoModalV,
		} = this.state

		const {teams, possibleExports = [], activeTeams, settings, roles, saldoDate, actOrg, integration, taxonomy, orgEnvs, t} = this.props
		const {contractTypes, hasDatum, exportUitleg, exportOpties} = taxonomy

		const factor = 0.096
		const magAccorderen = roles['uren_accorderen']
		const isFinancieel = roles['financieel']
		const isOverzicht = !detailUser && !plusMin && !verlof
		const isV = verlof && !plusMin
		const isSaldo = verlof || plusMin
		const hasConnexie = settings['uren_reg']['koppeling'] == 'connexie'
		var hasUsers = this.filterUsers().length > 0
		const fromTill = [date(vanaf, serverFormat), date(tot, serverFormat)]
		headerTPM_SUB[0] = `${t('on')} ${date(vanaf).format('DD-MM-YYYY')}`
		headerTPM_SUB[headerTPM_SUB.length - 2] = `${t('on')} ${date(tot).format('DD-MM-YYYY')}`
		headerV_SUB[0] = `${t('on')} ${date(vanaf).format('DD-MM-YYYY')}`
		headerV_SUB[headerV_SUB.length - 1] = `${t('on')} ${date(tot).format('DD-MM-YYYY')}`

		const rangePicker = (
			<RangePicker
				value={fromTill}
				format={dateFormat}
				disabledDate={this.disabledDate}
				onChange={this.onChange}
				style={{float: 'left', marginRight: 4, width: 260}}
				allowClear={false}
			/>
		)

		const exportState = {
			start_date: vanaf,
			end_date: tot,
		}
		const integName = `${utils.capitalize(integration)}Upload`
		const exportLinks = [
			<ModalLink to={`/exports/hours`} t="export_hours" state={exportState} />,
			<ModalLink to={`/exports/labour_costs`} t="export_wage_cost" state={exportState} />,
			integration && (integration == 'nmbrs' || possibleExports.includes(integName)) && (
				<ModalLink to={`/static_exports/${integName}`} state={exportState} t={`export_${integration}`} />
			),
		].filter(Boolean)

		const header = (
			<div className="relative veetwee32" style={{height: 55}}>
				<div className="subHeaderv3">
					<div className="fRow aCen jSta">
						{detailUser && (
							<Button
								type="primary"
								onClick={() => this.setState({detailUser: null}, () => this.onChange(undefined, [vanaf, tot]))}
								className="floatL"
								style={{marginRight: 4, marginTop: 0}}
							>
								{t('backHours')}
							</Button>
						)}

						{!verlof && !plusMin && (
							<RangePicker
								value={fromTill}
								format={dateFormat}
								disabledDate={this.disabledDate}
								onChange={this.onChange}
								style={{marginRight: 4, width: 260}}
								allowClear={false}
							/>
						)}

						{!verlof && !plusMin && <TeamDropdown planningActive envOnly />}

						{(verlof || plusMin) && (
							<div className="startBalance fRow aSta jSta">
								<div className="textButton" style={{marginLeft: 4}}>
									<p>
										{t('on')}: {date().format('DD/MM/YYYY')}
									</p>
								</div>
							</div>
						)}
					</div>

					<div className="fRow aCen jEnd">
						{isOverzicht && (
							<Dropdown elements={exportLinks}>
								<Button type="primary"> {t('exportText')} </Button>
							</Dropdown>
						)}

						{(verlof || plusMin) && (
							<Fragment>
								<div className="startBalance fRow aCen jEnd">
									<div className="textButton">
										<p className="startBalance">
											{' '}
											{t('balanceStart')}: {saldoDate ? date(saldoDate).format('DD/MM/YYYY') : 'N/A'}{' '}
										</p>
									</div>

									<Button type="primary" onClick={() => this.setState({saldoModalV: true})}>
										{' '}
										{t('manageBalance')}
									</Button>
								</div>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		)

		return (
			<PageLayout name="rooster" headers={header} bodyLayoutProps={{block: true}}>
				<div className="noselect">
					<div className={`${(verlof || plusMin) && 'verlofContainer'}`}>
						<Spin style={{position: 'absolute', left: '50%', top: 60, zIndex: 3000}} spinning={fetching} />

						{detailUser ? (
							<OverzichtTableHeader first={detailUser.naam} uitleg={headerTD_EX} items={headerTitlesD} />
						) : isOverzicht ? (
							<OverzichtTableHeader items={headerTitlesO} uitleg={headerTO_EX} />
						) : undefined}
						{plusMin && (
							<Users
								startDate={saldoDate}
								max={max}
								UPD={UPD}
								saldo
								plusMinSaldo
								pmSaldo
								ready={anim_ready}
								users={this.filterSaldoUsers()}
							/>
						)}

						{isV && (
							<Users
								startDate={saldoDate}
								max={max}
								UPD={UPD}
								saldo
								plusMinSaldo
								vSaldo
								ready={anim_ready}
								users={this.filterSaldoUsers()}
							/>
						)}

						{hasUsers && !isOverzicht && !detailUser && (
							<div className="verlofVandaag" style={{borderTop: '1px solid #eee'}}>
								<RangePicker
									defaultValue={fromTill}
									format={dateFormat}
									disabledDate={this.disabledDate}
									onChange={this.onChange}
									style={{width: 260}}
									allowClear={false}
								/>
							</div>
						)}

						{hasUsers && plusMin && <OverzichtTableHeader items={headerTitlesPM} uitleg={headerTPM_EX} sub={headerTPM_SUB} />}
						{plusMin && <Users plusMin users={this.filterUsers()} correctieClick={this.correctieModalOpen} />}
						{hasUsers && verlof && !plusMin && <OverzichtTableHeader items={headerTitlesV} uitleg={headerV_EX} sub={headerV_SUB} />}
						{!hasUsers && !fetching && isSaldo && (
							<div className="noActShifts" style={{width: '100%', paddingLeft: 120}}>
								<h2>{t('boring')}</h2>
								<p>{t('noSaldi1')}</p>
								<p>{t('noSaldi2')}</p>
								<p>{t('noSaldi3')}</p>
							</div>
						)}

						{isV && <Users factor={factor} verlof users={this.filterUsers()} correctieClick={this.correctieModalOpen} />}

						{isOverzicht && (
							<Users
								onClick={user => {
									this.setState({detailUser: user}, () => this.onChange(undefined, [vanaf, tot]))
								}}
								total={this.calcTotal()}
								users={this.overzichtUsers()}
								overzicht
							/>
						)}

						{detailUser && (
							<DetailUser
								user={detailUser}
								shiftDays={days}
								amtDays={date(tot).diff(date(vanaf), 'd') + 1}
								stDay={date(vanaf, serverFormat)}
							/>
						)}

						{modalV && (
							<CorrectieModal
								typ={verlof ? 'verlof' : 'plus_min'}
								afterSuccess={() => this.onChange(undefined, [vanaf, tot])}
								user={correctieUser}
								onCancel={() => this.setState({modalV: false})}
							/>
						)}

						{saldoModalV && <SaldoModal afterSucc={() => this.init()} onCancel={() => this.setState({saldoModalV: false})} />}
					</div>
				</div>
			</PageLayout>
		)
	}
}

Overzicht = withTranslation()(Overzicht)

export default connect(state => ({
	user: state.auth.user,
	settings: getEnvSettings(state),
	users: all(state, 'users'),
	activeEnvs: state.entities.activeEnvs,
	activeTeams: state.entities.activeTeams,
	teams: inEnv(state, 'teams'),
	integration: getEnvSetting(state, 'uren_reg', 'koppeling'),
	possibleExports: state.taxonomy ? Object.keys(state.taxonomy.exports) : [],
	roles: legacyRolesSelector(state),
	actOrg: currentOrgSelector(state)?.id,
	orgEnvs: orgEnvsSelector(state),
	envId: state.environment.active,
	saldoDate: getEnvSetting(state, 'statistieken', 'vanaf'),
	taxonomy: state.settings.taxonomy,
}))(Overzicht)
