import * as Sentry from '@sentry/browser'
import ErrorView from 'pages/system/err'
import React, {Fragment} from 'react'
import {withRouter} from 'react-router-dom'

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log(error.stack)
		return {hasError: true, error: error.message, stack: error.stack}
	}

	componentDidUpdate(prevProps) {
		if (this.state.hasError && this.props.location.pathname != prevProps.location.pathname) {
			this.setState({hasError: false})
		}
	}

	componentDidCatch(error) {
		if (error.name === 'ChunkLoadError') {
			// Refresh the page
			window.location.reload()
			return
		}

		Sentry.captureException(error)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI

			return (
				<Fragment>
					<ErrorView />
				</Fragment>
			)
		}

		return this.props.children
	}
}

ErrorBoundary = withRouter(ErrorBoundary)
