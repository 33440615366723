import utils from '@eitje/web_utils'
import {ContractHours} from 'cores/leave/index'
import {useSimpleContext} from 'contexts/simple'
import {Audits} from 'components/shared/index'

export const LeftModalFooter = () => {
	let {formRecord, range, schedules = [], request = {}} = useSimpleContext()
	let item
	const {audits} = request

	if (utils.exists(formRecord)) {
		item = formRecord
	} else {
		item = request
		range = request.__dateRange()
	}

	const {kind, startDate, endDate} = item
	const hasRange = startDate().isValid() && (kind != 'multiple_days' || endDate().isValid())

	return (
		<>
			{item?.user_id && hasRange && schedules.length > 0 && <ContractHours range={range} record={item} placement="top" />}
			{audits && <Audits tableName="leave_request" audits={audits} placement="top" />}
		</>
	)
}
