import {useEnvSettingGroup} from 'hooks/use_env_setting'
import useParams from 'hooks/use_params'
import {ContextForm} from '@eitje/form'
import {EitjeModal} from 'components/common/index'
import {saveSettingGroup} from 'actions/settings'
import Body from './body/index'
import './styles/index.less'
import {EnvRouteSelect} from 'shared/components/env_route_select'

export const Settings = () => {
	const {envId} = useParams()
	const initialValues = useEnvSettingGroup('leave', envId)
	const name = 'leave_settings'

	return (
		<ContextForm
			transNamespace={name}
			fieldProps={{submitStrategy: 'throttledChange'}}
			onSubmit={data => saveSettings(data, envId)}
			initialValues={initialValues}
		>
			<EitjeModal width={650} RightHeaderProps={{envId}} name={name} RightHeader={RightHeader}>
				<Body />
			</EitjeModal>
		</ContextForm>
	)
}

const saveSettings = (data, envId) => {
	return saveSettingGroup('leave', data, {env_id: envId})
}

const RightHeader = ({envId}) => {
	return <EnvRouteSelect modal shouldTranslate={false} envId={envId} urlFormat="/leave/:envId/settings" />
}

export default Settings
