import {useShared} from 'hooks'

export const useOnboardingStatus = () => {
	const {org} = useShared()
	const {onboarding_taxonomy} = org

	// Below should never hit, but add for safety during migration from old to new UI. Can delete later.
	if (!onboarding_taxonomy) return false

	const steps = _.values(onboarding_taxonomy)
	const stepsAmt = steps.length
	const stepsCompletedAmt = steps.filter(Boolean).length
	const percentageCompleted = (stepsCompletedAmt / stepsAmt) * 100

	return {percentageCompleted, completed: onboarding_taxonomy}
}
