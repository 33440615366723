import {useEffect} from 'react'
import {ENV} from 'initializers/api'

export const useGlobalDebug = () => {
	useEffect(() => {
		if (ENV === 'production') {
			console.log = function () {}
			console.info = function () {}
			console.warn = function () {}
			console.error = function () {}
			console.group = function () {}
		}
	}, [])
}
