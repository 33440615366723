import {Select, Tooltip} from 'antd'
import {UserImage} from 'components/ui'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {composedContractSelector} from 'selectors/legacy'
import 'styles/legacy/ant_overwrite.scoped.css'
import * as jsUtils from 'utils/jsutils'

const Option = Select.Option

export const statusMapBG = {aangevraagd: '#EDEFFF', opgenomen: 'white', conflict: '#FFE8E9', ingepland: '#fff'}
export const statusMapText = {
	partly: 'orange',
	unavailable: 'red',
	aangevraagd: '#9013FE',
	opgenomen: '#9013FE',
	conflict: '#ff0020',
	ingepland: '#0496ff',
}

export const determineBgColor = (obj, isAv, isPart) => {
	if (obj) {
		return statusMapBG[obj.status2] || statusMapBG[obj.status1]
	}
	return isAv ? 'white' : isPart ? 'white' : 'white'
}

export const Users = props => {
	const isSaldo = props.pmSaldo || props.vSaldo
	return (
		<div>
			<table
				className={`noselect borderTop Table ${isSaldo && 'borderBot'} ${!props.plusMin && !isSaldo && 'beschikbaarheidTD'}`}
				style={{marginBottom: isSaldo ? 40 : 28, marginTop: isSaldo ? -1 : -1}}
			>
				{_.sortBy(props.users, 'full_name').map(user => {
					// var prefShifts = user[props.activeYear] && user[props.activeYear][props.activeWeek]['pref_shifts']
					const firstUser = props.users.indexOf(user) == 0
					const lastUser = props.users.indexOf(user) == props.users.length - 1
					return (
						<tbody>
							{props.verlof ? (
								<VerlofUser {...props} picture user={user} />
							) : props.plusMinSaldo ? (
								<PMSaldoUser {...props} lastUser={lastUser} user={user} firstUser={firstUser} currentUser={props.user} />
							) : props.plusMin ? (
								<PlusMinUser {...props} user={user} picture currentUser={props.user} />
							) : (
								<OverzichtUser {...props} picture user={user} currentUser={props.user} />
							)}
						</tbody>
					)
				})}
				{props.total && (
					<tbody>
						{' '}
						<OverzichtUser totaal user={{...props.total, naam: `${t('total')}`}} />{' '}
					</tbody>
				)}
			</table>
		</div>
	)
}

const PMSaldoUser = ({user, ready, max, UPD, firstUser, lastUser}) => (
	<tr className={`saldoRow plusMin`}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					<UserImage user={user} divClassName="avaBGSmall" />
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard breakWord">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.diff >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.diff)}
			</div>{' '}
		</td>
		<td className="saldoGraph">
			{' '}
			<SaldoGraphUser lastUser={lastUser} firstUser={firstUser} max={max} UPD={UPD} ready={ready} user={user} />
		</td>
	</tr>
)

const pmFormula = (hours, max) => {
	const maxU = max
	const maxW = 89.4

	const width = hours * (maxW / maxU)
	return width > maxW ? maxW + 3 : width
}

const SaldoGraphUser = ({user, ready, max, UPD, firstUser, lastUser}) => {
	// uitdaging is om de user-lijntjes zo breed te maken als 't aantal uur // 49.3 % is het magische getal
	// daarbijhorende uitdaging is om het balkje ook altijd zo te positioneren dat-ie vanuit 't midden loopt
	if (!max) max = UPD * 10
	const cont = useSelector(state => composedContractSelector(state, user.id))
	const dataString = `${jsUtils.signedMinTTS(user.diff)} ${t('hoursS')} / ${(user.diff / 60 / UPD).toFixed(1)} ${t('days')}`
	if (!cont) return null

	const tooltipText =
		cont.typ == 'Uren contract' || cont.typ == 'Vaste dienst'
			? `${t('contractHover1')} ${cont.uren} ${t('contractHover2')} ${cont.amt_werkdagen} ${t('days')}. ${dataString}`
			: `Nuluren contract. ${dataString}`
	const color = user.diff < 0 ? '#FC5764' : '#0496FF'
	const uren = user.diff / 60
	var left
	var width = 0
	width = !ready ? 0 : pmFormula(Math.abs(user.diff / 60), max, UPD)
	const amtLijntjes = Math.round(max / UPD) + 1
	const lijntjesLeftInc = 87.2 / (amtLijntjes - 1)
	const lines =
		firstUser || lastUser
			? _(amtLijntjes).times(i => (
					<div className="pmLijntje" style={{left: `${2.2 + i * lijntjesLeftInc}%`}}>
						{lastUser && <span style={{fontSize: 8, cursor: 'default', position: 'relative', top: 39, left: -4}}> {i} </span>}
					</div>
			  ))
			: _(amtLijntjes).times(i => <div className="pmLijntje" style={{left: `${2.2 + i * lijntjesLeftInc}%`}}></div>)
	const imgLeft = amtLijntjes == 5 ? 2.5 : 2.5 - (amtLijntjes - 5) * 0.25
	return (
		<div>
			{lines}
			<Tooltip placement="topRight" title={tooltipText}>
				{' '}
				<div
					className="saldoLijntje"
					style={{
						height: 8,
						left: 0,
						position: 'relative',
						width: `${width}%`,
						left: '2.2%',
						top: 16,
						backgroundColor: `${color}`,
						border: `1px solid ${color}`,
						transition: 'width 2s',
					}}
				>
					{' '}
				</div>{' '}
			</Tooltip>
		</div>
	)
}

const VerlofUser = ({user, onClick, picture, correctieClick, factor}) => (
	<tr className={'tablebody plusMin'}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.verlof_saldo_start >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.verlof_saldo_start)}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.opgebouwd)} </div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.opgenomen)} </div>{' '}
		</td>
		<td onClick={() => correctieClick(user)}>
			{' '}
			<div className="cardUserRooster detailsCard correcties">{jsUtils.signedMinTTS(user.correcties)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.signedMinTTS(user.diff)}</div>{' '}
		</td>

		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.verlof_saldo_end >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.verlof_saldo_end)}
			</div>{' '}
		</td>
	</tr>
)

const PlusMinUser = ({user, totaal, onClick, picture, correctieClick}) => (
	<tr className={'tablebody ' + (totaal && 'total') + ' plusMin'}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.pm_saldo_start >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.pm_saldo_start)}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.contract_mins)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.total_mins)} </div>{' '}
		</td>
		<td onClick={() => correctieClick(user)}>
			{' '}
			<div className="cardUserRooster detailsCard correcties">{jsUtils.signedMinTTS(user.correcties)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.signedMinTTS(user.diff)}</div>{' '}
		</td>

		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.pm_saldo_end >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.pm_saldo_end)}
			</div>{' '}
		</td>
	</tr>
)

const OverzichtUser = ({user, totaal, onClick = _.noop, picture}) => (
	<tr onClick={() => onClick(user)} className={'tablebody ' + (totaal && 'total')}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.full_name || user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">
				{user.shifts_count} / {user.reg_count}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.planned_time)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.reg_time)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">
				{user.diff_time > 0 ? '+' : '-'}
				{jsUtils.minToTimeString(Math.abs(user.diff_time))}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{user.maaltijden}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.improd_total)}</div>{' '}
		</td>
	</tr>
)
