const scrollToEl = sel => {
	const el = document.querySelector(sel)
	el?.scrollIntoView({behavior: 'smooth'})
}

export const scrollDelayed = sel => {
	const fn = () => scrollToEl(sel)
	setTimeout(fn, 50)
}

export const scrollElIntoViewParent = (parentRef, childRef) => {
	const parent = parentRef instanceof Node ? parentRef : parentRef?.current
	const child = childRef?.current

	if (!parent || !child) return

	const parentRect = parent.getBoundingClientRect()
	const childRect = child.getBoundingClientRect()

	if (childRect.top < parentRect.top) {
		// Child is scrolled too high
		parent.scrollTop -= parentRect.top - childRect.top
	} else if (childRect.bottom > parentRect.bottom) {
		// Child is scrolled too low
		parent.scrollTop += childRect.bottom - parentRect.bottom
	}
}
