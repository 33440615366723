import {t} from 'initializers/i18n'
import {useDrop} from 'hooks'
import './styles/index.less'
import {DeleteButton} from '../eitje_button/eitje_buttons'

export const DeleteDropZone = ({onDrop, type, accept}) => {
	const {dragItem, dropRef, isOver} = useDrop({
		accept,
		onDrop,
	})

	if (!dragItem || dragItem.type !== accept) return null

	return (
		<div ref={dropRef}>
			<DeleteButton colorSet="solid" className="delete-dropzone" active={isOver}>
				{t('common.drop_to_delete', {type})}
			</DeleteButton>
		</div>
	)
}
