import React from 'react'
import {useWhere} from '@eitje/easy_api'
import {RevenueGroup, RevenueDay} from 'models/index'
import RevenueFooter from 'components/shared/revenue_footer/index'
import {useNonPlanShifts, usePerWeekContext} from 'cores/time_registration/index'
import {useFilterFooterContext} from 'common/components'

const Index = () => {
	const {envs} = useFilterFooterContext()
	return (
		<div className="revenue-footers">
			{envs.map(e => (
				<RevenueFooterWrapper env={e} />
			))}
		</div>
	)
}

const RevenueFooterWrapper = ({env}) => {
	const {dateRange, dateArray} = usePerWeekContext()
	const revenueGroups = RevenueGroup.where({environment_id: env.id})
	const days = RevenueDay.where({omzet_groep_id: revenueGroups.map(r => r.id), date: dateRange}).map(r => ({...r, amt: r.amt()}))
	const nonPlanShifts = useNonPlanShifts()
	const teams = useWhere('teams', {environment_id: env.id})
	const shifts = nonPlanShifts.where({team_id: teams.ids()}).filter(s => s.relevantForWage())

	return (
		<RevenueFooter
			featureName="time_registration"
			days={days}
			env={env}
			shifts={shifts}
			dateArray={dateArray}
			dateRange={dateRange}
			localName="timeRegRevenue"
		/>
	)
}

export default React.memo(Index)
