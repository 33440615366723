import React, {useState} from 'react'
import {DropdownPicker} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import utils from '@eitje/web_utils'
import {history} from 'index'
import {RevenueGroup} from 'models/index'
import {ContextForm} from '@eitje/form'
import FileDragAndDrop from 'components/drag_and_drop'
import {_upload} from 'actions/files'
import {FormSubmitButton} from 'components/form'
import {InboxOutlined} from '@ant-design/icons'
import {BackButton, EitjeButton} from 'common/components'
import {useLocation} from 'react-router-dom'
import './styles/index.less'

const kinds = ['forecast', 'actual']

const Import = ({envId}) => {
	const [file, setFile] = useState(null)

	const groups = RevenueGroup.where({environment_id: envId})
	const submit = async data => {
		const res = await _upload(file, {url: 'imports/revenue', extraParams: {env_id: envId, ...data}, doLoad: true})
		if (res.ok) {
			utils.successNotif({
				title: t('revenue.revenue_modal.importing.title'),
				content: t('revenue.revenue_modal.importing.body'),
			})
		}
		return res
	}

	const loc = useLocation()
	const isPlanning = loc.state?.background.pathname.includes('planning')

	let initialValues = {kind: isPlanning ? 'forecast' : 'actual'}
	if (groups.length == 1) {
		initialValues['revenue_group_id'] = groups[0].id
	}

	return (
		<div className="revenue-data-import">
			<ContextForm
				transNamespace="import_rev_group"
				className="eitje-form-2-use-borders"
				afterSubmit={history.goBack}
				initialValues={initialValues}
				onSubmit={submit}
			>
				<div className="revenue-modal-body">
					<FormRow>
						<DropdownPicker required field="kind" items={kinds} />
					</FormRow>
					<FormRow>
						<DropdownPicker required field="revenue_group_id" labelField="naam" items={groups} hideClearIcon />
					</FormRow>
					<FormRow>
						{/* replicate form classes for CSS, while on the JS side this actually is no part of the form lib (yet) */}
						<div className="eitje-form-2-field eitje-form-2-field-upload_xlsx">
							<FileDragAndDrop
								custom
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								Component={ExcelDropbox}
								onDrop={setFile}
							/>

							<EitjeButton
								className="revenue-modal-download-button"
								height="small"
								externalLink="https://eitje-prod.s3-eu-west-1.amazonaws.com/own/eitje_example_revenue_import.xlsx"
								t="revenue.revenue_modal.download_example_file"
							/>
						</div>
					</FormRow>
				</div>

				<div className="revenue-modal-footer">
					<div className="footer-buttons-container">
						<BackButton />
						{file && <FormSubmitButton />}
					</div>
				</div>
			</ContextForm>
		</div>
	)
}

const ExcelDropbox = ({isDragActive = false, ...rest}) => {
	const containerClass = utils.makeCns(' eitje-form-2-field-title-upload-container', isDragActive && 'active')
	return (
		<div className={containerClass} {...rest}>
			{rest.children}
			<InboxOutlined className="eitje-form-2-field-title-upload-icon" />
			<div className="eitje-form-2-field-text-container">
				<h4 className="eitje-form-2-field-title">{t('revenue.revenue_modal.import_revenue.title')}</h4>
				<h5 className="eitje-form-2-field-text">{t('revenue.revenue_modal.import_revenue.subtitle')}</h5>
			</div>
		</div>
	)
}

export default Import
