import {t} from 'initializers/i18n'
import {useAvContext} from 'contexts/availability'
import Day from './day'
import {useEnvContext} from '../contexts/env'
import {ModalLink} from 'components/routing'
import '../../styles/availability.less'
import './day.less'

const DaysHeader = () => {
	return (
		<div className="users-availability-table-row days-header-row">
			<InfoBox />
			<Days />
		</div>
	)
}

const Days = () => {
	const {dates} = useAvContext()
	return (
		<>
			{dates.map(d => (
				<Day date={d} />
			))}
		</>
	)
}

const InfoBox = () => {
	const {
		openRange: {start = {}, end = {}},
		env,
		settings,
	} = useEnvContext()

	const unsetRange = !start?._isValid && !end?._isValid
	if (unsetRange) {
		return (
			<ModalLink to={`/availability/${env.id}/settings`} className="day-header info-box">
				<div className="row">
					<img src="/images/web/icons/infoIcon.png" width="16" height="16" />
					<p className="day-header-title">{t('avOpenRangePlaceholder')}</p>
				</div>
			</ModalLink>
		)
	}

	return (
		<ModalLink to={`/availability/${env.id}/settings/period`} className="day-header info-box">
			<InfoTimeBox dateObj={start} text={'openFrom'} />
			{!!settings.max_periods_future && <InfoTimeBox dateObj={end} text={'closedAfter'} />}
		</ModalLink>
	)
}

const InfoTimeBox = ({text, dateObj = {}}) => {
	if (!dateObj._isValid) return null
	return (
		<div className="day-header-date-box">
			<p className="day-header-date-box-title">{t(text)}</p>
			<p className="day-header-date-box-date">{dateObj.format(`DD MMM 'YY`)}</p>
		</div>
	)
}

export default DaysHeader
