import {AuthPageLayout} from 'cores/auth'
import {PasswordInput, PasswordConfirmInput} from 'lib/form/fields'
import {useQueryParams} from 'hooks'
import {backend} from '@eitje/easy_api'
import {navigate} from 'components/routing'
import {t} from 'initializers/i18n'

export const ResetPassword = () => {
	const {reset_token} = useQueryParams()

	const onSubmit = data => {
		return backend.put(`/auth/password`, {
			...data,
			reset_password_token: reset_token,
			skipSuccessNotif: true,
		})
	}

	const afterSubmit = () => navigate('/') // redirect to start of login flow
	const subtitle = t('auth.forms.password_requirements')

	return (
		<AuthPageLayout
			name="reset_password"
			onSubmit={onSubmit}
			afterSubmit={afterSubmit}
			submitButtonProps={{iconLeft: 'locked', t: 'common.save'}}
			rawSubtitle={subtitle}
		>
			<PasswordInput />
			<PasswordConfirmInput />
		</AuthPageLayout>
	)
}
