import {date} from 'initializers/date'
import useCountup from 'hooks/use_countup'

const useCountdown = arg => {
	useCountup()
	const secondsToEnd = date(arg).diff(date(), 'seconds')
	return secondsToEnd
}

export default useCountdown
