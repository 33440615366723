import {useRoleOrgEnvs} from 'hooks'
import User from 'models/user'
import {useFilterFooterContext} from 'common/components/filter_footer/context'

export const useGetPreferredEnv = ({user, user_id}) => {
	const userById = User.find(user_id)
	const _user = user || userById
	const managerEnvIds = useRoleOrgEnvs('manager').ids()
	const {
		hasFiltered,
		filterValues: {teamIds, environmentIds},
	} = useFilterFooterContext()
	const envIds = hasFiltered.environment ? environmentIds : []
	const teams = hasFiltered.team ? teamIds : []

	if (!hasFiltered.environment && !hasFiltered.team) return

	if (_user?.id) {
		teams.filter(team => _user.team_ids.includes(team.id))
	}

	const teamEnvIds = teams._map('environment_id')

	let filteredEnvIds = [...envIds, ...teamEnvIds].uniq()

	if (_user?.id) {
		filteredEnvIds = _.intersection(filteredEnvIds, _user.environment_ids)
	}

	filteredEnvIds = filteredEnvIds.filter(envId => managerEnvIds.includes(envId))

	if (filteredEnvIds.length === 1) return filteredEnvIds[0]
}
