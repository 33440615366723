import {Fragment} from 'react'
import utils from '@eitje/web_utils'
import {makeCnVariants} from 'helpers'
import {Layout, usePageContext} from 'common/components'
import {AdvancedTableCell} from 'common/components/advanced_table'
import './styles/index.less'

// Can't memo the TableRow, it breaks the pinning functionality.

export const TableRow = ({row, RowWrapper, onRowClick = _.noop, ...rest}) => {
	// For fuck sakes: put back this unused hook to ensure renders, without this hook, apparently the moving left and right
	// of columns break (only the header cells will still move)
	const render = usePageContext()

	const {getIsGrouped, getVisibleCells, getIsSelected, getCanExpand} = row
	// A grouped row should never navigate on click, since A) the click should open/ close the group and B) it makes
	// no sense: to which of its group members' link should it navigate? Default to Fragment if there should be no navigation.
	const Wrapper = RowWrapper && !getIsGrouped() ? RowWrapper : Fragment

	const cells = getVisibleCells()
	const canExpand = getCanExpand()
	const classNames = utils.makeCns(
		makeCnVariants('advanced-table-row', canExpand && 'expandable'),
		(getIsSelected() || row.getIsAllSubRowsSelected()) && 'active',
	)
	const _onClick = canExpand ? row.getToggleExpandedHandler() : () => onRowClick(row.original)

	return (
		<Wrapper row={row}>
			<Layout onClick={_onClick} className={classNames} key={row.id} colorSet height={32} gap={0}>
				{cells.map((c, i) => (
					<AdvancedTableCell {...rest} index={i} row={row} cell={c} />
				))}
			</Layout>
		</Wrapper>
	)
}
