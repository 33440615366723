import {backend, find} from '@eitje/easy_api'
import {DatePicker, InputNumber, Modal, Select, Spin} from 'antd'
import {UserImage} from 'components/ui'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {envUsers} from 'selectors/entities'
import {composedContractSelector} from 'selectors/legacy'
import {getEnvSetting} from 'selectors/settings'
import * as jsUtils from 'utils/jsutils'
const Option = Select.Option

const getHours = amt => {
	if (!amt) return null
	let realAmt = Math.abs(amt)
	const mins = realAmt % 60
	return (realAmt - mins) / 60
}

const PositiveSelect = ({val, onChange}) => (
	<Select value={val} style={{width: 60}} onChange={onChange}>
		<Option value={true}>+</Option>
		<Option value={false}>-</Option>
	</Select>
)

const getMins = (amt = 0) => (amt ? Math.abs(amt) % 60 : undefined)

const contString = cont => {
	return `${t('from')} ${date(cont.start_date).format('DD-MM-YYYY')}
   ${cont.end_date ? `${t('till')} ${date(cont.end_date).format('DD-MM-YYYY')}` : t('forever')}`
}

const _SaldoRow = ({user, pmValue = {}, onPosChange, verlofValue = {}, cont, onChange, envId, nulUrenV}) => {
	const hasCont = cont && cont.environment_id === envId
	const hasPm = cont && !!cont.uren
	const hasVerlof = hasPm || (cont.id && nulUrenV)
	const hasPosPM = Object.keys(pmValue).includes('positive')
	const hasPosV = Object.keys(verlofValue).includes('positive')
	return (
		<div className="centerFlex startSaldiInput" style={{paddingBottom: 4}}>
			<div className="textLeft startFlex" style={{width: '33%'}}>
				<UserImage user={user} divClassName="avaBG" />
				<div style={{marginLeft: 8}}>
					<p>{user.full_name}</p>
					{hasCont ? (
						<span>
							{t(cont.typ)} {contString(cont)}{' '}
						</span>
					) : (
						<span>{t('noContract')}</span>
					)}
				</div>
			</div>

			<div className="centerFlex" style={{justifyContent: 'center', width: '33%'}}>
				<PositiveSelect className="inputMargin" val={hasPosPM ? pmValue.positive : true} onChange={p => onPosChange(p, user.id, 'pm')} />
				<InputNumber
					className="inputMargin"
					style={{width: 80}}
					min={0}
					value={pmValue.hours}
					disabled={!hasPm}
					placeholder={t('hoursS')}
					onChange={val => onChange(val, user.id, {hours: true, kind: 'pm'})}
				/>
				<InputNumber
					className="inputMargin"
					style={{width: 80}}
					max={59}
					min={0}
					value={pmValue.mins}
					disabled={!hasPm}
					placeholder={t('minS')}
					onChange={val => onChange(val, user.id, {kind: 'pm'})}
				/>
			</div>

			<div className="centerFlex" style={{justifyContent: 'center', width: '33%'}}>
				<PositiveSelect className="" val={hasPosV ? verlofValue.positive : true} onChange={p => onPosChange(p, user.id, 'verlof')} />
				<InputNumber
					className="inputMargin"
					style={{width: 80}}
					min={0}
					value={verlofValue.hours}
					disabled={!hasVerlof}
					placeholder={t('hoursS')}
					onChange={val => onChange(val, user.id, {hours: true, kind: 'verlof'})}
				/>
				<InputNumber
					className="inputMargin"
					style={{width: 80}}
					max={59}
					min={0}
					value={verlofValue.mins}
					disabled={!hasVerlof}
					placeholder={t('minS')}
					onChange={val => onChange(val, user.id, {kind: 'verlof'})}
				/>
			</div>
		</div>
	)
}

const SaldoRow = connect((state, ownProps) => ({
	cont: composedContractSelector(state, ownProps.user.id),
	envId: state.environment.active,
	nulUrenV: find(state, 'employmentTypes', {name: 'Nul uren', leave_registration: 'worked_hours'})?.id,
}))(_SaldoRow)

const combineToMins = map => {
	return _.mapValues(map, (v, k) => {
		const mod = Object.keys(v).includes('positive') && !v.positive ? -1 : 1
		return mod * (v.hours || 0) * 60 + mod * (v.mins || 0)
	})
}

const toMap = arr => {
	const obj = {}
	arr.forEach(i => {
		obj[i.user_id] = {hours: getHours(i.amt), mins: getMins(i.amt), positive: i.amt > 0}
	})
	return obj
}

class StartSaldoModal extends Component {
	state = {
		verlofMap: {},
		pmMap: {},
	}

	componentDidMount() {
		this.fetch()
	}

	fetch = async () => {
		const res = await backend.get(`roosters/start_saldo`)
		if (res.ok) {
			const json = res.data
			const vMap = toMap(json.verlof)
			const pMap = toMap(json.plus_min)
			this.setState({fetched: true, verlofMap: vMap, pmMap: pMap, origVMap: vMap, origPMap: pMap})
		}
	}

	submit = async () => {
		const {verlofMap, pmMap, newSaldoDate} = this.state
		if (newSaldoDate) {
			await jsUtils.updateEnvSetting({statistieken: {vanaf: newSaldoDate.format('YYYY-MM-DD')}})
		}
		const res = await backend.post(`roosters/start_saldo`, {
			verlof: combineToMins(verlofMap),
			plus_min: combineToMins(pmMap),
		})
		this.props.onCancel()
		this.props.afterSucc()
	}

	onChange = (val, userId, params) => {
		const {hours, kind} = params
		const km = kind + 'Map'
		const map = {...this.state[km]}
		if (!map[userId]) map[userId] = {}
		map[userId] = hours
			? {hours: val, mins: map[userId].mins || 0, positive: Object.keys(map[userId]).includes('positive') ? map[userId].positive : true}
			: {hours: map[userId].hours || 0, mins: val, positive: Object.keys(map[userId]).includes('positive') ? map[userId].positive : true}
		this.setState({[km]: map})
	}

	onPosChange = (val, userId, kind) => {
		const km = kind + 'Map'
		const map = {...this.state[km]}
		if (!map[userId]) map[userId] = {}
		map[userId] = {...map[userId], positive: val}
		this.setState({[km]: map})
	}

	render() {
		const {users, saldoDate, onCancel} = this.props
		const {fetched, verlofMap, pmMap, newSaldoDate} = this.state
		const sDate = date(saldoDate)
		const currSaldoDate = newSaldoDate || sDate

		return (
			<Modal
				width={900}
				title={t('insertStartSaldi')}
				className="noselect modalHuge"
				visible
				onOk={() => this.submit()}
				onCancel={onCancel}
			>
				<div className="default-modal-padding">
					<div className="centerFlex startSaldi borderBot" style={{padding: 24, marginBottom: 12}}>
						<div className="">
							<p>{t('startDateSaldi')}</p>
							<span>{t('startDateSaldi1')}.</span>
							<span>{t('startDateSaldi2')}.</span>
							<a
								style={{cursor: 'pointer'}}
								target="_blank"
								href="https://intercom.help/eitje/nl/articles/6019469-instellen-van-startsaldo"
							>
								<span style={{color: '#0496ff', fontStyle: 'italic', cursor: 'pointer'}}>{t('startDateSaldi3')}.</span>
							</a>
						</div>

						<div className="">
							<DatePicker allowClear={false} format="DD/MM/YYYY" onChange={v => this.setState({newSaldoDate: v})} value={currSaldoDate} />
						</div>
					</div>

					<div className="startSaldiInput">
						<div className="center centerFlex" style={{paddingBottom: 4}}>
							<div style={{width: '33%'}} />

							<div style={{width: '33%'}}>
								<p>{t('overtime')}</p>
								<span>
									{t('on')}: {currSaldoDate.format('DD/MM/YYYY')}
								</span>
							</div>
							<div style={{width: '33%'}}>
								<p>{t('leaveSaldi')}</p>
								<span>
									{t('on')}: {currSaldoDate.format('DD/MM/YYYY')}
								</span>
							</div>
						</div>
					</div>

					<Spin style={{position: 'absolute', top: '50%', left: '50%', width: 24, height: 24, zIndex: 1000}} spinning={!fetched} />
					{users.map(u => (
						<SaldoRow
							onPosChange={this.onPosChange}
							pmValue={pmMap[u.id]}
							verlofValue={verlofMap[u.id]}
							onChange={this.onChange}
							user={u}
						/>
					))}
				</div>
			</Modal>
		)
	}
}

export default connect(state => ({
	users: envUsers(state),
	saldoDate: getEnvSetting(state, 'statistieken', 'vanaf'),
}))(StartSaldoModal)
