import {Icon} from '@eitje/web_components'
import {Layout, Text, Title} from 'common/components'
import {useDocsLink} from 'hooks'
import './index.less'

const GROUP_WIDTH = 800
const GAP = 12
const ITEM_WIDTH = (GROUP_WIDTH - 2 * GAP) / 3

export const SupportCollections = ({items, title, headerButtons}) => {
	return (
		<Layout className="support-collections" gap={8} direction="vertical">
			<Layout horizontal="spaceBetween" padding="- - 8 -" margin="64 - - -" borderBottom>
				<Title t={title} fontSize={24} />
				{headerButtons && <Layout children={headerButtons} />}
			</Layout>
			<Layout childrenPerRow={3} width={GROUP_WIDTH} gap={GAP}>
				{items.map(item => (
					<SupportCard {...item} />
				))}
			</Layout>
		</Layout>
	)
}

const SupportCard = ({name, collection, article, icon, extLink}) => {
	const ns = `support.collections.${name}`
	const formattedKey = name.replaceAll('_', '-')
	const analyticsId = `${formattedKey}-support-card`

	// Can either be a link to: 1. docs collection, 2. docs article, or 3. an external link.
	let props

	if (extLink) {
		props = {extLink}
	} else if (collection) {
		const docs = useDocsLink({to: collection, collection: true})
		props = {extLink: docs.externalLink}
	} else if (article) {
		const docs = useDocsLink({to: article})
		props = {...docs}
	}

	return (
		<Layout
			className="support-collection"
			id={analyticsId}
			horizontal="center"
			height={140}
			width={ITEM_WIDTH}
			padding="- 24"
			type="card"
			direction="vertical"
			vertical="center"
			baseColor="blue"
			colorSet
			gap={10}
			{...props}
		>
			<Icon name={icon} fill={Colors.mediumBlue} />
			<Layout direction="vertical" horizontal="center" gap={0}>
				<Text t={`${ns}.title`} bold />
				<Text t={`${ns}.text`} darkGrey center />
			</Layout>
		</Layout>
	)
}
