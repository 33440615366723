import {Layout} from 'common/components'
import {getLanguage} from 'initializers/i18n'
import {useEffect, useRef} from 'react'
import './index.less'

const DISABLE = 0
const ENABLE = 1

export const YouTube = ({autoPlay, id, ...rest}) => {
	const playerRef = useRef(null)
	const playerInstanceRef = useRef(null)
	const locale = getLanguage()

	if (!id) return null

	const loadYouTubeIframeAPI = () => {
		if (typeof window === 'undefined') return

		if (window.YT && window.YT.Player) {
			initializePlayer()
			return
		}

		const tag = document.createElement('script')
		tag.src = 'https://www.youtube.com/iframe_api'
		const firstScriptTag = document.getElementsByTagName('script')[0]
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

		window.onYouTubeIframeAPIReady = initializePlayer // This function is called by the YouTube API once it's ready
	}

	const initializePlayer = () => {
		// Clean up any existing player instance before creating a new one
		if (playerInstanceRef.current) {
			playerInstanceRef.current.destroy()
		}

		playerInstanceRef.current = new window.YT.Player(playerRef.current, {
			width: '100%',
			height: '100%',
			videoId: id,
			playerVars: {
				autoplay: autoPlay && ENABLE,
				modestbranding: ENABLE, // Shows bare minumum youtube related content in the embedded window
				cc_load_policy: locale === 'nl' ? DISABLE : ENABLE, // Turns captions on by default
				cc_lang_pref: locale, //  Sets the language for the captions shown in the video
				loop: autoPlay && ENABLE,
				playlist: autoPlay && id, // For the loop to work, the playlist should be equal to the current video id
			},
		})
	}

	useEffect(() => {
		loadYouTubeIframeAPI()

		// Cleanup function to destroy player instance on component unmount or before reinitializing
		return () => {
			if (playerInstanceRef.current) {
				playerInstanceRef.current.destroy()
			}
		}
	}, [id, locale, autoPlay])

	return (
		<Layout className="youtube" width="100%" style={{backgroundSize: '100% auto'}} {...rest}>
			<div id="player" ref={playerRef}></div>
		</Layout>
	)
}
