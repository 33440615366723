import {flexRender} from '@tanstack/react-table'
import {Layout} from 'common/components'

export const BaseCollapsibleCell = ({Component, cell, children, extraProps, style}) => {
	const {getContext} = cell
	const props = {...extraProps, ...getContext()}

	return (
		<Layout grow style={style}>
			{flexRender(Component, props)}
			{children}
		</Layout>
	)
}
