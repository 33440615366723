import {ModalLink} from 'components/routing'
import {t, approveShifts} from 'cores/time_registration/index'
import {MultiAction as BaseMultiAction} from 'components/common/index'
import {SubmitPopout} from 'common/components'

export const MultiAction = ({selectedShifts, close}) => {
	const count = selectedShifts.length
	const title = count > 0 ? 'multi_action.has_selected' : 'multi_action.select_to_edit'
	const ids = selectedShifts.map(s => s.id)

	const _onConfirm = async () => {
		const res = await approveShifts(selectedShifts)
		if (res.ok) {
			close()
		}
		return res
	}

	const elements = [
		<ModalLink t="time_registration.edit" to={`/time_registration/multi_edit?ids=${ids}`} />,
		<SubmitPopout onSubmit={_onConfirm} text={t('approve_are_you_sure', {count})}>
			{t('approve')}
		</SubmitPopout>,
	]

	return <BaseMultiAction count={count} elements={elements} close={close} title={t(title, {count})} />
}
