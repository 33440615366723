import {formatTranslation} from 'cores/exports2/index'
import {useList} from 'hooks'
import {DeleteCross} from 'components/common'
import {ListPicker} from 'common/components/list_picker'
import {Layout, Text} from 'common/components'
import './styles/table.less'

export const Table = props => {
	const {selectedColumns, setColumns} = props
	const {list} = useList({
		items: selectedColumns,
		draggable: true,
		onDragEnd: setColumns,
		ListItem: Column,
		itemProps: props,
		direction: 'horizontal',
	})

	return <div className="layout-preview-table">{list}</div>
}

const Column = ({item, setColumnInfo, selectedColumns, removeColumn}) => {
	const {name, example, format, examples} = item

	const setFormat = format => {
		setColumnInfo({...item, format})
	}

	const Trigger = () => <Text truncate>{name}</Text>
	const exampleValue = examples.find(e => e.id == format)?.name || example

	return (
		<div className="table-column">
			<Layout className="table-header-cell">
				<ListPicker raw buildLabel={FormatLabel} Trigger={Trigger} dropdown single value={format} items={examples} onChange={setFormat} />

				{selectedColumns?.length > 1 && <DeleteCross confirm={false} onDel={() => removeColumn(item.id)} color="black" />}
			</Layout>
			<p className="table-row-cell"> {exampleValue} </p>
		</div>
	)
}

const FormatLabel = (label, {id}) => {
	return (
		<div>
			<h4 className="unset-text-transform"> {label} </h4>
			<h5>{formatTranslation(id)} </h5>
		</div>
	)
}
