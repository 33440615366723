// Dynamically create a taxonomy of all Cell components, with the cell's name (as string) as key and the component
// as value. Use this to A) skip imports in the columns taxonomy of a table instance (use strings instead of components)
// and B) with strings in the taxonomy (instead of components which are shown anonymously by React) we can reuse them
// to query other taxonomies, e.g. the COLUMNS_WITHOUT_AGGEGRATES taxonomy which sets default values, so that we don't
// always have to explicitly set that a column has no aggregates (for some types such as dates it never makes sense).
// For custom cells you may still use components.
import * as _CellTaxonomy from './cells'
export const CellTaxonomy = _CellTaxonomy

export const COLUMNS_WITHOUT_AGGEGRATES = ['Date', 'DateTime', 'ExtLink', 'Status', 'Time', 'Environment', 'Team', 'User']
export const COLUMNS_WITH_TOTAL_AS_CHILD = ['Status']
export const getColumnName = name => `${name}Cell` // use one fn to keep things DRY and taxonomy keys short
