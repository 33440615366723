const getTimeShiftUrl = ({user, date: _date, plan_shift, shift}) => {
	let url = `/time_registration/new`
	if (shift && shift.reg_shift_id) return `/time_registration/${shift.reg_shift_id}` // plan_shift that's been accorded
	if (shift) return `/time_registration/${shift.id}`
	if (user) url += `?user_id=${user.id}`
	if (_date) url += `&date=${_date.format()}`
	return url
}

export default getTimeShiftUrl
