import {API} from '@eitje/easy_api'
import FileList from 'components/file_list'
import {t} from 'initializers/i18n'
import FilePicker from 'lib/form/fields/image_picker'

const Documents = ({fullContract}) => {
	return (
		<>
			<div fieldWrapper className="modal-form-field-area">
				{fullContract.attachments.length === 0 && (
					<div className="w100 fRow aCen jCen emptyDocuments">
						<p className="eitje-text-secondary">{t('noDocumentHere')}</p>
					</div>
				)}
				<FileList id={fullContract.id} allowRemove items={fullContract.attachments} />
			</div>

			<div className="modal-inner-footer gap-xss">
				<FilePicker onFileChange={file => API.attach('contract_holders', fullContract.id, file)} hideFileList image={false} />
			</div>
		</>
	)
}

export default Documents
