export function isFunction(item) {
	const obj = _.isObject(item)
	if (!obj) return false

	const isFn = typeof item == 'function'
	const isComponent = typeof item == 'object' && typeof item.type == 'function'

	return isFn || isComponent
}

export function isPlainObject(item) {
	// Lodash' isPlainObject function returns true for rendered components
	const obj = _.isObject(item)
	return !!(obj && !isFunction(item))
}
