import {where} from '@eitje/easy_api'
import {EntityCount} from 'common/components/entity_count'
import {useShared} from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'

export const useTeamTitle = (team_ids = [], {maxTeams = 1} = {}) => {
	team_ids = [team_ids].flat()
	const {myOrgEnvs} = useShared()
	const teams = useSelector(state => where(state, 'teams', team_ids))
	if (maxTeams >= teams.length) {
		return teams.map(t => makeTeamName(t, myOrgEnvs))
	} else {
		return <EntityCount name="team_title" count={teams.length} />
	}
}

export const makeTeamName = (team, orgEnvs) => {
	if (orgEnvs.length == 1) return team?.naam
	if (!team) return
	const env = orgEnvs.find(e => e.id == team.environment_id)
	if (!env) return team.naam
	return `${env.naam}: ${team.naam}`
}

export default useTeamTitle
