import {WeekTableCell} from 'common/components'
import {ABSENCE_TYPES} from 'cores/planning/components/improductive_row/cell'
import {t} from 'initializers/i18n'

export const SickPeriodCell = ({sickPeriod}) => {
	const {baseColor, transKey} = ABSENCE_TYPES['sick']
	const sickLabel = {type: 'title', text: t(transKey), colorCircle: baseColor}

	return <WeekTableCell cellType="card" items={[sickLabel]} baseColor={baseColor} colorSet="grey-bordered" />
}
