import useFilter, {setFilter} from 'hooks/use_filter'
import utils from '@eitje/web_utils'

export const useFilterValue = ({statusses, name, status}) => {
	const filt = useFilter(name) || {}
	let keys = Object.keys(filt)
	const values = Object.values(filt)
	if (!utils.exists(values)) return Object.values(statusses).flat()
	if (status) {
		keys = [status]
	}
	return keys
		.map(k => (utils.exists(filt[k]) ? filt[k] : statusses[k]))
		.flat()
		.filter(Boolean)

	return []
}
