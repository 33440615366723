import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {PlanningShift, User, RevenueGroup, RevenueTeam, RevenueDay} from 'models/index'
import {useForecast} from 'cores/revenue/index'
import RevenueDayItem from './revenue_day'
import './styles/index.less'
import useHasRole from 'hooks/use_has_role'
import RevenueFooterProvider, {useRevenueFooterContext} from '../revenue_footer_provider'

const RevenueGroupWeek = ({group, revenueDays, ...rest}) => {
	const {dateRange, dateArray} = useRevenueFooterContext()
	const revenueTeams = RevenueTeam.where({omzet_groep_id: group.id})
	return <RevenueGroupWeekInner revenueTeams={revenueTeams} dateRange={dateRange} revenueDays={revenueDays} group={group} {...rest} />
}

export const RevenueGroupWeekInner = ({revenueDays, revenueTeams, dateRange, group, ...rest}) => {
	const {dateArray, shifts} = useRevenueFooterContext()
	const teamIds = revenueTeams.map(t => t.team_id)
	const teamShifts = shifts.where({team_id: teamIds})

	const data = useForecast({shifts: teamShifts, revenueDays, revenueTeams})
	return (
		<div className="revenue-group-week">
			<RevenueGroupTotal group={group} {...data} />
			{dateArray.map(d => (
				<RevenueDayItem
					revenueTeams={revenueTeams}
					shifts={teamShifts.where({date: d})}
					date={d}
					key={d}
					targetPct={group.streef_pct}
					revenueDays={revenueDays.filter(r => r.date == d.format())}
					{...rest}
				/>
			))}
		</div>
	)
}

const RevenueGroupTotal = ({group, revenue, laborProd, wageCost, wageCostPct, pctString}) => {
	const {env} = useRevenueFooterContext()
	const wageCostPctColor = !group.streef_pct || wageCostPct < group.streef_pct ? '#828282' : '#ff0020'
	const hasContractRole = useHasRole('contracten', env.id)
	return (
		<div className="revenue-group-week-total">
			<h4 className="revenue-group-week-total-title"> {group.naam} </h4>

			<div className="labour-data">
				<h4 className="revenue-group-week-total-amount"> {utils.money(revenue)} </h4>

				<h6>
					{t('common.ap')}: {utils.money(laborProd)}
				</h6>

				<h6 style={{color: wageCostPctColor}}>
					{hasContractRole && (
						<Fragment>
							$: {utils.money(wageCost)} | {pctString}
						</Fragment>
					)}
					{!hasContractRole && <Fragment> $: {pctString} </Fragment>}
				</h6>
			</div>
		</div>
	)
}

export default RevenueGroupWeek
