export const getKeys = (object, convertToNumber = true) => {
	const keys = Object.keys(object)
	if (!convertToNumber) return keys

	return keys.map(key => {
		const parsedKey = parseInt(key)
		return parsedKey === 'NaN' ? key : parsedKey
	})
}

export const has = (object, path, fallback) => {
	const hasProperty = _.has(object, path)
	return hasProperty ? _.get(object, path) : fallback
}

export const isEmptyObject = object => {
	return _.every(object, _.isEmpty)
}
