import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/chat'
import {useRoleMessage, useShared} from 'hooks'

export const useGroupChannelDisabled = channel => {
	const {org} = useShared()
	const adminRoleMessage = useRoleMessage({roles: 'admin'})

	const {
		data: {org_id},
		state: {
			members,
			membership: {user},
		},
	} = channel
	// need to use the members array here instead of the membership object
	// since on intitial load the role is missing in the membership object
	const isOwner = members[user.id].role === 'owner'
	const disabledByRole = !isOwner && adminRoleMessage
	const disabledByOrg = org_id !== org.id && t(`${NAMESPACE}.group_chat_button_disabled`)
	const disabled = disabledByOrg || disabledByRole

	return disabled
}
