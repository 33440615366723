import {useCallback, useMemo} from 'react'
import {useChatContext} from 'stream-chat-react'
import {PopoutCard} from '@eitje/web_components'
import {t} from 'initializers/i18n'
import {EitjeButton, ListPicker} from 'common/components'
import {NAMESPACE, CHANNEL_TYPES, useChannelMembers, useCreateChannel} from 'cores/chat'
import './online_members.less' // temporary soluton to allow coloring the left icon in the EitjeButton

export const OnlineMembers = () => {
	const {client, channel, setActiveChannel} = useChatContext()
	const {createChannel} = useCreateChannel()
	const {onlineMemberCount, members} = useChannelMembers(channel)
	const {userID: userId} = client
	const onlineLabel = t(`${NAMESPACE}.online`)
	const offlineLabel = t(`${NAMESPACE}.offline`)

	const memberCount = members.length
	const offlineMemberCount = memberCount - onlineMemberCount

	const groups = [
		{
			id: 'online',
			name: `${onlineLabel} (${onlineMemberCount})`,
		},
		{
			id: 'offline',
			name: `${offlineLabel} (${offlineMemberCount})`,
		},
	]

	const handleClick = useCallback(
		async user => {
			const userIds = [userId, user.toString()]

			const channel = await createChannel({
				type: CHANNEL_TYPES.DIRECT,
				userIds,
			})

			setActiveChannel(channel)
		},
		[userId],
	)

	const users = useMemo(() => {
		return members.reduce((acc, {user}) => {
			const listPickerGroup = user.online ? 'online' : 'offline'
			acc.push({
				...user,
				listPickerGroup,
			})

			return acc
		}, [])
	}, [userId, members])

	if (!channel || !users.length) {
		return null
	}

	const isDirect = channel.type === CHANNEL_TYPES.DIRECT

	if (isDirect) {
		// there is always just one user in direct chats
		const isOnline = users[0].online

		return (
			<EitjeButton iconLeft="dot-small" iconColor={isOnline ? Colors.mediumGreen : Colors.mediumRed} hasHover={false}>
				{isOnline ? onlineLabel : offlineLabel}
			</EitjeButton>
		)
	}

	return (
		<PopoutCard
			body={
				<ListPicker
					labelField="name"
					items={users}
					single
					raw
					groupField="listPickerGroup"
					groupItems={groups}
					showSearch={false}
					noSort
					onChange={handleClick}
				/>
			}
			bodyPadding={0}
			trigger="click"
			placement="bottom-start"
			width={200}
		>
			<EitjeButton className={onlineMemberCount ? 'green-icon' : 'red-icon'} iconLeft="dot-small" dropdown>
				{`${onlineLabel} (${onlineMemberCount}/${memberCount})`}
			</EitjeButton>
		</PopoutCard>
	)
}
