import {backend, find, where} from '@eitje/easy_api'
import {store} from 'index'
import api from 'initializers/api'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import pluralize from 'pluralize'
import {entitiesSelector} from 'selectors/entities'
import * as jsUtils from 'utils/jsutils'
const updateUrls = {
	users: id => `users/${id}/update`,
}

const indexUrls = {
	verlof_verzoeken: () => 'verlof_verzoeken/new_all',
	omzet_groepen: () => 'omzet_groepen/new_index',
}

export const fetchEntity = (kind, relId) => {
	const state = store.getState()
	return find(state, pluralize(kind), relId)
}

export const fetchEntities = (kind, relIds) => {
	const state = store.getState()
	const ent = entitiesSelector
	return where(state, pluralize(kind), relIds)
}

const handleErrors = data => {
	if (_.isArray(data)) {
		data.forEach(err => jsUtils.errNotif(t('att'), err))
	} else if (_.isObject(data) && data['errors']) {
		const errs = _.isArray(data['errors']) ? data['errors'] : _.values(data['errors'])
		errs.forEach(err => jsUtils.errNotif(t('att'), err))
	}
}

// NEW (DB-FASHION) ENTITIES
function relMapper(i) {
	const keys = Object.keys(i)
	const splitted = keys.map(k => k.split('_'))
	const relFields = splitted.filter(s => s.length > 1 && (s[s.length - 1] === 'id' || s[s.length - 1] == 'ids'))
	relFields.forEach(f => {
		const full = f.join('_')
		const name = jsUtils.snakeToCamel([...f].filter(i => f.indexOf(i) != f.length - 1).join('_'))
		const val = i[full]
		if (_.isArray(val)) {
			i[pluralize(name)] = () => fetchEntities(name, val)
		} else {
			i[name] = () => fetchEntity(name, val)
		}
	})
	return i
}

export async function getEntities(kind) {
	const url = indexUrls[kind] ? indexUrls[kind]() : kind
	const res = await api.get(url, {new_web: true})
	if (res.ok) {
		let items = res.data.items
		items = items.map(i => relMapper(i))
		store.dispatch({type: 'GET_ENTITIES', items: items, kind: jsUtils.snakeToCamel(kind)})
	}
}

export async function update(kind, params, {extraParams = {}} = {}) {
	const sing = pluralize.singular(kind)
	const obj = {new_web: true}
	obj[sing] = params
	const url = updateUrls[kind] ? updateUrls[kind](params.id) : `${kind}/${params.id}`
	const res = await api.put(url, obj)
	if (res.ok) {
		store.dispatch({type: 'UPDATE_ENTITY', item: relMapper(res.data.item), kind: jsUtils.snakeToCamel(kind)})
	} else {
		handleErrors(res.data)
	}
}

export async function insert(kind, items) {
	store.dispatch({type: 'GET_ENTITIES', kind: kind, items: items.map(i => relMapper(i))})
}

export async function insertOne(kind, item) {
	kind = jsUtils.snakeToCamel(kind)
	if (item && item.id) {
		store.dispatch({type: 'UPDATE_ENTITY', kind: kind, item: item})
	}
}

export async function add(kind, params, {extraParams = {}} = {}) {
	return params['id'] ? create(kind, params, extraParams) : update(kind, params, extraParams)
}

export async function create(kind, params, {extraParams = {}} = {}) {
	const sing = pluralize.singular(kind)
	const obj = {}
	obj[sing] = params
	const res = await api.post(kind, {...obj, ...extraParams})
	if (res.ok) {
		const json = res.data
		const item = relMapper(json.item)
		store.dispatch({type: 'CREATE_ENTITY', item: item, kind: jsUtils.snakeToCamel(kind)})
		return {ok: true}
	} else {
		handleErrors(res.data)
		return {ok: false}
	}
}

export async function destroy(kind, id) {
	const res = await api.delete(`${kind}/${id}`)
	if (res.ok) {
		store.dispatch({type: 'DELETE_ENTITY', id: id, kind: jsUtils.snakeToCamel(kind)})
		return true
	}
}

export async function del(kind, id) {
	store.dispatch({type: 'DELETE_ENTITY', id: id, kind: jsUtils.snakeToCamel(kind)})
}

export async function addMulti(kind, params, {extraParams = {}, showSucc = true} = {}) {
	const obj = {}
	obj[kind] = params
	const res = await api.post(`${kind}/add_multi`, {...obj, ...extraParams})
	if (res.ok) {
		showSucc && jsUtils.success()
		const json = res.data
		const items = json.items.map(i => relMapper(i))
		store.dispatch({type: 'ADD_ENTITIES', items: items, kind: jsUtils.snakeToCamel(kind)})
		return true
	}
	return false
}

///// OLD ENTITIES CODE

export async function inviteAllUsers() {
	const res = await backend.post(`invite_all`)
	if (res.ok) {
	}
}

export async function inviteUsers(serialized, envId) {
	const state = store.getState()
	const res = await backend.post(`invite`, {invite: serialized, env_id: envId})
	if (!res.problem) {
		// getUsers()
		// getTeams(state.auth.user.id)
		return false
	} else {
		return res.data
	}
}

export const isGhost = (user, envId) => {
	return !user.environment_ids.includes(envId)
}

export function filterUsers({users, mustBeInCurrent = false, orClause = null, orFunc = () => false}) {
	const fullState = store.getState()
	const {activeTeams, activeEnvs} = fullState.entities
	const {active} = fullState.environment
	const state = fullState.records
	let valid = true
	const userz = users.map(u => {
		const user = state.users.find(us => us.id == u.id)
		return Object.assign(u, user)
	})
	return userz.filter(u => {
		const hasPass = u[orClause] || orFunc(u, activeTeams)
		if (hasPass) return true
		if (mustBeInCurrent) {
			valid = u.environment_ids.includes(active)
		}
		return (
			valid &&
			(activeTeams.length === 0 || _.intersection(u.team_ids, activeTeams).length > 0) &&
			(activeEnvs.length === 0 || _.intersection(u.environment_ids, activeEnvs).length > 0)
		)
	})
}

export function filterShifts({shifts, envOnly = false}) {
	const state = store.getState().entities
	const {activeTeams, activeEnvs} = state
	return shifts.filter(
		s =>
			(activeTeams.length == 0 || activeTeams.includes(s.team_id)) &&
			(activeEnvs.length == 0 || envOnly || activeEnvs.includes(s.environment_id)),
	)
}

export async function saveUser(user, extra) {
	const res = await backend.put(`users/${user.id}/update`, {user, ...extra})
	if (res.ok) {
		// getUsers()
		return true
	}
}
