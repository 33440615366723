import {NAMESPACE} from 'cores/time_registration/pages/per_week/week_table_days_header'
import {usePageContext} from 'common/components'
import {useEditableFilteredShifts, usePerWeekContext} from 'cores/time_registration/index'
import {useDaysHeaderMultiEdit} from 'common/components/week_table'

export function useEditShifts(date) {
	const shifts = useEditableFilteredShifts()
	const {dateRange} = usePerWeekContext()
	const {setMultiEdit, setSelectedShifts} = usePageContext()

	return useDaysHeaderMultiEdit({
		shifts,
		setMultiEdit,
		setSelectedShifts,
		role: 'uren_accorderen',
		namespace: NAMESPACE,
		date: date || dateRange,
	})
}
