import useSelector from 'hooks/use_selector'
import utils from '@eitje/web_utils'

const useUserOvertime = userId => {
	const allOvertime = useSelector(state => state.rooster.plusMinUsers)
	const overtimeMins = allOvertime[userId] || 0
	return {mins: overtimeMins, timeString: utils.minToTimeString(overtimeMins, true), available: utils.exists(allOvertime[userId])}
}

export default useUserOvertime
