import {useLocation} from 'react-router-dom'
import {useChatContext} from 'stream-chat-react'
import {ModalWithNewForm} from 'components/form/new_modal_form'
import {useShared} from 'hooks'
import {CHANNEL_TYPES, useCreateChannel, GroupChatForm} from 'cores/chat'
import {closeModal} from 'actions'

const initialValues = {name: '', user_ids: [], remote_avatar_url: ''}

const trimInvalidCharacters = input => input.replace(/[^a-zA-Z0-9!_-]/g, '')

export const CreateGroupChat = () => {
	const {setActiveChannel} = useChatContext()
	const location = useLocation()
	const {createChannel} = useCreateChannel()
	const {me, org} = useShared()

	const onSubmit = async data => {
		// add current user to selected users array
		const userIds = [...data.user_ids, me.id]

		// by using Date.now() as channel ID we ensure the same list of members can participate in multiple channels
		// see: https://getstream.io/chat/docs/react/creating_channels/?language=javascript
		const orgIdWithDate = `${org.id}_${Date.now()}`
		const hyphenatedName = data.name
			.split(' ')
			.join('-')
			.slice(0, 64 - (orgIdWithDate.length + 1)) // id can be at most 64 characters, so slicing to the max here
		const channelId = `${hyphenatedName}_${orgIdWithDate}`

		// only letters, numbers and "!-_" are allowed
		const trimmedChannelId = trimInvalidCharacters(channelId)

		const channel = await createChannel({
			channelId: trimmedChannelId,
			type: CHANNEL_TYPES.GROUP,
			name: data.name,
			userIds,
			image: data.remote_avatar_url,
		})

		return {ok: !!channel, channel}
	}

	const close = ({res}) => {
		setActiveChannel(res.channel)
		closeModal(location)
	}

	return (
		<ModalWithNewForm name="create_group_chat" width={350}>
			<GroupChatForm initialValues={initialValues} onSubmit={onSubmit} afterSubmit={close} />
		</ModalWithNewForm>
	)
}
