import useRecordErrors from 'hooks/use_record_errors'
import {PlanningShift} from 'models/index'

const opts = {joins: ['users']}

export const useErroredShifts = () => {
	const recordNames = ['planningShifts']
	const errors = useRecordErrors(recordNames)

	const shifts = PlanningShift.where({id: errors.map(e => e.id)}, opts)
	const items = shifts.map(s => ({...s, error: errors.find(e => e.id == s.id)?.error}))
	return items
}

export default useErroredShifts
