import {Layout} from 'common/components'
import {Fragment, forwardRef} from 'react'
import {useLocalValue} from 'hooks'
import utils from '@eitje/web_utils'
import {PopoutCard} from '@eitje/web_components'

export const WeekTableCardCell = forwardRef(
	(
		{
			children,
			LeftContent,
			leftContentProps,
			leftContentLocalValue,
			baseColor,
			Wrapper,
			popoutTitle,
			popoutBody,
			wrapperProps,
			className,
			...rest
		},
		ref,
	) => {
		const inlineStyle = {borderRadius: 4}
		const showLeftContent = (useLocalValue(leftContentLocalValue) && leftContentLocalValue) || !leftContentLocalValue
		const classNames = utils.makeCns(className, 'week-table-cell', 'week-table-card')

		if (!Wrapper && popoutTitle) Wrapper = PopoutCard
		if (!Wrapper) Wrapper = Fragment

		return (
			<Wrapper {...wrapperProps} title={popoutTitle} body={popoutBody}>
				<Layout padding={4} border style={inlineStyle} width="full" baseColor={baseColor} ref={ref} className={classNames} {...rest}>
					{LeftContent && showLeftContent && <LeftContent {...leftContentProps} />}
					<Layout className="week-table-cell-items" direction="vertical" grow gap={0} baseColor={baseColor}>
						{children}
					</Layout>
				</Layout>
			</Wrapper>
		)
	},
)
