import _ from 'lodash'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {ContractEnv} from 'components/users'

export const renderCell = (item, field, name) => {
	const val = _.get(item, field)
	const key = field.includes('.') ? field : `${name}.${field}`

	switch (key) {
		case 'contract.duration':
			return t('dateRange', {...item})
		case 'salary.amount':
			return utils.formatMoney(val)
		case 'workSchedule.totalMins':
			return utils.minToTimeString(val) || '-'
		case 'contract.environment_id':
			return <ContractEnv id={val} />
		case 'employmentType.name':
			return val ? t(val) : null
		// temp hack to translate employment type names
		default:
			return val || '-'
	}
}
