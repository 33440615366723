import {usePerWeekContext} from './pages/per_week/time_registration_provider'
export * from './providers'
import {t as baseT} from 'initializers/i18n'
export * from './helpers'
export * from './actions'
export * from './pages'
export * from './hooks'
export * from './components'
export * from './constants'
const t = (text, opts = {}) => baseT(`time_registration.${text}`, opts)

export {t, usePerWeekContext}
