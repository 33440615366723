import React from 'react'
import {usePerWeekContext} from 'cores/time_registration/index'
import {Column} from './column.js'
import UserProvider from 'contexts/user'
import {Panel} from './panel'
import {WeekTableRow, useFilterFooterContext} from 'common/components'

export * from './time_registration_shift_cell'

const UserRow = ({user}) => {
	const {dateArray} = usePerWeekContext()
	const {filteredItems: shifts} = useFilterFooterContext()
	const userShifts = shifts.where({user_id: user.id})

	return (
		<UserProvider shifts={userShifts} user={user}>
			<WeekTableRow
				items={userShifts}
				Panel={Panel}
				Day={Column}
				dateArray={dateArray}
				minHeight={48}
				preventDoubleBorder
				initialBackground={Colors.white}
			/>
		</UserProvider>
	)
}

export default React.memo(UserRow)
