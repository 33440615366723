import {t} from 'initializers/i18n'
import {useGetEnvWithRole, useHasRoleInAnyEnv} from 'hooks'
import {ModalLink} from 'components/routing'
import {ExportHoursLink, TimeSettingsLink, IntegrationLink} from './small'
import {LegacyIntegrationLink} from 'components/shared'
import {usePageContext, Text, ToolsButton} from 'common/components'
import {pathActive} from 'helpers'

export const Tools = props => {
	const perMember = pathActive('/hours/per_week')
	const asTable = pathActive('/hours/per_member')
	const hasAnyApproveRole = useHasRoleInAnyEnv('uren_accorderen')
	const hasAnyWriteRole = useHasRoleInAnyEnv('uren_schrijven')
	const isAdmin = useHasRoleInAnyEnv('admin')
	const {setMultiEdit} = usePageContext()
	const adminEnv = useGetEnvWithRole('admin')
	const noWriteRole = !hasAnyWriteRole && t('header.disabled.write_hours_role')
	const disabledReason = {key: 'common.required_role', role: t('rolesEnum.uren_schrijven')}
	const disabledReasonAdmin = {key: 'common.required_role', role: t('rolesEnum.admin')}

	const elements = [
		{
			icon: 'plus',
			element: <ModalLink t="time_registration.add" to="/time_registration/new" />,
		},
		{icon: 'upload', element: <ExportHoursLink />, disabled: !hasAnyApproveRole && disabledReason},
		{icon: 'upload', element: <LegacyIntegrationLink />},
		{icon: 'upload', element: <IntegrationLink />},
		setMultiEdit &&
			!asTable && { // Checkboxes are always visible in the AdvancedTable rows, therefore this option is obsolete/ does nothing
				icon: 'pencil',
				element: <Text onClick={() => setMultiEdit(true)} t="time_registration.multi_edit.title" />,
				disabled: noWriteRole,
			},
		{
			icon: 'cog-wheel',
			element: <ModalLink t="common.settings" to={`/time_registration/${adminEnv?.id}/settings`} />,
			disabled: !adminEnv && disabledReasonAdmin,
		},
		isAdmin && {icon: 'cog-wheel', element: <TimeSettingsLink />},
	]

	return <ToolsButton elements={elements} {...props} />
}
