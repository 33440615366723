import {Layout} from 'common/components'
import {isProd} from 'constants/general'
import {useOnboardingActive} from 'cores/onboarding'
import {useLocalValue} from 'hooks'
import {EnvSwitch} from './env_switch'
import {Logo, NavItem} from './nav_item'
import {Notifs} from './notifs'
import {Onboarding} from './onboarding'
import {ProductUpdates} from './product_updates'
import './styles/index.less'

export const NAVBAR_HEIGHT = 50
const styles = {gap: 0, height: 'full'}

export const NavigationHeader = () => {
	const showDebug = useLocalValue('internalShowDebug')
	const onboardingActive = useOnboardingActive()

	return (
		<FixedHeaderWrapper height={NAVBAR_HEIGHT}>
			<Layout className="navbar-header" height={NAVBAR_HEIGHT} horizontal="spaceBetween" width="100vw" vertical="center">
				<Layout className="navbar-header-main" {...styles}>
					<Logo />
					<NavItem group="planning" />
					<NavItem group="time_registration" />
					<NavItem group="social" />
					<NavItem group="team" />
					<NavItem group="training" />
					<NavItem group="data" />
					<NavItem group="help" support />
				</Layout>

				<Layout {...styles}>
					{onboardingActive ? <Onboarding /> : <ProductUpdates />}
					<Notifs />
					<EnvSwitch />
					{!isProd && showDebug && <NavItem group="debug" />}
					<NavItem group="settings" />
				</Layout>
			</Layout>
		</FixedHeaderWrapper>
	)
}

export const FixedHeaderWrapper = ({children, ...rest}) => {
	// The "navbar-header-wrapper" wrapper is a temporary fix to support pages such as integrations, exports and news – which rely on
	// the former header's structure: a wrapper div with a height and position relative, containing the fixed header. Once we rewrite pages
	// with the PageLayout, we should be able to delete the wrapper.

	// This component passed the height to a relative positioned div, so that the y-axis positioning of the page does not have to be altered
	// because of the height of the fixed elements, which may float above other elements (the other cumbersome solution would be to use a lot
	// of margin tops...)

	return (
		<Layout className="fixed-header-wrapper" {...rest} style={{position: 'relative'}}>
			{children}
		</Layout>
	)
}
