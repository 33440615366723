import React from 'react'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useShiftContext, useEnvTableContext, useEnvsContext} from 'cores/planning/index'
import useHasRole from 'hooks/use_has_role'
import TradeTrigger from 'cores/planning/components/trade/approve_trade/trigger'
import {LeftComponent} from './left_component'
import {getShiftCondOpts} from 'cores/planning/helpers'
import {ShiftCardPlanning, FromTill, NoteTypeRow, ShiftIcons, ShiftName} from 'cores/planning'
import {Layout} from 'common/components'

const InnerShift = ({className, emoji}) => {
	const {item} = useShiftContext()
	const {env} = useEnvTableContext()
	const {multiEdit} = useEnvsContext()

	const hasManagerRole = useHasRole('manager', env.id)
	let condOpts = getShiftCondOpts({hasManagerRole, shift: item})

	const isDroppable = userCard => {
		if (userCard.id == item.id) return false
		return !userCard.planShifts.some(s => s.dateRange.overlaps(item.dateRange))
	}

	const dropProps = {
		accept: `availability-card-${item.team_id}`,
		canDrop: isDroppable,
		onDrop: userCard => item.update({user_id: userCard.id}),
	}

	const classNames = utils.makeCns('per-week', multiEdit && item.reg_shift_id && 'disabled-shift', className)
	return (
		<ShiftCardPlanning
			item={item}
			condOpts={condOpts}
			className={classNames}
			env={env}
			dropProps={dropProps}
			hasManagerRole={hasManagerRole}
		>
			{({isHovering, canSeeTrade, isOver, canDrop, canDrag, isDragging, trade_request_id}) => (
				<>
					{isOver && canDrop && <h5 className="drop-to-schedule"> {t('planning.environment_table.drop_to_schedule')} </h5>}
					<LeftComponent isDragging={isDragging} isHovering={isHovering} canDrag={canDrag} hasManagerRole={hasManagerRole} item={item} />
					<div className="shift-info">
						<FromTill />
						<Layout className="shift-sub-info">
							{canSeeTrade && <TradeTrigger inlineTrigger id={trade_request_id} />}
							<ShiftName emoji={emoji} />
						</Layout>
						<ShiftIcons />
					</div>
					<NoteTypeRow />
				</>
			)}
		</ShiftCardPlanning>
	)
}

export default InnerShift
