import React from 'react'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import SearchProvider from 'contexts/search'
import {getShiftStatusFilter} from 'cores/planning/hooks'
import {PlanningAdvancedTable} from 'common/components/advanced_table/instances/planning'
import {PageHeader} from './page_header'
import {usePlanningTableShifts} from './hooks'
import {useHasRoleInAnyEnv} from 'hooks'
import {PlanningTableProvider} from './providers'
import {useMultiEdit} from 'hooks'
import {t} from 'initializers/i18n'

export * from './hooks'
export * from './providers'

const conflictFilterObject = {
	name: 'conflict',
	items: ['with_conflict', 'without_conflict'],
	labelT: filterName => t(`common.${filterName}`),
	filterFunction: (shift, opts) => {
		if (!opts.length) return true // always include the shift when no filter is set
		if (shift.conflict) return opts.includes('with_conflict')
		if (!shift.conflict) return opts.includes('without_conflict')
	},
}

export const contractTypeFilterObject = {
	name: 'contract_type',
	items: ['Nul uren', 'Uren contract', 'Payroll', 'ZZP', 'no_contract'],
	filterFunction: (shift, opts) => {
		if (!opts.length) return true // always include the shift when no filter is set
		const type = shift.employmentType
		if (!type && opts.includes('no_contract')) return true
		return type && opts.includes(type)
	},
}

const _PlanningTable = ({}) => {
	const items = usePlanningTableShifts()
	const {filterFunction, filterProps} = getShiftStatusFilter({withOpenShifts: true})
	const statusFilter = {...filterProps, filterFunction}
	const core = 'planning' // use the core, not the page, as identifier for the FF, so that the core colours may appropriately be reused.
	const multiEditProps = useMultiEdit()

	// Ideally disable + tooltip instead of hiding, but currently not possible because role logic is not present in the component lib' Layout
	const contractRole = useHasRoleInAnyEnv('contracten')
	const filters = ['environment', 'team', statusFilter, conflictFilterObject, contractRole && contractTypeFilterObject]

	return (
		<SearchProvider>
			<FilterFooterProvider id={core} items={items} filters={filters}>
				<PageLayout name="planning_table" headers={<PageHeader />} footers={<FilterFooter />}>
					<PlanningAdvancedTable />
				</PageLayout>
			</FilterFooterProvider>
		</SearchProvider>
	)
}

const Wrapper = () => {
	return (
		<PlanningTableProvider>
			<_PlanningTable />
		</PlanningTableProvider>
	)
}

function areEqual() {
	return true
}

export const PlanningTable = React.memo(Wrapper, areEqual)
