import useSingleContracts, {useSingleSalaries, useSingleSchedules} from 'hooks/use_contract'
import DataDisplay from 'components/general/data_display'
import {navigateModal} from 'actions/routing'
import {renderCell} from './helpers'
import {hasMissing} from 'hooks/use_missing_contract'
import {currentOrgSelector} from 'selectors/records'
import {useSelector} from 'react-redux'

const contractHeaders = [
	{label: 'employmentType.name', width: 23},
	{label: 'duration', fontSize: 10, width: 32},
	{label: 'workSchedule.totalMins', width: 15},
	{label: 'salary.amount', width: 15},
]

const orgItem = {label: 'environment_id'}

const sharedProps = {
	renderCell,
	preChildren: item =>
		hasMissing(item) && (
			<div style={{marginLeft: -16, paddingRight: 6}}>
				<div style={{}} className="warningCircle fRow aCen jCen">
					<p>!</p>
				</div>
			</div>
		),
}

export const ContractTable = ({id}) => {
	const org = useSelector(currentOrgSelector)

	const headers = [...contractHeaders, org.id && orgItem].filter(Boolean)
	const contracts = useSingleContracts(id)
	return (
		<DataDisplay
			allowEmpty
			name="contract"
			onRowClick={item => navigateModal(`/forms/contracts/${item.contract_id}/details/`)}
			items={contracts}
			headers={headers}
			{...sharedProps}
		/>
	)
}

const salaryHeaders = ['contract.duration', 'employmentType.name', 'amount']

export const SalaryTable = ({id}) => {
	const salaries = useSingleSalaries(id)
	return (
		<DataDisplay
			headers={salaryHeaders}
			items={salaries}
			name="salary"
			renderCell={renderCell}
			onRowClick={item => navigateModal(`/forms/contracts/${item.contract_id}/salary`)}
		/>
	)
}

const scheduleHeaders = ['contract.duration', 'employmentType.name', 'totalMins', 'salary.amount']

export const ScheduleTable = ({id}) => {
	const schedules = useSingleSchedules(id)
	return (
		<DataDisplay
			headers={scheduleHeaders}
			items={schedules}
			name="workSchedule"
			renderCell={renderCell}
			onRowClick={item => navigateModal(`/forms/contracts/${item.contract_id}/workweek`)}
		/>
	)
}
