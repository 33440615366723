import {memo} from 'react'
import {WeekTableRow} from 'common/components'
import {Panel} from './panel'
import {Column} from './column'
import {usePlanningContext} from 'cores/planning'
import UserProvider from 'contexts/user'
import {usePerMemberContext} from 'cores/planning/pages/index/per_member/per_member_provider'
import {useImproductiveUserShifts} from 'cores/planning/hooks'
import {SickPeriod} from 'models'

export const NAMESPACE = 'planning.per_week.user_row'

const _UserPlanningShiftsRow = ({user}) => {
	const {dateArray, dateRange} = usePlanningContext()
	const {planShifts} = usePerMemberContext()
	const userShifts = planShifts.where({user_id: user.id})
	const improductiveShifts = useImproductiveUserShifts(user).filter(s => s.tableName === 'timeRegistrationShifts')
	const sickPeriods = SickPeriod.where({user_id: user.id, dateRange})

	return (
		<UserProvider shifts={userShifts} user={user} timeRegistrationShifts={improductiveShifts} sickPeriods={sickPeriods}>
			<WeekTableRow
				className="user-planning-shifts-row"
				Panel={Panel}
				Day={Column}
				dateArray={dateArray}
				minHeight={48}
				preventDoubleBorder
				initialBackground={Colors.white}
			/>
		</UserProvider>
	)
}

export const UserPlanningShiftsRow = memo(_UserPlanningShiftsRow)
