import {WeekTableCell} from 'common/components'
import {useShared} from 'hooks'
import {Environment} from 'models'
import {t} from 'initializers/i18n'
import useHasRole from 'hooks/use_has_role'

export const Cell = ({event}) => {
	const {multiEnvOrg} = useShared()
	const {from, till, title, environment_id} = event
	const environment = Environment.find(environment_id)
	const to = useHasRole('manager', environment_id) && `/forms/events/${event.id}`
	const baseColor = 'orange'
	const duration = from && till ? `${from} - ${till}` : t('events.whole_day')

	// Cell Items
	const eventName = {type: 'title', text: title}
	const startEndTimes = {type: 'subtitle', text: duration}
	const envName = multiEnvOrg && {type: 'subtitle', text: environment.naam}

	return (
		<WeekTableCell
			cellType="card"
			baseColor={baseColor}
			colorSet="color-bordered"
			items={[eventName, startEndTimes, envName]}
			modalLink={to}
		/>
	)
}
