import {t} from 'initializers/i18n'
import {EitjeButton, EitjeDropdown, Text, DeletePopout} from 'common/components'
import {ModalLink} from 'components/routing'
import {NAMESPACE, useGroupChannelDisabled} from 'cores/chat'
import {Team} from 'models'
import {useShared} from 'hooks'
import {useChatContext} from 'stream-chat-react'

export const ManageTeamChatButton = ({channel}) => {
	const {myOrgEnvIds} = useShared()
	const teamId = channel.id
	const team = Team.find(teamId)
	const {environment_id} = team
	const teamChatLink = `/environments/${environment_id}/teams/${teamId}`
	const disabled = !myOrgEnvIds.includes(environment_id) && t(`${NAMESPACE}.team_chat_button_disabled`)

	return (
		<EitjeButton
			modalLink={teamChatLink}
			iconLeft="cog-wheel"
			t={`${NAMESPACE}.manage_team_chat`}
			enableRole="manager"
			roleEnv={environment_id}
			disabled={disabled}
		/>
	)
}

export const ManageGroupChatButton = ({channel}) => {
	const {client, setActiveChannel} = useChatContext()
	const disabled = useGroupChannelDisabled(channel)
	const groupChatLink = `/chats/group/${channel.cid}`

	const leaveChannel = async () => {
		await channel.removeMembers([client.userID])
		setActiveChannel(null)
	}

	const leaveGroupTransKey = `${NAMESPACE}.leave_group`

	const elements = [
		{
			icon: 'cog-wheel',
			disabled,
			element: (
				<ModalLink to={groupChatLink}>
					<Text name={NAMESPACE} t="manage_group_chat" />
				</ModalLink>
			),
		},
		{
			icon: 'person-cross',
			element: (
				<DeletePopout onDelete={leaveChannel} title={leaveGroupTransKey} icon="person-cross" deleteText={t(leaveGroupTransKey)}>
					<Text name={NAMESPACE} t="leave_group" />
				</DeletePopout>
			),
		},
	]

	return (
		<EitjeDropdown trigger="click" elements={elements} placement="bottom-start">
			<EitjeButton iconLeft="cog-wheel" t="common.manage" />
		</EitjeDropdown>
	)
}
