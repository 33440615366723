import {useSquashedWageCosts} from 'cores/revenue/index'

const useForecast = ({shifts, revenueTeams, revenueDays}) => {
	const wageCosts = useSquashedWageCosts({shifts, revenueTeams})
	let {hours = 0, amount: wageCost = 0} = wageCosts
	if (isNaN(wageCost)) wageCost = 0
	const revenue = revenueDays.map(r => r.amt).sum()

	const laborProd = revenue > 0 && hours > 0 ? (revenue / hours).toFixed(2) : null

	const wageCostPct = revenue > 0 && wageCost > 0 ? ((wageCost / revenue) * 100).toFixed(1) : null

	const pctString = wageCostPct ? `${wageCostPct}%` : '0%'

	return {pctString, hours, wageCost, laborProd, revenue, wageCostPct}
}

export default useForecast
