import {useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {useRoleOrgEnvs, useShared} from 'hooks'

export const useOutOfServiceUsers = () => {
	const {orgEnvIds} = useShared()
	const roleEnvIds = useRoleOrgEnvs('manager').ids()

	let users = useAll('users')

	users = users.filter(u => {
		const activeOrgEnvs = utils.intersects(u.environment_ids, orgEnvIds)
		return !activeOrgEnvs
	})

	users = users.filter(u => {
		const deletedManagerEnvs = utils.intersects(u.del_env_ids, roleEnvIds)
		return deletedManagerEnvs
	})

	return users
}
