import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import {useState} from 'react'

const useSelectCheckboxState = opts => {
	const {def = [], max} = {...opts}
	const [state, setState] = useState(def)
	const setToggleState = newEl => {
		newEl = utils.alwaysArray(newEl)
		if (newEl.every(e => state.includes(e))) {
			setState(state.filter(s => !newEl.includes(s)))
		} else {
			const newArray = [...state, ...newEl].uniq()
			if (max && newArray.length > max) {
				return utils.errNotif(t('notifs.max_amount_notif', {count: max}))
			}
			setState(newArray)
		}
	}
	return [state, setToggleState, setState]
}

export default useSelectCheckboxState
