import React, {Fragment} from 'react'
import {PopoutCard} from '@eitje/web_components'
import {ColorCircle} from 'components/ui'
import useDay from 'cores/availability/hooks/use_day'
import utils from '@eitje/web_utils'
import {useAvFormContext} from 'cores/availability/components/form/availability_form_provider'
import {statusColors, isOutlinedStatus} from 'cores/availability/constants'
import {t} from 'initializers/i18n'
import useSelector from 'hooks/use_selector'
import {roleSelector} from 'selectors/new_settings'
import './availability_cell.less'

export const AvCell = ({item = {}, envId, alwaysOpen, visible = true, noBorder}) => {
	const {setDate, showForm = _.noop, isActive = _.noop, formItem} = useAvFormContext()
	const {date} = item
	const formActive = isActive(item.date)
	let currentItem = formActive && formItem ? formItem : item
	const {status} = currentItem

	const isFormable = status != 'sick' && status != 'leave_accepted' && status != 'leave_asked'

	const isManager = useSelector(roleSelector, 'manager', envId)
	const dayOpen = !useDay(date, envId)?.closed

	const formAllowed = alwaysOpen || isManager || dayOpen

	const classNames = utils.makeCns(
		'availability-cell',
		formActive && 'av-cell-active',
		isFormable && 'pointer-cursor',
		!visible && 'availability-cell-faded-by-filter',
		noBorder && 'no-border',
	)

	const _onClick = isFormable && formAllowed ? () => setDate?.(date) : _.noop

	return (
		<Fragment>
			<div onClick={_onClick} className={classNames}>
				<AvStatus item={currentItem} />
			</div>
			{isFormable && showForm(date)}
		</Fragment>
	)
}

export const SickStatus = () => {
	return <p className="line-height-1">🤒</p>
}

export const getLeaveRange = item => {
	const {timeBlocks = []} = item
	return timeBlocks[0] && timeBlocks[0].from ? timeBlocks : [item]
}

export const AvStatus = ({item}) => {
	const {status, secondaryStatus, remarks, tooltipText} = item

	return (
		<Fragment>
			<LeaveRange item={item} />
			<PopoutCard className="multiple-color-circles" title={tooltipText} hidden={!tooltipText}>
				{secondaryStatus && <Status status={secondaryStatus} />}
				<Status status={status} />
			</PopoutCard>
			<RemarkBox title={remarks} />
		</Fragment>
	)
}

export const Status = ({status}) => {
	if (status == 'sick') return <SickStatus />
	const color = statusColors[status]
	const isBorderColor = isOutlinedStatus(status)

	return <ColorCircle color={isBorderColor ? null : color} size={12} borderColor={isBorderColor ? color : null} />
}

export const RemarkBox = ({title}) => {
	if (!title) return null
	return (
		<PopoutCard title={title} className="availability-cell-remark-box">
			<img src={'/images/note.png'} />
		</PopoutCard>
	)
}

const FromTillPopoutBody = ({timeBlocks}) => (
	<div>
		<h4 className="availability-cell-date-popout-title">{t('unavailable')}</h4>
		<div className="availability-cell-date-popout-content">
			{timeBlocks.map(({from, till}) => (
				<h5>
					{t('from')} {from} {t('till')} {till}
				</h5>
			))}
		</div>
	</div>
)

export const LeaveRange = ({item}) => {
	const leaveRangeItems = getLeaveRange(item) || []
	const {from, till} = leaveRangeItems[0] || {}
	if (!from || !till) return null

	const rangeCount = leaveRangeItems.length

	if (rangeCount > 1) {
		const timeSortedBlocks = _.orderBy(leaveRangeItems, 'from')
		return (
			<PopoutCard className="availability-cell-date" body={<FromTillPopoutBody timeBlocks={timeSortedBlocks} />}>
				<div className="availability-cell-date-box">
					<p>{rangeCount}</p>
					<p>{t('slots')}</p>
				</div>
			</PopoutCard>
		)
	}

	return (
		<div className="availability-cell-time-box">
			<p>{from}</p>
			<p>{till}</p>
		</div>
	)
}

export default AvCell
