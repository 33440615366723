import utils from '@eitje/web_utils'
import Days from './days'
import SyncRow from './sync_row'
import Groups from './groups'
import useArrObjState from 'hooks/use_arr_obj_state'
import {usePosIntegration} from 'cores/integrations/index'
import {goBackToForeground} from 'actions'
import {BackButton, SubmitButton} from 'common/components'

const Page = ({dates = [], envId, onSubmit, showSync, dateDisabled, initialValue}) => {
	const [formData, setFormData] = useArrObjState({initialValue, idenField: 'date_group'})
	const integration = usePosIntegration(envId)
	const syncPossible = integration?.authed

	const classNames = utils.makeCns('revenue-form', (!showSync || !syncPossible) && 'without-sync-row')

	const submit = async () => {
		const res = await onSubmit(formData)
		if (res.ok) {
			goBackToForeground()
		}
	}

	return (
		<div className={classNames}>
			<Days dates={dates} />
			<Groups envId={envId} dates={dates.map(d => d.format())} data={formData} setData={setFormData} dateDisabled={dateDisabled} />
			{showSync && syncPossible && <SyncRow envId={envId} dates={dates} />}

			<div className="revenue-modal-footer">
				<div className="footer-buttons-container">
					<BackButton goBackToForeground />
					<SubmitButton onClick={() => submit(formData)} />
				</div>
			</div>
		</div>
	)
}

export default Page
