import React, {Fragment} from 'react'
import {t} from 'initializers/i18n'
import {ModalLink} from 'components/routing'
import Shift from './shift/index'
import {useTeamContext, useTeamDayContext, useEnvTableContext, getPlanningShiftUrl, usePlanningTeams} from 'cores/planning/index'
import useHasRole from 'hooks/use_has_role'
import {useDrop} from 'hooks'
import utils from '@eitje/web_utils'
import {PlanningShift} from 'models'
import useHover from 'hooks/use_hover'
import {makeCnVariants} from 'helpers'
import './styles/index.less'

const Shifts = ({shifts, isHovering, isDragging}) => {
	const {env} = useEnvTableContext()
	const hasManagerRole = useHasRole('manager', env.id)
	return (
		<Fragment>
			<ShiftsHolder shifts={shifts} />
			{hasManagerRole && isHovering && !isDragging && <NewShift />}
		</Fragment>
	)
}

const _ShiftsHolder = ({shifts}) => {
	// this component exists for memoization purposes -> Shifts re-renders a lot due to isHovering
	return (
		<Fragment>
			{shifts.map(s => (
				<Shift key={s.id} item={s} />
			))}
		</Fragment>
	)
}

const shiftsEqual = (prevProps, nextProps) => {
	return compareStamps(prevProps.shifts, nextProps.shifts, ['updated_at', 'shiftType.updated_at', 'team.updated_at'])
}

const compareStamps = (prev, next, fields) => {
	return fields.every(field => {
		return _.isEqual(
			prev.map(p => _.get(p, field)),
			next.map(n => _.get(n, field)),
		)
	})
}

const ShiftsHolder = React.memo(_ShiftsHolder, shiftsEqual)

ShiftsHolder.whyDidYouRender = true
// const ShiftsHolder = _ShiftsHolder

const NewShift = () => {
	const {team} = useTeamContext()
	const {date} = useTeamDayContext()
	const url = getPlanningShiftUrl({team, date})
	return (
		<ModalLink draggable="false" className="new-shift-button" to={url}>
			<div className="new-shift-button-inner">
				<img src="/images/web/icons/planning/plus.png" className="plus-icon" />
				<h5>{t('planning.environment_table.user_menu.create_shift')}</h5>
			</div>
		</ModalLink>
	)
}

const isDroppable = ({shift, shifts, date, envId}) => {
	const dateRange = shift.dateRange.changeDay(date)
	const userShifts = shifts.filter(s => s.user_id === shift.user_id)
	return shift.envId === envId && (shift.user_id === null || shift.date === date || !userShifts.some(s => s.dateRange.overlaps(dateRange)))
}

const Wrapper = props => {
	const {env} = useEnvTableContext()
	const {team} = useTeamContext()
	const {date} = useTeamDayContext()
	const {hoverActions, isHovering} = useHover()
	const teams = usePlanningTeams(env)
	const shifts = PlanningShift.where({team_id: teams.map(t => t.id), date})
	const formattedDate = date.format('YYYY-MM-DD')

	const onDrop = shift => {
		if (shift.date === formattedDate && shift.team_id === team.id) return
		shift.update({date, team_id: team.id})
	}

	const {isOver, canDrop, dropRef, dragItem, isDragging} = useDrop({
		accept: 'shift',
		canDrop: shift => isDroppable({shift, shifts, date: formattedDate, envId: env.id}),
		onDrop,
	})

	const isDraggingShift = dragItem?.type === 'shift'
	const classNames = makeCnVariants(
		'planning-day-shifts',
		isDragging && canDrop && 'droppable',
		isDragging && !canDrop && isDraggingShift && 'undroppable',
		isOver && canDrop && 'drag-is-over',
	)

	return (
		<div ref={dropRef} className={classNames} {...hoverActions}>
			<Shifts isHovering={isHovering} isDragging={isDragging} {...props} />
		</div>
	)
}

export default Wrapper
