import {exportT, useExportFilters} from 'cores/exports2/index'
import {mdT} from 'initializers/i18n'
import {t} from 'initializers/i18n'
import {makeCnVariants} from 'helpers'
import './styles/filter_sentence.less'

export const FilterSentence = ({item, className = 'vertical'}) => {
	// const {items: columnFilterItems, text: columnFilterEntityText} = useExportFilter(columnFilter)
	// const {items: rowFilterItems, text: rowFilterEntityText} = useExportFilter(rowFilter)
	const {row, column} = useExportFilters(item)
	const extraFilterLength = item.auxFilters().length

	const classNames = makeCnVariants(`export-filter-block-sentence`, className)

	return (
		<p className={classNames}>
			<FilterBaseSentence item={row} />
			{column.present && (
				<p className="column-firm-info">
					{t('common.column', {count: 2})}: {column.tableName} {t('common.in')}
					<b className="export-table-firm-names"> {column.firmsText} </b>
				</p>
			)}
			{extraFilterLength > 0 && <p>{exportT('extra_filters_active', {name: row.tableName, count: extraFilterLength})}.</p>}
		</p>
	)
}

export const FilterBaseSentence = ({item}) => {
	const row = `${t('common.row', {count: 2})}:`
	const firmText = item.present ? item.firmsText : t('common.no_filters_yet')
	if (item.type == 'users')
		return (
			<>
				<span> {row} </span>
				<b className="export-table-firm-names">{firmText}</b>
			</>
		)

	return (
		<p className="entity-info">
			{row} {t('common.all')} {item.tableName} {t('common.in')} <b className="export-table-firm-names"> {firmText} </b>
		</p>
	)
}

export const AccessSentence = ({item}) => {
	const {roles = []} = item
	const _roles = ['admin', ...roles]
		.uniq()
		.map(r => t(`rolesEnum.${r}`))
		.join(', ')

	return (
		<div className="access-sentence">
			<h5>{mdT('exports2.table_show.access_sentence.2', {roles: _roles})}</h5>
		</div>
	)
}
