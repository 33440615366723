import utils from '@eitje/web_utils'
import {mdT, t} from 'initializers/i18n'
import React, {forwardRef, Fragment, useRef, useState} from 'react'
import './styles/max_list.less'

export const MaxList = ({
	initialMax,
	containerClassName,
	openClassName,
	customContainerRef,
	scrollBack = true,
	moreTitle = t('showMore'),
	lessTitle = t('showLess'),
	children,
}) => {
	const [open, setOpen] = useState(false)
	const canBeOpened = initialMax < children.length
	const toRender = open ? children : children.slice(0, initialMax)
	const container = useRef(null)
	const _container = customContainerRef || container

	const className = utils.makeCns('max-list-container', containerClassName, openClassName)

	return (
		<div className={className} ref={container}>
			{toRender}
			{open && (
				<div
					className="column-with-border-and-padding-xs"
					onClick={() => {
						setOpen(false)
						scrollBack &&
							_container.current.scrollIntoView({
								behavior: 'smooth',
							})
					}}
				>
					<p className="grey-text">{lessTitle}</p>
				</div>
			)}
			{!open && canBeOpened && (
				<div className="read-more-button" onClick={() => setOpen(true)}>
					<p>{moreTitle}</p>
				</div>
			)}
		</div>
	)
}
