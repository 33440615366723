import {useEffect} from 'react'
import {exportT, ExportTable, Excel, ExportSubscription, useExportTaxonomy, useTableTax} from 'cores/exports2/index'
import {ProtectedRoute} from 'components/private_route'
import SimpleProvider from 'contexts/simple'
import {Redirect} from 'components/routing'
import {Footer} from './footer'
import {Route, Switch, useLocation, useRouteMatch} from 'react-router-dom'
import utils from '@eitje/web_utils'
import {steps} from './constants'
import {useMergeState} from '@eitje/react-hooks'
import {useGetEnvWithRole} from 'hooks'
import {PageLayout} from 'common/components'

const existsMerge = (obj1, obj2) => {
	const omitted1 = _.omitBy(obj1, v => !utils.exists(v))
	const omitted2 = _.omitBy(obj2, v => !utils.exists(v))

	return {...omitted1, ...omitted2}
}

export const TableEdit = () => {
	const item = ExportTable.show()
	if (!item.id) return <Redirect to="/exports2/tables" />
	return <Inner item={item} />
}

export const Inner = ({item}) => {
	const env = useGetEnvWithRole()
	const {defaultColumn} = useTableTax(item.table_name)

	const defaultFirmFilter = {
		filter: 'firms',
		value: {environment_ids: [env.id]},
	}

	const defaultFields = {
		row_filters: [defaultFirmFilter],
		columns: [{column: defaultColumn}],
		row_sort: {column: defaultColumn, direction: 'ascending'},
	}

	if (item.hasColumnFilter()) {
		defaultFields['column_filters'] = [defaultFirmFilter]
	}

	const defaultRecord = existsMerge(defaultFields, item)

	const [updateState, setUpdateState, setRawUpdateState] = useMergeState(defaultRecord)

	useEffect(() => {
		if (item.id) {
			setRawUpdateState(defaultRecord)
			// ensure form state is overwritten after every submit
		}
	}, [item.updated_at])

	const formItem = new ExportTable(updateState)

	const resetUpdateState = () => setRawUpdateState(defaultRecord)

	const loc = useLocation()
	const {url: baseUrl} = useRouteMatch()

	const pathParts = loc.pathname.split('/')

	const baseIndex = pathParts.indexOf('edit')

	const activeStepName = pathParts[baseIndex + 1]
	const activeStepItem = steps.find(s => s.name == activeStepName)
	let activeStep = steps.findIndex(s => s.name == activeStepName)

	if (activeStep < 1) return <Redirect to={`${baseUrl}/columns`} />

	const nextStep = steps[activeStep + 1]
	const prevStep = steps[activeStep - 1]

	const doneStep = item.filtersDone() ? 3 : item.columnsDone() ? 2 : 1

	return (
		<div className="table-edit">
			<SimpleProvider
				resetUpdateState={resetUpdateState}
				updateState={updateState}
				setUpdateState={setUpdateState}
				doneStep={doneStep}
				formItem={formItem}
				item={item}
				activeStepItem={activeStepItem}
				activeStep={activeStep}
				prevStep={prevStep}
				baseUrl={baseUrl}
				nextStep={nextStep}
				steps={steps}
			>
				<PageLayout unsetPagePadding bodyLayoutProps={{block: true}} name="export-table-edit" footers={<Footer />}>
					<StepRoutes />
				</PageLayout>
			</SimpleProvider>
		</div>
	)
}

const StepRoutes = () => {
	const {path} = useRouteMatch()
	// path does not work.
	return (
		<Switch>
			{steps.map(s => (
				<ProtectedRoute roleInAnyEnv="admin" component={s.Component} path={`${path}/${s.name}`} />
			))}
		</Switch>
	)
}
