import {Fragment} from 'react'
import {usePicker} from '@eitje/form'
import {buildField} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {useLocation} from 'react-router-dom'
import {makeCnVariants} from 'helpers'
import {Layout} from '../layout'
import {Text} from '../text'
import './styles/index.less'

const Tab = props => {
	const {item, onChange, readOnly, active, TabWrapper = Fragment, withQueryParams} = props
	const {search} = useLocation()
	const to = `${item.path}${withQueryParams ? search : ''}`
	const {disabled} = item
	const classNames = makeCnVariants('eitje-tab', props.disabled && 'disabled', active && 'active', readOnly && 'read-only')

	const act = props.disabled || readOnly || disabled ? _.noop : () => onChange(item.value)
	return (
		<TabWrapper to={to}>
			<Text semiBold className={classNames} onClick={act}>
				{item.label}
			</Text>
		</TabWrapper>
	)
}

const _Tabs = props => {
	let {value, height = 'regular', onChange = _.noop, width, ...rest} = props
	const {pickerItems} = usePicker(props)
	const cnVariants = makeCnVariants('eitje-tabs', height, props.disabled && 'disabled')
	const classNames = utils.makeCns(cnVariants, !props.label && 'eitje-form-2-tabs-no-label')

	return (
		<Layout className={classNames} width={width} childrenEqual={!!width}>
			{pickerItems.map(i => (
				<Tab onChange={onChange} active={value == i.value} item={i} {...rest} />
			))}
		</Layout>
	)
}

export const Tabs = buildField(_Tabs, {className: 'eitje-tabs-container', withIcon: false})

export default Tabs
