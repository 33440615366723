import {t, ExportButton} from 'cores/exports2/index'
import {useSimpleContext} from 'contexts/simple'
import {DocsButton, EitjeButton, DeleteButton, TourButton, Layout} from 'common/components'
import {BackBreadcrumb} from 'components/ui'
import {useHasRoleInAnyEnv} from 'hooks'
import './styles/index.less'

export const Header = () => {
	return (
		<div className="export-tables-show-header">
			<BackBreadcrumb to="/exports2/tables" text={t('back')} />

			<TitleBlock />
			<Actions />
		</div>
	)
}

const TitleBlock = () => {
	const {item} = useSimpleContext()
	const {name, description} = item
	return (
		<div className="export-tables-show-title">
			<h3>{name} </h3>
			<p>{description} </p>
			<Layout name="exports2.table_show">
				<DocsButton to="7973792" t="subscription_docs" height="small" />
				<DocsButton to="7973795" t="copy_docs" height="small" />
				<TourButton blockNamespace height="small" tourId="447862" />
			</Layout>
		</div>
	)
}

const Actions = () => {
	const {item} = useSimpleContext()
	const isAdmin = useHasRoleInAnyEnv('admin')

	return (
		<div className="export-tables-show-header-actions">
			<ExportButton data-intercom-target="export-btn" colorSet="solid" id={item.id} />
			{isAdmin && (
				<>
					<EitjeButton iconLeft="arrow-redo" onClick={() => item.duplicate()} t="common.duplicate" />
					<EitjeButton link="edit" iconLeft="pencil" t="common.edit" />
					<DeleteButton onDel={() => item.destroy()} />
				</>
			)}
		</div>
	)
}
