import ActiveRecord from 'models/active_record/base'
import {date} from 'initializers/date'

class ContractHolder extends ActiveRecord {
	static defaultJoins = ['users']
	static tableName = 'contractHolders'

	endDate() {
		return this.end_date && date(this.end_date)
	}
}

export default ContractHolder
