import {API, where, backend} from '@eitje/easy_api'
import Form from 'initializers/form'
import {useUserContext} from 'contexts/user'
import {useAvFormContext} from 'cores/availability/components/form/availability_form_provider'
import SimpleInputNumber from 'lib/form/fields/simple_input_number'
import {AvCell, AvStatus} from 'cores/availability/components/availability_cell'
import './styles/user_availability.less'
import './styles/user_availability_header.less'

export {AvCell, AvStatus}

export const WeekShifts = ({date, userWeek = {}}) => {
	const {weekKind} = useAvFormContext()
	const {userId} = useUserContext()
	const {preferred_shifts} = userWeek
	return (
		<div>
			<div className="availability-title-cell">
				<p>Shifts in week </p>
			</div>
			<div className="availability-status-cell">
				<Form
					overrideInitialValues
					debounceTime={200}
					initialValues={{preferred_shifts, date}}
					onSubmit={data => API.create(weekKind, {date: date.format('YYYY-MM-DD'), ...data, user_id: userId}, {headers: {doNotLoad: true}})}
				>
					<SimpleInputNumber submitStrategy="throttledChange" field="preferred_shifts" min={0} max={7} />
				</Form>
			</div>
		</div>
	)
}
