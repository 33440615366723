import useEnvSetting from 'hooks/use_env_setting'
import {ModalLink} from 'components/routing'
import useShared from 'hooks/use_shared'
import {useEnvIntegration} from 'cores/integrations'

const integs = [
	{title: 'exportToNmbrs', id: 'nmbrs', path: 'NmbrsUpload'},
	{title: 'export_connexie', path: `ConnexieUpload`, id: 'connexie'},
	{title: 'export_horecalonen', path: `HorecalonenUpload`, id: 'horecalonen'},
]

export const LegacyIntegrationLink = () => {
	const integName = useEnvSetting('uren_reg', 'koppeling')
	const integ = integs.find(i => i.id == integName)
	const {hasFinancialRole} = useShared()
	const modernInteg = useEnvIntegration({hr: true})

	if (modernInteg?.sanitized_type == 'nmbrs' || !hasFinancialRole || !integ) return null
	const path = integ.path || integ.id

	return <ModalLink to={`/static_exports/${path}`} t={integ.title} />
}
