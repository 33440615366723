import {ListPicker} from 'common/components'
import {envSwitch} from 'actions/environment'
import {NavItemLayout, TRIGGER_STYLING} from './nav_item'
import {t} from 'initializers/i18n'
import {Environment} from 'models'
import {useShared} from 'hooks'

const Trigger = () => {
	const {env} = useShared()
	return <NavItemLayout title={t('common.environment')} subtitle={env.naam} />
}

export const EnvSwitch = () => {
	const {env} = useShared()
	const envs = Environment.all()

	if (envs.length <= 1) return null

	return (
		<ListPicker
			data-intercom-target="env_picker"
			raw
			dropdown
			Trigger={Trigger}
			trigger="hover"
			onChange={envSwitch}
			single
			value={env.id}
			items={envs}
			labelField="naam"
			strategy="fixed"
			{...TRIGGER_STYLING}
		/>
	)
}
