import {API, find} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import FileDragAndDrop from 'components/drag_and_drop'
import {UploadButton, DeleteButton, CreateButton, PageLayout} from 'common/components'
import useParams from 'hooks/use_params'
import {history} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'

const Header = ({button}) => {
	return (
		<div className="page-menu-container">
			<div className="page-menu">
				<div className="page-menu-options"></div>
				<div className="page-menu-options">{button}</div>
			</div>
		</div>
	)
}

const NewArticleBox = ({id}) => {
	return (
		<Link to={`/articles/${id}`}>
			<div className="select-box">
				<img width={38} height={48} src="/images/web/icons/blankArticleIcon.png" />
				<div style={{width: '70%'}}>
					<p className="bold" style={{marginBottom: '4px'}}>
						{t('startBlank')}
					</p>
					<p className="eitje-text-secondary">{t('startArticleDesc')}</p>
				</div>
				<CreateButton />
			</div>
		</Link>
	)
}

export const PdfDropBox = ({isDragActive = false, ...rest}) => {
	const containerClass = utils.makeCns('select-box', isDragActive && 'active')
	return (
		<div className={containerClass} {...rest}>
			{rest.children}
			<img width={38} height={48} src="/images/web/icons/pdfIcon.png" />
			<div style={{width: '70%'}}>
				<p className="bold" style={{marginBottom: '4px'}}>
					{t('upload')} .PDF
				</p>
				<p className="eitje-text-secondary">{t('dropPdfDesc')}</p>
			</div>
			<UploadButton height="small" />
		</div>
	)
}

const isFilled = ({content, pdf_content, published, read_count}) => {
	return utils.exists(content) || utils.exists(pdf_content) || read_count > 0 || published
}

const Page = () => {
	const {id} = useParams()
	const article = useSelector(state => find(state, 'infos', id))

	useEffect(() => {
		if (!_.isEmpty(article) && isFilled(article)) {
			history.goBack()
		}
	}, [])

	if (_.isEmpty(article)) {
		return <Redirect push to="/topics" />
	}

	const onDrop = async file => {
		if (file[0].size >= 20000000) return utils.errNotif(t('pdfTooBig1'), t('pdfTooBig2'))
		const res = await API.updateAsset('infos', id, file, {doLoad: true})
		if (res.ok) {
			history.replace(`/articles/${id}`)
		}
	}

	const deleteButton = <DeleteButton afterDel={() => history.goBack} onDel={() => API.destroy('infos', id)} />

	return (
		<PageLayout name="blank-article" headers={<Header title={article.title} button={deleteButton} />}>
			<div className="row-with-justify-center gap-xl" style={{height: 'calc(100vh - 200px)', overflow: 'hidden'}}>
				<NewArticleBox id={id} />
				<FileDragAndDrop custom accept="application/pdf" onDrop={onDrop} Component={PdfDropBox} />
			</div>
		</PageLayout>
	)
}

export default Page
