import {t} from 'initializers/i18n'
import {useWhere} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {PlanningShift} from 'models/index'
import {calcShiftCost} from 'cores/revenue/index'
import {useAll} from '@eitje/easy_api'
import Allowance from 'models/allowance'
import {useOrgEnvSettingKeyValue} from 'hooks'
import {contractJoins as joins} from 'constants/users'
import {Text, InfoCard, Layout} from 'common/components'

const shiftMapper = shift => _.omit({...shift, start_date: shift.from, end_date: shift.till}, 'dateRange')

const ShiftCosts = ({data, ...rest}) => {
	const {user_id, date} = data
	const user = useWhere('users', [user_id], joins)[0]
	const shift = new PlanningShift(shiftMapper(data))
	const teams = useAll('teams')
	const holidays = useOrgEnvSettingKeyValue('holidays', 'active')
	const employmentTypes = useAll('employmentTypes')
	const allowances = Allowance.where({active: true})
	const {costs, ...other} = calcShiftCost({shift, user, allowances, teams, holidays, employmentTypes})

	if (!user || !date) return null

	const popoutBody = costs && <CostExplained costs={costs} {...other} shift={shift} />
	const value = !costs ? t('common.not_available') : utils.money(costs)
	return <InfoCard popoutBody={popoutBody} label={t('common.wage_costs')} children={value} icon="euro" {...rest} />
}

const CostExplained = ({costs, shift, hourlyCost, grossCosts, wage, conversionFactor, allowanceFactor}) => {
	const hasGross = utils.exists(grossCosts)
	grossCosts = utils.money(grossCosts)
	wage = utils.money(wage)
	costs = utils.money(costs)

	const firstKey = hasGross ? 'gross_salary' : 'hourly_labour_cost'
	const wageVal = hasGross ? wage : utils.money(hourlyCost)
	const firstTotalVal = hasGross ? grossCosts : costs
	const firstTotalKey = hasGross ? 'gross_costs' : 'net_costs'

	return (
		<Layout direction="vertical">
			<Text small>
				{t('common.shift_duration')} {shift.stringMins()} * {t(`common.${firstKey}`)} ({wageVal}) * {t('common.allowance_factor')} (
				{allowanceFactor?.round(2)}) ={' '}
				<Text small bold>
					{t(`common.${firstTotalKey}`)} ({firstTotalVal})
				</Text>
			</Text>

			{hasGross && (
				<Text small>
					{t('common.gross_costs')} ({grossCosts}) * {t('common.conversion_factor')} ({conversionFactor}) ={' '}
					<Text small bold>
						{t('common.net_costs')} ({costs})
					</Text>
				</Text>
			)}
		</Layout>
	)
}

export default ShiftCosts
