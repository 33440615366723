import {all} from '@eitje/easy_api'
import {topicQuestionOverview} from 'helpers/topics'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import useTopicResources from '../topics/use_topic_resources'
import './components.less'

export const ProgressDot = ({selected}) => {
	const className = selected ? 'checkbox-outer selected' : 'checkbox-outer'
	return (
		<div className="check-box-container">
			<div className={className}>
				<div className="checkbox-inner" />
			</div>
		</div>
	)
}

export const ProgressDots = ({total, currentIdx}) => {
	return (
		<div className="progress-dots fRow">
			{_(total).times(i => (
				<div style={i < total - 1 ? {paddingRight: '8px'} : {}}>
					<ProgressDot color={'#0496ff'} selected={i < currentIdx} />
				</div>
			))}
		</div>
	)
}

const NumberBox = ({number, text, color}) => {
	return (
		<div className="training-analytics-numbers">
			<div className="number-and-dot-wrapper" style={{position: 'relative', width: 'unset'}}>
				<p className="number">{number}</p>
				<div className="dot" style={color && {backgroundColor: color}} />
			</div>
			<div className="eitje-text-secondary">{text}</div>
		</div>
	)
}

const DataRow = ({label, value}) => {
	const valueText = value.toString() === 'NaN' ? 'n/a' : value
	return (
		<div className="modal-data-row">
			<p className="eitje-text-secondary">{t(label)}</p>
			<p className="bold">{valueText}</p>
		</div>
	)
}

const QuizOverview = ({user_answers, topicQuizItems = []}) => {
	const hasQuiz = topicQuizItems.length > 0
	if (!hasQuiz) return null
	const detailedItems = topicQuestionOverview(user_answers, topicQuizItems)
	return (
		<div style={{width: '100%'}}>
			{Object.keys(detailedItems).map(key => (
				<DataRow label={key} value={detailedItems[key]} />
			))}
		</div>
	)
}

export const TrainingResults = ({topic}) => {
	const {started = [], finished = [], total = 0, user_answers, team_ids, days_to_start, valid_untill, always_trainable} = topic

	const totalUserPool = useSelector(state => all(state, 'users')).filter(user => user.environment_ids.length > 0)
	const _started = totalUserPool.filter(user => started.includes(user.id))
	const _finished = totalUserPool.filter(user => finished.includes(user.id))

	const {publishedTopicResources: topicResources, topicQuizItems, done_user_ids = []} = useTopicResources(topic)
	const totalLeft = (days_to_start || valid_untill || always_trainable) && total

	const hasTrainingData = !_.isEmpty(_started) || !_.isEmpty(_finished) || totalLeft

	return (
		<div className="padding-xl">
			{hasTrainingData ? (
				<div className="row-with-border-and-padding-lg row-aro margin-bottom-lg">
					<NumberBox number={started.length} color="#0496FF99" text={t('startedTraining')} />
					<NumberBox number={finished.length} color="#00D37B99" text={t('finishedTraining')} />
					{totalLeft && <NumberBox number={totalLeft} color="#FF002099" text={t('total')} />}
				</div>
			) : (
				<div className="row-with-border-and-padding-sm">
					<p className="eitje-text-secondary">{t('noTrainingData')}</p>
				</div>
			)}
			<QuizOverview user_answers={user_answers} topicQuizItems={topicQuizItems} />
		</div>
	)
}
