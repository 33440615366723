import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import useContractMins from 'hooks/use_contract_mins'

const WithContract = ({schedule, timeString}) => {
	const {totalMins} = useContractMins(schedule.id)
	return (
		<p>
			{timeString} / {utils.minToTimeString(totalMins)}
		</p>
	)
}

const AmtWorked = ({user, shifts, dateRange}) => {
	const totalWorked = shifts.filter(s => !s.isPlan()).sum(s => s.totalMins())
	const totalPlanned = shifts.filter(s => s.isPlan()).sum(s => s.totalMins())

	const timeString = getTimeString({totalWorked, totalPlanned})

	const workSchedule = useFind('workSchedules', {user_id: user.id, dateRange})
	if (!workSchedule.id) return <p>{timeString} </p>
	return <WithContract timeString={timeString} schedule={workSchedule} />
}

const getTimeString = ({totalWorked, totalPlanned}) => {
	let str = utils.minToTimeString(totalWorked)
	if (totalPlanned) str = `${str} (${utils.minToTimeString(totalPlanned)})`
	return str
}

export default AmtWorked
