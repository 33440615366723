import {YouTubeModal} from 'common/components'
import {featureExplanationsTaxonomy} from 'cores/onboarding'
import {NAMESPACE} from 'cores/onboarding/pages/welcome'
import {t} from 'initializers/i18n'

export const FeatureExplanationModal = ({location}) => {
	const taxonomy = featureExplanationsTaxonomy()
	const taxonomyKey = _.last(location.pathname.split('/'))
	const taxItem = taxonomy.find(taxItem => taxItem.id === taxonomyKey)
	const {id, youTubeId} = taxItem
	const ns = `${NAMESPACE}.feature_explanation_modal.${id}`
	const title = t(`${ns}.title`)
	const subtitle = t(`${ns}.subtitle`)

	return <YouTubeModal name="feature-explanation" title={title} subtitle={subtitle} youTubeId={youTubeId} />
}
