import {all, find, whereNot} from '@eitje/easy_api'
import _ from 'lodash'
import {date} from 'initializers/date'
import createCachedSelector from 're-reselect'
import {createSelector} from 'reselect'
import {currentEnvSelector, currentOrgSelector} from 'selectors/records'
import {getRole, orgRolesSelector} from 'selectors/settings'
const teamsSelector = state => all(state, 'teams')
export const envIdSelector = state => state.environment.active
const envsSelector = state => all(state, 'environments')
const orgSelector = state => currentOrgSelector(state)
export const usersSelector = state => all(state, 'users')
const userSelector = state => state.auth.user
const otherEnvSelector = state => state.entities.otherOrgEnvs
const activeTeamsSelector = state => state.entities.activeTeams
const activeEnvs = state => state.entities.activeEnvs
const stateSelector = state => state
const tempSelector = state => state.entities.roosterTemplates
const entSelector = state => state.entities
export const getTeam = (state, id) => state.entities.teams.find(team => team.id == id)

export const envEntitySelector = createCachedSelector(
	entSelector,
	(state, key) => key,
	(state, key, envId) => envId || state.environment.active,
	(entities, key, envId) => entities[key].filter(i => i.environment_id === envId),
)((entities, key, envId) => `${envId}-${key}`)

export const activeEntitySelector = createCachedSelector(envEntitySelector, (entities, key, envId) => entities.filter(e => !!e.active))(
	(state, key, envId) => `${key}-${envId}`,
)

export const templateSelector = createCachedSelector(
	tempSelector,
	(state, envId) => envId,
	(temps, envId) => temps.filter(t => t.environment_id === envId),
)((temps, envId) => envId)

export const entitySelector = createCachedSelector(
	state => state,
	(state, key) => key,
	(state, key, id) => id,
	(state, key, id) => state.entities[key].find(i => i.id === id),
)((state, key, id) => `${key}-${id}`)

export const entitiesSelector = createCachedSelector(
	entSelector,
	(state, key) => key,
	(state, key, ids) => ids,
	(entities, key, ids) => entities[key].filter(i => ids.includes(i.id)),
)((state, key, ids) => `${key}-${ids.join(',')}`)

export const envSelector = createSelector(envIdSelector, envsSelector, (envId, envs) => envs.find(e => e.id == envId))

export const orgEnvsSelector = createSelector(envsSelector, orgSelector, envSelector, (envs, org, env) =>
	org.id ? envs.filter(e => org.environment_ids.includes(e.id)) : [env],
)

export const orgEnvRoleSelector = createCachedSelector(
	orgEnvsSelector,
	orgRolesSelector,
	(state, role) => role,
	(orgEnvs, orgRoles, role) => orgEnvs.filter(e => orgRoles[e.id] && orgRoles[e.id][role]),
)((state, role) => role)

export const isDemo = createSelector(envSelector, env => env?.status === 'demo')

export const teamSelector = createCachedSelector(
	teamsSelector,
	(state, teamId) => teamId,
	(teams, teamId) => teams.find(t => t.id === teamId),
)((teams, teamId) => teamId)

export const userTeamsSelector = createSelector(teamsSelector, userSelector, (teams, user) =>
	teams.filter(t => t.user_ids.includes(user.id)),
)

export const envTeamsSelector = createCachedSelector(
	teamsSelector,
	envIdSelector,
	(state, envId) => envId,
	(teams, active, envId) => teams.filter(t => t.environment_id == (envId || active)),
)((state, envId) => envId || state.environment.active)

export const adminEnvTeamsSelector = createSelector(
	envTeamsSelector,
	userSelector,
	state => getRole(state, 'admin'),
	(teams, user, isAdmin) => (isAdmin ? teams : teams.filter(t => t.user_ids.includes(user.id))),
)

export const userEnvTeamsSelector = createCachedSelector(
	envTeamsSelector,
	userSelector,
	(state, envId) => envId,
	(envTeams, user) => envTeams.filter(t => t.user_ids.includes(user.id)),
)((state, envId) => envId || state.environment.active)

export const allOrgEnvs = createSelector(orgEnvsSelector, otherEnvSelector, (myEnvs, otherEnvs) => [...myEnvs, ...otherEnvs])

export const orgTeamsSelector = createSelector(teamsSelector, orgEnvsSelector, envIdSelector, orgSelector, (teams, envs, env, isOrg) =>
	teams.filter(t => (envs.length > 0 && isOrg ? envs.map(e => e.id).includes(t.environment_id) : t.environment_id == env)),
)

export const nonLockedTeamsSelector = createSelector(teamsSelector, envIdSelector, (teams, active) =>
	teams.filter(t => t.environment_id == active && !t.locked),
)

export const nonLockedTeamsSelectorEnv = createCachedSelector(
	teamsSelector,
	(state, envId) => envId,
	(teams, envId) => teams.filter(t => t.environment_id === envId && !t.locked),
)((state, envId) => envId)

export const roosterTeamsSelector = createSelector(nonLockedTeamsSelector, teams =>
	_.sortBy(
		teams.filter(t => !t.onroosterbaar),
		te => -te.rooster_order,
	),
)

export const activeRoosterTeamsSelector = createSelector(activeTeamsSelector, roosterTeamsSelector, (activeTeams, roosterTeams) =>
	activeTeams.length == 0 ? roosterTeams : roosterTeams.filter(te => activeTeams.includes(te.id)),
)

export const activeEnvsSelector = createSelector(orgEnvsSelector, activeEnvs, (allEnvs, activeEnvs) =>
	activeEnvs.length === 0 ? allEnvs : allEnvs.filter(e => activeEnvs.includes(e.id)),
)

export const nonLockedTeamsSelectorAll = createSelector(teamsSelector, teams => {
	return teams.filter(t => !t.locked)
})

export const nonLockedTeamsSelectorOrg = createSelector(teamsSelector, orgEnvsSelector, (teams, orgEnvs) => {
	const envIds = orgEnvs.map(e => e.id)
	return teams.filter(t => !t.locked && envIds.includes(t.environment_id))
})

export const roosterTeamsSelectorOrg = createSelector(nonLockedTeamsSelectorOrg, teams =>
	_.sortBy(
		teams.filter(t => !t.onroosterbaar),
		te => -te.rooster_order,
	),
)

export const roosterTeamsSelectorAll = createSelector(nonLockedTeamsSelectorAll, teams =>
	_.sortBy(
		teams.filter(t => !t.onroosterbaar),
		te => -te.rooster_order,
	),
)

export const activeRoosterTeamsSelectorAll = createSelector(activeTeamsSelector, roosterTeamsSelectorAll, (activeTeams, roosterTeams) =>
	activeTeams.length == 0 ? roosterTeams : roosterTeams.filter(te => activeTeams.includes(te.id)),
)

export const getRoosterTeams = createCachedSelector(
	activeRoosterTeamsSelectorAll,
	(state, envId = state.environment.active) => envId || state.environment.active || 'noEnv',
	(teams, envId) => {
		return teams.filter(t => t.environment_id === envId)
	},
)((teams, envId) => `${JSON.stringify(teams)}-${envId}`)

export const getAllRoosterTeams = createCachedSelector(
	roosterTeamsSelectorAll,
	(state, envId) => envId,
	(teams, envId) => teams.filter(t => t.environment_id === envId),
)((teams, envId) => envId)

export const getFutureVerlof = createCachedSelector(
	state => state.records.leaveRequests,
	(state, userId) => userId,
	(vvs, userId) => vvs.filter(v => v.user_id === userId && date(v.start) > date()),
)((state, userId) => userId)

export const filterBy = createCachedSelector(
	state => state,
	(state, key) => key,
	(state, key, params) => params,
	(state, key, params) => filter(state.entities[key], params),
)((state, key, params) => `${JSON.stringify(params)}-${key}`)

const filter = (items, params) => {
	return items.filter(i => Object.keys(params).every(p => i[p] === params[p]))
}

const filterUsers = (users, {teams = []}) => {
	if (teams.length === 0) return users
	return users.filter(u => u.team_ids.some(id => teams.includes(id)))
}

export const envUsers = createCachedSelector(
	usersSelector,
	(state, envId) => envId || envIdSelector(state),
	(users, envId) => users.filter(u => u.environment_ids.includes(envId)),
)((state, envId) => envId || envIdSelector(state))

export const roosterbaarUsersAll = createSelector(usersSelector, orgEnvsSelector, (users, envs) =>
	users.filter(u => !envs.some(e => e.onroosterbaar_user_ids.includes(u.id))),
)

export const orgUsersSelector = createSelector(usersSelector, orgEnvsSelector, (users, envs) =>
	users.filter(u => envs.some(e => e?.user_ids && e.user_ids.includes(u.id))),
)

export const roosterbaarUsers = createSelector(envUsers, currentEnvSelector, (users, env) =>
	users.filter(u => !env.onroosterbaar_user_ids.includes(u.id)),
)

export const nonGeneralEnvUsers = createSelector(envUsers, nonLockedTeamsSelector, (users, teams) =>
	filterUsers(users, {teams: teams.map(te => te.id)}),
)

export const nonGeneralRoosterbaarUsers = createSelector(roosterbaarUsers, nonGeneralEnvUsers, (users1, users2) =>
	users1.filter(u => users2.includes(u)),
)

export const nonGeneralRoosterbaarUsersAll = createSelector(roosterbaarUsersAll, nonLockedTeamsSelectorAll, (users, teams) =>
	filterUsers(users, {teams: teams.map(te => te.id)}),
)

export const schedulableUsersEnv = createCachedSelector(
	envUsers,
	(state, envId) => find(state, 'environments', envId),
	(users, env) => users.filter(u => !env.onroosterbaar_user_ids.includes(u.id)),
)((state, envId) => envId)

export const roosterbaarUsersEnv = createCachedSelector(
	nonGeneralRoosterbaarUsersAll,
	(state, envId) => envId,
	(users, envId) => users.filter(u => u.environment_ids.includes(envId)),
)((users, envId) => envId)

export const generalRoosterbaarUsersEnv = createCachedSelector(
	roosterbaarUsersAll,
	(state, envId) => envId,
	(users, envId) => users.filter(u => u.environment_ids.includes(envId)),
)((users, envId) => envId)

const schedulableUserEnv = createSelector(nonGeneralRoosterbaarUsersAll, currentEnvSelector, (users, env) =>
	users.filter(u => u.environment_ids.includes(env.id)),
)

export const activeEnvRoosterUsers = createSelector(schedulableUserEnv, activeTeamsSelector, (users, activeTeams) =>
	filterUsers(users, {teams: activeTeams}),
)

export const activeEnvUsers = createSelector(nonGeneralEnvUsers, activeTeamsSelector, (users, activeTeams) =>
	filterUsers(users, {teams: activeTeams}),
)

export const futurePostSelector = createSelector(
	state => whereNot(state, 'posts', {published: true}),
	posts => posts,
)
