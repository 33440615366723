import utils from '@eitje/web_utils'
import {t, StatusCard} from 'cores/time_registration/index'
import {Layout, Text} from 'common/components'
import {ColorCircle} from 'components/ui'

const PADDING = [4, 8]
const MARGIN = [8, 0, 0, 0]

export const TimeBlock = ({shift}) => {
	const shifts = utils.alwaysArray(shift)
	const status = shifts[0].regStatus()
	return (
		<Layout type="card" direction="vertical" padding={8} width={210}>
			<Layout>
				<StatusCard shift={shifts[0]} />
				<Text small bold>
					{t(`time_status.${status}.title`)}
				</Text>
			</Layout>
			{shifts.map(s => (
				<ShiftTimeBlock shift={s} status={status} />
			))}
			{!_.isArray(shift) && shift.system_checkout && <SystemCheckout />}
		</Layout>
	)
}

const SystemCheckout = () => {
	return (
		<Layout type="card" padding={PADDING}>
			<ColorCircle text="!" />
			<Text mini grey>
				{' '}
				{t('add_modal.system_checkout')}{' '}
			</Text>
		</Layout>
	)
}

export const ShiftTimeBlock = ({shift, status}) => {
	return (
		<>
			<Layout childrenGrow>
				<InnerBlock time={shift.startTime()} text={`time_status.${status}.from`} />
				<InnerBlock time={shift.endTime({isHours: true})} text={`time_status.${status}.till`} />
			</Layout>
			{shift.exported_count > 0 && <ShiftExported />}
		</>
	)
}

const ShiftExported = () => {
	return (
		<Layout type="card" padding={PADDING} margin={MARGIN}>
			<ColorCircle text="!" />
			<Text mini grey>
				{t('add_modal.shift_exported')}
			</Text>
		</Layout>
	)
}

const InnerBlock = ({text, time}) => {
	return (
		<Layout type="card" padding={8} direction="vertical" horizontal="center">
			<Text mini grey>
				{t(text)}
			</Text>
			<Text small bold>
				{time}
			</Text>
		</Layout>
	)
}
