import {CheckInPopoverWrapper, getTimeShiftUrl, baseColorMapping, MultiActionCheckbox, useEditableShifts} from 'cores/time_registration'
import {navigateModal} from 'actions/routing'
import {t} from 'initializers/i18n'
import {WeekTableCell, usePageContext, WeekTableCellItem} from 'common/components'
import {useDrag, useShared, useHasRole} from 'hooks'
import {useClock} from 'components/common/index'
import {Team} from 'models'

export const TimeRegistrationShiftCell = ({shift, disableDrag}) => {
	const {multiEdit, selectedShifts, setSelectedShifts} = usePageContext()
	const {multiEnvOrg} = useShared()
	const isCheckIn = shift.isCheckIn()
	const baseString = `${shift.startTime()} - `
	const breaktime = shift.pauze_duur > 0 ? `(${shift.pauze_duur})` : ''
	const titleString = isCheckIn ? baseString + '...' : `${baseString} ${shift.endTime({isHours: true})} ${breaktime}`
	const team = Team.find(shift.team_id)
	const {environment} = team
	const hasAccordRole = useHasRole('uren_accorderen', environment.id)
	const status = shift.regStatus()
	const baseColor = baseColorMapping[status]
	const colorCircle = `medium${_.capitalize(baseColor)}`
	const editAllowed = hasAccordRole || shift.editableByWriter()
	const condOpts = {}
	if (editAllowed && !isCheckIn) {
		condOpts.onClick = multiEdit ? () => setSelectedShifts([shift.id]) : () => navigateModal(getTimeShiftUrl({shift}))
	}

	// Drag and drop
	const shiftIsEditable = !!useEditableShifts([shift])[0]
	const {isDragging, dragRef} = useDrag({
		type: `time-registration-shift`,
		item: {id: shift.id, shift},
		canDrag: !disableDrag && editAllowed && !shift.isPlan() && shiftIsEditable,
	})

	// Cell items
	const workedTime = !isCheckIn && {type: 'title', text: `${shift.totalMinsString()} ${t('common.hours')}`}
	const checkInTime = isCheckIn && <CheckInCellItem baseColor={baseColor} shift={shift} />
	const shiftStatus = {type: 'subtitle', colorCircle, text: t(`time_registration.statusses.${status}`)}
	const startEndTimes = {type: 'subtitle', text: titleString, localValue: 'hoursPerMemberShowStartEnd'}
	const shiftEnv = multiEnvOrg && {text: environment.naam, localValue: 'hoursPerMemberShowEnvironment'}
	const shiftTeam = {text: team.naam, localValue: 'hoursPerMemberShowTeam'}
	const exported = shift.exported() && {colorCircle: 'mediumGrey', type: 'subtitle', text: t('common.exported')}

	return (
		<WeekTableCell
			cellType="card"
			LeftContent={multiEdit && MultiActionCheckbox}
			leftContentProps={{shifts: [shift], disabled: isCheckIn || !editAllowed}}
			colorSet="color-bordered-black-content"
			ref={dragRef}
			Wrapper={isCheckIn && CheckInPopoverWrapper}
			wrapperProps={{shift}}
			{...condOpts}
			baseColor={baseColor}
			items={[workedTime, checkInTime, shiftStatus, exported, startEndTimes, shiftEnv, shiftTeam]}
		></WeekTableCell>
	)
}

const CheckInCellItem = ({shift, ...rest}) => {
	const timeElapsed = useClock(shift.start_date)
	return <WeekTableCellItem {...rest} type="title" text={timeElapsed} />
}
