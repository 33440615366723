import {useFilterFooterContext} from 'common/components/filter_footer/context'
import usePlanningDates from './use_planning_dates'
import useScheduledShifts from './use_scheduled_shifts'
import User from 'models/user'
import {contractJoins as opts} from 'constants/users'
import {filterItems} from 'hooks/use_search'
import {useSearchContext} from 'contexts/search'

export const useActiveUsers = () => {
	const {
		filterValues: {teamIds},
	} = useFilterFooterContext()
	const {search} = useSearchContext()
	const {dateRange} = usePlanningDates()
	let {planShifts} = useScheduledShifts({dateRange, teamIds})
	const usersInTeams = User.where({team_ids: teamIds}, opts)
	const usersWithShiftsInTeams = User.where(planShifts._map('user_id'), opts).uniq()
	let activeUsers = _.uniqBy([...usersInTeams, ...usersWithShiftsInTeams], 'id')

	if (search) {
		activeUsers = filterItems(activeUsers, search, {searchField: 'full_name'})
	}
	return _.sortBy(activeUsers, 'full_name')
}
