import {createStore, applyMiddleware, compose} from 'redux'
import reducers from './reducers'
import Reactotron from 'initializers/reactotron.js'
import {REHYDRATE, PURGE, persistCombineReducers, persistStore} from 'redux-persist'
import Localforage from 'localforage'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

const config = {
	key: 'eitje-web',
	storage: Localforage,
}

const makeStore = Reactotron ? Reactotron.createStore : createStore

const persistedReducers = persistCombineReducers(config, reducers)

const rootReducer = (state, action) => {
	return persistedReducers(state, action)
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const store = makeStore(rootReducer, undefined, compose(applyMiddleware(LogRocket.reduxMiddleware()), sentryReduxEnhancer))

const persistor = persistStore(store, null, () => {
	store.getState()
})

export default () => ({store, persistor})
