import {HeaderCell} from 'common/components/advanced_table'
import {Layout} from 'common/components'
import './styles/index.less'

export * from './components'
export * from './helpers'

export const AdvancedTableHeader = ({headers = [], ...rest}) => {
	return (
		<Layout className="advanced-table-header" width="100%" gap={0}>
			{headers.map(h => (
				<HeaderCell header={h} {...rest} />
			))}
		</Layout>
	)
}
