import React, {useEffect} from 'react'
import {useForm} from '@eitje/form'
import {FormRow} from 'components/ui'
import {useFormData} from 'hooks'
import {IsWeekSelector, PeriodSelectPicker} from 'common/components'
import {t} from 'initializers/i18n'
import {useDateCounts} from 'cores/planning'

export const PeriodsSelector = props => {
	const {
		setValues,
		form: {props: formProps},
	} = useForm()
	const {periods, is_week} = useFormData()
	const {data, periodData, periodLists} = useDateCounts({
		is_week,
		...props,
	})

	useEffect(() => {
		setValues({periods: []})
	}, [is_week])

	useEffect(() => {
		setValues({
			periods: periods?.filter(period => periodData?.[period]?.count !== 0) || [],
		})
	}, [data])

	const isWeekLabel = `form.${formProps?.transNamespace}.fields.is_week.label`

	return (
		<>
			<FormRow>
				<IsWeekSelector label={t(isWeekLabel)} />
			</FormRow>
			<FormRow>
				<PeriodSelectPicker field="periods" periodData={periodData} periodLists={periodLists} />
			</FormRow>
		</>
	)
}
