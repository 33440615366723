import {ChannelPreview, SearchResultMessagePreview} from './preview'
import {Title, Layout} from 'common/components'

const SearchResultTitle = ({t}) => (
	<Layout padding="10 12" borderBottom heigth={56}>
		<Title semiBold fontSize={14} t={t} />
	</Layout>
)

export const SearchResultsList = ({results}) => {
	const {channels, messages} = results.reduce(
		(acc, curr) => {
			const type = curr.message ? 'messages' : 'channels'
			acc[type].push(curr)
			return acc
		},
		{channels: [], messages: []},
	)

	const hasChannels = !!channels.length
	const hasMessages = !!messages.length

	return (
		<>
			{hasChannels && (
				<>
					<SearchResultTitle t="chats" />
					{channels.map(result => (
						<ChannelPreview key={result.id} channel={result} />
					))}
				</>
			)}
			{hasMessages && (
				<>
					<SearchResultTitle t="messages" />
					{messages.map(result => (
						<SearchResultMessagePreview key={result.message.id} message={result.message} />
					))}
				</>
			)}
		</>
	)
}
