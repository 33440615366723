import ActiveRecord, {DateRangeActiveRecord} from 'models/active_record/base'

const _defaultObj = {a: 3}

window.test = (times, defaultObj = _defaultObj) => {
	testIndividual(times, 'making AR', () => new ActiveRecord(defaultObj))
	testIndividual(times, 'making AR Date', () => new DateRangeActiveRecord(defaultObj))
	testIndividual(times, 'making objects', () => new Object(defaultObj))
	// testIndividual(times, 'making assocs', () => new Association([]))
}

const testIndividual = async (times, key, fn) => {
	console.time(key)
	_.times(times, fn)
	console.timeEnd(key)
}

window.testIndividual = testIndividual
