import {t} from 'initializers/i18n'
import {ShiftType} from 'models'
import {Layout, EitjeButton, ListPicker, Text} from 'common/components'
import {CreateButton} from 'common/components/eitje_button/eitje_buttons'
import {useHasRoleInAnyEnv} from 'hooks'

const AmountTooltip = ({item}) => {
	const fields = 'records.shift_type.fields'
	const hideEnd = item.hide_end && t(`${fields}.hide_end`)
	const showCall = item.show_call && t(`${fields}.show_call`)
	const options = [hideEnd, showCall].filter(Boolean)
	const optionAmount = options.length
	const title = options.join(' & ')

	if (optionAmount === 0) return null
	return (
		<div className="shift-type-item-right">
			<Text mini grey popoutTitle={title}>
				{optionAmount} {t(`${fields}.extra_options`, {count: optionAmount})}
			</Text>
		</div>
	)
}

const ListItemContent = ({item}) => {
	return (
		<Layout spaceBetween>
			<p className="list-picker-item-text">{item.nameWithEmoji()}</p>
			<AmountTooltip item={item} />
		</Layout>
	)
}

export const ShiftTypePicker = props => {
	const shiftTypes = ShiftType.all()
	const isAdmin = useHasRoleInAnyEnv('admin')
	const buttonText = `${t('common.manage')} ${t('records.shift_type.name_plural')}`

	const placeholderProps = {
		name: 'shift_types',
		animation: 'target-and-briefcase',
		subtitle: false,
		children: <CreateButton tabIndex={-1} modalLink="/shift_types" t="action_button" width="full" />,
	}

	return (
		<ListPicker
			single
			dropdown
			items={shiftTypes}
			field="shift_type_id"
			itemDisabled={item => !item?.active}
			ListItemContent={ListItemContent}
			sortField="active"
			sortOrder="desc"
			placeholderProps={isAdmin && placeholderProps}
			{...props}
		>
			{isAdmin && <EitjeButton tabIndex={-1} modalLink="/shift_types" children={buttonText} width="full" />}
		</ListPicker>
	)
}

export default ShiftTypePicker
