import React, {useEffect} from 'react'
import useFormData from 'hooks/use_form_data'
import Form from './form/index'
import HoursOverview from './hours_overview/index'
import {useSimpleContext} from 'contexts/simple'
import UserPicker from './user_picker/index'
import './styles/body.less'

const Body = () => {
	const {isPickingUser, setIsPickingUser, isEdit} = useSimpleContext()
	const {user_id} = useFormData()

	useEffect(() => {
		if (user_id && isEdit) {
			setIsPickingUser(false)
		} else {
			setIsPickingUser(true)
		}
	}, [user_id])

	return (
		<div className="time-registration-modal-body">
			<Form />
			<div className="time-registration-modal-body-right-column">{isPickingUser ? <UserPicker /> : <HoursOverview />}</div>
		</div>
	)
}

export default Body
