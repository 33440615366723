import {PlanningShift, User, TimeRegistrationShift} from 'models/index'
import {PlanningProvider} from 'cores/planning/index'

const Page = () => {
	// performance issues with large stores only arise after calling 'all'.. just getting em into redux store is not particularly slow, even with 10k records.
	// we've learned that 'enrichRecords' is very slow: 5k shifts take 1.7s with enrichrecords and 700ms without
	// defaultJoins also is a real penalty, but way smaller, something like 20% extra with 5k shifts
	// interestingly, av shifts are way faster than planning shifts, im not sure why.
	// Next big thing should be to destroy enrichRecords, and port it over to functions. Maybe we should also improve joins

	// TimeRegistrationShift.all()
	// PlanningShift.all()
	return (
		<PlanningProvider>
			<div> yo </div>
		</PlanningProvider>
	)
}

export default Page
