import {LegacySwitch as Switch} from '@eitje/form-fields-web'
import {updateOrgSettings} from 'actions/settings'
import {SettingsButton} from 'common/components'
import {useOrgSettings} from 'hooks'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'

const settings = [
	{name: 'time_settings', link: '/settings/time'},
	{name: 'common.contract_settings', link: '/settings/contract'},
]

const Organisation = () => {
	const orgSettings = useOrgSettings()

	return (
		<div className="borderBot pB12">
			<div style={{marginBottom: 8}}>
				<h3>{t('newsOrgSettings')} </h3>
				<Form styleKind={'settings'} onSubmit={updateOrgSettings} initialValues={orgSettings}>
					<Switch namespace="news" submitStrategy="change" field="allow_org_post" />
				</Form>
			</div>
			<h3>{t('general')} </h3>
			{settings.map((setting, i) => (
				<SettingsElement key={i} setting={setting} />
			))}
		</div>
	)
}

const SettingsElement = ({setting}) => (
	<div className="setting-container">
		<span className="setting-container-label">{t(setting.name)}</span>
		<div className="setting-container-input">
			<SettingsButton width="full" modalLink={setting.link} />
		</div>
	</div>
)

export default Organisation
