import {useWhere} from '@eitje/easy_api'
import {t as baseT} from 'initializers/i18n'
import {getTableName, getEntityName, getName} from 'helpers/index'
import {Text} from 'common/components'

export const useExportFilter = filter => {
	const queryType = filter.type == 'contract_environments' ? 'environments' : filter.type
	let items = useWhere(queryType || '', {id: filter.value})
	if (!filter.type) return {}
	items = fixNames(items)
	const tableName = getTableName(filter.type)
	return {items, text: getEntityName(items, tableName), type: filter.type, names: items.map(i => getName(i))}
}

export const useExportFilters = item => {
	const {filterInfo} = item
	const {tableName, columnTableNames, columnFilter, rowFilter} = filterInfo()
	const col = useExportFilter(columnFilter)
	const row = useExportFilter(rowFilter)
	const colTableName = getEntityName(columnTableNames, baseT('common.column', {count: 2}))
	return {
		row: {tableName, firmsText: row.text, present: !!rowFilter.value, ...row},
		column: {tableName: colTableName, firmsText: col.text, present: !!columnFilter.value, names: columnTableNames},
	}
}

// 4 parts
// 1. row table name (base table name)
// 2. row firms
// 3. column table names
// 4. column firms

function fixNames(filters) {
	return filters.map(f => ({...f, name: f.name || f.naam}))
}
