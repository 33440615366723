import {HourMinutePickerInput} from 'components/form/hour_minute_picker'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {totalDays} from 'helpers/schedule'
import './styles/schedule_days_overview.less'

export const ScheduleDaysOverview = ({schedule}) => {
	const {end_date, start_date, id} = schedule
	const workWeeks = _.orderBy(useWhere('workWeek', {work_schedule_id: id}), 'position')

	return (
		<div className="work-weeks">
			<h4 className="work-weeks-title"> {t('fromTill', {till: end_date, from: start_date, kind: 'common.workweek'})} </h4>
			{workWeeks.map((w, idx) => (
				<WorkWeek idx={idx} week={w} />
			))}
		</div>
	)
}

const makeMinsArr = week => {
	const {monday, tuesday, wednesday, thursday, friday, saturday, sunday} = week
	return [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
}

const WorkWeek = ({week, idx}) => {
	const kind = utils.isOdd(idx) ? t('odd') : t('even')
	const mins = makeMinsArr(week)
	return (
		<div className="work-week">
			<h5 className="work-week-title">{t('workWeekLabel', {days: totalDays([week]), kind})} </h5>
			<div className="work-week-days">
				{utils.weekdays.map((d, i) => (
					<HourMinutePickerInput readOnly labelVisible={false} value={mins[i]} />
				))}
			</div>
		</div>
	)
}
