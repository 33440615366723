import {WeekTableColumn} from 'common/components'
import {useImproductiveShifts, useFilteredImproductiveDayShifts} from 'cores/planning'
import {Cell} from './cell'

export const Column = ({date, ...rest}) => {
	// improductiveItems are either shifts or leaveRequests
	const improductiveItems = useFilteredImproductiveDayShifts(useImproductiveShifts(), date).sortBy('user.full_name')

	return (
		<WeekTableColumn {...rest}>
			{improductiveItems.map(item => (
				<Cell item={item} />
			))}
		</WeekTableColumn>
	)
}
