import passwordValidator from 'password-validator'

export const isEmail = val => {
  const emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return !val || val.length == 0 || emailCheck.test(val.trim())
}

export const isPass = val => {
  if (!val) return true
  const schema = new passwordValidator()
  schema.min(8).has().uppercase().lowercase().digits()
  return schema.validate(val)
}
