import utils from '@eitje/web_utils'
import './styles/color_circle.less'

export const ColorCircle = ({color, className, fontSize, borderColor, size = 16, shape = 'round', text}) => {
	const isHex = color?.[0] == '#'
	const borderIsHex = borderColor?.[0] == '#'

	if (borderColor && !color) {
		color = 'transparent'
	}

	if (!color) color = 'red'

	if (!fontSize) fontSize = size - 6

	const classNames = utils.makeCns([
		'eitje-circle',
		!isHex && `eitje-circle-color-${color}`,
		`eitje-circle-shape-${shape}`,
		borderColor && `eitje-circle-has-border`,
		!borderIsHex && borderColor && `eitje-circle-border-${borderColor}`,
		className,
	])

	let condStyle = {}
	if (isHex) condStyle['backgroundColor'] = color
	if (borderIsHex) condStyle['borderColor'] = borderColor

	return (
		<div
			className={classNames}
			style={{
				height: size,
				width: size,
				// minHeight& minWidth are needed to ensure the dimensions are applied in css
				minHeight: size,
				minWidth: size,
				...condStyle,
			}}
		>
			{text && (
				<span className="eitje-circle-text" style={{fontSize: fontSize, lineHeight: `${size}px`}}>
					{text}
				</span>
			)}
		</div>
	)
}
