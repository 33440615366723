import {useMemo} from 'react'
import {PlanningShift, TimeRegistrationShift, CheckIn} from 'models/index'

const opts = {joins: ['users', {teams: 'environments'}]}

// Only use this hook to populate the page's FilterFooterContext OR when you want to use unfiltered
// shifts, which is atypical since most often we only allow users to interact with items that are visible.

// TO DO: add requireArgs check for dateRange after merging onboarding, since it is required to prevent returned RegShifts
// from being empty, but provides no crash

export const useUnfilteredShifts = ({regOpts = opts, planOpts = opts, checkInOpts = opts, dateRange}) => {
	// CHECK INS
	const baseCheckIns = CheckIn.where({date: dateRange}, checkInOpts)
	// filter instead of querying with "end_date: nil", since that will exclude today's CheckIns if the scope is one day.
	const checkIns = useMemo(() => baseCheckIns.filter(ci => !ci.end_date), [baseCheckIns])
	const currentlyCheckedInIds = useMemo(() => checkIns.map(c => c.shift_ids).flat(), [checkIns])

	// REG SHIFTS
	const baseRegShifts = TimeRegistrationShift.where({date: dateRange}, regOpts)
	const regShifts = useMemo(() => baseRegShifts.filter(r => !currentlyCheckedInIds.includes(r.id)), [baseRegShifts, checkIns])

	// PLAN SHIFTS
	const basePlanShifts = PlanningShift.where({date: dateRange, published: true}, planOpts)
	const regPlanIds = baseRegShifts._map('planning_shift_id').filter(Boolean)
	const planShifts = useMemo(
		() =>
			basePlanShifts.filter(s => {
				const hasRegShift = regPlanIds.includes(s.id)
				const hasCheckIn = currentlyCheckedInIds.includes(s.id)
				return s.user_id && s.started() && !hasRegShift && !hasCheckIn
			}),
		[basePlanShifts, baseRegShifts, checkIns],
	)

	return {regShifts, planShifts, checkIns}
}
