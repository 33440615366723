import {Checkbox} from '@eitje/web_components'

export const CellCheckbox = ({row, cell, ...rest}) => {
	if (cell?.hasSubRows) return <GroupedCellCheckbox row={row} cell={cell} {...rest} />

	const checkboxSize = 16
	return (
		// the anchor here is needed for Firefox, as stopPropagation doesn't stop anchor links being followed in FF and the whole row is wrapped with an anchor.
		// however, only one anchor can be clicked at a time, so putting an anchor lower in the hierarchy solves this issue
		<a href="javascript:void(0)" style={{height: checkboxSize}}>
			<Checkbox
				size={checkboxSize}
				checked={row.getIsSelected()}
				onClick={e => {
					row.toggleSelected()
					e.stopPropagation()
				}}
			/>
		</a>
	)
}

const GroupedCellCheckbox = ({row, cell}) => {
	const isSelected = row.getIsAllSubRowsSelected()
	const table = cell.getContext().table
	const isSomeSelected = isSomeSubRowsSelected(row)
	const onChange = e => {
		e.stopPropagation()
		table.setRowSelection(old => {
			const oldIds = Object.keys(old)
			const subRowIds = row.subRows._map('id')
			const newSelection = isSelected ? oldIds.filter(id => !subRowIds.includes(id)) : [...oldIds, ...subRowIds]
			return newSelection.reduce((acc, v) => ({...acc, [v]: true}), {})
		})
	}

	return <Checkbox indeterminate={isSomeSelected} checked={isSelected} onClick={onChange} />
}

const isSomeSubRowsSelected = row => {
	const totalSubRows = row.subRows.length
	const selectedSubRowsCount = row.subRows.filter(subRow => subRow.getIsSelected()).length
	return selectedSubRowsCount > 0 && selectedSubRowsCount < totalSubRows
}
