import {buildColumn, LARGE_CELL_WIDTH, XLARGE_CELL_WIDTH} from 'common/components/advanced_table'
import {date} from 'initializers/date'
import {PERSONAL_ID_COLUMNS, PERSONAL_DETAIL_COLUMNS, CONTRACT_COLUMNS} from 'common/components/advanced_table/instances/users_in_service'
import {Environment} from 'models'

export const columns = [
	...PERSONAL_ID_COLUMNS,
	{
		id: 'deleted_environments',
		accessorFn: getDeletedEnvNames,
		width: XLARGE_CELL_WIDTH,
	},
	{
		accessorKey: 'deleted_at',
		cell: 'DateCell',
		width: LARGE_CELL_WIDTH,
	},
	...PERSONAL_DETAIL_COLUMNS,
	...CONTRACT_COLUMNS,
].map(buildColumn)

// Use function declarations instead of expressions because they're not hoisted.
function getDeletedEnvNames(user) {
	return Environment.where(user.del_env_ids)._map('naam').sort().join(', ')
}
