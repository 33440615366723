import React, {useEffect} from 'react'
import {t as baseT} from 'initializers/i18n'
import useList from 'hooks/use_list'
import {LeaveRequest} from 'models/index'
import {getPeriodString, StatusLabel} from 'cores/leave/index'
import {useModalContext} from 'components/common/index'
import {UserCard} from 'components/shared/index'
import {useSimpleContext} from 'contexts/simple'
import './styles/users_with_leave.less'
import {falsy} from 'constants/general'
import {useShared} from 'hooks'

const UsersWithLeave = () => {
	const {t} = useModalContext()
	const {request} = useSimpleContext()
	const {myOrgEnvs} = useShared()
	const filters = {start_date: request.startDate(), end_date: request.endDate()}

	useEffect(() => {
		LeaveRequest.index({filters})
	}, [])

	const orgEnvIds = myOrgEnvs.ids()
	const reqs = LeaveRequest.where({dateRange: request.dateRange, denied: falsy})
		.filter(r => r.id != request.id)
		.filter(r => orgEnvIds.includes(r.environment_id))

	const _reqs = reqs.map(r => ({...r, sameEnv: request.user.environment_ids.includes(r.environment_id)}))

	const groups = [
		{id: true, label: 'same'},
		{id: false, label: 'not same'},
	]

	const {list} = useList({items: _reqs, groups, groupField: 'sameEnv', GroupListItem, ListItem: UserRow})
	return (
		<div className="users-with-leave">
			<h3 className="users-with-leave-title"> {t('users_with_leave.title')} </h3>
			<h5 className="users-with-leave-subtitle"> {t('users_with_leave.subtitle', {count: reqs.length})} </h5>
			{list}
		</div>
	)
}

const GroupListItem = ({item}) => {
	const {id} = item
	const key = id ? 'this_env' : 'other_env'
	return (
		<>
			<h5>{baseT(`common.teammembers_${key}`)} </h5>
		</>
	)
}

const POSITION = [16, 0, '-', '-']

const UserRow = ({item}) => {
	return (
		<>
			<UserCard user={item.user} Subline={() => getPeriodString(item)} />
			<StatusLabel status={item.status()} absolute={POSITION} />
		</>
	)
}

export default UsersWithLeave
