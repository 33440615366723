import {useEffect} from 'react'

export function useScroll(fn, toWatch = []) {
	const element = document.querySelector('.page-body')
	useEffect(() => {
		element?.addEventListener('scroll', fn)
		return () => {
			element?.removeEventListener('scroll', fn)
		}
	}, [element, ...toWatch])
}

export default useScroll
