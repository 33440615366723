import {DeleteDropZone as _DeleteDropZone} from 'common/components/delete_dropzone'
import {t} from 'initializers/i18n'

export const DeleteDropZone = () => {
	const handleDrop = item => {
		item.shift.destroy()
	}

	return <_DeleteDropZone onDrop={handleDrop} type={t('records.shift.name')} accept="planning-shift" />
}
